import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyTreatment } from '../../../../generated/models';

@Component({
  selector: 'mp-fav-treatments',
  templateUrl: './fav-treatments.component.html',
  styleUrls: ['./fav-treatments.component.scss']
})
export class FavTreatmentsComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  items$ = new BehaviorSubject<MyTreatment[]>([]);

  @Input() loading = false;
  @Input() items: MyTreatment[] = [];

  @Input() analogs: { [treatment: string]: string[] } = {};

  @Output() onAdd = new EventEmitter<void>();

  @Output() onSelect = new EventEmitter<MyTreatment>();
  @Output() onCopy = new EventEmitter<MyTreatment>();
  @Output() onRemove = new EventEmitter<MyTreatment>();

  @Output() onChange = new EventEmitter<MyTreatment>();

  @Output() onAnalogAdded = new EventEmitter<{ treatment: string, analog: string }>();
  @Output() onAnalogRemoved = new EventEmitter<{ treatment: string, analog: string }>();

  filters = new FormGroup({
    search: new FormControl('')
  });

  constructor() {
    this.filters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { search: string }) => {
        if (value.search) {
          const regex = new RegExp(value.search, 'i');

          this.items$.next(this.items.filter(x => regex.test(x.treatmentName)));
        } else {
          this.items$.next([...this.items]);
        }
      });

  }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      const filters = this.filters.getRawValue();

      if (filters.search) {
        const regex = new RegExp(filters.search, 'i');

        this.items$.next(changes['items'].currentValue.filter(x => regex.test(x.treatmentName)));
      } else {

        this.items$.next([...changes['items'].currentValue]);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  analogsFor = (item: MyTreatment) => this.analogs[item.treatmentName] || [];

  add = () => this.onAdd.emit();

  select = (item: MyTreatment) => this.onSelect.emit(item);
  copy = (item: MyTreatment) => this.onCopy.emit(item);
  remove = (item: MyTreatment) => this.onRemove.emit(item);

  change = (item: MyTreatment) => this.onChange.emit(item);

  addAnalog = (item: MyTreatment, analog: string) => this.onAnalogAdded.emit({ treatment: item.treatmentName, analog });
  removeAnalog = (item: MyTreatment, analog: string) => this.onAnalogRemoved.emit({ treatment: item.treatmentName, analog });
}
