import { OrganizationResponse, PromotionResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OrganizationsService } from '../../../generated/services';
import { PromotionsService } from '../../../generated/services/promotions.service';

@Injectable({ providedIn: "root" })
export class PromotionResolver implements Resolve<PromotionResolverPayload> {

  public constructor(
    private service: PromotionsService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PromotionResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let promotionLoader: Observable<PromotionResponse> = null;

    if (id) {
      promotionLoader = this.service.Get(id);
    } else {
      const promotion: any = {};

      promotionLoader = from([promotion]);
    }

    return forkJoin({
      promotion: promotionLoader,
    });
  }
}

export class PromotionResolverPayload {
  public promotion: PromotionResponse;

  public constructor() {
  }
}
