import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManipulationsComponent } from './components/manipulations/manipulations.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ControlsModule } from '@skytecs/controls';
import { LayoutModule } from '@skytecs/layout';
import { ManipulationListItemComponent } from './components/manipulation-list-item/manipulation-list-item.component';
import { ManipulationsResolver } from './resolvers/manipulations-resolver';
import { ManipulationComponent } from './components/manipulation/manipulation.component';
import { ManipulationResolver } from './resolvers/manipulation-resolver';
import { SharedModule } from '../shared/shared.module';
import { ManipulationServiceModalComponent } from './components/manipulation-service-modal/manipulation-service-modal.component';
import { ManipulationRecommendationItemComponent } from './components/manipulation-recommendation-item/manipulation-recommendation-item.component';

const routes: Routes = [
  {
    path: '',
    component: ManipulationsComponent,
    resolve: {
      payload: ManipulationsResolver
    },
    data: {
      title: "Назначения",
      requirements: [PermissionNames.ManageManipulations]
    }
  },
  {
    path: 'new',
    component: ManipulationComponent,
    resolve: {
      payload: ManipulationResolver
    },
    data: {
      title: "Новое назначение",
      requirements: [PermissionNames.ManageManipulations]
    }
  },
  {
    path: ':id',
    component: ManipulationComponent,
    resolve: {
      payload: ManipulationResolver
    },
    data: {
      title: "Назначение",
      requirements: [PermissionNames.ManageManipulations]
    }
  }
];

@NgModule({
  declarations: [ManipulationsComponent, ManipulationListItemComponent, ManipulationComponent, ManipulationServiceModalComponent, ManipulationRecommendationItemComponent],
  entryComponents: [ManipulationServiceModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,

    SharedModule,

    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,
    InfiniteScrollModule,

    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule
  ]
})
export class ManipulationsSettingsModule { }
