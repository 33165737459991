import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagePartnerPlanModel } from 'projects/Clinic/src/app/generated/models';
import { PartnerPlansService } from 'projects/Clinic/src/app/generated/services';

@Component({
  selector: 'mp-manage-partner-plan-modal',
  templateUrl: './manage-partner-plan.modal.component.html',
  styleUrls: ['./manage-partner-plan.modal.component.scss']
})
export class ManagePartnerPlanModalComponent implements OnInit {
  public title: string;

  @Input() model: ManagePartnerPlanModel;

  @Input() btnTitle: string;
  @Input() message: string;
  @Input() confirmDisabled: boolean;

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private activeModal: NgbActiveModal,
    private plansService: PartnerPlansService
  ) {
    this.title = "Партнерская программа";
  }

  ngOnInit() {
    this.title = `Удаление программы "${this.model.name}"`;
  }

  public get canRemove(): boolean { return this.model && this.model.totalVisits === 0; }
  public get canArchive(): boolean { return this.model && !this.model.archived; }

  public get totalServices(): number { return (!!this.model) ? this.model.totalServices : 0; }
  public get totalVisits(): number { return (!!this.model) ? this.model.totalVisits : 0; }
  public get totalReferrals(): number { return (!!this.model) ? this.model.totalReferrals : 0; }

  public get removeTitle(): string {
    return this.model && this.model.totalVisits === 0 ?
      "Удалить программу" : "Удаление заблокировано: по программе оформлены посещения";
  }

  public get archiveTitle(): string {
    return this.model && !this.model.archived ?
      "Переместить программу в архив" : "Программа находится в архиве";
  }

  public remove(): void {
    this.plansService.Remove(this.model.id)
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (): void => { }
      );
  }

  public archive(): void {
    this.plansService.Archive(this.model.id)
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (): void => { }
      );
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public confirm(): void {
    this.onConfirm.emit();
  }

}
