import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefundReasonsComponent } from './components/refund-reasons/refund-reasons.component';
import { RefundReasonComponent } from './components/refund-reason/refund-reason.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RefundReasonResolver } from './resolvers/refund-reason-resolver';
import { RefundReasonItemComponent } from './components/refund-reason-item/refund-reason-item.component';
import { ManageRefundReasonComponent } from './components/manage-refund-reason/manage-refund-reason.component';

const routes: Routes = [
  {
    path: 'new',
    component: RefundReasonComponent,
    data: {
      title: "Причина возврата",
      requirements: [PermissionNames.ManagerRefundReasons]
    },
    resolve: {
      payload: RefundReasonResolver
    }
  },
  {
    path: ':id',
    component: RefundReasonComponent,
    data: {
      title: "Причина возврата",
      requirements: [PermissionNames.ManagerRefundReasons]
    },
    resolve: {
      payload: RefundReasonResolver
    }
  },
  {
    path: '',
    component: RefundReasonsComponent,
    data: {
      title: "Причины возврата",
      requirements: [PermissionNames.ManagerRefundReasons]
    }
  }

];

@NgModule({
  declarations: [RefundReasonsComponent, RefundReasonComponent, RefundReasonItemComponent, ManageRefundReasonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,

    NgbModalModule,
    ToastrModule,
    InfiniteScrollModule,

    RouterModule.forChild(routes)
  ],
  entryComponents: [
    ManageRefundReasonComponent
  ],
  exports: [
    RouterModule
  ]
})
export class RefundReasonsSettingsModule { }
