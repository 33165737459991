import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialitiesComponent } from './components/specialities/specialities.component';
import { SpecialityComponent } from './components/speciality/speciality.component';
import { SpecialityItemComponent } from './components/speciality-item/speciality-item.component';
import { Routes, RouterModule } from '@angular/router';
import { SpecialityResolver } from './resolvers/speciality-resolver';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { PermissionNames } from '../../models/permission-names';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: "new",
    component: SpecialityComponent,
    data: {
      title: "Новая специальность",
      requirements: [PermissionNames.EditSpecialities]
    },
    resolve: {
      payload: SpecialityResolver
    }
  },
  {
    path: ":id",
    component: SpecialityComponent,
    data: {
      title: "Специальность",
      requirements: [PermissionNames.EditSpecialities]
    },
    resolve: {
      payload: SpecialityResolver
    }
  },
  {
    path: "",
    component: SpecialitiesComponent,
    data: {
      title: "Список специальностей",
      requirements: [PermissionNames.EditSpecialities]
    }
  }
];

@NgModule({
  declarations: [SpecialitiesComponent, SpecialityComponent, SpecialityItemComponent],
  imports: [
    CommonModule,
    ToastrModule,

    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NgbDropdownModule,
    InfiniteScrollModule,

    LayoutModule,
    ControlsModule,

    SharedModule

  ],
  exports: [
    RouterModule
  ]
})
export class SpecialitySettingsModule { }
