import { Component, HostBinding, Input, OnInit } from '@angular/core';

import * as moment from 'moment';
import { AppointmentViewModel } from '../../../../generated/models';

@Component({
  selector: 'mp-day-slot',
  templateUrl: './day-slot.component.html',
  styleUrls: ['./day-slot.component.scss']
})
export class DaySlotComponent implements OnInit {

  @Input() slot: AppointmentViewModel;

  @HostBinding('class.loading')
  @Input()
  loading = false;

  @HostBinding('class.selected')
  @Input()
  selected = false;

  @Input()
  showRecommendations = false;

  @HostBinding('class.recommended')
  get recommended(): boolean {
    return this.slot && this.slot.recommended && !this.slot.claimed && !this.slot.outOfDoctors && !this.slot.inThePast && !(this.slot.break || this.slot.unscheduledBreak);
  }

  @HostBinding('class.free')
  get free(): boolean { return this.slot && !this.slot.claimed; }

  @HostBinding('class.in-the-past')
  get inThePast(): boolean { return this.slot && this.slot.inThePast; }

  @HostBinding('class.break')
  get break(): boolean { return this.slot && (this.slot.break || this.slot.unscheduledBreak); }

  @HostBinding('class.blocked')
  get blocked(): boolean { return this.slot && this.slot.blocked; }

  @HostBinding('class.claimed')
  get claimed(): boolean { return this.slot && this.slot.claimed; }

  @HostBinding('class.confirmed')
  get confirmed(): boolean { return this.slot && this.slot.confirmed; }

  @HostBinding('class.out-of-doctors')
  get outOfDoctors(): boolean { return this.slot && this.slot.outOfDoctors; }

  get start(): string {
    if (!this.slot) return '';

    return moment(this.slot.start, 'DD.MM.YYYY HH:mm').format("HH:mm");
  }

  get shortName(): string {
    const parts = [];

    if (!this.slot) return '';

    if (this.slot.patientLastName) {
      parts.push(this.slot.patientLastName);
    }

    if (this.slot.patientFirstName) {
      parts.push(`${this.slot.patientFirstName[0]}.`);
    }

    if (this.slot.patientMiddleName) {
      parts.push(`${this.slot.patientMiddleName[0]}.`);
    }

    return parts.join(' ').trim();
  }

  constructor() { }

  ngOnInit() { }

}
