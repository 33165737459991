import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ManageOrganizationModel, PromotionsResponse } from '../../../../generated/models';
import { UserStorage } from '../../../../services/user-storage';
import { PromotionResponse } from '../../../../generated/models/promotion-response';
import { ManagePromotionModalComponent } from '../manage-promotion.modal/manage-promotion.modal.component';
import { PromotionsService } from '../../../../generated/services/promotions.service';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { fromPromise } from 'rxjs/internal-compatibility';

@Component({
  selector: 'mp-promotion-list',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
  host: { class: "page" }
})
export class PromotionsComponent implements OnInit, OnDestroy {
  private _promotions: PromotionResponse[];
  private _selected: { [id: number]: boolean };

  private _page: number;
  private _size: number;
  private _total: number;
  private _search: string;
  private _activeOnly: boolean;

  public scrollDistance = 1;

  public filters: FormGroup;

  private _filtersChangeSubscription: Subscription;

  constructor(
    private promotionsService: PromotionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private userStorage: UserStorage
  ) {
    this._promotions = [];
    this._selected = {};

    this._page = 1;
    this._size = 20;
    this._total = 0;

    this._activeOnly = true;

    this.filters = new FormGroup({
      search: new FormControl(""),
      activeOnly: new FormControl("")
    });

    this.filters.controls["activeOnly"].setValue(this._activeOnly);

    this._filtersChangeSubscription = this.filters.valueChanges
      .pipe(
        distinctUntilChanged((x: any, y: any): boolean => {
          return x.activeOnly === y.activeOnly && x.search === y.search;
        })
      )
      .subscribe((values: any): void => {
        this._page = 1;
        this._promotions = [];

        this._search = values.search;
        this._activeOnly = values.activeOnly;

        const queryParams: Params = {};

        if (this._search) {
          queryParams.search = this._search;
        }

        if (!this._activeOnly) {
          queryParams.activeOnly = false;
        }

        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

        this.loadPage();
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    if (queryMap.has("search")) {
      this._search = queryMap.get("search");
      this.filters.get("search").setValue(this._search);
    }

    if (!queryMap.has("activeOnly") || queryMap.get("activeOnly") == "true") {
      this._activeOnly = true;
      this.filters.get("activeOnly").setValue(true);
    } else {
      this._activeOnly = false;
      this.filters.get("activeOnly").setValue(false);
    }

    this.filters.controls["search"].setValue(this._search);
  }

  ngOnDestroy() {
    this._filtersChangeSubscription.unsubscribe();
  }

  private loadPage(): void {
    this.promotionsService.Promotions({ Page: this._page, Size: this._size, Search: this._search, ActiveOnly: this._activeOnly })
      .subscribe((result: PromotionsResponse): void => {
        this._promotions = this._promotions.concat(result.promotions);
        this._total = result.total;
      });
  }

  public onScrollDown(): void {
    this._page++;

    this.loadPage();
  }

  public openManager(promotion: PromotionResponse): void {
    this.promotionsService.Manage(promotion.id)
      .subscribe(
        (manageModel: ManageOrganizationModel): void => {
          const options: NgbModalOptions = {
            backdrop: 'static',
            size: 'sm',
            centered: true,
            windowClass: "modal-confirm"
          };

          const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);

          modalRef.componentInstance.message = "Удалить запись из списка?";

          modalRef.result.then(
            (): void => {
              this.promotionsService.Remove(promotion.id).subscribe(
                (): void => {
                  this._page = 1;
                  this._promotions = [];

                  this.loadPage();
                },
                (): void => { }
              )
            },
            (): void => { }
          );

        });
  }

  public get promotions(): PromotionResponse[] { return this._promotions; }
  public get selected(): { [id: number]: boolean } { return this._selected; }

  public edit = (promotion: PromotionResponse): void => {
    this.router.navigate(["settings", "promotions", promotion.id]);
  }

  public create(): void {
    this.router.navigate(["settings", "promotions", "new"]);
  }

}
