import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ServiceCategory } from '../../../../generated/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-service-category-item',
  templateUrl: './service-category-item.component.html',
  styleUrls: ['./service-category-item.component.scss']
})
export class ServiceCategoryItemComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();

  @Input() category: ServiceCategory;
  @Input() public set checked(value: boolean) { this.checkbox.setValue(value); }

  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  public checkbox: FormControl;
  constructor() {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: boolean): void => {
          this.onCheck.emit(value);
        },
        (): void => { }
      );

  }

  ngOnInit() { }
  ngOnDestroy() {
    this._destroy.next;
    this._destroy.unsubscribe();
  }


}
