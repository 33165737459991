import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mp-visit-tab',
  templateUrl: './visit-tab.component.html',
  styleUrls: ['./visit-tab.component.scss']
})
export class VisitTabComponent {
  active = false;

  @Input("mp-title") title: string;
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() hidden = false;

  activate = () => this.active = true;
  deactivate = () => this.active = false;
}
