import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttendanceCallReport } from '../../../../generated/models';

@Component({
  selector: 'mp-call-report-item',
  templateUrl: './call-report-item.component.html',
  styleUrls: ['./call-report-item.component.scss'],
  host: { class: 'call' }
})
export class CallReportItemComponent {
  @Input() call: AttendanceCallReport;
  @Input() canRemove = false;
  @Output() onRemove = new EventEmitter<void>();

  get status(): string {
    if (!this.call) return '';

    switch (this.call.status) {

      case 0: return 'Звонок не завершен';
      case 1: return 'Неправильный номер';
      case 2: return 'Нет ответа';
      case 3: return 'Перезвонить позднее';
      case 4: return 'Связь прервалась';
      case 5: return 'Явка подтверждена';

      default: '';
    }
  }

  remove() {
    if (!this.canRemove) return;
    this.onRemove.emit();
  }
}
