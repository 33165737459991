import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonConsultation } from '../../../../generated/models';

@Component({
  selector: 'mp-visit-list-item',
  templateUrl: './visit-list-item.component.html',
  styleUrls: ['./visit-list-item.component.scss']
})
export class VisitListItemComponent implements OnInit {

  @Input() item: PersonConsultation;
  @Input() expanded: boolean;
  @Input() current: boolean;
  @Input() canAddService = false;

  @Output() onAdd = new EventEmitter<void>();
  @Output() onAddService = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  add = () => this.onAdd.emit()
  addService = () => this.onAddService.emit();
}
