import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin, from } from "rxjs";
import { CategoriesService, ServiceGroupsService, ServicesService } from "../../../generated/services";
import { Category, Service, ServiceGroup } from "../../../generated/models";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ServiceGroupResolver implements Resolve<ServiceGroupResolverPayload> {

  constructor(private groupServicesService: ServiceGroupsService,
    private servicesService: ServicesService,
    private categoriesService: CategoriesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceGroupResolverPayload> {
    const id = parseInt(route.paramMap.get("id"), 10)

    return forkJoin({
      serviceGroup: id > 0 ? this.groupServicesService.ServiceGroupAsync(id) : from([{}]),
      services: this.servicesService.Services({ Size: 0, Page: 1, IncludeArchived: true }).pipe(map(x => x.services)),
      categories: this.categoriesService.Categories({ Size: 0, Page: 1 }).pipe(map(x => x.items))
    });
  }
}

export interface ServiceGroupResolverPayload {
  serviceGroup: ServiceGroup;
  services: Service[];
  categories: Category[];
}
