import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { takeUntil, tap, switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { RolesService } from '../../../../generated/services';
import { Role } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';

@Component({
  selector: 'mp-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  host: { class: "page" }
})
export class RolesComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  loading = false;
  roles: Role[] = [];
  private _selectedItems: Role[];

  canView = false;
  canCreate = false;
  canRemove = false;

  checkbox: FormControl = new FormControl(false);
  form: FormGroup = new FormGroup({
    search: new FormControl("")
  });

  get hasSelected(): boolean { return this._selectedItems.length > 0; }
  get indeterminate(): boolean { return this._selectedItems.length > 0 && this._selectedItems.length != this.roles.length; }
  get allSelected(): boolean { return this._selectedItems.length == this.roles.length; }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private rolesService: RolesService,
    private toastrService: ToastrService,
    private userStorage: UserStorage
  ) {
    this._selectedItems = [];

    this.canView = this.userStorage.hasPermission(PermissionNames.ViewRoles);
    this.canCreate = this.userStorage.hasPermission(PermissionNames.CreateRoles);
    this.canRemove = this.userStorage.hasPermission(PermissionNames.DeleteRoles);


    this.checkbox.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (value: boolean): void => {
          if (value) {
            this._selectedItems = [...this.roles];
          } else {
            this._selectedItems = [];
          }

        },
        (): void => { }
      );

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((value: { search: string }): void => {
          const params: Params = {};
          if (!!value.search) {
            params.search = value.search;
          }

          this.router.navigate([], { queryParams: params, relativeTo: this.activatedRoute });
        }),
        tap((): void => {
          this.loading = true;
          this._selectedItems = [];
        }),
        switchMap((value: { search: string }): Observable<Role[]> => this.rolesService.Roles({ Search: value.search }))
      )
      .subscribe(
        (roles: Role[]): void => {
          this.roles = roles;
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          this.loading = false;
          this.handleErrorResponse(response);
        }
      );

  }

  ngOnInit() {
    this.form.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search")
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  create(): void {
    if (!this.canCreate) return;

    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  edit(role: Role): void {
    if (!this.canView) return;

    this.router.navigate([role.id.toString()], { relativeTo: this.activatedRoute });
  }

  async remove(): Promise<void> {
    if (!this.canRemove) return;

    if (!this.hasSelected) return;

    const ids: number[] = this._selectedItems.map((x: Role): number => x.id);

    try {
      await this.rolesService.RemoveMany(ids).toPromise();
      this.roles = this.roles.filter(x => !ids.includes(x.id));
      this.checkbox.setValue(false);
      this._selectedItems = [];

    } catch (e) {
      const response = e as HttpErrorResponse;
      this.handleErrorResponse(response);
    }

  }

  public selected(role: Role): boolean { return this._selectedItems.some((x: Role): boolean => x.id === role.id); }

  public toggle(role: Role, value: boolean): void {
    const index: number = this._selectedItems.findIndex((x: Role): boolean => x.id === role.id);

    if (value && index === -1) {
      this._selectedItems.push(role);
    }

    if (!value && index !== -1) {
      this._selectedItems.splice(index, 1);
    }
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (response.status === 400) {
      const error: { status: number } = response.error;

      switch (error.status) {
        case 1: this.toastrService.warning("Для выполнения действия требуется разрешение", "Запрещено"); return;
        case 2: this.toastrService.warning("Одна или несколько ролей используются", "Ошибка"); return;
        case 6: this.toastrService.warning("Передан пустой список ключей", "Ошибка"); return;

      }

    }

    this.toastrService.error("Произошла внутренняя ошибка сервера", "Ошибка");
  }

}
