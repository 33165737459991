import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mp-attendance-type-select',
  templateUrl: './attendance-type-select.component.html',
  styleUrls: ['./attendance-type-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AttendanceTypeSelectComponent),
    multi: true
  }]
})
export class AttendanceTypeSelectComponent implements OnInit, ControlValueAccessor {
  disabled = false;
  value: 0 | 1 | 2 = 0;

  fn = value => { };

  constructor() { }

  selected = (type: 1 | 2) => this.value === type;

  toggle(type: 1 | 2) {
    if (this.disabled) return;

    if (this.value === type) this.value = 0;
    else this.value = type;

    this.fn(this.value);
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.fn = fn || (value => { });
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit() { }

}
