import { Pipe, PipeTransform } from '@angular/core';
import { Icd10ResponseItem } from '../../../generated/models';

@Pipe({
  name: 'diagnosisFilterPipe'
})
export class DiagnosisFilterPipePipe implements PipeTransform {

  transform(value: Icd10ResponseItem[], search: string): Icd10ResponseItem[] {
    if (!value || value.length === 0) {
      return value;
    }

    if (!search) {
      return value;
    }

    const tester: RegExp = new RegExp(search, "i");

    return value.filter((x: Icd10ResponseItem): boolean => tester.test(x.code) || tester.test(x.name));
  }
}
