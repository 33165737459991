import { Component, Input, OnInit } from '@angular/core';
import { TemplateSectionMetric } from '../../../../generated/models';

@Component({
  selector: 'mp-template-section-metric',
  templateUrl: './template-section-metric.component.html',
  styleUrls: ['./template-section-metric.component.scss'],
  host: { class: 'm-2 p-1, d-flex' }
})
export class TemplateSectionMetricComponent implements OnInit {

  @Input() index: number = 0;
  @Input() metric: TemplateSectionMetric = {};

  constructor() { }

  ngOnInit() { }

}
