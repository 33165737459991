import { Component, OnInit, HostListener } from '@angular/core';

import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { ActiveCallService } from '../../modules/active-call/services/active-call-service';

@Component({
  selector: 'mp-incoming-call-notification',
  templateUrl: './incoming-call-notification.component.html',
  styleUrls: ['./incoming-call-notification.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ]
})
export class IncomingCallNotificationComponent extends Toast implements OnInit {

  @HostListener("click")
  handleClick(): void {
    const phone: string = this.message.toString().replace(/[^\d]/g, "");

    this.router.navigate(["call-center", "patients"], { queryParams: { phone: phone }, });
    this.remove();
  }

  get phone(): string | SafeHtml {
    if (!this.message) {
      return "";
    }

    if (typeof this.message !== "string") return this.message;

    if (this.message.length === 11) {
      return `+7(${this.message.substr(1, 3)}) ${this.message.substr(4, 3)}-${this.message.substr(7, 2)}-${this.message.substr(9, 2)}`;
    }

    if (this.message.length === 10) {
      return `+7(${this.message.substr(0, 3)}) ${this.message.substr(3, 3)}-${this.message.substr(6, 2)}-${this.message.substr(8, 2)}`;
    }

    return this.message;
  }

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private router: Router,
    private activeCallService: ActiveCallService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit() { }

  action(event: Event) {
    event.stopPropagation();
    return false;
  }
  searchFor(phone: string | SafeHtml): void {
    this.router.navigate(["call-center", "patients"], { queryParams: { phone: phone }, });
    this.remove();
  }

  close = () => this.remove();
}
