import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, from, Observable } from "rxjs";
import { RefundReason } from "../../../generated/models";
import { RefundReasonsService } from "../../../generated/services";

@Injectable({
  providedIn: "root"
})
export class RefundReasonResolver implements Resolve<RefundReasonResolverPayload> {
  constructor(private service: RefundReasonsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RefundReasonResolverPayload> {
    const id = parseInt(route.paramMap.get('id'));
    let reasonLoader: Observable<RefundReason> = from([{}]);

    if (id > 0) {
      reasonLoader = this.service.RefundReasonAsync(id);
    }

    return forkJoin({
      refundReason: reasonLoader
    });
  }
}

export interface RefundReasonResolverPayload {
  refundReason: RefundReason
}
