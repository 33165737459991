import { Component, OnInit, forwardRef } from '@angular/core';

import * as moment from "moment";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'mp-daterange',
  templateUrl: './daterange.component.html',
  styleUrls: ['./daterange.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DaterangeComponent),
    multi: true
  }]
})
export class DaterangeComponent implements OnInit, ControlValueAccessor {
  public disabled;

  private onChange: (value: { from: moment.Moment, to: moment.Moment }) => any;
  private onTouched: (status: any) => any;

  public form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      from: new FormControl(""),
      to: new FormControl("")
    });

    this.form.valueChanges.subscribe(
      (range: { from: moment.Moment, to: moment.Moment }): void => {
        if (this.onChange) {
          this.onChange(range);
        }
      });

    this.form.statusChanges.subscribe((status: any): void => {
      if (this.onTouched) {
        this.onTouched(status);
      }

    })
  }

  ngOnInit() { }

  writeValue(obj: any): void {
    this.form.get("from").setValue(obj ? obj.from : null);
    this.form.get("to").setValue(obj ? obj.to : null);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
