/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PriceEditorModel } from '../models/price-editor-model';
@Injectable({
  providedIn: 'root',
})
class PricesService extends __BaseService {
  static readonly PricePath = '/api/Prices/id';
  static readonly AddPath = '/api/Prices';
  static readonly EditPath = '/api/Prices/{id}';
  static readonly DeletePath = '/api/Prices/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  PriceResponse(id?: number): __Observable<__StrictHttpResponse<PriceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Prices/id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Price(id?: number): __Observable<PriceEditorModel> {
    return this.PriceResponse(id).pipe(
      __map(_r => _r.body as PriceEditorModel)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  AddResponse(model?: PriceEditorModel): __Observable<__StrictHttpResponse<PriceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceEditorModel>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Add(model?: PriceEditorModel): __Observable<PriceEditorModel> {
    return this.AddResponse(model).pipe(
      __map(_r => _r.body as PriceEditorModel)
    );
  }

  /**
   * @param params The `PricesService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditResponse(params: PricesService.EditParams): __Observable<__StrictHttpResponse<PriceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Prices/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceEditorModel>;
      })
    );
  }
  /**
   * @param params The `PricesService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  Edit(params: PricesService.EditParams): __Observable<PriceEditorModel> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as PriceEditorModel)
    );
  }

  /**
   * @param id undefined
   */
  DeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Prices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Delete(id: number): __Observable<null> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PricesService {

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    id: number;
    model?: PriceEditorModel;
  }
}

export { PricesService }
