import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { PriceSet, PriceSetsResponse } from '../../../../generated/models';
import { PriceSetsService } from '../../../../generated/services';
import { PreviewModalComponent } from '../../../shared/components/preview-modal/preview-modal.component';
import { ReportsUtilityService } from '../../../shared/services/reports-url-service';

@Component({
  selector: 'mp-price-sets',
  templateUrl: './price-sets.component.html',
  styleUrls: ['./price-sets.component.scss'],
  host: { class: "page" }
})
export class PriceSetsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  priceSets: PriceSet[] = [];

  loading = false;
  page = 1;
  size = 20;
  scrollDistance = 1;

  filters = new FormGroup({
    search: new FormControl(""),
    activeOnly: new FormControl("")
  });

  constructor(private priceSetsService: PriceSetsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private reportsUtilityService: ReportsUtilityService,
    private sanitaizer: DomSanitizer
  ) {

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: SearchValue): void => {
          const params: Params = {};

          if (value.search) {
            params.search = value.search
          }

          if (!value.activeOnly) {
            params.activeOnly = false;
          }

          this.router.navigate([], { queryParams: params, relativeTo: this.activatedRoute });
        }),
        tap(() => this.loading = true),
        tap(() => this.page = 1),
        switchMap((value: SearchValue) => this.priceSetsService.PriceSets({
          Search: value.search,
          Page: this.page,
          Size: this.size,
          IncludeExpired: !value.activeOnly
        })),
        tap(() => this.loading = false)
      )
      .subscribe(
        (response: PriceSetsResponse) => this.priceSets = response.priceSets,
        (response: HttpErrorResponse) => this.toastrService.error('Не удалось загрузить прайс-листы', 'Ошибка')
      );
  }

  ngOnInit() {
    const queryParamMap = this.activatedRoute.snapshot.queryParamMap;

    this.filters.patchValue({
      search: queryParamMap.get("search"),
      activeOnly: !queryParamMap.has("activeOnly") || queryParamMap.get("activeOnly") === "true"
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  loadPage(): void {
    const value: SearchValue = this.filters.getRawValue();

    this.priceSetsService.PriceSets({
      Page: this.page,
      Size: this.size,
      Search: value.search,
      IncludeExpired: !value.activeOnly
    }).subscribe((result: PriceSetsResponse) => this.priceSets = this.priceSets.concat(result.priceSets))
  }

  onScrollDown(): void {
    this.page++;
    this.loadPage();
  }

  handleError(response: HttpErrorResponse) {
    if (response.status === 400) {
      for (const error of response.error.errors) {

        if (error.status) {
          switch (error.status) {
            case 1: this.toastrService.warning('Прайс-лист не найден', 'Ошибка'); break;
            case 9: this.toastrService.warning('Невозможно удалить прайс-лист по умолчанию', 'Ошибка'); break;
            case 14: this.toastrService.warning('Невозможно удалить прайс-лист, для которого оформлены посещения', 'Ошибка'); break;

            default: this.toastrService.error('Внутренняя ошибка сервера', 'Ошибка'); break;
          }
        }

        if (typeof error === 'string') {
          switch (error) {
            case 'PriceSetNotFound': this.toastrService.warning('Прайс-лист не найден', 'Ошибка'); break;
            case 'ForbiddenForDefaultPriceSet': this.toastrService.warning('Невозможно удалить прайс-лист по умолчанию', 'Ошибка'); break;
            case 'ForbiddenForPriceSetWithVisits': this.toastrService.warning('Невозможно удалить прайс-лист, для которого оформлены посещения', 'Ошибка'); break;

            default: this.toastrService.error('Внутренняя ошибка сервера', 'Ошибка'); break;
          }
        }
      }

      return;
    }

    this.toastrService.error('Не удалось удалить прайс-лист', 'Отмена');

  }

  delete(priceSet: PriceSet): void {
    const modalRef: NgbModalRef = this.modalService.open(DeleteConfirmationModalComponent, { backdrop: 'static', centered: true });
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Удалить прайс-лист ${priceSet.name}?`;

    modalRef.result.then(
      () => {
        this.priceSetsService.Remove(priceSet.id).subscribe(
          (): void => {
            this.priceSets = [];
            this.page = 1;
            this.loadPage();

            this.toastrService.success('Прайс-лист удалён', 'Успешно');
          },
          (response: HttpErrorResponse): void => this.handleError(response))
      },
      () => { }
    );
  }

  copy(priceSet: PriceSet): void {
    const modalRef: NgbModalRef = this.modalService.open(DeleteConfirmationModalComponent, { backdrop: 'static', centered: true });
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Копировать прайс-лист ${priceSet.name}?`;
    componentRef.confirmBtnText = "Копировать";

    modalRef.result.then(
      () => {
        this.priceSetsService.Copy(priceSet.id)
          .subscribe(
            (): void => {
              this.priceSets = [];
              this.page = 1;
              this.loadPage();

              this.toastrService.success('Прайс-лист скопирован', 'Успешно');
            },
            (error: HttpErrorResponse): void => {
              if (error.status === 400) {
                this.toastrService.error(error.error.message, 'Ошибка');
                return;
              }

              this.toastrService.error('Не удалось удалить прайс-лист', 'Отмена');
            }
          )
      },
      () => { }
    );
  }

  edit = (priceSet: PriceSet) => this.router.navigate([priceSet.id], { relativeTo: this.activatedRoute });
  create = () => this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  print = (priceSet: PriceSet) => this.reportsUtilityService.printReport({ name: 'PriceSetReport', id: priceSet.id.toString() });

  //print(priceSet: PriceSet): void {
  //  const parameters: { [key: string]: string } = {
  //    name: 'PriceSetReport',
  //    id: priceSet.id.toString()
  //  };

  //  this.reportsUtilityService.getUrl(parameters)
  //    .subscribe(url => {
  //      const options: NgbModalOptions = {
  //        centered: true,
  //        backdrop: 'static',
  //        size: 'lg'
  //      };
  //      const modalRef: NgbModalRef = this.modalService.open(PreviewModalComponent, options);
  //      const componentRef: PreviewModalComponent = modalRef.componentInstance;

  //      componentRef.title = "Просмотр отчета";
  //      componentRef.pdfSrc = this.sanitaizer.bypassSecurityTrustResourceUrl(url);

  //      componentRef.onDownload
  //        .subscribe((type: string): void => {
  //          this.reportsUtilityService.downloadReport(parameters, type);
  //        });

  //      componentRef.onCancel.subscribe(() => {
  //        modalRef.close();
  //      });

  //    });
  //}
}

interface SearchValue {
  search: string;
  activeOnly: boolean;
}
