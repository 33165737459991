import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Results } from '../../models/results';

@Component({
  selector: "mp-results-modal",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"]
})
export class ResultsModalComponent implements OnInit {
  @Input() title: string;
  @Input() results: Results[] = [];

  constructor(private modal: NgbActiveModal) { }

  ngOnInit() { }

  cancel = () => this.modal.close();
  ok = () => this.modal.close();
}
