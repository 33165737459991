import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CallAction } from '../../services/active-call-service';

@Component({
  selector: 'mp-active-call-action',
  templateUrl: './active-call-action.component.html',
  styleUrls: ['./active-call-action.component.scss'],
  host: { class: 'd-flex' }
})
export class ActiveCallActionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();
  @Input() selected = false;

  @HostBinding('class.text-muted')
  get muted(): boolean { return !this.selected; }


  @Input() item: CallAction;
  @Output() onAdd = new EventEmitter<void>();
  @Output() onRemove = new EventEmitter<void>();

  control = new FormControl(false);

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => {
        if (value) this.onAdd.emit();
        else this.onRemove.emit();
      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      const selected = changes['selected'].currentValue;

      this.control.setValue(selected, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get name(): string {
    if (!this.item) return '';

    switch (this.item.ActionType) {
      case 1: return 'Консультация';
      case 2: return 'Запись на прием';
      case 3: return 'Результаты анализов';
      case 4: return 'Другое';
    }

    return '';
  }

  add = () => this.onAdd.emit();
  remove = () => this.onRemove.emit();
}
