import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecordSection } from '../../../../generated/models';

@Component({
  selector: 'mp-section-editor',
  templateUrl: './section-editor.component.html',
  styleUrls: ['./section-editor.component.scss']
})
export class SectionEditorComponent implements OnInit {

  @Input() section: RecordSection;

  @Input() signed: boolean;
  @Input() disabled: boolean;

  @Output()
  onRestore: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onChange: EventEmitter<RecordSection> = new EventEmitter<RecordSection>();

  constructor() { }

  ngOnInit() { }

  public toggleExtractStatus(): void {
    const updated: RecordSection = { ...this.section, includeInExtract: !this.section.includeInExtract };

    this.onChange.emit(updated);
  }

  public toggleVisibility(): void {
    const updated: RecordSection = { ...this.section, show: !this.section.show };

    this.onChange.emit(updated);
  }

  public restore(): void {
    this.onRestore.emit();
  }

}
