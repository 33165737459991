import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PersonMetric } from '../../../../generated/models';

import * as moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'mp-person-metrics-list',
  templateUrl: './person-metrics-list.component.html',
  styleUrls: ['./person-metrics-list.component.scss']
})
export class PersonMetricsListComponent implements OnInit, OnChanges {

  @Input() metrics: PersonMetric[] = [];

  @Output() onSelect = new EventEmitter<PersonMetric>();

  recent: PersonMetric[] = [];
  grouped: MetricGroup[] = [];

  history: { [name: string]: PersonMetric[] } = {};
  opened: { [name: string]: boolean } = {};

  filters = new FormGroup({
    recent: new FormControl(true)
  });

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['metrics']) {
      this.recent = [];
      this.grouped = [];

      const grouped: MetricGroup[] = [];
      const recent: PersonMetric[] = [];

      for (let i = 0; i < this.metrics.length; i++) {
        const metric = this.metrics[i];

        const index = recent.findIndex(x => x.systemName === metric.systemName);

        if (index === -1) {
          recent.push(metric);
        } else if (moment(recent[index].recordSigned, 'DD.MM.YYYY').isBefore(moment(metric.recordSigned, 'DD.MM.YYYY'))) {
          recent.splice(index, 1, metric);
        }

        if (!this.history[metric.systemName]) {
          this.history[metric.systemName] = [];
        }

        this.history[metric.systemName].push(metric);
      }

      for (const key of Object.keys(this.history)) {
        this.history[key] = this.history[key].sort((x, y) => moment(y.recordSigned, 'DD.MM.YYYY').unix() - moment(x.recordSigned, 'DD.MM.YYYY').unix());
      }

      this.recent = recent;
    }
  }

  select = (metric: PersonMetric) => this.onSelect.emit(metric);
  historyFor = (metric: PersonMetric) => (this.history[metric.systemName] || []).filter(x => x.id !== metric.id);
  isOpened = (metric: PersonMetric) => this.opened[metric.systemName];

  toggle = (metric: PersonMetric) => this.opened[metric.systemName] = !this.opened[metric.systemName];
}

interface MetricGroup {
  systemName: string;
  description: string;
  displayName: string
  metrics: PersonMetric[];
}
