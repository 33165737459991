import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Logo } from '../../../../generated/models';

@Component({
  selector: '[mp-file-input]',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  host: { class: "form-group" },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileInputComponent),
    multi: true
  }]
})
export class FileInputComponent implements OnInit, ControlValueAccessor {
  private _destroy$: Subject<void> = new Subject<void>();
  private file: Logo;

  fileUploader: FormControl;
  status: FormControl;
  buttonText: string;

  @Input() lable: string;
  @Input() acceptTypes: string = 'application/pdf,image/jpeg,image/jpg,image/png';
  @Output() fileChange = new EventEmitter<Logo>();

  @ViewChild("uploader") uploader: ElementRef<HTMLInputElement>;

  ngOnInit() { }

  constructor() {
    this.status = new FormControl({ value: '', disabled: true });


    this.fileUploader = new FormControl();
    this.fileUploader.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((): void => {
        const files: FileList = this.uploader.nativeElement.files;
        const reader = new FileReader();

        if (files.length > 0) {
          const file = files[0];
          reader.readAsDataURL(file);
          reader.onload = () => {
            const f = {
              filetype: file.type,
              value: reader.result.toString().split(',')[1]
            };
            this.onChange(f);
            this.updateState(f)
          };
        }
      });

    this.updateState(null);
  }

  openFileDialog(): void {
    this.uploader.nativeElement.click();
  }

  onChange = (value: Logo) => { };
  onTouched = () => { };

  updateState(file: Logo) {
    var hasFile = file && file.value;
    this.buttonText = hasFile ? "Изменить" : "Добавить";
    this.status.setValue(hasFile ? "Файл загружен" : "Файл не загружен");
  }

  writeValue(obj: any): void {
    if (obj && (obj as Logo)) {
      const f: Logo = (obj as Logo)
      this.file = f;
      this.updateState(f)
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.fileUploader.disable() : this.fileUploader.enable();
  }






}
