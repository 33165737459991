import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManageQuestionnaireModalComponent } from '../manage-questionnaire.modal/manage-questionnaire.modal.component';
//import { QuestionnaireListItem } from '../../../../generated/models/questionnaire-list-item';
import { QuestionnairesResponse } from '../../../../generated/models/questionnaires-response';
import { ManageQuestionnaireModel } from '../../../../generated/models/manage-questionnaire-model';
import { QuestionnairesService } from '../../../../generated/services/questionnaires.service';
import { QuestionnaireResponse } from '../../../../generated/models';
import { UserStorage } from '../../../../services/user-storage';

@Component({
    selector: 'mp-questionnaire-list',
    templateUrl: './questionnaires.component.html',
    styleUrls: ['./questionnaires.component.scss'],
    host: { class: "page" }
})
export class QuestionnairesComponent implements OnInit, OnDestroy {
    private _questionnaires: QuestionnaireResponse[];
    private _selected: { [id: number]: boolean };

    private _page: number;
    private _size: number;
    private _total: number;
    private _search: string;
    private _activeOnly: boolean;

    public scrollDistance = 1;

    public filters: FormGroup;

    private _filtersChangeSubscription: Subscription;

    constructor(
        private questionnairesService: QuestionnairesService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private modal: NgbModal,
        private userStorage: UserStorage
    ) {
        this._questionnaires = [];
        this._selected = {};

        this._page = 1;
        this._size = 20;
        this._total = 0;

        this._activeOnly = true;

        this.filters = new FormGroup({
            search: new FormControl(""),
            activeOnly: new FormControl("")
        });

        this.filters.controls["activeOnly"].setValue(this._activeOnly);

        this._filtersChangeSubscription = this.filters.valueChanges
            .pipe(
                distinctUntilChanged((x: any, y: any): boolean => {
                    return x.activeOnly === y.activeOnly && x.search === y.search;
                })
            )
            .subscribe((values: any): void => {
                this._page = 1;
                this._questionnaires = [];

                this._search = values.search;
                this._activeOnly = values.activeOnly;

                const queryParams: Params = {};

                if (this._search) {
                    queryParams.search = this._search;
                }

                if (!this._activeOnly) {
                    queryParams.activeOnly = false;
                }

                this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

                this.loadPage();
            });
    }

    ngOnInit() {
        const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

        if (queryMap.has("search")) {
            this._search = queryMap.get("search");
            this.filters.get("search").setValue(this._search);
        }

        if (!queryMap.has("activeOnly") || queryMap.get("activeOnly") == "true") {
            this._activeOnly = true;
            this.filters.get("activeOnly").setValue(true);
        } else {
            this._activeOnly = false;
            this.filters.get("activeOnly").setValue(false);
        }

        this.filters.controls["search"].setValue(this._search);
    }

    ngOnDestroy() {
        this._filtersChangeSubscription.unsubscribe();
    }

    private loadPage(): void {
        this.questionnairesService.Questionnaires({ Page: this._page, Size: this._size, Search: this._search, ActiveOnly: this._activeOnly })
            .subscribe((result: QuestionnairesResponse): void => {
                this._questionnaires = this._questionnaires.concat(result.questionnaires);
                this._total = result.total;
            });
    }

    public onScrollDown(): void {
        this._page++;

        this.loadPage();
    }

    public openManager(questionnaire: QuestionnaireResponse): void {
        this.questionnairesService.Manage(questionnaire.id)
        .subscribe(
          (manageModel: ManageQuestionnaireModel): void => {
            const modalRef: NgbModalRef = this.modal.open(ManageQuestionnaireModalComponent, { backdrop: "static", size: "lg" });

            modalRef.componentInstance.model = manageModel;

            modalRef.result.then(
              (): void => {
                this._page = 1;
                this._questionnaires = [];

                this.loadPage();
              },
              (): void => { }
            );

          });
    }

    public get questionnaires(): QuestionnaireResponse[] { return this._questionnaires; }
    public get selected(): { [id: number]: boolean } { return this._selected; }

    public edit = (questionnaire: QuestionnaireResponse): void => {
        this.router.navigate(["settings", "questionnaires", questionnaire.id]);
    }

    public create(): void {
        this.router.navigate(["settings", "questionnaires", "new"]);
    }
}
