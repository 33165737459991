import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ReportEmployee } from '../../../../generated/models';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-employees-modal',
  templateUrl: './employees-modal.component.html',
  styleUrls: ['./employees-modal.component.scss']
})
export class EmployeesModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  private _employeesSubject: BehaviorSubject<ReportEmployee[]> = new BehaviorSubject<ReportEmployee[]>([]);
  private _selected: ReportEmployee[] = [];

  @Input() title: string;
  @Input() selected: ReportEmployee[] = [];
  @Input() employees: ReportEmployee[] = [];

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<ReportEmployee[]> = new EventEmitter<ReportEmployee[]>();

  get result(): Observable<ReportEmployee[]> { return this._employeesSubject; }

  loading = false;
  search: FormControl;

  constructor() {
    this.search = new FormControl("");

    this.search.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: string): void => {
          if (!value) {
            this._employeesSubject.next([...this.employees]);
          } else {

            const search = new RegExp(value, "i");

            const filtered: ReportEmployee[] = this.employees
              .filter((x: ReportEmployee): boolean => search.test(x.lastname) || (x.position && search.test(x.position)));

            this._employeesSubject.next(filtered);
          }
        }
      );
  }

  ngOnInit() {
    this._selected = this.selected;
    this.search.setValue("");
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  isSelected(employee: ReportEmployee): boolean {
    return this._selected.some((x: ReportEmployee): boolean => x.id === employee.id);
  }

  toggle(employee: ReportEmployee): void {
    const index: number = this._selected.findIndex((x: ReportEmployee): boolean => x.id === employee.id);

    if (index === -1) {
      this._selected.push(employee);
    } else {
      this._selected.splice(index, 1);
    }
  }

  confirm(): void {
    this.onConfirm.emit(this._selected);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
