/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ScheduleBreakReason } from '../models/schedule-break-reason';
import { CreateScheduleBreakReasonResponse } from '../models/create-schedule-break-reason-response';
import { CreateScheduleBreakReasonRequest } from '../models/create-schedule-break-reason-request';
import { UpdateScheduleBreakReasonRequest } from '../models/update-schedule-break-reason-request';
@Injectable({
  providedIn: 'root',
})
class ScheduleBreakReasonsService extends __BaseService {
  static readonly ReasonsAsyncPath = '/api/ScheduleBreakReasons';
  static readonly CreateAsyncPath = '/api/ScheduleBreakReasons';
  static readonly ReasonAsyncPath = '/api/ScheduleBreakReasons/{id}';
  static readonly UpdateAsyncPath = '/api/ScheduleBreakReasons/{id}';
  static readonly DeleteAsyncPath = '/api/ScheduleBreakReasons/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScheduleBreakReasonsService.ReasonsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  ReasonsAsyncResponse(params: ScheduleBreakReasonsService.ReasonsAsyncParams): __Observable<__StrictHttpResponse<Array<ScheduleBreakReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleBreakReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ScheduleBreakReason>>;
      })
    );
  }
  /**
   * @param params The `ScheduleBreakReasonsService.ReasonsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  ReasonsAsync(params: ScheduleBreakReasonsService.ReasonsAsyncParams): __Observable<Array<ScheduleBreakReason>> {
    return this.ReasonsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ScheduleBreakReason>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAsyncResponse(request?: CreateScheduleBreakReasonRequest): __Observable<__StrictHttpResponse<CreateScheduleBreakReasonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleBreakReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateScheduleBreakReasonResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAsync(request?: CreateScheduleBreakReasonRequest): __Observable<CreateScheduleBreakReasonResponse> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateScheduleBreakReasonResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ReasonAsyncResponse(id: number): __Observable<__StrictHttpResponse<ScheduleBreakReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleBreakReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleBreakReason>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ReasonAsync(id: number): __Observable<ScheduleBreakReason> {
    return this.ReasonAsyncResponse(id).pipe(
      __map(_r => _r.body as ScheduleBreakReason)
    );
  }

  /**
   * @param params The `ScheduleBreakReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsyncResponse(params: ScheduleBreakReasonsService.UpdateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ScheduleBreakReasons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleBreakReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsync(params: ScheduleBreakReasonsService.UpdateAsyncParams): __Observable<null> {
    return this.UpdateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleBreakReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteAsync(id: number): __Observable<null> {
    return this.DeleteAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ScheduleBreakReasonsService {

  /**
   * Parameters for ReasonsAsync
   */
  export interface ReasonsAsyncParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for UpdateAsync
   */
  export interface UpdateAsyncParams {
    id: number;
    request?: UpdateScheduleBreakReasonRequest;
  }
}

export { ScheduleBreakReasonsService }
