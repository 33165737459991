import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceGroup } from '../../../../generated/models';

@Component({
  selector: 'mp-service-group-item',
  templateUrl: './service-group-item.component.html',
  styleUrls: ['./service-group-item.component.scss']
})
export class ServiceGroupItemComponent implements OnInit {

  @Input() item: ServiceGroup = {};

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();

}
