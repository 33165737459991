import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ServiceListItem } from '../services-list/services-list.component';

@Component({
  selector: 'mp-prescripted-service-item',
  templateUrl: './prescripted-service-item.component.html',
  styleUrls: ['./prescripted-service-item.component.scss']
})
export class PrescriptedServiceItemComponent implements OnInit {

  @Input() item: ServiceListItem;

  @HostBinding('class.no-price') get noPrice() { return !this.item.grossPrice; }
  @HostBinding('class.disabled') get isDisabled() { return this.item.selected && !this.item.canStack; }

  constructor() { }

  ngOnInit() { }
}
