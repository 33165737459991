import { Component, Input, OnInit, HostListener, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup } from "@angular/forms";
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';

import { RecordsService } from "../../../../generated/services/records.service"
import { RecordSection, Icd10ResponseItem, Icd10FavoritesChangeRequestItem, Icd10FavoritesResponseItem } from 'projects/Clinic/src/app/generated/models';
import { ClinicalRecommendationsService, DiagnosesService, DiagnosisAliasesService, MyService } from '../../../../generated/services';
import { DiagnosisFilterPipePipe } from '../../pipes/diagnosis-filter-pipe.pipe';
import { DiagnosesStorageService } from "../../../../services/diagnoses-storage-service";

@Component({
  selector: "mp-diagnosis-modal",
  templateUrl: "./diagnosis-modal.component.html",
  styleUrls: ["./diagnosis-modal.component.scss"]
})

export class DiagnosisModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  groups: Icd10ResponseItem[] = [];
  all: Icd10ResponseItem[] = [];
  allowed: Icd10ResponseItem[] = [];
  favourites: Icd10ResponseItem[] = [];
  recent: Icd10ResponseItem[] = [];
  recommended: Icd10ResponseItem[] = [];

  seelectedItem: Icd10ResponseItem;
  selectedGroup: Array<Icd10ResponseItem> = new Array<Icd10ResponseItem>();

  diagnoses: Array<Icd10ResponseItem>;
  currentCollection$: Observable<Array<Icd10ResponseItem>>;
  filter: FormControl = new FormControl('');

  loading = false;
  loaded = true;
  editing = false;
  wasReordered = false;

  showAllowed = false;

  currentSection = 4;
  canSelectCustom = true;

  subject = new Subject<string>();
  searchText = '';

  stars: { [code: string]: boolean } = {};

  allowedLoading = false;
  favouritesLoading = false;
  recentLoading = false;
  groupsLoading = false;
  allLoading = false;
  recommendedLoading = false;
  isSearch = false;

  searchControl: FormControl;
  checkboxesControl: FormGroup;

  @Input() title = '';
  @Input() section: RecordSection;

  @Input() restrictions = '';

  @Input() age: number;
  @Input() gender = '';

  private _allowedSubject: BehaviorSubject<Icd10ResponseItem[]> = new BehaviorSubject<Icd10ResponseItem[]>([]);
  private _favouritesSubject: BehaviorSubject<Icd10ResponseItem[]> = new BehaviorSubject<Icd10ResponseItem[]>([]);
  private _recommendedSubject: BehaviorSubject<Icd10ResponseItem[]> = new BehaviorSubject<Icd10ResponseItem[]>([]);

  public constructor(
    private modal: NgbActiveModal,
    private recordsService: RecordsService,
    private diagnosesService: DiagnosesService,
    private recommendationsService: ClinicalRecommendationsService,
    private diagnosesStorageService: DiagnosesStorageService,
    private diagnosisAliasesService: DiagnosisAliasesService,
    private myService: MyService
  ) {
    this.searchControl = new FormControl("", []);
    this.checkboxesControl = new FormGroup({});
  }

  public ngOnInit() {
    this.allowed = [];
    this.recent = [];
    this.favourites = [];
    this.diagnoses = [];

    this.diagnoses = this.allowed;

    this.loadAllowedDiagnoses();
    this.loadFavourites();
    this.loadRecent();
    this.loadRecommended();

    this.getGroups();
    this.getAllIcd10();

    this.searchControl.valueChanges.subscribe(async (value: string) => {
      if (value.length < 2) {
        this.isSearch = false;
        return;
      } else {
        this.isSearch = true;
      }
      this.loadRecent();

      this.loading = true;

      if (!value) {
        this.diagnoses = [...this.all];
      } else {

        try {
          this.diagnoses = await this.diagnosesService.SearchAsync(value).toPromise();
        } catch (e) {
          console.error(e);
          this.diagnoses = [];
        }
      }

      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this._allowedSubject.complete();
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public searchTriggered(): void {
    this.subject.next(this.searchText);
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(this.favourites, event.previousIndex, event.currentIndex);
    moveItemInArray(this.favourites, event.previousIndex, event.currentIndex);
    this.wasReordered = true;
  }

  public cancel = (): void => {
    this.modal.dismiss();
  }

  public ok = (): void => {
    if (!!this.seelectedItem) {
      this.modal.close(this.seelectedItem);
    }
  }

  get allowed$(): Observable<Icd10ResponseItem[]> {
    return this._allowedSubject.asObservable();
  }

  get favourites$(): Observable<Icd10ResponseItem[]> {
    return this._favouritesSubject.asObservable();
  }

  get recommended$(): Observable<Icd10ResponseItem[]> {
    return this._recommendedSubject.asObservable();
  }

  get deleting(): boolean { return this.selectedGroup.length > 0; }

  get currentCollection(): Observable<Array<Icd10ResponseItem>> { return this.currentCollection$; }

  get disabled(): boolean { return !this.seelectedItem; }

  get allowedDiagnoses(): string { return this.allowed.map(x => x.code).join(','); }

  isSelected(item: Icd10ResponseItem): boolean {
    return !!item && !!this.seelectedItem && item.code === this.seelectedItem.code;
  }

  select(item: Icd10ResponseItem): void {
    if (!this.editing) {
      this.seelectedItem = item;

      this.ok();
    }
  }

  showAllowedDiagnoses(): void {
    this.showAllowed = !this.showAllowed;
  }

  isCurrentSection(num: number): boolean {
    return this.currentSection == num;
  }

  changeSection(num: number): void {
    this.editing = false;
    this.currentSection = num;
  }

  isInFavourites(item: Icd10ResponseItem): boolean {
    return this.stars[item.code];
  }

  selected(item: Icd10ResponseItem): boolean {
    return this.stars[item.code];
  }

  changeFavourites(item: Icd10ResponseItem): void {

    if (this.isInFavourites(item)) {

      const index: number = this.favourites.findIndex((x: Icd10ResponseItem): boolean => x.code === item.code);
      if (index !== -1) {
        const removing: Icd10ResponseItem = this.favourites[index];

        this.myService.RemoveFavoriteDiagnosis(removing.id)
          .subscribe(
            (): void => {
              const removedIndex: number = this.favourites.findIndex((x: Icd10ResponseItem): boolean => x.id === removing.id);

              if (removedIndex !== -1) {
                this.favourites.splice(removedIndex, 1);
              }

              this.stars[item.code] = false;
              this.checkboxesControl.removeControl(this.generateControlName(removing.id));
            }
          );
      }

    } else {
      this.myService.AddFavoriteDiagnosis({
        code: item.code
      })
        .subscribe((response: Icd10FavoritesResponseItem): void => {
          const index: number = this.favourites.findIndex((x: Icd10FavoritesResponseItem): boolean => x.id === item.id);

          if (index === -1) {
            this.favourites.unshift(response);
          }
          this.stars[item.code] = true;
          this.addFormControl(response);
        });
    }
  }

  startStopEditing(): void {
    this.editing ? this.commit() : this.startEditing();
  }

  async commit(): Promise<void> {
    this.selectedGroup = [];

    if (!this.wasReordered) {
      this.editing = false;
      return;
    }

    let requestItems: Icd10FavoritesChangeRequestItem[] = this.favourites
      .map((x: Icd10ResponseItem, index: number): Icd10FavoritesChangeRequestItem => ({
        id: x.id,
        index: index
      }));

    try {
      await this.myService.UpdateFavoriteDiagnoses({ items: requestItems }).toPromise();
    } catch (e) {
      console.error('Observer got an error: ', e);
    }

    this.editing = false;
  }

  private startEditing(): void {
    this.editing = true;
    this.seelectedItem = null;
  }

  onDiagnosisChecked(event: any, item: Icd10ResponseItem): void {
    if (!event.checked) {
      this.selectedGroup.push(item);
    }
    if (event.checked) {
      let index = this.selectedGroup.indexOf(item);
      if (index > -1) {
        this.selectedGroup.splice(index, 1);
      }
    }
  }

  async deleteFromFavourites(): Promise<void> {
    if (this.selectedGroup.length === 0) {
      return;
    }

    const remove: number[] = this.selectedGroup.map((x: Icd10ResponseItem): number => x.id);

    try {
      this.myService.RemoveManyFavoriteDiagnoses(remove).toPromise();

      this.favourites = this.favourites.filter((x: Icd10ResponseItem): boolean =>
        !this.selectedGroup.some((s: Icd10ResponseItem): boolean => s.id === x.id)
      );

      this.selectedGroup.forEach((x: Icd10ResponseItem): void => {
        this.stars[x.code] = false;
      });

      this.selectedGroup = [];
      this._favouritesSubject.next(this.favourites);
    } catch (e) {
      this.selectedGroup = [];
    }

  }

  cancelDeleting(): void {
    this.selectedGroup = [];
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.ok();
    }
  }

  //Getting data from API

  async loadAllowedDiagnoses(): Promise<void> {
    this.allowedLoading = true;

    const response = await this.recordsService.DiagnosesForSection(this.section.id).toPromise();

    this.allowed = response.items;
    this.allowedLoading = false;

    this._allowedSubject.next(this.allowed);
  }

  async getAllIcd10(): Promise<void> {
    this.allLoading = true;

    await this.diagnosesStorageService.getAllIcd10().subscribe(
      result => {
        this.all = result;
        this.allLoading = false;
      },
      () => {
        this.allLoading = false;
      });
  }

  async getGroups(): Promise<void> {
    this.groupsLoading = true;

    this.groups = await this.diagnosesService.GetDiagnosesGroups().toPromise();

    this.groupsLoading = false;
  }

  async loadFavourites(): Promise<void> {
    this.favouritesLoading = true;

    const response = await this.myService.FavoriteDiagnoses().toPromise();

    this.favourites = response.items;
    this.favourites.forEach((x: Icd10ResponseItem): void => {
      this.stars[x.code] = true;
      this.addFormControl(x);
    });

    this._favouritesSubject.next(this.favourites);

    this.favouritesLoading = false;
  }

  async loadRecent(): Promise<void> {
    this.recentLoading = true;

    const response = await this.myService.RecentDiagnoses(this.searchControl.value).toPromise();

    this.recent = response.items;
    this.recentLoading = false;
  }

  async loadRecommended(): Promise<void> {
    this.recommendedLoading = true;

    const response = await this.recommendationsService.DiagnosesAsync({ Gender: this.gender, Age: this.age }).toPromise();
    this.recommended = [...response];

    this._recommendedSubject.next(this.recommended);

    this.recommendedLoading = false;
  }

  groupIsNotEmpty(group: Icd10ResponseItem): boolean {
    return this.all.some(x => x.parentId == group.id);
  }

  getDiagnosesForGroup(group: Icd10ResponseItem): Icd10ResponseItem[] {
    var result = [];
    var listToAdd = this.all.filter(x => x.parentId === group.id);
    while (listToAdd.length > 0) {
      result.push(...listToAdd);
      var addedDiagnoses = [...listToAdd];
      listToAdd = this.all.filter(x => addedDiagnoses.some(y => y.id == x.parentId));
    }
    return result.sort((a, b) => a.code.localeCompare(b.code));
  }

  showHint(collection: Array<Icd10ResponseItem>, value: boolean): boolean {
    return !value && collection.length == 0;
  }

  generateControlName(id: number): string {
    return `chk-icd10-${id}`;
  }

  addFormControl(item: Icd10ResponseItem): void {
    let formControl: FormControl = new FormControl(false, []);
    formControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: boolean) => {
        console.log(event);
        if (event) {
          this.selectedGroup.push(item);
        }
        else {
          let index = this.selectedGroup.indexOf(item);
          if (index > -1) {
            this.selectedGroup.splice(index, 1);
          }
        }
        console.log(this.selectedGroup);
      });
    this.checkboxesControl.addControl(this.generateControlName(item.id), formControl)
  }

}
