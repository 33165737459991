import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Company, SystemSettingViewModelItem, SystemUser } from '../../../../generated/models';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mp-system-setting-edit-modal',
  templateUrl: './system-setting-edit-modal.component.html',
  styleUrls: ['./system-setting-edit-modal.component.scss']
})
export class SystemSettingEditModalComponent implements OnInit {
  title = "Настройка системы";

  @Input() setting: SystemSettingViewModelItem = {};

  @Input() companies: Company[] = [];
  @Input() users: SystemUser[] = [];

  @Input() controlType = "string";

  @Output() onCancel = new EventEmitter<void>();

  @Output() onConfirm = new EventEmitter<SystemSettingsModalPayload>();

  get isBoolean(): boolean { return this.controlType === "boolean"; }
  get yesNo(): string { return this.form.get("booleanValue").value ? "Да" : "Нет"; }

  form = new FormGroup({
    company: new FormControl(""),
    user: new FormControl(""),
    value: new FormControl("", [Validators.required]),

    booleanValue: new FormControl(false)
  });

  constructor() { }

  ngOnInit() {
    this.form.patchValue({
      company: this.setting.companyId,
      user: this.setting.userId,
      value: this.setting.value
    });

    if (this.controlType === "boolean") {
      this.form.patchValue({ booleanValue: this.setting.value === "True" });

      this.form.get('value').disable();
    }

    if (this.setting.companyId > 0 && !this.companies.some((x: Company): boolean => x.id === this.setting.companyId)) {
      this.companies.unshift({ id: this.setting.companyId, name: this.setting.companyName });
    }

    if (this.setting.userId > 0 && !this.users.some((x: SystemUser): boolean => x.id === this.setting.userId)) {
      this.users.unshift({ id: this.setting.userId, name: this.setting.userName });
    }

  }

  cancel = () => this.onCancel.emit();

  public submit() {
    this.form.get("value").markAsDirty();
    this.form.get("value").markAsTouched();

    if (this.form.invalid) return;

    const value: FormValue = this.form.getRawValue();

    let resultedValue = "";

    switch (this.controlType) {
      case "boolean": resultedValue = value.booleanValue ? "True" : "False"; break;
      default: resultedValue = value.value; break;
    }

    this.onConfirm.emit({ company: value.company, user: value.user, value: resultedValue });

  }
}

interface FormValue {
  company: number;
  user: number;
  value: string;
  booleanValue: boolean;
}

export interface SystemSettingsModalPayload {
  company: number;
  user: number;
  value: string;
}
