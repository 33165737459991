import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

import * as Centrifuge from "centrifuge";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SettingsService } from '../generated/services';
import { CentrifugoSettings } from '../generated/models';

interface Event {
  name: string;
}

@Injectable()
export class MessageReceiverService implements Resolve<any> {
  events = new Subject<Event>();

  constructor(private settingsService: SettingsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.settingsService.CentrifugeSettings()
      .subscribe(
        (settings: CentrifugoSettings): void => {
          const config: Centrifuge.Options = {
            debug: true,
            onRefresh: (ctx: any, cd: (data: any) => void): void => {
              this.settingsService.CentrifugeSettings()
                .subscribe(
                  (response: CentrifugoSettings): void => {
                    const payload: any = {
                      status: 200,
                      data: {
                        token: response.token
                      }
                    };
                    cd(payload);
                  }
                );
            }
          };

          const centrifuge: Centrifuge = new Centrifuge(settings.url, config);

          centrifuge.setToken(settings.token);

          for (let channel of settings.channels) {
            centrifuge.subscribe(channel, (message: { data: Event }): void => {
              this.events.next(message.data);
            });

          }

          centrifuge.connect();
        });

  }

  forEvent(name: string): Observable<any> {
    return this.events.pipe(filter(event => event.name === name));
  }
}
/** Cобытие входящего звонка */
export class IncomingCall {
  public phone: string;
}

/** Cобытие входящего звонка */
export interface AcceptedCall extends Event {
  phone: string;
  callId: string;
}

/** Cобытие завершенного звонка */
export interface CompletedCall extends Event {
  callId: string;
}

/** Cобытие пропущенного звонка */
export interface CancelledCall extends Event {
  callId: string;
}

/** Событие обновления посещения */
export interface VisitUpdatedEvent extends Event {
  /** Ключ посещения */
  visitId: number;
  /** Тип события */
  action: 1 | 2 | 3;
}

export interface ReceiptCreatedPayload {
  visitId: number;
  receiptTaskId: number;
  contractItems: number[];
}

export interface RefundCreatedPayload {
  visitId: number;
  receiptTaskId: number;
  contractItems: number[];
}

export interface ReceiptUpdatedPayload {
  receiptTaskId: number;
  status: 0 | 1 | 2 | 3 | 4 | 5;
  visitId: number;
}

export enum EventNames {
  /** Заявка создана*/
  OrderCreated = "order-created",
  /** Произошло изменение посещения*/
  Visit = "visit",
  /** Произошло изменение слота расписания*/
  Slot = "slot",
  /** Выставлен запрещенный диагноз*/
  ForbiddenDiagnosesEstablished = "forbidden_diagnoses_established",
  /** Звонок завершен*/
  CompletedCall = "completed_call",
  /** Звонок отменен*/
  CancelledCall = "cancelled_call",
  /** Звонок принят*/
  AcceptedCall = "accepted_call",
  /** Произошло изменение уведомлений пользователя в интерфейсе*/
  NotificationsUpdated = "notifications_updated",
  /** Отчет по входящему звонку заполнен*/
  CallReportFinished = "call_report_finished",

  /** Задание печати чека прихода создано*/
  ReceiptCreated = "receipt_created",
  /** Задание печати чека возврата создано*/
  RefundCreated = "refund_created",
  /** Задание печати чека обновлено*/
  ReceiptUpdated = "receipt_updated"
}
