import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './components/profile/profile.component';
import { Routes, RouterModule } from '@angular/router';

import { LayoutModule } from "@skytecs/layout";
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ProfileResolver } from './resolvers/profile-resolver';
import { PasswordModalComponent } from './components/password-modal/password-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

const routes: Routes = [
  {
    path: "",
    component: ProfileComponent,
    data: {
      title: "Профиль пользователя"
    },
    resolve: {
      payload: ProfileResolver
    }

  }
];

@NgModule({
  declarations: [
    ProfileComponent,
    PasswordModalComponent
  ],
  entryComponents: [
    PasswordModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NgbDropdownModule,
    NgbModalModule,
    ToastrModule,

    LayoutModule
  ],
  exports: [
    RouterModule
  ]
})
export class ProfileModule { }
