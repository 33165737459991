import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-company-sign',
  templateUrl: './company-sign.component.html',
  styleUrls: ['./company-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySignComponent implements OnInit {

  @Input() name: string;
  @Input() acronym: string;

  constructor() { }

  ngOnInit() { }

}
