import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Document } from 'projects/Clinic/src/app/generated/models';

import * as moment from 'moment';

@Component({
  selector: 'mp-anamnesis-item',
  templateUrl: './anamnesus-item.component.html',
  styleUrls: ['./anamnesus-item.component.scss']
})
export class AnamnesusItemComponent implements OnInit {

  @Input() document: Document

  @Output() onDownload = new EventEmitter<void>();
  @Output() onPrint = new EventEmitter<void>();
  @Output() onRemove = new EventEmitter<void>();

  @Output() onOpen = new EventEmitter<void>();

  get date(): string {
    return this.document ? moment(this.document.date).format('DD.MM.YYYY HH:mm') : '';
  }

  constructor() { }

  ngOnInit() { }

  download = () => this.onDownload.emit();
  remove = () => this.onRemove.emit();
  print = () => this.onPrint.emit();
  open = () => this.onOpen.emit();
}
