import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserStorage } from '../services/user-storage';
import { PermissionNames } from '../models/permission-names';
import { take, tap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Profile } from '../generated/models';

@Injectable({
  providedIn: 'root'
})
export class MainRedirectGuard implements CanActivate {

  constructor(
    private userStorage: UserStorage,
    private router: Router
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.userStorage.profileSource
      .pipe(
        take(1),
        tap(
          (profile: Profile): void => {

            console.debug("RedirectGuard: checking permissions...");

            console.debug("RedirectGuard: checking visits permission...");
            if (this.userStorage.hasPermission(PermissionNames.ViewVisits)) {
              console.debug("RedirectGuard: visits permission checked. Access granted. Redirecting...");
              this.router.navigate(["/visits"]);
              return;
            }
            console.debug("RedirectGuard: access denied...");
            console.debug("RedirectGuard: checking workspace permission...");

            if (this.userStorage.hasPermission(PermissionNames.ViewWorkspace)) {
              console.debug("RedirectGuard: workspace permission checked. Access granted. Redirecting...");
              this.router.navigate(["/workspace"]);
              return;
            }
            console.debug("RedirectGuard: access denied...");
            console.debug("RedirectGuard: checking review records permission...");

            if (this.userStorage.hasPermission(PermissionNames.ReviewRecords)) {
              console.debug("RedirectGuard: review records permission checked. Access granted. Redirecting...");
              this.router.navigate(["/quality-control"]);
              return;
            }

            console.debug("RedirectGuard: access denied...");
            console.debug("RedirectGuard: checking schedules permission...");

            if (this.userStorage.hasPermission(PermissionNames.ViewSchedules)) {
              console.debug("RedirectGuard: schedules permission checked. Access granted. Redirecting...");
              this.router.navigate(["/schedules/week"]);
              return;
            }

            console.debug("RedirectGuard: access denied...");
            console.debug("RedirectGuard: checking reports permission...");
            if (this.userStorage.hasPermission(PermissionNames.ViewReports)) {
              console.debug("RedirectGuard: reports permission checked. Access granted. Redirecting...");
              this.router.navigate(["/reports"]);
              return;
            }
            console.debug("RedirectGuard: access denied...");

            console.debug("RedirectGuard: checking results permission...");
            if (this.userStorage.hasPermission(PermissionNames.ViewResults)) {
              console.debug("RedirectGuard: reports permission checked. Access granted. Redirecting...");
              this.router.navigate(["/observations"]);
              return;
            }
            console.debug("RedirectGuard: access denied...");

            console.debug("RedirectGuard: logging out...");

            this.router.navigate(["/signout"]);
            return;
          }
        ),
        map((): boolean => false)
      );
  }

}
