import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbsenceReason, Attendance, AttendanceCallReport } from '../../../../generated/models';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbsenceReasonsService } from '../../../../generated/services';

@Component({
  selector: 'mp-call-modal',
  templateUrl: './call-modal.component.html',
  styleUrls: ['./call-modal.component.scss']
})
export class CallModalComponent implements OnInit {

  @Input() report: CallResult;

  @Input() left: CallInfo;
  @Input() right: CallInfo;
  @Input() phone = '';
  @Input() patientName = '';
  @Input() loading = false;

  @Output() onCancel = new EventEmitter<void>();

  @Output() onConfirm = new EventEmitter<CallResult>();

  form = new FormGroup({
    outcome: new FormControl(undefined, [Validators.required]),
    comment: new FormControl(''),
    reasons: new FormControl([], [])
  });

  reasons: AbsenceReason[] = [];

  reasonsLoading = false;

  constructor(private absenceReasonsService: AbsenceReasonsService) { }

  async ngOnInit() {

    if (this.report) {
      this.form.patchValue({ ...this.report });
    } else {
      this.form.patchValue({
        outcome: 6,
        reasons: [],
        comment: ''
      });
    }

    this.reasonsLoading = true;
    this.reasons = await this.absenceReasonsService.ReasonsAsync().toPromise();
    this.reasonsLoading = false;
  }

  get canSave(): boolean { return this.form.valid; }

  get showReasons(): boolean { return this.form.getRawValue().outcome === 6; }

  select = (outcome: 1 | 2 | 3 | 4 | 5 | 6) => this.form.patchValue({ outcome });

  selected = (outcome: 1 | 2 | 3 | 4 | 5 | 6) => this.form.getRawValue().outcome === outcome;

  reasonSelected = (reason: AbsenceReason) => (this.form.getRawValue().reasons || []).some(x => x.id === reason.id);

  toggleReason(reason: AbsenceReason): void {
    const value: CallResult = this.form.getRawValue();

    if (value.reasons.some(x => x.id === reason.id)) {
      value.reasons = value.reasons.filter(x => x.id !== reason.id);
    } else {
      value.reasons = [...value.reasons, reason];
    }

    this.form.patchValue(value);
  }

  cancel = () => this.onCancel.emit();

  confirm(): void {
    if (this.loading) return;

    if (!this.form.value) return;
    this.loading = true;
    this.onConfirm.emit(this.form.getRawValue());
  }
}

export interface CallResult {
  outcome: 1 | 2 | 3 | 4 | 5 | 6;
  comment: string;
  reasons: AbsenceReason[];
}

export interface CallInfo {
  title: string;
  items: string[];
  empty: string;
  isEmpty?: boolean;
}
