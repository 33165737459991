import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ScheduleBreakReason } from '../../../../generated/models';

@Component({
  selector: 'mp-add-break-modal',
  templateUrl: './add-break-modal.component.html',
  styleUrls: ['./add-break-modal.component.scss']
})
export class AddBreakModalComponent implements OnInit {

  @Input() reasons: ScheduleBreakReason[] = [];
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onConfirm: EventEmitter<ScheduleBreakReason> = new EventEmitter<ScheduleBreakReason>();

  get disabled(): boolean { return !this.selected; }

  selected: ScheduleBreakReason;

  constructor() { }

  ngOnInit() { }

  cancel(): void {
    this.onCancel.emit();
  }

  confirm(): void {
    if (!this.selected) return;

    this.onConfirm.emit(this.selected);
  }

  select(item: ScheduleBreakReason): void {
    this.selected = item;
  }

  isSelected(item: ScheduleBreakReason): boolean {
    return this.selected && item && this.selected.id === item.id;
  }
}
