import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mp-slot-actions',
  templateUrl: './slot-actions.component.html',
  styleUrls: ['./slot-actions.component.scss']
})
export class SlotActionsComponent implements OnInit {

  @Input() loading = false;
  @Input() editing = false;
  @Input() slotsSelected = false;

  @Input() canManageBreaks = false;

  @Output() onExportPatient = new EventEmitter<void>();
  @Output() onAddBreak = new EventEmitter<void>();
  @Output() onRemoveAppointment = new EventEmitter<void>();
  @Output() onNotifyPatient = new EventEmitter<void>();

  get patientExportDisabled(): boolean { return !this.editing || this.loading; }
  get breakAddingDisabled(): boolean { return !this.canManageBreaks || this.editing || !this.slotsSelected || this.loading; }
  get appointmentRemovalDisabled(): boolean { return !this.editing || this.loading; }
  get patientNotificationDisabled(): boolean { return !this.editing || this.loading; }

  constructor() { }

  ngOnInit() { }

  exportPatient(): void {
    if (this.patientExportDisabled) {
      return;
    }
    this.onExportPatient.emit();
  }

  addBreak(): void {
    if (this.breakAddingDisabled) {
      return;
    }
    this.onAddBreak.emit();
  }

  removeAppointment(): void {
    if (this.appointmentRemovalDisabled) {
      return;
    }
    this.onRemoveAppointment.emit();
  }

  notifyPatient(): void {
    if (this.patientNotificationDisabled) {
      return;
    }
    this.onNotifyPatient.emit();
  }
}
