import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import * as moment from "moment";

import { VisitsService, CashboxService, BarcodePrinterService, VisitReportsService, PatientsService, ExternalConnectionsService, MyService, InsuranceService, PeopleService, PersonMarksService, ColorMarksService } from 'projects/Clinic/src/app/generated/services';
import { CheckPatientsResponse, ColorMark, ColorMarksResponse, PersonInfo, PersonMark, Profile, Visit, VisitsResponse } from 'projects/Clinic/src/app/generated/models';
import { Subject, Observable, from } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EventNames, MessageReceiverService, ReceiptUpdatedPayload, VisitUpdatedEvent } from '../../../../services/message.receiver.service';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { takeUntil, tap, switchMap, map, catchError, filter } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ReceiptParametersService } from '../../../correction-receipt/services/receipt-parameters.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FiscalPrinterModalComponent } from '../fiscal-printer-modal/fiscal-printer-modal.component';
import { BalanceManagementModalComponent } from '../../../balance-management/components/balance-management-modal/balance-management-modal.component';
import { TaxDeductionsModalComponent } from '../../../tax-deductions/components/tax-deductions-modal/tax-deductions-modal.component';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { PersonMarkModalComponent, PersonMarkModalPayload } from '../../../person-marks/components/person-mark-modal/person-mark-modal.component';

@Component({
  selector: 'mp-visits',
  templateUrl: './visits.component.html',
  styleUrls: ['./visits.component.scss'],
  host: { class: "page" }
})
export class VisitsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = false;
  printingLabels = false;
  creatingLabels = false;

  colorMarks: ColorMark[] = [];

  visits: Visit[] = [];
  page = 1;
  size = 50;
  /** Проблемные пациенты*/
  invalidPatients = new Set<number>();

  title = "Список посещений";

  searchForm = new FormGroup({
    search: new FormControl(""),
    range: new FormControl()
  });

  showCreateBtn = false;
  showManagePersonsBtn = false;
  showManageBalanceBtn = false;
  showCashboxBtns = false;

  barcodesAutoprinting = false;
  showVisitCompany = false;

  fiscalPrinterName = "Касса";

  canViewWorkspace = false;
  canManageBalance = false;
  canManageTaxDeductions = false;

  canAddMark = false;
  canManageMarks = false;

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: "RUB",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  get showShortSearchDisclaimer(): boolean {
    const value: SearchValue = this.searchForm.getRawValue();

    return !this.loading && value.search && value.search.length > 0 && value.search.length < 3;
  }

  get showEmptyResultDisclaimer(): boolean {
    const value: SearchValue = this.searchForm.getRawValue();

    return !this.loading && value.search && value.search.length > 3 && this.visits.length === 0;
  }

  constructor(
    private visitsService: VisitsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    messageReceiverService: MessageReceiverService,
    private userStorage: UserStorage,
    private cashboxService: CashboxService,
    private receiptParametersService: ReceiptParametersService,
    private barcodePrintingService: BarcodePrinterService,
    private visitReportsService: VisitReportsService,
    private patientsService: PatientsService,
    private externalConnectionsService: ExternalConnectionsService,
    private myService: MyService,
    private modal: NgbModal,
    private insuranceService: InsuranceService,
    private peopleService: PeopleService,
    private personMarksService: PersonMarksService,
    private confirmationService: ConfirmationModalService,
    private colorMarksService: ColorMarksService
  ) {

    this.userStorage.profileSource
      .pipe(takeUntil(this.destroy$))
      .subscribe((profile: Profile): void => {
        this.fiscalPrinterName = `Касса ${profile.cashboxName || ''}`.trim();

        this.barcodesAutoprinting = profile.barcodesAutoprinting;
        this.showVisitCompany = profile.showVisitCompany;

        this.showManageBalanceBtn = profile.enableBalanceOperations;

        this.canViewWorkspace = this.userStorage.hasPermission(PermissionNames.ViewWorkspace);
        this.canManageBalance = this.userStorage.hasPermission(PermissionNames.OperateOnPatientBalance);
        this.canManageTaxDeductions = this.userStorage.hasPermission(PermissionNames.ManageTaxDeductions);

        this.canAddMark = this.userStorage.hasPermission(PermissionNames.AddPersonMarks);
        this.canManageMarks = this.userStorage.hasPermission(PermissionNames.ManagePersonMarks);
      });

    messageReceiverService.forEvent(EventNames.OrderCreated)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: { visitId: number }) => this.visits.filter(x => x.id === event.visitId).forEach(x => x.hasOrder = true));

    messageReceiverService.forEvent(EventNames.Visit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: VisitUpdatedEvent): void => {

        if (event.action === 3) {
          const index: number = this.visits.findIndex((x: Visit): boolean => x.id === event.visitId);

          if (index !== -1) {
            this.visits.splice(index, 1);
            return;
          }
        }

        const from: moment.Moment = this.searchForm.get("range").value.from;
        const to: moment.Moment = this.searchForm.get("range").value.to;

        if (event.action === 1 || event.action === 2) {
          this.visitsService.Visits({
            Page: this.page,
            Size: this.size,
            Search: this.searchForm.get("search").value,
            From: from && from.isValid() ? from.format("DD.MM.YYYY") : "",
            To: to && to.isValid() ? to.format("DD.MM.YYYY") : "",
            Ids: [event.visitId]
          })
            .subscribe(
              (response: VisitsResponse): void => {
                const existingIndex: number = this.visits.findIndex((x: Visit): boolean => x.id === event.visitId);

                switch (event.action) {
                  // created
                  case 1: {
                    // if there is no visit with given id - add one
                    if (existingIndex === -1 && response.items.length === 1) {
                      this.visits.unshift(response.items[0]);
                    }

                  }
                    break;
                  // update
                  case 2: {

                    // remove what shouldn't be in the list
                    if (response.items.length === 0 && existingIndex !== -1) {
                      this.visits.splice(existingIndex, 1);
                    }

                    // add what wasn't added
                    if (response.items.length === 1 && existingIndex === -1) {
                      this.visits.unshift(response.items[0]);
                    }

                    // update what should be updated
                    if (response.items.length === 1 && existingIndex !== -1) {
                      this.visits[existingIndex] = response.items[0];
                    }

                  }

                }

              });
        }
      });

    messageReceiverService.forEvent(EventNames.ForbiddenDiagnosesEstablished)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: ForbiddenDiagnosisEstablishedEvent): void => {
          if (data.diagnoses.length === 1) {
            this.toastrService.warning(`Пациенту ${data.patientName} выставлен диагноз, запрещённый программой ДМС.`, 'Пациент без оплаты', { timeOut: 0 });
          }

          this.invalidPatients.add(data.patientId);
        }
      );

    messageReceiverService.forEvent(EventNames.ReceiptUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: ReceiptUpdatedPayload) => {
        if (!event.visitId) return;

        const from: moment.Moment = this.searchForm.get("range").value.from;
        const to: moment.Moment = this.searchForm.get("range").value.to;

        this.visitsService.Visits({
          Page: this.page,
          Size: this.size,
          Search: this.searchForm.get("search").value,
          From: from && from.isValid() ? from.format("DD.MM.YYYY") : "",
          To: to && to.isValid() ? to.format("DD.MM.YYYY") : "",
          Ids: [event.visitId]
        }).subscribe(response => {
          const existingIndex = this.visits.findIndex(x => x.id === event.visitId);

          // remove what shouldn't be in the list
          if (response.items.length === 0 && existingIndex !== -1) {
            this.visits.splice(existingIndex, 1);
          }

          // add what wasn't added
          if (response.items.length === 1 && existingIndex === -1) {
            this.visits.unshift(response.items[0]);
          }

          // update what should be updated
          if (response.items.length === 1 && existingIndex !== -1) {
            this.visits[existingIndex] = response.items[0];
          }
        });

      });

    this.searchForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(() => !this.loading),
        tap((value: SearchValue): void => {
          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          if (value.range.from && value.range.from.isValid()) {
            queryParams.from = value.range.from.format("DD.MM.YYYY");
          }

          if (value.range.to && value.range.to.isValid()) {
            queryParams.to = value.range.to.format("DD.MM.YYYY");
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
        switchMap((value: SearchValue): Observable<VisitsResponse> => {
          this.page = 1;
          this.visits = [];
          this.loading = true;

          if (value.search && value.search.length < 3) {

            return from([{
              items: [],
              page: 1,
              size: this.size,
              total: 0
            }]);
          }

          return this.visitsService.Visits({
            Search: value.search,
            From: !!value.range.from && value.range.from.isValid() ? value.range.from.format("DD.MM.YYYY") : "",
            To: !!value.range.to && value.range.to.isValid() ? value.range.to.format("DD.MM.YYYY") : "",
            Page: 1,
            Size: this.size
          })
            .pipe(
              catchError((): Observable<VisitsResponse> => {
                toastrService.error("Не удалось загрузить посещения", "Ошибка");

                const fakeResponse: VisitsResponse = {
                  items: [],
                  page: 1,
                  size: this.size,
                  total: 0
                };

                return from([fakeResponse]);
              })
            );
        }),
        map((response: VisitsResponse): Visit[] => response.items)
      )
      .subscribe(
        (visits: Visit[]): void => {
          this.visits = visits;
          this.loading = false;

          const dmsPatients = visits.filter(x => x.paymentType === 3 && !this.invalidPatients.has(x.patientId));

          if (dmsPatients.length > 0) {
            this.insuranceService.CheckPatientsAsync({ ids: dmsPatients.map(x => x.patientId) })
              .subscribe((response: CheckPatientsResponse): void => {
                for (const patient of response.patients.filter(x => x.status === 1)) {
                  this.invalidPatients.add(patient.id);
                }
              });
          }
        },
        () => this.loading = false
      );

    this.showCreateBtn = userStorage.hasPermission(PermissionNames.CreateVisit);
    this.showManagePersonsBtn = userStorage.hasPermission(PermissionNames.EditPersonalData);
    this.showCashboxBtns = userStorage.hasPermission(PermissionNames.ViewCashbox);
  }

  ngOnInit() {
    const queryMap = this.activatedRoute.snapshot.queryParamMap;

    const value: SearchValue = {
      range: {
        from: moment().startOf("day"),
        to: moment()
      },
      search: queryMap.get("search")
    };


    if (queryMap.has("from")) {
      const from: moment.Moment = moment(queryMap.get("from"), "DD.MM.YYYY");
      if (from && from.isValid()) {
        value.range.from = from;
      }
    }

    if (queryMap.has("to")) {
      const to: moment.Moment = moment(queryMap.get("to"), "DD.MM.YYYY");
      if (to && to.isValid()) {
        value.range.to = to;
      }
    }

    this.searchForm.patchValue(value);

    this.colorMarksService.ColorMarks({ Page: 1, Size: 100 }).subscribe(
      (response: ColorMarksResponse) => {
        this.colorMarks = response.items;
      }
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScrollDown(): void {
    if (this.loading) return;

    this.page++;
    this.loading = true;

    this.load();
  }

  abbr(visit: Visit): string {
    if (visit.companyAbbr) return visit.companyAbbr;
    if (!visit.companyName) return "";

    return visit.companyName.split(" ").filter(x => !!x).map(x => x[0]).join("").toUpperCase();
  }

  primaryTitle(visit: Visit): string {
    if (visit.id > 0) return visit.primary ? 'Первичное' : 'Повторное';
    return '';
  }

  total = (visit: Visit) => visit.total.toLocaleString('ru-RU', this.currencyFormat);
  hasDebt = (visit: Visit) => visit.debt > 0;
  debt = (visit: Visit) => visit.debt > 0 ? visit.debt.toLocaleString('ru-RU', this.currencyFormat) : "";

  phone(visit: Visit): string {
    if (visit.phone && visit.phone.replace(/\D/g, "").length === 11) {
      const escaped: string = visit.phone.replace(/\D/g, "");

      return `${escaped.startsWith("7") ? "+7" : escaped[0]} (${escaped.substr(1, 3)}) ${escaped.substr(4, 3)}-${escaped.substr(7, 2)}-${escaped.substr(9, 2)}`;
    }
    return visit.phone;

  }

  open(visit: Visit): void {
    if (visit.id > 0) this.router.navigate(["visits", visit.id]);
  }

  create = () => this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  createFor = (visit: Visit) => this.router.navigate(["new"], { relativeTo: this.activatedRoute, queryParams: { patientId: visit.patientId, primary: false, parentId: visit.id } });

  createPrimaryFor(visit: Visit): void {
    if (visit.type === 2) {
      this.router.navigate(["new"], { relativeTo: this.activatedRoute, queryParams: { personId: visit.personId, parentId: visit.id } });
      return;
    }

    this.router.navigate(["new"], { relativeTo: this.activatedRoute, queryParams: { patientId: visit.patientId, parentId: visit.id } });
  }

  schedule(visit: Visit): void {
    let url = `${location.origin}/schedules/week/${moment().format("YYYY-MM-DD")}?patientId=${visit.patientId}`;

    if (visit.type === 2) {
      url = `${location.origin}/schedules/week/${moment().format("YYYY-MM-DD")}?personId=${visit.personId}`;
    }

    window.open(url, "_blank");
  }

  editPerson = (visit: Visit) => window.open(`${location.origin}/call-center/patients/${visit.personId}`, "_blank");

  openCard = (visit: Visit) => window.open(`${location.origin}/workspace/medicalfiles/${visit.contractItemId}`, "_blank");

  private load(): void {
    const from: moment.Moment = this.searchForm.get("range").value.from;
    const to: moment.Moment = this.searchForm.get("range").value.to;

    this.visitsService.Visits({
      Page: this.page,
      Size: this.size,
      Search: this.searchForm.get("search").value,
      From: from && from.isValid() ? from.format("DD.MM.YYYY") : "",
      To: to && to.isValid() ? to.format("DD.MM.YYYY") : ""
    })
      .subscribe(
        (response: VisitsResponse): void => {
          this.visits.push(...response.items);
          this.loading = false;

          const dmsPatients = response.items.filter(x => x.paymentType === 3 && !this.invalidPatients.has(x.patientId));

          if (dmsPatients.length > 0) {
            this.insuranceService.CheckPatientsAsync({ ids: dmsPatients.map(x => x.patientId) })
              .subscribe((response: CheckPatientsResponse): void => {
                for (const patient of response.patients.filter(x => x.status === 1)) {
                  this.invalidPatients.add(patient.id);
                }
              });
          }
        }
      );
  }

  async printContract(visit: Visit): Promise<void> {
    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintVisitContract({ type: 'pdf', visitId: visit.id, asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (e) {
      this.toastrService.warning("Не удалось напечатать договор", "Ошибка");
    }
  }

  async printInvoice(visit: Visit): Promise<void> {
    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintInvoiceReportsGroup({ type: 'pdf', visitId: visit.id, asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (e) {
      this.toastrService.warning("Не удалось напечатать счет", "Ошибка");
    }
  }

  async printAgreement(visit: Visit): Promise<void> {
    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.visitReportsService.PrintAgreementReportGroup({ type: 'pdf', visitId: visit.id, asCopy: true }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (e) {
      if (e instanceof HttpErrorResponse) {
        const httpErrorResponse = e as HttpErrorResponse;

        if (httpErrorResponse.status === 400) {
          this.toastrService.warning("Нет документов для печати", "Печать")
        } else {
          this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
        }
      } else {
        this.toastrService.warning("Не удалось напечатать согласие", "Ошибка");
      }
    }
  }

  async printCover(visit: Visit): Promise<void> {
    try {
      this.toastrService.success("Документ формируется...", "Печать");
      const data = await this.patientsService.PatientsCover({ id: visit.patientId, type: 'pdf' }).toPromise();
      this.printReport(data.content, data.reportType);
    }
    catch (e) {
      this.toastrService.warning("Не удалось напечатать амбулаторную карту", "Ошибка");
    }
  }

  createLabels(visit: Visit): void {
    if (this.creatingLabels) return;

    this.creatingLabels = true;

    this.visitsService.CreateOrders(visit.id)
      .subscribe(
        (): void => {
          this.creatingLabels = false;

          if (this.barcodesAutoprinting) {
            this.toastrService.success("Передача на печать", "Заявки созданы");

            this.barcodePrintingService.BarcodesForVisit(visit.id)
              .subscribe(
                () => this.toastrService.success(`Используется принтер ${this.userStorage.profile.barcodePrinterName}`, 'Печать этикеток начата'),
                (response: HttpErrorResponse): void => {
                  if (response.status === 400) {
                    this.toastrService.warning(response.error.message, 'Ошибка');
                    return;
                  }

                  this.toastrService.warning('Не удалось напечатать этикетки', 'Ошибка');
                }
              );

            return;
          }
          this.toastrService.success("Заявки созданы", "Успешно");
        },
        (response: HttpErrorResponse): void => {
          this.creatingLabels = false;

          if (response.status === 500) {
            this.toastrService.error("Не удалось сформировать заявки", "Ошибка");
            return;
          }

          if (response.status === 400) {
            for (const error of response.error.errors) {
              this.toastrService.warning(error.message, "Заявки не сформированы");
            }
          }

        }
      );
  }

  printCorrectionReceipt = () => this.receiptParametersService.showModal();

  printXReport(): void {
    this.cashboxService.CreateXReportTask()
      .subscribe(
        () => this.toastrService.success("Х отчет", "Запрос отправлен"),
        (error: HttpErrorResponse): void => {
          if (error.status === 400) {
            this.toastrService.error(error.error.description, "Ошибка");
          }
          else {
            this.toastrService.error("Ошибка при печати", "Ошибка");
          }
        });
  }

  closeShift(): void {
    this.cashboxService.CreateCloseShiftTask()
      .subscribe(
        () => this.toastrService.success("Закрытие смены", "Запрос отправлен"),
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            if (response.error.errors && response.error.errors.length > 0) {
              for (const error of response.error.errors) {
                this.toastrService.error(error.message, "Ошибка");
              }
            } else {
              this.toastrService.error("Ошибка при открытии смены", "Ошибка");
            }
          }
          else {
            this.toastrService.error("Ошибка при открытии смены", "Ошибка");
          }
        }
      );
  }

  openShift(): void {
    this.cashboxService.CreateOpenShiftTask()
      .subscribe(
        () => this.toastrService.success("Открытие смены", "Запрос отправлен"),
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            if (response.error.errors && response.error.errors.length > 0) {
              for (const error of response.error.errors) {
                this.toastrService.error(error.message, "Ошибка");
              }
            } else {
              this.toastrService.error("Ошибка при открытии смены", "Ошибка");
            }
          }
          else {
            this.toastrService.error("Ошибка при открытии смены", "Ошибка");
          }
        }
      );
  }

  printLabels(visit: Visit): void {
    if (this.printingLabels) return;

    this.printingLabels = true;
    this.barcodePrintingService.BarcodesForVisit(visit.id)
      .subscribe(
        (): void => {
          this.printingLabels = false;
          this.toastrService.success(`Используется принтер ${this.userStorage.profile.barcodePrinterName}`, 'Печать этикеток начата');
        },
        (response: HttpErrorResponse): void => {
          this.printingLabels = false;

          if (response.status === 400) {
            this.toastrService.warning(response.error.message, 'Ошибка');
            return;
          }

          this.toastrService.warning('Не удалось напечатать этикетки', 'Ошибка');
        }
      );
  }

  changeFiscalPrinter(): void {
    const options: NgbModalOptions = { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" };

    const modalRef: NgbModalRef = this.modal.open(FiscalPrinterModalComponent, options);
    const componentRef: FiscalPrinterModalComponent = modalRef.componentInstance;

    componentRef.devices$ = this.externalConnectionsService.Cashboxes(this.userStorage.profile.companyId);
    componentRef.fiscalPrinterId = this.userStorage.profile.cashboxId;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((fiscalPrinterId: number): void => {
      this.myService.ChangeFiscalPrinter({ fiscalPrinterId: fiscalPrinterId })
        .subscribe(
          (): void => {
            this.userStorage.load().subscribe();
            modalRef.close();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, 'Ошибка');
            } else {
              this.toastrService.error('Не удалось изменить кассовый аппарат', 'Ошибка');
            }
          }
        )
    });

  }

  private printReport(content: string, type: string) {
    const byteCharacters = atob(content);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const url = URL.createObjectURL(new Blob(byteArrays, { type: type }));
    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = url;
    frame.onload = () => URL.revokeObjectURL(url);

    document.body.append(frame);
    frame.contentWindow.print();
  }


  manageBalance(visit: Visit): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true, size: 'lg' };

    this.peopleService.PersonAsync(visit.parentId > 0 ? visit.parentId : visit.personId)
      .subscribe(
        (response: PersonInfo) => {
          const modalRef = this.modal.open(BalanceManagementModalComponent, options);
          const componentRef: BalanceManagementModalComponent = modalRef.componentInstance;

          componentRef.personId = response.id;
          componentRef.patientName = [response.lastname, response.firstname, response.middlename].filter(x => x).join(' ');
          componentRef.cashboxName = this.userStorage.profile.cashboxName;
          componentRef.isRep = visit.parentId > 0;

          componentRef.onCancel.subscribe(() => modalRef.close());
        },
        () => {
          this.toastrService.error('Не удалось загрузить баланс', 'Ошибка');
        }
      );


  }

  manageDeductions(visit: Visit): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true, size: 'lg' };
    const modalRef = this.modal.open(TaxDeductionsModalComponent, options);
    const componentRef: TaxDeductionsModalComponent = modalRef.componentInstance;

    componentRef.patientId = visit.patientId;
    componentRef.personId = visit.personId;
    componentRef.patientName = visit.patientName;
    componentRef.patientInn = visit.inn;

    componentRef.onCancel.subscribe(() => modalRef.close());
  }

  canRemoveMark = (mark: PersonMark) => this.canManageMarks || (this.canAddMark && mark.authorId == this.userStorage.profile.id);

  loadMarks(personId: number) {
    this.personMarksService.PersonMarksAsync(personId)
      .subscribe(
        (response: PersonMark[]) => {
          for (const visit of this.visits.filter(x => x.personId === personId)) {
            visit.marks = response;
          }
        }
      );
  }

  addMark(visit: Visit) {
    const modalRef = this.modal.open(PersonMarkModalComponent, { centered: true, backdrop: 'static' });
    const componentRef: PersonMarkModalComponent = modalRef.componentInstance;

    componentRef.colorMarks = this.colorMarks;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((value: PersonMarkModalPayload) => {
      componentRef.processing = true;

      this.personMarksService.CreatePersonMarkAsync({ personId: visit.personId, request: { colorMarkId: value.colorMarkId, description: value.description } })
        .subscribe(
          () => {
            this.toastrService.success('Метка добавлена', 'Успешно');
            componentRef.processing = false;
            modalRef.close();

            this.loadMarks(visit.personId);
          },
          (response: HttpErrorResponse) => {
            componentRef.processing = false;
            this.handleColorMarkError(response, 'Не удалось добавить метку');
          }
        );


    });

  }

  async removeMark(mark: PersonMark) {
    const confirmed = await this.confirmationService.open({ message: `Метка ${mark.colorMarkName} пациента ${mark.personName} будет удалена. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.personMarksService.RemovePersonMarkAsync({ personId: mark.personId, id: mark.id })
      .subscribe(
        () => {
          this.toastrService.success(`Метка ${mark.colorMarkName} удалена`, 'Успешно');

          this.loadMarks(mark.personId);
        },
        (response: HttpErrorResponse) => {
          this.handleColorMarkError(response, 'Не удалось удалить метку');
        }
      );

  }

  handleColorMarkError(response: HttpErrorResponse, message = '') {
    if (response.status === 400 && response.error) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Требуется разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Метка не найдена', 'Ошибка'); break;
          case 2: this.toastrService.warning('Метка пациента не найдена', 'Ошибка'); break;
          case 3: this.toastrService.warning('Пациент не найден', 'Ошибка'); break;
          case 4: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 5: this.toastrService.warning('Указанная метка уже добавлена', 'Ошибка'); break;
          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }
    } else {
      this.toastrService.error(message, 'Ошибка');
    }
  }

}

interface SearchValue {
  search: string;
  range: {
    from: moment.Moment;
    to: moment.Moment;
  };
}

interface ForbiddenDiagnosisEstablishedEvent {
  patientId: number;
  patientName: string;
  diagnoses: string[];
}
