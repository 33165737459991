import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../../../generated/services';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfilePayload } from '../../resolvers/profile-resolver';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PasswordModalComponent, Passwords } from '../password-modal/password-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserStorage } from '../../../../services/user-storage';

@Component({
  selector: 'mp-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  private _lastname: string;
  private _firstname: string;
  private _middlename: string;

  private _phone: string;
  private _email: string;

  public get name(): string {
    return [this._lastname, this._firstname, this._middlename].join(" ");
  }

  public get phone(): string {
    if (!this._phone) {
      return "";
    }

    if (this._phone.length === 10) {
      return `+7(${this._phone.substr(0, 3)})${this._phone.substr(3, 3)}-${this._phone.substr(6, 2)}-${this._phone.substr(8, 2)}`;
    }

    if (this._phone.length === 11) {
      return `+7(${this._phone.substr(1, 3)})${this._phone.substr(4, 3)}-${this._phone.substr(7, 2)}-${this._phone.substr(9, 2)}`;
    }

    return this._phone;


  }

  public get helpPageHref(): string { return this.userStorage.profile.calendarSyncHelpPageUrl; }

  public get email(): string { return this._email; }

  constructor(
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private toastrService: ToastrService,
    private profileService: ProfileService,
    private userStorage: UserStorage
  ) {

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: ProfilePayload }): void => {

      this._lastname = data.payload.profile.lastname;
      this._firstname = data.payload.profile.firstname;
      this._middlename = data.payload.profile.middlename;

      this._email = data.payload.profile.email;
      this._phone = data.payload.profile.phone;
    });
  }

  public openPasswordModal(): void {

    const options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      windowClass: "modal-small"
    };

    const modalRef: NgbModalRef = this.modal.open(PasswordModalComponent, options);

    modalRef.componentInstance.onSubmit
      .subscribe(
        (value: Passwords): void => {
          this.profileService.ChangePassword({ oldPassword: value.oldPassword, newPassword: value.newPassword })
            .subscribe(
              (): void => {
                this.toastrService.success("Ваш пароль изменен", "Успешно");

                modalRef.dismiss();
              },
              (response: HttpErrorResponse): void => {
                if (response.status === 400) {

                  if (response.error.status === 3) {
                    modalRef.componentInstance.oldPassword.setErrors({ required: true });
                    return;
                  }

                  if (response.error.status === 4) {
                    modalRef.componentInstance.newPassword.setErrors({ required: true });
                    return;
                  }

                  if (response.error.status === 5) {
                    modalRef.componentInstance.oldPassword.setErrors({ invalid: true });
                    return;
                  }

                  this.toastrService.warning(response.error.message, "Ошибка");
                  modalRef.dismiss();
                  return;
                }

                this.toastrService.error("Не удалось сменить пароль", "Ошибка");
                modalRef.dismiss();
              }
            );
        }
      );
  }

  public send(system: 1 | 2, delivery: 1 | 2): void {
    this.profileService.SendScheduleLink({ Delivery: delivery, System: system })
      .subscribe(
        (): void => {
          this.toastrService.success("Ссылка для синхронизации расписания отправлена", "Успешно");
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }

          this.toastrService.error("не удалось отправить ссылку", "Ошибка");
        }
      )


  }
}
