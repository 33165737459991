import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { PatientSearchResult, PatientSearchResponse } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { PatientsService } from '../../../../generated/services';
import { takeUntil, debounceTime, tap, switchMap, map } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'mp-representative-select-modal',
  templateUrl: './representative-select-modal.component.html',
  styleUrls: ['./representative-select-modal.component.scss'],
  host: {
    class: "modal-default"
  }
})
export class RepresentativeSelectModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() selectedPatientId: number;

  @Output() onClose = new EventEmitter<void>();
  @Output() onSelect = new EventEmitter<PatientSearchResult>();

  patients: PatientSearchResult[] = [];

  selectedClientId: number;
  selectedClient: PatientSearchResult;

  loading = false;
  page = 1;
  size = 50;

  get disabled(): boolean { return !this.selectedClientId; }
  get selectedPatient(): PatientSearchResult { return this.selectedClient; }

  searchControl = new FormControl("");

  constructor(private patientsService: PatientsService) {

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap(() => this.page = 1),
        tap(() => this.loading = true),
        tap(() => this.patients = []),
        switchMap((value: string): Observable<PatientSearchResponse> => {
          if (!value) return of({ items: [] });

          return this.patientsService.Search({ Page: this.page, Size: this.size, Search: value })
        }),
        map((response: PatientSearchResponse): PatientSearchResult[] => response.items)
      )
      .subscribe(
        (items: PatientSearchResult[]) => {
          this.patients = items;
          this.loading = false;
        },
        () => {
          this.patients = [];
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.selectedClientId = this.selectedPatientId;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  selected = (patient: PatientSearchResult) => this.selectedClientId && this.selectedClientId === patient.id;

  onScroll() {
    this.page++;

    const value: string = this.searchControl.value;
    if (!value) return;

    this.patientsService.Search({ Page: this.page, Size: this.size, Search: value })
      .subscribe((response: PatientSearchResponse) => this.patients.push(...response.items));
  }

  dismiss = () => this.onClose.emit();

  select(patient: PatientSearchResult) {
    this.selectedClient = patient;
    this.onSelect.emit(patient);
  }
}
