import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Metric } from "../../../generated/models";
import { Observable, forkJoin, from } from "rxjs";
import { MetricsService } from "../../../generated/services";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class MetricResolver implements Resolve<MetricResolverPayload> {
  constructor(private metricsService: MetricsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MetricResolverPayload> {
    const id = parseInt(route.paramMap.get("id"), 10);

    const metricLoader: Observable<Metric> = id > 0 ? this.metricsService.MetricAsync(id) : from([{}]);

    return forkJoin({
      metric: metricLoader
    });
  }
}

export interface MetricResolverPayload {
  metric: Metric;
}
