import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { UsersTemplate, Record } from '../../../../generated/models';

@Component({
  selector: 'mp-change-template-modal-item',
  templateUrl: './change-template-modal-item.component.html',
  styleUrls: ['./change-template-modal-item.component.scss']
})
export class ChangeTemplateModalItemComponent implements OnInit {

  @Input() item: UsersTemplate = {};
  @Input() record: Record = {};

  @Input() selected = false;
  @Input() current = false;

  @HostBinding('class.selected') get hasSelectedClass() { return this.selected; }
  @HostBinding('class.current') get hasCurrentClass() { return this.current; }
  @HostBinding('class.in-use') get hasInUseClass() { return this.record && this.record.id > 0; }

  constructor() { }

  ngOnInit() { }

}
