import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DiseaseOutcome, RecordSection } from '../../../../generated/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

@Component({
  selector: 'mp-outcome-section-editor',
  templateUrl: './outcome-section-editor.component.html',
  styleUrls: ['./outcome-section-editor.component.scss']
})
export class OutcomeSectionEditorComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() disabled = false;
  @Input() dirty = false;
  @Input() base: string;
  @Input() section: RecordSection = {};
  @Input() outcomes: DiseaseOutcome[] = []

  @Output() onChange = new EventEmitter<RecordSection>();

  form = new FormGroup({
    outcomeType: new FormControl(0, [Validators.required]),
    outcomeDescription: new FormControl(),
    appointmentAt: new FormControl(undefined),
    closedAt: new FormControl(undefined),
    closedWithStatus: new FormControl(undefined)
  });

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: FormValue) => {

        this.onChange.emit({
          ...this.section,
          outcomeType: value.outcomeType,
          outcomeDescription: value.outcomeDescription,
          appointmentAt: value.appointmentAt && value.appointmentAt.isValid() ? value.appointmentAt.format('DD.MM.YYYY') : '',
          closedAt: value.closedAt && value.closedAt.isValid() ? value.closedAt.format('DD.MM.YYYY') : '',
          closedWithStatus: value.closedWithStatus
        })
      });

  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      const section: RecordSection = changes['section'].currentValue;

      if (section) {
        this.form.patchValue({
          ...section,
          appointmentAt: section.appointmentAt ? moment(section.appointmentAt, 'DD.MM.YYYY') : undefined,
          closedAt: section.closedAt ? moment(section.closedAt, 'DD.MM.YYYY') : undefined,
        }, { emitEvent: false });
      } else {
        this.form.patchValue({
          outcomeType: 0,
          outcomeDescription: '',
          closedWithStatus: undefined,
          appointmentAt: undefined,
          closedAt: undefined
        }, { emitEvent: false });
      }

    }

    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled && !this.form.disabled) {
        this.form.disable({ emitEvent: false });
      }

      if (!disabled && this.form.disabled) {
        this.form.enable({ emitEvent: false });
      }
    }

    if (changes['dirty']) {
      const dirty: boolean = changes['dirty'].currentValue;

      if (dirty) {
        Object.entries(this.form.controls).forEach(x => {
          x[1].markAsDirty();
          x[1].markAsTouched();
        });

      }
    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clear = () => this.setDate(null);

  addWeek = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "week"));
  addFortnight = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(2, "week"));
  addMonth = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "month"));
  add6Month = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(6, "month"));
  addYear = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "year"));

  setDate = (date: moment.Moment) => this.form.get('appointmentAt').setValue(date);
}

interface FormValue {
  outcomeType: 0 | 1 | 2;
  outcomeDescription?: string;
  appointmentAt?: moment.Moment;
  closedAt?: moment.Moment;
  closedWithStatus?: number;
}
