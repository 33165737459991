import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ScheduleBreakReason } from '../../../../generated/models';

@Component({
  selector: 'mp-schedule-break-reason-list-item',
  templateUrl: './schedule-break-reason-list-item.component.html',
  styleUrls: ['./schedule-break-reason-list-item.component.scss']
})
export class ScheduleBreakReasonListItemComponent implements OnInit {
  @Input() item: ScheduleBreakReason;

  @Output() onRemove = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();
}
