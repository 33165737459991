import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { FiscalPrinter } from '../../../../generated/models';

@Component({
  selector: 'mp-fiscal-printer-modal',
  templateUrl: './fiscal-printer-modal.component.html',
  styleUrls: ['./fiscal-printer-modal.component.scss']
})
export class FiscalPrinterModalComponent implements OnInit {
  private _selected: number;

  @Input() devices$: Observable<FiscalPrinter[]>;

  @Input() fiscalPrinterId: number;

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<number> = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
    this._selected = this.fiscalPrinterId;
  }

  cancel(): void {
    this.onCancel.emit();
  }

  confirm(): void {
    if (!this._selected) {
      return;
    }

    this.onConfirm.emit(this._selected);
  }

  select(device: FiscalPrinter): void {
    this._selected = device.id;
  }

  selected(device: FiscalPrinter): boolean {
    return this._selected === device.id;
  }

  get disabled(): boolean { return !this._selected; }
}
