import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Resource } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-remove-schedule-modal',
  templateUrl: './remove-schedule.modal.component.html',
  styleUrls: ['./remove-schedule.modal.component.scss']
})
export class RemoveScheduleModalComponent implements OnInit {
  @Input() resource: Resource;

  @Output()
  onConfirm = new EventEmitter<void>();

  @Output()
  onCancel = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  public remove(): void {
    this.onConfirm.emit();
  }

  public cancel(): void {
    this.onCancel.emit();
  }

}
