import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ServiceGroupsService } from '../../../../generated/services';
import { Category, ServiceGroup } from '../../../../generated/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { ServiceGroupsResolverPayload } from '../../resolvers/service-groups-resolver';

@Component({
  selector: 'mp-service-groups',
  templateUrl: './service-groups.component.html',
  styleUrls: ['./service-groups.component.scss'],
  host: { class: "page" }
})
export class ServiceGroupsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  filters = new FormGroup({
    search: new FormControl(''),
    category: new FormControl(null),
    includeArchival: new FormControl(false)
  });

  page = 1;
  size = 100;

  loading = false;
  items: ServiceGroup[] = [];

  categories: Category[] = [];

  constructor(private router: Router,
    private service: ServiceGroupsService,
    private toastrService: ToastrService,
    private confirmationsService: ConfirmationModalService,
    private activatedRoute: ActivatedRoute) {
    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FilterValue) => {
          const params: Params = {};

          if (value.search) {
            params.search = value.search;
          }
          if (value.category > 0) {
            params.category = value.category;
          }

          if (value.includeArchival) {
            params.includeArchival = true;
          }

          this.router.navigate([], { queryParams: params, relativeTo: activatedRoute });
        }),
        tap(() => {
          this.page = 1;
          this.items = [];
        })
      ).subscribe(async () => {

        this.loading = true;

        this.items = await this.load();

        this.loading = false;

      });

  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParamMap;

    this.filters.patchValue({
      search: params.get('search'),
      category: parseInt(params.get('category'), 10) > 0 ? parseInt(params.get('category'), 10) : null,
      includeArchival: params.get('includeArchival') === 'true'
    });

    this.activatedRoute.data.subscribe((data: { payload: ServiceGroupsResolverPayload }) => {
      this.categories = data.payload.categories;
    });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  create = () => this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  edit = (item: ServiceGroup) => this.router.navigate([item.id], { relativeTo: this.activatedRoute });

  async remove(item: ServiceGroup) {
    const confirmed = await this.confirmationsService.open({ message: `Пакет «${item.name}» будет удален. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.service.RemoveServiceGroupAsync(item.id).subscribe(
      async () => {
        this.toastrService.success(`Пакет «${item.name}» удален`, 'Успешно');

        this.page = 1
        this.items = [];

        this.loading = true;
        const items = await this.load();
        this.items.push(...items);
        this.loading = false;
      },
      (response: HttpErrorResponse) => this.handleError(response, `Не удалось удалить пакет «${item.name}»`)
    );

  }

  async onScollDown() {
    this.page++;

    const items = await this.load();
    this.items.push(...items);
  }

  async load(): Promise<ServiceGroup[]> {
    const value: FilterValue = this.filters.getRawValue();
    try {
      return await this.service.ServiceGroupsAsync({
        Page: this.page,
        Size: this.size,
        Search: value.search,
        CategoryId: value.category,
        IncludeArchival: value.includeArchival
      }).toPromise();
    } catch (e) {
      this.handleError(e as HttpErrorResponse, 'Не удалось загрузить пакеты услуг');

      return [];
    }
  }

  errorMessage(code: string, message: string) {

    switch (code) {

      case 'NameRequired': return 'Необходимо указать имя пакета';
      case 'RoundingTypeInvalid': return 'Неизвестный тип округления';
      case 'PermissionRequired': return 'Для выполнения операции отсутствует необходимое разрешение';
      case 'ServiceGroupNotFound': return 'Пакет не найдена';
      case 'ServiceAlreadyAdded': return 'Услуга уже добавлена в пакет';
      case 'ServiceNotFound': return 'Услуга не найдена';
      case 'ServiceGroupServiceNotFound': return 'Услуга не найдена в данном пакете';

      default: return message;
    }
  }

  handleError(response: HttpErrorResponse, message: string) {
    if (response.status === 400 && response.error && response.error.errors) {

      for (const error of response.error.errors) {
        this.toastrService.warning(this.errorMessage(error.errorCode, message), 'Ошибка');
      }

      return;
    }

    if (response.status === 400 && response.error && Array.isArray(response.error)) {
      for (const error in response.error) {
        this.toastrService.warning(this.errorMessage(error, message), 'Ошибка');
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }
}

interface FilterValue {
  search: string;
  category?: number;
  includeArchival: false;
}
