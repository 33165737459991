import { ProfileInfo } from '../../../generated/models';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ProfileService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class ProfileResolver implements Resolve<ProfilePayload> {

  constructor(
    private profileService: ProfileService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProfilePayload> {
    const profileLoader = this.profileService.Profile();
    return forkJoin({
      profile: profileLoader
    });
  }
}

export interface ProfilePayload {
  profile: ProfileInfo;
}
