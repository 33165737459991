import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Icd10 } from "../../models/ic10";

import { ActivatedRoute, Router } from '@angular/router';
import { ReferralsModalComponent } from '../referrals-modal/referrals-modal.component';
import { DiagnosisModalComponent } from '../diagnosis-modal/diagnosis-modal.component';
import { RecordsService, MyService, PeopleService, PatientsService, ReviewsService, InsuranceService, ClinicalRecommendationsService, PersonsService, MyFavoritesService, ColorMarksService, PersonMarksService, PersonMetricsService } from 'projects/Clinic/src/app/generated/services';
import { RecordSection, Record, SavedTemplateGroup, UnsignManyResponse, PermissionInCompany, SaveRecordRequest, PrescriptionItem, AccessTokenResponse, QuestionnaireResponse, AddQuizRunResponse, PersonQuizRunResult, DiseaseOutcome, RecordReviewSummary, CheckDiagnosesResponse, ClinicalRecommendation, SectionsServicePrescription, SaveServicePrescriptionRequest, PersonTestResult, PersonTestResultAttachment, PersonsVisit, EmployeeFavoriteManipulation, ColorMark, ColorMarksResponse, PersonMark, PersonMetric, LabResult, PersonLabOrder, PersonLabResult } from 'projects/Clinic/src/app/generated/models';
import { DocumentPreviewModalComponent } from '../document-preview-modal/document-preview-modal.component';
import { RecordPayload } from '../../resolvers/record-resolver';
import { SignManyResponse } from '../../../../generated/models/sign-many-response';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ToastrService } from 'ngx-toastr';
import { SaveSectionRequest } from '../../../../generated/models/save-section-request';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Subject, Observable, forkJoin, from } from 'rxjs';
import { map, tap, switchMap, share, filter, catchError } from 'rxjs/operators';

import * as $ from "jquery";
import * as moment from "moment";

import { QuestionaryModalComponent } from '../questionary-modal/questionary-modal.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { PersonQuizRun } from '../../../../generated/models/person-quiz-run';
import { ReviewsModalComponent } from '../reviews-modal/reviews-modal.component';
import { ReportsUtilityService } from '../../../shared/services/reports-url-service';
import { DiagnosisInfo, DiagnosisWarningModalComponent } from '../diagnosis-warning-modal/diagnosis-warning-modal.component';
import { SignDocumentModalComponent } from '../sign-document-modal/sign-document-modal.component';
import { SignatureWarningModalComponent } from '../signature-warning-modal/signature-warning-modal.component';
import { Certificate, createDetachedSignature, createHash, getUserCertificates } from 'crypto-pro';
import { SignatureLoadingModalComponent } from '../signature-loading-modal/signature-loading-modal.component';
import { TreatmentPrescriptionsModalComponent } from '../../../treatments/components/treatment-prescriptions-modal/treatment-prescriptions-modal.component';
import { TreatmentPrescriptionPayload } from '../../../treatments/models/treatment-prescription-payload';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { PersonMarkModalService } from '../../../person-marks/services/person-mark-modal.service';
import { PersonMarkModalComponent, PersonMarkModalPayload } from '../../../person-marks/components/person-mark-modal/person-mark-modal.component';
import { ChangeTemplateModalComponent } from '../../../record-editor/components/change-template-modal/change-template-modal.component';
import { LabResultsModalComponent } from '../../../record-editor/components/lab-results-modal/lab-results-modal.component';

enum Action {
  Abort = 0,
  Draft = 1,
  Save = 2
}

@Component({
  selector: 'mp-record.editor',
  templateUrl: './record.editor.component.html',
  styleUrls: ['./record.editor.component.scss'],
  host: { class: "page" }
})
export class RecordEditorComponent implements OnInit, OnDestroy {
  recordId: number;
  priceSetId: number;

  patientId: number;
  personId: number;
  phone: string;
  patientAge: number;
  patientGender = '';
  patientDob: string = '';

  created: string;
  dirty = false;

  sections: RecordSection[] = [];
  favoritePrescriptions: EmployeeFavoriteManipulation[] = [];
  outcomes: DiseaseOutcome[] = [];

  colorMarks: ColorMark[] = [];
  personMarks: PersonMark[] = [];

  personMetrics: PersonMetric[] = [];

  model: Record;

  loading = false;
  saved = false;
  saving = false;
  printed = false;
  printing = false;

  showPanel = false;

  useFastDiagnosisSearch = false;

  userId = 0;

  canUnsign = false;
  canEdit = false;
  canEditSigned = false;
  canReview = false;

  canSign = false;
  canAddMark = false;
  canManageMarks = false;

  private _dirtySections: { [id: number]: boolean } = {};
  private _certificate: Certificate = null;

  recommendations: ClinicalRecommendation[] = [];

  parameters$: Observable<SavedTemplateGroup[]> = null;
  quizRuns$: Observable<PersonQuizRun[]>;
  testResults$: Observable<PersonTestResult[]>;
  visits$: Observable<PersonsVisit[]>;

  labOrders$: Observable<PersonLabOrder[]>;

  //Для модальных окон с подтверждением действий
  text: string;
  title: string;
  protocolName: string;

  previewUrl: SafeResourceUrl;
  showPreview = false;

  readonly appointmentSectionType = 4;
  readonly closingSectionType = 5;

  get appointmentDate(): string {
    const section = this.sections
      .filter(x => x.show)
      .find(x => (x.type === 4 && !!x.appointmentDate) || (x.type === 7 && !!x.appointmentAt && x.outcomeType === 1));

    if (!section) return '';

    return section.appointmentDate || section.appointmentAt;
  }
  get closingDate(): string {
    const section = this.sections
      .filter(x => x.show)
      .find(x => (x.type === 5 && !!x.closingDate) || (x.type === 7 && x.outcomeType === 2 && !!x.closedAt));

    if (!section) return '';

    return section.closingDate || section.closedAt;
  }

  @ViewChild("DummyWindow") dummyWindow: ElementRef<HTMLDivElement>;

  previewPrintingSource = new Subject<string>();

  get showPrintBtn(): boolean { return this.userStorage.profile.workspaceShowPrintBtn; }
  get showSendBtn(): boolean { return this.userStorage.profile.workspaceShowSendToPatientBtn; }
  get showSaveDraftBtn(): boolean { return this.userStorage.profile.workspaceShowSaveDraftBtn; }

  get appointmentReasonRequired(): boolean { return this.userStorage.profile.appointmentReasonRequired; }

  get disabled() {
    if (!this.model) return true;
    if (this.model.authorId !== this.userStorage.profile.id && !this.userStorage.hasPermission(PermissionNames.EditDocumentsOfOtherDoctors)) return true;
    if ((this.model.signed && !this.canEditSigned)) return true;

    return false;
  }

  constructor(
    private modal: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private recordsService: RecordsService,
    private userStorage: UserStorage,
    private toastrService: ToastrService,
    private myService: MyService,
    private peopleService: PeopleService,
    private personsService: PersonsService,
    private patientsService: PatientsService,
    private reviewsService: ReviewsService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private reportsUtilityService: ReportsUtilityService,
    private insuranceService: InsuranceService,
    private recommendationsService: ClinicalRecommendationsService,
    private myFavoritesService: MyFavoritesService,
    private colorMarksService: ColorMarksService,
    private personMarksService: PersonMarksService,
    private httpClient: HttpClient,
    private confirmationService: ConfirmationModalService,
    private personMarksModalService: PersonMarkModalService,
    private personMetricsService: PersonMetricsService
  ) {
    this.canUnsign = userStorage.profile.permissions
      .filter((x: PermissionInCompany): boolean => !x.companyId || x.companyId === userStorage.profile.companyId)
      .some((x: PermissionInCompany): boolean => x.name === PermissionNames.CanRemoveSignatureFromRecord);

    this.canSign = userStorage.profile.permissions
      .filter((x: PermissionInCompany): boolean => !x.companyId || x.companyId === userStorage.profile.companyId)
      .some((x: PermissionInCompany): boolean => x.name === PermissionNames.AddDigitalSignature);

    this.userId = this.userStorage.profile.id;

    this.useFastDiagnosisSearch = userStorage.profile.enableFasterDiagnosisSearch;

    this.canEditSigned = this.canUnsign;
    this.canReview = this.userStorage.hasPermission(PermissionNames.ReviewRecords);
    this.canAddMark = this.userStorage.hasPermission(PermissionNames.AddPersonMarks)
    this.canManageMarks = this.userStorage.hasPermission(PermissionNames.ManagePersonMarks)

    this.userStorage.localSettings.subscribe(settings => {
      this.showPanel = settings.workspaceInfoPanelOpened;
    });

    this.previewPrintingSource
      .subscribe((src: string): void => {
        this.dummyWindow.nativeElement.innerHTML = "";

        const frame: HTMLIFrameElement = document.createElement("iframe");
        frame.style.display = "none";
        frame.src = src;
        this.dummyWindow.nativeElement.append(frame);

        frame.contentWindow.print();
      });
  }

  ngOnInit() {
    this.recordId = parseInt((this.activatedRoute.snapshot.paramMap.get("id")));

    this.myFavoritesService.MyFavoriteManipulationsAsync().subscribe((response: EmployeeFavoriteManipulation[]) => this.favoritePrescriptions = response);

    this.activatedRoute.data.subscribe((data: { payload: RecordPayload }): void => {
      this.model = data.payload.record;

      this.priceSetId = data.payload.record.priceSetId;

      this.created = data.payload.record.created;

      this.patientId = data.payload.record.patientId;
      this.personId = data.payload.record.personId;
      this.phone = data.payload.record.phone;

      if (data.payload.patient.age) {
        this.patientAge = parseInt(data.payload.patient.age);
      }

      this.patientDob = data.payload.patient.dob;

      switch (data.payload.patient.gender) {
        case 1: this.patientGender = 'f'; break;
        case 2: this.patientGender = 'm'; break;
      }

      this.quizRuns$ = this.peopleService.QuizRuns(this.personId);
      this.testResults$ = this.personsService.ResultsAsync(this.personId);
      this.visits$ = this.personsService.ConsultationsAsync(this.personId);
      this.labOrders$ = this.personsService.LabOrdersAsync(this.personId).pipe(catchError((e) => from([[]])));

      this.outcomes = data.payload.outcomes;

      this.loadPersonMarks();

      this.canEdit = data.payload.record.authorId === this.userStorage.profile.id || this.userStorage.hasPermission(PermissionNames.EditDocumentsOfOtherDoctors);

      this.protocolName = data.payload.record.title;

      this.sections = data.payload.sections;

      const primarySection = this.sections.find(x => x.diagnosisCode && x.diagnosisType === 1 && x.show);
      if (primarySection) {
        this.loadRecommendations(primarySection.diagnosisCode, false);
      }

      this.parameters$ = this.recordsService.Parameters(this.recordId).pipe(share());
    });

    this.saved = false;
    this.saving = false;
    this.printed = false;
    this.printing = false;

    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`/records/${this.recordId}/preview`);

    this.colorMarksService.ColorMarks({ Page: 1, Size: 0 }).subscribe(
      (response: ColorMarksResponse) => this.colorMarks = response.items,
      (response: HttpErrorResponse) => this.toastrService.error('Не удалось загрузить цветовые метки', 'Ошибка')
    );

    this.personMetricsService.PersonMetricsAsync(this.personId)
      .subscribe((response: PersonMetric[]) => {
        const metrics: PersonMetric[] = [];

        for (let i = 0; i < response.length; i++) {
          const metric: PersonMetric = response[i];

          const index = metrics.findIndex(x => x.systemName === metric.systemName);

          if (index === -1) {
            metrics.push(metric);
            continue;
          }

          if (moment(metrics[index].recordSigned, 'DD.MM.YYYY').isBefore(moment(metric.recordSigned, 'DD.MM.YYYY'))) {
            metrics.splice(index, 1, metric);
          }
        }

        this.personMetrics = metrics;
      });
  }

  ngOnDestroy() {
    this.previewPrintingSource.unsubscribe();
  }

  loadPersonMarks() {
    this.personMarksService.PersonMarksAsync(this.personId)
      .subscribe(
        (response: PersonMark[]) => this.personMarks = response,
        (response: HttpErrorResponse) => this.toastrService.error('Не удалось загрузить метки пациента', 'Ошибка')
      );
  }

  removeSignature(content: any): void {
    if (!this.canUnsign) return;

    this.title = "Снять подпись?";
    this.text = "С данного документа будет снята подпись. Продолжить?";

    const modal: NgbModalRef = this.modal.open(content, { centered: true });

    modal.result.then(
      () => {
        this.loading = true;

        this.recordsService.UnsignMany({ recordIds: [this.recordId] })
          .subscribe(
            (response: UnsignManyResponse) => {
              if (response.unsigned.some((x: number): boolean => x === this.recordId)) {
                this.model.signed = false;
                this.model.signedByName = '';

                this.toastrService.success(`Документ помечен как черновик`, "Подпись снята");
                this.loading = false;
              }
            },
            (error) => {
              console.error('Observer got an error: ', error);
              this.loading = false;
            }
          )
      },
      () => { }
    );
  }

  signRecord(content: any) {
    this.dirty = true;

    if (this.sections.some(x => this.sectionInvalid(x))) return;

    this.title = "Подписать документ?";
    this.text = "После подписи данный документ нельзя будет редактировать. Продолжить?";

    const modal: NgbModalRef = this.modal.open(content, { centered: true });

    modal.result.then(
      () => {
        this.loading = true;

        this.recordsService.SignMany({ recordIds: [this.recordId] })
          .subscribe(
            (response: SignManyResponse): void => {
              if (response.signed.some((x: number): boolean => x === this.recordId)) {
                this.model.signed = true;
                this.model.signedByName = response.signByName;

                this.toastrService.success(`Пользователь ${response.signByName} подписал документ`, "Документ подписан");
                this.loading = false;
              }
            },
            (): void => {
              this.toastrService.error("Не удалось подписать документ", "Ошибка");
              this.loading = false;
            }
          );
      },
      () => { }
    )

  }

  restoreAll = (content: any): void => {
    if (!this.canEdit) return;

    this.title = "Сбросить изменение?";
    this.text = "Содержимое документа будет сброшено к начальной версии. Продолжить?";

    const modal: NgbModalRef = this.modal.open(content, { centered: true });


    modal.result.then(
      () => {
        this.loading = true;

        this.recordsService.RestoreAll(this.recordId)
          .subscribe(
            (sections: RecordSection[]): void => {
              //sections.forEach((x: RecordSection): void => this.updateSection(x, x));

              this.sections = sections.map((x: RecordSection): RecordSection => ({ ...x }));
              this.recommendations = [];

              this.loading = false;
              this.toastrService.success("Содержимое всех секций сброшено к начальной версии", "Содержимое сброшено");
            },
            (): void => {
              this.toastrService.error("Не удалось сбросить изменения", "Ошибка");
              this.loading = false;
            }
          );

      },
      () => { }
    );
  }

  openDiagnosisModal = (section: RecordSection): void => {
    const modalRef: NgbModalRef = this.modal.open(DiagnosisModalComponent, {
      size: 'xl' as 'lg',
      centered: true,
      backdrop: 'static',
      windowClass: "diagnosis-modal-window"
    });

    const componentRef: DiagnosisModalComponent = modalRef.componentInstance;

    modalRef.result.then((selected: Icd10): void => {
      const updated: RecordSection = { ...section, diagnosisCode: selected.code, diagnosisText: selected.name, diagnosisId: selected.id };

      this.updateSection(section, updated);
    }, (reason) => {
      console.log("Modal closed", reason);
    });

    componentRef.title = "Справочник диагнозов";
    componentRef.section = section;
    componentRef.restrictions = section.allowedDiagnoses;
    componentRef.age = this.patientAge;
    componentRef.gender = '';// this.patientGender;
  }

  openReferralsModal(section: RecordSection) {

    const modalOptions: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      windowClass: "referrals-modal-window",
      size: 'xl' as 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(ReferralsModalComponent, modalOptions);
    const componentRef: ReferralsModalComponent = modalRef.componentInstance;

    componentRef.onClose.subscribe(() => {
      this.myFavoritesService.MyFavoriteManipulationsAsync().subscribe((response: EmployeeFavoriteManipulation[]) => this.favoritePrescriptions = response);

      modalRef.close();
    });

    componentRef.onSubmit.subscribe((selected: PrescriptionItem[]): void => {
      const updated: RecordSection = { ...section, referrals: [...selected] };

      this.updateSection(section, updated);
      this.myFavoritesService.MyFavoriteManipulationsAsync().subscribe((response: EmployeeFavoriteManipulation[]) => this.favoritePrescriptions = response);

      modalRef.close();
    });

    componentRef.title = "Назначения";
    componentRef.priceSetId = this.priceSetId;
    componentRef.selectedItems = section.referrals;
    componentRef.clinicalRecommendations = this.recommendations.filter(x => x.type === 0);
    componentRef.qualityControlRecommendations = this.recommendations.filter(x => x.type === 1);
    componentRef.recommendations = this.recommendations;
  }

  clearDiagnosis = (section: RecordSection, content: any): void => {
    this.title = "Удаление диагноза";
    this.text = `Диагноз ${section.diagnosisCode} ${section.diagnosisText} будет удален. Продолжить? `;

    const modal: NgbModalRef = this.modal.open(content, { centered: true });

    modal.result.then(
      () => {
        const updated: RecordSection = { ...section, diagnosisCode: "", diagnosisText: "", diagnosisId: undefined };

        this.updateSection(section, updated);
      },
      () => { }
    )
  }

  toggleParametersPanel(value: boolean) {
    this.userStorage.setWorkspaceInfoPanelOpened(value);

    this.showPanel = value;
  }

  //вызовы к апи
  private gatherRequest(): SaveRecordRequest {
    const request: SaveRecordRequest = {
      title: this.model.title,
      visitReason: this.model.visitReason,
      sections: this.sections.map((x: RecordSection): SaveSectionRequest => ({
        id: x.id,
        type: x.type,
        content: x.content,
        show: x.show,
        includeInExtract: x.includeInExtract,

        diagnosisCode: x.diagnosisCode,
        diagnosisText: x.diagnosisText,

        appointmentDate: x.appointmentDate,
        appointmentReason: x.appointmentReason,

        closingDate: x.closingDate,
        caseResultId: x.caseResultId,
        comment: x.comment,

        outcomeType: x.outcomeType,
        outcomeDescription: x.outcomeDescription,
        appointmentAt: x.appointmentAt,
        closedAt: x.closedAt,
        closedWithStatus: x.closedWithStatus,

        servicePrescriptions: x.referrals.map((x: SectionsServicePrescription): SaveServicePrescriptionRequest => ({
          id: x.id,
          serviceId: x.serviceId > 0 ? x.serviceId : undefined,
          serviceName: x.name,
          manipulationId: x.manipulationId > 0 ? x.manipulationId : undefined,
          manipulationName: x.name,
          listOrder: x.listOrder
        })),

        treatmentPrescriptions: x.treatmentPrescriptions.map(x => ({
          id: x.id,
          treatmentName: x.treatmentName,
          dosage: x.dosage,
          schema: x.schema,
          duration: x.duration,
          listOrder: x.listOrder
        })),

        metrics: x.metrics.map(x => ({ systemName: x.systemName, value: `${x.value}` }))
      }))
    };

    return request;
  }

  saveRecordDraft(invalid = false): void {
    this.dirty = true;

    this.loading = true;

    this.recordsService.SaveRecordDraft({ id: this.recordId, request: this.gatherRequest() })
      .subscribe(
        (): void => {
          if (invalid) {
            this.toastrService.warning("Документ содержит ошибки, недопустимые значения или незаполненные обязательные секции", "Сохранено как черновик");
          } else {
            this.toastrService.success("Черновик сохранен", "Успешно");
          }

          this.loading = false;
        },
        (e): void => {
          this.handleSaveError(e as HttpErrorResponse);
          this.loading = false;
        }
      );
  }

  forbiddenDiagnosisWarning(action: Action): Promise<Action> {
    return new Promise<Action>((resolve, reject) => {
      if (action !== Action.Save) {
        resolve(action);
        return;
      }

      const diagnoses = this.sections.filter(x => x.show && x.type === 2 && x.diagnosisCode);

      this.insuranceService.CheckDiagnosesForPatient({ patientId: this.patientId, request: { codes: diagnoses.map(x => x.diagnosisCode) } })
        .subscribe(
          (response: CheckDiagnosesResponse): void => {
            if (response.diagnoses.every(x => x.status === 0)) {
              resolve(Action.Save);
              return;
            }

            const options: NgbModalOptions = { centered: true, backdrop: 'static', size: 'lg' };
            const modalRef: NgbModalRef = this.modal.open(DiagnosisWarningModalComponent, options);
            const componentRef: DiagnosisWarningModalComponent = modalRef.componentInstance;

            const diagnosesInfo: DiagnosisInfo[] = [];

            for (const diagnosis of diagnoses) {
              const status = response.diagnoses.find(x => x.code === diagnosis.diagnosisCode);

              diagnosesInfo.push({ code: diagnosis.diagnosisCode, name: diagnosis.diagnosisText, status: status ? status.status : 0 });
            }

            componentRef.insuranceCompany = response.insuranceCompanyName;
            componentRef.diagnoses = diagnosesInfo;

            componentRef.onCancel.subscribe((): void => {
              modalRef.close();
              resolve(Action.Abort);
            });

            componentRef.onConfirm.subscribe((): void => {
              modalRef.close();
              resolve(Action.Save);
            });
          },
          (): void => { }
        );
    });
  }

  collidingSectionsWarning(action: Action): Promise<Action> {
    return new Promise<Action>((resolve, reject) => {
      if (action !== Action.Save) {
        resolve(action);
        return;
      }

      const endcaseSections = this.sections.filter(x => x.show && x.type == 5);
      const nextVisitSections = this.sections.filter(x => x.show && x.type == 4);

      if (endcaseSections.length === 0 && nextVisitSections.length === 0) {
        resolve(Action.Save);
        return;
      }

      if (nextVisitSections.length === 0 && endcaseSections.every(x => !x.closingDate)) {
        this.toastrService.warning("Необходимо указать дату завершения случая", "Ошибка");
        resolve(Action.Abort);
        return;
      }

      if (endcaseSections.length === 0 && nextVisitSections.every(x => !x.appointmentDate)) {
        this.toastrService.warning("Необходимо указать дату следующей явки", "Ошибка");
        resolve(Action.Abort);
        return;
      }

      if (endcaseSections.every(x => !x.closingDate) && nextVisitSections.every(x => !x.appointmentDate)) {
        this.toastrService.warning("Необходимо указать дату завершения случая или дату следующей явки", "Ошибка");
        resolve(Action.Abort);
        return;
      }

      let filledEndcase = endcaseSections.filter(x => x.show && x.closingDate);
      let filledNextVisit = nextVisitSections.filter(x => x.show && x.appointmentDate);

      if (filledEndcase.length > 0 && filledNextVisit.length > 0) {
        if (filledEndcase.every(x => x.mandatory) && filledNextVisit.every(x => x.mandatory)) {
          resolve(Action.Save);
          return;
        }

        this.toastrService.warning("Невозможно сохранить документ с заполненными секциями окончания случая и следующего посещения. Скройте одну из секций", "Ошибка");
        resolve(Action.Abort);
        return;
      }

      resolve(Action.Save);
      return;
    })
  }

  async signatureWarning(action: Action): Promise<Action> {
    if (action !== Action.Save) {
      return action;
    }

    const options: NgbModalOptions = { centered: true, backdrop: 'static', size: 'lg' };

    return new Promise<Action>(async (resolve, reject) => {

      let certificates: Certificate[] = [];

      try {
        certificates = await getUserCertificates();
      } catch (e) {
        this.toastrService.warning('Не удалось получить список доступных сертификатов', 'Ошибка');
        resolve(Action.Save);
        return;
      }

      certificates = certificates.filter(x => x);

      for (const certificate of certificates) {
        const validFrom = moment(certificate.validFrom);
        const validTill = moment(certificate.validTo);

        if (moment().isBetween(validFrom, validTill, 'day', '[]')) {
          const parts = this.userStorage.profile.userName.split(' ');

          if (parts.every(x => certificate.subjectName.includes(x))) {
            this._certificate = certificate;
            resolve(Action.Save);
            return;
          }
        }
      }

      const modalRef: NgbModalRef = this.modal.open(SignatureWarningModalComponent, options);
      const componentRef: SignatureWarningModalComponent = modalRef.componentInstance;

      componentRef.certificates = certificates;
      componentRef.signatureIdentifier = this.userStorage.profile.signatureIdentifier;

      componentRef.onClose.subscribe((): void => {
        modalRef.close();
        this.toastrService.warning('Для продолжения необходимо выбрать сертификат ЭЦП', 'Ошибка');
        resolve(Action.Abort);
      });

      componentRef.onConfirm.subscribe((certificate: Certificate): void => {
        modalRef.close();
        this._certificate = certificate;

        resolve(Action.Save);
      });

    });
  }

  handleSaveError(response: HttpErrorResponse): void {
    if (response.status !== 400) {
      this.toastrService.error('Не удалось сохранить протокол', 'Ошибка');
      return;
    }

    switch (response.error.status) {
      case 5: this.toastrService.warning('Документ не найден', 'Ошибка'); break;
      case 7: this.toastrService.warning('Для выполнения операции отсутствует необходимое разрешение', 'Ошибка'); break;
      case 6: this.toastrService.warning('Изменение подписанного документа запрещено', 'Ошибка'); break;
      case 22: this.toastrService.warning('Некоторые назначенные услуги не найдены', 'Ошибка'); break;
      case 24: this.toastrService.warning('Необходимо разрешение для подписи протокола', 'Ошибка'); break;
      case 25: this.toastrService.warning('Необходимо разрешение для повторного подписания документа', 'Ошибка'); break;
      case 26: break;
      case 27: this.toastrService.warning('Необходимо указать название назначения', 'Ошибка'); break;
      case 28: this.toastrService.warning('Требуется идентификатор подписи', 'Ошибка'); break;
      case 29: this.toastrService.warning('Требуется имя подписи', 'Ошибка'); break;
      case 30: this.toastrService.warning('Срок действия подписи истек или ещё не начался', 'Ошибка'); break;
      case 31: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Нет имени файла подписи
      case 32: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Нет имени файла протокола
      case 33: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Нет содержимого файла подписи
      case 34: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Нет содержимого файла протокола
      case 35: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Не указан номер редакции
      case 36: this.toastrService.warning('Не удалось подписать протокол', 'Ошибка'); break;// Не удалось сохранить редакцию
      case 37: this.toastrService.warning('Не удалось сохранить подписанный протокол', 'Ошибка'); break;// Не удалось сохранить подпись
      case 38: this.toastrService.warning('Хранение подписанных протоколов отключено', 'Ошибка'); break;// Хранение редакций отключено

      default: this.toastrService.error('Не удалось сохранить протокол', 'Ошибка'); break;
    }

    for (const name of response.error.archivedServices) {
      this.toastrService.warning(`Услуга ${name} перенесена в архив и не может быть назначена`, 'Ошибка');
    }

  }

  private async save(print = false): Promise<void> {

    this.dirty = true;

    const withSignature = this.userStorage.profile.autoSignRecord;

    const invalid = this.sections.some(x => this.sectionInvalid(x))

    let status = invalid ? Action.Draft : Action.Save;

    status = await this.forbiddenDiagnosisWarning(status);
    //status = await this.collidingSectionsWarning(status);

    if (withSignature && !this._certificate) {
      status = await this.signatureWarning(status);
    }

    if (status === Action.Abort) return;

    if (status === Action.Draft) {
      this.saveRecordDraft(invalid);
      return;
    }

    const request = this.gatherRequest();

    const parameters = {
      id: this.recordId.toString(),
      signature: ""
    };

    if (this._certificate) {
      parameters.signature = JSON.stringify({
        Id: this._certificate.thumbprint,
        Owner: this._certificate.subjectName,
        ValidFrom: moment(this._certificate.validFrom).format('DD.MM.YYYY'),
        ValidTill: moment(this._certificate.validTo).format("DD.MM.YYYY")
      });
    }

    if (withSignature && this._certificate) {
      const options: NgbModalOptions = { centered: true, backdrop: 'static', size: 'sm' };
      const modalRef: NgbModalRef = this.modal.open(SignatureLoadingModalComponent, options);
      const componentRef: SignatureLoadingModalComponent = modalRef.componentInstance;

      this.loading = true;
      componentRef.message = "Сохранение протокола";

      try {
        await this.recordsService.SaveRecord({ id: this.recordId, request }).toPromise();
      }
      catch (e) {
        this.handleSaveError(e as HttpErrorResponse);
        this.loading = false;
        modalRef.close();
        return;
      }

      componentRef.message = "Скачивание документа";

      const token = await this.myService.AccessToken({ parameters }).toPromise();

      const buffer = await this.httpClient.get(`/records/preview?token=${token.token}&type=pdf`, { responseType: 'arraybuffer' }).toPromise();

      componentRef.message = "Подпись документа";

      const hash = await createHash(buffer)
      const value = await createDetachedSignature(this._certificate.thumbprint, hash);

      componentRef.message = "Загрузка документа";

      try {
        await this.recordsService.SealEditionAsync({
          id: this.recordId,
          Document: new File([buffer], `${this.recordId}.pdf`),
          Signature: new File([value], `${this.recordId}.sgn`),
          SignatureId: this._certificate.thumbprint,
          SignatureName: this._certificate.subjectName,
          SignatureValidFrom: moment(this._certificate.validFrom).format('DD.MM.YYYY'),
          SignatureValidTill: moment(this._certificate.validTo).format("DD.MM.YYYY")
        }).toPromise();
      } catch (e) {
        this.loading = false;
        modalRef.close();

        this.handleSaveError(e as HttpErrorResponse);
        return;
      }

      componentRef.message = "Документ подписан";

      this.model.signatureId = this._certificate.thumbprint;
      this.model.signatureName = this._certificate.subjectName;
      this.model.sealed = moment().unix();
      this.model.signatureValidFrom = moment(this._certificate.validFrom).format('DD.MM.YYYY');
      this.model.signatureValidTill = moment(this._certificate.validTo).format("DD.MM.YYYY");

      if (print) {
        this.previewPrintingSource.next(`/records/preview?token=${token.token}&type=pdf`);
      }

      setTimeout(() => {
        modalRef.close();
      }, 3000);

      this.loading = false;

      this.toastrService.success("", "Документ сохранен");

      return;
    }

    this.loading = true;
    try {
      await this.recordsService.SaveRecord({ id: this.recordId, request }).toPromise();

      this.model.signed = true;
      this.model.signedByName = this.userStorage.profile.userName;

      const token = await this.myService.AccessToken({ parameters }).toPromise();

      if (print) {
        this.previewPrintingSource.next(`/records/preview?token=${token.token}`);
      }

      this.loading = false;

      this.toastrService.success("", "Документ сохранен");
    }
    catch (e) {
      this.handleSaveError(e as HttpErrorResponse);
      this.loading = false;
    }
  }

  async saveRecordUnprinted(): Promise<void> {
    await this.save(false);
  }

  async saveAndPrintRecord(): Promise<void> {
    await this.save(true);
  }

  async finishCase(): Promise<void> {
    var sections = this.sections.filter(x => x.type == 5 && x.show);
    sections.forEach(x => {
      var updated = { ...x };
      if (x.closingDate == null || x.closingDate == "") {
        updated.closingDate = moment().format("DD.MM.YYYY");
      }
      if (x.caseResultId == null) {
        updated.caseResultId = 303;
      }
      this.updateSection(x, updated);
    });
  }

  async downloadSignedArchive(): Promise<void> {

    const parameters = {
      id: this.recordId.toString()
    };

    const token = await this.myService.AccessToken({ parameters }).toPromise();

    const url = `api/clinic/v1/records/${this.recordId}/signed?token=${token.token}`;

    window.open(url, '_blank');
  }

  sendRecordToClient() { }

  previewRecord = (): void => {

    this.myService.AccessToken({ parameters: { id: this.recordId.toString() } })
      .subscribe(
        (response: AccessTokenResponse) => {
          const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
          modal.componentInstance.path = `/records/preview?token=${response.token}`;
          modal.componentInstance.canPrint = this.model.signed;
        },
        (): void => {
          this.toastrService.error("Не удалось сгенерировать документ", "Ошибка");
        });
  }

  printRecord = () => this.previewPrintingSource.next(`/records/${this.recordId}/preview`);

  restoreSection(section: RecordSection) {
    this.recordsService.RestoreSection({ recordId: this.recordId, sectionId: section.id })
      .subscribe(
        (restored: RecordSection): void => this.updateSection(section, restored),
        (): void => {
          this.toastrService.error("Не удалось сбросить содержимое секций документа", "Ошибка")
        }
      );
  }

  private loadRecommendations(code: string, updateRecommendations = true) {
    this.recommendationsService.GetByDiagnosisCode(code)
      .subscribe(
        (response: ClinicalRecommendation[]): void => {
          this.recommendations = response;

          const mandatory = response.filter(x => x.mandatory);

          if (mandatory.length > 0) {

            if (updateRecommendations) {
              const prescriptionsSections = this.sections.filter(x => x.type === 3);
              for (const section of prescriptionsSections) {
                for (const item of mandatory) {
                  if (section.referrals.some(x => x.name === item.manipulationName)) continue;

                  if (this.patientAge !== null && this.patientAge !== undefined) {
                    if (item.maxAge !== null && item.maxAge < this.patientAge) continue;
                    if (item.minAge !== null && item.minAge > this.patientAge) continue;
                  }
                  if (item.gender === 'f' && this.patientGender !== 'f') continue;
                  if (item.gender === 'm' && this.patientGender !== 'm') continue;

                  section.referrals.push({
                    manipulationId: item.manipulationId,
                    name: item.manipulationName
                  });

                }
              }
            }
          }

        }
      );
  }

  updateRecord(value: Record) {
    this.model = { ...this.model, title: value.title, visitReason: value.visitReason };
  }

  updateSection(oldValue: RecordSection, newValue: RecordSection): void {
    const index: number = this.sections.findIndex(x => x.id === oldValue.id);

    if (index !== -1) {
      this.sections.splice(index, 1, newValue);
    }

    this._dirtySections[newValue.id] = true;

    if (newValue.type === 2 && newValue.diagnosisType === 1 && oldValue.diagnosisCode !== newValue.diagnosisCode) {
      if (newValue.diagnosisCode) {
        this.loadRecommendations(newValue.diagnosisCode);
      } else {
        this.recommendations = [];
      }
    }

    if (newValue.type === 8 && newValue.metrics && newValue.metrics.length > 0) {
      const metrics = this.sections.map(x => x.metrics).reduce((x, y) => x.concat(y), []);
      const values: { [key: string]: string } = {};
      metrics.forEach(x => values[x.systemName] = x.value);

      for (const metric of metrics) {
        if (metric.format === 3 && metric.formula) {
          try {
            const data = values;
            const value = eval(metric.formula);
            metric.value = isFinite(parseFloat(value)) ? value : ''
          }
          catch (e) {
            console.info(e);
            metric.value = '';
          }

        }
      }

      for (const metric of metrics) {
        const index = this.personMetrics.findIndex(x => x.systemName === metric.systemName);

        if (index === -1 && !metric.value) {
          continue;
        }

        if (index === -1 && !!metric.value) {
          this.personMetrics.push({
            systemName: metric.systemName,
            displayName: metric.displayName,
            description: metric.description,
            value: metric.value,
            units: metric.units
          });

          continue;
        }

        if (index !== -1 && !metric.value) {
          this.personMetrics.splice(index, 1);
          continue;
        }

        this.personMetrics[index].value = metric.value;
      }

      this.personMetrics = [...this.personMetrics];

      const parts = metrics.map(x => {
        let value = x.value || '';

        if (x.value && x.units) {
          value = `${x.value} ${x.units}`;
        }

        return {
          pattern: `<lp( class="ql-lazy-placeholder"){0,1} data-placeholder="${x.systemName}" title="${x.systemName}">.*?</lp>`,
          sub: `<lp class="ql-lazy-placeholder" data-placeholder="${x.systemName}" title="${x.systemName}">${value} </lp>`
        };
      });

      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i];

        if (section.type === 1 && !section.readonly && section.content) {
          let content = section.content;

          for (const part of parts) {
            content = content.replace(new RegExp(part.pattern, 'gmi'), part.sub);
          }

          if (section.content !== content) {
            this.sections.splice(i, 1, { ...section, content });
          }
        }

        if (section.type === 8 && section.metrics && section.metrics.length > 0) {
          for (let i = 0; i < section.metrics.length; i++) {
            for (let metric of metrics) {
              if (section.metrics[i].format === 3 && section.metrics[i].systemName === metric.systemName) {
                section.metrics.splice(i, 1, { ...metric });
              }

            }
          }
        }
      }
    }
  }

  sectionInvalid(section: RecordSection): boolean {
    if (!section) return false;

    if (section.type === 4 && this.appointmentReasonRequired && !section.appointmentReason) return false;

    if (!section.mandatory) return false;

    if (section.type === 7 && section.show && section.outcomeType === 0) return true;
    if (section.type === 7 && section.show && section.outcomeType === 1 && !section.appointmentAt) return true;
    if (section.type === 7 && section.show && section.outcomeType === 2 && !section.closedAt) return true;
    if (section.type === 8 && section.show && section.metrics.some(x => [1, 2].includes(x.format) && !x.value)) return true;

    switch (section.type) {
      case 1: {

        return $(section.content)
          .filter(":not(:empty)")
          .filter((index: number, x: HTMLElement): boolean => x.innerHTML !== "<br>"
            && x.innerHTML !== "&nbsp;"
            && !!x.innerHTML.trim()
          )
          .length === 0;
      }
      case 2: return !section.diagnosisCode;
      case 3: return !section.referrals || section.referrals.length === 0;
      case 4: return !section.appointmentDate;
      case 5: return !section.closingDate || !section.caseResultId;
      default: return false;
    }
  }

  sectionDirty = (section: RecordSection) => this.dirty || this._dirtySections[section.id];

  tracker = (x: RecordSection, y: RecordSection) => x && y && x.id === y.id;

  copyParameterValue(parameter: { value: string, title: string }): void {
    if (!parameter.value) return;

    this.toastrService.success(`${parameter.title} - ${parameter.value}`, 'Скопировано');

    this.copy(parameter.value);
  }

  private addQuizRun(selected: QuestionnaireResponse): Observable<AddQuizRunResponse> {
    return this.peopleService.AddQuizRun({
      id: this.personId,
      request: {
        id: selected.id,
        title: `${selected.title} ${moment().format("DD.MM.YYYY")}`
      }
    })
      .pipe(
        tap((): void => {
          this.quizRuns$ = this.peopleService.QuizRuns(this.personId);
        })
      );
  }

  private copy(value: string): void {
    const textarea: HTMLTextAreaElement = document.createElement("textarea");
    textarea.value = value;

    textarea.style.position = "fixed";
    textarea.style.top = "-1000px";

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

  }

  selectQuizRun(run: PersonQuizRun) {

    this.peopleService.QuizRun({ id: this.personId, runId: run.runId })
      .subscribe(
        (response: PersonQuizRunResult): void => {
          const value = `Опросник ${response.quizTitle} ${response.date}: ${response.score} ${response.interpretation}`;

          this.copy(value);
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {

            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }
          this.toastrService.error("Не удалось получить результат опросника", "Ошибка");
        }
      );
  }

  selectAttachment(data: { id: number, testOrderId: number }) {
    this.myService.AccessToken({ parameters: { id: data.id.toString(), testOrderId: data.testOrderId.toString() } })
      .subscribe(
        (response: AccessTokenResponse) => {
          const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
          modal.componentInstance.path = `/api/clinic/v1/observations/attachment?token=${response.token}`;
        }
      );
  }

  selectRecord(record: Record) {
    this.myService.AccessToken({ parameters: { id: record.id.toString() } })
      .subscribe(
        (response: AccessTokenResponse) => {
          const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
          modal.componentInstance.path = `/records/preview?token=${response.token}`;
          modal.componentInstance.canPrint = this.model.signed;
        },
        (): void => {
          this.toastrService.error("Не удалось сгенерировать документ", "Ошибка");
        });
  }

  selectLabTest(test: PersonLabResult) {
    if (!test || !test.value) return;

    const value = `${test.name}: ${test.value} ${test.units}`;

    this.toastrService.success(value, 'Скопировано');

    this.copy(value);
  }

  selectLabResultAsTable(result: PersonLabOrder) {
    console.log('Not implemented');
  }

  selectLabResultAsList(result: PersonLabOrder) {
    console.log('Not implemented');
  }

  selectLabResult(result: PersonLabOrder) {
    if (!result) return;

    const parts = [];

    for (const test of result.groups) {
      parts.push(...this.formatTest(test));
    }

    this.toastrService.success(`Результаты исследования от ${result.visitDate} (${result.labName})`, 'Скопировано');

    this.copy(parts.join('\n'));
  }

  openLabResult(result: PersonLabOrder) {
    const modalRef = this.modal.open(LabResultsModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: LabResultsModalComponent = modalRef.componentInstance;

    componentRef.item = result;

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe((items: PersonLabResult[]) => {
      const parts = [];

      for (const test of items) {
        parts.push(...this.formatTest(test));
      }

      this.toastrService.success(`Выбранные результаты исследования «${result.labName}» от ${result.visitDate}`, 'Скопировано');

      this.copy(`${result.visitDate}\n${parts.join('; ')}`);

      modalRef.close();
    });
  }

  formatTest(test: PersonLabResult) {
    if (!test) return [];
    if (test.tests && test.tests.length > 0) return [test.groupName, ...test.tests.map(x => this.formatTest(x))];

    return [`${test.name}: ${test.value} ${test.units}`];
  }

  selectMetric(metric: PersonMetric) {
    this.copy(metric.units ? `${metric.value} ${metric.units}` : metric.value);

    this.toastrService.success(`Показатель ${metric.displayName} скопирован`);
  }

  openQuestionaryModal(): void {

    const options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      windowClass: "modal-default-size"
    }

    const modalRef: NgbModalRef = this.modal.open(QuestionaryModalComponent, options);

    modalRef.componentInstance.hasPhone = !!this.phone;

    modalRef.componentInstance.onPrintQuiz
      .subscribe((selected: QuestionnaireResponse): void => {
        this.addQuizRun(selected)
          .pipe(
            switchMap((response: AddQuizRunResponse): Observable<AccessTokenResponse> => this.myService.AccessToken({
              parameters: {
                id: response.quizId.toString(),
                quizRunId: response.runId,
                name: "QuestionnaireReport"
              }
            })),
            map((response: AccessTokenResponse): string => `api/v1/Reports/Prepared?token=${response.token}&type=html`)
          )

          .subscribe(
            (url: string): void => {
              this.previewPrintingSource.next(url);

              modalRef.componentInstance.dismiss();
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }

              this.toastrService.error("Не удалось распечатать опросник", "Ошибка");
            }
          );

      });

    modalRef.componentInstance.onSendQuiz
      .subscribe((selected: QuestionnaireResponse): void => {

        this.addQuizRun(selected)
          .subscribe(
            (response: AddQuizRunResponse): void => {
              this.patientsService.ShareQuizRun({
                id: this.patientId,
                request: {
                  quizId: response.quizId,
                  runId: response.runId
                }
              })
                .subscribe(
                  (): void => { },
                  (response: HttpErrorResponse): void => {
                    if (response.status === 400) {
                      this.toastrService.warning(response.error.message, "Ошибка");
                      return;
                    }

                    this.toastrService.error("Не удалось отправить ссылку на опросник", "Ошибка");
                  }
                );
            },
            (): void => { }
          );
      });
  }

  addReview(): void {
    window.open(`/quality-control/records/${this.recordId}/reviews/new`)
  }

  openReviews(): void {
    this.reviewsService.RecordReviewsAsync(this.recordId)
      .subscribe((reviews: RecordReviewSummary[]): void => {
        const options: NgbModalOptions = {
          size: 'lg',
          backdrop: 'static',
          centered: true,
          windowClass: "modal-default-size"
        }

        const modalRef: NgbModalRef = this.modal.open(ReviewsModalComponent, options);
        const componentRef: ReviewsModalComponent = modalRef.componentInstance;

        componentRef.reviews = reviews; componentRef.onCancel.subscribe((): void => {
          modalRef.close();
        });

        componentRef.onAdd.subscribe((): void => {
          modalRef.close();
          this.router.navigateByUrl(`/quality-control/records/${this.recordId}/reviews/new`);
        });

        componentRef.onOpen.subscribe((review: RecordReviewSummary): void => {
          modalRef.close();
          this.router.navigateByUrl(`/quality-control/records/${this.recordId}/reviews/${review.id}`);
        });

        componentRef.onPrint.subscribe((review: RecordReviewSummary): void => {
          this.printReview(review.id);
        });
      });


  }

  private printReview(recordReviewId: number): void {
    const parameters: { [key: string]: string } = {
      recordReviewId: `${recordReviewId}`,
      name: 'RecordReviewReport'
    };

    this.reportsUtilityService.printReport(parameters);
  }

  signDocument(): void {
    const name = `${this.protocolName} ${this.model.signedByName}`;


    this.myService.AccessToken({ parameters: { id: this.recordId.toString() } })
      .subscribe(
        (response: AccessTokenResponse) => {
          const options: NgbModalOptions = { backdrop: 'static', size: 'lg' };
          const modalRef: NgbModalRef = this.modal.open(SignDocumentModalComponent, options);
          const componentRef: SignDocumentModalComponent = modalRef.componentInstance;

          componentRef.onClose.subscribe(() => {
            modalRef.close();
          });

          componentRef.path = `/records/preview?token=${response.token}`;
          componentRef.canPrint = true;
          componentRef.filename = name;
          componentRef.recordId = this.recordId;
        },
        (): void => {
          this.toastrService.error("Не удалось сгенерировать документ", "Ошибка");
        });

  }

  get showFinishBtn(): boolean {
    return this.sections.some(x => x.type === 5 && x.show);
  }

  openTreatmentPrescriptionsModal(section: RecordSection): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true, size: 'lg' };
    const modalRef = this.modal.open(TreatmentPrescriptionsModalComponent, options);
    const componentRef: TreatmentPrescriptionsModalComponent = modalRef.componentInstance;

    componentRef.gender = this.patientGender;
    componentRef.date = moment(this.created, "DD.MM.YYYY");
    componentRef.dob = moment(this.patientDob, "DD.MM.YYYY");
    componentRef.codes = this.sections.filter(x => x.type === 2 && x.show && x.diagnosisCode).map(x => x.diagnosisCode);

    componentRef.prescriptions = section.treatmentPrescriptions
      .map(x => ({
        id: x.id,
        treatmentName: x.treatmentName,
        dosage: x.dosage,
        duration: x.duration,
        schema: x.schema,
        listOrder: x.listOrder,
        analogs: []
      }));

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((value: TreatmentPrescriptionPayload[]): void => {
      section.treatmentPrescriptions = value.map(x => ({
        id: x.id,
        treatmentName: x.treatmentName,
        dosage: x.dosage,
        schema: x.schema,
        duration: x.duration,
        listOrder: x.listOrder
      }));

      modalRef.close();
    })
  }

  saveDraftToDb() {
    console.debug('local draft saving is not implimented');
  }

  handleColorMarkError(response: HttpErrorResponse, message = '') {
    if (response.status === 400 && response.error) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Требуется разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Метка не найдена', 'Ошибка'); break;
          case 2: this.toastrService.warning('Метка пациента не найдена', 'Ошибка'); break;
          case 3: this.toastrService.warning('Пациент не найден', 'Ошибка'); break;
          case 4: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 5: this.toastrService.warning('Указанная метка уже добавлена', 'Ошибка'); break;
          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }
    } else {
      this.toastrService.error(message, 'Ошибка');
    }
  }

  addMark() {
    const modalRef = this.personMarksModalService.open();

    const componentRef: PersonMarkModalComponent = modalRef.componentInstance;

    componentRef.colorMarks = this.colorMarks;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((value: PersonMarkModalPayload) => {
      componentRef.processing = true;

      this.personMarksService.CreatePersonMarkAsync({ personId: this.personId, request: { colorMarkId: value.colorMarkId, description: value.description } })
        .subscribe(
          () => {
            this.toastrService.success('Метка добавлена', 'Успешно');
            componentRef.processing = false;
            modalRef.close();

            this.loadPersonMarks();
          },
          (response: HttpErrorResponse) => {
            componentRef.processing = false;
            this.handleColorMarkError(response, 'Не удалось добавить метку');
          }
        );


    });

  }

  async removeMark(mark: PersonMark) {
    const confirmed = await this.confirmationService.open({ message: `Метка ${mark.colorMarkName} будет удалена. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.personMarksService.RemovePersonMarkAsync({ personId: this.personId, id: mark.id })
      .subscribe(
        () => {
          this.toastrService.success(`Метка ${mark.colorMarkName} удалена`, 'Успешно');

          this.loadPersonMarks();
        },
        (response: HttpErrorResponse) => {
          this.handleColorMarkError(response, 'Не удалось удалить метку');
        }
      );

  }

  changeTemplate() {
    const modalRef = this.modal.open(ChangeTemplateModalComponent, { centered: true, backdrop: 'static', size: 'lg' });
    const componentRef: ChangeTemplateModalComponent = modalRef.componentInstance;

    componentRef.templateId = this.model.templateId;
    componentRef.patientId = this.model.patientId;
    componentRef.contractItemId = this.model.contractItemId;



    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((templateId: number) => {
      componentRef.processing = true;

      const payload = this.gatherRequest();

      this.recordsService.ChangeRecordTemplateAsync({ id: this.model.id, request: { templateId, sections: payload.sections } })
        .subscribe(
          () => {
            forkJoin({
              sections: this.recordsService.Sections(this.model.id),
              record: this.recordsService.Record(this.model.id)
            }).subscribe(
              (response: { sections: RecordSection[], record: Record }) => {
                this.model = response.record;
                this.sections = response.sections;

                modalRef.close();
                componentRef.processing = true;

                this.toastrService.success('Шаблон протокола заменен', 'Успешно');
              },
              () => {
                this.toastrService.error('Не удалось загрузить данные. Перезагрузите страницу', 'Ошибка');
                componentRef.processing = true;
              }
            );
          },
          (response: HttpErrorResponse) => {
            componentRef.processing = true;

            if (response.status !== 400) {
              this.toastrService.error('Не удалось сменить шаблон протокола', 'Ошибка');
              return;
            }

            switch (response.error.status) {
              case 1: this.toastrService.warning('Шаблон не найден', 'Ошибка'); break;
              case 5: this.toastrService.warning('Документ не найден', 'Ошибка'); break;
              case 7: this.toastrService.warning('Для выполнения операции отсутствует необходимое разрешение', 'Ошибка'); break;
              case 39: this.toastrService.warning('Выбран текущий шаблон протокола', 'Ошибка'); break;

              default: this.toastrService.error('Не удалось сохранить протокол', 'Ошибка'); break;
            }

          }
        );


    });

  }

}

export interface Prescription {
  name: string;
  dose?: number;
  doseUnit?: string;

  quantity?: number;
  quantityUnit?: string;

  repeats?: number;
  period?: number;
  periodUnit?: 0 | 1 | 2 | 3;

  duration?: number;
  durationUnit?: 0 | 1 | 2 | 3;// Однократно, день, неделя, месяц

  method?: string;
  time?: string;
  food?: string;
  begin?: string;
}

