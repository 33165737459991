import { Injectable } from '@angular/core';
import { EmployeesService, PartnerPlansService, SpecialitiesService, ServiceCategoriesService, CompaniesService, CodeSystemsService, WageSchemasService } from '../../../generated/services';
import { EmployeeEditorModel, PartnerPlanListItem, PartnerPlansResponse, Speciality, ServiceCategory, ServiceCategoriesResponse, Company, SpecialitiesResponse, CodeSystem, WageSchema } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PbxOperatorViewModel } from '../../../generated/models/pbx-operator-view-model';
import { PbxOperatorsService } from '../../../generated/services/pbx-operators.service';
import { PbxOperatorsResponse } from '../../../generated/models/pbx-operators-response';

@Injectable({ providedIn: "root" })
export class EmployeeResolver implements Resolve<EmployeePayload> {
  constructor(
    private employeesService: EmployeesService,
    private plansService: PartnerPlansService,
    private specialitiesService: SpecialitiesService,
    private categoriesService: ServiceCategoriesService,
    private companiesService: CompaniesService,
    private phonesService: PbxOperatorsService,
    private wageSchemasService: WageSchemasService,
    private codeSystemsService: CodeSystemsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmployeePayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    let employeeLoader: Observable<EmployeeEditorModel> = null;

    if (id) {
      employeeLoader = this.employeesService.Employee(id);
    } else {
      employeeLoader = from([{}]);
    }
    const plansLoader: Observable<PartnerPlanListItem[]> = this.plansService.Rates({ Size: 0, ActiveOnly: true })
      .pipe(
        map((response: PartnerPlansResponse): PartnerPlanListItem[] => response.plans)
      );

    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(
        map((response: SpecialitiesResponse): Speciality[] => response.items)
      );
    const categoriesLoader: Observable<ServiceCategory[]> = this.categoriesService.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
      );

    const companiesloader: Observable<Company[]> = this.companiesService.Companies({});

    const phonesLoader: Observable<PbxOperatorViewModel[]> = this.phonesService.PbxOperators({ Page: 1, Size: 0 })
      .pipe(
        map((response: PbxOperatorsResponse): PbxOperatorViewModel[] => response.items)
      );

    const wageSchemasLoader: Observable<WageSchema[]> = this.wageSchemasService.WageSchemas({ Page: 1, Size: 0 });

    const codeSystemsLoader: Observable<CodeSystem[]> = this.codeSystemsService.CodeSystems({ Page: 1, Size: 0 });

    return forkJoin({
      employee: employeeLoader,
      plans: plansLoader,
      specialities: specialitiesLoader,
      categories: categoriesLoader,
      companies: companiesloader,
      phones: phonesLoader,
      codeSystems: codeSystemsLoader,
      wageSchemas: wageSchemasLoader
    });
  }
}

export class EmployeePayload {
  public employee: EmployeeEditorModel;
  public plans: PartnerPlanListItem[];
  public specialities: Speciality[];
  public categories: ServiceCategory[];
  public companies: Company[];
  public phones: PbxOperatorViewModel[];
  public wageSchemas: WageSchema[];
  public codeSystems: CodeSystem[];
}
