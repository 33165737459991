import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { AppointmentHistoryItem, Attendance, AttendanceCallReport, AttendanceVisit } from '../../../../generated/models';
import { ScheduleHistoryService } from '../../../../generated/services';

@Component({
  selector: 'mp-attendance-item',
  templateUrl: './attendance-item.component.html',
  styleUrls: ['./attendance-item.component.scss']
})
export class AttendanceItemComponent {
  @Input() canRemoveCalls = false;
  @Input() item: Attendance;

  @HostBinding('class.success') get success() { return this.item && this.item.visits.length > 0; }

  @HostBinding('class.warning')
  get warning() {
    if (!this.item) return false;
    if (this.item.visits && this.item.visits.length > 0) return false;
    if (!this.item.calls || this.item.calls.length === 0) return true;
    if (this.item.calls.some(c => c.reasons && c.reasons.every(r => r.reasonable))) return true;

    return false;
  }

  @HostBinding('class.danger') get danger() { return this.item && this.item.calls && this.item.calls.some(c => c.reasons && c.reasons.some(r => !r.reasonable)); }

  @Output() onCall = new EventEmitter<void>();
  @Output() onTypeClick = new EventEmitter<void>();
  @Output() onRemoveCall = new EventEmitter<AttendanceCallReport>();
  @Output() onUpdateCall = new EventEmitter<AttendanceCallReport>();

  get showBtn(): boolean {
    if (!this.item) return false;
    //if (this.item.visits && this.item.visits.length > 0) return false;
    if (this.item.calls && this.item.calls.length > 0) return false;

    return true;
  }

  get canManage(): boolean {
    if (!this.item) return false;
    if (!this.item.calls || this.item.calls.length === 0) return false;

    return this.canRemoveCalls;
  }

  get date(): string { return !this.item && !this.item.date ? '' : this.item.date.substr(0, 10); }
  get time(): string { return !this.item && !this.item.date ? '' : this.item.date.substr(11, 5); }
  get phone(): string { return !this.item || !this.item.phone ? '' : this.format(this.item.phone); }

  get issues(): string[] {
    if (!this.item) return [];
    const issues = [];

    if (this.item.issues & 1) issues.push('Нет посещения специалиста, к которому был записан пациент');
    if (this.item.issues & 2) issues.push('Нет услуги, на которую был записан пациент');
    if (this.item.issues & 4) issues.push('Имя или отчество не совпадает');
    if (this.item.issues & 8) issues.push('Недопустимые символы в ФИО');
    if (this.item.issues & 16) {
      //issues.push(`Опечатка в ФИО: ${this.item.patientName} / ${this.item.visits.map(x => this.patientName(x)).filter(x => this.item.patientName !== x).join(' / ')}`);
      issues.push('Возможная опечатка в фамилии пациента')
    }

    return issues;
  }

  loading = false;
  history: AppointmentHistoryItem[] = [];

  constructor(private historyService: ScheduleHistoryService) { }

  format = (phone: string) => phone && phone.length === 11 ? `+7 (${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7, 2)}-${phone.substr(9, 2)}` : phone;
  patientName = (visit: AttendanceVisit): string => [visit.patientLastName, visit.patientFirstName, visit.patientMiddleName].filter(x => x).join(' ').trim();
  call = () => this.onCall.emit();
  removeCall = () => this.onRemoveCall.emit(this.item.calls[0]);
  updateCall = () => this.onUpdateCall.emit(this.item.calls[0]);

  clickType = () => this.onTypeClick.emit();

  async load() {
    this.loading = true;
    this.history = [];

    const result = await this.historyService.HistoryAsync({ scheduleId: this.item.scheduleId, Date: this.item.date }).toPromise();

    this.history = result.items;
    this.loading = false;
  }
}
