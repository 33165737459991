/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonMark } from '../models/person-mark';
import { PersonMarksControllerErrorsResponse } from '../models/person-marks-controller-errors-response';
import { CreatePersonMarkRequest } from '../models/create-person-mark-request';
@Injectable({
  providedIn: 'root',
})
class PersonMarksService extends __BaseService {
  static readonly PersonMarksAsyncPath = '/api/v2/persons/{personId}/marks';
  static readonly CreatePersonMarkAsyncPath = '/api/v2/persons/{personId}/marks';
  static readonly RemovePersonMarkAsyncPath = '/api/v2/persons/{personId}/marks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param personId undefined
   * @return Success
   */
  PersonMarksAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonMark>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/marks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonMark>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  PersonMarksAsync(personId: number): __Observable<Array<PersonMark>> {
    return this.PersonMarksAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonMark>)
    );
  }

  /**
   * @param params The `PersonMarksService.CreatePersonMarkAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreatePersonMarkAsyncResponse(params: PersonMarksService.CreatePersonMarkAsyncParams): __Observable<__StrictHttpResponse<PersonMarksControllerErrorsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/persons/${params.personId}/marks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonMarksControllerErrorsResponse>;
      })
    );
  }
  /**
   * @param params The `PersonMarksService.CreatePersonMarkAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreatePersonMarkAsync(params: PersonMarksService.CreatePersonMarkAsyncParams): __Observable<PersonMarksControllerErrorsResponse> {
    return this.CreatePersonMarkAsyncResponse(params).pipe(
      __map(_r => _r.body as PersonMarksControllerErrorsResponse)
    );
  }

  /**
   * @param params The `PersonMarksService.RemovePersonMarkAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `id`:
   *
   * @return Success
   */
  RemovePersonMarkAsyncResponse(params: PersonMarksService.RemovePersonMarkAsyncParams): __Observable<__StrictHttpResponse<PersonMarksControllerErrorsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/persons/${params.personId}/marks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonMarksControllerErrorsResponse>;
      })
    );
  }
  /**
   * @param params The `PersonMarksService.RemovePersonMarkAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `id`:
   *
   * @return Success
   */
  RemovePersonMarkAsync(params: PersonMarksService.RemovePersonMarkAsyncParams): __Observable<PersonMarksControllerErrorsResponse> {
    return this.RemovePersonMarkAsyncResponse(params).pipe(
      __map(_r => _r.body as PersonMarksControllerErrorsResponse)
    );
  }
}

module PersonMarksService {

  /**
   * Parameters for CreatePersonMarkAsync
   */
  export interface CreatePersonMarkAsyncParams {
    personId: number;
    request?: CreatePersonMarkRequest;
  }

  /**
   * Parameters for RemovePersonMarkAsync
   */
  export interface RemovePersonMarkAsyncParams {
    personId: number;
    id: number;
  }
}

export { PersonMarksService }
