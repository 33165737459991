import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mp-observation-comment-modal',
  templateUrl: './observation-comment-modal.component.html',
  styleUrls: ['./observation-comment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ObservationCommentModalComponent implements OnInit {
  @Input() comment: string;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<string>();

  commentControl = new FormControl(undefined, [Validators.maxLength(255)]);

  ngOnInit() {
    this.commentControl.setValue(this.comment);
  }

  cancel = () => this.onCancel.emit();

  submit() {
    this.commentControl.markAsTouched();

    if (!this.commentControl.valid) return;

    this.onSubmit.emit(this.commentControl.value);
  }
}
