import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Service, ServiceCategory, PriceSetPrice, SelectedService, PatientPrescriptedManipulation, PatientPrescriptedService, CompanyServiceGroup, PriceSetGroupServicePrice } from '../../../../generated/models';

import * as moment from 'moment';

function serviceComparer(a: Service, b: Service): number {
  const aname = a.name.trim().replace(/\s{2,}/g, ' ');
  const bname = b.name.trim().replace(/\s{2,}/g, ' ');

  return (a.code - b.code) || aname.localeCompare(bname);
}

function itemComparer(a: ServiceListItem, b: ServiceListItem): number {
  const aname = a.serviceName.trim().replace(/\s{2,}/g, ' ');
  const bname = b.serviceName.trim().replace(/\s{2,}/g, ' ');

  return ((a.serviceCode || 0) - (b.serviceCode || 0)) || aname.localeCompare(bname);
}

@Component({
  selector: 'mp-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit, OnDestroy, OnChanges {
  destroy$ = new Subject<void>();

  private _searchResult = new BehaviorSubject<ServiceListItem[]>([]);

  searchApplied = false;

  @Input() loading: boolean;
  @Input() showLimits = false;

  @Input() discount: number;

  @Input() canAddService: boolean;

  @Input() services: Service[] = [];
  @Input() groups: CompanyServiceGroup[] = [];
  @Input() categories: ServiceCategory[] = [];
  @Input() prices: PriceSetPrice[] = [];
  @Input() groupsPrices: PriceSetGroupServicePrice[] = [];
  @Input() prescriptions: PatientPrescriptedManipulation[] = [];

  @Input() selectedServices: SelectedService[] = [];

  @Output() selectService = new EventEmitter<SelectedService[]>();

  get searchResult$(): Observable<ServiceListItem[]> { return this._searchResult.asObservable(); }

  get hasDiscount(): boolean { return this.discount > 0 && this.discount <= 100; }

  search = new FormControl("");

  groupedPrescription: DoctorsPrescriptions[] = [];
  serviceCategories: ServiceListCategory[] = [];
  items: ServiceListItem[] = [];
  favourites: ServiceListItem[] = [];

  constructor() {
    this.search.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: string): void => {
          this.searchApplied = !!value;

          this._searchResult.next([]);

          if (this.searchApplied) {
            const filtered: ServiceListItem[] = this.items
              .filter(x => x.grossPrice > 0
                && (new RegExp(value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "gi").test(`${x.serviceCode} ${x.serviceName}`)
                  || x.items.some(s => new RegExp(value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), "gi").test(`${s.serviceCode} ${s.serviceName}`))));

            if (/^\d{1,}$/g.test(value)) {
              const code: number = parseInt(value, 10);

              const codeFiltered: ServiceListItem[] = filtered.filter(x => x.serviceCode === code || x.items.some(s => s.serviceCode === code));

              if (codeFiltered.length === 1 && (codeFiltered[0].canStack || !codeFiltered[0].selected) && codeFiltered[0].items.length === 0) {
                this.selectItem(codeFiltered[0]);
                this.search.setValue("");
              }
            }

            this._searchResult.next(filtered.sort(itemComparer));
          }
        }
      );
  }

  ngOnChanges(changes: SimpleChanges) {

    const prices: PriceSetPrice[] = changes['prices'] ? changes['prices'].currentValue : this.prices;
    const groupsPrices: PriceSetGroupServicePrice[] = changes['groupsPrices'] ? changes['groupsPrices'].currentValue : this.groupsPrices;
    const discount: number = changes['discount'] ? changes['discount'].currentValue : this.discount;
    const selectedServices: SelectedService[] = changes['selectedServices'] ? changes['selectedServices'].currentValue : this.selectedServices;

    if (changes["prescriptions"]) {
      const groups: DoctorsPrescriptions[] = [];

      const manipulations: PatientPrescriptedManipulation[] = changes["prescriptions"] ? changes["prescriptions"].currentValue : this.prescriptions;

      for (const manipulation of manipulations) {

        let group = groups.find(x => x.id === manipulation.authorId);

        if (!group) {
          group = {
            id: manipulation.authorId,
            name: manipulation.authorName,
            total: 0,
            actionRequired: 0,
            notAvailable: 0,
            available: 0,
            hasLabTests: false,
            prescriptions: []
          };

          groups.push(group);
        }

        if (manipulation.services.some(x => !!x.fulfilled)) {
          continue;
        }

        //if (selectedServices.some(x => x.prescriptionId === manipulation.id)) {
        //  continue;
        //}

        const manipulationItem: ServiceListManipulation = {
          id: manipulation.id,
          date: manipulation.date,

          authorId: manipulation.authorId,
          authorName: manipulation.authorName,

          manipulationId: manipulation.manipulationId,
          manipulationName: manipulation.manipulationName,

          sectionId: manipulation.sectionId,
          sectionRecordId: manipulation.sectionRecordId,

          services: manipulation.services.map(service => ({
            categoryId: 0,
            categoryName: '',
            favourite: false,

            canStack: service.serviceCanStack,
            isExpress: service.serviceIsExpress,
            serviceId: service.serviceId,
            serviceCode: service.serviceCode,
            serviceName: service.serviceName,
            kdlCode: service.serviceKdlNumber,
            timing: service.serviceTiming,

            specialityId: service.serviceSpecialityId,
            specialityName: service.serviceSpecialityName,

            manipulationId: service.manipulationId,
            fulfilled: service.fulfilled,
            isDefault: service.isDefault,

            total: 0,
            available: 0,
            discount: 0,
            finalPrice: 0,
            grossPrice: 0,

            selected: selectedServices.some(x => x.prescriptionId === manipulation.id),

            items: []
          }))
        };


        group.prescriptions.push(manipulationItem);

        group.total += 1;
        group.available += manipulation.services.length === 1 ? 1 : 0;
        group.actionRequired += manipulation.services.length > 1 ? 1 : 0;
        group.notAvailable += manipulation.services.length === 0 ? 1 : 0;
        group.hasLabTests = group.hasLabTests || (manipulation.services.length === 1 && manipulation.services.some(x => !!x.serviceKdlNumber));
      }

      this.groupedPrescription = groups.filter(x => x.total > 0);
    }


    if (changes['prices'] || changes['groupsPrices'] || changes['discount'] || changes['selectedServices']) {
      this.recalculateItems(prices || [], groupsPrices || [], discount, selectedServices);
    }

    if (changes["prescriptions"] || changes['selectedServices'] || changes['prices'] || changes['discount']) {

      this.recalculatePrescriptions(prices || [], discount, selectedServices || [])
    }
  }

  recalculateItems(prices: PriceSetPrice[], groupsPrices: PriceSetGroupServicePrice[], discount: number, selectedServices: SelectedService[]) {
    const discountRate = (100 - (discount || 0)) / 100;

    for (const item of this.items) {

      item.grossPrice = 0;
      item.finalPrice = 0;

      if (item.items.length > 0) {

        for (const service of item.items) {
          const price = groupsPrices.find(x => x.groupId === service.groupId && x.serviceId === service.serviceId);

          service.discount = discount;

          if (!!price) {
            service.grossPrice = price.finalPrice;
            service.finalPrice = price.finalPrice; //price.finalPrice * discountRate;
          } else {
            service.grossPrice = 0;
            service.finalPrice = 0;
          }

          item.grossPrice += service.grossPrice;
          item.finalPrice += service.finalPrice;

          service.selected = selectedServices.some(x => x.serviceId === service.serviceId);
        }

        item.discount = discount;
        item.selected = item.items.some(x => x.selected);

        continue;
      }

      const price = prices.find(x => x.serviceId === item.serviceId);

      item.discount = discount || 0;
      item.selected = selectedServices.some(x => x.serviceId === item.serviceId);

      if (!!price) {
        item.grossPrice = price.amount;
        item.finalPrice = price.amount * discountRate;
        item.total = price.total;
        item.available = price.available;
      } else {
        item.grossPrice = 0;
        item.finalPrice = 0;
        item.total = 0;
        item.available = 0;
      }

    }
  }

  recalculatePrescriptions(prices: PriceSetPrice[], discount: number, selectedServices: SelectedService[]) {
    const discountRate = (100 - (discount || 0)) / 100;

    for (const group of this.groupedPrescription) {
      group.total = 0;
      group.available = 0;
      group.actionRequired = 0;
      group.notAvailable = 0;
      group.hasLabTests = false;

      for (const prescription of group.prescriptions) {
        for (const item of prescription.services) {

          const price = prices.find(x => x.serviceId === item.serviceId);

          item.discount = discount || 0;
          item.selected = selectedServices.some(x => x.serviceId === item.serviceId);

          if (!!price) {
            item.grossPrice = price.amount;
            item.finalPrice = price.amount * discountRate;
            item.total = price.total;
            item.available = price.available;
          } else {
            item.grossPrice = 0;
            item.finalPrice = 0;
            item.total = 0;
            item.available = 0;
          }
        }

        group.total += 1;
        group.available += prescription.services.length === 1 && !prescription.services.some(x => x.selected) ? 1 : 0;
        group.actionRequired += prescription.services.length > 1 && !prescription.services.some(x => x.selected) ? 1 : 0;
        group.notAvailable += prescription.services.length === 0 ? 1 : 0;
        group.hasLabTests = group.hasLabTests || (prescription.services.length === 1 && prescription.services.some(x => !!x.kdlCode));

      }

    }
  }

  ngOnInit() {
    this.items = this.services.map(service => ({
      serviceId: service.id,
      serviceName: service.name,
      serviceCode: service.code,

      timing: service.timing,
      kdlCode: service.kdlCode,
      isExpress: service.isExpress,

      specialityId: service.specialityId,
      specialityName: service.specialityName,

      categoryId: service.categoryId,
      categoryName: service.categoryName,

      favourite: service.favorite,
      canStack: service.canStack,

      grossPrice: 0,
      finalPrice: 0,

      total: 0,
      available: 0,

      discount: 0,

      selected: false,
      isDefault: false,
      items: []
    }))

    const groups: ServiceListItem[] = this.groups.map(x => ({
      serviceId: undefined,
      serviceName: x.name,
      serviceCode: undefined,

      timing: undefined,
      kdlCode: undefined,
      isExpress: undefined,

      specialityId: undefined,
      specialityName: undefined,

      categoryId: x.categoryId,
      categoryName: x.categoryName,

      favourite: x.favorite,
      canStack: false,

      grossPrice: 0,
      finalPrice: 0,

      total: 0,
      available: 0,

      discount: 0,

      selected: false,
      isDefault: false,

      items: x.services.map(service => ({
        groupId: x.id,
        groupName: x.name,

        serviceId: service.id,
        serviceName: service.name,
        serviceCode: service.code,

        timing: service.timing,
        kdlCode: service.kdlCode,
        isExpress: service.isExpress,

        specialityId: service.specialityId,
        specialityName: service.specialityName,

        categoryId: service.categoryId,
        categoryName: service.categoryName,

        favourite: service.favorite,
        canStack: service.canStack,

        grossPrice: 0,
        finalPrice: 0,

        total: 0,
        available: 0,

        discount: 0,

        selected: false,
        isDefault: false,
        items: []

      })).sort(itemComparer)
    }))

    this.items.push(...groups);

    for (const category of this.categories.sort((x, y) => x.name.localeCompare(y.name))) {
      const item: ServiceListCategory = {
        id: category.id,
        name: category.name,
        items: this.items.filter(x => x.categoryId === category.id).sort(itemComparer)
      };

      if (item.items.length > 0) {
        this.serviceCategories.push(item);
      }
    }

    this.favourites = this.items.filter(x => x.favourite).sort(itemComparer);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();

    this._searchResult.unsubscribe();
  }

  selectItem(item: ServiceListItem) {
    if (!this.canAddService) return;

    if (!item.finalPrice && item.finalPrice !== 0) return;

    if (!item.canStack && item.selected) {
      return;
    }

    if (item.items.length > 0) {
      this.selectItems(item.items);
    } else {
      this.selectItems([item]);
    }

    this.search.setValue("");
  }

  selectItems(items: ServiceListItem[]) {
    if (!items || items.length === 0) return;

    const result: SelectedService[] = [];

    for (const item of items) {
      const selected = this.convertFromServiceItem(item);

      const prescriptions = this.prescriptions.filter(x => !x.services.some(x => !!x.fulfilled) && x.services.some(s => s.serviceId === selected.serviceId))
        .sort((a, b) => moment(a.date, 'DD.MM.YYYY').valueOf() - moment(b.date, 'DD.MM.YYYY').valueOf());

      if (prescriptions.length > 0) {
        const prescription = prescriptions[0];

        selected.prescribed = prescription.date;
        selected.prescriptionId = prescription.id;
        selected.prescribedById = prescription.authorId;
        selected.prescribedByName = prescription.authorName;
      }
      result.push(selected);
    }

    this.selectService.emit(result);

    this.search.setValue("");
  }

  selectPrescription(payload: { service: ServiceListItem, manipulation: PatientPrescriptedManipulation }) {

    if (!this.canAddService) return;

    if (!payload.service.finalPrice && payload.service.finalPrice !== 0) return;

    if (!payload.service.canStack && payload.service.selected) {
      return;
    }

    this.selectPrescriptions([payload]);
  }

  selectPrescriptions(items: { service: ServiceListItem, manipulation: PatientPrescriptedManipulation }[]) {
    const selected = items.filter(item => {
      if (!item.service.finalPrice && item.service.finalPrice !== 0) return false;

      if (!item.service.canStack && item.service.selected) {
        return false;
      }

      return true;
    }).map(x => this.convertFromPrescription(x.service, x.manipulation));


    this.emitServices(selected);
  }

  emitServices(selectedServices: SelectedService[]) {
    if (!this.canAddService) return;
    if (selectedServices.length == 0) return;

    this.selectService.emit(selectedServices.sort(serviceComparer));
  }

  convertFromPrescription(service: ServiceListItem, manipulation: PatientPrescriptedManipulation): SelectedService {

    const result = this.convertFromServiceItem(service);

    result.prescribed = manipulation.date;
    result.prescribedById = manipulation.authorId;
    result.prescribedByName = manipulation.authorName;

    result.prescriptionId = manipulation.id;

    return result;
  }

  convertFromServiceItem(item: ServiceListItem): SelectedService {

    return {
      id: -1 * Math.floor(Math.random() * 1000000),
      code: item.serviceCode,
      serviceId: item.serviceId,
      name: item.serviceName,
      duration: item.timing,
      performer: null,
      specialityId: item.specialityId,
      specialityName: item.specialityName,
      containers: [],
      kdlCode: item.kdlCode,
      quantity: 1,
      isExpress: item.isExpress,
      grossPrice: item.grossPrice,
      finalPrice: item.finalPrice,

      groupId: item.groupId,
      groupName: item.groupName
    };
  }
}

export interface ServiceListItem {
  serviceId: number;
  serviceName: string;
  serviceCode?: number;

  favourite: boolean;
  canStack: boolean;

  grossPrice: number;
  finalPrice: number;

  total: number;
  available: number;

  discount: number;

  timing: number;

  specialityId: number;
  specialityName: string;

  categoryId: number;
  categoryName: string;

  kdlCode: string;
  isExpress: boolean;

  selected: boolean;

  manipulationId?: number;
  fulfilled?: string;
  isDefault: boolean;

  groupId?: number;
  groupName?: string;

  items: ServiceListItem[];
}

export interface ServiceListManipulation {
  id?: number;
  manipulationId?: number;
  manipulationName?: string;
  sectionId?: number;
  sectionRecordId?: number;
  authorId?: number;
  authorName?: string;
  date?: string;
  services?: ServiceListItem[];
}

interface ServiceListCategory {
  id: number;
  name: string;

  items: ServiceListItem[];
}

export interface DoctorsPrescriptions {
  id: number;
  name: string;
  hasLabTests: boolean;
  prescriptions: ServiceListManipulation[];

  total: number;
  available: number;
  actionRequired: number;
  notAvailable: number;

}
