import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { PersonsVisit } from '../../../../generated/models';

@Component({
  selector: 'mp-visit-list-item',
  templateUrl: './visit-list-item.component.html',
  styleUrls: ['./visit-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitListItemComponent implements OnInit {

  @Input() item: PersonsVisit;
  @Input() initials: string;
  @Input() lastNameDiffer: boolean;
  @Input() canOpen: boolean;

  @Output() onOpen = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();

  get issuer(): string { return this.item && this.item.issuer ? `кем выдан: ${this.item.issuer}` : ''; }
  get issuerCode(): string {
    if (!this.item || !this.item.issuerCode) return '';
    if (this.item.issuerCode.length !== 6) return `код подразделения: ${this.item.issuerCode}`;

    return `код подразделения: ${this.item.issuerCode.substr(0, 3)}-${this.item.issuerCode.substr(3, 3)}`;
  }

  get issued(): string { return this.item && this.item.issued ? `дата выдачи: ${this.item.issued}` : ''; }

  constructor() { }

  ngOnInit() { }

  open() {
    if (!this.canOpen) return;

    this.onOpen.emit();
  }

  edit = () => this.onEdit.emit();

  get date() { return this.item && this.item.date ? this.item.date.substring(0, 10) : ''; }
  get time() { return this.item && this.item.date && this.item.date.length > 11 ? this.item.date.substring(11) : ''; }
}
