import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ReportDiscount } from '../../../../generated/models';

@Component({
  selector: 'mp-sales-list-item',
  templateUrl: './sales-list-item.component.html',
  styleUrls: ['./sales-list-item.component.scss']
})
export class SalesListItemComponent implements OnInit {

  @Input() discount: ReportDiscount;
  @Input() selected: boolean;

  @Output()
  public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }
  public toggle(): void {
    this.onToggle.emit(!this.selected);
  }

}
