import { Injectable, Injector, InjectionToken } from '@angular/core';
import { timer, Subject, Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { tap, delayWhen, timeout, zip, map, filter } from 'rxjs/operators';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { ConnectionLostNotificationComponent } from '../components/connection-lost-notification/connection-lost-notification.component';
import { ConnectionEstablishedNotificationComponent } from '../components/connection-established-notification/connection-established-notification.component';
import { HttpBackend, HttpClient } from '@angular/common/http';

/** Адрес запроса, определяющего доступность сервиса */
export const HEALTHCHECK_PING_URL: InjectionToken<BehaviorSubject<string>> = new InjectionToken<BehaviorSubject<string>>("HEALTHCHECK_PING_URL");
/** Задержка перед следующим запросом при установленном соединении (в секундах) */
export const HEALTHCHECK_SUCCESS_TIMEOUT: InjectionToken<BehaviorSubject<number>> = new InjectionToken<BehaviorSubject<number>>("HEALTHCHECK_SUCCESS_TIMEOUT");
/** Задержка перед следующим запросом при потере соединения (в секундах)*/
export const HEALTHCHECK_FAILURE_TIMEOUT: InjectionToken<BehaviorSubject<number>> = new InjectionToken<BehaviorSubject<number>>("HEALTHCHECK_FAILURE_TIMEOUT");
/** Период ожидания ответа от сервера (в секундах) */
export const HEALTHCHECK_WAITING_PERIOD: InjectionToken<BehaviorSubject<number>> = new InjectionToken<BehaviorSubject<number>>("HEALTCHECK_WAITING_PERIOD");
/** Задержка перед закрытием уведомления об установленном соединении (в секундах)*/
export const HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME: InjectionToken<BehaviorSubject<number>> = new InjectionToken<BehaviorSubject<number>>("HEALTCHECK_WAITING_PERIOD");
/** Указывает, необходимо ли активировать проверку доступности сервера */
export const HEALTHCHECK_ENABLED: InjectionToken<BehaviorSubject<boolean>> = new InjectionToken<BehaviorSubject<boolean>>("HEALTHCHECK_ENABLED");

@Injectable({ providedIn: "root" })
export class HealthcheckService {
  /** Период ожидания ответа от сервера (в секундах)*/
  private _waitingPeriod: number;
  /** Задержка при отправке запроса при установленном соединении (в секундах)*/
  private _successTimeout: number;
  /** Задержка при отправке запроса при потере соединения (в секундах)*/
  private _failureTimeout: number;
  /** Адрес запроса проверки соединения*/
  private _pingURl: string;
  /** Время жизни уведомления об успешном восстановлении соединения*/
  private _successToastrLifetime: number;

  private _successId: number;
  private _errorId: number;

  private _connected: boolean;

  private _subject: Subject<number> = new Subject<number>();

  private _statusSubject: BehaviorSubject<HealthcheckStatus>;
  public readonly status: Observable<any>;

  constructor(
    private toastrService: ToastrService,
    httpBackend: HttpBackend,
    injector: Injector
  ) {
    this._connected = true;
    this._successToastrLifetime = 0;
    this._statusSubject = new BehaviorSubject({
      message: "",
      show: false,
      connectionEstablished: false,
      connectionLost: false,
      establishedTimeout: this._successToastrLifetime
    });
    this.status = this._statusSubject.asObservable();

    const client = new HttpClient(httpBackend);

    this._subject
      .pipe(
        delayWhen((value: number): Observable<number> => timer(value)),
        tap((): void => {
          console.debug("HealthcheckService: heartbeat");
        })
      )
      .subscribe(
        (): void => {

          client.post(this._pingURl, {}, {})
            .pipe(
              timeout(this._waitingPeriod)
            )
            .subscribe(
              (): void => this.successfullPingHandler(),
              (): void => this.failedPingHandler()
            );
        }
      );

    console.debug("HealthcheckService: Healthcheck monitor is ready and waiting for configuration");

    combineLatest(
      injector.get(HEALTHCHECK_PING_URL),
      injector.get(HEALTHCHECK_SUCCESS_TIMEOUT),
      injector.get(HEALTHCHECK_FAILURE_TIMEOUT),
      injector.get(HEALTHCHECK_WAITING_PERIOD),
      injector.get(HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME),
      injector.get(HEALTHCHECK_ENABLED)
    )
      .pipe(
        map((value: [string, number, number, number, number, boolean]): HealthcheckOptions => (
          {
            url: value[0],
            successTimeout: value[1] * 1000,
            failureTimeout: value[2] * 1000,
            waitingPeriod: value[3] * 1000,
            successNotificationLifitime: value[4] * 1000,
            enabled: value[5]
          })),
        tap((value: HealthcheckOptions): void => {
          console.debug(value.enabled ? "HealthcheckService: healthcheck enabled" : "HealthcheckService: healthcheck disabled");
        }),
        filter((value: HealthcheckOptions): boolean => value.enabled && !!value.url),
        tap((value: HealthcheckOptions): void => {
          this._pingURl = value.url;
          this._successTimeout = value.successTimeout;
          this._failureTimeout = value.failureTimeout;
          this._waitingPeriod = value.waitingPeriod;
          this._successToastrLifetime = value.successNotificationLifitime
        })
      )
      .subscribe(
        (): void => {
          console.debug("HealthcheckService: Healthcheck monitor is confifured");
          console.debug("HealthcheckService: Starting healthcheck...");

          this._subject.next(0);
        },
        (): void => {
          console.debug("HealthcheckService: Failed to start healthcheck monitor");
        }
      );
  }

  private successfullPingHandler(): void {
    console.debug(`HealthcheckService: Ping is successful. Next ping in ${this._successTimeout}ms"`);

    this._connected = true;
    this._subject.next(this._successTimeout);
    this._statusSubject.next({
      message: "Подключение к интернету установлено",
      connectionEstablished: true,
      connectionLost: false,
      establishedTimeout: this._successToastrLifetime
    });
  }

  private failedPingHandler(): void {
    console.debug(`HealthcheckService: Ping has failed. Next ping in ${this._failureTimeout}ms"`);

    this._connected = false;
    this._subject.next(this._failureTimeout);
    this._statusSubject.next({
      message: "Нет доступа к интернету. Дождитесь уведомления о его подключении.\n Введенные на странице данные не будут сохранены. Скопируйте их перед тем, как обновите страницу.",
      connectionLost: true,
      connectionEstablished: false,
      establishedTimeout: this._successToastrLifetime
    });
  }

}

interface HealthcheckOptions {
  url: string;
  successTimeout: number;
  failureTimeout: number;
  waitingPeriod: number;
  successNotificationLifitime: number;
  enabled: boolean;
}

export interface HealthcheckStatus {
  message: string;
  connectionEstablished: boolean;
  connectionLost: boolean;
  establishedTimeout: number;
}
