import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WageSchemasComponent } from './components/wage-schemas/wage-schemas.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';

import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WageSchemasListItemComponent } from './components/wage-schemas-list-item/wage-schemas-list-item.component';
import { WageSchemaComponent } from './components/wage-schema/wage-schema.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { WageSchemaResolver } from './resolvers/wage-schema-resolver';

const routes: Routes = [
  {
    path: "new",
    component: WageSchemaComponent,
    data: {
      title: "Новая схема оплаты",
      requirements: [PermissionNames.ManageWageSchemas]
    }
  },
  {
    path: "",
    component: WageSchemasComponent,
    data: {
      title: "Список схем оплаты",
      requirements: [PermissionNames.ManageWageSchemas]
    },
    resolve: {
      payload: WageSchemaResolver
    }
  },
  {
    path: ":id",
    component: WageSchemaComponent,
    data: {
      title: "Схема оплаты",
      requirements: [PermissionNames.ManageWageSchemas]
    },
    resolve: {
      payload: WageSchemaResolver
    }
  }
];

@NgModule({
  declarations: [WageSchemasComponent, WageSchemasListItemComponent, WageSchemaComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,
    SharedModule,

    ToastrModule,
    NgbModalModule,
    InfiniteScrollModule
  ],
  exports: [RouterModule]
})
export class WageSchemaSettingsModule { }
