import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WageSchema } from '../../../../generated/models';

@Component({
  selector: 'mp-wage-schemas-list-item',
  templateUrl: './wage-schemas-list-item.component.html',
  styleUrls: ['./wage-schemas-list-item.component.scss']
})
export class WageSchemasListItemComponent implements OnInit {

  @Input() wageSchema: WageSchema = {};

  @Output()
  onEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  edit(): void {
    this.onEdit.emit();
  }

  remove(): void {
    this.onRemove.emit();
  }

}
