import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Company, MyReport, ReportDiscount, ReportEmployee, ReportGroup, ReportPromotion, ReportService } from '../../../../generated/models';
import { MyService } from '../../../../generated/services';
import { ReportsFiltersValue, RequestedReport } from '../reports/reports.component';

@Component({
  selector: 'mp-report-group',
  templateUrl: './report-group.component.html',
  styleUrls: ['./report-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportGroupComponent implements OnInit {
  @Input() group: ReportGroup = {};
  @Input() filters: ReportsFiltersValue = {};

  @Output()
  onFiltersChanged: EventEmitter<ReportsFiltersValue> = new EventEmitter<ReportsFiltersValue>();

  @Output()
  onEmployeesRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onServicesRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDiscountsRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onPromotionsRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCustomersRequested: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onReportRequested: EventEmitter<RequestedReport> = new EventEmitter<RequestedReport>();

  get anySpecialistSelected(): boolean {
    if (this.filters.allSpecialistsSelected || this.filters.allPartnersSelected || this.filters.allEmployeesSelected) {
      return true;
    }

    return this.filters.selectedEmployees.length > 0;
  }

  get anyServicesSelected(): boolean {
    return this.filters.allServicesSelected || this.filters.selectedServices.length > 0;
  }

  get anyDiscountSelected(): boolean {
    return this.filters.allDiscountsSelected || this.filters.selectedDiscounts.length > 0;
  }

  get anyPromotionSelected(): boolean {
    return this.filters.allPromotionsSelected || this.filters.selectedPromotions.length > 0;
  }

  get anyCustomerSelected(): boolean {
    return this.filters.selectedCustomers.length > 0;
  }

  loading = false;
  reports: MyReport[] = [];

  constructor(private changeDetectorRef: ChangeDetectorRef, private myService: MyService) { }

  ngOnInit() {
    this.loading = true;

    this.myService.AvailableReports(this.group.type)
      .subscribe(
        (reports: MyReport[]): void => {

          this.loading = false;
          this.reports = reports;

          this.changeDetectorRef.markForCheck();
        }
      );

  }

  // Employees
  selectAllSpecialists(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allSpecialistsSelected: true,
      allPartnersSelected: false,
      allEmployeesSelected: false,
      selectedEmployees: []
    };

    this.onFiltersChanged.emit(filters);
  }

  selectAllEmployees(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allSpecialistsSelected: false,
      allPartnersSelected: false,
      allEmployeesSelected: true,
      selectedEmployees: []
    };

    this.onFiltersChanged.emit(filters);
  }

  selectAllPartners(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allSpecialistsSelected: false,
      allPartnersSelected: true,
      allEmployeesSelected: false,
      selectedEmployees: []
    };

    this.onFiltersChanged.emit(filters);
  }

  requestEmployees(): void {
    this.onEmployeesRequested.emit();
  }

  removeEmployee(employee: ReportEmployee): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedEmployees: this.filters.selectedEmployees.filter(x => x.id !== employee.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllSpecialists(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allEmployeesSelected: false,
      allPartnersSelected: false,
      allSpecialistsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllEmployees(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allEmployeesSelected: false,
      allPartnersSelected: false,
      allSpecialistsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllPartners(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allEmployeesSelected: false,
      allPartnersSelected: false,
      allSpecialistsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  // Services
  selectAllServices(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allServicesSelected: true,
      selectedServices: []
    };

    this.onFiltersChanged.emit(filters);
  }

  removeService(item: ReportService): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedServices: this.filters.selectedServices.filter(x => x.id !== item.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllServices(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allServicesSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  requestServices(): void {
    this.onServicesRequested.emit();
  }

  // Discounts
  selectAllDiscounts(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allDiscountsSelected: true,
      selectedDiscounts: []
    };

    this.onFiltersChanged.emit(filters);
  }

  removeDiscount(item: ReportDiscount): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedDiscounts: this.filters.selectedDiscounts.filter(x => x.id !== item.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllDiscounts(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allDiscountsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  requestDiscounts(): void {
    this.onDiscountsRequested.emit();
  }

  // Promotions
  selectAllPromotions(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allPromotionsSelected: true,
      selectedPromotions: []
    };

    this.onFiltersChanged.emit(filters);
  }

  removePromotion(item: ReportPromotion): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedPromotions: this.filters.selectedPromotions.filter(x => x.id !== item.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAllPromotions(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allPromotionsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }

  requestPromotions(): void {
    this.onPromotionsRequested.emit();
  }

  // customers

  removeCustomer(item: Company): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedCustomers: this.filters.selectedCustomers.filter(x => x.id !== item.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  requestCustomers(): void {
    this.onCustomersRequested.emit();
  }

  //reports
  open(report: MyReport): void {
    this.onReportRequested.emit({ name: report.name, parameters: report.parameters });
  }

  disabled(report: MyReport): boolean {
    if (!this.filters.selectedCompanies.some(x => report.companies.includes(x.id))) {
      return true;
    }

    if (report.type === 3
      && this.filters.selectedEmployees.length === 0
      && !this.filters.allEmployeesSelected
      && !this.filters.allSpecialistsSelected
      && !this.filters.allPartnersSelected) {
      return true;
    }

    if (report.type === 4
      && this.filters.selectedServices.length === 0
      && !this.filters.allServicesSelected) {
      return true;
    }

    if (report.type === 5
      && this.filters.selectedDiscounts.length === 0
      && !this.filters.allDiscountsSelected) {
      return true;
    }

    if (report.type === 6
      && this.filters.selectedPromotions.length === 0
      && !this.filters.allPromotionsSelected) {
      return true;
    }

    if (report.type === 7
      && this.filters.selectedCustomers.length === 0) {
      return true;
    }

    return false;
  }
}
