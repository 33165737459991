/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonalBalance } from '../models/personal-balance';
import { PersonalalBalanceOperation } from '../models/personalal-balance-operation';
@Injectable({
  providedIn: 'root',
})
class PersonalBalanceService extends __BaseService {
  static readonly GetPersonalBalanceAsyncPath = '/api/v1/balance/personal/{personId}';
  static readonly GetPersonalBalanceOperationsAsyncPath = '/api/v1/balance/personal/{personId}/operations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param personId undefined
   * @return Success
   */
  GetPersonalBalanceAsyncResponse(personId: number): __Observable<__StrictHttpResponse<PersonalBalance>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/balance/personal/${personId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonalBalance>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  GetPersonalBalanceAsync(personId: number): __Observable<PersonalBalance> {
    return this.GetPersonalBalanceAsyncResponse(personId).pipe(
      __map(_r => _r.body as PersonalBalance)
    );
  }

  /**
   * @param personId undefined
   * @return Success
   */
  GetPersonalBalanceOperationsAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonalalBalanceOperation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/balance/personal/${personId}/operations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonalalBalanceOperation>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  GetPersonalBalanceOperationsAsync(personId: number): __Observable<Array<PersonalalBalanceOperation>> {
    return this.GetPersonalBalanceOperationsAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonalalBalanceOperation>)
    );
  }
}

module PersonalBalanceService {
}

export { PersonalBalanceService }
