import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Company, ReportDiscount } from '../../../../generated/models';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-customers-modal',
  templateUrl: './customers-modal.component.html',
  styleUrls: ['./customers-modal.component.scss']
})
export class CustomersModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  private _selected: Company[] = [];
  private _filteringSubject: BehaviorSubject<Company[]> = new BehaviorSubject<Company[]>([]);

  @Input() title: string;
  @Input() selected: Company[] = [];
  @Input() companies: Company[] = [];

  @Output()
  onConfirm: EventEmitter<Company[]> = new EventEmitter<Company[]>();
  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  get result(): Observable<Company[]> { return this._filteringSubject; }

  loading = false;

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      search: new FormControl(""),
      type: new FormControl(0)
    });

    this.form.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: FiltersValue): void => {
        let filtered: Company[] = [...this.companies];

        if (value.type > 0) {
          filtered = filtered.filter(x => x.type === value.type)
        }

        if (value.search) {
          const search = new RegExp(value.search, "i");
          filtered = filtered.filter((x: ReportDiscount): boolean => search.test(x.name));
        }

        this._filteringSubject.next(filtered);
      });
  }

  ngOnInit() {
    this._selected = this.selected;

    this.form.patchValue({ search: "", type: 0 });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
  isSelected(item: Company): boolean {
    return this._selected.some((x: Company): boolean => x.id === item.id);
  }

  toggle(item: Company): void {
    const index: number = this._selected.findIndex((x: Company): boolean => x.id === item.id);

    if (index === -1) {
      this._selected.push(item);
    } else {
      this._selected.splice(index, 1);
    }
  }

  submit(): void {
    this.onConfirm.emit([...this._selected]);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}

interface FiltersValue {
  search: string;
  type: 0 | 1 | 2 | 3 | 4;
}
