import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceSet } from '../../../../generated/models';

@Component({
  selector: 'mp-price-set-list-item',
  templateUrl: './price-set-list-item.component.html',
  styleUrls: ['./price-set-list-item.component.scss']
})
export class PriceSetListItemComponent {
  @Input() item: PriceSet;

  @Output() onDelete = new EventEmitter<void>();
  @Output() onCopy = new EventEmitter<void>();
  @Output() onPrint = new EventEmitter<void>();

  delete = () => this.onDelete.emit();
  copy = () => this.onCopy.emit();
  print = () => this.onPrint.emit();
}
