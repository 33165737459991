/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MyTreatment } from '../models/my-treatment';
import { CreateMyTreatmentResponse } from '../models/create-my-treatment-response';
import { CreateMyTreatmentRequest } from '../models/create-my-treatment-request';
import { UpdateMyTreatmentRequest } from '../models/update-my-treatment-request';
import { MyTreatmentAnalog } from '../models/my-treatment-analog';
import { CreateTreatmentAnalogRequest } from '../models/create-treatment-analog-request';
@Injectable({
  providedIn: 'root',
})
class MyTreatmentsService extends __BaseService {
  static readonly MyTreatmentsAsyncPath = '/api/my/treatments';
  static readonly CreateMyTreatmentAsyncPath = '/api/my/treatments';
  static readonly MyTreatmentAsyncPath = '/api/my/treatments/{id}';
  static readonly UpdateMyTreatmentAsyncPath = '/api/my/treatments/{id}';
  static readonly RemoveMyTreatmentAsyncPath = '/api/my/treatments/{id}';
  static readonly FindAnalogsAsyncPath = '/api/my/treatments/analogs';
  static readonly CreateTreatmentAnalogAsyncPath = '/api/my/treatments/analogs';
  static readonly RemoveTreatmentAnalogAsyncPath = '/api/my/treatments/analogs';
  static readonly AnalogsAsyncPath = '/api/my/treatments/analogs/all';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  MyTreatmentsAsyncResponse(): __Observable<__StrictHttpResponse<Array<MyTreatment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/my/treatments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MyTreatment>>;
      })
    );
  }
  /**
   * @return Success
   */
  MyTreatmentsAsync(): __Observable<Array<MyTreatment>> {
    return this.MyTreatmentsAsyncResponse().pipe(
      __map(_r => _r.body as Array<MyTreatment>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateMyTreatmentAsyncResponse(request?: CreateMyTreatmentRequest): __Observable<__StrictHttpResponse<CreateMyTreatmentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/my/treatments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateMyTreatmentResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateMyTreatmentAsync(request?: CreateMyTreatmentRequest): __Observable<CreateMyTreatmentResponse> {
    return this.CreateMyTreatmentAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateMyTreatmentResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  MyTreatmentAsyncResponse(id: number): __Observable<__StrictHttpResponse<Array<MyTreatment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/my/treatments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MyTreatment>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  MyTreatmentAsync(id: number): __Observable<Array<MyTreatment>> {
    return this.MyTreatmentAsyncResponse(id).pipe(
      __map(_r => _r.body as Array<MyTreatment>)
    );
  }

  /**
   * @param params The `MyTreatmentsService.UpdateMyTreatmentAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateMyTreatmentAsyncResponse(params: MyTreatmentsService.UpdateMyTreatmentAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/my/treatments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MyTreatmentsService.UpdateMyTreatmentAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateMyTreatmentAsync(params: MyTreatmentsService.UpdateMyTreatmentAsyncParams): __Observable<null> {
    return this.UpdateMyTreatmentAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveMyTreatmentAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/my/treatments/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveMyTreatmentAsync(id: number): __Observable<null> {
    return this.RemoveMyTreatmentAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param For undefined
   * @return Success
   */
  FindAnalogsAsyncResponse(For?: string): __Observable<__StrictHttpResponse<Array<MyTreatmentAnalog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (For != null) __params = __params.set('For', For.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/my/treatments/analogs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MyTreatmentAnalog>>;
      })
    );
  }
  /**
   * @param For undefined
   * @return Success
   */
  FindAnalogsAsync(For?: string): __Observable<Array<MyTreatmentAnalog>> {
    return this.FindAnalogsAsyncResponse(For).pipe(
      __map(_r => _r.body as Array<MyTreatmentAnalog>)
    );
  }

  /**
   * @param request undefined
   */
  CreateTreatmentAnalogAsyncResponse(request?: CreateTreatmentAnalogRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/my/treatments/analogs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  CreateTreatmentAnalogAsync(request?: CreateTreatmentAnalogRequest): __Observable<null> {
    return this.CreateTreatmentAnalogAsyncResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MyTreatmentsService.RemoveTreatmentAnalogAsyncParams` containing the following parameters:
   *
   * - `Treatment`:
   *
   * - `Analog`:
   */
  RemoveTreatmentAnalogAsyncResponse(params: MyTreatmentsService.RemoveTreatmentAnalogAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Treatment != null) __params = __params.set('Treatment', params.Treatment.toString());
    if (params.Analog != null) __params = __params.set('Analog', params.Analog.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/my/treatments/analogs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MyTreatmentsService.RemoveTreatmentAnalogAsyncParams` containing the following parameters:
   *
   * - `Treatment`:
   *
   * - `Analog`:
   */
  RemoveTreatmentAnalogAsync(params: MyTreatmentsService.RemoveTreatmentAnalogAsyncParams): __Observable<null> {
    return this.RemoveTreatmentAnalogAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  AnalogsAsyncResponse(): __Observable<__StrictHttpResponse<Array<MyTreatmentAnalog>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/my/treatments/analogs/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MyTreatmentAnalog>>;
      })
    );
  }
  /**
   * @return Success
   */
  AnalogsAsync(): __Observable<Array<MyTreatmentAnalog>> {
    return this.AnalogsAsyncResponse().pipe(
      __map(_r => _r.body as Array<MyTreatmentAnalog>)
    );
  }
}

module MyTreatmentsService {

  /**
   * Parameters for UpdateMyTreatmentAsync
   */
  export interface UpdateMyTreatmentAsyncParams {
    id: number;
    request?: UpdateMyTreatmentRequest;
  }

  /**
   * Parameters for RemoveTreatmentAnalogAsync
   */
  export interface RemoveTreatmentAnalogAsyncParams {
    Treatment?: string;
    Analog?: string;
  }
}

export { MyTreatmentsService }
