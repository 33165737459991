/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ServicesResponse } from '../models/services-response';
import { ServiceEditorModel } from '../models/service-editor-model';
import { ServicePricesResponse } from '../models/service-prices-response';
import { ServiceStandardsResponse } from '../models/service-standards-response';
import { AddServiceStandardModel } from '../models/add-service-standard-model';
import { ServiceIntegrationsResponse } from '../models/service-integrations-response';
import { AvailablePriceSetsResponse } from '../models/available-price-sets-response';
import { ServiceArchive } from '../models/service-archive';
import { PerformersResponse } from '../models/performers-response';
import { ServiceOptionsResponse } from '../models/service-options-response';
import { ServiceOption } from '../models/service-option';
import { ServiceAgreement } from '../models/service-agreement';
import { ServiceDetails } from '../models/service-details';
@Injectable({
  providedIn: 'root',
})
class ServicesService extends __BaseService {
  static readonly ServicesPath = '/api/Services';
  static readonly CreateServicePath = '/api/Services';
  static readonly ServicePath = '/api/Services/{id}';
  static readonly UpdateServicePath = '/api/Services/{id}';
  static readonly RemovePath = '/api/Services/{id}';
  static readonly PricesPath = '/api/Services/{id}/prices';
  static readonly StandardsPath = '/api/Services/{id}/standards';
  static readonly AddStandardPath = '/api/Services/{id}/standards';
  static readonly IntegrationsPath = '/api/Services/{id}/integrations';
  static readonly AvailablePriceSetsPath = '/api/Services/{id}/availablePriceSets';
  static readonly ArchiveInfoPath = '/api/Services/{id}/archive';
  static readonly ArchivePath = '/api/Services/{id}/archive';
  static readonly PerformersPath = '/api/Services/{id}/performers';
  static readonly OptionsPath = '/api/Services/{id}/options';
  static readonly OptionsAsyncPath = '/api/Services/options';
  static readonly RemoveStandardPath = '/api/Services/{id}/standards/{standardId}';
  static readonly AgreeentsPath = '/api/Services/agreements';
  static readonly DetailsAsyncPath = '/api/Services/details';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ServicesService.ServicesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `DoctorId`:
   *
   * - `CompanyId`:
   *
   * - `CategoryId`:
   *
   * - `BookingGroup`:
   *
   * @return Success
   */
  ServicesResponse(params: ServicesService.ServicesParams): __Observable<__StrictHttpResponse<ServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    if (params.DoctorId != null) __params = __params.set('DoctorId', params.DoctorId.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
    if (params.BookingGroup != null) __params = __params.set('BookingGroup', params.BookingGroup.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesResponse>;
      })
    );
  }
  /**
   * @param params The `ServicesService.ServicesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `DoctorId`:
   *
   * - `CompanyId`:
   *
   * - `CategoryId`:
   *
   * - `BookingGroup`:
   *
   * @return Success
   */
  Services(params: ServicesService.ServicesParams): __Observable<ServicesResponse> {
    return this.ServicesResponse(params).pipe(
      __map(_r => _r.body as ServicesResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateServiceResponse(model?: ServiceEditorModel): __Observable<__StrictHttpResponse<ServiceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceEditorModel>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  CreateService(model?: ServiceEditorModel): __Observable<ServiceEditorModel> {
    return this.CreateServiceResponse(model).pipe(
      __map(_r => _r.body as ServiceEditorModel)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ServiceResponse(id: number): __Observable<__StrictHttpResponse<ServiceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Service(id: number): __Observable<ServiceEditorModel> {
    return this.ServiceResponse(id).pipe(
      __map(_r => _r.body as ServiceEditorModel)
    );
  }

  /**
   * @param params The `ServicesService.UpdateServiceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  UpdateServiceResponse(params: ServicesService.UpdateServiceParams): __Observable<__StrictHttpResponse<ServiceEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Services/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceEditorModel>;
      })
    );
  }
  /**
   * @param params The `ServicesService.UpdateServiceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  UpdateService(params: ServicesService.UpdateServiceParams): __Observable<ServiceEditorModel> {
    return this.UpdateServiceResponse(params).pipe(
      __map(_r => _r.body as ServiceEditorModel)
    );
  }

  /**
   * @param id undefined
   */
  RemoveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Services/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Remove(id: number): __Observable<null> {
    return this.RemoveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ServicesService.PricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  PricesResponse(params: ServicesService.PricesParams): __Observable<__StrictHttpResponse<ServicePricesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeExpired != null) __params = __params.set('IncludeExpired', params.IncludeExpired.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${params.id}/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicePricesResponse>;
      })
    );
  }
  /**
   * @param params The `ServicesService.PricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  Prices(params: ServicesService.PricesParams): __Observable<ServicePricesResponse> {
    return this.PricesResponse(params).pipe(
      __map(_r => _r.body as ServicePricesResponse)
    );
  }

  /**
   * @param params The `ServicesService.StandardsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  StandardsResponse(params: ServicesService.StandardsParams): __Observable<__StrictHttpResponse<ServiceStandardsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeExpired != null) __params = __params.set('IncludeExpired', params.IncludeExpired.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${params.id}/standards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceStandardsResponse>;
      })
    );
  }
  /**
   * @param params The `ServicesService.StandardsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  Standards(params: ServicesService.StandardsParams): __Observable<ServiceStandardsResponse> {
    return this.StandardsResponse(params).pipe(
      __map(_r => _r.body as ServiceStandardsResponse)
    );
  }

  /**
   * @param params The `ServicesService.AddStandardParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  AddStandardResponse(params: ServicesService.AddStandardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Services/${params.id}/standards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServicesService.AddStandardParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  AddStandard(params: ServicesService.AddStandardParams): __Observable<null> {
    return this.AddStandardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ServicesService.IntegrationsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  IntegrationsResponse(params: ServicesService.IntegrationsParams): __Observable<__StrictHttpResponse<ServiceIntegrationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeExpired != null) __params = __params.set('IncludeExpired', params.IncludeExpired.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${params.id}/integrations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceIntegrationsResponse>;
      })
    );
  }
  /**
   * @param params The `ServicesService.IntegrationsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeExpired`:
   *
   * @return Success
   */
  Integrations(params: ServicesService.IntegrationsParams): __Observable<ServiceIntegrationsResponse> {
    return this.IntegrationsResponse(params).pipe(
      __map(_r => _r.body as ServiceIntegrationsResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AvailablePriceSetsResponse(id: number): __Observable<__StrictHttpResponse<AvailablePriceSetsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${id}/availablePriceSets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AvailablePriceSetsResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AvailablePriceSets(id: number): __Observable<AvailablePriceSetsResponse> {
    return this.AvailablePriceSetsResponse(id).pipe(
      __map(_r => _r.body as AvailablePriceSetsResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ArchiveInfoResponse(id: number): __Observable<__StrictHttpResponse<ServiceArchive>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceArchive>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ArchiveInfo(id: number): __Observable<ServiceArchive> {
    return this.ArchiveInfoResponse(id).pipe(
      __map(_r => _r.body as ServiceArchive)
    );
  }

  /**
   * @param id undefined
   */
  ArchiveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Services/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Archive(id: number): __Observable<null> {
    return this.ArchiveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ServicesService.PerformersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `VisitDate`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  PerformersResponse(params: ServicesService.PerformersParams): __Observable<__StrictHttpResponse<PerformersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.VisitDate != null) __params = __params.set('VisitDate', params.VisitDate.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.Dob != null) __params = __params.set('Dob', params.Dob.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${params.id}/performers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PerformersResponse>;
      })
    );
  }
  /**
   * @param params The `ServicesService.PerformersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `VisitDate`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Performers(params: ServicesService.PerformersParams): __Observable<PerformersResponse> {
    return this.PerformersResponse(params).pipe(
      __map(_r => _r.body as PerformersResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  OptionsResponse(id: number): __Observable<__StrictHttpResponse<ServiceOptionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/${id}/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceOptionsResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Options(id: number): __Observable<ServiceOptionsResponse> {
    return this.OptionsResponse(id).pipe(
      __map(_r => _r.body as ServiceOptionsResponse)
    );
  }

  /**
   * @param params The `ServicesService.OptionsAsyncParams` containing the following parameters:
   *
   * - `ServiceIds`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  OptionsAsyncResponse(params: ServicesService.OptionsAsyncParams): __Observable<__StrictHttpResponse<Array<ServiceOption>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ServiceIds || []).forEach(val => {if (val != null) __params = __params.append('ServiceIds', val.toString())});
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceOption>>;
      })
    );
  }
  /**
   * @param params The `ServicesService.OptionsAsyncParams` containing the following parameters:
   *
   * - `ServiceIds`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  OptionsAsync(params: ServicesService.OptionsAsyncParams): __Observable<Array<ServiceOption>> {
    return this.OptionsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ServiceOption>)
    );
  }

  /**
   * @param params The `ServicesService.RemoveStandardParams` containing the following parameters:
   *
   * - `standardId`:
   *
   * - `id`:
   */
  RemoveStandardResponse(params: ServicesService.RemoveStandardParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Services/${params.id}/standards/${params.standardId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServicesService.RemoveStandardParams` containing the following parameters:
   *
   * - `standardId`:
   *
   * - `id`:
   */
  RemoveStandard(params: ServicesService.RemoveStandardParams): __Observable<null> {
    return this.RemoveStandardResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  AgreeentsResponse(): __Observable<__StrictHttpResponse<Array<ServiceAgreement>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/agreements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceAgreement>>;
      })
    );
  }
  /**
   * @return Success
   */
  Agreeents(): __Observable<Array<ServiceAgreement>> {
    return this.AgreeentsResponse().pipe(
      __map(_r => _r.body as Array<ServiceAgreement>)
    );
  }

  /**
   * @param params The `ServicesService.DetailsAsyncParams` containing the following parameters:
   *
   * - `ServiceIds`:
   *
   * - `Dob`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  DetailsAsyncResponse(params: ServicesService.DetailsAsyncParams): __Observable<__StrictHttpResponse<Array<ServiceDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.ServiceIds || []).forEach(val => {if (val != null) __params = __params.append('ServiceIds', val.toString())});
    if (params.Dob != null) __params = __params.set('Dob', params.Dob.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Services/details`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceDetails>>;
      })
    );
  }
  /**
   * @param params The `ServicesService.DetailsAsyncParams` containing the following parameters:
   *
   * - `ServiceIds`:
   *
   * - `Dob`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  DetailsAsync(params: ServicesService.DetailsAsyncParams): __Observable<Array<ServiceDetails>> {
    return this.DetailsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ServiceDetails>)
    );
  }
}

module ServicesService {

  /**
   * Parameters for Services
   */
  export interface ServicesParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
    DoctorId?: number;
    CompanyId?: number;
    CategoryId?: number;
    BookingGroup?: any;
  }

  /**
   * Parameters for UpdateService
   */
  export interface UpdateServiceParams {
    id: number;
    model?: ServiceEditorModel;
  }

  /**
   * Parameters for Prices
   */
  export interface PricesParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeExpired?: boolean;
  }

  /**
   * Parameters for Standards
   */
  export interface StandardsParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeExpired?: boolean;
  }

  /**
   * Parameters for AddStandard
   */
  export interface AddStandardParams {
    id: number;
    model?: AddServiceStandardModel;
  }

  /**
   * Parameters for Integrations
   */
  export interface IntegrationsParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeExpired?: boolean;
  }

  /**
   * Parameters for Performers
   */
  export interface PerformersParams {
    id: number;
    VisitDate?: string;
    Size?: number;
    Page?: number;
    Gender?: any;
    Dob?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for OptionsAsync
   */
  export interface OptionsAsyncParams {
    ServiceIds?: Array<number>;
    Date?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for RemoveStandard
   */
  export interface RemoveStandardParams {
    standardId: number;
    id: number;
  }

  /**
   * Parameters for DetailsAsync
   */
  export interface DetailsAsyncParams {
    ServiceIds?: Array<number>;
    Dob?: string;
    Date?: string;
    CompanyId?: number;
  }
}

export { ServicesService }
