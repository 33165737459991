import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClinicalRecommendation, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-service-prescriptions',
  templateUrl: './service-prescriptions.component.html',
  styleUrls: ['./service-prescriptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicePrescriptionsComponent implements OnInit, OnChanges {

  @Input() prescriptions: SectionsServicePrescription[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];

  recommended: SectionsServicePrescription[] = [];
  prescribed: SectionsServicePrescription[] = [];
  missed: ClinicalRecommendation[] = [];
  unused: ClinicalRecommendation[] = [];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(): void {
    this.prescribed = [];
    this.recommended = [];
    this.missed = [];
    this.unused = [];

    if (!this.recommendations || this.recommendations.length === 0) {
      this.prescribed = [...this.prescriptions];
      return;
    }

    for (const prescription of this.prescriptions) {
      const recommendation = this.recommendations.find(x => x.manipulationName === prescription.name);

      if (recommendation) {
        this.recommended.push(prescription);
      } else {
        this.prescribed.push(prescription);
      }
    }

    for (const item of this.recommendations) {
      const prescription = this.prescriptions.find(x => item.manipulationName === x.name);

      if (prescription) continue;

      if (item.mandatory) {
        this.missed.push(item);
      } else {
        this.unused.push(item);
      }
    }

    this.missed = this.missed.sort((x, y) => x.type - y.type);
    this.unused = this.unused.sort((x, y) => x.type - y.type);
  }

  abbr(recommendation: ClinicalRecommendation): string {
    switch (recommendation.type) {
      case 0: return 'КР';
      case 1: return 'КК';
      default: return '';
    }
  }

  recommendationsFor = (name: string, type: 0 | 1) => this.recommendations
    .filter(x => x.type === type && x.manipulationName === name)
    .map(x => ({ title: x.clinicalRecommendationName, text: x.text }));

}
