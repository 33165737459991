import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonConsultation, Record } from '../../../../generated/models';

@Component({
  selector: 'mp-visit-protocol-parameter',
  templateUrl: './visit-protocol-parameter.component.html',
  styleUrls: ['./visit-protocol-parameter.component.scss']
})
export class VisitProtocolParameterComponent implements OnInit {

  @Input() visit: PersonConsultation = {};
  @Input() record: Record = {};

  @Output() onAction = new EventEmitter<void>();

  get date() { return this.visit && this.visit.date.length > 10 ? this.visit.date.substring(0, 10) : ''; }

  constructor() { }

  ngOnInit() { }

  act = () => this.onAction.emit();

}
