import { Injectable } from '@angular/core';
import { SpecialitiesService } from '../../../generated/services';
import { SpecialityEditorModel } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';

@Injectable({ providedIn: "root" })
export class SpecialityResolver implements Resolve<SpecialityPayload>{
  constructor(private specialitiesService: SpecialitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SpecialityPayload> {
    const id: number = route.paramMap.has("id") ? parseInt(route.paramMap.get("id"), 10) : undefined;

    let itemResolver: Observable<SpecialityEditorModel> = null;

    if (id > 0) {
      itemResolver = this.specialitiesService.Speciality(id);
    } else {
      itemResolver = of({});
    }

    return forkJoin({
      item: itemResolver
    });
  }

}

export interface SpecialityPayload {
  item: SpecialityEditorModel
}
