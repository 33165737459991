import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Speciality, Company, ServiceAgreement, ResourceUserViewModel, ResourceSpecialityViewModel, ScheduleItemViewModel, BreakViewModel, ResourceCategoryViewModel, AvailableResourceService } from 'projects/Clinic/src/app/generated/models';
import { ScheduleSettingsService, SpecialitiesService, CategoriesService } from 'projects/Clinic/src/app/generated/services';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScheduleItemPayload } from '../../resolvers/schedule-item.resolver';
import { ScheduleEmployeeModalComponent } from '../schedule-employee.modal/schedule-employee.modal.component';
import { ScheduleSpecialityModalComponent } from '../schedule-speciality.modal/schedule-speciality.modal.component';
import { ScheduleShiftModalComponent, ScheduleShiftModalPayload } from '../schedule-shift.modal/schedule-shift.modal.component';
import { ScheduleBreakModalComponent, ScheduleBreakPayload } from '../schedule-break.modal/schedule-break.modal.component';
import { ScheduleCategoryModalComponent } from '../schedule-category.modal/schedule-category.modal.component';
import { Category } from '../../../../generated/models/category';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'mp-schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['./schedule-item.component.scss'],
  host: { class: "page" }
})
export class ScheduleItemComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  resourceId: number;
  title = 'Новое расписание';

  showEmployees = false;
  showSpecialities = false;
  showCategories = false;
  showShifts = false;
  showBreaks = false;

  specialitiesSet: Speciality[] = [];
  categoriesSet: Category[] = [];

  companies: Company[] = [];
  services: AvailableResourceService[] = [];

  employees: ResourceUserViewModel[] = [];
  specialities: ResourceSpecialityViewModel[] = [];
  categories: ResourceCategoryViewModel[] = [];
  shifts: ScheduleItemViewModel[] = [];
  breaks: BreakViewModel[] = [];

  scheduleForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    medicalCenter: new FormControl("", [Validators.required]),

    requiresService: new FormControl(false),
    includedArchived: new FormControl(false),
    isCompactBooking: new FormControl(false),
    serviceId: new FormControl("")
  });

  employeesFilters = new FormGroup({ search: new FormControl("") });

  specialitiesFilters = new FormGroup({ search: new FormControl("") });

  categoriesFilters = new FormGroup({ search: new FormControl("") });

  shiftsFilters = new FormGroup({ includeInactive: new FormControl(false) });

  employees$ = new BehaviorSubject<ResourceUserViewModel[]>([]);
  specialities$ = new BehaviorSubject<ResourceSpecialityViewModel[]>([]);
  shifts$ = new BehaviorSubject<ScheduleItemViewModel[]>([]);
  breaks$ = new BehaviorSubject<BreakViewModel[]>([]);
  categories$ = new BehaviorSubject<ResourceCategoryViewModel[]>([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private scheduleSettingsService: ScheduleSettingsService,
    private specialitiesService: SpecialitiesService,
    private categoriesService: CategoriesService,
    private router: Router,
    private modal: NgbModal,
    private toastrService: ToastrService
  ) {


    this.employeesFilters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((): void => {
        this.employees = [];
        this.loadEmployees();
      });

    this.specialitiesFilters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value: { search: string }): void => {

        if (value.search) {
          const rg = new RegExp(value.search, 'i');

          this.specialities$.next(this.specialities.filter(x => rg.test(x.specialityName)));
        } else {
          this.specialities$.next([...this.specialities]);
        }
      });

    this.categoriesFilters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value: { search: string }): void => {
        if (value.search) {
          const rg = new RegExp(value.search, "i");

          this.categories$.next(this.categories.filter(x => rg.test(x.categoryName)));
        } else {
          this.categories$.next([...this.categories]);
        }
      });

    this.shiftsFilters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value: { includeInactive: boolean }): void => {
        this.shifts$.next(this.shifts.filter(x => value.includeInactive || !x.expires || moment(x.expires, 'DD.MM.YYYY').isAfter(moment())));
      });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: ScheduleItemPayload }): void => {
      this.resourceId = data.payload.resource.id;

      this.title = this.resourceId > 0 ? `Расписание ${data.payload.resource.resourceName}` : 'Новое расписание';

      this.showEmployees = !!this.resourceId;
      this.showSpecialities = !!this.resourceId;
      this.showCategories = !!this.resourceId;
      this.showShifts = !!this.resourceId;
      this.showBreaks = !!this.resourceId;

      this.specialitiesSet = data.payload.specialities;
      this.categories = data.payload.categories;
      this.companies = data.payload.companies;

      if (data.payload.resource.serviceId > 0) {
        this.services = [{ id: data.payload.resource.serviceId, name: data.payload.resource.serviceName }];
      }

      this.scheduleForm.patchValue({
        name: data.payload.resource.resourceName,
        medicalCenter: data.payload.resource.organizationId,
        includedArchived: data.payload.resource.archived,
        requiresService: data.payload.resource.requiresService,
        isCompactBooking: data.payload.resource.isCompactBooking,
        serviceId: data.payload.resource.serviceId
      });

      if (this.resourceId) {
        this.loadSpecialitiesSet();
        this.loadCategoriesSet();
        this.loadEmployees();
        this.loadCategories();
        this.loadShifts();
        this.loadBreaks();
        this.loadSpecialities();
        this.loadServices();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  handleErrorResponse(response: HttpErrorResponse, message: string) {
    if (response.status === 400) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Для выполнения операции отсутствует необходимое разрешение', 'Ошибка'); break;

          case 1: this.toastrService.warning('Необходимо указать дату начала действия', 'Ошибка'); break;
          case 3: this.toastrService.warning('Необходимо указать время начала', 'Ошибка'); break;
          case 4: this.toastrService.warning('Необходимо указать время окончания', 'Ошибка'); break;

          case 9: this.toastrService.warning('Неправильный тип элемента расписания', 'Ошибка'); break;
          case 10: this.toastrService.warning('Недопустимая продолжительность смены', 'Ошибка'); break;

          case 11: break;
          case 12: this.toastrService.warning('Ресурс не найден', 'Ошибка'); break;

          case 14: this.toastrService.warning('Сотрудник не найден', 'Ошибка'); break;
          case 15: this.toastrService.warning('Сотрудник уже добавлен', 'Ошибка'); break;
          case 16: this.toastrService.warning('Сотрудник не найден', 'Ошибка'); break;
          case 17: this.toastrService.warning('Специальность уже добавлена', 'Ошибка'); break;
          case 18: this.toastrService.warning('Специальность не найдена', 'Ошибка'); break;
          case 19: this.toastrService.warning('Специальность не найдена', 'Ошибка'); break;
          case 20: this.toastrService.warning('Категория уже добавлена', 'Ошибка'); break;
          case 21: this.toastrService.warning('Категория не найдена', 'Ошибка'); break;
          case 22: this.toastrService.warning('Можно добавить категорию только для ресурсов с обязательной услугой', 'Ошибка'); break;
          case 23: this.toastrService.warning('Категория не найдена', 'Ошибка'); break;
          case 24: this.toastrService.warning('Необходимо указать время начала перерыва', 'Ошибка'); break;
          case 25: this.toastrService.warning('Необходимо указать время окончания перерыва', 'Ошибка'); break;
          case 26: this.toastrService.warning('Перерыв не найден', 'Ошибка'); break;
          case 27: this.toastrService.warning('Смена не найден', 'Ошибка'); break;
          case 28: this.toastrService.warning('Услуга не найден', 'Ошибка'); break;
          case 29: this.toastrService.warning('Сотрудник не может оказывать выбранную по умолчанию услугу расписания', 'Ошибка'); break;
          case 30: this.toastrService.warning('Услуга по умолчанию расписания запрещена к выполнению данному сотруднику', 'Ошибка'); break;
          case 31: this.toastrService.warning('Нельзя удалить специальность услуги по умолчанию', 'Ошибка'); break;
          case 32: this.toastrService.warning('При удалении сотрудника услуга по умолчанию расписания больше не сможет быть оказана', 'Ошибка'); break;
          case 33: this.toastrService.warning('При добавлении специальности услуга по умолчанию расписания окажется запрещена', 'Ошибка'); break;

          case 34: this.toastrService.warning('Среди специальностей расписания отсутствует специальность услуги по умолчанию', 'Ошибка'); break;
          case 35: this.toastrService.warning('Услуги по умолчанию запрещены для расписаний с пустыми списками сотрудников и специальностей', 'Ошибка'); break;
          case 36: this.toastrService.warning('Услуга по умолчанию не может быть оказана некоторыми сотрудниками расписания', 'Ошибка'); break;
          case 37: this.toastrService.warning('Услуга по умолчанию запрещена для некоторых сотрудников расписания', 'Ошибка'); break;

          default: this.toastrService.error(message, 'Ошибка'); break;
        }
      }
      return;
    }

    this.toastrService.error(message, 'Ошибка');
    return;
  }

  async update(value: FormValue): Promise<void> {

    try {
      await this.scheduleSettingsService.UpdateResource({
        id: this.resourceId,
        request: {
          resourceName: value.name,
          companyId: value.medicalCenter,
          isCompactBooking: value.isCompactBooking,
          archived: value.includedArchived,
          requiresService: value.requiresService,
          serviceId: value.serviceId
        }
      }).toPromise();

      this.title = `Расписание ${value.name}`;
      this.toastrService.success('Расписание обновлено', 'Успешно');
    } catch (e) {
      this.handleErrorResponse(e as HttpErrorResponse, 'Не уалось обновить расписание');
    }
  }

  async create(value: FormValue): Promise<void> {
    try {
      const response = await this.scheduleSettingsService.CreateResource({
        resourceName: value.name,
        companyId: value.medicalCenter,
        isCompactBooking: value.isCompactBooking,
        archived: value.includedArchived,
        requiresService: value.requiresService
      }).toPromise();

      this.toastrService.success('Расписание добавлено', 'Успешно');
      this.router.navigate(["..", response.id], { relativeTo: this.activatedRoute });
    } catch (e) {
      this.handleErrorResponse(e as HttpErrorResponse, 'Не уалось добавить расписание');
    }
  }

  async acceptChanges(value: FormValue): Promise<void> {
    if (this.resourceId > 0) await this.update(value); else await this.create(value);
  }

  async loadEmployees(): Promise<void> {
    this.employees = await this.scheduleSettingsService.Employees(this.resourceId).toPromise();

    const value: { search: string } = this.employeesFilters.getRawValue();

    if (value.search) {
      const regex = new RegExp(value.search, 'i');
      this.employees$.next(this.employees.filter(x => regex.test(x.employeeName)));
    } else {
      this.employees$.next([...this.employees]);
    }
  }

  async loadSpecialities(): Promise<void> {
    this.specialities = await this.scheduleSettingsService.Specialities(this.resourceId).toPromise();

    const value: { search: string } = this.specialitiesFilters.getRawValue();

    if (value.search) {
      const regex = new RegExp(value.search, 'i');
      this.specialities$.next(this.specialities.filter(x => regex.test(x.specialityName)));
    } else {
      this.specialities$.next([...this.specialities]);
    }
  }

  async loadCategories() {
    this.categories = await this.scheduleSettingsService.Categories(this.resourceId).toPromise();

    const value: { search: string } = this.categoriesFilters.getRawValue();

    if (value.search) {
      const rg = new RegExp(value.search, "i");

      this.categories$.next(this.categories.filter(x => rg.test(x.categoryName)));
    } else {
      this.categories$.next([...this.categories]);
    }
  }

  async loadShifts() {
    this.shifts = await this.scheduleSettingsService.Shifts(this.resourceId).toPromise();

    const value: { includeInactive: boolean } = this.shiftsFilters.getRawValue();

    this.shifts$.next(this.shifts.filter(x => value.includeInactive || !x.expires || moment(x.expires, 'DD.MM.YYYY').isAfter(moment())));
  }

  async loadBreaks() {
    this.breaks = await this.scheduleSettingsService.Breaks(this.resourceId).toPromise();

    this.breaks$.next([...this.breaks]);
  }

  async loadSpecialitiesSet() {
    const response = await this.specialitiesService.Specialities({ Page: 1, Size: 0 }).toPromise();
    this.specialitiesSet = response.items;
  }

  async loadCategoriesSet() {
    const response = await this.categoriesService.Categories({ Page: 1, Size: 0 }).toPromise();
    this.categoriesSet = response.items;
  }

  async loadServices() {

    const value: FormValue = this.scheduleForm.getRawValue();

    this.services = this.services.filter(x => x.id === value.serviceId);

    const services = await this.scheduleSettingsService.AvailableServicesAsync(this.resourceId).toPromise();

    this.services.push(...services.filter(x => x.id !== value.serviceId));
  }

  async addEmployee(): Promise<void> {
    const options: NgbModalOptions = { backdrop: "static", size: 'lg', centered: true };

    const doctors = await this.scheduleSettingsService.AvailableEmployeesAsync(this.resourceId).toPromise();

    const modalRef: NgbModalRef = this.modal.open(ScheduleEmployeeModalComponent, options);
    const componentRef: ScheduleEmployeeModalComponent = modalRef.componentInstance;

    componentRef.doctors = doctors;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onSubmit.subscribe(async (employeeId: number): Promise<void> => {
      try {
        await this.scheduleSettingsService.CreateEmployee({
          resourceId: this.resourceId,
          request: { employeeId: employeeId }
        }).toPromise();

        this.toastrService.success('Сотрудник добавлен', 'Успешно');
        this.loadEmployees();
        this.loadServices();

        modalRef.close();

      } catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось добавить сотрудника');
      }

    });
  }

  async deleteEmployee(employee: ResourceUserViewModel): Promise<void> {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true, windowClass: "modal-confirm" };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Сотрудник будет удалён. Продолжить?";
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      async () => {
        try {
          await this.scheduleSettingsService.DeleteEmployee({ id: employee.id, resourceId: this.resourceId }).toPromise();

          this.toastrService.success('Сотрудник удалён', 'Успешно');
          this.loadEmployees();
          this.loadServices();
        } catch (e) {
          this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось удалить сотрудника');
        }
      },
      (): void => { }
    );
  }

  async addSpeciality() {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true }

    const specialities = await this.scheduleSettingsService.AvailableSpecialitiesAsync(this.resourceId).toPromise();

    const modalRef: NgbModalRef = this.modal.open(ScheduleSpecialityModalComponent, options);
    const componentRef: ScheduleSpecialityModalComponent = modalRef.componentInstance;

    componentRef.specialities = specialities;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (specialityId: number) => {
      try {
        await this.scheduleSettingsService.CreateSpeciality({ resourceId: this.resourceId, request: { specialityId } }).toPromise();

        this.toastrService.success('Специальность добавлена', 'Успешно');

        this.loadSpecialities();
        this.loadServices();

        modalRef.close();
      }
      catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось добавить специальность');
      }
    });
  }

  addCategory(): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };

    const modalRef: NgbModalRef = this.modal.open(ScheduleCategoryModalComponent, options);
    const componentRef: ScheduleCategoryModalComponent = modalRef.componentInstance;

    componentRef.categories = this.categoriesSet;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (categoryId: number) => {

      try {
        await this.scheduleSettingsService.CreateCategory({ resourceId: this.resourceId, request: { categoryId } }).toPromise();

        this.loadCategories();
        this.toastrService.success('Категория добавлена', 'Успешно');
        modalRef.close();
      }
      catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось добавить категорию');
      }
    });
  }

  async deleteCategory(category: ResourceCategoryViewModel) {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true, windowClass: "modal-confirm" };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Категория будет удалена. Продолжить?";
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      async () => {
        try {
          await this.scheduleSettingsService.DeleteCategory({ id: category.id, resourceId: this.resourceId }).toPromise();

          this.toastrService.success('Категория удалена', 'Успешно');
          this.loadCategories();
        } catch (e) {
          this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось удалить категорию');
        }
      },
      (): void => { }
    );
  }

  addShift(): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };

    const modalRef: NgbModalRef = this.modal.open(ScheduleShiftModalComponent, options);
    const componentRef: ScheduleShiftModalComponent = modalRef.componentInstance;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (payload: ScheduleShiftModalPayload) => {
      try {
        await this.scheduleSettingsService.CreateShift({
          resourceId: this.resourceId,
          request: {
            start: payload.start,
            stop: payload.stop,
            effective: payload.effective ? payload.effective.format("DD.MM.YYYY") : undefined,
            expires: payload.expires ? payload.expires.format("DD.MM.YYYY") : undefined,
            days: payload.days,
            weekParity: payload.weekParity,
            duration: payload.duration,
            cabinet: payload.cabinet
          }
        }).toPromise();

        this.loadShifts();

        this.toastrService.success("Смена добавлена", "Успешно");

        modalRef.close();
      } catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось добавить смену');
      }
    });

  }

  async editShift(item: ScheduleItemViewModel) {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };

    const shift = await this.scheduleSettingsService.ShiftAsync({ id: item.id, resourceId: this.resourceId }).toPromise();


    const modalRef: NgbModalRef = this.modal.open(ScheduleShiftModalComponent, options);
    const componentRef: ScheduleShiftModalComponent = modalRef.componentInstance;

    componentRef.shift = shift;
    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (payload: ScheduleShiftModalPayload) => {
      try {
        await this.scheduleSettingsService.EditShift({
          id: item.id,
          resourceId: this.resourceId,
          request: {
            start: payload.start,
            stop: payload.stop,
            effective: payload.effective ? payload.effective.format("DD.MM.YYYY") : undefined,
            expires: payload.expires ? payload.expires.format("DD.MM.YYYY") : undefined,
            days: payload.days,
            weekParity: payload.weekParity,
            duration: payload.duration,
            cabinet: payload.cabinet
          }
        }).toPromise();

        this.loadShifts();
        this.toastrService.success("Смена обновлена", "Успешно");

        modalRef.close();
      } catch (e) {
        const response = e as HttpErrorResponse;

        for (const error of response.error.errors) {
          if (error.status === 11) {
            this.toastrService.warning(error.message, 'Ошикбка');
          }
        }

        this.handleErrorResponse(response, 'Не уалось добавить смену');
      }


    });
  }

  addBreak(): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };
    const modalRef: NgbModalRef = this.modal.open(ScheduleBreakModalComponent, options);
    const componentRef: ScheduleBreakModalComponent = modalRef.componentInstance;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (value: ScheduleBreakPayload) => {

      try {
        await this.scheduleSettingsService.CreateBreak({
          resourceId: this.resourceId,
          request: {
            start: value.start,
            stop: value.stop,
            effective: value.effective ? value.effective.format("DD.MM.YYYY") : "",
            expires: value.expires ? value.expires.format("DD.MM.YYYY") : "",
            days: value.days,
            weekParity: value.weekParity
          }
        }).toPromise();

        this.toastrService.success('Перерыв добавлен', 'Успешно');
        this.loadBreaks();

        modalRef.close();

      } catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не удалить добавить перерыв');
      }

    });
  }

  editBreak(item: BreakViewModel): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };
    const modalRef: NgbModalRef = this.modal.open(ScheduleBreakModalComponent, options);
    const componentRef: ScheduleBreakModalComponent = modalRef.componentInstance;

    componentRef.break = item;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (value: ScheduleBreakPayload) => {

      try {
        await this.scheduleSettingsService.EditBreak({
          id: item.id,
          resourceId: this.resourceId,
          request: {
            start: value.start,
            stop: value.stop,
            effective: value.effective ? value.effective.format("DD.MM.YYYY") : "",
            expires: value.expires ? value.expires.format("DD.MM.YYYY") : "",
            days: value.days,
            weekParity: value.weekParity
          }
        }).toPromise();

        this.toastrService.success('Перерыв обновлен', 'Успешно');
        this.loadBreaks();

        modalRef.close();

      } catch (e) {
        this.handleErrorResponse(e as HttpErrorResponse, 'Не обновить обновить перерыв');
      }

    });
  }

  async deleteBreak(item: BreakViewModel) {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true, windowClass: "modal-confirm" };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Перерыв будет удалён. Продолжить?";
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      async () => {
        try {
          await this.scheduleSettingsService.DeleteBreak({ id: item.id, resourceId: this.resourceId }).toPromise();

          this.toastrService.success('Перерыв удалён', 'Успешно');
          this.loadBreaks();
        } catch (e) {
          this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось удалить перерыв');
        }
      },
      (): void => { }
    );
  }

  async deleteSpeciality(speciality: ResourceSpecialityViewModel) {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true, windowClass: "modal-confirm" };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Специальность будет удалена. Продолжить?";
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      async () => {
        try {
          await this.scheduleSettingsService.DeleteSpeciality({ id: speciality.id, resourceId: this.resourceId }).toPromise();

          this.toastrService.success('Специальность удалена', 'Успешно');
          this.loadSpecialities();
          this.loadServices();
        } catch (e) {
          this.handleErrorResponse(e as HttpErrorResponse, 'Не удалось удалить специальность');
        }
      },
      (): void => { }
    );
  }

  deleteShift(item: ScheduleItemViewModel): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true, windowClass: "modal-confirm" };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Смена будет удалена. Продолжить?";
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      async () => {
        try {
          await this.scheduleSettingsService.DeleteShift({ resourceId: this.resourceId, id: item.id }).toPromise();
          this.toastrService.success("Смена удалена", "Успешно");
          this.loadShifts();
        } catch (e) {
          const response = e as HttpErrorResponse;

          for (const error of response.error.errors) {
            if (error.status === 11) {
              this.toastrService.warning('Не возможно удалить смену, в которой есть записи или перерывы', 'Ошибка');
            }
          }

          this.handleErrorResponse(response, 'Не удалось удалить смену');
        }
      },
      (): void => { }
    );
  }

  getWeekParity(item: ScheduleItemViewModel | BreakViewModel): string {
    switch (item.weekParity) {
      case 3: return 'Каждая';
      case 2: return 'Нечетные';
      case 1: return 'Четные';
      default: return '';
    }
  }

  getDay(item: ScheduleItemViewModel | BreakViewModel): string {
    let days: number = 0;

    for (let day of item.days) {
      days += day;
    }

    if (days === 127) {
      return "Каждый день";
    }

    if (days === 62) {
      return "Будние дни";
    }

    if (days === 65) {
      return "Выходные дни";
    }

    const parts: string[] = [];

    if ((days & 2) === 2) {
      parts.push("ПН")
    }

    if ((days & 4) === 4) {
      parts.push("ВТ")
    }

    if ((days & 8) === 8) {
      parts.push("СР")
    }

    if ((days & 16) === 16) {
      parts.push("ЧТ")
    }

    if ((days & 32) === 32) {
      parts.push("ПТ")
    }

    if ((days & 64) === 64) {
      parts.push("СБ")
    }

    if ((days & 1) === 1) {
      parts.push("ВС")
    }

    return parts.join(" ");
  }
}

interface FormValue {
  name: string;
  medicalCenter: number;
  serviceId: number;

  requiresService: boolean;
  includedArchived: boolean;
  isCompactBooking: boolean;
}
