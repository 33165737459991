import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { SavedTemplateGroup, PersonQuizRun, SavedTemplateItem, PersonTestResult, PersonTestResultAttachment, PersonConsultation, Record, PersonMetric, PersonLabOrder, PersonLabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {

  @HostBinding('class.opened') get opened() { return this.show; }

  @Input() recordId: number;

  @Input() parameters: SavedTemplateGroup[] = [];
  @Input() quizRuns: PersonQuizRun[] = [];
  @Input() labProtocols: PersonTestResult[] = [];
  @Input() visits: PersonConsultation[] = [];
  @Input() labOrders: PersonLabOrder[] = [];
  @Input() metrics: PersonMetric[] = [];

  @Input() loading = false;
  @Input() show = false;

  @Output() onToggle = new EventEmitter<boolean>();
  @Output() onParameterSelect = new EventEmitter<SavedTemplateItem>();
  @Output() onQuizRunSelect = new EventEmitter<PersonQuizRun>();
  @Output() onSelectAttachment = new EventEmitter<{ id: number, testOrderId: number }>();
  @Output() onSelectRecord = new EventEmitter<Record>();
  @Output() onSelectMetric = new EventEmitter<PersonMetric>();

  @Output() onSelectLabTest = new EventEmitter<PersonLabResult>();
  @Output() onSelectLabResult = new EventEmitter<PersonLabOrder>();
  @Output() onSelectLabResultAsTable = new EventEmitter<PersonLabOrder>();
  @Output() onSelectLabResultAsList = new EventEmitter<PersonLabOrder>();
  @Output() onOpenLabResult = new EventEmitter<PersonLabOrder>();
  get visitProtocolsDisabled() { return !this.visits || !this.visits.some(x => x.records.some(r => r.signed && r.id !== this.recordId)) };

  constructor() { }

  ngOnInit() { }

  togglePanel = () => this.onToggle.emit(!this.show);

  selectParameter = (parameter: SavedTemplateItem) => this.onParameterSelect.emit(parameter);
  selectQuizRun = (run: PersonQuizRun) => this.onQuizRunSelect.emit(run);
  selectAttachment = (attachment: PersonTestResultAttachment, protocol: PersonTestResult) => this.onSelectAttachment.emit({ id: attachment.id, testOrderId: protocol.id });
  selectRecord = (record: Record) => this.onSelectRecord.emit(record);
  selectMetric = (metric: PersonMetric) => this.onSelectMetric.emit(metric);

  selectLabTest = (item: PersonLabResult) => this.onSelectLabTest.emit(item);
  selectLabResult = (result: PersonLabOrder) => this.onSelectLabResult.emit(result);
  selectAsTable = (result: PersonLabOrder) => this.onSelectLabResultAsTable.emit(result);
  selectAsList = (result: PersonLabOrder) => this.onSelectLabResultAsList.emit(result);
  openLabResult = (result: PersonLabOrder) => this.onOpenLabResult.emit(result);

  disabled = (parameter: { value: string }) => !parameter.value;

  metricValue = (metric: PersonMetric) => metric.units ? `${metric.value} ${metric.units}` : metric.value;
}
