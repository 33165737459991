import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PersonMetric } from '../../../../generated/models';

@Component({
  selector: 'mp-person-metrics-list-item',
  templateUrl: './person-metrics-list-item.component.html',
  styleUrls: ['./person-metrics-list-item.component.scss']
})
export class PersonMetricsListItemComponent implements OnInit {

  @Input() item: PersonMetric = {};

  @Input() showName = false;
  @Input() showHandler = false;

  @Input() opened = false;

  @Output() onSelect = new EventEmitter<void>();
  @Output() onToggle = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  select = () => this.onSelect.emit();
  toggle = () => this.onToggle.emit();
}
