/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LabsResponse } from '../models/labs-response';
import { ReferralsResponse } from '../models/referrals-response';
import { GetOrdersResponse } from '../models/get-orders-response';
import { GetOrdersResponseItemAttachment } from '../models/get-orders-response-item-attachment';
import { ManageObservationModel } from '../models/manage-observation-model';
import { UpdateObservationCommentRequest } from '../models/update-observation-comment-request';
import { DisableResultsSyncRequest } from '../models/disable-results-sync-request';
@Injectable({
  providedIn: 'root',
})
class ObservationsService extends __BaseService {
  static readonly LabsPath = '/api/clinic/v1/observations/labs';
  static readonly ReferralsPath = '/api/clinic/v1/observations/referrals';
  static readonly GetOrdersPath = '/api/clinic/v1/observations/orders';
  static readonly GetAttachmentPath = '/api/clinic/v1/observations/attachment';
  static readonly AttachmentsPath = '/api/clinic/v1/observations/{id}/attachments';
  static readonly AddAttachmentsPath = '/api/clinic/v1/observations/{id}/attachments';
  static readonly GetFhirResultsPath = '/api/clinic/v1/observations/{id}/fhirResults';
  static readonly ManagePath = '/api/clinic/v1/observations/{id}/manage';
  static readonly RemovePendingOrderPath = '/api/clinic/v1/observations/pending/{id}';
  static readonly RetryPath = '/api/clinic/v1/observations/{id}/retry';
  static readonly RemoveAttachmentsPath = '/api/clinic/v1/observations/{observationId}';
  static readonly UpdateCommentPath = '/api/clinic/v1/observations/{id}/comment';
  static readonly DisableResultsSyncPath = '/api/clinic/v1/observations/{id}/disableResultsSync';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  LabsResponse(): __Observable<__StrictHttpResponse<LabsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/labs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LabsResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  Labs(): __Observable<LabsResponse> {
    return this.LabsResponse().pipe(
      __map(_r => _r.body as LabsResponse)
    );
  }

  /**
   * @return Success
   */
  ReferralsResponse(): __Observable<__StrictHttpResponse<ReferralsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/referrals`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralsResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  Referrals(): __Observable<ReferralsResponse> {
    return this.ReferralsResponse().pipe(
      __map(_r => _r.body as ReferralsResponse)
    );
  }

  /**
   * @param params The `ObservationsService.GetOrdersParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Search`:
   *
   * - `Referrals`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `Labs`:
   *
   * - `From`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  GetOrdersResponse(params: ObservationsService.GetOrdersParams): __Observable<__StrictHttpResponse<GetOrdersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    (params.Referrals || []).forEach(val => {if (val != null) __params = __params.append('Referrals', val.toString())});
    if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    (params.Labs || []).forEach(val => {if (val != null) __params = __params.append('Labs', val.toString())});
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.Companies || []).forEach(val => {if (val != null) __params = __params.append('Companies', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetOrdersResponse>;
      })
    );
  }
  /**
   * @param params The `ObservationsService.GetOrdersParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Search`:
   *
   * - `Referrals`:
   *
   * - `PageSize`:
   *
   * - `Page`:
   *
   * - `Labs`:
   *
   * - `From`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  GetOrders(params: ObservationsService.GetOrdersParams): __Observable<GetOrdersResponse> {
    return this.GetOrdersResponse(params).pipe(
      __map(_r => _r.body as GetOrdersResponse)
    );
  }

  /**
   * @param params The `ObservationsService.GetAttachmentParams` containing the following parameters:
   *
   * - `Token`:
   *
   * - `Download`:
   */
  GetAttachmentResponse(params: ObservationsService.GetAttachmentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/attachment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ObservationsService.GetAttachmentParams` containing the following parameters:
   *
   * - `Token`:
   *
   * - `Download`:
   */
  GetAttachment(params: ObservationsService.GetAttachmentParams): __Observable<null> {
    return this.GetAttachmentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AttachmentsResponse(id: number): __Observable<__StrictHttpResponse<Array<GetOrdersResponseItemAttachment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/${id}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GetOrdersResponseItemAttachment>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Attachments(id: number): __Observable<Array<GetOrdersResponseItemAttachment>> {
    return this.AttachmentsResponse(id).pipe(
      __map(_r => _r.body as Array<GetOrdersResponseItemAttachment>)
    );
  }

  /**
   * @param params The `ObservationsService.AddAttachmentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   */
  AddAttachmentsResponse(params: ObservationsService.AddAttachmentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/observations/${params.id}/attachments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ObservationsService.AddAttachmentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   */
  AddAttachments(params: ObservationsService.AddAttachmentsParams): __Observable<null> {
    return this.AddAttachmentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetFhirResultsResponse(id: number): __Observable<__StrictHttpResponse<{[key: string]: any}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/${id}/fhirResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: any}>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetFhirResults(id: number): __Observable<{[key: string]: any}> {
    return this.GetFhirResultsResponse(id).pipe(
      __map(_r => _r.body as {[key: string]: any})
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManageObservationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/observations/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManageObservationModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManageObservationModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManageObservationModel)
    );
  }

  /**
   * @param id undefined
   */
  RemovePendingOrderResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/clinic/v1/observations/pending/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemovePendingOrder(id: number): __Observable<null> {
    return this.RemovePendingOrderResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RetryResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/observations/${id}/retry`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Retry(id: number): __Observable<null> {
    return this.RetryResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param observationId undefined
   */
  RemoveAttachmentsResponse(observationId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/clinic/v1/observations/${observationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param observationId undefined
   */
  RemoveAttachments(observationId: number): __Observable<null> {
    return this.RemoveAttachmentsResponse(observationId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ObservationsService.UpdateCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateCommentResponse(params: ObservationsService.UpdateCommentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/clinic/v1/observations/${params.id}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ObservationsService.UpdateCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateComment(params: ObservationsService.UpdateCommentParams): __Observable<null> {
    return this.UpdateCommentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ObservationsService.DisableResultsSyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  DisableResultsSyncResponse(params: ObservationsService.DisableResultsSyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/clinic/v1/observations/${params.id}/disableResultsSync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ObservationsService.DisableResultsSyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  DisableResultsSync(params: ObservationsService.DisableResultsSyncParams): __Observable<null> {
    return this.DisableResultsSyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ObservationsService {

  /**
   * Parameters for GetOrders
   */
  export interface GetOrdersParams {
    To?: string;
    Search?: string;
    Referrals?: Array<number>;
    PageSize?: number;
    Page?: number;
    Labs?: Array<number>;
    From?: string;
    Companies?: Array<number>;
  }

  /**
   * Parameters for GetAttachment
   */
  export interface GetAttachmentParams {
    Token?: string;
    Download?: boolean;
  }

  /**
   * Parameters for AddAttachments
   */
  export interface AddAttachmentsParams {
    id: number;
    file?: Blob;
  }

  /**
   * Parameters for UpdateComment
   */
  export interface UpdateCommentParams {
    id: number;
    request?: UpdateObservationCommentRequest;
  }

  /**
   * Parameters for DisableResultsSync
   */
  export interface DisableResultsSyncParams {
    id: number;
    request?: DisableResultsSyncRequest;
  }
}

export { ObservationsService }
