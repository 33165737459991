import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { PatientsService } from '../../../../generated/services';
import { PatientSearchResponse, PatientSearchResult } from '../../../../generated/models';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'mp-call-center-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
  host: { class: "page" }
})

export class PatientsComponent implements OnInit {
  patients: PatientSearchResult[] = [];
  loading = false;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientsService: PatientsService) { }

  ngOnInit() {
    this.router.onSameUrlNavigation = 'reload';

    const phone: string = this.activatedRoute.snapshot.queryParamMap.get("phone")

    if (phone) {
      this.loading = true;
      this.patientsService.Search({ Search: phone.replace(/[^\d]/g, ""), Size: 0 })
        .subscribe(
          (response: PatientSearchResponse): void => {
            if (response.items.length === 1) {
              this.scheduleFor(response.items[0]);
              return;
            }

            this.patients = response.items;
            this.loading = false;
          },
          (error: HttpErrorResponse) => this.loading = false
        );
    }
  }

  scheduleFor = (patient: PatientSearchResult) => this.router.navigate(["schedules", "week"], { queryParams: { patientId: patient.id } });
  navigateToPatient = (patient: PatientSearchResult) => this.router.navigate(["../", patient.id], { relativeTo: this.activatedRoute });
}
