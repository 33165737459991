import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, HostBinding } from '@angular/core';
import { SelectedService, SelectedLocusContainer } from '../../../../generated/models';
import { Subject } from 'rxjs';

import * as moment from "moment";

@Component({
  selector: 'mp-selected-services-item',
  templateUrl: './selected-services-item.component.html',
  styleUrls: ['./selected-services-item.component.scss']
})
export class SelectedServicesItemComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() service: SelectedService;

  @Input() date: moment.Moment;
  @Input() age: number;

  @Output("toggleService") onToggle = new EventEmitter<boolean>();
  @Output() requestPerformer = new EventEmitter<void>();
  @Output() requestContainer = new EventEmitter<void>();

  @HostBinding('class.selected')
  @Input() selected = false;

  @HostBinding('class.refunded')
  get refunded(): boolean { return this.service.refundId > 0 && this.service.refundStatus === 1; }

  @HostBinding('class.invalid')
  get invalid(): boolean { return this.invalidPrice || this.performerRequired || this.invalidContainers; }

  get hasDiscount(): boolean { return this.service.grossPrice - this.service.finalPrice >= 0.01; }
  get hasPerformer(): boolean { return !!this.service.performer; }

  get performerRequired(): boolean { return !!this.service.specialityId && !this.service.performer; }
  get invalidPrice(): boolean { return !this.service.grossPrice; }

  get invalidPerformer(): boolean {
    if (!this.hasPerformer) return false;

    if (this.age === undefined) return false;

    if (!!this.service.performer.minAge && this.age < this.service.performer.minAge) {
      return true;
    }

    if (!!this.service.performer.maxAge && this.age > this.service.performer.maxAge) {
      return true;
    }

    return false;
  }

  get readyDate(): string {
    if (!this.service.duration) return "Не указано";

    return `${this.service.duration} дн. (${this.date.clone().add(this.service.duration, "days").format("DD.MM.YYYY")})`;
  }

  get selectedContainers(): string {
    if (this.service.containers.length === 0) return "—";

    return this.service.containers.map((x: SelectedLocusContainer): string => `${x.containerName} (${x.locusName})`).join(", ");
  }

  get selectedPerformer(): string {
    return this.service.performer && this.service.performer.id > 0 ? this.service.performer.name : "—";
  }

  get withContainers(): boolean { return !!this.service.kdlCode && !this.service.isExpress; }
  get withPerformer(): boolean { return this.service.specialityId > 0; }

  get invalidContainers(): boolean {
    return !!this.service.kdlCode && (!this.service.containers || this.service.containers.length === 0) && !this.service.isExpress;
  }

  get grossPrice(): string { return this.formatPrice(this.service.grossPrice); }
  get finalPrice(): string { return this.formatPrice(this.service.finalPrice); }


  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  constructor() { }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  formatPrice(price: number) {
    if (price - Math.floor(price) >= 0.01) {
      return price.toLocaleString('ru-RU', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    return price.toLocaleString('ru-RU', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 });
  }

  changePerformer = () => this.requestPerformer.emit();
  changeContainer = () => this.requestContainer.emit();

  toggle = () => this.onToggle.emit(!this.selected);
}
