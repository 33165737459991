import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ScheduleBreakReasonsService } from '../../../../generated/services';
import { ScheduleBreakReasonResolverPayload } from '../../resolvers/schedule-break-reason-resolver';

@Component({
  selector: 'mp-schedule-break-reason',
  templateUrl: './schedule-break-reason.component.html',
  styleUrls: ['./schedule-break-reason.component.scss']
})
export class ScheduleBreakReasonComponent implements OnInit {

  id: number;
  title = "Новая причина перерыва";

  breadcrumbs = [
    { url: '/', title: 'Главная' },
    { url: '/settings/break-reasons', title: 'Причины перерывов' }
  ];

  form = new FormGroup({
    description: new FormControl("", [Validators.required]),
    initiator: new FormControl(null, [Validators.required])
  });

  constructor(private service: ScheduleBreakReasonsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService) {
  }

  ngOnInit() {
    this.activatedRoute.data
      .subscribe((data: { payload: ScheduleBreakReasonResolverPayload }): void => {
        this.id = data.payload.reason.id;

        this.title = this.id > 0 ? data.payload.reason.description : "Новая причина перерыва";

        this.form.patchValue({ ...data.payload.reason }, { emitEvent: false });
      });
  }

  acceptChanges(value: FormValue): void {
    Object.entries(this.form.controls).forEach(x => x[1].markAsTouched());

    if (!this.form.valid) return;
    if (this.id > 0) {
      this.service.UpdateAsync({ id: this.id, request: { description: value.description, initiator: value.initiator } })
        .subscribe(
          (): void => {
            this.toastrService.success('Причина перерыва обновлена', 'Успешно');

            this.router.navigate(['..'], { relativeTo: this.activatedRoute });
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              for (const error of response.error.errors) {
                this.toastrService.warning(error.message, 'Ошибка');
              }

            } else {
              this.toastrService.error('Не удалось обновить причину перерыва', 'Ошибка');
            }
          }
        );
    }
    else {
      this.service.CreateAsync({ description: value.description, initiator: value.initiator })
        .subscribe(
          (): void => {
            this.toastrService.success('Причина перерыва добавлена', 'Успешно');

            this.router.navigate(['..'], { relativeTo: this.activatedRoute });
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              for (const error of response.error.errors) {
                this.toastrService.warning(error.message, 'Ошибка');
              }

            } else {
              this.toastrService.error('Не удалось обновить причину перерыва', 'Ошибка');
            }
          }
        );
    }
  }
}

interface FormValue {
  description: string;
  initiator: 0 | 1;
}
