import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiscountResponse } from '../../../../generated/models';

@Component({
  selector: 'mp-discount-settings-item',
  templateUrl: './discount-settings-item.component.html',
  styleUrls: ['./discount-settings-item.component.scss']
})
export class DiscountSettingsItemComponent implements OnInit {

  @Input() item: DiscountResponse;

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get getDiscountType(): string {
    if (!this.item) return '';

    if (this.item.discountType === 1) return "Постоянная";
    if (this.item.discountType === 2) return "На посещение";

    return '';
  }

  get getSourceOfFinanceType(): string {
    if (!this.item) return '';

    if (this.item.sourceOfFinance === 1) return "За счет сотрудника";
    if (this.item.sourceOfFinance === 2) return "За счет организации";

    return '';
  }

  remove = () => this.onRemove.emit();
}
