import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageExternalConnectionModel } from '../../../../generated/models';
import { ExternalConnectionsService } from '../../../../generated/services';

@Component({
  selector: 'mp-manage-external-connection-modal',
  templateUrl: './manage-external-connection.modal.component.html',
  styleUrls: ['./manage-external-connection.modal.component.scss']
})
export class ManageExternalConnectionModalComponent implements OnInit {
  public title: string;

  @Input("model") model: ManageExternalConnectionModel;

  constructor(
    private activeModal: NgbActiveModal,
    private externalConnectionsService: ExternalConnectionsService
  ) {
    this.title = "Внешняя система";
  }

  ngOnInit() {

    this.title = `Удаление внешней системы "${this.model.name}"`;
  }

  public remove(): void {
    this.externalConnectionsService.Remove(this.model.id)
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (): void => { }
      );
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

}
