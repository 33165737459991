import { Injectable } from '@angular/core';
import { IdbBaseService } from './idb-base-service';
import { ReplaySubject, Observable, Subject, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserStorageService } from '@skytecs/security';
import { DiagnosesService } from '../generated/services';

@Injectable({
  providedIn: 'root'
})
export class DiagnosesWebworkerService {
  dataLoaded = new ReplaySubject<boolean>();

  constructor(private idbBaseService: IdbBaseService,
    private diagnosesService: DiagnosesService,
    private userStorageService: UserStorageService) { }

  init() {
    this.idbBaseService.dbLoaded
      .pipe(
        switchMap(() => { return this.needsUpdate() }),
        switchMap((result) => { return result ? this.loadData() : of({}) })
      ).subscribe(() => this.dataLoaded.next(true));
  }

  loadData(): Observable<void> {
    const result = new Subject<void>();

    if (typeof Worker !== 'undefined') {
      const worker = new Worker('/web-workers/web-worker.js', { type: 'module' });
      worker.onerror = () => result.next();
      worker.onmessage = ({ data }) => {
        if (!data || typeof (data) === 'string') {
          result.next();
          return;
        }

        const transaction = this.idbBaseService.db.transaction(["icd10"], "readwrite");
        transaction.onerror = (e) => console.info('transerror', e);
        transaction.onabort = (e) => console.info('transabort', e);
        transaction.oncomplete = () => result.next();

        transaction.objectStore("icd10").clear();

        for (let item of data) {
          transaction.objectStore("icd10").add({ "id": item.id, "code": item.code, "name": item.name, "parentId": item.parentId });
        }

        worker.terminate();
      };

      worker.postMessage({ url: window.location.origin + "/api/v1/Diagnoses", token: this.userStorageService.getToken() });

    } else {
      console.error("Web workers not supported")
      result.next();
    }

    return result.asObservable();
  }

  needsUpdate(): Observable<boolean> {
    const item = localStorage.getItem('mypatients_databset_versions');

    let version = { icd10: -1 };

    if (item) {
      version = JSON.parse(item);
    }

    return this.diagnosesService.VersionAsync()
      .pipe(
        tap(x => {
          const newVersion = { ...version, icd10: x.version };
          localStorage.setItem('mypatients_databset_versions', JSON.stringify(newVersion));
        }),
        map(x => x.version !== version.icd10),
      );
  }
}
