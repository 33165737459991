import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable, of } from "rxjs";
import { AbsenceReason } from "../../../generated/models";
import { AbsenceReasonsService } from "../../../generated/services";

@Injectable({ providedIn: "root" })
export class AbsenceReasonResolver implements Resolve<AbsenceReasonPayload>  {

  constructor(private absenceReasonsService: AbsenceReasonsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): AbsenceReasonPayload | Observable<AbsenceReasonPayload> | Promise<AbsenceReasonPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    let reasonLoader: Observable<AbsenceReason> = of({});

    if (id > 0) {
      reasonLoader = this.absenceReasonsService.ReasonAsync(id);
    }

    return forkJoin({
      absenceReason: reasonLoader
    });
  }


}

export interface AbsenceReasonPayload {
  absenceReason?: AbsenceReason
}
