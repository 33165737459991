import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject, from } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Metric } from '../../../../generated/models';
import { MetricsService } from '../../../../generated/services';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'mp-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  host: { class: "page" }
})
export class MetricsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  filters = new FormGroup({
    search: new FormControl('')
  });

  loading = false;
  page = 1;
  size = 100;
  items: Metric[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private confirmationService: ConfirmationModalService,
    private metricsService: MetricsService
  ) {

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FilterValues) => {
          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
        tap(() => {
          this.loading = true;
          this.page = 1;
          this.items = [];
        }),
        switchMap((value: FilterValues): Observable<Metric[]> => {
          return this.metricsService.MetricsAsync({ Page: this.page, Size: this.size, Search: value.search }).pipe(catchError((): Observable<Metric[]> => from([])));
        })
      )
      .subscribe((result: Metric[]) => {
        this.items = result;
        this.loading = false;
      });
  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParamMap;

    const value: FilterValues = {
      search: ''
    };

    if (params.has('search')) {
      value.search = params.get('search');
    }

    this.filters.patchValue(value);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async onScrollDown() {
    this.page += 1;
    this.loading = true;

    const items = await this.load();

    this.items.push(...items);
    this.loading = false;
  }

  async load() {
    const value: FilterValues = this.filters.getRawValue();

    return this.metricsService.MetricsAsync({ Page: this.page, Size: this.size, Search: value.search }).toPromise();
  }

  add = () => this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  edit = (item: Metric) => this.router.navigate([`${item.id}`], { relativeTo: this.activatedRoute });

  async remove(item: Metric) {
    const confirmed = await this.confirmationService.open({ message: `Показатель "${item.displayName}" будет удален. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.metricsService.RemoveMetricAsync(item.id)
      .subscribe(
        async () => {
          this.toastrService.success(`Показатель "${item.displayName}" удалён`, 'Успешно');

          this.page = 1;
          this.items = [];
          this.loading = true;

          this.items = await this.load();
          this.loading = false;

        },
        (response: HttpErrorResponse) => {
          this.handleError(response, 'Не удалось удалить показатель');
        }
      );

  }

  handleError(response: HttpErrorResponse, message: string) {
    if (response.status === 400 && response.error && response.error.errors) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;

          case 1: this.toastrService.warning('Необходимо указать код показателя', 'Ошибка'); break;
          case 2: this.toastrService.warning('Необходимо указать название показателя', 'Ошибка'); break;
          case 3: this.toastrService.warning('Неизвестный тип показателя', 'Ошибка'); break;
          case 4: this.toastrService.warning('Необходимо указать формулу вычисляемого показателя', 'Ошибка'); break;
          case 5: this.toastrService.warning('Показатель с указанным кодом уже добавлен', 'Ошибка'); break;
          case 6: this.toastrService.warning('Показатель не найден', 'Ошибка'); break;
          case 7: this.toastrService.warning('Показатель используется', 'Ошибка'); break;

          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }
}

interface FilterValues {
  search: string;
}
