import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mp-discount-badge',
  templateUrl: './discount-badge.component.html',
  styleUrls: ['./discount-badge.component.scss'],
})
export class DiscountBadgeComponent implements OnInit {
  @Input() amount: number;

  get show(): boolean { return this.amount > 0 && this.amount <= 100; }

  constructor() { }
  ngOnInit() { }
}
