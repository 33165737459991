import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WageSchemasService } from '../../../../generated/services';
import { WageSchemaPayload } from '../../resolvers/wage-schema-resolver';

@Component({
  selector: 'mp-wage-schema',
  templateUrl: './wage-schema.component.html',
  styleUrls: ['./wage-schema.component.scss'],
  host: { class: "page" }
})
export class WageSchemaComponent implements OnInit {

  id: number;

  title: string;
  breadcrumbs = [{ url: '/', title: 'Главная' }, { url: '/settings/wage-schemas', title: 'Список схем оплаты' }]

  form: FormGroup;

  constructor(private wageSchemasService: WageSchemasService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.title = "Новая схема оплаты";

    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      multiplier: new FormControl(0, [Validators.min(0)])
    });
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { payload: WageSchemaPayload }): void => {
        this.id = data.payload.wageSchema.id;

        if (this.id) {
          this.form.patchValue({
            name: data.payload.wageSchema.name,
            multiplier: data.payload.wageSchema.multiplier
          });

          this.title = `Схема оплаты: ${data.payload.wageSchema.name}`;
        }
      }
    )
  }

  acceptChanges(): void {
    Object.entries(this.form.controls).map(x => x[1]).forEach(x => x.markAsDirty());

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      this.wageSchemasService.UpdateWageSchema({ id: this.id, request: { multiplier: value.multiplier, name: value.name } })
        .subscribe(
          (): void => {
            this.toastrService.success(`Схема оплаты «‎${value.name}» обновлена`, "Успешно");
            this.router.navigate(['..'], { relativeTo: this.activatedRoute });
          },
          (error: HttpErrorResponse): void => {
            if (error.status === 400) {
              this.toastrService.warning(error.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось обновить схему оплаты", "Ошибка");
          }
        );

    } else {
      this.wageSchemasService.CreateWageSchema({ multiplier: value.multiplier, name: value.name })
        .subscribe(
          (): void => {
            this.toastrService.success(`Схема оплаты «‎${value.name}» добавлена`, "Успешно");
            this.router.navigate(['..'], { relativeTo: this.activatedRoute });
          },
          (error: HttpErrorResponse): void => {
            if (error.status === 400) {
              this.toastrService.warning(error.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось создать схему оплаты", "Ошибка");
          }
        );
    }
  }
}

interface FormValue {
  name: string;
  multiplier: number;
}
