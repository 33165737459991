import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { timer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-timer-activator',
  templateUrl: './timer-activator.component.html',
  styleUrls: ['./timer-activator.component.scss']
})
export class TimerActivatorComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() time = 0;
  @Output() onTimerTriggered = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
    timer(this.time, this.time)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onTimerTriggered.emit());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
