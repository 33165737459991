import { Company, Attending, AttendingsResponse } from '../../../generated/models';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CompaniesService, EmployeesService } from '../../../generated/services';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class DashboardResolver implements Resolve<WorkspaceDashboardPayload>  {

  constructor(
    private companiesService: CompaniesService,
    private employeesService: EmployeesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkspaceDashboardPayload> {

    const companiesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: CompanyType.Branch });
    const attendingsLoader: Observable<Attending[]> = this.employeesService.Attendings({ Companies: [], Page: 1, Size: 0 })
      .pipe(
        map((response: AttendingsResponse): Attending[] => response.items)
      );

    return forkJoin({
      companies: companiesLoader,
      attendings: attendingsLoader
    });
  }
}

export class WorkspaceDashboardPayload {
  public companies: Company[];
  public attendings: Attending[];
}

enum CompanyType {
  Branch = 4
}
