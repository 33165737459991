import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mp-selected-items-list',
  templateUrl: './selected-items-list.component.html',
  styleUrls: ['./selected-items-list.component.scss']
})
export class SelectedItemsListComponent implements OnInit {

  @Input() showPlaceholder = false
  @Input() placeholder: string;

  @Output()
  onModalOpened: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  openModal(): void {
    this.onModalOpened.emit();
  }
}
