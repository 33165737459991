import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbsenceReasonsService } from '../../../../generated/services';
import { AbsenceReasonPayload } from '../../resolvers/absence-reason-resolver';

@Component({
  selector: 'mp-absence-reason',
  templateUrl: './absence-reason.component.html',
  styleUrls: ['./absence-reason.component.scss'],
  host: { class: "page" }
})
export class AbsenceReasonComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  title = "Новая причина возврата";

  breadcrumbs = [
    { url: '/', title: 'Главная' },
    { url: '/settings/absence-reasons', title: 'Причины неявки' }
  ];

  form = new FormGroup({
    name: new FormControl("", [Validators.required, Validators.maxLength(512)]),
    reasonable: new FormControl(false)
  });

  constructor(
    private absenceReasonsService: AbsenceReasonsService,
    private toastrService: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { payload: AbsenceReasonPayload }): void => {

        this.id = data.payload.absenceReason.id;

        if (this.id > 0) {
          this.title = `Причина неявки: ${data.payload.absenceReason.name}`;
        }

        this.form.patchValue(data.payload.absenceReason, { emitEvent: false });


      });

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async acceptChanges(): Promise<void> {
    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      try {
        await this.absenceReasonsService.UpdateAsync({
          id: this.id,
          request: {
            name: value.name,
            reasonable: value.reasonable
          }
        }).toPromise();

        this.toastrService.success('Причина неявки сохранена', 'Успешно');
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          for (const error of response.error.errors) {
            switch (error.status) {
              case 1: this.toastrService.warning('Доступ запрещен', 'Ошибка'); break;
              case 2: this.toastrService.warning('Необходимо указать название', 'Ошибка'); break;
              case 3: this.toastrService.warning('Длина названия не может превышать 512 символов', 'Ошибка'); break;
              case 4: this.toastrService.warning('Причина не найдена', 'Ошибка'); break;

              default: this.toastrService.warning('Не удалось сохранить причину неявки', 'Ошибка');
            }
          }
        } else {
          this.toastrService.error('Не удалось сохранить причину неявки', 'Ошибка');
        }

      }
    } else {
      try {
        const response = await this.absenceReasonsService.CreateAsync({
          name: value.name,
          reasonable: value.reasonable
        }).toPromise();

        this.toastrService.success('Причина неявки добавлена', 'Успешно');
        this.router.navigate(['..', response.absenceReasonId], { relativeTo: this.activatedRoute });

      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          for (const error of response.error.errors) {
            switch (error.status) {
              case 1: this.toastrService.warning('Доступ запрещен', 'Ошибка'); break;
              case 2: this.toastrService.warning('Необходимо указать название', 'Ошибка'); break;
              case 3: this.toastrService.warning('Длина названия не может превышать 512 символов', 'Ошибка'); break;
              case 4: this.toastrService.warning('Причина не найдена', 'Ошибка'); break;

              default: this.toastrService.warning('Не удалось создать причину неявки', 'Ошибка');
            }
          }
        } else {
          this.toastrService.error('Не удалось создать причину неявки', 'Ошибка');
        }

      }

    }
  }
}

class FormValue {
  name: string;
  reasonable: boolean;
}
