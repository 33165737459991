import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceSetService } from '../../../../generated/models';

@Component({
  selector: 'mp-price-set-prices-list-item',
  templateUrl: './price-set-prices-list-item.component.html',
  styleUrls: ['./price-set-prices-list-item.component.scss']
})
export class PriceSetPricesListItemComponent {
  @Input() price: PriceSetService;
  @Input() canDelete = false;

  @Output() onDelete = new EventEmitter<void>();

  getPriceString = (amount: number) => (amount && amount !== 0) ? amount.toString() : '';
  delete = () => this.onDelete.emit();
}
