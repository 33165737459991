/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CheckDiagnosesResponse } from '../models/check-diagnoses-response';
import { CheckDiagnosesRequest } from '../models/check-diagnoses-request';
import { CheckPatientsResponse } from '../models/check-patients-response';
import { CheckPatientsRequires } from '../models/check-patients-requires';
@Injectable({
  providedIn: 'root',
})
class InsuranceService extends __BaseService {
  static readonly CheckDiagnosesForPatientPath = '/api/v1/patients/{patientId}/diagnoses/check';
  static readonly CheckPatientsAsyncPath = '/api/v1/patients/insurance/check';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `InsuranceService.CheckDiagnosesForPatientParams` containing the following parameters:
   *
   * - `patientId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CheckDiagnosesForPatientResponse(params: InsuranceService.CheckDiagnosesForPatientParams): __Observable<__StrictHttpResponse<CheckDiagnosesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/patients/${params.patientId}/diagnoses/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckDiagnosesResponse>;
      })
    );
  }
  /**
   * @param params The `InsuranceService.CheckDiagnosesForPatientParams` containing the following parameters:
   *
   * - `patientId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CheckDiagnosesForPatient(params: InsuranceService.CheckDiagnosesForPatientParams): __Observable<CheckDiagnosesResponse> {
    return this.CheckDiagnosesForPatientResponse(params).pipe(
      __map(_r => _r.body as CheckDiagnosesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CheckPatientsAsyncResponse(request?: CheckPatientsRequires): __Observable<__StrictHttpResponse<CheckPatientsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/patients/insurance/check`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckPatientsResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CheckPatientsAsync(request?: CheckPatientsRequires): __Observable<CheckPatientsResponse> {
    return this.CheckPatientsAsyncResponse(request).pipe(
      __map(_r => _r.body as CheckPatientsResponse)
    );
  }
}

module InsuranceService {

  /**
   * Parameters for CheckDiagnosesForPatient
   */
  export interface CheckDiagnosesForPatientParams {
    patientId: number;
    request?: CheckDiagnosesRequest;
  }
}

export { InsuranceService }
