import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mp-manage-refund-reason',
  templateUrl: './manage-refund-reason.component.html',
  styleUrls: ['./manage-refund-reason.component.scss']
})
export class ManageRefundReasonComponent implements OnInit {

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onArchive: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();

  @Input() archived = false;
  @Input() inUse = false;
  @Input() fullName = "";

  constructor() { }

  ngOnInit() { }

  remove() {
    this.onRemove.emit();
  }

  archive() {
    this.onArchive.emit();
  }

  cancel() {
    this.onCancel.emit();
  }
}
