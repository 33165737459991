import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Attendance, Attending, Company, Speciality, CreateCallReportResponse, AttendanceCallReport } from '../../../../generated/models';
import { AttendanceFilters } from '../attendance-filters/attendance-filters.component';

import * as moment from 'moment';

import { ActivatedRoute, Params, Router } from '@angular/router';
import { AttendanceService, CallReportsService } from '../../../../generated/services';
import { takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallModalComponent, CallResult } from '../call-modal/call-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AttendancePayload } from '../../resolvers/attendance-resolver';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'mp-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  appointments$ = new BehaviorSubject<Attendance[]>([]);

  title = 'Контроль явки';

  loading = false;
  appointments: Attendance[] = [];
  planned: Attendance[] = [];

  companies: Company[] = [];
  doctors: Attending[] = [];
  specialities: Speciality[] = [];

  scheduleTotal = 0;
  scheduleArrived = 0;

  plannedTotal = 0;
  plannedArrived = 0;

  canRemoveCalls = false;

  filters: AttendanceFilters = {
    period: {
      from: moment().add(-1, 'day'),
      to: moment().add(-1, 'day')
    },
    date: moment().add(-1, 'day'),
    companies: [],
    doctors: [],
    specialities: [],
  };

  auxiliaryFilters = new FormGroup({
    includeArrived: new FormControl(false),
    type: new FormControl(0)
  });

  constructor(private userStorage: UserStorage,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private attendanceService: AttendanceService,
    private callReportsService: CallReportsService,
    private toastrService: ToastrService,
    private modal: NgbModal,
    private confirmationModalSrvice: ConfirmationModalService
  ) {
    this.canRemoveCalls = userStorage.hasPermission(PermissionNames.RemoveCallReports)

    this.auxiliaryFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.updateRoute();
          this.updateCollections();
        }
      );

  }

  async ngOnInit() {

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: { payload: AttendancePayload }) => {

        this.companies = data.payload.companies;
        this.doctors = data.payload.doctors;
        this.specialities = data.payload.specialities;

        const queryMap = this.activatedRoute.snapshot.queryParamMap;

        const filters: AttendanceFilters = {
          date: moment().add(-1, 'day'),
          period: {
            from: moment().add(-1, 'day'),
            to: moment().add(-1, 'day')
          },
          companies: [],
          doctors: [],
          specialities: [],
        };

        //if (queryMap.has('date') && moment(queryMap.get('date'), 'DD.MM.YYYY').isValid()) {
        //  filters.date = moment(queryMap.get('date'), 'DD.MM.YYYY');
        //}

        if (queryMap.has('from') && moment(queryMap.get('from'), 'DD.MM.YYYY').isValid()) {
          filters.period.from = moment(queryMap.get('from'), 'DD.MM.YYYY');
        }

        if (queryMap.has('to') && moment(queryMap.get('to'), 'DD.MM.YYYY').isValid()) {
          filters.period.to = moment(queryMap.get('to'), 'DD.MM.YYYY');
        }

        if (queryMap.has('includeArrived') && queryMap.get('includeArrived') === 'true') {
          this.auxiliaryFilters.patchValue({ includeArrived: true });
        }

        if (queryMap.has('company')) {
          filters.companies = queryMap.getAll('company').map(x => parseInt(x, 10));
        }

        if (queryMap.has('doctor')) {
          filters.doctors = queryMap.getAll('doctor').map(x => parseInt(x, 10));
        }

        if (queryMap.has('specialities')) {
          filters.specialities = queryMap.getAll('specialities').map(x => parseInt(x, 10));
        }

        if (queryMap.has('type')) {
          switch (parseInt(queryMap.get('type'), 10)) {
            case 1: this.auxiliaryFilters.patchValue({ type: 1 }); break;
            case 2: this.auxiliaryFilters.patchValue({ type: 2 }); break;
            default: this.auxiliaryFilters.patchValue({ type: 0 }); break;
          }
        }

        this.changeFilters(filters);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  updateRoute() {
    const auxillaryFilters: AuxiliaryFilters = this.auxiliaryFilters.getRawValue();

    const params: Params = {};

    //if (this.filters.date && this.filters.date.isValid()) {
    //  params['date'] = this.filters.date.format('DD.MM.YYYY')
    //}

    if (this.filters.period.from && this.filters.period.from.isValid()) {
      params['from'] = this.filters.period.from.format('DD.MM.YYYY')
    }

    if (this.filters.period.to && this.filters.period.to.isValid()) {
      params['to'] = this.filters.period.to.format('DD.MM.YYYY')
    }

    if (auxillaryFilters.includeArrived) {
      params['includeArrived'] = true;
    }

    if (this.filters.companies.length > 0) {
      params['company'] = this.filters.companies;
    }

    if (this.filters.doctors.length > 0) {
      params['doctor'] = this.filters.doctors;
    }

    if (this.filters.specialities.length > 0) {
      params['specialities'] = this.filters.specialities;
    }

    if ([1, 2].includes(auxillaryFilters.type)) {
      params['type'] = auxillaryFilters.type;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params })
  }

  updateCollections() {
    const auxillaryFilters: AuxiliaryFilters = this.auxiliaryFilters.getRawValue();


    const filtered = [];

    if (auxillaryFilters.type === 0 || auxillaryFilters.type === 1) {
      filtered.push(...this.appointments.filter(x => auxillaryFilters.includeArrived || x.visits.length === 0));
    }

    if (auxillaryFilters.type === 0 || auxillaryFilters.type === 2) {
      filtered.push(...this.planned.filter(x => auxillaryFilters.includeArrived || x.visits.length === 0));
    }

    this.appointments$.next(filtered);
  }

  async changeFilters(value: AttendanceFilters): Promise<void> {
    this.filters = { ...this.filters, ...value };

    this.updateRoute();

    this.title = `Контроль явки`;

    this.loading = true;
    try {
      const response = await this.attendanceService.AttendanceAsync({
        From: this.filters.period.from.format('DD.MM.YYYY'),
        To: this.filters.period.to.clone().add(1, 'day').format('DD.MM.YYYY'),
        CompanyIds: this.filters.companies,
        DoctorIds: this.filters.doctors,
        SpecialityIds: this.filters.specialities
      }).toPromise();

      this.appointments = [...response.appointments];
      this.planned = [...response.planned];
    } catch (e) {
      this.loading = false;
      this.toastrService.error('Не удалось загрузить список', 'Ошибка');
    }
    this.loading = false;

    this.updateCollections();
  }

  async createCallReportAsync(item: Attendance, result: CallResult): Promise<CreateCallReportResponse> {

    if (item.attendanceType === 1) {
      return await this.callReportsService.CreateScheduleCallReportAsync({
        entryId: item.entryId,
        phone: item.phone,
        comment: result.comment,
        reasonIds: result.reasons.map(x => x.id),
        status: result.outcome
      }).toPromise();
    }

    if (item.attendanceType === 2) {
      return await this.callReportsService.CreateRecordCallReportAsync({
        recordId: item.recordId,
        phone: item.phone,
        comment: result.comment,
        reasonIds: result.reasons.map(x => x.id),
        status: result.outcome
      }).toPromise();
    }

    throw "Unknown attendance type";
  }

  handleCallReportError(response: HttpErrorResponse) {
    if (response.status === 400) {
      for (const error of response.error.errors) {

        switch (error.status) {
          case -2: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Звонок не найден', 'Ошибка'); break;
          case 2: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 3: this.toastrService.warning('Причина неявки не найдена', 'Ошибка'); break;
          case 4: this.toastrService.warning('Запись отменена', 'Ошибка'); break;

          default: this.toastrService.error('Не удалось сохранить звонок', 'Ошибка'); break;
        }
      }
    }
    else {
      this.toastrService.error('Не удалось сохранить звонок', 'Ошибка');
    }
  }

  async createCall(item: Attendance) {
    const componentRef = await this.openCall(item);

    componentRef.onConfirm.subscribe(async (result: CallResult) => {
      componentRef.loading = true;

      try {
        const response = await this.createCallReportAsync(item, result);

        item.calls.push({
          id: response.id,
          date: moment().format('DD.MM.YYYY HH:mm'),
          status: result.outcome,
          reasons: result.reasons.map(x => ({ reasonId: x.id, name: x.name, reasonable: x.reasonable })),
          comment: result.comment
        });

        this.toastrService.success("Отчет о звонке добавлен", "Успешно");

        componentRef.cancel();
      } catch (e) {
        this.handleCallReportError(e as HttpErrorResponse);
      }

      componentRef.loading = false;
    });
  }

  async updateCall(item: Attendance, call: AttendanceCallReport) {
    const componentRef = await this.openCall(item);
    componentRef.report = {
      comment: call.comment,
      outcome: 6,
      reasons: call.reasons.map(x => ({ ...x, id: x.reasonId }))
    };

    componentRef.onConfirm.subscribe(async (result: CallResult) => {
      componentRef.loading = true;

      try {
        await this.callReportsService.UpdateCallReportAsync({ id: call.id, request: { reasonIds: result.reasons.map(x => x.id), comment: result.comment } }).toPromise();

        call.comment = result.comment;
        call.reasons = result.reasons.map(x => ({ reasonId: x.id, name: x.name, reasonable: x.reasonable }));

        this.toastrService.success("Отчет о звонке обновлен", "Успешно");

        componentRef.cancel();
      } catch (e) {
        this.handleCallReportError(e as HttpErrorResponse);
      }

      componentRef.loading = false;
    });

  }

  async openCall(item: Attendance): Promise<CallModalComponent> {
    const modalRef = this.modal.open(CallModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: CallModalComponent = modalRef.componentInstance;

    if (item.attendanceType === 1) {
      componentRef.left = {
        title: 'Запись',
        empty: '',
        items: [
          item.date,
          item.companyName,
          item.doctorName,
          item.serviceName
        ]
      };
    }

    if (item.attendanceType === 2) {
      componentRef.left = {
        title: 'Основание явки',
        empty: '',
        items: [
          item.doctorName,
          item.serviceName,
          item.appointmentReason
        ]
      };
    }

    componentRef.right = {
      title: 'Посещения',
      empty: 'Нет посещений',
      items: item.visits.map(x => x.services.map(s => s.serviceName)).reduce((x, y) => [...x, ...y], []),
      isEmpty: item.visits.length === 0
    };

    componentRef.patientName = item.patientName;
    componentRef.phone = item.phone;

    if (item.phone && item.phone.length === 11) {
      componentRef.phone = [
        '+7',
        ` (${item.phone.substr(1, 3)}) `,
        `${item.phone.substr(4, 3)}-${item.phone.substr(7, 2)}-${item.phone.substr(9, 2)}`
      ].join('');
    }

    componentRef.onCancel.subscribe(() => modalRef.close());

    return componentRef;
  }

  clickType(item: Attendance) {
    if (item.attendanceType === 1) {
      const date = moment(item.date, 'DD.MM.YYYY HH:mm');

      const params: Params = {
        medicalCenter: [item.companyId]
      };

      if (item.doctorId > 0) {
        params['doctor'] = [item.doctorId];
      }

      if (item.serviceId > 0) {
        params['service'] = [item.serviceId];
      }

      this.router.navigate(['schedules', 'day', date.format('YYYY-MM-DD')], { queryParams: params });
    }

    if (item.attendanceType === 2) {
      this.router.navigate(['workspace', 'records', item.recordId]);
    }
  }

  async removeCall(item: Attendance, call: AttendanceCallReport) {

    const confirm = await this.confirmationModalSrvice.open({ message: 'Удалить отчет о звонке?' });

    if (confirm) {
      try {
        await this.callReportsService.RemoveCallReportAsync(call.id).toPromise();
        item.calls = item.calls.filter(x => x.id !== call.id);
        this.toastrService.success('Отчет по звонку удалён', 'Успешно');
      }
      catch (e) {
        this.handleCallReportError(e as HttpErrorResponse);
      }
    }
  }

  async downloadXlsx() {
    try {
      const response = await this.attendanceService.ExportScheduleAttendanceAsync({
        From: this.filters.period.from.format('DD.MM.YYYY'),
        To: this.filters.period.to.clone().add(1, 'day').format('DD.MM.YYYY'),
        CompanyIds: this.filters.companies,
        DoctorIds: this.filters.doctors,
        SpecialityIds: this.filters.specialities
      }).toPromise();

      this.print(response.content, response.contentType);
    } catch (e) {
      this.loading = false;
      this.toastrService.error('Не удалось загрузить список', 'Ошибка');
    }
  }

  private print(content: string, type: string) {
    const byteCharacters = atob(content);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: type });

    const url = URL.createObjectURL(blob);
    window.open(url);
  }

}

interface AuxiliaryFilters {
  includeArrived: boolean;
  type: 0 | 1 | 2;
}
