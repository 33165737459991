/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SystemSetting } from '../models/system-setting';
import { SystemSettingViewModel } from '../models/system-setting-view-model';
import { AddSettingResponse } from '../models/add-setting-response';
import { SystemSettingEditorModel } from '../models/system-setting-editor-model';
import { DeleteSettingValuesResponse } from '../models/delete-setting-values-response';
@Injectable({
  providedIn: 'root',
})
class SystemSettingsService extends __BaseService {
  static readonly SystemSettingsListPath = '/api/SystemSettings';
  static readonly SystemSettingInfoPath = '/api/SystemSettings/{name}';
  static readonly AddSystemSettingValuePath = '/api/SystemSettings/{name}/values';
  static readonly ChangeSystemSettingValuePath = '/api/SystemSettings/values/{id}';
  static readonly DeleteSystemSettingValuesPath = '/api/SystemSettings/values';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SystemSettingsService.SystemSettingsListParams` containing the following parameters:
   *
   * - `Query`:
   *
   * - `HasValue`:
   *
   * @return Success
   */
  SystemSettingsListResponse(params: SystemSettingsService.SystemSettingsListParams): __Observable<__StrictHttpResponse<Array<SystemSetting>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Query != null) __params = __params.set('Query', params.Query.toString());
    if (params.HasValue != null) __params = __params.set('HasValue', params.HasValue.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SystemSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystemSetting>>;
      })
    );
  }
  /**
   * @param params The `SystemSettingsService.SystemSettingsListParams` containing the following parameters:
   *
   * - `Query`:
   *
   * - `HasValue`:
   *
   * @return Success
   */
  SystemSettingsList(params: SystemSettingsService.SystemSettingsListParams): __Observable<Array<SystemSetting>> {
    return this.SystemSettingsListResponse(params).pipe(
      __map(_r => _r.body as Array<SystemSetting>)
    );
  }

  /**
   * @param name undefined
   * @return Success
   */
  SystemSettingInfoResponse(name: string): __Observable<__StrictHttpResponse<SystemSettingViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/SystemSettings/${name}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SystemSettingViewModel>;
      })
    );
  }
  /**
   * @param name undefined
   * @return Success
   */
  SystemSettingInfo(name: string): __Observable<SystemSettingViewModel> {
    return this.SystemSettingInfoResponse(name).pipe(
      __map(_r => _r.body as SystemSettingViewModel)
    );
  }

  /**
   * @param params The `SystemSettingsService.AddSystemSettingValueParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddSystemSettingValueResponse(params: SystemSettingsService.AddSystemSettingValueParams): __Observable<__StrictHttpResponse<AddSettingResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/SystemSettings/${params.name}/values`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddSettingResponse>;
      })
    );
  }
  /**
   * @param params The `SystemSettingsService.AddSystemSettingValueParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddSystemSettingValue(params: SystemSettingsService.AddSystemSettingValueParams): __Observable<AddSettingResponse> {
    return this.AddSystemSettingValueResponse(params).pipe(
      __map(_r => _r.body as AddSettingResponse)
    );
  }

  /**
   * @param params The `SystemSettingsService.ChangeSystemSettingValueParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeSystemSettingValueResponse(params: SystemSettingsService.ChangeSystemSettingValueParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/SystemSettings/values/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SystemSettingsService.ChangeSystemSettingValueParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeSystemSettingValue(params: SystemSettingsService.ChangeSystemSettingValueParams): __Observable<null> {
    return this.ChangeSystemSettingValueResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SystemSettingsService.DeleteSystemSettingValuesParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `Ids`:
   *
   * @return Success
   */
  DeleteSystemSettingValuesResponse(params: SystemSettingsService.DeleteSystemSettingValuesParams): __Observable<__StrictHttpResponse<DeleteSettingValuesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.name != null) __params = __params.set('name', params.name.toString());
    (params.Ids || []).forEach(val => {if (val != null) __params = __params.append('Ids', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/SystemSettings/values`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteSettingValuesResponse>;
      })
    );
  }
  /**
   * @param params The `SystemSettingsService.DeleteSystemSettingValuesParams` containing the following parameters:
   *
   * - `name`:
   *
   * - `Ids`:
   *
   * @return Success
   */
  DeleteSystemSettingValues(params: SystemSettingsService.DeleteSystemSettingValuesParams): __Observable<DeleteSettingValuesResponse> {
    return this.DeleteSystemSettingValuesResponse(params).pipe(
      __map(_r => _r.body as DeleteSettingValuesResponse)
    );
  }
}

module SystemSettingsService {

  /**
   * Parameters for SystemSettingsList
   */
  export interface SystemSettingsListParams {
    Query?: string;
    HasValue?: boolean;
  }

  /**
   * Parameters for AddSystemSettingValue
   */
  export interface AddSystemSettingValueParams {
    name: string;
    request?: SystemSettingEditorModel;
  }

  /**
   * Parameters for ChangeSystemSettingValue
   */
  export interface ChangeSystemSettingValueParams {
    id: number;
    request?: SystemSettingEditorModel;
  }

  /**
   * Parameters for DeleteSystemSettingValues
   */
  export interface DeleteSystemSettingValuesParams {
    name?: string;
    Ids?: Array<number>;
  }
}

export { SystemSettingsService }
