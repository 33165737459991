import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonDiagnosis } from '../../../../generated/models';

@Component({
  selector: 'mp-diagnosis-list',
  templateUrl: './diagnosis-list.component.html',
  styleUrls: ['./diagnosis-list.component.scss']
})
export class DiagnosisListComponent implements OnInit {

  @Input() forbidden = new Set<string>();
  @Input() items: PersonDiagnosis[] = [];
  @Input() loading = false;

  @Output() onOpen = new EventEmitter<PersonDiagnosis>();

  constructor() { }

  ngOnInit() { }

  open = (item: PersonDiagnosis) => this.onOpen.emit(item);

}
