import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mp-diagnosis-warning-modal',
  templateUrl: './diagnosis-warning-modal.component.html',
  styleUrls: ['./diagnosis-warning-modal.component.scss']
})
export class DiagnosisWarningModalComponent implements OnInit {

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  diagnoses: DiagnosisInfo[] = [];

  @Input()
  insuranceCompany = ""

  get forbiddenDiagnoses(): string[] { return this.diagnoses.filter(x => x.status === 1).map(x => x.code); }

  constructor() { }

  ngOnInit() { }

  confirm(): void {
    this.onConfirm.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }
}

export interface DiagnosisInfo {
  code: string;
  name: string;
  status: 0 | 1 | 2;
}
