import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Role } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-role-list-item',
  templateUrl: './role-list-item.component.html',
  styleUrls: ['./role-list-item.component.scss']
})
export class RoleListItemComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();

  @Input() role: Role;
  @Input() public set checked(value: boolean) {
    this.checkbox.setValue(value);
  }

  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  public checkbox: FormControl;

  constructor() {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: boolean): void => {
          this.onCheck.emit(value);
        },
        (): void => { }
      );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }


}
