import { Component, OnInit, Input, OnDestroy, forwardRef, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export const SEARCH_CONTROL_DEBOUNCE: InjectionToken<BehaviorSubject<number>> = new InjectionToken<BehaviorSubject<number>>("SEARCH_CONTROL_DEBOUNCE");

@Component({
  selector: 'mp-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchPanelComponent),
    multi: true
  }]
})
export class SearchPanelComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input("placeholder") searchPlaceholder: string;
  @Input() debounce: number;

  destroy$ = new Subject<void>();
  source$ = new Subject<string>()

  value = '';
  disabled = false;

  changeFn: (value: any) => any;
  touchFn: (value: any) => any;

  constructor(private injector: Injector) { }

  ngOnInit() {

    this.injector.get(SEARCH_CONTROL_DEBOUNCE, new BehaviorSubject<number>(1000))
      .subscribe((value: number) => {
        this.debounce = this.debounce || value || 1000;

        this.source$
          .pipe(
            takeUntil(this.destroy$),
            debounceTime(this.debounce)
          )
          .subscribe((x: string) => {
            if (this.changeFn) this.changeFn(x)
          });
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  change() { this.source$.next(this.value); }

  blur() { if (this.touchFn) this.touchFn(this.value); }

  /*-----------ControlValueAccessor------------*/
  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    if (fn) this.changeFn = fn;
  }
  registerOnTouched(fn: any): void {
    if (fn) this.touchFn = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
