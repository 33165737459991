/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CategoriesResponse } from '../models/categories-response';
import { CreateCategoryResponse } from '../models/create-category-response';
import { CreateCategoryRequest } from '../models/create-category-request';
import { CategoryEditorModel } from '../models/category-editor-model';
import { UpdateCategoryRequest } from '../models/update-category-request';
@Injectable({
  providedIn: 'root',
})
class CategoriesService extends __BaseService {
  static readonly CategoriesPath = '/api/Categories';
  static readonly CreatePath = '/api/Categories';
  static readonly CategoryPath = '/api/Categories/{id}';
  static readonly UpdatePath = '/api/Categories/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CategoriesService.CategoriesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  CategoriesResponse(params: CategoriesService.CategoriesParams): __Observable<__StrictHttpResponse<CategoriesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CategoriesResponse>;
      })
    );
  }
  /**
   * @param params The `CategoriesService.CategoriesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Categories(params: CategoriesService.CategoriesParams): __Observable<CategoriesResponse> {
    return this.CategoriesResponse(params).pipe(
      __map(_r => _r.body as CategoriesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: CreateCategoryRequest): __Observable<__StrictHttpResponse<CreateCategoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCategoryResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: CreateCategoryRequest): __Observable<CreateCategoryResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as CreateCategoryResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  CategoryResponse(id: number): __Observable<__StrictHttpResponse<CategoryEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Categories/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CategoryEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Category(id: number): __Observable<CategoryEditorModel> {
    return this.CategoryResponse(id).pipe(
      __map(_r => _r.body as CategoryEditorModel)
    );
  }

  /**
   * @param params The `CategoriesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: CategoriesService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Categories/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CategoriesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: CategoriesService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CategoriesService {

  /**
   * Parameters for Categories
   */
  export interface CategoriesParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: UpdateCategoryRequest;
  }
}

export { CategoriesService }
