import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TreatmentPrescriptionPayload } from '../../models/treatment-prescription-payload';

@Component({
  selector: 'mp-treatment-analog-modal',
  templateUrl: './treatment-analog-modal.component.html',
  styleUrls: ['./treatment-analog-modal.component.scss']
})
export class TreatmentAnalogModalComponent implements OnInit {

  @Input() treatmentName = '';
  @Input() analogs: string[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onAdd = new EventEmitter<string>();
  @Output() onRemove = new EventEmitter<string>();

  title = 'Аналоги';

  control: FormControl = new FormControl();

  constructor() { }

  ngOnInit() {
    this.title = `${this.treatmentName} - Аналоги`;
  }

  add(): void {
    const value: string = this.control.value;

    if (!value) return;
    if (value === this.treatmentName) return;
    if (this.analogs.some(x => x.trim() === value.trim())) return;

    this.onAdd.emit(value);
    this.control.reset();
  }

  remove(item: string): void {
    this.onRemove.emit(item);
  }

  cancel(): void {
    this.onCancel.emit();
  }

}
