import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Icd10ResponseItem, RecordSection } from '../../../../generated/models';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, OperatorFunction, Subject, from } from 'rxjs';
import { DiagnosesService } from '../../../../generated/services';

@Component({
  selector: 'mp-diagnosis-section-editor',
  templateUrl: './diagnosis-section-editor.component.html',
  styleUrls: ['./diagnosis-section-editor.component.scss']
})
export class DiagnosisSectionEditorComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() useFastDiagnosisSearch = false;
  @Input() disabled = true;
  @Input() section: RecordSection = {};

  @Output() onRequest = new EventEmitter<void>();
  @Output() onClear = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<RecordSection>();

  control = new FormControl();
  search = new FormControl();

  isType = (type: 0 | 1 | 2 | 3) => this.section && this.section.diagnosisType === type;

  filterKeys(e) {
    e.stopPropagation();
    return false;
  }

  get selected(): boolean { return this.section && !!this.section.diagnosisCode; }

  constructor(private diagnosesService: DiagnosesService) {

    this.control.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged((x: string, y: string): boolean => x === y),
        takeUntil(this.destroy$)
      )
      .subscribe((value: string) => this.onChange.emit({ ...this.section, diagnosisText: value }));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      const section: RecordSection = changes['section'].currentValue;

      this.control.setValue(section.diagnosisText, { emitEvent: false, emitModelToViewChange: true });
      this.search.setValue('');
    }

    if (changes['disabled']) {
      const disabled = changes['disabled'].currentValue;

      if (disabled) {
        this.control.disable({ emitEvent: false });
        this.search.disable({ emitEvent: false });
      } else {
        this.control.enable({ emitEvent: false });
        this.search.enable({ emitEvent: false });
      }

    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  requestDiagnosis() {
    if (this.disabled) return;

    this.onRequest.emit();
  }

  clearDiagnosis = () => this.onClear.emit();

  diagnosesSearch: OperatorFunction<string, Icd10ResponseItem[]> = (text$: Observable<string>) =>
    text$.pipe(
      takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        if (!term || term.length < 3) return from([[]]);

        return this.diagnosesService.SearchAsync(term)
          .pipe(
            tap((result: Icd10ResponseItem[]) => {
              if (result.length === 1) {
                this.onChange.emit({ ...this.section, diagnosisText: result[0].name, diagnosisCode: result[0].code });
              }
            }),
            map((result: Icd10ResponseItem[]) => result.length > 10 ? result.slice(0, 10) : result)
          );
      })
    );

  formatter = (value: Icd10ResponseItem): string => value ? `${value.name}` : '';

  select = (value: { item: Icd10ResponseItem }) => {
    if (!value) return;

    this.onChange.emit({ ...this.section, diagnosisText: value.item.name, diagnosisCode: value.item.code });
    this.search.setValue('');
  }
}
