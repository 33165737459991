/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FiltersResponse } from '../models/filters-response';
import { ScheduleDoctor } from '../models/schedule-doctor';
import { ScheduleCompany } from '../models/schedule-company';
import { ScheduleService } from '../models/schedule-service';
import { ResourcesDayScheduleViewModel } from '../models/resources-day-schedule-view-model';
import { AppointmentHistoryItem } from '../models/appointment-history-item';
import { AppointmentViewModel } from '../models/appointment-view-model';
import { ReserveSlotRequest } from '../models/reserve-slot-request';
import { ClaimSlotRequest } from '../models/claim-slot-request';
import { UpdateSlotRequest } from '../models/update-slot-request';
import { ConfirmSlotRequest } from '../models/confirm-slot-request';
import { AddBreakRequest } from '../models/add-break-request';
import { WeekScheduleViewModel } from '../models/week-schedule-view-model';
import { ShiftService } from '../models/shift-service';
import { AppointmentSummary } from '../models/appointment-summary';
import { Slot } from '../models/slot';
@Injectable({
  providedIn: 'root',
})
class SchedulesService extends __BaseService {
  static readonly FiltersPath = '/api/Schedules/filters';
  static readonly DoctorsPath = '/api/Schedules/doctors';
  static readonly CompaniesPath = '/api/Schedules/companies';
  static readonly ServicesPath = '/api/Schedules/services';
  static readonly ResourceSlotsPath = '/api/Schedules/day/slots';
  static readonly PeriodPath = '/api/Schedules/period';
  static readonly SlotHistoryPath = '/api/Schedules/day/slots/{scheduleId}/history';
  static readonly ReserveSlotPath = '/api/Schedules/day/slots/{scheduleId}/reserve';
  static readonly ClaimSlotPath = '/api/Schedules/day/slots/{scheduleId}/claim';
  static readonly ChangeSlotPath = '/api/Schedules/day/slots/{scheduleId}/change/{id}';
  static readonly ReleaseSlotPath = '/api/Schedules/day/slots/{scheduleId}/release/{id}';
  static readonly ConfirmSlotPath = '/api/Schedules/day/slots/{scheduleId}/confirm/{id}';
  static readonly DenySlotPath = '/api/Schedules/day/slots/{scheduleId}/deny/{id}';
  static readonly RejectSlotPath = '/api/Schedules/day/slots/{scheduleId}/reject/{id}';
  static readonly BreakSlotPath = '/api/Schedules/day/slots/{scheduleId}/break/{id}';
  static readonly AddBreakAsyncPath = '/api/appointments/{id}/break';
  static readonly RemoveBreakAsyncPath = '/api/appointments/{id}/break';
  static readonly NotifyAsyncPath = '/api/Schedules/day/slots/{scheduleId}/notify/{id}';
  static readonly WeeklySchedulePath = '/api/Schedules/week/slots';
  static readonly ScheduleServicesPath = '/api/Schedules/day/slots/{scheduleId}/services';
  static readonly GenerateCalendarPath = '/schedule/calendar';
  static readonly SummaryPath = '/api/Schedules/appointments/{id}/summary';
  static readonly GetEntryIdPath = '/api/Schedules/appointments/entries/{entryId}';
  static readonly GetEntrySummaryAsyncPath = '/api/Schedules/appointments/entries/{entryId}/summary';
  static readonly FindSlotAsyncPath = '/api/Schedules/day/slots/{scheduleId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param bookingEnabledFor undefined
   * @return Success
   */
  FiltersResponse(bookingEnabledFor?: any): __Observable<__StrictHttpResponse<FiltersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (bookingEnabledFor != null) __params = __params.set('bookingEnabledFor', bookingEnabledFor.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/filters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FiltersResponse>;
      })
    );
  }
  /**
   * @param bookingEnabledFor undefined
   * @return Success
   */
  Filters(bookingEnabledFor?: any): __Observable<FiltersResponse> {
    return this.FiltersResponse(bookingEnabledFor).pipe(
      __map(_r => _r.body as FiltersResponse)
    );
  }

  /**
   * @return Success
   */
  DoctorsResponse(): __Observable<__StrictHttpResponse<Array<ScheduleDoctor>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/doctors`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ScheduleDoctor>>;
      })
    );
  }
  /**
   * @return Success
   */
  Doctors(): __Observable<Array<ScheduleDoctor>> {
    return this.DoctorsResponse().pipe(
      __map(_r => _r.body as Array<ScheduleDoctor>)
    );
  }

  /**
   * @return Success
   */
  CompaniesResponse(): __Observable<__StrictHttpResponse<Array<ScheduleCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ScheduleCompany>>;
      })
    );
  }
  /**
   * @return Success
   */
  Companies(): __Observable<Array<ScheduleCompany>> {
    return this.CompaniesResponse().pipe(
      __map(_r => _r.body as Array<ScheduleCompany>)
    );
  }

  /**
   * @param bookingPermittedFor undefined
   * @return Success
   */
  ServicesResponse(bookingPermittedFor?: any): __Observable<__StrictHttpResponse<Array<ScheduleService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (bookingPermittedFor != null) __params = __params.set('bookingPermittedFor', bookingPermittedFor.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ScheduleService>>;
      })
    );
  }
  /**
   * @param bookingPermittedFor undefined
   * @return Success
   */
  Services(bookingPermittedFor?: any): __Observable<Array<ScheduleService>> {
    return this.ServicesResponse(bookingPermittedFor).pipe(
      __map(_r => _r.body as Array<ScheduleService>)
    );
  }

  /**
   * @param params The `SchedulesService.ResourceSlotsParams` containing the following parameters:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `DoctorIds`:
   *
   * - `Day`:
   *
   * @return Success
   */
  ResourceSlotsResponse(params: SchedulesService.ResourceSlotsParams): __Observable<__StrictHttpResponse<Array<ResourcesDayScheduleViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.SpecsIds || []).forEach(val => {if (val != null) __params = __params.append('SpecsIds', val.toString())});
    (params.ServicesIds || []).forEach(val => {if (val != null) __params = __params.append('ServicesIds', val.toString())});
    (params.OrganizationIds || []).forEach(val => {if (val != null) __params = __params.append('OrganizationIds', val.toString())});
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    if (params.Day != null) __params = __params.set('Day', params.Day.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/day/slots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResourcesDayScheduleViewModel>>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ResourceSlotsParams` containing the following parameters:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `DoctorIds`:
   *
   * - `Day`:
   *
   * @return Success
   */
  ResourceSlots(params: SchedulesService.ResourceSlotsParams): __Observable<Array<ResourcesDayScheduleViewModel>> {
    return this.ResourceSlotsResponse(params).pipe(
      __map(_r => _r.body as Array<ResourcesDayScheduleViewModel>)
    );
  }

  /**
   * @param params The `SchedulesService.PeriodParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `BookingAllowedFor`:
   *
   * @return Success
   */
  PeriodResponse(params: SchedulesService.PeriodParams): __Observable<__StrictHttpResponse<Array<ResourcesDayScheduleViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    (params.SpecsIds || []).forEach(val => {if (val != null) __params = __params.append('SpecsIds', val.toString())});
    (params.ServicesIds || []).forEach(val => {if (val != null) __params = __params.append('ServicesIds', val.toString())});
    (params.OrganizationIds || []).forEach(val => {if (val != null) __params = __params.append('OrganizationIds', val.toString())});
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    if (params.BookingAllowedFor != null) __params = __params.set('BookingAllowedFor', params.BookingAllowedFor.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/period`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResourcesDayScheduleViewModel>>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.PeriodParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `BookingAllowedFor`:
   *
   * @return Success
   */
  Period(params: SchedulesService.PeriodParams): __Observable<Array<ResourcesDayScheduleViewModel>> {
    return this.PeriodResponse(params).pipe(
      __map(_r => _r.body as Array<ResourcesDayScheduleViewModel>)
    );
  }

  /**
   * @param params The `SchedulesService.SlotHistoryParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `Date`:
   *
   * @return Success
   */
  SlotHistoryResponse(params: SchedulesService.SlotHistoryParams): __Observable<__StrictHttpResponse<Array<AppointmentHistoryItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AppointmentHistoryItem>>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.SlotHistoryParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `Date`:
   *
   * @return Success
   */
  SlotHistory(params: SchedulesService.SlotHistoryParams): __Observable<Array<AppointmentHistoryItem>> {
    return this.SlotHistoryResponse(params).pipe(
      __map(_r => _r.body as Array<AppointmentHistoryItem>)
    );
  }

  /**
   * @param params The `SchedulesService.ReserveSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `request`:
   *
   * @return Success
   */
  ReserveSlotResponse(params: SchedulesService.ReserveSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/reserve`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ReserveSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `request`:
   *
   * @return Success
   */
  ReserveSlot(params: SchedulesService.ReserveSlotParams): __Observable<AppointmentViewModel> {
    return this.ReserveSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.ClaimSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `request`:
   *
   * @return Success
   */
  ClaimSlotResponse(params: SchedulesService.ClaimSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/claim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ClaimSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `request`:
   *
   * @return Success
   */
  ClaimSlot(params: SchedulesService.ClaimSlotParams): __Observable<AppointmentViewModel> {
    return this.ClaimSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.ChangeSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ChangeSlotResponse(params: SchedulesService.ChangeSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/change/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ChangeSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ChangeSlot(params: SchedulesService.ChangeSlotParams): __Observable<AppointmentViewModel> {
    return this.ChangeSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.ReleaseSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ReleaseSlotResponse(params: SchedulesService.ReleaseSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/release/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ReleaseSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ReleaseSlot(params: SchedulesService.ReleaseSlotParams): __Observable<AppointmentViewModel> {
    return this.ReleaseSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.ConfirmSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ConfirmSlotResponse(params: SchedulesService.ConfirmSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/confirm/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ConfirmSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ConfirmSlot(params: SchedulesService.ConfirmSlotParams): __Observable<AppointmentViewModel> {
    return this.ConfirmSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.DenySlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  DenySlotResponse(params: SchedulesService.DenySlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/deny/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.DenySlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  DenySlot(params: SchedulesService.DenySlotParams): __Observable<AppointmentViewModel> {
    return this.DenySlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.RejectSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  RejectSlotResponse(params: SchedulesService.RejectSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/reject/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.RejectSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  RejectSlot(params: SchedulesService.RejectSlotParams): __Observable<AppointmentViewModel> {
    return this.RejectSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.BreakSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  BreakSlotResponse(params: SchedulesService.BreakSlotParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/break/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.BreakSlotParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   *
   * @return Success
   */
  BreakSlot(params: SchedulesService.BreakSlotParams): __Observable<AppointmentViewModel> {
    return this.BreakSlotResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.AddBreakAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddBreakAsyncResponse(params: SchedulesService.AddBreakAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/appointments/${params.id}/break`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.AddBreakAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddBreakAsync(params: SchedulesService.AddBreakAsyncParams): __Observable<null> {
    return this.AddBreakAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveBreakAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/appointments/${id}/break`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveBreakAsync(id: number): __Observable<null> {
    return this.RemoveBreakAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SchedulesService.NotifyAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   */
  NotifyAsyncResponse(params: SchedulesService.NotifyAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/notify/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.NotifyAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `id`:
   */
  NotifyAsync(params: SchedulesService.NotifyAsyncParams): __Observable<null> {
    return this.NotifyAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SchedulesService.WeeklyScheduleParams` containing the following parameters:
   *
   * - `StartDate`:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `EndDate`:
   *
   * - `DoctorIds`:
   *
   * @return Success
   */
  WeeklyScheduleResponse(params: SchedulesService.WeeklyScheduleParams): __Observable<__StrictHttpResponse<WeekScheduleViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.StartDate != null) __params = __params.set('StartDate', params.StartDate.toString());
    (params.SpecsIds || []).forEach(val => {if (val != null) __params = __params.append('SpecsIds', val.toString())});
    (params.ServicesIds || []).forEach(val => {if (val != null) __params = __params.append('ServicesIds', val.toString())});
    (params.OrganizationIds || []).forEach(val => {if (val != null) __params = __params.append('OrganizationIds', val.toString())});
    if (params.EndDate != null) __params = __params.set('EndDate', params.EndDate.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/week/slots`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WeekScheduleViewModel>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.WeeklyScheduleParams` containing the following parameters:
   *
   * - `StartDate`:
   *
   * - `SpecsIds`:
   *
   * - `ServicesIds`:
   *
   * - `OrganizationIds`:
   *
   * - `EndDate`:
   *
   * - `DoctorIds`:
   *
   * @return Success
   */
  WeeklySchedule(params: SchedulesService.WeeklyScheduleParams): __Observable<WeekScheduleViewModel> {
    return this.WeeklyScheduleResponse(params).pipe(
      __map(_r => _r.body as WeekScheduleViewModel)
    );
  }

  /**
   * @param params The `SchedulesService.ScheduleServicesParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `PatientAge`:
   *
   * @return Success
   */
  ScheduleServicesResponse(params: SchedulesService.ScheduleServicesParams): __Observable<__StrictHttpResponse<Array<ShiftService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.PatientAge != null) __params = __params.set('PatientAge', params.PatientAge.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ShiftService>>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.ScheduleServicesParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `PatientAge`:
   *
   * @return Success
   */
  ScheduleServices(params: SchedulesService.ScheduleServicesParams): __Observable<Array<ShiftService>> {
    return this.ScheduleServicesResponse(params).pipe(
      __map(_r => _r.body as Array<ShiftService>)
    );
  }

  /**
   * @param params The `SchedulesService.GenerateCalendarParams` containing the following parameters:
   *
   * - `System`:
   *
   * - `ApiKey`:
   */
  GenerateCalendarResponse(params: SchedulesService.GenerateCalendarParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.System != null) __params = __params.set('System', params.System.toString());
    if (params.ApiKey != null) __params = __params.set('ApiKey', params.ApiKey.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/schedule/calendar`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.GenerateCalendarParams` containing the following parameters:
   *
   * - `System`:
   *
   * - `ApiKey`:
   */
  GenerateCalendar(params: SchedulesService.GenerateCalendarParams): __Observable<null> {
    return this.GenerateCalendarResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  SummaryResponse(id: number): __Observable<__StrictHttpResponse<AppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/appointments/${id}/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentSummary>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Summary(id: number): __Observable<AppointmentSummary> {
    return this.SummaryResponse(id).pipe(
      __map(_r => _r.body as AppointmentSummary)
    );
  }

  /**
   * @param entryId undefined
   * @return Success
   */
  GetEntryIdResponse(entryId: string): __Observable<__StrictHttpResponse<AppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/appointments/entries/${entryId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentSummary>;
      })
    );
  }
  /**
   * @param entryId undefined
   * @return Success
   */
  GetEntryId(entryId: string): __Observable<AppointmentSummary> {
    return this.GetEntryIdResponse(entryId).pipe(
      __map(_r => _r.body as AppointmentSummary)
    );
  }

  /**
   * @param entryId undefined
   * @return Success
   */
  GetEntrySummaryAsyncResponse(entryId: string): __Observable<__StrictHttpResponse<AppointmentSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/appointments/entries/${entryId}/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentSummary>;
      })
    );
  }
  /**
   * @param entryId undefined
   * @return Success
   */
  GetEntrySummaryAsync(entryId: string): __Observable<AppointmentSummary> {
    return this.GetEntrySummaryAsyncResponse(entryId).pipe(
      __map(_r => _r.body as AppointmentSummary)
    );
  }

  /**
   * @param params The `SchedulesService.FindSlotAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `date`:
   *
   * @return Success
   */
  FindSlotAsyncResponse(params: SchedulesService.FindSlotAsyncParams): __Observable<__StrictHttpResponse<Slot>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.date != null) __params = __params.set('date', params.date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Schedules/day/slots/${params.scheduleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Slot>;
      })
    );
  }
  /**
   * @param params The `SchedulesService.FindSlotAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `date`:
   *
   * @return Success
   */
  FindSlotAsync(params: SchedulesService.FindSlotAsyncParams): __Observable<Slot> {
    return this.FindSlotAsyncResponse(params).pipe(
      __map(_r => _r.body as Slot)
    );
  }
}

module SchedulesService {

  /**
   * Parameters for ResourceSlots
   */
  export interface ResourceSlotsParams {
    SpecsIds?: Array<number>;
    ServicesIds?: Array<number>;
    OrganizationIds?: Array<number>;
    DoctorIds?: Array<number>;
    Day?: string;
  }

  /**
   * Parameters for Period
   */
  export interface PeriodParams {
    To?: string;
    SpecsIds?: Array<number>;
    ServicesIds?: Array<number>;
    OrganizationIds?: Array<number>;
    From?: string;
    DoctorIds?: Array<number>;
    BookingAllowedFor?: number;
  }

  /**
   * Parameters for SlotHistory
   */
  export interface SlotHistoryParams {
    scheduleId: number;
    Date?: string;
  }

  /**
   * Parameters for ReserveSlot
   */
  export interface ReserveSlotParams {
    scheduleId: number;
    request?: ReserveSlotRequest;
  }

  /**
   * Parameters for ClaimSlot
   */
  export interface ClaimSlotParams {
    scheduleId: number;
    request?: ClaimSlotRequest;
  }

  /**
   * Parameters for ChangeSlot
   */
  export interface ChangeSlotParams {
    scheduleId: number;
    id: number;
    request?: UpdateSlotRequest;
  }

  /**
   * Parameters for ReleaseSlot
   */
  export interface ReleaseSlotParams {
    scheduleId: number;
    id: number;
  }

  /**
   * Parameters for ConfirmSlot
   */
  export interface ConfirmSlotParams {
    scheduleId: number;
    id: number;
    request?: ConfirmSlotRequest;
  }

  /**
   * Parameters for DenySlot
   */
  export interface DenySlotParams {
    scheduleId: number;
    id: number;
  }

  /**
   * Parameters for RejectSlot
   */
  export interface RejectSlotParams {
    scheduleId: number;
    id: number;
  }

  /**
   * Parameters for BreakSlot
   */
  export interface BreakSlotParams {
    scheduleId: number;
    id: number;
  }

  /**
   * Parameters for AddBreakAsync
   */
  export interface AddBreakAsyncParams {
    id: number;
    request?: AddBreakRequest;
  }

  /**
   * Parameters for NotifyAsync
   */
  export interface NotifyAsyncParams {
    scheduleId: number;
    id: number;
  }

  /**
   * Parameters for WeeklySchedule
   */
  export interface WeeklyScheduleParams {
    StartDate?: string;
    SpecsIds?: Array<number>;
    ServicesIds?: Array<number>;
    OrganizationIds?: Array<number>;
    EndDate?: string;
    DoctorIds?: Array<number>;
  }

  /**
   * Parameters for ScheduleServices
   */
  export interface ScheduleServicesParams {
    scheduleId: number;
    PatientAge?: number;
  }

  /**
   * Parameters for GenerateCalendar
   */
  export interface GenerateCalendarParams {
    System?: any;
    ApiKey?: string;
  }

  /**
   * Parameters for FindSlotAsync
   */
  export interface FindSlotAsyncParams {
    scheduleId: number;
    date?: string;
  }
}

export { SchedulesService }
