import { Injectable } from "@angular/core";
import { Manipulation, Service, Speciality } from "../../../generated/models";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin, from } from "rxjs";
import { ManipulationsService, ServicesService, SpecialitiesService } from "../../../generated/services";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ManipulationResolver implements Resolve<ManipulationResolverPayload> {
  constructor(private specialitiesService: SpecialitiesService,
    private manipulationsService: ManipulationsService,
    private servicesService: ServicesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ManipulationResolverPayload> {
    const id = parseInt(route.paramMap.get("id"), 10);

    let manipulationLoader: Observable<Manipulation> = from([{}]);

    if (id > 0) {
      manipulationLoader = this.manipulationsService.ManipulationAsync(id);
    }

    const specialitiesLoader = this.specialitiesService.Specialities({ Page: 1, Size: 0 }).pipe(map(x => x.items));
    const servicesLoader = this.servicesService.Services({ Page: 1, Size: 0 }).pipe(map(x => x.services));

    return forkJoin({
      manipulation: manipulationLoader,
      specialities: specialitiesLoader,
      services: servicesLoader
    });
  }
}

export interface ManipulationResolverPayload {
  manipulation: Manipulation;
  specialities: Speciality[];
  services: Service[];
}
