import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { OrganizationComponent } from './components/organization/organization.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { OrganizationResolver } from './resolvers/organization.resolver';
import { ManageOrganizationModalComponent } from './components/manage-organization.modal/manage-organization.modal.component';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';
import { CodeSystemModalComponent } from './components/code-system-modal/code-system-modal.component';
import { CodeSystemItemComponent } from './components/code-system-item/code-system-item.component';
import { ForbiddenDiagnosesComponent } from './components/forbidden-diagnoses/forbidden-diagnoses.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { OrganizationItemComponent } from './components/organization-item/organization-item.component';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: OrganizationResolver
    },
    component: OrganizationComponent,
    data: {
      title: "Новая организация",
      requirements: [PermissionNames.ManageCompanies]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: OrganizationResolver
    },
    component: OrganizationComponent,
    data: {
      title: "Организация",
      requirements: [PermissionNames.ManageCompanies]
    }
  },
  {
    path: "",
    component: OrganizationsComponent,
    data: {
      title: "Организации",
      requirements: [PermissionNames.ManageCompanies]
    }
  }
];

@NgModule({
  declarations: [
    OrganizationComponent,
    OrganizationsComponent,
    ManageOrganizationModalComponent,
    CodeSystemModalComponent,
    CodeSystemItemComponent,
    ForbiddenDiagnosesComponent,
    FileInputComponent,
    OrganizationItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManageOrganizationModalComponent,
    CodeSystemModalComponent
  ]
})
export class OrganizationSettingsModule { }
