/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RateTypeOption } from '../models/rate-type-option';
import { RateDiscountBehaviorOption } from '../models/rate-discount-behavior-option';
import { PartnerPlansResponse } from '../models/partner-plans-response';
import { CreatePartnerPlanResponse } from '../models/create-partner-plan-response';
import { EditPartnerPlanModel } from '../models/edit-partner-plan-model';
import { PartnerPlanService } from '../models/partner-plan-service';
import { PlanServiceEditorModel } from '../models/plan-service-editor-model';
import { PartnerPlanServicesResponse } from '../models/partner-plan-services-response';
import { ManagePartnerPlanModel } from '../models/manage-partner-plan-model';
@Injectable({
  providedIn: 'root',
})
class PartnerPlansService extends __BaseService {
  static readonly TypesPath = '/api/PartnerPlans/types';
  static readonly BehavioursPath = '/api/PartnerPlans/discountbehaviours';
  static readonly RatesPath = '/api/PartnerPlans';
  static readonly CreatePath = '/api/PartnerPlans';
  static readonly GetPath = '/api/PartnerPlans/{id}';
  static readonly EditPath = '/api/PartnerPlans/{id}';
  static readonly RemovePath = '/api/PartnerPlans/{id}';
  static readonly EditServicePath = '/api/PartnerPlans/{id}/services/{serviceId}';
  static readonly ServicesPath = '/api/PartnerPlans/{id}/services';
  static readonly ManagePath = '/api/PartnerPlans/{id}/manage';
  static readonly ArchivePath = '/api/PartnerPlans/{id}/archive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  TypesResponse(): __Observable<__StrictHttpResponse<Array<RateTypeOption>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans/types`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RateTypeOption>>;
      })
    );
  }
  /**
   * @return Success
   */
  Types(): __Observable<Array<RateTypeOption>> {
    return this.TypesResponse().pipe(
      __map(_r => _r.body as Array<RateTypeOption>)
    );
  }

  /**
   * @return Success
   */
  BehavioursResponse(): __Observable<__StrictHttpResponse<Array<RateDiscountBehaviorOption>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans/discountbehaviours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RateDiscountBehaviorOption>>;
      })
    );
  }
  /**
   * @return Success
   */
  Behaviours(): __Observable<Array<RateDiscountBehaviorOption>> {
    return this.BehavioursResponse().pipe(
      __map(_r => _r.body as Array<RateDiscountBehaviorOption>)
    );
  }

  /**
   * @param params The `PartnerPlansService.RatesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  RatesResponse(params: PartnerPlansService.RatesParams): __Observable<__StrictHttpResponse<PartnerPlansResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerPlansResponse>;
      })
    );
  }
  /**
   * @param params The `PartnerPlansService.RatesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  Rates(params: PartnerPlansService.RatesParams): __Observable<PartnerPlansResponse> {
    return this.RatesResponse(params).pipe(
      __map(_r => _r.body as PartnerPlansResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: EditPartnerPlanModel): __Observable<__StrictHttpResponse<CreatePartnerPlanResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PartnerPlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePartnerPlanResponse>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: EditPartnerPlanModel): __Observable<CreatePartnerPlanResponse> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as CreatePartnerPlanResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<EditPartnerPlanModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditPartnerPlanModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<EditPartnerPlanModel> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as EditPartnerPlanModel)
    );
  }

  /**
   * @param params The `PartnerPlansService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  EditResponse(params: PartnerPlansService.EditParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PartnerPlans/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PartnerPlansService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  Edit(params: PartnerPlansService.EditParams): __Observable<null> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PartnerPlans/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Remove(id: number): __Observable<null> {
    return this.RemoveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PartnerPlansService.EditServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditServiceResponse(params: PartnerPlansService.EditServiceParams): __Observable<__StrictHttpResponse<PartnerPlanService>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PartnerPlans/${params.id}/services/${params.serviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerPlanService>;
      })
    );
  }
  /**
   * @param params The `PartnerPlansService.EditServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditService(params: PartnerPlansService.EditServiceParams): __Observable<PartnerPlanService> {
    return this.EditServiceResponse(params).pipe(
      __map(_r => _r.body as PartnerPlanService)
    );
  }

  /**
   * @param params The `PartnerPlansService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  ServicesResponse(params: PartnerPlansService.ServicesParams): __Observable<__StrictHttpResponse<PartnerPlanServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerPlanServicesResponse>;
      })
    );
  }
  /**
   * @param params The `PartnerPlansService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  Services(params: PartnerPlansService.ServicesParams): __Observable<PartnerPlanServicesResponse> {
    return this.ServicesResponse(params).pipe(
      __map(_r => _r.body as PartnerPlanServicesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManagePartnerPlanModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PartnerPlans/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManagePartnerPlanModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManagePartnerPlanModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManagePartnerPlanModel)
    );
  }

  /**
   * @param id undefined
   */
  ArchiveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PartnerPlans/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Archive(id: number): __Observable<null> {
    return this.ArchiveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PartnerPlansService {

  /**
   * Parameters for Rates
   */
  export interface RatesParams {
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    id: number;
    model?: EditPartnerPlanModel;
  }

  /**
   * Parameters for EditService
   */
  export interface EditServiceParams {
    serviceId: number;
    id: number;
    model?: PlanServiceEditorModel;
  }

  /**
   * Parameters for Services
   */
  export interface ServicesParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
    CategoryId?: number;
  }
}

export { PartnerPlansService }
