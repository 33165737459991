import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router, ParamMap } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RemoveScheduleModalComponent } from '../remove-schedule.modal/remove-schedule.modal.component';
import { ScheduleSettingsService } from '../../../../generated/services/schedule-settings.service';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, tap, switchMap, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Company, Resource, ResourcesInfoResponse } from '../../../../generated/models';



@Component({
  selector: 'mp-schedules-list',
  templateUrl: './schedule-items.component.html',
  styleUrls: ['./schedule-items.component.scss'],
  host: { class: "page" }
})
export class ScheduleItemsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  resources: Resource[] = [];
  medicalCenters: Company[] = [];

  loading = false;

  page = 1;
  size = 50;
  selected: { [id: number]: boolean } = {};

  scrollDistance = 1;

  searchForm = new FormGroup({
    search: new FormControl(""),
    medicalCenter: new FormControl(""),
    archived: new FormControl("")
  });;

  constructor(
    private scheduleSettingsService: ScheduleSettingsService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private modal: NgbModal
  ) {
    this.searchForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((): void => {
          this.page = 1;
          this.resources = [];
          this.loading = true;
        }),
        tap((value: SearchValue): void => {
          const queryParams: Params = {};
          if (value.archived) {
            queryParams["includeArchived"] = true;
          }

          if (value.medicalCenter > 0) {
            queryParams["company"] = value.medicalCenter;
          }

          if (value.search) {
            queryParams["search"] = value.search;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams });
        }),
        switchMap((value: SearchValue): Observable<ResourcesInfoResponse> => {
          return this.scheduleSettingsService.Resources({
            Page: this.page,
            Size: this.size,
            IncludedArchived: value.archived,
            OrganizationId: value.medicalCenter,
            Search: value.search
          })
            .pipe(
              catchError((): Observable<ResourcesInfoResponse> => {
                this.toastrService.error("Не удалось загрузить расписания", "Ошибка")

                return of({ resources: [], total: 0 });
              })
            )
        })
      )
      .subscribe(
        (response: ResourcesInfoResponse): void => {
          this.resources = response.resources;

          this.loading = false;
        },
        (): void => {
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const value: SearchValue = {
      archived: queryMap.has("includeArchived") && queryMap.get("includeArchived") === "true",
      search: queryMap.get("search"),
      medicalCenter: queryMap.has("company") ? parseInt(queryMap.get("company")) : undefined
    };

    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: { payload: Company[] }): void => {
          this.medicalCenters = data.payload;
        }
      );

    this.searchForm.patchValue(value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScrollDown() {
    this.page++;

    const value: SearchValue = this.searchForm.getRawValue();

    this.scheduleSettingsService.Resources({
      Page: this.page,
      Size: this.size,
      IncludedArchived: value.archived,
      OrganizationId: value.medicalCenter,
      Search: value.search
    })
      .subscribe(
        (result: ResourcesInfoResponse): void => {
          //this._resources = [];
          this.resources.push(...result.resources);
          this.loading = false;
        },
        (): void => {
          this.loading = false;
        });

  }

  async load() {

    this.resources = [];
    this.page = 1;
    this.loading;

    const value: SearchValue = this.searchForm.getRawValue();

    try {
      const response = await this.scheduleSettingsService.Resources({
        Page: this.page,
        Size: this.size,
        IncludedArchived: value.archived,
        OrganizationId: value.medicalCenter,
        Search: value.search
      }).toPromise();

      this.resources = response.resources;
    } catch (e) {
      this.toastrService.error('Не удалось загрузить расписания', 'Ошибка');
    }

    this.loading = false;
  }

  create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  edit(resource: Resource): void {
    this.router.navigate([resource.id], { relativeTo: this.activatedRoute });
  }

  remove(resource: Resource): void {
    const options: NgbModalOptions = { backdrop: "static", size: "sm", centered: true };

    const modalRef: NgbModalRef = this.modal.open(RemoveScheduleModalComponent, options);
    const componentRef: RemoveScheduleModalComponent = modalRef.componentInstance;

    componentRef.resource = resource;
    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe(async () => {
      try {
        await this.scheduleSettingsService.DeleteResource(resource.id).toPromise();

        this.toastrService.success(`Расписание ${resource.resourceName} удалено`, 'Успешно');
        this.load();

        modalRef.close();

      } catch (e) {
        this.toastrService.error('Не удалось удалить расписание', 'Ошибка');
        return;
      }

    });
  }
}

interface SearchValue {
  search: string;
  medicalCenter: number;
  archived: boolean;
}
