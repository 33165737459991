import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdbBaseService {

  public dbName: string = "CLINIC_IDB";

  private _db: IDBDatabase;
  private _dbVersion: number = 3;

  private _dbLoaded: ReplaySubject<boolean> = new ReplaySubject();

  public get dbLoaded(): ReplaySubject<boolean> { return this._dbLoaded; }

  constructor() {
    this.initDb()
  }

  private initDb = (): void => {
    let req: IDBOpenDBRequest = window.indexedDB.open(this.dbName, this._dbVersion);
    req.onerror = (e: any) => { console.log("Error initing DB", e.target.errorCode) }
    req.onsuccess = (e: any) => {

      console.log("db created")
      this._db = e.target.result
      this._dbLoaded.next(true)

    }

    req.onupgradeneeded = (e: any) => {
      console.log("updating db", e)

      this._db = e.target.result;

      switch (e.oldVersion) {
        case 0:
          var objectStoreIcd10 = this._db.createObjectStore("icd10", { keyPath: "code" });
          objectStoreIcd10.createIndex("code", "code");

          var objectStoreStandards = this._db.createObjectStore("standardServices", { keyPath: "id" });
          objectStoreStandards.createIndex("id", "id");
        case 2:
          var objectStoreDrafts = this._db.createObjectStore("recordDrafts", { keyPath: "id" });
          objectStoreDrafts.createIndex("id", "id");
      }

      
    }
  }

  //public addObjectStore(objectStoreName: string, index: string): void {

  //  this.dbLoaded.subscribe((res) => {

  //    var request = indexedDB.open(this.dbName);
  //    console.log(request);

  //    request.onsuccess = (e: any) => {

  //      let database = e.target.result;
  //      let version: number = database.version;
  //      database.close();
  //      var secondRequest = indexedDB.open(this.dbName, version + 1);

  //      console.log(secondRequest)

  //      secondRequest.onupgradeneeded = (e: any) => {
  //        var database2 = e.target.result;

  //        var objectStore = database2.createObjectStore(objectStoreName, { keyPath: index });

  //        objectStore.createIndex(index, index);
  //        console.log("creating object store")

  //        objectStore.transaction.oncomplete = (e) => { console.log("Created object store", objectStoreName); }
  //      };
  //      secondRequest.onsuccess = (e: any) => {
  //        e.target.result.close();
  //      }
  //    }

  //  })

  //}

  public getObjectStore = (objectStoreName: string): IDBObjectStore => {
    try {
      let transaction = this.db.transaction([objectStoreName], "readwrite");
      return transaction.objectStore(objectStoreName);
    }
    catch (e) {
      console.log(`Error: Failed to get object store. ${e}, name: ${objectStoreName}`);
      return null;
    }
  }

  public objectStoreEmpty = (objectStoreName: string): Observable<boolean> => {
    const subject = new Subject<boolean>();

    try {
      let objectStore: IDBObjectStore = this.getObjectStore(objectStoreName);
      objectStore.count().onsuccess = (evt: any) => {
        var count = evt.target.result;
        if (count > 0) {
          subject.next(false)
        }
        else {
          subject.next(true)
        }
      };
    }
    catch (e) {
      console.log(`Error: Failed to get update. ${e}, name: ${objectStoreName}`);
      subject.next(true);
    }

    return subject.asObservable();
  }

  public get db(): IDBDatabase { return this._db; }

}
