/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { OrganizationsResponse } from '../models/organizations-response';
import { OrganizationResponse } from '../models/organization-response';
import { ManageOrganizationModel } from '../models/manage-organization-model';
import { CompanyForbiddenDiagnosis } from '../models/company-forbidden-diagnosis';
import { AddForbiddenDiagnosisResponse } from '../models/add-forbidden-diagnosis-response';
import { AddForbiddenDiagnosisRequest } from '../models/add-forbidden-diagnosis-request';
import { UpdateForbiddenDiagnosesRequest } from '../models/update-forbidden-diagnoses-request';
@Injectable({
  providedIn: 'root',
})
class OrganizationsService extends __BaseService {
  static readonly OrganizationsPath = '/api/Organizations';
  static readonly CreatePath = '/api/Organizations';
  static readonly GetPath = '/api/Organizations/{id}';
  static readonly EditPath = '/api/Organizations/{organizationId}';
  static readonly RemovePath = '/api/Organizations/{organizationId}';
  static readonly ManagePath = '/api/Organizations/{id}/manage';
  static readonly ForbiddenDiagnosesAsyncPath = '/api/Organizations/{companyId}/forbidden-diagnoses';
  static readonly AddForbiddenDiagnosisAsyncPath = '/api/Organizations/{companyId}/forbidden-diagnoses';
  static readonly UpdateForbiddenDiagnosesAsyncPath = '/api/Organizations/{companyId}/forbidden-diagnoses';
  static readonly ForbiddenDiagnosisAsyncPath = '/api/Organizations/{companyId}/firbidden-diagnoses/{id}';
  static readonly RemoveForbiddenDiagnosisAsyncPath = '/api/Organizations/{companyId}/forbidden-diagnoses/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `OrganizationsService.OrganizationsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  OrganizationsResponse(params: OrganizationsService.OrganizationsParams): __Observable<__StrictHttpResponse<OrganizationsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationsResponse>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.OrganizationsParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  Organizations(params: OrganizationsService.OrganizationsParams): __Observable<OrganizationsResponse> {
    return this.OrganizationsResponse(params).pipe(
      __map(_r => _r.body as OrganizationsResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: OrganizationResponse): __Observable<__StrictHttpResponse<OrganizationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Organizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationResponse>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: OrganizationResponse): __Observable<OrganizationResponse> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as OrganizationResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<OrganizationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<OrganizationResponse> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as OrganizationResponse)
    );
  }

  /**
   * @param params The `OrganizationsService.EditParams` containing the following parameters:
   *
   * - `organizationId`:
   *
   * - `request`:
   *
   * @return Success
   */
  EditResponse(params: OrganizationsService.EditParams): __Observable<__StrictHttpResponse<OrganizationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Organizations/${params.organizationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrganizationResponse>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.EditParams` containing the following parameters:
   *
   * - `organizationId`:
   *
   * - `request`:
   *
   * @return Success
   */
  Edit(params: OrganizationsService.EditParams): __Observable<OrganizationResponse> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as OrganizationResponse)
    );
  }

  /**
   * @param organizationId undefined
   */
  RemoveResponse(organizationId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Organizations/${organizationId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param organizationId undefined
   */
  Remove(organizationId: number): __Observable<null> {
    return this.RemoveResponse(organizationId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManageOrganizationModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManageOrganizationModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManageOrganizationModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManageOrganizationModel)
    );
  }

  /**
   * @param companyId undefined
   * @return Success
   */
  ForbiddenDiagnosesAsyncResponse(companyId: number): __Observable<__StrictHttpResponse<Array<CompanyForbiddenDiagnosis>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/${companyId}/forbidden-diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyForbiddenDiagnosis>>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return Success
   */
  ForbiddenDiagnosesAsync(companyId: number): __Observable<Array<CompanyForbiddenDiagnosis>> {
    return this.ForbiddenDiagnosesAsyncResponse(companyId).pipe(
      __map(_r => _r.body as Array<CompanyForbiddenDiagnosis>)
    );
  }

  /**
   * @param params The `OrganizationsService.AddForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddForbiddenDiagnosisAsyncResponse(params: OrganizationsService.AddForbiddenDiagnosisAsyncParams): __Observable<__StrictHttpResponse<AddForbiddenDiagnosisResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Organizations/${params.companyId}/forbidden-diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddForbiddenDiagnosisResponse>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.AddForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddForbiddenDiagnosisAsync(params: OrganizationsService.AddForbiddenDiagnosisAsyncParams): __Observable<AddForbiddenDiagnosisResponse> {
    return this.AddForbiddenDiagnosisAsyncResponse(params).pipe(
      __map(_r => _r.body as AddForbiddenDiagnosisResponse)
    );
  }

  /**
   * @param params The `OrganizationsService.UpdateForbiddenDiagnosesAsyncParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `request`:
   */
  UpdateForbiddenDiagnosesAsyncResponse(params: OrganizationsService.UpdateForbiddenDiagnosesAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Organizations/${params.companyId}/forbidden-diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.UpdateForbiddenDiagnosesAsyncParams` containing the following parameters:
   *
   * - `companyId`:
   *
   * - `request`:
   */
  UpdateForbiddenDiagnosesAsync(params: OrganizationsService.UpdateForbiddenDiagnosesAsyncParams): __Observable<null> {
    return this.UpdateForbiddenDiagnosesAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `OrganizationsService.ForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ForbiddenDiagnosisAsyncResponse(params: OrganizationsService.ForbiddenDiagnosisAsyncParams): __Observable<__StrictHttpResponse<CompanyForbiddenDiagnosis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Organizations/${params.companyId}/firbidden-diagnoses/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CompanyForbiddenDiagnosis>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.ForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `companyId`:
   *
   * @return Success
   */
  ForbiddenDiagnosisAsync(params: OrganizationsService.ForbiddenDiagnosisAsyncParams): __Observable<CompanyForbiddenDiagnosis> {
    return this.ForbiddenDiagnosisAsyncResponse(params).pipe(
      __map(_r => _r.body as CompanyForbiddenDiagnosis)
    );
  }

  /**
   * @param params The `OrganizationsService.RemoveForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `companyId`:
   */
  RemoveForbiddenDiagnosisAsyncResponse(params: OrganizationsService.RemoveForbiddenDiagnosisAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Organizations/${params.companyId}/forbidden-diagnoses/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `OrganizationsService.RemoveForbiddenDiagnosisAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `companyId`:
   */
  RemoveForbiddenDiagnosisAsync(params: OrganizationsService.RemoveForbiddenDiagnosisAsyncParams): __Observable<null> {
    return this.RemoveForbiddenDiagnosisAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module OrganizationsService {

  /**
   * Parameters for Organizations
   */
  export interface OrganizationsParams {
    Type?: any;
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    organizationId: number;
    request?: OrganizationResponse;
  }

  /**
   * Parameters for AddForbiddenDiagnosisAsync
   */
  export interface AddForbiddenDiagnosisAsyncParams {
    companyId: number;
    request?: AddForbiddenDiagnosisRequest;
  }

  /**
   * Parameters for UpdateForbiddenDiagnosesAsync
   */
  export interface UpdateForbiddenDiagnosesAsyncParams {
    companyId: number;
    request?: UpdateForbiddenDiagnosesRequest;
  }

  /**
   * Parameters for ForbiddenDiagnosisAsync
   */
  export interface ForbiddenDiagnosisAsyncParams {
    id: number;
    companyId: number;
  }

  /**
   * Parameters for RemoveForbiddenDiagnosisAsync
   */
  export interface RemoveForbiddenDiagnosisAsyncParams {
    id: number;
    companyId: number;
  }
}

export { OrganizationsService }
