import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ServiceCategoriesService } from '../../../generated/services';
import { Observable } from 'rxjs';
import { ServiceCategory, ServiceCategoriesResponse } from '../../../generated/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceItemsResolver implements Resolve<ServiceCategory[]> {

  constructor(private service: ServiceCategoriesService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceCategory[]> {
    return this.service.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((result: ServiceCategoriesResponse): ServiceCategory[] => result.categories)
      );
  }
}
