import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mp-spendings-badge',
  templateUrl: './spendings-badge.component.html',
  styleUrls: ['./spendings-badge.component.scss']
})
export class SpendingsBadgeComponent {
  @Input() amount: number;
  get show(): boolean { return this.amount > 0; }
}
