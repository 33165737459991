import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CodeSystemsService, OrganizationsService, ValueSetsService } from 'projects/Clinic/src/app/generated/services';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from "moment";
import { OrganizationResolverPayload } from '../../resolvers/organization.resolver';
import { OrganizationResponse, FilterItem, Logo, CodeSystemValue, CodeSystem } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CodeSystemModalComponent, CodeSystemModalPaylod } from '../code-system-modal/code-system-modal.component';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
  selector: 'mp-organization-editor',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  host: { class: "page" }
})
export class OrganizationComponent implements OnInit {
  destroy$ = new Subject<void>();
  id: number;

  title: string;

  get isNew(): boolean { return !this.id; }

  codeSystems: CodeSystem[] = [];
  codeSystemValues: CodeSystemValue[] = [];

  exchangeProtocols: FilterItem[] = [
    { id: 1, name: "КДЛ" },
    { id: 2, name: "Скайтекс" },
    { id: 3, name: "Скайтекс v2" },
    { id: 4, name: "КДЛ v2" },
    { id: 5, name: "Экспресс" }
  ];

  companyTypes: FilterItem[] = [
    { id: 3, name: "Страховая компания ОМС" },
    { id: 5, name: "Страховая компания ДМС" },
    { id: 4, name: "Собственная организация" },
    { id: 1, name: "Лечебное учреждение (направляет пациентов)" },
    { id: 2, name: "Лаборатория (выполняет исследования)" },
    { id: 6, name: "Организация-заказчик" }
  ];

  organizationForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    type: new FormControl("", [Validators.required]),

    fullName: new FormControl(""),
    shortName: new FormControl(""),
    abbr: new FormControl("", [Validators.maxLength(3)]),

    bank: new FormControl(""),
    bik: new FormControl(""),
    account: new FormControl(""),
    correspondentAccount: new FormControl(""),

    billingAddress: new FormControl(""),
    shippingAddress: new FormControl(""),
    phone: new FormControl(""),
    site: new FormControl(""),
    email: new FormControl(""),

    effective: new FormControl(undefined),
    expires: new FormControl(undefined),

    inn: new FormControl(null),
    kpp: new FormControl(""),
    ogrn: new FormControl(""),
    issuer: new FormControl(""),
    issued: new FormControl(""),

    license: new FormControl(""),
    licenseIssuer: new FormControl(""),
    licenseIssued: new FormControl(""),
    licenseReRegistrationNumber: new FormControl(""),
    licenseReRegistrationDate: new FormControl(""),

    representedBy: new FormControl(""),
    actsOn: new FormControl(""),
    signature: new FormControl(""),

    logo: new FormControl(""),
    publicLogo: new FormControl(""),
    medicalDocumentsLogo: new FormControl(""),
    sign: new FormControl(""),
    stamp: new FormControl(""),

    mailbox: new FormControl(""),
    mailboxPass: new FormControl(""),

    server: new FormControl(""),
    port: new FormControl(null),
    exchangeProtocol: new FormControl(null),
    companyCode: new FormControl(""),

    color: new FormControl(""),
  });


  constructor(
    private organizationsService: OrganizationsService,
    private codeSystemsService: CodeSystemsService,
    private valueSetsService: ValueSetsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private modal: NgbModal
  ) { }

  async ngOnInit() {
    this.activatedRoute.data.subscribe(async (data: { payload: OrganizationResolverPayload }): Promise<void> => {

      this.id = data.payload.organization.id;
      this.title = data.payload.organization.id > 0 ? `Организация: ${data.payload.organization.name || ""}` : "Новая организация";

      this.organizationForm.patchValue({
        ...data.payload.organization,
        effective: moment(data.payload.organization.effective, 'DD.MM.YYYY'),
        expires: moment(data.payload.organization.expires, 'DD.MM.YYYY'),
      });

      this.organizationForm.controls["issued"].setValue(moment(data.payload.organization.issued, "DD.MM.YYYY"));
      this.organizationForm.controls["licenseIssued"].setValue(moment(data.payload.organization.licenseIssued, "DD.MM.YYYY"));
      this.organizationForm.controls["licenseReRegistrationDate"].setValue(moment(data.payload.organization.licenseReRegistrationDate, "DD.MM.YYYY"));
      this.organizationForm.controls["logo"].setValue(data.payload.organization.logo);
      this.organizationForm.controls["publicLogo"].setValue(data.payload.organization.publicLogo);
      this.organizationForm.controls["medicalDocumentsLogo"].setValue(data.payload.organization.medicalDocumentsLogo);
      this.organizationForm.controls["sign"].setValue(data.payload.organization.sign);
      this.organizationForm.controls["stamp"].setValue(data.payload.organization.stamp);

      if (this.id > 0) {
        this.codeSystemValues = await this.loadCodeSystemValues();
        this.codeSystems = await this.codeSystemsService.CodeSystems({ Page: 1, Size: 0 }).toPromise();
      }
    });

  }

  async loadCodeSystemValues(): Promise<CodeSystemValue[]> {
    return this.valueSetsService.Values({ id: this.id, type: "company" }).toPromise();
  }

  acceptChanges(value: any): void {
    Object.entries(this.organizationForm.controls)
      .forEach((x: [string, AbstractControl]): void => { x[1].markAsTouched(); })

    if (this.organizationForm.invalid) {
      return;
    }

    if (this.id) {
      this.organizationsService.Edit({
        organizationId: this.id,
        request: {
          name: value.name,
          bank: value.bank,
          fullName: value.fullName,
          shortName: value.shortName,
          site: value.site,
          email: value.email,
          inn: value.inn,
          kpp: value.kpp,
          bik: value.bik,
          ogrn: value.ogrn,
          issuer: value.issuer,
          issued: value.issued.format("DD.MM.YYYY"),
          representedBy: value.representedBy,
          actsOn: value.actsOn,
          signature: value.signature,
          account: value.account,
          correspondentAccount: value.correspondentAccount,
          billingAddress: value.billingAddress,
          shippingAddress: value.shippingAddress,
          phone: value.phone,
          license: value.license,
          licenseIssuer: value.licenseIssuer,
          licenseIssued: value.licenseIssued.format("DD.MM.YYYY"),
          licenseReRegistrationNumber: value.licenseReRegistrationNumber,
          licenseReRegistrationDate: value.licenseReRegistrationDate.format("DD.MM.YYYY"),
          logo: value.logo,
          publicLogo: value.publicLogo,
          medicalDocumentsLogo: value.medicalDocumentsLogo,
          sign: value.sign,
          stamp: value.stamp,
          mailbox: value.mailbox,
          mailboxPass: value.mailboxPass,
          server: value.server,
          port: value.port,
          exchangeProtocol: value.exchangeProtocol,
          type: value.type,
          companyCode: value.companyCode,
          color: value.color,
          abbr: value.abbr,

          effective: value.effective && value.effective.isValid() ? value.effective.format('DD.MM.YYYY') : '',
          expires: value.expires && value.expires.isValid() ? value.expires.format('DD.MM.YYYY') : '',
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("Организация сохранена", "Успешно");
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось сохранить организацию", "Ошибка");
          }
        );
    } else {

      this.organizationsService.Create({
        name: value.name,
        bank: value.bank,
        fullName: value.fullName,
        shortName: value.shortName,
        site: value.site,
        email: value.email,
        inn: value.inn,
        kpp: value.kpp,
        bik: value.bik,
        ogrn: value.ogrn,
        issuer: value.issuer,
        issued: value.issued.format("DD.MM.YYYY"),
        representedBy: value.representedBy,
        actsOn: value.actsOn,
        signature: value.signature,
        account: value.account,
        correspondentAccount: value.correspondentAccount,
        billingAddress: value.billingAddress,
        shippingAddress: value.shippingAddress,
        phone: value.phone,
        license: value.license,
        licenseIssuer: value.licenseIssuer,
        licenseIssued: value.licenseIssued.format("DD.MM.YYYY"),
        licenseReRegistrationNumber: value.licenseReRegistrationNumber,
        licenseReRegistrationDate: value.licenseReRegistrationDate.format("DD.MM.YYYY"),
        logo: value.logo,
        publicLogo: value.publicLogo,
        medicalDocumentsLogo: value.medicalDocumentsLogo,
        sign: value.sign,
        stamp: value.stamp,
        mailbox: value.mailbox,
        mailboxPass: value.mailboxPass,
        server: value.server,
        port: value.port,
        type: value.type,
        exchangeProtocol: value.exchangeProtocol,
        companyCode: value.companyCode,
        color: value.color,
        abbr: value.abbr,

        effective: value.effective && value.effective.isValid() ? value.effective.format('DD.MM.YYYY') : '',
        expires: value.expires && value.expires.isValid() ? value.expires.format('DD.MM.YYYY') : '',
      })
        .subscribe((created: OrganizationResponse): void => {
          this.toastrService.success("Организация добавлена", "Успешно");
          this.router.navigate(["..", created.id], { relativeTo: this.activatedRoute });
        });
    }
  }

  addCodeSystem(): void {
    const options: NgbModalOptions = { backdrop: "static" };
    const modalRef: NgbModalRef = this.modal.open(CodeSystemModalComponent, options);
    const componentRef: CodeSystemModalComponent = modalRef.componentInstance;

    componentRef.codeSystems = this.codeSystems;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((payload: CodeSystemModalPaylod): void => {
      this.valueSetsService.Create({
        type: "company", request: {
          codeSystemId: payload.codeSystem,
          value: payload.value,
          targetId: this.id
        }
      }).subscribe(
        async (): Promise<void> => {
          this.toastrService.success("Значение добавлено", "Успешно");
          this.codeSystemValues = await this.valueSetsService.Values({ id: this.id, type: "company" }).toPromise();
          modalRef.close();
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
          } else {
            this.toastrService.warning("Не удалось добавить значение", "Ошибка");
          }
        }
      );

    });
  }

  removeValueSet(valueSet: CodeSystemValue) {
    const options: NgbModalOptions = { backdrop: 'static' };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Значение системы ${valueSet.system} ${valueSet.systemName} будет удалено. продолжить?`;
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result.then(
      (): void => {
        this.valueSetsService.Delete(valueSet.id)
          .subscribe(
            async (): Promise<void> => {
              this.toastrService.success("Значение удалено", "Успешно");
              this.codeSystemValues = await this.loadCodeSystemValues();
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
              } else {
                this.toastrService.warning("Не удалось добавить значение", "Ошибка");
              }
            }
          );

      },
      (): void => { }
    );
  }

}
