import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecommendedManipulation } from '../../../../generated/models';

@Component({
  selector: 'mp-manipulation-modal',
  templateUrl: './manipulation-modal.component.html',
  styleUrls: ['./manipulation-modal.component.scss']
})
export class ManipulationModalComponent implements OnInit {

  @Input() manipulation: RecommendedManipulation = {};
  @Input() processing = false;

  @Output() onConfirm = new EventEmitter<RecommendedManipulation>();
  @Output() onCancel = new EventEmitter<void>();

  get canConfime() { return !this.processing && this.form.valid; }

  form = new FormGroup({
    clinicalRecommendationDiagnosisCode: new FormControl(''),
    clinicalRecommendationName: new FormControl(''),

    manipulationName: new FormControl(''),

    type: new FormControl(undefined, [Validators.required]),
    mandatory: new FormControl(false),

    minAge: new FormControl(),
    maxAge: new FormControl(),
    gender: new FormControl(null),

    n804Id: new FormControl(''),
    n804Name: new FormControl(''),
    text: new FormControl('', [Validators.required]),
    commentForCompany: new FormControl(''),
    stage: new FormControl(''),
    documentLink: new FormControl('')
  });

  get canConfirm() { return !this.processing && this.form.valid; }
  constructor() {
    this.form.get('clinicalRecommendationDiagnosisCode').disable({ emitEvent: false });
    this.form.get('clinicalRecommendationName').disable({ emitEvent: false });
    this.form.get('manipulationName').disable({ emitEvent: false });
  }

  ngOnInit() {
    this.form.patchValue({ ...this.manipulation });
  }


  confirm = () => this.onConfirm.emit(this.form.getRawValue());
  cancel = () => this.onCancel.emit();

}
