import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {  RecentTreatmentPrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-recent-treatment-prescription',
  templateUrl: './recent-treatment-prescription.component.html',
  styleUrls: ['./recent-treatment-prescription.component.scss']
})
export class RecentTreatmentPrescriptionComponent implements OnInit {

  @Input() item: RecentTreatmentPrescription;

  @Output()
  onAdd: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get formated(): string {
    if (!this.item) return '';

    return [this.item.treatmentName, this.item.dosage, this.item.schema, this.item.duration].filter(x => x).join(', ');
  }

  add(): void {
    this.onAdd.emit();
  }
}
