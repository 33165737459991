/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TemplatesResponse } from '../models/templates-response';
import { CreateTemplateResponse } from '../models/create-template-response';
import { CreateTemplateRequest } from '../models/create-template-request';
import { DeleteTemplatesResponse } from '../models/delete-templates-response';
import { SavedTemplateGroup } from '../models/saved-template-group';
import { Template } from '../models/template';
import { UpdateTemplateRequest } from '../models/update-template-request';
import { TemplateSection } from '../models/template-section';
import { ArchiveTemplatesResponse } from '../models/archive-templates-response';
import { ArchiveTemplatesRequest } from '../models/archive-templates-request';
import { RestoreTemplatesResponse } from '../models/restore-templates-response';
import { RestoreTemplatesRequest } from '../models/restore-templates-request';
@Injectable({
  providedIn: 'root',
})
class TemplatesService extends __BaseService {
  static readonly TemplatesPath = '/api/v1/Templates';
  static readonly CreatePath = '/api/v1/Templates';
  static readonly DeleteManyPath = '/api/v1/Templates';
  static readonly PlaceholdersPath = '/api/v1/Templates/placeholders';
  static readonly TemplatePath = '/api/v1/Templates/{id}';
  static readonly UpdatePath = '/api/v1/Templates/{id}';
  static readonly RemovePath = '/api/v1/Templates/{id}';
  static readonly SectionsPath = '/api/v1/Templates/{id}/sections';
  static readonly CopyPath = '/api/v1/Templates/{id}/copy';
  static readonly ArchiveManyPath = '/api/v1/Templates/archive';
  static readonly RestoreManyPath = '/api/v1/Templates/restore';
  static readonly RestorePath = '/api/v1/Templates/{id}/restore';
  static readonly ArchivePath = '/api/v1/Templates/{id}/archive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TemplatesService.TemplatesParams` containing the following parameters:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `OwnerId`:
   *
   * - `Archived`:
   *
   * @return Success
   */
  TemplatesResponse(params: TemplatesService.TemplatesParams): __Observable<__StrictHttpResponse<TemplatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SpecialityId != null) __params = __params.set('SpecialityId', params.SpecialityId.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OwnerId != null) __params = __params.set('OwnerId', params.OwnerId.toString());
    if (params.Archived != null) __params = __params.set('Archived', params.Archived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TemplatesResponse>;
      })
    );
  }
  /**
   * @param params The `TemplatesService.TemplatesParams` containing the following parameters:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `OwnerId`:
   *
   * - `Archived`:
   *
   * @return Success
   */
  Templates(params: TemplatesService.TemplatesParams): __Observable<TemplatesResponse> {
    return this.TemplatesResponse(params).pipe(
      __map(_r => _r.body as TemplatesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: CreateTemplateRequest): __Observable<__StrictHttpResponse<CreateTemplateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateTemplateResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: CreateTemplateRequest): __Observable<CreateTemplateResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as CreateTemplateResponse)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  DeleteManyResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<DeleteTemplatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeleteTemplatesResponse>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  DeleteMany(Id?: Array<number>): __Observable<DeleteTemplatesResponse> {
    return this.DeleteManyResponse(Id).pipe(
      __map(_r => _r.body as DeleteTemplatesResponse)
    );
  }

  /**
   * @return Success
   */
  PlaceholdersResponse(): __Observable<__StrictHttpResponse<Array<SavedTemplateGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Templates/placeholders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SavedTemplateGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  Placeholders(): __Observable<Array<SavedTemplateGroup>> {
    return this.PlaceholdersResponse().pipe(
      __map(_r => _r.body as Array<SavedTemplateGroup>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  TemplateResponse(id: number): __Observable<__StrictHttpResponse<Template>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Templates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Template>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Template(id: number): __Observable<Template> {
    return this.TemplateResponse(id).pipe(
      __map(_r => _r.body as Template)
    );
  }

  /**
   * @param params The `TemplatesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: TemplatesService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/Templates/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TemplatesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: TemplatesService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Templates/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Remove(id: number): __Observable<null> {
    return this.RemoveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  SectionsResponse(id: number): __Observable<__StrictHttpResponse<Array<TemplateSection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Templates/${id}/sections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TemplateSection>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Sections(id: number): __Observable<Array<TemplateSection>> {
    return this.SectionsResponse(id).pipe(
      __map(_r => _r.body as Array<TemplateSection>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  CopyResponse(id: number): __Observable<__StrictHttpResponse<CreateTemplateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates/${id}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateTemplateResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Copy(id: number): __Observable<CreateTemplateResponse> {
    return this.CopyResponse(id).pipe(
      __map(_r => _r.body as CreateTemplateResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  ArchiveManyResponse(request?: ArchiveTemplatesRequest): __Observable<__StrictHttpResponse<ArchiveTemplatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ArchiveTemplatesResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  ArchiveMany(request?: ArchiveTemplatesRequest): __Observable<ArchiveTemplatesResponse> {
    return this.ArchiveManyResponse(request).pipe(
      __map(_r => _r.body as ArchiveTemplatesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  RestoreManyResponse(request?: RestoreTemplatesRequest): __Observable<__StrictHttpResponse<RestoreTemplatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RestoreTemplatesResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  RestoreMany(request?: RestoreTemplatesRequest): __Observable<RestoreTemplatesResponse> {
    return this.RestoreManyResponse(request).pipe(
      __map(_r => _r.body as RestoreTemplatesResponse)
    );
  }

  /**
   * @param id undefined
   */
  RestoreResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates/${id}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Restore(id: number): __Observable<null> {
    return this.RestoreResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ArchiveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Templates/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Archive(id: number): __Observable<null> {
    return this.ArchiveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TemplatesService {

  /**
   * Parameters for Templates
   */
  export interface TemplatesParams {
    SpecialityId?: number;
    Size?: number;
    Search?: string;
    Page?: number;
    OwnerId?: number;
    Archived?: boolean;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: UpdateTemplateRequest;
  }
}

export { TemplatesService }
