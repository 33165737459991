import { DiseaseOutcome, Patient, Record, RecordSection } from "../../../generated/models";
import { Injectable } from '@angular/core';
import { DiseaseOutcomeService, PatientsService, RecordsService } from '../../../generated/services';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

@Injectable({ providedIn: "root" })
export class RecordResolver implements Resolve<RecordPayload> {
  public constructor(
    private recordsService: RecordsService,
    private outcomesService: DiseaseOutcomeService,
    private patientsService: PatientsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RecordPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    const recordLoader: Observable<Record> = this.recordsService.Record(id);
    const sectionsLoader: Observable<RecordSection[]> = this.recordsService.Sections(id);
    const outcomesLoader: Observable<DiseaseOutcome[]> = this.outcomesService.DiseaseOutcomes();
    const patientLoader: Observable<Patient> = this.patientsService.PatientForRecordAsync(id);

    return forkJoin({
      record: recordLoader,
      sections: sectionsLoader,
      outcomes: outcomesLoader,
      patient: patientLoader
    });
  }
}

export class RecordPayload {
  public record: Record;
  public sections: RecordSection[];
  public outcomes: DiseaseOutcome[];
  public patient: Patient;
}
