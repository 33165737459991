import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManagePromotionModel } from '../../../../generated/models';
import { PromotionsService } from '../../../../generated/services/promotions.service';

@Component({
  selector: 'mp-manage-promotion-modal',
  templateUrl: './manage-promotion.modal.component.html',
  styleUrls: ['./manage-promotion.modal.component.scss']
})
export class ManagePromotionModalComponent implements OnInit {
  public title: string;

  @Input("model") model: ManagePromotionModel;

  constructor(
    private activeModal: NgbActiveModal,
    private promotionsService: PromotionsService
  ) {
    this.title = "Канал привлечения пациентов";
  }

  ngOnInit() {

    this.title = `Удаление канала привлечения пациентов "${this.model.name}"`;
  }

  public remove(): void {
    this.promotionsService.Remove(this.model.id)
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (): void => { }
      );
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

}
