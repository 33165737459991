import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-laboratory-list-item',
  templateUrl: './laboratory-list-item.component.html',
  styleUrls: ['./laboratory-list-item.component.scss']
})
export class LaboratoryListItemComponent implements OnInit {
  @Input() result: LabResult;

  @Output() onOpenAttachment = new EventEmitter<number>();
  @Output() onEmailResult = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  openAttachment = (id: number) => this.onOpenAttachment.emit(id);
  emailResult = () => this.onEmailResult.emit();
}
