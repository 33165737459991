import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, from, Observable } from "rxjs";
import { ScheduleBreakReason } from "../../../generated/models";
import { ScheduleBreakReasonsService } from "../../../generated/services";

@Injectable({
  providedIn: "root"
})
export class ScheduleBreakReasonResolver implements Resolve<ScheduleBreakReasonResolverPayload> {

  constructor(private service: ScheduleBreakReasonsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleBreakReasonResolverPayload> {
    const id = parseInt(route.paramMap.get('id'));
    let reasonLoader: Observable<ScheduleBreakReason> = from([{}]);

    if (id > 0) {
      reasonLoader = this.service.ReasonAsync(id);
    }

    return forkJoin({
      reason: reasonLoader
    });

  }
}

export interface ScheduleBreakReasonResolverPayload {
  reason: ScheduleBreakReason;
}
