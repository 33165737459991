import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';

import { Diagnosis } from '../../models/diagnosis'
import { DiagnosisType } from '../../models/diagnosis.type';

@Component({
  selector: "mp-diagnosis-editor-modal",
  templateUrl: "./diagnosis-editor.component.html"
})

export class DiagnosisEditorModalComponent implements OnInit {

  private _modal: NgbActiveModal;

  private _loading: boolean;
  private _loaded: boolean;

  private _model: Diagnosis;
  private _diagnosisTypes: Array<DiagnosisType>;
  private _icd10Items: Array<number>;

  @Input("title") title: string;

  public constructor(
    modal: NgbActiveModal ) {
    this._modal = modal;
  }

  ngOnInit(): void {

    this._loaded = false;
    this._loading = true;

    this._model = Diagnosis.create({
      Id: 0, Icd10Id: 0, Text: "Хроническая усталость", Type: DiagnosisType.Sequela,
      Code: "A00.00", Icd10Name: "Хроническая усталость", DoctorId: 0, DoctorFullName: "Иванов Иван Иванович",
      DoctorShortName: "Иванов И.И."
    });

    this._diagnosisTypes = [];
    this._icd10Items = [];
  }

  public get loading(): boolean { return this._loading; }
  public get loaded(): boolean { return this._loaded; }

  public get model(): Diagnosis { return this._model; }

  public get diagnosisTypes(): Array<DiagnosisType> { return this._diagnosisTypes; }

  public get icd10Items(): Array<number> { return this._icd10Items; }

  public ok = (): void => {
    this._modal.dismiss();
  }

  public cancel = (): void => {
    this._modal.dismiss();
  }

  public get disabled(): boolean { return false; }

}
