import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TaxDeductionCandidate } from '../../../../generated/models';

import * as moment from 'moment';

@Component({
  selector: 'mp-tax-deductions-visit',
  templateUrl: './tax-deductions-visit.component.html',
  styleUrls: ['./tax-deductions-visit.component.scss']
})
export class TaxDeductionsVisitComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() candidate: TaxDeductionCandidate;
  @Input() selected = false;
  @Input() disabled = false;

  @Output() onChange = new EventEmitter<boolean>();
  @Output() onPrint = new EventEmitter<void>();

  get date(): string {
    if (!this.candidate || !this.candidate.date) return '';

    return moment(this.candidate.date, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY');
  }

  get time(): string {
    if (!this.candidate || !this.candidate.date) return '';

    return moment(this.candidate.date, 'DD.MM.YYYY HH:mm').format('HH:mm');
  }

  get total(): string {
    if (!this.candidate) return '';

    return this.candidate.total.toLocaleString('ru-RU', this.currencyFormat);
  }

  get deductionTitle(): string {
    if (!this.candidate.hash) return '';

    const date = moment(this.candidate.printed);

    return `Справка выдана ${date.format('DD.MM.YYYY HH:mm')}`;
  }

  control = new FormControl(false);

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: boolean) => {
          if (value !== this.selected) {
            this.onChange.emit(value);
          }
        }
      );
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      this.control.setValue(changes['selected'].currentValue, { emitEvent: false });
    }

    if (changes['disabled'] && changes['disabled'].currentValue) {
      this.control.disable({ emitEvent: false });
    }

    if (changes['disabled'] && !changes['disabled'].currentValue) {
      this.control.enable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  print = () => this.onPrint.emit();
}
