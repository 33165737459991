import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ActiveCallService {
  callId: number;

  started$ = new Subject<string>();
  accepted$ = new Subject<void>();
  cancelled$ = new Subject<void>();
  completed$ = new Subject<void>();

  active$ = new Subject<Call>();

  interlocutorUpdated$ = new Subject<Interlocutor>();
  actionsUpdates$ = new Subject<CallAction[]>();

  calls$ = new BehaviorSubject<Call[]>([]);

  calls: Call[] = [];

  get active(): Call {
    return this.calls.find(x => x.id === this.callId);
  }

  get accepted(): boolean {
    if (!this.active) return false;
    return this.active.accepted;
  }

  get interlocutor(): Interlocutor {
    if (!this.active) return undefined;
    return this.active.interlocutor;
  }

  /** Звонок начат*/
  start(phone: string) {
    let escaped = phone;

    if (escaped.startsWith('+') && escaped.length === 12) {
      escaped = escaped.substring(1);
    } else if (escaped.startsWith('8') && escaped.length === 11) {
      escaped = `7${escaped.substring(1)}`;
    }

    const call: Call = { id: Date.now(), accepted: false, started: true, actions: [], interlocutor: { phone: escaped } };

    this.calls.push(call);
    this.calls$.next(this.calls);

    if (!!this.callId) return;

    this.activate(call.id);
  }

  activate(id: number) {
    const call = this.calls.find(x => x.id === id);
    if (!call) return;

    this.callId = id;

    this.active$.next(call);
    this.started$.next(call.interlocutor.phone);
    this.interlocutorUpdated$.next(call.interlocutor);
  }

  /** Звонок принят*/
  accept() {
    if (!this.callId) return;

    const call = this.calls.find(x => x.id === this.callId);
    if (!call) return;

    call.accepted = true;

    this.accepted$.next();
    this.active$.next(call);
    this.calls$.next(this.calls);
  }

  /** Звонок сброшен*/
  cancel(startNext = false) {
    if (!this.callId) return;

    this.calls = this.calls.filter(x => x.id !== this.callId);
    this.callId = undefined;

    this.cancelled$.next();
    this.calls$.next(this.calls);

    if (startNext && this.calls.length > 0) {
      this.activate(this.calls[0].id)
    }
  }

  /** Звонок завершен*/
  complete(startNext = false) {
    if (!this.callId) return;

    this.calls = this.calls.filter(x => x.id !== this.callId);
    this.callId = undefined;

    this.completed$.next();
    this.calls$.next(this.calls);
    if (startNext && this.calls.length > 0) {
      this.activate(this.calls[0].id)
    }

  }

  setInterlocutor(id: number, lastname: string, firstname: string, middlename: string, dob: string) {
    if (!this.callId) return;

    const call = this.calls.find(x => x.id === this.callId);
    if (!call) return;

    call.interlocutor = {
      ...call.interlocutor,
      id,
      lastname,
      firstname,
      middlename,
      dob
    };

    this.interlocutorUpdated$.next(call.interlocutor);
    this.calls$.next(this.calls);
    this.active$.next(call);
  }

  addAction(type: 0 | 1 | 2 | 3 | 4) {
    if (!this.callId) return;

    const call = this.calls.find(x => x.id === this.callId);
    if (!call) return;

    if (!call.actions.some(x => x.ActionType === type)) {
      call.actions.push({ ActionType: type });

      this.actionsUpdates$.next(call.actions.sort((x, b) => x.ActionType - b.ActionType));
      this.calls$.next(this.calls);
      this.active$.next(call);
    }
  }

  removeAction(action: CallAction) {
    if (!this.callId) return;

    const call = this.calls.find(x => x.id === this.callId);
    if (!call) return;

    if (call.actions.some(x => x.ActionType === action.ActionType)) {
      call.actions = call.actions.filter(x => x.ActionType !== action.ActionType);

      this.actionsUpdates$.next(call.actions.sort((x, b) => x.ActionType - b.ActionType));
      this.calls$.next(this.calls);
      this.active$.next(call);
    }
  }
}

/** Текущий собеседник*/
export interface Interlocutor {
  id?: number;
  lastname?: string;
  firstname?: string;
  middlename?: string;
  phone?: string;
  dob?: string;
}

export interface CallAction {
  ActionType: 0 | 1 | 2 | 3 | 4
}

export interface Call {
  id: number;
  accepted: boolean;
  started: boolean;
  interlocutor: Interlocutor;
  actions: CallAction[];
}
