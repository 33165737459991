import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonLabOrder, PersonLabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-lab-results-modal',
  templateUrl: './lab-results-modal.component.html',
  styleUrls: ['./lab-results-modal.component.scss']
})
export class LabResultsModalComponent implements OnInit {

  @Input() item: PersonLabOrder;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<PersonLabResult[]>();

  get totalSelected(): number { return this.selected.length; }
  get disabled(): boolean { return this.selected.length === 0; }

  isSelected = (item: PersonLabResult) => this.selected.some(x => x.name === item.name && x.value === item.value);

  selected: PersonLabResult[] = [];



  constructor() { }

  ngOnInit() { }

  toggle(item: PersonLabResult) {
    console.info(item);
    const index = this.selected.findIndex(x => x.name === item.name && x.value === item.value);

    if (index === -1) {
      this.selected.push(item);
    } else {
      this.selected.splice(index, 1);
    }

  }

  cancel = () => this.onCancel.emit();
  confirm = () => this.onConfirm.emit(this.selected);
}
