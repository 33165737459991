import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@angular/forms';
import { PriceSetService } from '../../../../generated/models';

@Component({
  selector: 'mp-price-set-price-modal',
  templateUrl: './price-set-price-modal.component.html',
  styleUrls: ['./price-set-price-modal.component.scss']
})
export class PriceSetPriceModalComponent implements OnInit {
  title = 'Цена';

  @Input() priceName: number;
  @Input() price: PriceSetService;

  @Output() onConfirm = new EventEmitter<PriceSetPricePayload>();
  @Output() onCancel = new EventEmitter<void>();

  form: FormGroup = new FormGroup({
    costs: new FormControl(""),
    wages: new FormControl(""),
    price: new FormControl("", [Validators.required]),
    quantity: new FormControl("", [Validators.min(0)])
  });

  ngOnInit() {
    if (this.price) {
      const patch: PriceSetPricePayload = {
        costs: this.price.cost,
        wages: this.price.doctorWages,
        price: this.price.amount,
        quantity: this.price.quantity
      };

      this.form.patchValue(patch);
    }
  }

  cancel = () => this.onCancel.emit();

  submit(): void {
    Object.entries(this.form.controls).map(x => x[1]).forEach(x => { x.markAsTouched(); x.markAsDirty(); });

    if (this.form.invalid) return;
    this.onConfirm.emit(this.form.value)
  }
}

export interface PriceSetPricePayload {
  costs: number;
  wages: number;
  price: number;
  quantity: number;
}
