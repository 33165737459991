import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { RolePermission } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-role-permission-item',
  templateUrl: './role-permission-item.component.html',
  styleUrls: ['./role-permission-item.component.scss']
})
export class RolePermissionItemComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();
  @Input() permission: RolePermission;
  @Input() public set checked(value: boolean) {
    this.checkbox.setValue(value);
  }

  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  public checkbox: FormControl;
  constructor() {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: boolean): void => {
          this.onCheck.emit(value);
        },
        (): void => { }
      );
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

}
