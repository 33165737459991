import { Component, OnInit, AfterViewInit, AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { VisitTabComponent } from '../visit-tab/visit-tab.component';

@Component({
  selector: 'mp-visit-tabs',
  templateUrl: './visit-tabs.component.html',
  styleUrls: ['./visit-tabs.component.scss']
})
export class VisitTabsComponent implements AfterContentInit {
  @ContentChildren(VisitTabComponent) panes !: QueryList<VisitTabComponent>;

  ngAfterContentInit(): void {
    if (this.panes.length > 0) this.panes.first.activate();
  }

  activate(tab: VisitTabComponent): void {
    if (tab.disabled) return;

    this.panes.forEach(x => x.deactivate());
    tab.activate();
  }
}
