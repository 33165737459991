import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ServiceItemComponent } from './components/service-item/service-item.component';
import { ServiceItemResolver } from './resolvers/service-item.resolver';
import { ServiceItemsComponent } from './components/service-items/service-items.component';
import { ServiceItemsResolver } from './resolvers/service-items.resolver';
import { ServicePriceModalComponent } from './components/service-price.modal/service-price.modal.component';
import { ServiceStandardModalComponent } from './components/service-standard.modal/service-standard.modal.component';
import { RemoveServiceModalComponent } from './components/remove-service.modal/remove-service.modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { ManageServiceStandardModalComponent } from './components/manage-service-standard.modal/manage-service-standard.modal.component';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';
import { ServiceCodeSystemModalComponent } from './components/service-code-system-modal/service-code-system-modal.component';
import { ServiceCodeListItemComponent } from './components/service-code-list-item/service-code-list-item.component';
import { SharedModule } from '../shared/shared.module';
import { ServiceCompanyComponent } from './components/service-company/service-company.component';
import { ServiceManipulationItemComponent } from './components/service-manipulation-item/service-manipulation-item.component';
import { ServiceManipulationModalComponent } from './components/service-manipulation-modal/service-manipulation-modal.component';
import { GroupsModalComponent } from './components/groups-modal/groups-modal.component';

const routes: Routes = [
  {
    path: "new",
    component: ServiceItemComponent,
    data: {
      title: "Услуги: Новая услуга",
      requirements: [PermissionNames.ManagerServices]
    },
    resolve: {
      payload: ServiceItemResolver
    }
  },
  {
    path: ":id",
    component: ServiceItemComponent,
    data: {
      title: "Услуги: Изменение услуг",
      requirements: [PermissionNames.ManagerServices]
    },
    resolve: {
      payload: ServiceItemResolver
    }
  },
  {
    path: "",
    component: ServiceItemsComponent,
    resolve: {
      payload: ServiceItemsResolver
    },
    data: {
      title: "Список услуг",
      requirements: [PermissionNames.ManagerServices]
    }
  }
];

@NgModule({
  declarations: [
    ServiceItemsComponent,
    ServiceItemComponent,
    ServicePriceModalComponent,
    ServiceStandardModalComponent,
    RemoveServiceModalComponent,
    ManageServiceStandardModalComponent,
    ServiceCodeSystemModalComponent,
    ServiceCodeListItemComponent,
    ServiceCompanyComponent,
    ServiceManipulationItemComponent,
    ServiceManipulationModalComponent,
    GroupsModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ServicePriceModalComponent,
    ServiceStandardModalComponent,
    RemoveServiceModalComponent,
    ManageServiceStandardModalComponent,
    ServiceCodeSystemModalComponent,
    ServiceManipulationModalComponent,
    GroupsModalComponent
  ]
})
export class ServiceSettingsModule { }
