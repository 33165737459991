import { Component, Input, OnInit } from '@angular/core';
import { ServiceListItem } from '../services-list/services-list.component';

@Component({
  selector: 'mp-services-list-item',
  templateUrl: './services-list-item.component.html',
  styleUrls: ['./services-list-item.component.scss']
})
export class ServicesListItemComponent implements OnInit {

  @Input() disabled = true;
  @Input() showLimits = false;
  @Input() item: ServiceListItem;

  get grossPrice(): string { return this.formatPrice(this.item.grossPrice); }
  get finalPrice(): string { return this.formatPrice(this.item.finalPrice); }

  get hasDiscount(): boolean { return this.item.grossPrice - this.item.finalPrice > .01; }

  constructor() { }

  ngOnInit() { }

  formatPrice(price: number) {
    if (price - Math.floor(price) >= 0.01) {
      return price.toLocaleString('ru-RU', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });
    }

    return price.toLocaleString('ru-RU', { style: 'decimal', maximumFractionDigits: 0, minimumFractionDigits: 0 });
  }

}
