import { Visits } from './visits';
import { Records } from './records';
import { Results } from './results';
import { PhoneCalls } from './phone-calls';
import { Assignments } from './assignments';
import * as moment from "moment";

/** Представление информации о пользователе */
export class Patient {
  private _id: number;
  private _firstName: string;
  private _lastName: string;
  private _patronymic: string;
  private _age: number;
  private _dob: moment.Moment;;
  private _gender: number;
  private _phone: string;
  private _login: string;

  private _visits: Array<Visits>;
  private _records: Array<Records>;
  private _results: Array<Results>;
  private _phoneCalls: Array<PhoneCalls>;
  private _assignments: Array<Assignments>;

  public constructor() {
  }

  public get id(): number { return this._id; }
  public set id(value: number) { this._id = value; }

  public get firstName(): string { return this._firstName; }
  public set firstName(value: string) { this._firstName = value; }

  public get lastName(): string { return this._lastName; }
  public set lastName(value: string) { this._lastName = value; }

  public get patronymic(): string { return this._patronymic; }
  public set patronymic(value: string) { this._patronymic = value; }

  public get age(): number { return this._age; }
  public set age(value: number) { this._age = value; }

  public get dob(): moment.Moment { return this._dob; }
  public set dob(value: moment.Moment) { this._dob = value; }

  public get gender(): number { return this._gender; }
  public set gender(value: number) { this._gender = value; }

  public get phone(): string { return this._phone; }
  public set phone(value: string) { this._phone = value; }

  public get login(): string { return this._login; }
  public set login(value: string) { this._login = value; }

  public get visits(): Array<Visits> { return this._visits; }
  public set visits(value: Array<Visits> ) { this._visits= value; }

  public get assignments(): Array<Assignments> { return this._assignments; }
  public set assignments(value: Array<Assignments>) { this._assignments = value; }

  public get records(): Array<Records> { return this._records; }
  public set records(value: Array<Records>) { this._records = value; }

  public get results(): Array<Results> { return this._results; }
  public set results(value: Array<Results>) { this._results = value; }

  public get phoneCalls(): Array<PhoneCalls> { return this._phoneCalls; }
  public set phoneCalls(value: Array<PhoneCalls>) { this._phoneCalls = value; }

  public get fullName(): string { return `${this._firstName} ${this._patronymic} ${this._lastName}`; }

  public clone = (): Patient => {
    const cloned: Patient = new Patient();

    cloned.id = this._id;
    cloned.firstName = this._firstName;
    cloned.lastName = this._lastName;
    cloned.patronymic = this.patronymic;
    cloned.phone = this._phone;
    cloned.age = this._age;
    cloned.gender = this._gender;
    cloned.login = this._login;
    cloned.dob = this._dob;
    cloned.visits = this._visits;
    cloned.records = this._records;
    cloned.results = this._results;
    cloned.assignments = this._assignments;
    cloned.phoneCalls = this._phoneCalls;

    return cloned;
  }
}

export class Unit {
  public name: string;
  public id: number;
  constructor(name: string, id: number) {
    this.name = name;
    this.id = id;
  }
}

export class Status {
  public name: string;
  public value: number;
  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}

export class Attachment {
  public id: number;
  public name: string;
  constructor(id: number, name: string) {
    this.name = name;
    this.id = id;
  }
}
