/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeRecentManipulation } from '../models/employee-recent-manipulation';
@Injectable({
  providedIn: 'root',
})
class MyRecentService extends __BaseService {
  static readonly MyRecentManipulationsAsyncPath = '/api/v2/my/recent/manipulations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param limit undefined
   * @return Success
   */
  MyRecentManipulationsAsyncResponse(limit?: number): __Observable<__StrictHttpResponse<Array<EmployeeRecentManipulation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (limit != null) __params = __params.set('limit', limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/my/recent/manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeRecentManipulation>>;
      })
    );
  }
  /**
   * @param limit undefined
   * @return Success
   */
  MyRecentManipulationsAsync(limit?: number): __Observable<Array<EmployeeRecentManipulation>> {
    return this.MyRecentManipulationsAsyncResponse(limit).pipe(
      __map(_r => _r.body as Array<EmployeeRecentManipulation>)
    );
  }
}

module MyRecentService {
}

export { MyRecentService }
