/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateRefunRequest } from '../models/create-refun-request';
import { CreateBalanceRefundRequest } from '../models/create-balance-refund-request';
@Injectable({
  providedIn: 'root',
})
class RefundsService extends __BaseService {
  static readonly CreateRefundAsyncPath = '/api/visits/{id}/refund';
  static readonly CreateBalanceRefundAsyncPath = '/api/v2/people/{id}/refund';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RefundsService.CreateRefundAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateRefundAsyncResponse(params: RefundsService.CreateRefundAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.id}/refund`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RefundsService.CreateRefundAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateRefundAsync(params: RefundsService.CreateRefundAsyncParams): __Observable<null> {
    return this.CreateRefundAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RefundsService.CreateBalanceRefundAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateBalanceRefundAsyncResponse(params: RefundsService.CreateBalanceRefundAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/people/${params.id}/refund`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RefundsService.CreateBalanceRefundAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateBalanceRefundAsync(params: RefundsService.CreateBalanceRefundAsyncParams): __Observable<null> {
    return this.CreateBalanceRefundAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RefundsService {

  /**
   * Parameters for CreateRefundAsync
   */
  export interface CreateRefundAsyncParams {
    id: number;
    request?: CreateRefunRequest;
  }

  /**
   * Parameters for CreateBalanceRefundAsync
   */
  export interface CreateBalanceRefundAsyncParams {
    id: number;
    request?: CreateBalanceRefundRequest;
  }
}

export { RefundsService }
