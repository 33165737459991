import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientSearchResult } from '../../../../generated/models';

import * as moment from 'moment';

@Component({
  selector: 'mp-patients-list-item',
  templateUrl: './patients-list-item.component.html',
  styleUrls: ['./patients-list-item.component.scss']
})
export class PatientsListItemComponent implements OnInit {

  @Input() patient: PatientSearchResult;
  @Input() selected = false;

  @Output()
  onSelect = new EventEmitter<void>();

  get name(): string {
    if (!this.patient) return '';

    return [this.patient.lastname, this.patient.firstname, this.patient.middlename].join(' ');
  }

  get age() {
    if (!this.patient || !this.patient.dob) return '';

    const dob = moment(this.patient.dob, 'DD.MM.YYYY');

    const years = moment().diff(dob, 'years');
    const month = moment().diff(dob, 'month');
    const days = moment().diff(dob, 'days');

    if (years > 0) {
      if ([11, 12, 13, 14].includes(years)) return `${years} лет`;
      if (years % 10 === 1) return `${years} год`;
      if ([2, 3, 4,].includes(years % 10)) return `${years} года`;
      return `${years} лет`;
    }

    if (month > 0) {
      if (month === 1) return '1 месяц';
      if ([2, 3, 4].includes(month)) return `${month} месяца`;
      return `${month} месяцев`;
    }

    if (days > 0) {
      if ([11, 12, 13, 14].includes(days)) return `${days} дней`;
      if (days % 10 === 1) return `${days} день`;
      if ([2, 3, 4,].includes(days % 10)) return `${days} дня`;
      return `${days} дней`;
    }

    return '';
  }

  constructor() { }

  ngOnInit() { }

  select = () => this.onSelect.emit();
}
