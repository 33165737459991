import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../generated/services';
import { AccountCompany } from '../../generated/models';
import { UserStorageService } from '@skytecs/security';

@Component({
  selector: 'mp-change-company-modal',
  templateUrl: './change-company-modal.component.html',
  styleUrls: ['./change-company-modal.component.scss']
})
export class ChangeCompanyModalComponent implements OnInit {
  companies: AccountCompany[] = [];

  loading = false;
  entering = false;
  enteringId: number;

  constructor(private accountService: AccountService, private userStorage: UserStorageService) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.companies = await this.accountService.MyCompanies().toPromise();
    this.loading = false;
  }

  async selectCompany(company: AccountCompany): Promise<void> {

    this.entering = true;
    this.enteringId = company.companyId;

    const response = await this.accountService.ChangeCompany({ companyId: company.companyId }).toPromise();
    this.userStorage.authenticate(response.token, response.expires);
    window.location.reload();
  }

  selected(company: AccountCompany): boolean { return this.enteringId === company.companyId; }

  rolesFor(company: AccountCompany): string { return company.roles.join(", "); }
}
