import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefundReason } from '../../../../generated/models';

@Component({
  selector: 'mp-refund-reason-item',
  templateUrl: './refund-reason-item.component.html',
  styleUrls: ['./refund-reason-item.component.scss']
})
export class RefundReasonItemComponent implements OnInit {

  @Input() item: RefundReason;
  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }
}
