import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExternalConnectionsResponse, ExternalConnectionResponse } from '../../../../generated/models';

@Component({
  selector: 'mp-external-connections-list-item',
  templateUrl: './external-connections-list-item.component.html',
  styleUrls: ['./external-connections-list-item.component.scss']
})
export class ExternalConnectionsListItemComponent implements OnInit {

  @Input() externalConnection: ExternalConnectionResponse;

  @Output()
  onEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  public edit(): void {
    this.onEdit.emit()
  }

  public delete(): void {
    this.onDelete.emit()
  }

  public getExternalConnectionType(externalConnection: ExternalConnectionResponse): string {
    if (externalConnection.type == 1) return "Оборудование";
    if (externalConnection.type == 2) return "Лабораторная система";
    if (externalConnection.type == 3) return "Кассовый аппарат";
    if (externalConnection.type == 4) return "Приложение";
    if (externalConnection.type == 5) return "Штрих-принтер";
    if (externalConnection.type == 6) return "Принтер документов";
  }

}
