import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Metric } from '../../../../generated/models';

@Component({
  selector: 'mp-metrics-modal',
  templateUrl: './metrics-modal.component.html',
  styleUrls: ['./metrics-modal.component.scss']
})
export class MetricsModalComponent implements OnInit {

  @Input() selected: number[] = [];
  @Input() occupied: number[] = [];

  @Input() metrics: Metric[] = []

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<number[]>();

  constructor() { }

  ngOnInit() { }

  cancel = () => this.onCancel.emit();
  confirm = () => this.onConfirm.emit([...this.selected]);

  isSelected = (metric: Metric) => this.selected.some(x => metric.id === x);
  isDisabled = (metric: Metric) => this.occupied.some(x => metric.id == x);

  setSelected(metric: Metric, value: boolean) {
    if (!metric) return;

    if (!value) {
      this.selected = this.selected.filter(x => x !== metric.id);
    } else if (!this.selected.some(x => metric.id === x)) {
      this.selected.push(metric.id);
    }
  }

  toggle(metric: Metric) {
    if (this.isDisabled(metric)) return;

    const index = this.selected.findIndex(x => metric.id === x);

    if (index === -1) {
      this.selected.push(metric.id);
    }
    else {
      this.selected = this.selected.filter(x => metric.id !== x);
    }
  }

}
