import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() filters: DashboardFilters = { period: { from: undefined, to: undefined } };

  @Output()
  onFiltersChanged: EventEmitter<DashboardFilters> = new EventEmitter<DashboardFilters>();

  form: FormGroup = new FormGroup({
    period: new FormControl()
  });

  constructor() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: DashboardFilters, b: DashboardFilters): boolean => {
          if (!a.period.from && !!b.period.from) return false;
          if (!a.period.to && !!b.period.to) return false;

          if (!a.period.from.isSame(b.period.from, 'date')) return false;
          if (!a.period.to.isSame(b.period.to, 'date')) return false;

          return true;
        })
      )
      .subscribe((value: DashboardFilters): void => {
        this.onFiltersChanged.emit(value);
      });
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      const current: DashboardFilters = changes['filters'].currentValue;

      this.form.patchValue(current, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

export interface DashboardFilters {
  period: {
    from: moment.Moment;
    to: moment.Moment;
  }
}
