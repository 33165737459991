import { Component, OnInit, Input, Output } from '@angular/core';
import { PatientSearchResult } from '../../../../generated/models';

@Component({
  selector: 'mp-patient-list-item',
  templateUrl: './patient-list-item.component.html',
  styleUrls: ['./patient-list-item.component.scss']
})
export class PatientListItemComponent implements OnInit {

  @Input() patient: PatientSearchResult;
  get name(): string {
    if (!this.patient) return "";

    const parts: string[] = [this.patient.lastname];
    if (this.patient.firstname) parts.push(this.patient.firstname);
    if (this.patient.middlename) parts.push(this.patient.middlename);

    return parts.join(" ").trim();
  }

  constructor() { }

  ngOnInit() { }

}
