import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DiseaseOutcome, TemplateSection } from '../../../../generated/models';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'mp-outcome-section',
  templateUrl: './outcome-section.component.html',
  styleUrls: ['./outcome-section.component.scss']
})
export class OutcomeSectionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() section: TemplateSection = {};
  @Input() disabled = false;

  @Input() outcomes: DiseaseOutcome[] = []

  @Output() onChanges = new EventEmitter<TemplateSection>();

  form = new FormGroup({
    nextAppointmentInDays: new FormControl(),
    defaultOutcomeDescription: new FormControl(),
    defaultOutcomeStatus: new FormControl(null)
  });

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value: OutcomeSectionFormValue) => {

        this.onChanges.emit({ ...this.section, ...value });
      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      const section: TemplateSection = { ...changes['section'].currentValue };
      const value: OutcomeSectionFormValue = this.form.getRawValue();

      if (value.defaultOutcomeDescription !== section.defaultOutcomeDescription) {
        this.form.patchValue({ defaultOutcomeDescription: section.defaultOutcomeDescription });
      }

      if (value.nextAppointmentInDays !== section.nextAppointmentInDays) {
        this.form.patchValue({ nextAppointmentInDays: section.nextAppointmentInDays });
      }

      if (value.defaultOutcomeStatus !== section.defaultOutcomeStatus) {
        this.form.patchValue({ defaultOutcomeStatus: section.defaultOutcomeStatus })
      }
    }

    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled && !this.form.disabled) {
        this.form.disable({ emitEvent: false })
      }

      if (!disabled && this.form.disabled) {
        this.form.enable({ emitEvent: false });
      }

    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clear = () => this.form.get('nextAppointmentInDays').setValue(undefined);

  addWeek = () => this.form.get('nextAppointmentInDays').setValue(7);
  addMonth = () => this.form.get('nextAppointmentInDays').setValue(30);
  addHalfYear = () => this.form.get('nextAppointmentInDays').setValue(180);
  addYear = () => this.form.get('nextAppointmentInDays').setValue(365);

}

interface OutcomeSectionFormValue {
  nextAppointmentInDays?: number;
  defaultOutcomeDescription: string;
  defaultOutcomeStatus?: number;
}
