/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TreatmentRecommendation } from '../models/treatment-recommendation';
import { CreateTreatmentRecommendationRequest } from '../models/create-treatment-recommendation-request';
@Injectable({
  providedIn: 'root',
})
class TreatmentRecommendationsService extends __BaseService {
  static readonly TreatmentsAsyncPath = '/api/v1/treatments/recommended';
  static readonly TreatmentsAsync_1Path = '/api/v1/recommendations/treatment';
  static readonly CreateAsyncPath = '/api/v1/recommendations/treatment';
  static readonly CreateManyAsyncPath = '/api/v1/recommendations/treatment/many';
  static readonly ParseAsyncPath = '/api/v1/recommendations/treatment/parse';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TreatmentRecommendationsService.TreatmentsAsyncParams` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `DiagnosisCode`:
   *
   * - `Date`:
   *
   * @return Success
   */
  TreatmentsAsyncResponse(params: TreatmentRecommendationsService.TreatmentsAsyncParams): __Observable<__StrictHttpResponse<Array<TreatmentRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.Dob != null) __params = __params.set('Dob', params.Dob.toString());
    (params.DiagnosisCode || []).forEach(val => {if (val != null) __params = __params.append('DiagnosisCode', val.toString())});
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/treatments/recommended`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TreatmentRecommendation>>;
      })
    );
  }
  /**
   * @param params The `TreatmentRecommendationsService.TreatmentsAsyncParams` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `DiagnosisCode`:
   *
   * - `Date`:
   *
   * @return Success
   */
  TreatmentsAsync(params: TreatmentRecommendationsService.TreatmentsAsyncParams): __Observable<Array<TreatmentRecommendation>> {
    return this.TreatmentsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<TreatmentRecommendation>)
    );
  }

  /**
   * @param params The `TreatmentRecommendationsService.TreatmentsAsync_1Params` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `DiagnosisCode`:
   *
   * - `Date`:
   *
   * @return Success
   */
  TreatmentsAsync_1Response(params: TreatmentRecommendationsService.TreatmentsAsync_1Params): __Observable<__StrictHttpResponse<Array<TreatmentRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.Dob != null) __params = __params.set('Dob', params.Dob.toString());
    (params.DiagnosisCode || []).forEach(val => {if (val != null) __params = __params.append('DiagnosisCode', val.toString())});
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/recommendations/treatment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TreatmentRecommendation>>;
      })
    );
  }
  /**
   * @param params The `TreatmentRecommendationsService.TreatmentsAsync_1Params` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `DiagnosisCode`:
   *
   * - `Date`:
   *
   * @return Success
   */
  TreatmentsAsync_1(params: TreatmentRecommendationsService.TreatmentsAsync_1Params): __Observable<Array<TreatmentRecommendation>> {
    return this.TreatmentsAsync_1Response(params).pipe(
      __map(_r => _r.body as Array<TreatmentRecommendation>)
    );
  }

  /**
   * @param request undefined
   */
  CreateAsyncResponse(request?: CreateTreatmentRecommendationRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/recommendations/treatment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  CreateAsync(request?: CreateTreatmentRecommendationRequest): __Observable<null> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  CreateManyAsyncResponse(request?: Array<CreateTreatmentRecommendationRequest>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/recommendations/treatment/many`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  CreateManyAsync(request?: Array<CreateTreatmentRecommendationRequest>): __Observable<null> {
    return this.CreateManyAsyncResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  ParseAsyncResponse(file?: Blob): __Observable<__StrictHttpResponse<Array<TreatmentRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/recommendations/treatment/parse`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TreatmentRecommendation>>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  ParseAsync(file?: Blob): __Observable<Array<TreatmentRecommendation>> {
    return this.ParseAsyncResponse(file).pipe(
      __map(_r => _r.body as Array<TreatmentRecommendation>)
    );
  }
}

module TreatmentRecommendationsService {

  /**
   * Parameters for TreatmentsAsync
   */
  export interface TreatmentsAsyncParams {
    Gender?: any;
    Dob?: string;
    DiagnosisCode?: Array<string>;
    Date?: string;
  }

  /**
   * Parameters for TreatmentsAsync_1
   */
  export interface TreatmentsAsync_1Params {
    Gender?: any;
    Dob?: string;
    DiagnosisCode?: Array<string>;
    Date?: string;
  }
}

export { TreatmentRecommendationsService }
