import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Company } from '../../../../generated/models';

@Component({
  selector: 'mp-dms-info',
  templateUrl: './dms-info.component.html',
  styleUrls: ['./dms-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DmsInfoComponent implements OnInit, OnDestroy, OnChanges {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() disabled = false;
  @Input() submitted = false;
  @Input() companies: Company[] = [];
  @Input() dmsInfo: DmsInfo = { certificate: '', insuranceCompanyId: undefined, guaranteeNumber: '', guaranteeDate: undefined };

  @Output()
  onDmsInfoChanged: EventEmitter<DmsInfo> = new EventEmitter<DmsInfo>();

  insuranceCompanyControl: FormControl;
  certificateControl: FormControl;
  guaranteeNumberControl: FormControl;
  guaranteeDateControl: FormControl;

  form: FormGroup;

  constructor() {
    this.insuranceCompanyControl = new FormControl(undefined, [Validators.required]);
    this.certificateControl = new FormControl('', [Validators.required]);
    this.guaranteeNumberControl = new FormControl('');
    this.guaranteeDateControl = new FormControl(undefined);

    this.form = new FormGroup({
      insuranceCompanyId: this.insuranceCompanyControl,
      certificate: this.certificateControl,
      guaranteeNumber: this.guaranteeNumberControl,
      guaranteeDate: this.guaranteeDateControl
    });


    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(500),
      )
      .subscribe((): void => {

        const payload: DmsInfo = {
          insuranceCompanyId: this.insuranceCompanyControl.value,
          certificate: this.certificateControl.value,
          guaranteeNumber: this.guaranteeNumberControl.value,
          guaranteeDate: this.guaranteeDateControl.value && this.guaranteeDateControl.value.isValid()
            ? this.guaranteeDateControl.value.format("DD.MM.YYYY")
            : ""
        };

        this.onDmsInfoChanged.emit(payload);
      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dmsInfo']) {
      const value: DmsInfo = changes['dmsInfo'].currentValue;
      this.form.patchValue({
        ...value,
        guaranteeDate: moment(value.guaranteeDate, 'DD.MM.YYYY').isValid() ? moment(value.guaranteeDate, 'DD.MM.YYYY') : undefined
      }, { emitEvent: false });
    }

    if (changes['disabled']) {
      const value: boolean = changes['disabled'].currentValue;

      if (value) {
        this.certificateControl.disable({ emitEvent: false });
        this.insuranceCompanyControl.disable({ emitEvent: false });
      } else {
        this.certificateControl.enable({ emitEvent: false });
        this.insuranceCompanyControl.enable({ emitEvent: false });
      }
    }

    if (changes['submitted']) {
      const value: boolean = changes['submitted'].currentValue;
      if (value) {
        this.certificateControl.markAsTouched();
        this.insuranceCompanyControl.markAsTouched();
      }
    }

  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
}

export interface DmsInfo {
  insuranceCompanyId: number;
  certificate: string;
  guaranteeNumber: string;
  guaranteeDate: string;
}
