import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { PermissionNames } from '../../models/permission-names';
import { ReviewsResolver } from './resolvers/reviews-resolver';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ReviewCandidateItemComponent } from './components/review-candidate-item/review-candidate-item.component';
import { SharedModule } from '../shared/shared.module';
import { RevisionComponent } from './components/revision/revision.component';
import { RevisionResolver } from './resolvers/revision-resolver';
import { ReactiveFormsModule } from '@angular/forms';
import { SectionRevisionComponent } from './components/section-revision/section-revision.component';
import { RecordReviewSectionComponent } from './components/record-review-section/record-review-section.component';
import { ReviewDefectsComponent } from './components/review-defects/review-defects.component';
import { QuillModule } from 'ngx-quill';
import { ReviewsFiltersComponent } from './components/reviews-filters/reviews-filters.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { SectionReviewComponent } from './components/section-review/section-review.component';
import { NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RevisionNavigationGroupComponent } from './components/revision-navigation-group/revision-navigation-group.component';
import { ReferralsListComponent } from './components/referrals-list/referrals-list.component';
import { ServicePrescriptionsComponent } from './components/service-prescriptions/service-prescriptions.component';
import { ReviewDefectComponent } from './components/review-defect/review-defect.component';
import { ReviewsFiltersModalComponent } from './components/reviews-filters-modal/reviews-filters-modal.component';

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "records/:id/reviews/new",
        component: RevisionComponent,
        data: {
          title: "Карта оценки качества",
          requirements: [[PermissionNames.ReviewRecords, PermissionNames.ViewWorkspace]]
        },
        resolve: {
          payload: RevisionResolver
        }
      },
      {
        path: "records/:id/reviews/:reviewId",
        component: RevisionComponent,
        data: {
          title: "Карта оценки качества",
          requirements: [[PermissionNames.ReviewRecords, PermissionNames.ViewWorkspace]]
        },
        resolve: {
          payload: RevisionResolver
        }
      },
      {
        path: "records",
        component: ReviewsComponent,
        data: {
          title: "Клинико-экспертная работа",
          requirements: [[PermissionNames.ReviewRecords, PermissionNames.ViewWorkspace]]
        },
        resolve: {
          payload: ReviewsResolver
        }
      },
      {
        path: "",
        redirectTo: "records",

      }
    ]
  }
];

@NgModule({
  declarations: [
    ReviewsComponent,
    ReviewCandidateItemComponent,
    RevisionComponent,
    SectionRevisionComponent,
    RecordReviewSectionComponent,
    ReviewDefectsComponent,
    ReviewsFiltersComponent,
    SectionReviewComponent,
    RevisionNavigationGroupComponent,
    ReferralsListComponent,
    ServicePrescriptionsComponent,
    ReviewDefectComponent,
    ReviewsFiltersModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    SharedModule,

    LayoutModule,
    ControlsModule,

    InfiniteScrollModule,
    QuillModule.forRoot(),
    NgxSelectModule,
    NgbPopoverModule,
    NgbDropdownModule,
    NgbModalModule
  ],
  entryComponents: [
    ReviewsFiltersModalComponent
  ],
  exports: [
    RouterModule
  ]
})
export class ReviewsModule { }
