/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeeCompaniesResponse } from '../models/employee-companies-response';
import { AddEmployeeCompanyRequest } from '../models/add-employee-company-request';
import { AvailableEmployeeCompany } from '../models/available-employee-company';
import { UpdateEmployeeCompanyRequest } from '../models/update-employee-company-request';
@Injectable({
  providedIn: 'root',
})
class EmployeeCompaniesService extends __BaseService {
  static readonly CompaniesAsyncPath = '/api/employees/{id}/companies';
  static readonly CreateAsyncPath = '/api/employees/{id}/companies';
  static readonly AvailableCompaniesPath = '/api/employees/{id}/available-companies';
  static readonly UpdateCompanyPath = '/api/employees/{employeeId}/companies/{companyId}';
  static readonly DeleteCompanyPath = '/api/employees/{employeeId}/companies/{companyId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  CompaniesAsyncResponse(id: number): __Observable<__StrictHttpResponse<EmployeeCompaniesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/employees/${id}/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeCompaniesResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  CompaniesAsync(id: number): __Observable<EmployeeCompaniesResponse> {
    return this.CompaniesAsyncResponse(id).pipe(
      __map(_r => _r.body as EmployeeCompaniesResponse)
    );
  }

  /**
   * @param params The `EmployeeCompaniesService.CreateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateAsyncResponse(params: EmployeeCompaniesService.CreateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/employees/${params.id}/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeeCompaniesService.CreateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateAsync(params: EmployeeCompaniesService.CreateAsyncParams): __Observable<null> {
    return this.CreateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AvailableCompaniesResponse(id: number): __Observable<__StrictHttpResponse<Array<AvailableEmployeeCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/employees/${id}/available-companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableEmployeeCompany>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AvailableCompanies(id: number): __Observable<Array<AvailableEmployeeCompany>> {
    return this.AvailableCompaniesResponse(id).pipe(
      __map(_r => _r.body as Array<AvailableEmployeeCompany>)
    );
  }

  /**
   * @param params The `EmployeeCompaniesService.UpdateCompanyParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   *
   * - `request`:
   */
  UpdateCompanyResponse(params: EmployeeCompaniesService.UpdateCompanyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/employees/${params.employeeId}/companies/${params.companyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeeCompaniesService.UpdateCompanyParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   *
   * - `request`:
   */
  UpdateCompany(params: EmployeeCompaniesService.UpdateCompanyParams): __Observable<null> {
    return this.UpdateCompanyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeeCompaniesService.DeleteCompanyParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   */
  DeleteCompanyResponse(params: EmployeeCompaniesService.DeleteCompanyParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/employees/${params.employeeId}/companies/${params.companyId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeeCompaniesService.DeleteCompanyParams` containing the following parameters:
   *
   * - `employeeId`:
   *
   * - `companyId`:
   */
  DeleteCompany(params: EmployeeCompaniesService.DeleteCompanyParams): __Observable<null> {
    return this.DeleteCompanyResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EmployeeCompaniesService {

  /**
   * Parameters for CreateAsync
   */
  export interface CreateAsyncParams {
    id: number;
    request?: AddEmployeeCompanyRequest;
  }

  /**
   * Parameters for UpdateCompany
   */
  export interface UpdateCompanyParams {
    employeeId: number;
    companyId: number;
    request?: UpdateEmployeeCompanyRequest;
  }

  /**
   * Parameters for DeleteCompany
   */
  export interface DeleteCompanyParams {
    employeeId: number;
    companyId: number;
  }
}

export { EmployeeCompaniesService }
