/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GlobalSettings } from '../models/global-settings';
import { CentrifugoSettings } from '../models/centrifugo-settings';
@Injectable({
  providedIn: 'root',
})
class SettingsService extends __BaseService {
  static readonly GlobalPath = '/api/Settings';
  static readonly CentrifugeSettingsPath = '/api/Settings/centrifuge';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  GlobalResponse(): __Observable<__StrictHttpResponse<GlobalSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GlobalSettings>;
      })
    );
  }
  /**
   * @return Success
   */
  Global(): __Observable<GlobalSettings> {
    return this.GlobalResponse().pipe(
      __map(_r => _r.body as GlobalSettings)
    );
  }

  /**
   * @return Success
   */
  CentrifugeSettingsResponse(): __Observable<__StrictHttpResponse<CentrifugoSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Settings/centrifuge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CentrifugoSettings>;
      })
    );
  }
  /**
   * @return Success
   */
  CentrifugeSettings(): __Observable<CentrifugoSettings> {
    return this.CentrifugeSettingsResponse().pipe(
      __map(_r => _r.body as CentrifugoSettings)
    );
  }
}

module SettingsService {
}

export { SettingsService }
