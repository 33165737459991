import { Patient, DiseaseOutcome, TherapyResult, ColorMark } from '../../../generated/models';
import { PatientsService, DiseaseOutcomeService, TherapyResultsService, ColorMarksService } from '../../../generated/services';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class PatientResolver implements Resolve<PatientPayload> {
  constructor(
    private patientsService: PatientsService,
    private outcomesService: DiseaseOutcomeService,
    private resultsService: TherapyResultsService,
    private colorMarksService: ColorMarksService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PatientPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    const patientLoader: Observable<Patient> = this.patientsService.PatientForConsultation(id);
    const outcomesLoader: Observable<DiseaseOutcome[]> = this.outcomesService.DiseaseOutcomes();
    const resultsLoader: Observable<TherapyResult[]> = this.resultsService.TherapyResults();
    const colorMarksLoader: Observable<ColorMark[]> = this.colorMarksService.ColorMarks({ Page: 1, Size: 100 }).pipe(map(x => x.items));

    return forkJoin({
      patient: patientLoader,
      outcomes: outcomesLoader,
      results: resultsLoader,
      colorMarks: colorMarksLoader
    });
  }

}

export interface PatientPayload {
  patient: Patient;
  outcomes: DiseaseOutcome[];
  results: TherapyResult[];
  colorMarks: ColorMark[];
}
