import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from "moment";
import { DiscountResolverPayload } from '../../resolvers/discount.resolver';
import { DiscountsService } from '../../../../generated/services/discounts.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiscountCompany } from '../../../../generated/models';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-discount-editor',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.scss'],
  host: { class: "page" }
})
export class DiscountComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  discountForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    reason: new FormControl(''),
    discountType: new FormControl(null, [Validators.required]),
    value: new FormControl(null),
    isFixedValue: new FormControl(false),
    isPromoCode: new FormControl(false),
    sourceOfFinance: new FormControl(null, [Validators.required]),
    effective: new FormControl('', [Validators.required]),
    expires: new FormControl(''),
    sumForDiscount: new FormControl(null)
  });

  discountTypes = [{ id: 1, name: "Постоянная" }, { id: 2, name: "На посещение" }];
  sourceOfFinanceTypes = [{ id: 1, name: "За счет сотрудника" }, { id: 2, name: "За счет организации" }];

  id: number;
  title = 'Новая скидка';

  lastVisitDate = '';
  firstVisitDate = '';
  discountCompanies: DiscountCompany[] = [];

  get fixed(): boolean { return this.discountForm.get("isFixedValue").value; }
  get periodWarning(): string {
    const effectiveInvalid: boolean = this.discountForm.get('effective').errors ? this.discountForm.get('effective').errors.invalid : false;
    const expiresInvalid: boolean = this.discountForm.get('expires').errors ? this.discountForm.get('expires').errors.invalid : false;

    if (effectiveInvalid && expiresInvalid) {
      return `Срок действия скидки не может начинаться позднее ${this.firstVisitDate} и заканчиваться ранее ${this.lastVisitDate} из-за оформленных посещений`;
    }

    if (effectiveInvalid) {
      return `Срок действия скидки не может начинаться позднее ${this.firstVisitDate} из-за оформленных посещений`;
    }
    if (expiresInvalid) {
      return `Срок действия скидки не может заканчиваться ранее ${this.lastVisitDate} из-за оформленных посещений`;
    }

    return '';
  }

  constructor(private discountsService: DiscountsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private confirmationService: ConfirmationModalService
  ) {
    this.discountForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: FormValue): void => {

          if (value.isFixedValue && !value.value) {
            this.discountForm.get("value").setErrors({ required: true });
          } else {
            this.discountForm.get("value").setErrors(null);
          }

          if (this.lastVisitDate && moment(this.lastVisitDate, 'DD.MM.YYYY').isValid()) {
            const expires = value.expires;
            const effective = value.effective;

            if (effective && effective.isAfter(moment(this.lastVisitDate, 'DD.MM.YYYY'))) {
              this.discountForm.get("effective").setErrors({ invalid: true });
            }

            if (expires && this.lastVisitDate && expires.isBefore(moment(this.lastVisitDate, 'DD.MM.YYYY'))) {
              this.discountForm.get("expires").setErrors({ invalid: true });
            }
          }
        });
  }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { payload: DiscountResolverPayload }) => {
        this.id = data.payload.discount.id;

        this.discountForm.patchValue({ ...data.payload.discount });
        this.firstVisitDate = data.payload.discount.id > 0 ? data.payload.discount.firstVisitDate : '';
        this.lastVisitDate = data.payload.discount.id > 0 ? data.payload.discount.lastVisitDate : '';

        if (data.payload.discount.effective) {
          this.discountForm.controls["effective"].setValue(moment(data.payload.discount.effective, "DD.MM.YYYY"));
        }
        if (data.payload.discount.expires) {
          this.discountForm.controls["expires"].setValue(moment(data.payload.discount.expires, "DD.MM.YYYY"));
        }

        this.title = data.payload.discount.id > 0 ? `Скидка: ${data.payload.discount.name}` : "Новая скидка";

        if (this.lastVisitDate) {
          this.discountForm.disable({ emitEvent: false });
          this.discountForm.get('expires').enable({ emitEvent: false });
        }

        if (data.payload.discount.id > 0) {
          this.loadDiscountCompanies();
        }
      });
  }

  loadDiscountCompanies() {
    this.discountsService.DiscountCompaniesAsync(this.id)
      .subscribe(
        (response: DiscountCompany[]) => {
          this.discountCompanies = response;
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить список организаций', 'Ошибка');
        }
      )
  }

  async enableDiscountCompany(item: DiscountCompany) {
    const confirmed = await this.confirmationService.open({
      message: `Скидка будет разрешена в организации «${item.companyName}». Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.discountsService.EnableDiscountInCompanyAsync({ discountId: this.id, request: { companyId: item.companyId } })
      .subscribe(
        () => {
          this.toastrService.success(`Скидка разрешена в организации «${item.companyName}»`, 'Успешно');
          this.loadDiscountCompanies();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось разрешить скидку в организации «${item.companyName}»`, 'Ошибка');
          this.loadDiscountCompanies();
        }
      );
  }

  async disableDiscountCompany(item: DiscountCompany) {
    const confirmed = await this.confirmationService.open({
      message: `Скидка будет запрещена в организации «${item.companyName}». Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.discountsService.DisableDiscountInCompanyAsync({ discountId: this.id, request: { companyId: item.companyId } })
      .subscribe(
        () => {
          this.toastrService.success(`Скидка запрещена в организации «${item.companyName}»`, 'Успешно');
          this.loadDiscountCompanies();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось запретить скидку в организации «${item.companyName}»`, 'Ошибка');
          this.loadDiscountCompanies();
        }
      );
  }

  async enableDiscountInAllCompanies() {
    const confirmed = await this.confirmationService.open({
      message: `Скидка будет разрешена во всех организацих. Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.discountsService.EnableDiscountInAllCompaniesAsync(this.id)
      .subscribe(
        () => {
          this.toastrService.success(`Скидка разрешена во всех организациях`, 'Успешно');
          this.loadDiscountCompanies();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось разрешить скидку во всех организациях`, 'Ошибка');
          this.loadDiscountCompanies();
        }
      );
  }

  async disableDiscountInAllCompanies() {
    const confirmed = await this.confirmationService.open({
      message: `Скидка будет запрещена во всех организацих. Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.discountsService.DisableDiscountInAllCompaniesAsync(this.id)
      .subscribe(
        () => {
          this.toastrService.success(`Скидка запрещена во всех организациях`, 'Успешно');
          this.loadDiscountCompanies();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось запретить скидку во всех организациях`, 'Ошибка');
          this.loadDiscountCompanies();
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  handleError(response: HttpErrorResponse) {
    if (response.status === 403) {
      this.toastrService.warning('Для выполнения операции требуется разрешение', 'Запрещено');
      return;
    }

    if (response.status === 400 && response.error.errors) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Для выполнения операции требуется разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Необходимо указать дату начала срока действия скидки', 'Ошибка'); break;
          case 2: this.toastrService.warning('Необходимо указать название скидки', 'Ошибка'); break;
          case 3: this.toastrService.warning('Для фиксированной скидки необходимо указать значение', 'Ошибка'); break;
          case 4: this.toastrService.warning('Допустимы значения скидки от 0 до 100', 'Ошибка'); break;
          case 5: this.toastrService.warning('Скидка не найдена', 'Ошибка'); break;
          case 6: this.toastrService.warning('Допустимо только изменение даты окончания срока действия', 'Скидка используется в посещениях'); break;
          case 7: this.toastrService.warning(`Скидка должна действовать по ${response.error.lastVisitDate} включительно`, 'Скидка используется в посещениях'); break;


          default: this.toastrService.warning('Не удалось сохранить скидку', 'Ошибка'); break;
        }
      }

      return;
    }

    this.toastrService.error('Не удалось сохранить скидку', 'Ошибка');
  }

  async update(value: FormValue) {
    try {
      await this.discountsService.Edit({
        discountId: this.id,
        model: {
          name: value.name,
          effective: value.effective.format("DD.MM.YYYY"),
          expires: value.expires && value.expires.isValid() ? value.expires.format("DD.MM.YYYY") : null,
          discountType: value.discountType,
          isFixedValue: value.isFixedValue,
          reason: value.reason,
          sourceOfFinance: value.sourceOfFinance,
          value: value.value,
          isPromoCode: value.isPromoCode,
          sumForDiscount: value.sumForDiscount
        }
      }).toPromise();

      this.toastrService.success("Скидка сохранена", "Успешно");
    } catch (e) {
      this.handleError(e as HttpErrorResponse);
      this.discountForm.markAsDirty();
    }
  }

  async create(value: FormValue) {
    try {
      console.info(value);

      const response = await this.discountsService.Create({
        name: value.name,
        effective: value.effective.format("DD.MM.YYYY"),
        expires: value.expires && value.expires.isValid() ? value.expires.format("DD.MM.YYYY") : null,
        discountType: value.discountType,
        isFixedValue: value.isFixedValue,
        reason: value.reason,
        sourceOfFinance: value.sourceOfFinance,
        value: value.value,
        isPromoCode: value.isPromoCode,
        sumForDiscount: value.sumForDiscount
      }).toPromise();

      this.toastrService.success("Скидка добавлена", "Успешно");
      this.router.navigate(["..", response.id], { relativeTo: this.activatedRoute });
    }
    catch (e) {
      this.handleError(e as HttpErrorResponse);
      this.discountForm.markAsDirty();
    }
  }

  async acceptChanges(value: FormValue) {

    if (this.id > 0) {
      await this.update(value);
    } else {
      await this.create(value);
    }
  }
}

interface FormValue {
  name: string;
  reason: string;
  discountType: 1 | 2;
  value: number;
  isFixedValue: boolean;
  isPromoCode: boolean;
  sourceOfFinance: 1 | 2;
  effective: moment.Moment;
  expires: moment.Moment;
  sumForDiscount: number;
}
