import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosisResolverPayload } from '../../resolvers/diagnosis-resolver';
import { DiagnosesService, DiagnosisAliasesService, DiagnosisRecommendationsService, RecommendedManipulationsService } from '../../../../generated/services';
import { ClinicalRecommendation, DiagnosisAlias, DiagnosisRecommendation, Icd10ResponseItem, RecommendedManipulation } from '../../../../generated/models';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiagnosisAliasModalComponent } from '../diagnosis-alias-modal/diagnosis-alias-modal.component';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { RecommendationModalComponent } from '../recommendation-modal/recommendation-modal.component';
import { ManipulationModalComponent } from '../manipulation-modal/manipulation-modal.component';

@Component({
  selector: 'mp-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss'],
  host: { class: "page" }
})
export class DiagnosisComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  title = 'Новый диагноз';
  code = '';
  name = '';

  loadingAliases = false;
  loadingChildren = false;
  loadingRecommendations = false;

  aliases: DiagnosisAlias[] = [];
  children: Icd10ResponseItem[] = [];
  recommendations: DiagnosisRecommendation[] = [];
  manipulations: RecommendedManipulation[] = [];

  aliases$ = new BehaviorSubject<DiagnosisAlias[]>([]);

  form = new FormGroup({
    code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required]),
  });

  aliasesFilter = new FormGroup({
    search: new FormControl('')
  });

  get disableAliases() { return !this.code; }
  get disableChildren() { return !this.code; }
  get disableRecommendations() { return !this.code; }

  recommendationManipulations: { [id: number]: RecommendedManipulation[] } = {};

  constructor(private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private modal: NgbModal,
    private diagnosesService: DiagnosesService,
    private diagnosisAliasesService: DiagnosisAliasesService,
    private diagnosisRecommendationsService: DiagnosisRecommendationsService,
    private recommendedManipulationsService: RecommendedManipulationsService,
    private confirmationService: ConfirmationModalService
  ) {
    this.aliasesFilter.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: AliasesFilterValue) => {
          if (value.search) {
            const regex = new RegExp(value.search);

            this.aliases$.next([...this.aliases.filter(x => regex.test(x.text))]);
          } else {
            this.aliases$.next([...this.aliases]);
          }

        }
      );
  }

  ngOnInit() {
    this.form.disable();

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { payload: DiagnosisResolverPayload }) => {

          if (data.payload.diagnosis.id > 0) {
            this.id = data.payload.diagnosis.id;
            this.title = `Диагноз: ${data.payload.diagnosis.code}`;
            this.code = data.payload.diagnosis.code;
            this.name = data.payload.diagnosis.name;
          }

          this.form.patchValue(data.payload.diagnosis);

          this.loadAliases();
          this.loadChildren();
          this.loadRecommendationsAndManipulations();
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  loadAliases() {
    if (!this.code) return;

    this.loadingAliases = true;
    this.diagnosisAliasesService.DiagnosisAliasesAsync(this.code)
      .subscribe(
        (response: DiagnosisAlias[]) => {
          const value: AliasesFilterValue = this.aliasesFilter.getRawValue();

          this.aliases = response;

          if (value.search) {
            const regex = new RegExp(value.search);

            this.aliases$.next([...this.aliases.filter(x => regex.test(x.text))]);
          }
          else {
            this.aliases$.next([...this.aliases]);
          }

          this.loadingAliases = false;
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить псевдонимы', 'Ошибка');
          this.loadingAliases = false;
        }
      )
  }

  addAlias() {
    const modalRef = this.modal.open(DiagnosisAliasModalComponent, { centered: true, backdrop: 'static', size: 'lg' });
    const componentRef: DiagnosisAliasModalComponent = modalRef.componentInstance;

    componentRef.name = this.name;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(async (value: string) => {

      try {
        componentRef.processing = true;
        await this.diagnosisAliasesService.CreateDiagnosisAliasAsync({ code: this.code, request: { text: value } }).toPromise();

        modalRef.close();
        this.toastrService.success(`Псевдоним '${value}'' добавлен`, 'Успешно');
        this.loadAliases();
      }
      catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400 && response.error && response.error.errors) {
          for (const error of response.error.errors) {
            switch (error.status) {
              case 1: this.toastrService.warning('Необходимо указать псевдоним', 'Ошибка'); break;
              case 2: this.toastrService.warning('Необходимо указать код диагноза', 'Ошибка'); break;
              case 3: this.toastrService.warning('Указанный псевдоним уже добавлен', 'Ошибка'); break;
              case 4: this.toastrService.warning('Диагноз не найден', 'Ошибка'); break;
              case 5: this.toastrService.warning('Необходимо разрешение', 'Ошибка'); break;
              default: this.toastrService.warning('Не удалось добавить псевдоним', 'Ошибка'); break;
            }
          }
        } else {
          this.toastrService.error('Не удалось добавить псевдоним диагноза', 'Ошибка');
        }

        componentRef.processing = false;
      }
    });
  }

  async removeAlias(item: DiagnosisAlias) {

    const confirmed = await this.confirmationService.open({ message: `Псевдоним '${item.text}' будет удалён. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    try {
      await this.diagnosisAliasesService.RemoveDiagnosisAliasAsync(item.id).toPromise();
      this.toastrService.success(`Псевдоним '${item.text}' удалён`, 'Успешно');
      this.loadAliases();
    }
    catch (e) {
      const response = e as HttpErrorResponse;

      if (response.status === 400 && response.error && response.error.errors) {
        for (const error of response.error.errors) {
          switch (error.status) {
            case 5: this.toastrService.warning('Необходимо разрешение', 'Ошибка'); break;
            case 6: this.toastrService.warning('Псевдоним не найден', 'Ошибка'); break;
            default: this.toastrService.warning('Не удалось удалить псевдоним', 'Ошибка'); break;
          }
        }
      } else {
        this.toastrService.error('Не удалось удалить псевдоним диагноза', 'Ошибка');
      }
    }

  }

  loadChildren() {

    if (new RegExp(/^[a-zA-Z]\d\d$/ig).test(this.code)) {
      this.diagnosesService.SearchAsync(this.code)
        .subscribe(
          (response: Icd10ResponseItem[]) => {
            this.children = response.filter(x => x.id !== this.id);
            this.loadingChildren = false;
          },
          (response: HttpErrorResponse) => {
            this.toastrService.error('Не удалось загрузить связанные диагнозы');
            this.loadingChildren = false;
          }
        );
    }

    this.loadingChildren = true;
  }

  loadRecommendations() {
    if (!this.code) return;

    this.loadingRecommendations = true;

    this.diagnosisRecommendationsService.DiagnosisRecommendationsAsync(this.code)
      .subscribe(
        (response: DiagnosisRecommendation[]) => {
          this.recommendations = response;
          this.loadingRecommendations = false;

          this.recommendationManipulations = this.recalculateRecommendations(this.recommendations, this.manipulations);
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить рекомендации', 'Ошибка');
          this.loadingRecommendations = false;
        }
      );
  }

  addRecommendation() {
    if (!this.code) return;

    const modalRef = this.modal.open(RecommendationModalComponent, { size: 'lg', backdrop: 'static', centered: true });
    const componentRef: RecommendationModalComponent = modalRef.componentInstance;

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe(async (payload: { name: string }) => {

      try {
        componentRef.processing = true;
        const response = await this.diagnosisRecommendationsService.CreateDiagnosisRecommendationAsync({ code: this.code, request: { name: payload.name } }).toPromise();
        componentRef.processing = false;
        modalRef.close();

        this.toastrService.success('Рекомендация добавлена', 'Успешно');
        this.loadRecommendations();
      } catch (e) {
        const response = e as HttpErrorResponse;

        this.toastrService.error('Не удалось добавить рекомендацию', 'Ошибка');
        componentRef.processing = false
      }
    });

  }

  editRecommendation(item: DiagnosisRecommendation) {
    if (!this.code) return;

    const modalRef = this.modal.open(RecommendationModalComponent, { size: 'lg', backdrop: 'static', centered: true });
    const componentRef: RecommendationModalComponent = modalRef.componentInstance;

    componentRef.recommendation = item;

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe(async (payload: { name: string }) => {

      try {
        componentRef.processing = true;
        await this.diagnosisRecommendationsService.UpdateDiagnosisRecommendationAsync({ id: item.id, request: { name: payload.name } }).toPromise();
        componentRef.processing = false;
        modalRef.close();

        this.toastrService.success('Рекомендация обновлена', 'Успешно');
        this.loadRecommendations();
      } catch (e) {
        const response = e as HttpErrorResponse;

        this.toastrService.error('Не удалось обновить рекомендацию', 'Ошибка');
        componentRef.processing = false
      }
    });

  }

  async removeRecommendation(recommendation: DiagnosisRecommendation) {
    const confirmed = await this.confirmationService.open({
      message: `Рекомендация '${recommendation.name}' и все входящие в нее назначения будут удалены. Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.diagnosisRecommendationsService.RemoveDiagnosisRecommendationAsync(recommendation.id)
      .subscribe(
        () => {
          this.toastrService.success('Рекомендация удалена', 'Успешно');
          this.loadRecommendations();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось удалить рекомендацию', 'Ошибка');
        }
      );

  }

  loadManipulations() {
    if (!this.code) return;

    this.recommendedManipulationsService.RecommendedManipulationsAsync(this.code)
      .subscribe(
        (response: RecommendedManipulation[]) => {
          this.manipulations = response;

          this.recommendationManipulations = this.recalculateRecommendations(this.recommendations, this.manipulations);
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить рекомендованные назначения', 'Ошибка');
        }
      )
  }

  recalculateRecommendations(recs: DiagnosisRecommendation[], manipulations: RecommendedManipulation[]) {
    const result: { [id: number]: RecommendedManipulation[] } = {};

    for (const rec of recs) {
      result[rec.id] = manipulations.filter(x => x.clinicalRecommendationId === rec.id);
    }

    return result;
  }

  loadRecommendationsAndManipulations() {
    if (!this.code) return;

    forkJoin({
      recommendations: this.diagnosisRecommendationsService.DiagnosisRecommendationsAsync(this.code),
      manipulations: this.recommendedManipulationsService.RecommendedManipulationsAsync(this.code)
    }).subscribe(
      (response: { recommendations: DiagnosisRecommendation[], manipulations: RecommendedManipulation[] }) => {
        this.recommendations = response.recommendations;
        this.manipulations = response.manipulations;

        this.recommendationManipulations = this.recalculateRecommendations(this.recommendations, this.manipulations);

      },
      (response: HttpErrorResponse) => {
        this.toastrService.error('Не удалось загрузить рекомендации и назначения', 'Ошибка');
      }
    );
  }

  manipulationsFor = (rec: DiagnosisRecommendation) => this.recommendationManipulations[rec.id] || [];

  openDocument(target: RecommendedManipulation) {
    if (!target || !target.documentLink) return;

    window.open(target.documentLink, '_blank');
  }

  addManipulation(item: DiagnosisRecommendation) {
    const modalRef = this.modal.open(ManipulationModalComponent, { backdrop: 'static', centered: true, size: 'xxl' as 'lg' });
    const componentRef: ManipulationModalComponent = modalRef.componentInstance;

    componentRef.manipulation = { clinicalRecommendationDiagnosisCode: item.diagnosisCode, clinicalRecommendationName: item.name };

    componentRef.onCancel.subscribe(() => modalRef.close());

  }

  editManipulation(item: RecommendedManipulation) {
    const modalRef = this.modal.open(ManipulationModalComponent, { backdrop: 'static', centered: true, size: 'xxl' as 'lg' });
    const componentRef: ManipulationModalComponent = modalRef.componentInstance;

    componentRef.manipulation = item;

    componentRef.onCancel.subscribe(() => modalRef.close());

  }

  async removeManipulation(item: RecommendedManipulation) {

    const name = item.manipulationName || item.n804Name;

    const confirmed = await this.confirmationService.open({ message: `Назначение '${name}' будет удалено. продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    try {
      await this.recommendedManipulationsService.RemoveRecommendedManipulationAsync(item.id).toPromise();

      this.toastrService.success('Назначение удалено', 'Успешно');
      this.loadManipulations();
    }
    catch (e) {
      const response = e as HttpErrorResponse;

      this.toastrService.error('Не удалось удалить назначение', 'Ошибка');
    }

  }

}

interface AliasesFilterValue {
  search: string;
}
