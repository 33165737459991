import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'mp-treatment-analog-editor',
  templateUrl: './treatment-analog-editor.component.html',
  styleUrls: ['./treatment-analog-editor.component.scss']
})
export class TreatmentAnalogEditorComponent implements OnInit {

  @Input() item = '';

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }
}
