import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesModalComponent } from '../templates-modal/templates-modal.component';
import { CommentEditorModalComponent } from '../comment-editor/comment-editor.component';
import { MedicalFilesService, PatientsService, RecordsService, DocumentsService, MyService, LinesService, ReviewsService, OrganizationsService, VisitsService, PersonDocumentsService, PersonMarksService, PersonMetricsService } from 'projects/Clinic/src/app/generated/services';
import { MedicalFileResponse } from 'projects/Clinic/src/app/generated/models/medical-file-response';

import * as moment from 'moment';

import { Record, RemoveDocumentsResponse, AccessTokenResponse, DiseaseOutcome, TherapyResult, UsersTemplate, RecordReviewSummary, CompanyForbiddenDiagnosis, AddedServicesResponse, PersonConsultation, PersonDiagnosis, PersonDocument, PersonMark, ColorMark, PersonMetric } from 'projects/Clinic/src/app/generated/models';
import { DocumentPreviewModalComponent } from '../document-preview-modal/document-preview-modal.component';
import { PatientPayload } from '../../resolvers/patient-resolver';
import { Gender } from '../../models/gender';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Observable, from, of } from 'rxjs';
import { concatMap, map, scan, switchMap, takeUntil } from 'rxjs/operators';
import { SettingsStorage } from '../../../../services/settings-storage.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ReviewsModalComponent } from '../reviews-modal/reviews-modal.component';
import { ReportsUtilityService } from '../../../shared/services/reports-url-service';
import { AddServiceModalComponent, ConfirmEvent } from '../add-service-modal/add-service-modal.component';
import { PersonsService } from '../../../../generated/services/persons.service';
import { PersonTestResult } from '../../../../generated/models/person-test-result';
import { PersonTestResultAttachment } from '../../../../generated/models/person-test-result-attachment';
import { AddPersonDocumentResponse } from '../../../../generated/models/add-person-document-response';
import { DocumentPreviewService } from '../../../shared/services/document-preview-service';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { PersonMarkModalService } from '../../../person-marks/services/person-mark-modal.service';
import { PersonMarkModalComponent, PersonMarkModalPayload } from '../../../person-marks/components/person-mark-modal/person-mark-modal.component';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  host: { class: "page" }
})
export class PatientComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  printSource$ = new Subject<string[]>();

  userId: number;

  omsEnabled = false;

  title = "Медицинская карта";

  lastname = '';
  firstname = '';
  middlename = '';
  personalNumber = '';

  dmsCompanyId: number;
  dmsCompany = '';
  dmsCertificate = '';
  paymentSource: 0 | 1 | 2 | 3 | 4 | 5;

  customerName = '';

  forbiddenDiagnoses: Set<string> = new Set<string>();

  hasForbiddenDiagnoses = false;

  private _omsData: { result: number; outcome: number, resultDate: moment.Moment };

  gender = "";
  age = "";

  comment: string;

  patientId: number;
  personId: number;
  consultationId: number;

  model: MedicalFileResponse = {};
  diagnoses: PersonDiagnosis[] = [];
  documents: PersonDocument[] = [];
  visits: PersonConsultation[] = [];
  testResults: PersonTestResult[] = [];
  metrics: PersonMetric[] = [];

  outcomes: DiseaseOutcome[] = [];
  therapyResults: TherapyResult[] = [];

  colorMarks: ColorMark[] = [];
  marks: PersonMark[] = [];

  selectedTab: number;

  loading = false;
  loadingDocuments = false;
  servicesLoading = false;
  loadingDiagnoses = false;
  loadingVisits = false;
  loadingResults = false;
  loadingMetrics = false;

  preliminaryDiagnosisCode: string;
  primaryDiagnosisCode: string;
  coexistingDiagnosisCode: string;
  sequelaDiagnosisCode: string;

  preliminaryDiagnosisName: string;
  primaryDiagnosisName: string;
  coexistingDiagnosisName: string;
  sequelaDiagnosisName: string;

  @ViewChild("DummyWindow") dummyWindow: ElementRef<HTMLDivElement>;

  public get loadingServices(): boolean { return this.servicesLoading; }

  get displayAge(): string {
    return moment(this.model.dob, "DD.MM.YYYY").isValid()
      ? moment().diff(moment(this.model.dob, "DD.MM.YYYY"), 'years').toString() + " лет"
      : "Возраст не указан";
  }

  get hasDiagnoses(): boolean { return !!this.preliminaryDiagnosisCode || !!this.primaryDiagnosisCode || !!this.coexistingDiagnosisCode || !!this.sequelaDiagnosisCode }

  get disabled(): boolean { return false; }
  get signDocumentsAutomatically(): boolean { return false; }
  get canRemoveSignature(): boolean { return false; }

  get omsData(): { result: number; outcome: number } { return this._omsData; }

  get paymentSourceTitle(): string {
    switch (this.paymentSource) {
      case 1: return "Платные услуги";
      case 3: return this.hasForbiddenDiagnoses ? "ДМС - есть запрещенные диагнозы" : "ДМС";
      default: return "";
    }

  }

  omsForm = new FormGroup({
    result: new FormControl(undefined),
    outcome: new FormControl(undefined),
    resultDate: new FormControl(undefined)
  });

  canReview = false;
  canAddService = false;
  canRemoveSigned = false;

  canAddMark = false;
  canManageMarks = false;

  constructor(
    private modal: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private medicalFilesService: MedicalFilesService,
    private patientsService: PatientsService,
    private recordsService: RecordsService,
    private documentsService: DocumentsService,
    private settingsStorage: SettingsStorage,
    private toastrService: ToastrService,
    private myService: MyService,
    private userStorage: UserStorage,
    private linesService: LinesService,
    private reviewsService: ReviewsService,
    private reportsUtilityService: ReportsUtilityService,
    private organizationsService: OrganizationsService,
    private visitsService: VisitsService,
    private personsService: PersonsService,
    private personDocumentsService: PersonDocumentsService,
    private documentPreviewService: DocumentPreviewService,
    private personMarksService: PersonMarksService,
    private confirmationService: ConfirmationModalService,
    private personMarkModalService: PersonMarkModalService,
    private personMetricsService: PersonMetricsService
  ) {
    this.canReview = userStorage.hasPermission(PermissionNames.ReviewRecords);
    this.canAddService = userStorage.hasPermission(PermissionNames.AddServicesFromWorkspace);
    this.canRemoveSigned = userStorage.hasPermission(PermissionNames.RemoveSignedProtocols);

    this.canAddMark = this.userStorage.hasPermission(PermissionNames.AddPersonMarks);
    this.canManageMarks = this.userStorage.hasPermission(PermissionNames.ManagePersonMarks);

    this.omsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { result: number; outcome: number, resultDate: moment.Moment }) => this._omsData = { ...value });

    this.omsEnabled = settingsStorage.global.visitAdditionalPatientsFields;

    this._omsData = { outcome: undefined, result: undefined, resultDate: undefined };

    this.printSource$.subscribe((urls: string[]): void => {
      this.dummyWindow.nativeElement.innerHTML = "";

      for (const url of urls) {
        const frame: HTMLIFrameElement = document.createElement("iframe");

        frame.style.display = "none";
        frame.src = url;

        this.dummyWindow.nativeElement.append(frame);
        frame.contentWindow.print();
      }
    });
  }

  ngOnInit() {
    this.userId = this.userStorage.profile.id;
    this.consultationId = parseInt(this.activatedRoute.snapshot.paramMap.get("id"), 10);

    this.activatedRoute.data.subscribe((data: { payload: PatientPayload }): void => {

      this.patientId = data.payload.patient.id;
      this.personId = data.payload.patient.personId;

      this.firstname = data.payload.patient.firstname || '';
      this.middlename = data.payload.patient.middlename || '';
      this.lastname = data.payload.patient.lastname || '';
      this.personalNumber = data.payload.patient.personalNumber ? String(data.payload.patient.personalNumber) : "";
      this.comment = data.payload.patient.comment;

      this.paymentSource = data.payload.patient.paymentSource;
      this.dmsCertificate = data.payload.patient.policy;
      this.dmsCompany = data.payload.patient.insuranceCompanyName;
      this.dmsCompanyId = data.payload.patient.insuranceCompanyId;

      this.customerName = data.payload.patient.customerCompanyName;

      this._omsData = {
        result: data.payload.patient.therapyResult,
        outcome: data.payload.patient.diseaseOutcome,
        resultDate: moment(data.payload.patient.therapyFinished, 'DD.MM.YYYY')
      };

      this.omsForm.patchValue(this._omsData);

      const age: number = parseInt(data.payload.patient.age, 10);
      switch (age % 10) {
        case 1:
          this.age = `${age} год`;
          break;
        case 2:
        case 3:
        case 4:
          this.age = `${age} года`;
          break;
        case 0:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
          this.age = `${age} лет`;
          break;
        default:
          this.age = "";
          break;
      }

      switch (data.payload.patient.gender) {
        case Gender.Fimale:
          this.gender = "Жен.";
          break;
        case Gender.Male:
          this.gender = "Муж.";
          break;
        default:
          this.gender = "";
          break;
      }

      this.title = "Медицинская карта: " + [this.lastname, this.firstname, this.middlename].join(" ");

      this.outcomes = data.payload.outcomes;
      this.therapyResults = data.payload.results;
      this.colorMarks = data.payload.colorMarks;

      this.loadingDiagnoses = true;
      this.personsService.DiagnosesAsync(this.personId)
        .subscribe(
          (response: PersonDiagnosis[]): void => {
            this.diagnoses = response;
            this.loadingDiagnoses = false;

            const preIndex: number = this.diagnoses.findIndex(x => x.type === 0);
            const priIndex: number = this.diagnoses.findIndex(x => x.type === 1);
            const seqIndex: number = this.diagnoses.findIndex(x => x.type === 2);
            const coeIndex: number = this.diagnoses.findIndex(x => x.type === 3);

            if (preIndex !== -1) {
              this.preliminaryDiagnosisCode = this.diagnoses[preIndex].code;
              this.preliminaryDiagnosisName = this.diagnoses[preIndex].name;
            }

            if (priIndex !== -1) {
              this.primaryDiagnosisCode = this.diagnoses[priIndex].code;
              this.primaryDiagnosisName = this.diagnoses[priIndex].name;
            }

            if (seqIndex !== -1) {
              this.sequelaDiagnosisCode = this.diagnoses[seqIndex].code;
              this.sequelaDiagnosisName = this.diagnoses[seqIndex].name;
            }

            if (coeIndex !== -1) {
              this.coexistingDiagnosisCode = this.diagnoses[coeIndex].code;
              this.coexistingDiagnosisName = this.diagnoses[coeIndex].name;
            }

            if (this.paymentSource === 3) {
              this.organizationsService.ForbiddenDiagnosesAsync(this.dmsCompanyId)
                .subscribe((response: CompanyForbiddenDiagnosis[]): void => {
                  response.forEach(x => this.forbiddenDiagnoses.add(x.diagnosisCode));

                  this.hasForbiddenDiagnoses = this.diagnoses.some(x => this.forbiddenDiagnoses.has(x.code));
                });
            }
          }
        );

      this.loadingVisits = true;
      this.personsService.ConsultationsAsync(this.personId)
        .subscribe(
          (consultations: PersonConsultation[]): void => {
            this.visits = consultations;
            this.loadingVisits = false;
          }
        );

      this.loadingResults = true;
      this.personsService.ResultsAsync(this.personId)
        .subscribe(
          (results: PersonTestResult[]): void => {
            this.testResults = results;
            this.loadingResults = false;
          }
        );

      this.loadingDocuments = true;

      this.personDocumentsService.DocumentsAsync(this.personId)
        .subscribe(
          (documents: PersonDocument[]): void => {
            this.loadingDocuments = false;
            this.documents = documents;
          }
        );

      this.loadingMetrics = true;

      this.personMetricsService.PersonMetricsAsync(this.personId)
        .subscribe((response: PersonMetric[]) => {
          this.metrics = response;
          this.loadingMetrics = false;
        });

      this.loadMarks();

      this.selectTab(3);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();

    this.destroy$.unsubscribe();
    this.printSource$.unsubscribe();
  }

  selectTab = (index: number) => this.selectedTab = index;


  loadMarks() {
    this.personMarksService.PersonMarksAsync(this.personId)
      .subscribe(
        (response: PersonMark[]) => {
          this.marks = response;
        }
      );
  }

  addRecord(item: PersonConsultation): void {
    const modalRef: NgbModalRef = this.modal.open(TemplatesModalComponent, { centered: true, backdrop: 'static', size: "lg" });
    const componentRef: TemplatesModalComponent = modalRef.componentInstance;

    componentRef.contractItemId = item.id;
    componentRef.personId = this.personId;

    componentRef.onSubmit.subscribe(
      (template: UsersTemplate): void => {
        this.recordsService.Create({ contractItemId: item.id, templateId: template.id })
          .subscribe(
            (response: Record): void => {
              const index = this.visits.findIndex(x => x.id === item.id)

              if (index !== -1) {
                this.visits[index].records.unshift(response);
              }

              this.router.navigate(['./records', response.id], { relativeTo: this.activatedRoute.parent.parent })
                .then((success): void => {
                  if (success) {
                    modalRef.close();
                  }
                })
            },
            (response: HttpErrorResponse): void => {
              modalRef.componentInstance.processing = false;

              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }

              this.toastrService.error("Не удалось добавить документ", "Ошибка");
            }
          );
      }
    );

    componentRef.onRecordOpen.subscribe((id: number): void => {
      this.router.navigate(['./records', id], { relativeTo: this.activatedRoute.parent.parent })
        .then((success): void => {
          if (success) {
            modalRef.close();
          }
        });
    });

    componentRef.title = "Список доступных шаблонов";
  }

  addService(item: PersonConsultation): void {
    const modalRef = this.modal.open(AddServiceModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: AddServiceModalComponent = modalRef.componentInstance;

    componentRef.doctorId = this.userId;
    componentRef.visitId = item.visitId;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((event: ConfirmEvent): void => {
      modalRef.componentInstance.processing = true;

      this.visitsService.AddServices({ id: item.visitId, request: { serviceIds: [event.serviceId] } })
        .pipe(
          switchMap((response: AddedServicesResponse) => {
            if (response.contractItemIds.length != 1) {
              console.error("Multiple services created");
              return of({});
            }

            return this.recordsService.Create({ contractItemId: response.contractItemIds[0], templateId: event.templateId })
          })
        )
        .subscribe((response: Record) => {
          if (response.id) {
            this.router.navigate(['./records', response.id], { relativeTo: this.activatedRoute.parent.parent })
              .then((success): void => {
                if (success) {
                  modalRef.close();
                }
              });
          }
        }, (response: HttpErrorResponse): void => {
          modalRef.componentInstance.processing = false;

          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }

          this.toastrService.error("Не удалось добавить документ", "Ошибка");
        })
    });
  }

  openCommentModal = (): void => {
    const modalRef: NgbModalRef = this.modal.open(CommentEditorModalComponent, { backdrop: 'static', size: "lg" });
    const componentRef: CommentEditorModalComponent = modalRef.componentInstance;

    modalRef.result.then(
      (result: string) => this.patientsService.EditComment({ id: this.patientId, request: { comment: result } }).subscribe(() => this.comment = result),
      () => { }
    );

    componentRef.title = "Комментарий";
    componentRef.txt = this.comment;
  }

  openRecord = (record: Record) => this.router.navigate(['./records', record.id], { relativeTo: this.activatedRoute.parent.parent });

  async openDocument(document: PersonDocument) {
    const parameters = {
      hash: document.documentHash,
      personId: this.personId.toString()
    };

    const result = await this.myService.AccessToken({ parameters }).toPromise();
    const component = this.documentPreviewService.open(`/api/v2/persons/documents/${result.token}`);

    component.onDownload.subscribe(async () => {
      const response = await this.myService.AccessToken({ parameters }).toPromise();
      window.open(`/api/v2/persons/documents/${response.token}?download=true`, "_blank");
    });
  }

  openSourceFor(diagnosis: PersonDiagnosis) {
    this.myService.AccessToken({ parameters: { id: diagnosis.sourceId.toString() } })
      .subscribe((response: AccessTokenResponse) => {
        const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
        modal.componentInstance.path = `/records/preview?token=${response.token}`;
      })
  }

  async downloadDocument(item: PersonDocument) {
    const parameters = { personId: this.personId.toString(), hash: item.documentHash };

    const response = await this.myService.AccessToken({ parameters }).toPromise();

    const iframe: HTMLIFrameElement = document.createElement("iframe");

    iframe.src = `/api/v2/persons/documents/${response.token}?download=true`
    iframe.width = "0";
    iframe.height = "0";
    iframe.style.display = "none";

    document.body.append(iframe);
  }

  async printDocuments(document: PersonDocument) {

    const response = await this.myService.AccessToken({
      parameters: {
        personId: this.personId.toString(),
        hash: document.documentHash
      }
    }).toPromise();

    this.printSource$.next([`/api/v2/persons/documents/${response.token}`]);
  }

  printRecords(records: Record[]): void {
    if (records.length === 0) return;

    for (const record of records) {
      this.myService.AccessToken({ parameters: { id: record.id.toString() } })
        .subscribe((response: AccessTokenResponse) => this.printSource$.next([`/records/preview?token=${response.token}${(record.sealed ? '&type=pdf' : '')}`]));
    }
  }

  downloadRecord = (record: Record): void => {
    this.myService.AccessToken({ parameters: { id: record.id.toString() } })
      .subscribe(
        (response: AccessTokenResponse): void => {
          const iframe: HTMLIFrameElement = document.createElement("iframe");

          iframe.src = `/records/preview?token=${response.token}&type=pdf&download=true`
          iframe.width = "0";
          iframe.height = "0";
          iframe.style.display = "none";

          document.body.append(iframe);
        }
      )
  }

  async downloadArchive(record: Record): Promise<void> {
    const token = await this.myService.AccessToken({ parameters: { id: record.id.toString() } }).toPromise();
    window.open(`api/clinic/v1/records/${record.id}/signed?token=${token.token}`, '_blank');
  }

  async removeRecords(records: Record[]): Promise<void> {
    if (records.length === 0) return;

    if (records.some(x => x.signed && !this.canRemoveSigned)) {
      this.toastrService.warning('Для удаления подписанных протоколов требуется соответствующее разрешение', 'Запрещено');
      return;
    }

    const ids: number[] = records.map(x => x.id);

    try {
      await this.recordsService.RemoveMany(ids).toPromise();

      for (const id of ids) {
        for (const visit of this.visits) {
          const index = visit.records.findIndex(x => x.id === id);

          if (index !== -1) {
            visit.records.splice(index, 1);
            break;
          }
        }
      }

      this.toastrService.success('Выбранные протоколы удалены', 'Успешно');

    } catch (e) {
      const response = e as HttpErrorResponse;

      if (response.status === 400 && response.error) {
        if (response.error === 21) {
          this.toastrService.warning('Для удаления подписанных протоколов требуется соответствующее разрешение', 'Запрещено');
          return;
        }
      }

      this.toastrService.warning('Не удалось удалить протоколы', 'Ошибка');
    }
  }

  printCover(): void {
    this.myService.AccessToken({ parameters: { id: this.patientId.toString() } })
      .subscribe((response: AccessTokenResponse) => this.printSource$.next([`/api/patients/cover?token=${response.token}`]));
  }

  printEpicrisis(): void {
    this.myService.AccessToken({ parameters: { id: this.patientId.toString() } })
      .subscribe((response: AccessTokenResponse) => this.printSource$.next([`/api/patients/epicrisis?token=${response.token}`]));
  }

  callNextInLine(): void {
    this.linesService.NextPatient().subscribe(
      () => this.toastrService.success("Следующий пациент приглашен", "Успешно"),
      () => this.toastrService.error("Сервис недоступен", "Ошибка")
    );
  }

  addDocuments(files: File[]): void {
    for (const file of files) {
      const document: PersonDocument = {
        id: -moment().valueOf(),
        status: 1,
        date: moment().valueOf(),
        title: file.name,
        documentType: 'Upload',
        authorShortName: this.userStorage.profile.userName
      };

      this.documents = [document, ...this.documents];

      this.personDocumentsService.UploadAsync({ personId: this.personId, Document: file })
        .subscribe(
          (response: AddPersonDocumentResponse) => {
            document.status = 2;

            document.documentHash = response.hash;

            this.toastrService.success(`Файл ${file.name} загружен`, 'Успешно');
          },
          (response: HttpErrorResponse) => {
            document.status = 3;

            this.toastrService.warning(`Не удалось загрузить файл ${file.name}`, 'Ошибка');
          }
        );
    }
  }

  async removeDocuments(document: PersonDocument) {
    try {
      await this.personDocumentsService.RemoveAsync({ personId: this.personId, hash: document.documentHash }).toPromise();

      this.toastrService.success(`Документ ${document.title} удален`, 'Успешно');
      this.documents = this.documents.filter(x => x.documentHash != document.documentHash)
    } catch (e) {
      this.toastrService.error(`Не удалось удалить документ ${document.title}`, 'Ошибка');
    }
  }

  updateOms(data: { result: number; outcome: number, resultDate: moment.Moment }): void {
    this.omsForm.get("result").markAsDirty();
    this.omsForm.get("outcome").markAsDirty();
    this.omsForm.get("resultDate").markAsDirty();

    this.omsForm.patchValue(data);
  }

  acceptOmsChanges(): void {

    const value: { outcome: number; result: number; resultDate: moment.Moment } = this.omsForm.getRawValue();

    this.patientsService.UpdateOmsInfo({
      id: this.patientId,
      request: {
        diseaseOutcome: value.outcome,
        therapyResult: value.result,
        therapyFinished: value.resultDate.format("DD.MM.YYYY")
      }
    }).subscribe(
      () => this.toastrService.success("Сведения о случае обновлены", "Обновлено"),
      () => this.toastrService.error("Не удалось обновить сведения о случае", "Ошибка")
    );
  }

  openAttachment(data: { id: number, testOrderId: number }): void {

    this.myService.AccessToken({ parameters: { id: data.id.toString(), testOrderId: data.testOrderId.toString() } })
      .subscribe(
        (response: AccessTokenResponse) => {
          const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
          modal.componentInstance.path = `/api/clinic/v1/observations/attachment?token=${response.token}`;
        }
      );
  }

  printAttachments(test: PersonTestResult): void {
    if (test.attachments.length === 0) return;

    from(test.attachments)
      .pipe(
        concatMap((x: PersonTestResultAttachment): Observable<AccessTokenResponse> => this.myService.AccessToken({ parameters: { id: x.id.toString() } })),
        map((response: AccessTokenResponse): string => `/api/clinic/v1/observations/attachment?token=${response.token}`),
        scan((array: string[], value: string): string[] => [...array, value], []),
      ).subscribe((value: string[]) => this.printSource$.next(value));
  }

  openReviews(record: Record): void {
    this.reviewsService.RecordReviewsAsync(record.id)
      .subscribe((reviews: RecordReviewSummary[]): void => {
        const modalRef: NgbModalRef = this.modal.open(ReviewsModalComponent, { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" });
        const componentRef: ReviewsModalComponent = modalRef.componentInstance;

        componentRef.reviews = reviews;
        componentRef.onCancel.subscribe(() => modalRef.close());

        componentRef.onAdd.subscribe((): void => {
          modalRef.close();
          this.router.navigateByUrl(`/quality-control/records/${record.id}/reviews/new`);
        });

        componentRef.onOpen.subscribe((review: RecordReviewSummary): void => {
          modalRef.close();
          this.router.navigateByUrl(`/quality-control/records/${record.id}/reviews/${review.id}`);
        });

        componentRef.onPrint.subscribe((review: RecordReviewSummary) => this.printReview(review.id));
      });
  }

  handleColorMarkError(response: HttpErrorResponse, message = '') {
    if (response.status === 400 && response.error) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Требуется разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Метка не найдена', 'Ошибка'); break;
          case 2: this.toastrService.warning('Метка пациента не найдена', 'Ошибка'); break;
          case 3: this.toastrService.warning('Пациент не найден', 'Ошибка'); break;
          case 4: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 5: this.toastrService.warning('Указанная метка уже добавлена', 'Ошибка'); break;
          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }
    } else {
      this.toastrService.error(message, 'Ошибка');
    }
  }

  canRemoveMark = (mark: PersonMark) => this.canManageMarks || (this.canAddMark && mark.authorId === this.userStorage.profile.id);

  async removeMark(mark: PersonMark) {
    if (!this.canAddMark && !this.canManageMarks) return;
    if (!this.canManageMarks && mark.authorId != this.userStorage.profile.id) return;

    const confirmed = await this.confirmationService.open({ message: `Метка "${mark.colorMarkName}" будет снята. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.personMarksService.RemovePersonMarkAsync({ id: mark.id, personId: this.personId })
      .subscribe(
        () => {
          this.toastrService.success('Метка удалена', 'Успешно');

          this.loadMarks();
        },
        (response: HttpErrorResponse) => {
          this.handleColorMarkError(response, 'Не удалось добавить метку');
        }
      );
  }

  addMark() {
    const modalRef = this.personMarkModalService.open();
    const componentRef: PersonMarkModalComponent = modalRef.componentInstance;

    componentRef.colorMarks = this.colorMarks;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((value: PersonMarkModalPayload) => {
      componentRef.processing = true;

      this.personMarksService.CreatePersonMarkAsync({ personId: this.personId, request: { colorMarkId: value.colorMarkId, description: value.description } })
        .subscribe(
          () => {
            this.toastrService.success('Метка добавлена', 'Успешно');
            componentRef.processing = false;
            modalRef.close();

            this.loadMarks();
          },
          (response: HttpErrorResponse) => {
            componentRef.processing = false;
            this.handleColorMarkError(response, 'Не удалось добавить метку');
          }
        );
    });
  }

  private printReview = (recordReviewId: number) => this.reportsUtilityService.printReport({ recordReviewId: `${recordReviewId}`, name: 'RecordReviewReport' });

  openSourceForMetric(metric: PersonMetric) {
    this.myService.AccessToken({ parameters: { id: metric.recordId.toString() } })
      .subscribe((response: AccessTokenResponse) => {
        const modal: NgbModalRef = this.modal.open(DocumentPreviewModalComponent, { size: 'lg', centered: true });
        modal.componentInstance.path = `/records/preview?token=${response.token}`;
      })
  }
}
