import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { BreakViewModel } from 'projects/Clinic/src/app/generated/models';

import * as moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'mp-schedule-break-modal',
  templateUrl: './schedule-break.modal.component.html',
  styleUrls: ['./schedule-break.modal.component.scss']
})
export class ScheduleBreakModalComponent implements OnInit {

  daysOfWeek = [
    { value: 2, text: "Понедельник" },
    { value: 4, text: "Вторник" },
    { value: 8, text: "Среда" },
    { value: 16, text: "Четверг" },
    { value: 32, text: "Пятница" },
    { value: 64, text: "Суббота" },
    { value: 1, text: "Воскресенье" },
  ];

  @Input() break: BreakViewModel = {};

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<ScheduleBreakPayload>();

  get title(): string { return this.break && this.break.id > 0 ? 'Обновление перерыва' : 'Добавление перерыва'; }

  public form = new FormGroup({
    weekParity: new FormControl("", [Validators.required]),
    days: new FormControl("", [Validators.required]),
    start: new FormControl("", [Validators.required]),
    stop: new FormControl("", [Validators.required]),
    effective: new FormControl("", [Validators.required]),
    expires: new FormControl("", [])
  });


  constructor() { }

  ngOnInit() {
    this.form.patchValue({
      ...this.break,
      effective: this.break.effective ? moment(this.break.effective, "DD.MM.YYYY") : undefined,
      expires: this.break.expires ? moment(this.break.expires, "DD.MM.YYYY") : undefined
    });
  }

  confirm() {
    Object.entries(this.form.controls).forEach((item: [string, AbstractControl]): void => item[1].markAsDirty());

    if (this.form.invalid) return;
    this.onConfirm.emit(this.form.getRawValue());
  }

  cancel = () => this.onCancel.emit();
}

export interface ScheduleBreakPayload {
  weekParity: 0 | 1 | 2 | 3;
  days: number[];
  start: string;
  stop: string;
  effective: moment.Moment;
  expires: moment.Moment;
}
