/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Role } from '../models/role';
import { CreateRoleResponse } from '../models/create-role-response';
import { CreateRoleRequest } from '../models/create-role-request';
import { RoleEditorModel } from '../models/role-editor-model';
import { UpdateRoleRequest } from '../models/update-role-request';
@Injectable({
  providedIn: 'root',
})
class RolesService extends __BaseService {
  static readonly RolesPath = '/api/Roles';
  static readonly CreatePath = '/api/Roles';
  static readonly RemoveManyPath = '/api/Roles';
  static readonly RolePath = '/api/Roles/{id}';
  static readonly UpdatePath = '/api/Roles/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RolesService.RolesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  RolesResponse(params: RolesService.RolesParams): __Observable<__StrictHttpResponse<Array<Role>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Role>>;
      })
    );
  }
  /**
   * @param params The `RolesService.RolesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Roles(params: RolesService.RolesParams): __Observable<Array<Role>> {
    return this.RolesResponse(params).pipe(
      __map(_r => _r.body as Array<Role>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: CreateRoleRequest): __Observable<__StrictHttpResponse<CreateRoleResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateRoleResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: CreateRoleRequest): __Observable<CreateRoleResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as CreateRoleResponse)
    );
  }

  /**
   * @param Id undefined
   */
  RemoveManyResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Id undefined
   */
  RemoveMany(Id?: Array<number>): __Observable<null> {
    return this.RemoveManyResponse(Id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RoleResponse(id: number): __Observable<__StrictHttpResponse<RoleEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Roles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RoleEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Role(id: number): __Observable<RoleEditorModel> {
    return this.RoleResponse(id).pipe(
      __map(_r => _r.body as RoleEditorModel)
    );
  }

  /**
   * @param params The `RolesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: RolesService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Roles/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RolesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: RolesService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RolesService {

  /**
   * Parameters for Roles
   */
  export interface RolesParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: UpdateRoleRequest;
  }
}

export { RolesService }
