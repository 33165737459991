import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmployeeCompany, Company, FiscalPrinter, DocumentPrinter } from 'projects/Clinic/src/app/generated/models';
import { ExternalConnectionsService } from 'projects/Clinic/src/app/generated/services';
import { BarcodePrinter } from 'projects/Clinic/src/app/generated/models/barcode-printer';
import { Observable } from 'rxjs';

@Component({
  selector: 'mp-employee-company-modal',
  templateUrl: './employee-company.modal.component.html',
  styleUrls: ['./employee-company.modal.component.scss']
})
export class EmployeeCompanyModalComponent implements OnInit {
  @Input() company: EmployeeCompany;
  @Input() companies: Company[];

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSubmit: EventEmitter<EmployeeCompanyPayload> = new EventEmitter<EmployeeCompanyPayload>();

  title = "Организация сотрудника";

  form: FormGroup = new FormGroup({
    hasPartner: new FormControl(false),
    primary: new FormControl(false),
    receivingPatients: new FormControl(false),
    companyId: new FormControl(null, [Validators.required]),
    cashboxId: new FormControl(null),
    barcodePrinterId: new FormControl(null),
    documentPrinterId: new FormControl(null)
  });

  cashboxes$: Observable<FiscalPrinter[]>;
  barcodePrinters$: Observable<BarcodePrinter[]>;
  documentPrinters$: Observable<DocumentPrinter[]>;

  constructor(private connectionsService: ExternalConnectionsService) {
    this.form.get('companyId').valueChanges
      .subscribe(
        (companyId: number): void => {
          this.cashboxes$ = this.connectionsService.Cashboxes(companyId);
        }
      );
  }

  ngOnInit() {
    this.form.patchValue(this.company, { emitEvent: false });

    if (this.company.id) {
      this.form.get("companyId").disable({ emitEvent: false });
    }

    this.cashboxes$ = this.connectionsService.Cashboxes(this.company.companyId);
    this.barcodePrinters$ = this.connectionsService.BarcodePrinters();
    this.documentPrinters$ = this.connectionsService.DocumentPrinters();
  }

  submit(): void {
    this.form.get('companyId').markAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.onSubmit.emit(this.form.getRawValue());
  }

  cancel(): void {
    this.onCancel.emit();
  }

}

export interface EmployeeCompanyPayload {
  companyId: number;
  cashboxId: number;
  barcodePrinterId: number;
  documentPrinterId: number;
  primary: boolean;
  hasPartner: boolean;
  receivingPatients: boolean;
}
