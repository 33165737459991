import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { from, Observable, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PatientSearchResult } from '../../../../generated/models';
import { PatientsService } from '../../../../generated/services';
import { ActiveCallService } from '../../../active-call/services/active-call-service';

@Component({
  selector: 'mp-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
  host: { class: 'page' }
})
export class CallComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = false;

  title = 'Входящий звонок';
  patients: PatientSearchResult[] = [];

  filters = new FormGroup({
    search: new FormControl("")
  });

  constructor(private activatedRoute: ActivatedRoute,
    private patientsService: PatientsService,
    private activeCallService: ActiveCallService) {
    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => { this.loading = true; }),
        switchMap((value: Filters): Observable<PatientSearchResult[]> => {
          if (!value.search) {
            return from([]);
          }

          return this.patientsService.Search({ Page: 1, Size: 900, Search: value.search }).pipe(
            map(response => response.items),
            catchError((e) => {
              console.error(e);
              return from([]);
            }));
        }),
        tap(() => { this.loading = false; })
      )
      .subscribe((response: PatientSearchResult[]) => {
        this.patients = response;
      });
  }

  ngOnInit() {
    const phone = this.activatedRoute.snapshot.queryParamMap.get('phone');
    this.title = `Входящий звонок ${phone}`;
    this.filters.patchValue({ search: phone });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  select(patient: PatientSearchResult) { }
}

interface Filters {
  search: string;
}
