import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { Attending, Company, Speciality } from '../../../generated/models';
import { CompaniesService, EmployeesService, SchedulesService, SpecialitiesService } from '../../../generated/services';

import * as moment from 'moment';

import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class AttendanceResolver implements Resolve<AttendancePayload> {
  public constructor(private companiesService: CompaniesService,
    private employeesService: EmployeesService,
    private specialitiesService: SpecialitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AttendancePayload> {
    return forkJoin({
      companies: this.companiesService.Companies({ Type: 4, Date: moment().format('DD.MM.YYYY') }),
      doctors: this.employeesService.Attendings({ Page: 1, Size: 999 }).pipe(map(x => x.items)),
      specialities: this.specialitiesService.Specialities({ Page: 1, Size: 999 }).pipe(map(x => x.items))
    });
  }
}

export interface AttendancePayload {
  companies: Company[];
  doctors: Attending[];
  specialities: Speciality[];
}

