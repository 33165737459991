import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Attending, Company, Speciality } from '../../../../generated/models';
import { ReviewsFiltersModalComponent } from '../reviews-filters-modal/reviews-filters-modal.component';
import { ReviewsFilters } from '../reviews/reviews.component';

@Component({
  selector: 'mp-reviews-filters',
  templateUrl: './reviews-filters.component.html',
  styleUrls: ['./reviews-filters.component.scss']
})
export class ReviewsFiltersComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() companies: Company[] = [];
  @Input() specialities: Speciality[] = [];
  @Input() doctors: Attending[] = [];

  @Input() canReviewRecords = false;

  @Input()
  filters: ReviewsFilters = {};

  @Output()
  onFiltersChanged: EventEmitter<ReviewsFilters> = new EventEmitter<ReviewsFilters>();

  advancedSearch = false;

  form = new FormGroup({
    companies: new FormControl([]),
    specialities: new FormControl([]),
    doctors: new FormControl([]),
    patientName: new FormControl(""),
    patientDob: new FormControl(undefined),
    patientGender: new FormControl(0),
    visitDate: new FormControl(undefined),
    onlyClosed: new FormControl(true),
    onlyReviewed: new FormControl(false),
    onlyUnrevised: new FormControl(false),
    onlyDrafts: new FormControl(false),
    isPreventive: new FormControl(false),
    reviewDate: new FormControl(undefined),
    diagnosis: new FormControl('')
  });

  constructor(private modal: NgbModal) {
    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(500)
      )
      .subscribe((value: ReviewsFilters): void => {
        this.onFiltersChanged.emit(this.form.getRawValue());
      });
  }

  ngOnInit() {
    this.form.patchValue(this.filters);

    if (!this.canReviewRecords) {
      this.form.get('doctors').disable({ emitEvent: false });
      this.form.get('onlyReviewed').disable({ emitEvent: false });
      this.form.get('onlyDrafts').disable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  toggleAdavancedSearch(): void {
    this.advancedSearch = !this.advancedSearch;
  }

  openAdvancedSearch() {
    const modalRef = this.modal.open(ReviewsFiltersModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: ReviewsFiltersModalComponent = modalRef.componentInstance;

    componentRef.companies = this.companies;
    componentRef.specialities = this.specialities;
    componentRef.doctors = this.doctors;
    componentRef.filters = this.form.getRawValue();

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((value: ReviewsFilters) => {
      this.form.patchValue(value);

      modalRef.close();
    })
  }
}
