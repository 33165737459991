import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceCompany } from '../../../../generated/models';

@Component({
  selector: 'mp-service-company',
  templateUrl: './service-company.component.html',
  styleUrls: ['./service-company.component.scss']
})
export class ServiceCompanyComponent implements OnInit {

  @Input() item: ServiceCompany;

  @Output() onToggle = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  toggle = () => this.onToggle.emit();

}
