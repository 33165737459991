/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AppointmentHistoryResponse } from '../models/appointment-history-response';
@Injectable({
  providedIn: 'root',
})
class ScheduleHistoryService extends __BaseService {
  static readonly HistoryAsyncPath = '/api/schedules/{scheduleId}/history';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScheduleHistoryService.HistoryAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `ReservedTill`:
   *
   * - `ReservedByName`:
   *
   * - `ReservedById`:
   *
   * - `Date`:
   *
   * @return Success
   */
  HistoryAsyncResponse(params: ScheduleHistoryService.HistoryAsyncParams): __Observable<__StrictHttpResponse<AppointmentHistoryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.ReservedTill != null) __params = __params.set('ReservedTill', params.ReservedTill.toString());
    if (params.ReservedByName != null) __params = __params.set('ReservedByName', params.ReservedByName.toString());
    if (params.ReservedById != null) __params = __params.set('ReservedById', params.ReservedById.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/schedules/${params.scheduleId}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentHistoryResponse>;
      })
    );
  }
  /**
   * @param params The `ScheduleHistoryService.HistoryAsyncParams` containing the following parameters:
   *
   * - `scheduleId`:
   *
   * - `ReservedTill`:
   *
   * - `ReservedByName`:
   *
   * - `ReservedById`:
   *
   * - `Date`:
   *
   * @return Success
   */
  HistoryAsync(params: ScheduleHistoryService.HistoryAsyncParams): __Observable<AppointmentHistoryResponse> {
    return this.HistoryAsyncResponse(params).pipe(
      __map(_r => _r.body as AppointmentHistoryResponse)
    );
  }
}

module ScheduleHistoryService {

  /**
   * Parameters for HistoryAsync
   */
  export interface HistoryAsyncParams {
    scheduleId: number;
    ReservedTill?: number;
    ReservedByName?: string;
    ReservedById?: number;
    Date?: string;
  }
}

export { ScheduleHistoryService }
