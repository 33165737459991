"use strict";

import { DiagnosisType } from "./diagnosis.type";

import * as MomentJs from "moment";

/**
 Поставленный диагноз
 */
export class Diagnosis {
  private _id: number;
  private _icd10Id: number;
  private _text: string;
  private _type: DiagnosisType;
  private _code: string;
  private _established: MomentJs.Moment;

  private _icd10Name: string;

  private _doctorId: number;
  private _doctorFullName: string;
  private _doctoShortName: string;

  private _documentId: number;
  private _documentTitle: string

  public constructor() {

  }

  public get id(): number { return this._id; }
  public set id(value: number) { this._id = value; }

  public get icd10Id(): number { return this._icd10Id; }
  public set icd10Id(value: number) { this._icd10Id = value; }

  public get text(): string { return this._text; }
  public set text(value: string) { this._text = value; }

  public get type(): DiagnosisType { return this._type; }
  public set type(value: DiagnosisType) { this._type = value; }

  public get code(): string { return this._code; }
  public set code(value: string) { this._code = value; }

  public get established(): MomentJs.Moment { return this._established; }
  public set established(value: MomentJs.Moment) { this._established = value; }

  public get icd10Name(): string { return this._icd10Name; }
  public set icd10Name(value: string) { this._icd10Name = value; }

  public get doctorId(): number { return this._doctorId; }
  public set doctorId(value: number) { this._doctorId = value; }

  public get doctorFullName(): string { return this._doctorFullName; }
  public set doctorFullName(value: string) { this._doctorFullName = value; }

  public get doctorShortName(): string { return this._doctoShortName; }
  public set doctorShortName(value: string) { this._doctoShortName = value; }

  public get documentId(): number { return this._documentId; }
  public set documentId(value: number) { this._documentId = value; }

  public get documentTitle(): string { return this._documentTitle; }
  public set documentTitle(value: string) { this._documentTitle = value; }

  public get displayDate(): string { return (!!this.established) ? this._established.format("DD.MM.YY") : ""; }
  public get displayType(): string {
    switch (this._type) {
      case DiagnosisType.Preliminary:
        return "Предварительный";
      case DiagnosisType.Primary:
        return "Основной";
      case DiagnosisType.Coexisting:
        return "Сопутствующий";
      case DiagnosisType.Sequela:
        return "Осложнение";
      default: return "";
    }
  }

  public static create = (data: any): Diagnosis => {
    const model: Diagnosis = new Diagnosis();
    model.id = data.Id;
    model.icd10Id = data.Icd10Id;
    model.text = data.Text;
    model.type = data.Type;
    model.code = data.Code
    model.icd10Name = data.Icd10Name;
    model.established = (!!data.Established) ? MomentJs.unix(data.Established) : undefined;

    model.doctorId = data.DoctorId;
    model.doctorFullName = data.DoctorFullName;
    model.doctorShortName = data.DoctorShortName;

    model.documentId = data.DocumentId;
    model.documentTitle = data.DocumentTitle;

    return model;
  }

  public static pack = (model: Diagnosis): any => {
    const data: any = {};

    data.Id = model.id;
    data.Icd10Id = model.icd10Id;
    data.Text = model.text;
    data.Type = model.type;

    return data;
  }
}
