import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecordReviewDefectDescription } from '../../../../generated/models';

@Component({
  selector: 'mp-review-defects',
  templateUrl: './review-defects.component.html',
  styleUrls: ['./review-defects.component.scss']
})
export class ReviewDefectsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() description: RecordReviewDefectDescription;

  @Input() readonly = true;

  @Input() set defects(value: Array<0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192>) {
    if (!value) {
      this.form.reset();
      return;
    }

    const defects: FormValue = {
      failureToComply: value.some(x => x === 1),
      lackOfTools: value.some(x => x === 2),
      incompleteExamination: value.some(x => x === 4),
      objectiveDifficultiesInDiagnosis: value.some(x => x === 8),
      wrongConsultant: value.some(x => x === 16),
      objectiveDifficultiesOfTreatment: value.some(x => x === 32),
      lackOfContinuity: value.some(x => x === 64),
      insufficientQualifications: value.some(x => x === 128),
      other: value.some(x => x === 256),
      returnDateUnset: value.some(x => x === 512),
      returnDateNotJustified: value.some(x => x === 1024),
      notSentToAnotherSpecialist: value.some(x => x === 2048),

      testsNotOrdered: value.some(x => x === 4096),
      ultrasoundNotOrdered: value.some(x => x === 8192),
    };

    this.form.patchValue(defects, { emitEvent: false });
  }

  @Output()
  onDefectsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  form = new FormGroup({
    failureToComply: new FormControl(false),
    lackOfTools: new FormControl(false),
    incompleteExamination: new FormControl(false),
    objectiveDifficultiesInDiagnosis: new FormControl(false),
    wrongConsultant: new FormControl(false),
    objectiveDifficultiesOfTreatment: new FormControl(false),
    lackOfContinuity: new FormControl(false),
    insufficientQualifications: new FormControl(false),
    returnDateUnset: new FormControl(false),
    returnDateNotJustified: new FormControl(false),
    notSentToAnotherSpecialist: new FormControl(false),
    other: new FormControl(false),
    testsNotOrdered: new FormControl(false),
    ultrasoundNotOrdered: new FormControl(false),
  });

  names = ['failureToComply', 'lackOfTools', 'incompleteExamination',
    'objectiveDifficultiesInDiagnosis', 'wrongConsultant',
    'objectiveDifficultiesOfTreatment', 'lackOfContinuity',
    'insufficientQualifications', /*'returnDateUnset',
    'returnDateNotJustified', */'notSentToAnotherSpecialist',
    'testsNotOrdered', 'ultrasoundNotOrdered',
    'other'];

  constructor() {
    this.form.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((value: FormValue): void => {
        const defects: number[] = [];

        if (value.failureToComply) {
          defects.push(1);
        }

        if (value.lackOfTools) {
          defects.push(2);
        }

        if (value.incompleteExamination) {
          defects.push(4);
        }

        if (value.objectiveDifficultiesInDiagnosis) {
          defects.push(8);
        }

        if (value.wrongConsultant) {
          defects.push(16);
        }

        if (value.objectiveDifficultiesOfTreatment) {
          defects.push(32);
        }

        if (value.lackOfContinuity) {
          defects.push(64);
        }

        if (value.insufficientQualifications) {
          defects.push(128);
        }

        if (value.other) {
          defects.push(256);
        }

        if (value.returnDateUnset) {
          defects.push(512);
        }

        if (value.returnDateNotJustified) {
          defects.push(1024);
        }

        if (value.notSentToAnotherSpecialist) {
          defects.push(2048);
        }

        if (value.testsNotOrdered) {
          defects.push(4096);
        }

        if (value.ultrasoundNotOrdered) {
          defects.push(8192);
        }

        this.onDefectsChange.emit(defects);
      });

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  toggle(name: string): void {
    if (this.readonly) return;

    this.form.controls[name].setValue(!this.form.controls[name].value);
  }
}

interface FormValue {
  failureToComply: boolean;
  lackOfTools: boolean;
  incompleteExamination: boolean;
  objectiveDifficultiesInDiagnosis: boolean;
  wrongConsultant: boolean;
  objectiveDifficultiesOfTreatment: boolean;
  lackOfContinuity: boolean;
  insufficientQualifications: boolean;
  returnDateUnset: boolean;
  returnDateNotJustified: boolean;
  notSentToAnotherSpecialist: boolean;
  other: boolean;

  testsNotOrdered: boolean;
  ultrasoundNotOrdered: boolean;
}
