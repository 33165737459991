import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserStorage } from '../services/user-storage';
import { map, take, tap } from 'rxjs/operators';
import { Profile, PermissionInCompany } from '../generated/models';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate, CanActivateChild, OnDestroy {
  public constructor(
    private userStorage: UserStorage,
    private router: Router,
    private toastrService: ToastrService
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.check(next)
      .pipe(
        map((value: boolean): boolean | UrlTree => {
          if (!value) {
            return this.router.createUrlTree(["/"]);
          }

          return true;
        })
      );
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.check(next)
      .pipe(
        map((value: boolean): boolean | UrlTree => {
          if (!value) {
            return this.router.createUrlTree(["/"]);
          }

          return true;
        })
      );
  }

  private check(activatedRoute: ActivatedRouteSnapshot): Observable<boolean> {
    const requirements: string[] | string[][] = activatedRoute.data["requirements"] || [];

    if (!requirements || requirements.length === 0) {
      return of(true);
    }

    return this.userStorage.profileSource
      .pipe(
        map((profile: Profile): boolean => {
          const permissions: string[] = profile.permissions
            .filter((x: PermissionInCompany): boolean => !x.companyId || x.companyId === profile.companyId)
            .map((x: PermissionInCompany): string => x.name);

          for (const item of requirements) {
            if (typeof item === "string" && !permissions.includes(item)) {
              return false;
            }

            if (Array.isArray(item) && !item.some((x: string): boolean => permissions.includes(x))) {
              return false;
            }
          }

          return true;
        }),
        tap((value: boolean): void => {
          if (!value) {
            this.toastrService.error("", "Доступ запрещен");
          }
        })
      );
  }

  ngOnDestroy(): void { }

}
