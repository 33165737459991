import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { ToastrModule } from 'ngx-toastr';
import { PermissionNames } from '../../models/permission-names';
import { ExternalConnectionResolver } from './resolvers/external-connection.resolver';
import { ManageExternalConnectionModalComponent } from './components/manage-external-connection.modal/manage-external-connection.modal.component';
import { ExternalConnectionsComponent } from './components/external-connections/external-connections.component';
import { ExternalConnectionComponent } from './components/external-connection/external-connection.component';
import { ExternalConnectionsListItemComponent } from './components/external-connections-list-item/external-connections-list-item.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: ExternalConnectionResolver
    },
    component: ExternalConnectionComponent,
    data: {
      title: "Новый канал привлечения пациентов",
      requirements: [PermissionNames.EditExternalConnections]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: ExternalConnectionResolver
    },
    component: ExternalConnectionComponent,
    data: {
      title: "Внешняя система",
      requirements: [PermissionNames.EditExternalConnections]
    }
  },
  {
    path: "",
    component: ExternalConnectionsComponent,
    data: {
      title: "Внешние системы",
      requirements: [PermissionNames.EditExternalConnections]
    }
  }
];

@NgModule({
  declarations: [
    ExternalConnectionComponent,
    ExternalConnectionsComponent,
    ManageExternalConnectionModalComponent,
    ExternalConnectionsListItemComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    SharedModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot({}),
    ToastrModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManageExternalConnectionModalComponent
  ]
})
export class ExternalConnectionSettingsModule { }
