import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, from, Observable } from "rxjs";
import { ColorMark } from "../../../generated/models";
import { ColorMarksService } from "../../../generated/services";

@Injectable({
  providedIn: "root"
})
export class ColorMarkResolver implements Resolve<ColorMarkResolverPayload> {
  constructor(private service: ColorMarksService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ColorMarkResolverPayload> {
    const id = parseInt(route.paramMap.get('id'));
    let markLoader: Observable<ColorMark> = from([{}]);

    if (id > 0) {
      markLoader = this.service.ColorMark(id);
    }

    return forkJoin({
      colorMark: markLoader
    });
  }
}

export interface ColorMarkResolverPayload {
  colorMark: ColorMark
}
