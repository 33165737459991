/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Patient } from '../models/patient';
import { RemotePatient } from '../models/remote-patient';
import { PersonSearchRequest } from '../models/person-search-request';
import { ClosedCase } from '../models/closed-case';
import { PatientSearchResponse } from '../models/patient-search-response';
import { Document } from '../models/document';
import { PatientDiagnosis } from '../models/patient-diagnosis';
import { PatientConsultation } from '../models/patient-consultation';
import { TestResult } from '../models/test-result';
import { PatientPrescription } from '../models/patient-prescription';
import { AvailableDiscount } from '../models/available-discount';
import { PatientObservation } from '../models/patient-observation';
import { CreatePatientResponse } from '../models/create-patient-response';
import { CreatePatientRequest } from '../models/create-patient-request';
import { LinkWithAccountRequest } from '../models/link-with-account-request';
import { EditCommentRequest } from '../models/edit-comment-request';
import { UpdatePatientOmsInfoRequest } from '../models/update-patient-oms-info-request';
import { PatientReportResponse } from '../models/patient-report-response';
import { ShareQuizRunRequest } from '../models/share-quiz-run-request';
import { AddRepresentativeRequest } from '../models/add-representative-request';
@Injectable({
  providedIn: 'root',
})
class PatientsService extends __BaseService {
  static readonly PatientPath = '/api/Patients/{id}';
  static readonly PersonPath = '/api/Patients/person';
  static readonly PatientForVisitPath = '/api/visits/{visitId}/patient';
  static readonly PatientForConsultationPath = '/api/consultations/{contractItemId}/patient';
  static readonly PatientForRecordAsyncPath = '/api/v1/records/{recordId}/patient';
  static readonly ClosedPath = '/api/Patients/closed';
  static readonly FindByContractAsyncPath = '/api/Patients/contracts/{contract}';
  static readonly SearchPath = '/api/Patients/search';
  static readonly DocumentsPath = '/api/Patients/{id}/documents';
  static readonly DiagnosesPath = '/api/Patients/{id}/diagnosis';
  static readonly ConsultationsPath = '/api/Patients/{id}/consultations';
  static readonly ResultsPath = '/api/Patients/{id}/results';
  static readonly PrescriptionsPath = '/api/Patients/{id}/prescriptions';
  static readonly DiscountsPath = '/api/Patients/{id}/discounts';
  static readonly ObservationsAsyncPath = '/api/Patients/{id}/observations';
  static readonly CreateAsyncPath = '/api/Patients';
  static readonly LinkWithAccountAsyncPath = '/api/Patients/{id}/account';
  static readonly EditCommentPath = '/api/Patients/{id}/comment';
  static readonly UpdateOmsInfoPath = '/api/Patients/{id}/oms';
  static readonly EpicrisisPath = '/api/Patients/epicrisis';
  static readonly CoverPath = '/api/Patients/cover';
  static readonly PatientsCoverPath = '/api/Patients/{id}/cover.{type}';
  static readonly ShareQuizRunPath = '/api/Patients/{id}/quizruns/share';
  static readonly AddRepresentativePath = '/api/Patients/{id}/representative';
  static readonly RemoveRepresentativePath = '/api/Patients/{id}/representative';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  PatientResponse(id: number): __Observable<__StrictHttpResponse<Patient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Patient>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Patient(id: number): __Observable<Patient> {
    return this.PatientResponse(id).pipe(
      __map(_r => _r.body as Patient)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  PersonResponse(request?: PersonSearchRequest): __Observable<__StrictHttpResponse<RemotePatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients/person`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemotePatient>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Person(request?: PersonSearchRequest): __Observable<RemotePatient> {
    return this.PersonResponse(request).pipe(
      __map(_r => _r.body as RemotePatient)
    );
  }

  /**
   * @param visitId undefined
   * @return Success
   */
  PatientForVisitResponse(visitId: number): __Observable<__StrictHttpResponse<Patient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/visits/${visitId}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Patient>;
      })
    );
  }
  /**
   * @param visitId undefined
   * @return Success
   */
  PatientForVisit(visitId: number): __Observable<Patient> {
    return this.PatientForVisitResponse(visitId).pipe(
      __map(_r => _r.body as Patient)
    );
  }

  /**
   * @param contractItemId undefined
   * @return Success
   */
  PatientForConsultationResponse(contractItemId: number): __Observable<__StrictHttpResponse<Patient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/consultations/${contractItemId}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Patient>;
      })
    );
  }
  /**
   * @param contractItemId undefined
   * @return Success
   */
  PatientForConsultation(contractItemId: number): __Observable<Patient> {
    return this.PatientForConsultationResponse(contractItemId).pipe(
      __map(_r => _r.body as Patient)
    );
  }

  /**
   * @param recordId undefined
   * @return Success
   */
  PatientForRecordAsyncResponse(recordId: number): __Observable<__StrictHttpResponse<Patient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/records/${recordId}/patient`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Patient>;
      })
    );
  }
  /**
   * @param recordId undefined
   * @return Success
   */
  PatientForRecordAsync(recordId: number): __Observable<Patient> {
    return this.PatientForRecordAsyncResponse(recordId).pipe(
      __map(_r => _r.body as Patient)
    );
  }

  /**
   * @param params The `PatientsService.ClosedParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  ClosedResponse(params: PatientsService.ClosedParams): __Observable<__StrictHttpResponse<Array<ClosedCase>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/closed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClosedCase>>;
      })
    );
  }
  /**
   * @param params The `PatientsService.ClosedParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Closed(params: PatientsService.ClosedParams): __Observable<Array<ClosedCase>> {
    return this.ClosedResponse(params).pipe(
      __map(_r => _r.body as Array<ClosedCase>)
    );
  }

  /**
   * @param contract undefined
   * @return Success
   */
  FindByContractAsyncResponse(contract: number): __Observable<__StrictHttpResponse<Patient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/contracts/${contract}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Patient>;
      })
    );
  }
  /**
   * @param contract undefined
   * @return Success
   */
  FindByContractAsync(contract: number): __Observable<Patient> {
    return this.FindByContractAsyncResponse(contract).pipe(
      __map(_r => _r.body as Patient)
    );
  }

  /**
   * @param params The `PatientsService.SearchParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  SearchResponse(params: PatientsService.SearchParams): __Observable<__StrictHttpResponse<PatientSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatientSearchResponse>;
      })
    );
  }
  /**
   * @param params The `PatientsService.SearchParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Search(params: PatientsService.SearchParams): __Observable<PatientSearchResponse> {
    return this.SearchResponse(params).pipe(
      __map(_r => _r.body as PatientSearchResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DocumentsResponse(id: number): __Observable<__StrictHttpResponse<Array<Document>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Document>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Documents(id: number): __Observable<Array<Document>> {
    return this.DocumentsResponse(id).pipe(
      __map(_r => _r.body as Array<Document>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DiagnosesResponse(id: number): __Observable<__StrictHttpResponse<Array<PatientDiagnosis>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/diagnosis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PatientDiagnosis>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Diagnoses(id: number): __Observable<Array<PatientDiagnosis>> {
    return this.DiagnosesResponse(id).pipe(
      __map(_r => _r.body as Array<PatientDiagnosis>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ConsultationsResponse(id: number): __Observable<__StrictHttpResponse<Array<PatientConsultation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PatientConsultation>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Consultations(id: number): __Observable<Array<PatientConsultation>> {
    return this.ConsultationsResponse(id).pipe(
      __map(_r => _r.body as Array<PatientConsultation>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ResultsResponse(id: number): __Observable<__StrictHttpResponse<Array<TestResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TestResult>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Results(id: number): __Observable<Array<TestResult>> {
    return this.ResultsResponse(id).pipe(
      __map(_r => _r.body as Array<TestResult>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PrescriptionsResponse(id: number): __Observable<__StrictHttpResponse<Array<PatientPrescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PatientPrescription>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Prescriptions(id: number): __Observable<Array<PatientPrescription>> {
    return this.PrescriptionsResponse(id).pipe(
      __map(_r => _r.body as Array<PatientPrescription>)
    );
  }

  /**
   * @param params The `PatientsService.DiscountsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  DiscountsResponse(params: PatientsService.DiscountsParams): __Observable<__StrictHttpResponse<Array<AvailableDiscount>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${params.id}/discounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableDiscount>>;
      })
    );
  }
  /**
   * @param params The `PatientsService.DiscountsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Discounts(params: PatientsService.DiscountsParams): __Observable<Array<AvailableDiscount>> {
    return this.DiscountsResponse(params).pipe(
      __map(_r => _r.body as Array<AvailableDiscount>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ObservationsAsyncResponse(id: number): __Observable<__StrictHttpResponse<Array<PatientObservation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/${id}/observations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PatientObservation>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ObservationsAsync(id: number): __Observable<Array<PatientObservation>> {
    return this.ObservationsAsyncResponse(id).pipe(
      __map(_r => _r.body as Array<PatientObservation>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAsyncResponse(request?: CreatePatientRequest): __Observable<__StrictHttpResponse<CreatePatientResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreatePatientResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAsync(request?: CreatePatientRequest): __Observable<CreatePatientResponse> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as CreatePatientResponse)
    );
  }

  /**
   * @param params The `PatientsService.LinkWithAccountAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  LinkWithAccountAsyncResponse(params: PatientsService.LinkWithAccountAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients/${params.id}/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PatientsService.LinkWithAccountAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  LinkWithAccountAsync(params: PatientsService.LinkWithAccountAsyncParams): __Observable<null> {
    return this.LinkWithAccountAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PatientsService.EditCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditCommentResponse(params: PatientsService.EditCommentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Patients/${params.id}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PatientsService.EditCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditComment(params: PatientsService.EditCommentParams): __Observable<null> {
    return this.EditCommentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PatientsService.UpdateOmsInfoParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateOmsInfoResponse(params: PatientsService.UpdateOmsInfoParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Patients/${params.id}/oms`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PatientsService.UpdateOmsInfoParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateOmsInfo(params: PatientsService.UpdateOmsInfoParams): __Observable<null> {
    return this.UpdateOmsInfoResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Token undefined
   */
  EpicrisisResponse(Token?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Token != null) __params = __params.set('Token', Token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/epicrisis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Token undefined
   */
  Epicrisis(Token?: string): __Observable<null> {
    return this.EpicrisisResponse(Token).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Token undefined
   */
  CoverResponse(Token?: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Token != null) __params = __params.set('Token', Token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Patients/cover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Token undefined
   */
  Cover(Token?: string): __Observable<null> {
    return this.CoverResponse(Token).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PatientsService.PatientsCoverParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `id`:
   *
   * @return Success
   */
  PatientsCoverResponse(params: PatientsService.PatientsCoverParams): __Observable<__StrictHttpResponse<PatientReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients/${params.id}/cover.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PatientReportResponse>;
      })
    );
  }
  /**
   * @param params The `PatientsService.PatientsCoverParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `id`:
   *
   * @return Success
   */
  PatientsCover(params: PatientsService.PatientsCoverParams): __Observable<PatientReportResponse> {
    return this.PatientsCoverResponse(params).pipe(
      __map(_r => _r.body as PatientReportResponse)
    );
  }

  /**
   * @param params The `PatientsService.ShareQuizRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ShareQuizRunResponse(params: PatientsService.ShareQuizRunParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients/${params.id}/quizruns/share`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PatientsService.ShareQuizRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ShareQuizRun(params: PatientsService.ShareQuizRunParams): __Observable<null> {
    return this.ShareQuizRunResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PatientsService.AddRepresentativeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddRepresentativeResponse(params: PatientsService.AddRepresentativeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Patients/${params.id}/representative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PatientsService.AddRepresentativeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddRepresentative(params: PatientsService.AddRepresentativeParams): __Observable<null> {
    return this.AddRepresentativeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveRepresentativeResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Patients/${id}/representative`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveRepresentative(id: number): __Observable<null> {
    return this.RemoveRepresentativeResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PatientsService {

  /**
   * Parameters for Closed
   */
  export interface ClosedParams {
    To?: string;
    From?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for Search
   */
  export interface SearchParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Discounts
   */
  export interface DiscountsParams {
    id: number;
    Date?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for LinkWithAccountAsync
   */
  export interface LinkWithAccountAsyncParams {
    id: number;
    request?: LinkWithAccountRequest;
  }

  /**
   * Parameters for EditComment
   */
  export interface EditCommentParams {
    id: number;
    request?: EditCommentRequest;
  }

  /**
   * Parameters for UpdateOmsInfo
   */
  export interface UpdateOmsInfoParams {
    id: number;
    request?: UpdatePatientOmsInfoRequest;
  }

  /**
   * Parameters for PatientsCover
   */
  export interface PatientsCoverParams {
    type: string;
    id: number;
  }

  /**
   * Parameters for ShareQuizRun
   */
  export interface ShareQuizRunParams {
    id: number;
    request?: ShareQuizRunRequest;
  }

  /**
   * Parameters for AddRepresentative
   */
  export interface AddRepresentativeParams {
    id: number;
    request?: AddRepresentativeRequest;
  }
}

export { PatientsService }
