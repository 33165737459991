import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnairesService } from 'projects/Clinic/src/app/generated/services';

@Component({
  selector: 'mp-questionnaire-question-modal',
  templateUrl: './questionnaire-question.modal.component.html',
  styleUrls: ['./questionnaire-question.modal.component.scss']
})
export class QuestionnaireQuestionModalComponent implements OnInit {

  @Input("questionnaireId") questionnaireId: number;
  @Input("mode") mode: number;
  @Input("questionId") questionId: number;
  @Input("title") title: string;
  @Input("description") description: string;
  @Input("isText") isText: boolean;
  @Input("number") number: boolean;

  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private questionnairesService: QuestionnairesService
  ) {
    // this.name = "Новый вопрос";

    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      description: new FormControl(""),
      isText: new FormControl(""),
      number: new FormControl("", [Validators.required])
    });
  }

  ngOnInit() {
    this.form.controls["name"].setValue(this.title);
    this.form.controls["description"].setValue(this.description);
    this.form.controls["isText"].setValue(this.isText);
    this.form.controls["number"].setValue(this.number);
  }

  public submit(): void {
    Object.entries(this.form.controls)
      .map((item: [string, AbstractControl]): AbstractControl => item[1])
      .forEach((control: AbstractControl): void => {
        control.markAsDirty();
      });

    if (this.form.invalid) {
      return;
    }
    this.questionnairesService.AddQuestion({
      questionnaireId: this.questionnaireId,
      model: {
        title: this.form.get("name").value,
        description: this.form.get("description").value,
        isText: this.form.get("isText").value,
        number: this.form.get("number").value
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public edit(): void {
    if (this.form.invalid) {
      return;
    }
    this.questionnairesService.EditQuestion({
      questionnaireId: this.questionnaireId,
      model: {
        id: this.questionId,
        title: this.form.get("name").value,
        description: this.form.get("description").value,
        isText: this.form.get("isText").value,
        number: this.form.get("number").value
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public delete(): void {
    this.questionnairesService.DeleteQuestion({
      questionnaireId: this.questionnaireId,
      model: {
        id: this.questionId
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
