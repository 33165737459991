/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ManipulationRecommendation } from '../models/manipulation-recommendation';
@Injectable({
  providedIn: 'root',
})
class ManipulationRecommendationsService extends __BaseService {
  static readonly RecommendationsAsyncPath = '/api/manipulations/{manipulationId}/recommendations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param manipulationId undefined
   * @return Success
   */
  RecommendationsAsyncResponse(manipulationId: number): __Observable<__StrictHttpResponse<Array<ManipulationRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/manipulations/${manipulationId}/recommendations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManipulationRecommendation>>;
      })
    );
  }
  /**
   * @param manipulationId undefined
   * @return Success
   */
  RecommendationsAsync(manipulationId: number): __Observable<Array<ManipulationRecommendation>> {
    return this.RecommendationsAsyncResponse(manipulationId).pipe(
      __map(_r => _r.body as Array<ManipulationRecommendation>)
    );
  }
}

module ManipulationRecommendationsService {
}

export { ManipulationRecommendationsService }
