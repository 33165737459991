import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Metric } from '../../../../generated/models';

@Component({
  selector: 'mp-metric-list-item',
  templateUrl: './metric-list-item.component.html',
  styleUrls: ['./metric-list-item.component.scss']
})
export class MetricListItemComponent implements OnInit {

  @Input() item: Metric = {};

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();
}
