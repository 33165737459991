import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Company, ReportEmployee, ReportPromotion, ReportDiscount, ReportService, ReportGroup } from '../../../generated/models';
import { CompaniesService, MyService, ReportsService } from '../../../generated/services';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable({ providedIn: "root" })
export class ReportsResolver implements Resolve<ReportsPayload> {

  public constructor(
    private companiesService: CompaniesService,
    private reportsService: ReportsService,
    private myService: MyService
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReportsPayload> {
    const companiesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: 4, Date: moment().format('DD.MM.YYYY') });
    const employeesLoader: Observable<ReportEmployee[]> = this.reportsService.ReportEmployees();
    const promotionsloader: Observable<ReportPromotion[]> = this.reportsService.ReportPromotions();
    const discountsLoader: Observable<ReportDiscount[]> = this.reportsService.ReportDiscounts();
    const servicesLoader: Observable<ReportService[]> = this.reportsService.ReportServices();
    const groupsLoader: Observable<ReportGroup[]> = this.myService.AvailableReportGroups();
    const filterCompaniesLoader: Observable<Company[]> = this.companiesService.Companies({ Date: moment().format('DD.MM.YYYY') })
      .pipe(map(x => x.filter(c => [1, 3, 5].includes(c.type))));

    return forkJoin({
      companies: companiesLoader,
      employees: employeesLoader,
      promotions: promotionsloader,
      discounts: discountsLoader,
      services: servicesLoader,
      customers: filterCompaniesLoader,
      groups: groupsLoader
    });
  }

}

export interface ReportsPayload {
  companies: Company[];
  employees: ReportEmployee[];
  promotions: ReportPromotion[];
  discounts: ReportDiscount[];
  services: ReportService[];
  customers: Company[];
  groups: ReportGroup[];
}
