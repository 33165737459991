import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { ExternalConnectionsService } from '../../../generated/services';
import { ExternalConnectionResponse } from '../../../generated/models/external-connection-response';

@Injectable({ providedIn: "root" })
export class ExternalConnectionResolver implements Resolve<ExternalConnectionResolverPayload> {

  public constructor(
    private service: ExternalConnectionsService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ExternalConnectionResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let externalConnectionLoader: Observable<ExternalConnectionResponse> = from([{}]);

    if (id > 0) {
      externalConnectionLoader = this.service.Get(id);
    }

    return forkJoin({
      externalConnection: externalConnectionLoader
    });
  }
}

export interface ExternalConnectionResolverPayload {
  externalConnection: ExternalConnectionResponse;
}
