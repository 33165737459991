import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConnectedPatient, Representative } from '../../../../generated/models';

@Component({
  selector: 'mp-connected-patients',
  templateUrl: './connected-patients.component.html',
  styleUrls: ['./connected-patients.component.scss']
})
export class ConnectedPatientsComponent implements OnInit {

  @Input() representative: Representative;
  @Input() children: ConnectedPatient[];
  @Input() duplicates: ConnectedPatient[];

  @Output() onPatientClicked = new EventEmitter<ConnectedPatient>();
  @Output() onMerge = new EventEmitter<ConnectedPatient>();

  constructor() { }

  ngOnInit() { }

  onPatientSelected = (patient: ConnectedPatient) => this.onPatientClicked.emit(patient);
  onPatientMerge = (patient: ConnectedPatient) => this.onMerge.emit(patient);
}
