import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MyService, PatientsService } from '../../../../generated/services';
import { PatientConsultation, UsersTemplate, Record } from '../../../../generated/models';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'mp-change-template-modal',
  templateUrl: './change-template-modal.component.html',
  styleUrls: ['./change-template-modal.component.scss']
})
export class ChangeTemplateModalComponent implements OnInit {

  @Input() processing = false;

  @Input() templateId: number;
  @Input() patientId: number;
  @Input() contractItemId: number;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<number>();

  templates: UsersTemplate[] = [];
  records: Record[] = [];

  selected: UsersTemplate;

  get disabled() { return !this.selected; }

  constructor(private myService: MyService, private patientsService: PatientsService) { }

  ngOnInit() {

    forkJoin({
      consultations: this.patientsService.Consultations(this.patientId),
      templates: this.myService.Templates()
    }).subscribe((response: { templates: UsersTemplate[], consultations: PatientConsultation[] }) => {
      this.templates = response.templates;

      const consultation = response.consultations.find(x => x.id === this.contractItemId);

      if (consultation) {
        this.records = consultation.records;
      }
    });
  }


  isSelected = (item: UsersTemplate) => this.selected && this.selected.id === item.id;
  isCurrent = (item: UsersTemplate) => this.templateId === item.id;

  recordFor = (item: UsersTemplate) => this.records.find(x => x.templateId === item.id);

  select(item: UsersTemplate) {
    if (item.id === this.templateId) return;
    if (this.records.some(x => x.templateId === item.id)) return;

    this.selected = item;
  }

  cancel = () => this.onCancel.emit();
  confirm = () => this.selected && this.selected.id > 0 ? this.onConfirm.emit(this.selected.id) : undefined;
}
