import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { CallCenterService, ColorMarksService, PeopleService } from '../../../generated/services';
import { Person } from '../../../generated/models/person';
import { Medcard, PersonsVisit, ConnectedPatient, Representative, LabResult, ColorMark } from '../../../generated/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class PatientResolver implements Resolve<PatientPayload> {

  constructor(
    private callCenterService: CallCenterService,
    private peopleService: PeopleService,
    private colorMarksService: ColorMarksService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PatientPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    let patientLoader: Observable<Person> = null;
    let medcard: Observable<Medcard[]> = null;
    let visits: Observable<PersonsVisit[]> = null;
    let children: Observable<ConnectedPatient[]> = null;
    let representative: Observable<Representative[]> = null;
    let labResults: Observable<LabResult[]> = null;

    const colorMarksLoader = this.colorMarksService.ColorMarks({ Page: 1, Size: 100 }).pipe(map(x => x.items));

    if (id > 0) {
      patientLoader = this.callCenterService.Patient(id);
      medcard = this.peopleService.Medcards(id);
      visits = this.peopleService.Visits(id);
      children = this.peopleService.GetChildren(id);
      representative = this.peopleService.GetRepresentatives(id);

      labResults = this.peopleService.GetLabResults(id);
    } else {
      patientLoader = of({});
      medcard = of([]);
      visits = of([]);
      children = of([]);
      representative = of([]);

      labResults = of([]);
    }

    return forkJoin({
      patient: patientLoader,
      medcards: medcard,
      visits: visits,
      children: children,
      representative: representative,
      labResults: labResults,
      colorMarks: colorMarksLoader
    });
  }
}

export interface PatientPayload {
  patient: Person;
  medcards: Medcard[];
  visits: PersonsVisit[];
  children: ConnectedPatient[];
  representative: Representative[];
  labResults: LabResult[];
  colorMarks: ColorMark[];
}
