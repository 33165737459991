import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Certificate } from 'crypto-pro';

import * as moment from "moment";

@Component({
  selector: 'mp-signature-warning-modal',
  templateUrl: './signature-warning-modal.component.html',
  styleUrls: ['./signature-warning-modal.component.scss']
})
export class SignatureWarningModalComponent implements OnInit {

  @Input() certificates: Certificate[] = [];
  @Input() signatureIdentifier: string = '';

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<Certificate> = new EventEmitter<Certificate>();

  control: FormControl;

  constructor() {
    this.control = new FormControl(null, [Validators.required]);
  }

  private certificateName(certificate: Certificate): string {
    if (!certificate) return '';
    if (!this.signatureIdentifier) return certificate.name;
    if (!certificate.subjectName) return certificate.name;

    const properties = certificate.subjectName.split(',')
      .map(x => x.split('='))
      .filter(x => x.length === 2)
      .map(x => ({ key: x[0], value: x[1] }));

    const property = properties.find(x => x.key.toLowerCase().trim() === this.signatureIdentifier.toLowerCase().trim())

    return property ? property.value : certificate.name;
  }

  display(certificate: Certificate): string {
    if (!certificate) return '';

    const validFrom = moment(certificate.validFrom).isValid() ? moment(certificate.validFrom).format("DD.MM.YYYY") : "";
    const validTo = moment(certificate.validTo).isValid() ? moment(certificate.validTo).format("DD.MM.YYYY") : "";

    const validPeriod = validFrom && validTo ? `, действителен с ${validFrom} по ${validTo}` : "";

    return `${this.certificateName(certificate)}${validPeriod}`;
  }

  ngOnInit() { }

  close() {
    this.onClose.emit();
  }

  confirm() {

    this.control.markAsDirty();
    this.control.markAsTouched();

    if (this.control.invalid) {
      return;
    }

    this.onConfirm.emit(this.control.value);
  }
}
