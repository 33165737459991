/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateBarcodesResponse } from '../models/create-barcodes-response';
import { CreateBarcodesRequest } from '../models/create-barcodes-request';
@Injectable({
  providedIn: 'root',
})
class BarcodePrinterService extends __BaseService {
  static readonly CreateBarcodesPath = '/api/BarcodePrinter';
  static readonly BarcodesForVisitPath = '/api/BarcodePrinter/visits/{visitId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateBarcodesResponse(request?: CreateBarcodesRequest): __Observable<__StrictHttpResponse<CreateBarcodesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BarcodePrinter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateBarcodesResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateBarcodes(request?: CreateBarcodesRequest): __Observable<CreateBarcodesResponse> {
    return this.CreateBarcodesResponse(request).pipe(
      __map(_r => _r.body as CreateBarcodesResponse)
    );
  }

  /**
   * @param visitId undefined
   */
  BarcodesForVisitResponse(visitId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/BarcodePrinter/visits/${visitId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param visitId undefined
   */
  BarcodesForVisit(visitId: number): __Observable<null> {
    return this.BarcodesForVisitResponse(visitId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module BarcodePrinterService {
}

export { BarcodePrinterService }
