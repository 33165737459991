import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsenceReasonsComponent } from './components/absence-reasons/absence-reasons.component';
import { AbsenceReasonComponent } from './components/absence-reason/absence-reason.component';
import { AbsenceReasonItemComponent } from './components/absence-reason-item/absence-reason-item.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { AbsenceReasonResolver } from './resolvers/absence-reason-resolver';

const routes: Routes = [
  {
    path: 'new',
    component: AbsenceReasonComponent,
    resolve: {
      payload: AbsenceReasonResolver
    },
    data: {
      title: 'Новая причина неявки',
      requirements: [PermissionNames.ManageAbsenceReasons]
    }
  },
  {
    path: ':id',
    component: AbsenceReasonComponent,
    resolve: {
      payload: AbsenceReasonResolver
    },
    data: {
      title: 'Причина неявки',
      requirements: [PermissionNames.ManageAbsenceReasons]
    }
  },
  {
    path: '',
    component: AbsenceReasonsComponent,
    data: {
      title: 'Причины неявки',
      requirements: [PermissionNames.ManageAbsenceReasons]
    }
  }
];


@NgModule({
  declarations: [AbsenceReasonsComponent, AbsenceReasonComponent, AbsenceReasonItemComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,

    NgbModalModule,
    ToastrModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AbsenceReasonsSettingsModule { }
