import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-expandable-parameter-group',
  templateUrl: './expandable-parameter-group.component.html',
  styleUrls: ['./expandable-parameter-group.component.scss']
})
export class ExpandableParameterGroupComponent implements OnInit {

  @HostBinding("class.expanded") show = false;
  @HostBinding("class.disabled") get isDisabled() { return this.disabled; }

  @Input() title = '';
  @Input() disabled = false;

  constructor() { }

  ngOnInit() { }

  toggle = () => this.show = this.disabled ? this.show : !this.show;

}
