import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActiveCallModalComponent } from './components/active-call-modal/active-call-modal.component';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCallReportModalComponent } from './components/active-call-report-modal/active-call-report-modal.component';
import { ToastrModule } from 'ngx-toastr';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { ReactiveFormsModule } from '@angular/forms';
import { PatientsListItemComponent } from './components/patients-list-item/patients-list-item.component';
import { ActiveCallActionComponent } from './components/active-call-action/active-call-action.component';

@NgModule({
  declarations: [ActiveCallModalComponent, ActiveCallReportModalComponent, PatientsListItemComponent, ActiveCallActionComponent],
  entryComponents: [ActiveCallModalComponent, ActiveCallReportModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    LayoutModule,
    ControlsModule,

    NgbModalModule,
    NgbDropdownModule,
    ToastrModule
  ]
})
export class ActiveCallModule { }
