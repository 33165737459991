import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PriceSetPricesResponse, PriceSetService, PriceSetServiceResponse, ServiceCategory } from '../../../../generated/models';
import { PriceSetsService } from '../../../../generated/services';

@Component({
  selector: 'mp-price-set-prices-list',
  templateUrl: './price-set-prices-list.component.html',
  styleUrls: ['./price-set-prices-list.component.scss']
})
export class PriceSetPricesListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() categories: ServiceCategory[] = [];
  @Input() priceSetId: number;
  @Input() pricesUpdated$: Subject<void>;

  @Output() onEdit = new EventEmitter<PriceSetService>();
  @Output() onDelete = new EventEmitter<PriceSetService>();

  scrollDistance = 1;
  page = 1;
  size = 200;

  prices: PriceSetService[] = [];

  filterForm = new FormGroup({
    search: new FormControl(""),
    includeArchived: new FormControl(false),
    category: new FormControl(null)
  });

  constructor(private priceSetsService: PriceSetsService) {

    this.filterForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.page = 1),
        switchMap((value: SearchValue) => this.priceSetsService.Services({
          id: this.priceSetId,
          Search: value.search,
          CategoryId: value.category,
          IncludeArchived: value.includeArchived,
          Page: this.page,
          Size: this.size
        }))
      ).subscribe((response: PriceSetPricesResponse) => this.prices = response.items);
  }

  ngOnInit() {
    this.filterForm.patchValue({ search: '', includeArchived: false, category: "" });

    this.pricesUpdated$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((): void => {
      this.page = 1;
      this.prices = [];
      this.loadPage();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  isPositive = (price: PriceSetService) => price.status === 0;

  onScrollDown(): void {
    this.page++;
    this.loadPage();
  }

  editPrice = (price: PriceSetService) => this.onEdit.emit(price);
  deletePrice = (price: PriceSetService) => this.onDelete.emit(price);

  loadPage(): void {
    const value: SearchValue = this.filterForm.getRawValue();

    this.priceSetsService.Services({
      id: this.priceSetId,
      Search: value.search,
      CategoryId: value.category,
      IncludeArchived: value.includeArchived,
      Page: this.page,
      Size: this.size
    }).subscribe((result: PriceSetServiceResponse) => this.prices = this.prices.concat(result.items));
  }
}

interface SearchValue {
  search: string;
  includeArchived: boolean;
  category: number;
}
