/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AbsenceReasonsService } from './services/absence-reasons.service';
import { AccountService } from './services/account.service';
import { AgreementsService } from './services/agreements.service';
import { AppointmentsService } from './services/appointments.service';
import { AppointmentsV2Service } from './services/appointments-v2.service';
import { AttachmentsService } from './services/attachments.service';
import { AttendanceService } from './services/attendance.service';
import { BarcodePrinterService } from './services/barcode-printer.service';
import { BarcodesService } from './services/barcodes.service';
import { CallCenterService } from './services/call-center.service';
import { CallReportsService } from './services/call-reports.service';
import { CasesService } from './services/cases.service';
import { CashboxService } from './services/cashbox.service';
import { CategoriesService } from './services/categories.service';
import { ClinicalRecommendationsService } from './services/clinical-recommendations.service';
import { CodeSystemsService } from './services/code-systems.service';
import { ColorMarksService } from './services/color-marks.service';
import { CompaniesService } from './services/companies.service';
import { CustomerChannelsService } from './services/customer-channels.service';
import { DiagnosesService } from './services/diagnoses.service';
import { DiagnosisAliasesService } from './services/diagnosis-aliases.service';
import { DiagnosisRecommendationsService } from './services/diagnosis-recommendations.service';
import { DiscountsService } from './services/discounts.service';
import { DiseaseOutcomeService } from './services/disease-outcome.service';
import { DocumentsService } from './services/documents.service';
import { EmployeeCompaniesService } from './services/employee-companies.service';
import { EmployeePhonesService } from './services/employee-phones.service';
import { EmployeesService } from './services/employees.service';
import { EvotorAPIService } from './services/evotor-api.service';
import { ExternalConnectionsService } from './services/external-connections.service';
import { ImportService } from './services/import.service';
import { InsuranceService } from './services/insurance.service';
import { InvoicesService } from './services/invoices.service';
import { LinesService } from './services/lines.service';
import { LinksService } from './services/links.service';
import { LocusesService } from './services/locuses.service';
import { ManipulationRecommendationsService } from './services/manipulation-recommendations.service';
import { ManipulationsService } from './services/manipulations.service';
import { ManipulationServicesService } from './services/manipulation-services.service';
import { MedicalFilesService } from './services/medical-files.service';
import { MetricsService } from './services/metrics.service';
import { MyService } from './services/my.service';
import { MyFavoritesService } from './services/my-favorites.service';
import { MyRecentService } from './services/my-recent.service';
import { MyTreatmentsService } from './services/my-treatments.service';
import { ObservationsService } from './services/observations.service';
import { OrganizationsService } from './services/organizations.service';
import { ParseService } from './services/parse.service';
import { PartnerPlansService } from './services/partner-plans.service';
import { PartnersService } from './services/partners.service';
import { PatientPrescriptionsService } from './services/patient-prescriptions.service';
import { PatientsService } from './services/patients.service';
import { PaymentsService } from './services/payments.service';
import { PbxOperatorsService } from './services/pbx-operators.service';
import { PeopleService } from './services/people.service';
import { PermissionsService } from './services/permissions.service';
import { PersonalBalanceService } from './services/personal-balance.service';
import { PersonDocumentsService } from './services/person-documents.service';
import { PersonMarksService } from './services/person-marks.service';
import { PersonMetricsService } from './services/person-metrics.service';
import { PersonsService } from './services/persons.service';
import { PhoneCallsService } from './services/phone-calls.service';
import { PlannedVisitsService } from './services/planned-visits.service';
import { PrescriptionsService } from './services/prescriptions.service';
import { PricesService } from './services/prices.service';
import { PriceSetsService } from './services/price-sets.service';
import { PrintingService } from './services/printing.service';
import { ProfileService } from './services/profile.service';
import { PromotionsService } from './services/promotions.service';
import { ProtocolsService } from './services/protocols.service';
import { QuestionnairesService } from './services/questionnaires.service';
import { ReceiptsService } from './services/receipts.service';
import { RecommendationsService } from './services/recommendations.service';
import { RecommendedManipulationsService } from './services/recommended-manipulations.service';
import { RecordsService } from './services/records.service';
import { RefundReasonsService } from './services/refund-reasons.service';
import { RefundsService } from './services/refunds.service';
import { ReportsService } from './services/reports.service';
import { ReviewsService } from './services/reviews.service';
import { RolesService } from './services/roles.service';
import { ScheduleBreakReasonsService } from './services/schedule-break-reasons.service';
import { ScheduleHistoryService } from './services/schedule-history.service';
import { SchedulesService } from './services/schedules.service';
import { ScheduleSettingsService } from './services/schedule-settings.service';
import { ScheduleShiftsService } from './services/schedule-shifts.service';
import { ServiceCategoriesService } from './services/service-categories.service';
import { ServiceCompaniesService } from './services/service-companies.service';
import { ServiceGroupsService } from './services/service-groups.service';
import { ServicesService } from './services/services.service';
import { SettingsService } from './services/settings.service';
import { SocialStatusesService } from './services/social-statuses.service';
import { SpecialitiesService } from './services/specialities.service';
import { StandardsService } from './services/standards.service';
import { StandartizationService } from './services/standartization.service';
import { SuggestionsService } from './services/suggestions.service';
import { SystemSettingsService } from './services/system-settings.service';
import { TaxationSystemsService } from './services/taxation-systems.service';
import { TaxDeductionsService } from './services/tax-deductions.service';
import { TaxTypesService } from './services/tax-types.service';
import { TemplatesService } from './services/templates.service';
import { TherapyResultsService } from './services/therapy-results.service';
import { TreatmentRecommendationsService } from './services/treatment-recommendations.service';
import { ValueSetsService } from './services/value-sets.service';
import { VersionService } from './services/version.service';
import { VisitReportsService } from './services/visit-reports.service';
import { VisitsService } from './services/visits.service';
import { WageSchemasService } from './services/wage-schemas.service';
import { WorkspaceService } from './services/workspace.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AbsenceReasonsService,
    AccountService,
    AgreementsService,
    AppointmentsService,
    AppointmentsV2Service,
    AttachmentsService,
    AttendanceService,
    BarcodePrinterService,
    BarcodesService,
    CallCenterService,
    CallReportsService,
    CasesService,
    CashboxService,
    CategoriesService,
    ClinicalRecommendationsService,
    CodeSystemsService,
    ColorMarksService,
    CompaniesService,
    CustomerChannelsService,
    DiagnosesService,
    DiagnosisAliasesService,
    DiagnosisRecommendationsService,
    DiscountsService,
    DiseaseOutcomeService,
    DocumentsService,
    EmployeeCompaniesService,
    EmployeePhonesService,
    EmployeesService,
    EvotorAPIService,
    ExternalConnectionsService,
    ImportService,
    InsuranceService,
    InvoicesService,
    LinesService,
    LinksService,
    LocusesService,
    ManipulationRecommendationsService,
    ManipulationsService,
    ManipulationServicesService,
    MedicalFilesService,
    MetricsService,
    MyService,
    MyFavoritesService,
    MyRecentService,
    MyTreatmentsService,
    ObservationsService,
    OrganizationsService,
    ParseService,
    PartnerPlansService,
    PartnersService,
    PatientPrescriptionsService,
    PatientsService,
    PaymentsService,
    PbxOperatorsService,
    PeopleService,
    PermissionsService,
    PersonalBalanceService,
    PersonDocumentsService,
    PersonMarksService,
    PersonMetricsService,
    PersonsService,
    PhoneCallsService,
    PlannedVisitsService,
    PrescriptionsService,
    PricesService,
    PriceSetsService,
    PrintingService,
    ProfileService,
    PromotionsService,
    ProtocolsService,
    QuestionnairesService,
    ReceiptsService,
    RecommendationsService,
    RecommendedManipulationsService,
    RecordsService,
    RefundReasonsService,
    RefundsService,
    ReportsService,
    ReviewsService,
    RolesService,
    ScheduleBreakReasonsService,
    ScheduleHistoryService,
    SchedulesService,
    ScheduleSettingsService,
    ScheduleShiftsService,
    ServiceCategoriesService,
    ServiceCompaniesService,
    ServiceGroupsService,
    ServicesService,
    SettingsService,
    SocialStatusesService,
    SpecialitiesService,
    StandardsService,
    StandartizationService,
    SuggestionsService,
    SystemSettingsService,
    TaxationSystemsService,
    TaxDeductionsService,
    TaxTypesService,
    TemplatesService,
    TherapyResultsService,
    TreatmentRecommendationsService,
    ValueSetsService,
    VersionService,
    VisitReportsService,
    VisitsService,
    WageSchemasService,
    WorkspaceService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
