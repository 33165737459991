import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.scss'],
  host: { class: "modal-default" }
})
export class PasswordModalComponent implements OnInit, OnDestroy {
  private _destroy: Subject<void> = new Subject<void>();

  @Output()
  onSubmit: EventEmitter<Passwords> = new EventEmitter<Passwords>();

  public form: FormGroup;

  public oldPassword: FormControl;
  public newPassword: FormControl;
  public confirmPassword: FormControl;

  constructor(
    private activeModal: NgbActiveModal
  ) {

    this.oldPassword = new FormControl("", [Validators.required]);
    this.newPassword = new FormControl("", [Validators.required]);
    this.confirmPassword = new FormControl("", []);

    this.form = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: FormValue): void => {
          if (value.newPassword !== value.confirmPassword) {
            this.confirmPassword.setErrors({ ...this.confirmPassword.errors, confirm: true });
          } else {
            if (this.confirmPassword.errors) {
              let errors = this.confirmPassword.errors;
              if (Object.keys(errors).some((x: string): boolean => x !== "confirm")) {
                delete errors.confirm;
              } else {
                errors = null;
              }

              this.confirmPassword.setErrors(errors);
            }
          }

          this.confirmPassword.markAsTouched();
        }
      );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

  public ok(): void {
    Object.entries(this.form.controls)
      .forEach((item: [string, AbstractControl]): void => (item[1].markAsTouched()));

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onSubmit.emit({ oldPassword: value.oldPassword, newPassword: value.newPassword });
  }
}

interface FormValue {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}


export interface Passwords {
  oldPassword: string;
  newPassword: string;
}
