import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { IssuersSuggestion } from '../../../../generated/models';

@Component({
  selector: 'mp-issuer-code',
  templateUrl: './issuer-code.component.html',
  styleUrls: ['./issuer-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IssuerCodeComponent
    }
  ]
})
export class IssuerCodeComponent implements ControlValueAccessor, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() loader: (string) => Observable<IssuersSuggestion[]>;
  @Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

  control = new FormControl();
  onChange: (string) => void;

  formatter = (value: IssuersSuggestion): string => value.issuerCode;

  issuersSearch: OperatorFunction<string, IssuersSuggestion[]> = (text$: Observable<string>) =>
    text$.pipe(
      takeUntil(this._destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        if (!term || term.length < 3) {
          return from([[]]);
        }

        return this.loader(term);

      })
    );

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: IssuersSuggestion): void => {
        this.onChange(value.issuerCode);
      });
  }

  writeValue(obj: string): void {
    console.info(obj);
    this.control.setValue({ issuerCode: obj }, { emitEvent: false });
  }
  registerOnChange(fn: (string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {

  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.control.disable({ emitEvent: false }); else this.control.enable({ emitEvent: false });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  select(payload: NgbTypeaheadSelectItemEvent): void {
    this.onChange(payload.item.issuerCode);
    this.onSelect.emit(payload.item.issuerName)
  }

}
