import { Component, OnInit, Input } from '@angular/core';
import { Speciality } from 'projects/Clinic/src/app/generated/models/speciality';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService, QuestionnairesService } from 'projects/Clinic/src/app/generated/services';
import { EmployeeSpeciality } from 'projects/Clinic/src/app/generated/models';

@Component({
    selector: 'mp-questionnaire-threshold-modal',
    templateUrl: './questionnaire-threshold.modal.component.html',
    styleUrls: ['./questionnaire-threshold.modal.component.scss']
})
export class QuestionnaireThresholdModalComponent implements OnInit {
    //public name: string;

    @Input("questionnaireId") questionnaireId: number;
    @Input("mode") mode: number;
    @Input("questionId") questionId: number;
    @Input("title") title: string;
    @Input("value") value: number;

    public form: FormGroup;

    constructor(
        private activeModal: NgbActiveModal,
        private questionnairesService: QuestionnairesService
    ) {
       // this.name = "Новый вопрос";

        this.form = new FormGroup({
            name: new FormControl("", [Validators.required]),
            value: new FormControl("", [Validators.required])
        });
    }

    ngOnInit() {
        this.form.controls["name"].setValue(this.title);
        this.form.controls["value"].setValue(this.value);
    }

    public submit(): void {
        if (this.form.invalid) {
            return;
        }
        this.questionnairesService.AddThreshold({
            questionnaireId: this.questionnaireId,
            model: {
                title: this.form.get("name").value,
                value: this.form.get("value").value
            }
        })
            .subscribe(
                (result: any): void => {
                    this.activeModal.close(result);
                });
    }

    public edit(): void {
        if (this.form.invalid) {
            return;
        }
        this.questionnairesService.EditThreshold({
            questionnaireId: this.questionnaireId,
            model: {
                id: this.questionId,
                title: this.form.get("name").value,
                value: this.form.get("value").value
            }
        })
            .subscribe(
                (result: any): void => {
                    this.activeModal.close(result);
                });
    }

    public delete(): void {
        this.questionnairesService.DeleteThreshold({
            questionnaireId: this.questionnaireId,
            model: {
                id: this.questionId
            }
        })
            .subscribe(
                (result: any): void => {
                    this.activeModal.close(result);
                });
    }

    public cancel(): void {
        this.activeModal.dismiss();
    }
}
