/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RefundReason } from '../models/refund-reason';
import { CreateRefundReasonResponse } from '../models/create-refund-reason-response';
import { CreateRefundReasonRequest } from '../models/create-refund-reason-request';
import { UpdateRefundReasonRequest } from '../models/update-refund-reason-request';
@Injectable({
  providedIn: 'root',
})
class RefundReasonsService extends __BaseService {
  static readonly RefundReasonsAsyncPath = '/api/RefundReasons';
  static readonly CreateAsyncPath = '/api/RefundReasons';
  static readonly AllAsyncPath = '/api/RefundReasons/all';
  static readonly RefundReasonAsyncPath = '/api/RefundReasons/{id}';
  static readonly UpdateAsyncPath = '/api/RefundReasons/{id}';
  static readonly RemoveAsyncPath = '/api/RefundReasons/{id}';
  static readonly ArchiveAsyncPath = '/api/RefundReasons/{id}/archive';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RefundReasonsService.RefundReasonsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  RefundReasonsAsyncResponse(params: RefundReasonsService.RefundReasonsAsyncParams): __Observable<__StrictHttpResponse<Array<RefundReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RefundReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RefundReason>>;
      })
    );
  }
  /**
   * @param params The `RefundReasonsService.RefundReasonsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  RefundReasonsAsync(params: RefundReasonsService.RefundReasonsAsyncParams): __Observable<Array<RefundReason>> {
    return this.RefundReasonsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<RefundReason>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAsyncResponse(request?: CreateRefundReasonRequest): __Observable<__StrictHttpResponse<CreateRefundReasonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/RefundReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateRefundReasonResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAsync(request?: CreateRefundReasonRequest): __Observable<CreateRefundReasonResponse> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateRefundReasonResponse)
    );
  }

  /**
   * @param params The `RefundReasonsService.AllAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  AllAsyncResponse(params: RefundReasonsService.AllAsyncParams): __Observable<__StrictHttpResponse<Array<RefundReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RefundReasons/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RefundReason>>;
      })
    );
  }
  /**
   * @param params The `RefundReasonsService.AllAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  AllAsync(params: RefundReasonsService.AllAsyncParams): __Observable<Array<RefundReason>> {
    return this.AllAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<RefundReason>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RefundReasonAsyncResponse(id: number): __Observable<__StrictHttpResponse<RefundReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/RefundReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RefundReason>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  RefundReasonAsync(id: number): __Observable<RefundReason> {
    return this.RefundReasonAsyncResponse(id).pipe(
      __map(_r => _r.body as RefundReason)
    );
  }

  /**
   * @param params The `RefundReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsyncResponse(params: RefundReasonsService.UpdateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/RefundReasons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RefundReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsync(params: RefundReasonsService.UpdateAsyncParams): __Observable<null> {
    return this.UpdateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/RefundReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveAsync(id: number): __Observable<null> {
    return this.RemoveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ArchiveAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/RefundReasons/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ArchiveAsync(id: number): __Observable<null> {
    return this.ArchiveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RefundReasonsService {

  /**
   * Parameters for RefundReasonsAsync
   */
  export interface RefundReasonsAsyncParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
  }

  /**
   * Parameters for AllAsync
   */
  export interface AllAsyncParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
  }

  /**
   * Parameters for UpdateAsync
   */
  export interface UpdateAsyncParams {
    id: number;
    request?: UpdateRefundReasonRequest;
  }
}

export { RefundReasonsService }
