import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportDiscount } from '../../../../generated/models';
import { ReportsFiltersValue } from '../reports/reports.component';

@Component({
  selector: 'mp-selected-discounts-list',
  templateUrl: './selected-discounts-list.component.html',
  styleUrls: ['./selected-discounts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedDiscountsListComponent implements OnInit {
  @Input() filters: ReportsFiltersValue = {};

  @Output()
  onFiltersChanged: EventEmitter<ReportsFiltersValue> = new EventEmitter<ReportsFiltersValue>();

  @Output()
  onModalOpened: EventEmitter<void> = new EventEmitter<void>();

  get anyDiscountSelected(): boolean {
    return this.filters.allDiscountsSelected || this.filters.selectedDiscounts.length > 0;
  }

  constructor() { }

  ngOnInit() { }

  openModal(): void {
    this.onModalOpened.emit();
  }

  remove(item: ReportDiscount): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      selectedDiscounts: this.filters.selectedDiscounts.filter(x => x.id !== item.id)
    };

    this.onFiltersChanged.emit(filters);
  }

  removeAll(): void {
    const filters: ReportsFiltersValue = {
      ...this.filters,
      allDiscountsSelected: false
    };

    this.onFiltersChanged.emit(filters);
  }
}
