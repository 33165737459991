import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResourceSpecialityViewModel, Speciality } from 'projects/Clinic/src/app/generated/models';

import { ScheduleSettingsService } from 'projects/Clinic/src/app/generated/services';
@Component({
  selector: 'mp-schedule-speciality-modal',
  templateUrl: './schedule-speciality.modal.component.html',
  styleUrls: ['./schedule-speciality.modal.component.scss']
})
export class ScheduleSpecialityModalComponent implements OnInit {
  title = "Специальность";

  form = new FormGroup({
    speciality: new FormControl("", [Validators.required])
  });

  @Input() specialities: Speciality[] = [];

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onConfirm = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }

  cancel = () => this.onCancel.emit();

  submit(): void {
    this.form.markAsTouched();

    if (!this.form.valid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onConfirm.emit(value.speciality);
  }
}

interface FormValue {
  speciality: number;
}
