import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Appointment } from '../../../../generated/models';

@Component({
  selector: 'mp-dashboard-list-item',
  templateUrl: './dashboard-list-item.component.html',
  styleUrls: ['./dashboard-list-item.component.scss']
})
export class DashboardListItemComponent implements OnInit {

  @Input() item: Appointment;

  @HostBinding('class.confirmed')
  get valid() { return this.item&& this.item.confirmed; }

  constructor() { }

  ngOnInit() { }

  get date(): string {
    if (!this.item && !this.item.date) return '';

    return this.item.date.substr(0, 10);
  }

  get time(): string {
    if (!this.item && !this.item.date) return '';

    return this.item.date.substr(11, 5);
  }

  get phone(): string {
    if (!this.item && !this.item.patientPhone) return '';

    if (this.item.patientPhone.length === 11) {
      const phone = this.item.patientPhone;
      return `+7 (${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7, 2)}-${phone.substr(9,2)}`;
    }

    return this.item.patientPhone;
  }

}
