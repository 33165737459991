import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { ServiceCategoryEditorModel } from '../../../generated/models';
import { ServiceCategoriesService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class ServiceCategoryResolver implements Resolve<ServiceCategoryPayload> {

  public constructor(
    private serviceCategoriesService: ServiceCategoriesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceCategoryPayload> {
    const id: number = route.paramMap.has("id") ? parseInt(route.paramMap.get("id"), 10) : undefined;

    let categoryLoader: Observable<ServiceCategoryEditorModel> = null;

    if (id > 0) {
      categoryLoader = this.serviceCategoriesService.Category(id);
    } else {
      categoryLoader = of({});
    }

    return forkJoin({
      category: categoryLoader
    });
  }
}

export interface ServiceCategoryPayload {
  category: ServiceCategoryEditorModel

}
