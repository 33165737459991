import { Injectable } from '@angular/core';
import { Template, Speciality, Attending, AttendingsResponse, SavedTemplateGroup, SpecialitiesResponse, Metric, DiseaseOutcome } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { TemplatesService, SpecialitiesService, EmployeesService, MetricsService, DiseaseOutcomeService } from '../../../generated/services';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class TemplateResolver implements Resolve<TemplatePayload> {

  constructor(
    private templatesService: TemplatesService,
    private specialitiesService: SpecialitiesService,
    private employeesService: EmployeesService,
    private metricsService: MetricsService,
    private outcomesService: DiseaseOutcomeService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TemplatePayload> {
    const id: number = parseInt(route.paramMap.get("id"));

    let templateLoader: Observable<Template> = undefined;
    if (!!id) {
      templateLoader = this.templatesService.Template(id);
    } else {
      templateLoader = of<Template>({});
    }

    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(map(response => response.items));

    const attendingsLoader: Observable<Attending[]> = this.employeesService.Attendings({ Page: 1, Size: 0 })
      .pipe(map(response => response.items));

    const placeholdersLoader: Observable<SavedTemplateGroup[]> = this.templatesService.Placeholders();
    const metricsLoader: Observable<Metric[]> = this.metricsService.MetricsAsync({ Page: 1, Size: 0 });
    const outcomesLoader: Observable<DiseaseOutcome[]> = this.outcomesService.DiseaseOutcomes();

    return forkJoin({
      template: templateLoader,
      specialities: specialitiesLoader,
      attendings: attendingsLoader,
      placeholders: placeholdersLoader,
      metrics: metricsLoader,
      outcomes: outcomesLoader
    });
  }
}

export interface TemplatePayload {
  template: Template;
  specialities: Speciality[];
  attendings: Attending[];
  placeholders: SavedTemplateGroup[];
  metrics: Metric[];
  outcomes: DiseaseOutcome[];
}
