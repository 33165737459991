import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportService } from '../../../../generated/models';

@Component({
  selector: 'mp-services-modal',
  templateUrl: './services-modal.component.html',
  styleUrls: ['./services-modal.component.scss']
})
export class ServicesModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  private _selected: ReportService[] = [];
  private _servicesSubject$: BehaviorSubject<ReportService[]> = new BehaviorSubject<ReportService[]>([]);

  @Input() title: string;
  @Input() selected: ReportService[] = [];
  @Input() services: ReportService[] = [];

  @Output()
  onConfirm: EventEmitter<ReportService[]> = new EventEmitter<ReportService[]>();
  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  get result(): Observable<ReportService[]> { return this._servicesSubject$; }

  loading = false;
  search: FormControl;

  constructor() {
    this.search = new FormControl("");

    this.search.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: string): void => {
          if (!value) {
            this._servicesSubject$.next([...this.services]);
          } else {

            const search = new RegExp(value, "i");
            const filtered: ReportService[] = this.services.filter((x: ReportService): boolean => search.test(x.name) || search.test(x.code));
            this._servicesSubject$.next(filtered);
          }
        }
      );
  }

  ngOnInit() {
    this._selected = this.selected;
    this.search.setValue("");
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  isSelected(item: ReportService): boolean {
    return this._selected.some((x: ReportService): boolean => x.id === item.id);
  }

  toggle(item: ReportService): void {
    const index: number = this._selected.findIndex((x: ReportService): boolean => x.id === item.id);

    if (index === -1) {
      this._selected.push(item);
    } else {
      this._selected.splice(index, 1);
    }
  }

  submit(): void {
    this.onConfirm.emit([...this._selected]);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
