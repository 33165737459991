/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrintingTask } from '../models/printing-task';
import { PrintedTask } from '../models/printed-task';
@Injectable({
  providedIn: 'root',
})
class ReceiptsService extends __BaseService {
  static readonly GetTaskPath = '/api/Receipts/receipts/{id}';
  static readonly UpdateTaskPath = '/api/Receipts/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetTaskResponse(id: number): __Observable<__StrictHttpResponse<PrintingTask>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Receipts/receipts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintingTask>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetTask(id: number): __Observable<PrintingTask> {
    return this.GetTaskResponse(id).pipe(
      __map(_r => _r.body as PrintingTask)
    );
  }

  /**
   * @param params The `ReceiptsService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `task`:
   */
  UpdateTaskResponse(params: ReceiptsService.UpdateTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.task;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Receipts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReceiptsService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `task`:
   */
  UpdateTask(params: ReceiptsService.UpdateTaskParams): __Observable<null> {
    return this.UpdateTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReceiptsService {

  /**
   * Parameters for UpdateTask
   */
  export interface UpdateTaskParams {
    id: number;
    task?: PrintedTask;
  }
}

export { ReceiptsService }
