import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyTreatment } from '../../../../generated/models';
import { TreatmentAnalogModalComponent } from '../treatment-analog-modal/treatment-analog-modal.component';

@Component({
  selector: 'mp-fav-treatment',
  templateUrl: './fav-treatment.component.html',
  styleUrls: ['./fav-treatment.component.scss']
})
export class FavTreatmentComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() item: MyTreatment;
  @Input() analogs: string[] = [];

  @Output() onSelect = new EventEmitter<void>();
  @Output() onCopy = new EventEmitter<void>();
  @Output() onRemove = new EventEmitter<void>();

  @Output() onChange = new EventEmitter<MyTreatment>();

  @Output() onAnalogAdded = new EventEmitter<string>();
  @Output() onAnalogRemoved = new EventEmitter<string>();

  saved: MyTreatment;
  editing = false;

  analogs$ = new Subject<string[]>();

  form = new FormGroup({
    treatmentName: new FormControl('', [Validators.required]),
    dosage: new FormControl('', []),
    schema: new FormControl('', []),
    duration: new FormControl('', [])
  });

  constructor(private ngbModal: NgbModal) {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.saved = { ...this.saved, ...this.form.getRawValue() };
      });
  }

  ngOnInit() {
    this.editing = this.item && this.item.id < 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      this.saved = { ...changes['item'].currentValue };

      this.form.patchValue({ ...this.saved }, { emitEvent: false });
    }

    if (changes['analogs']) {
      this.analogs$.next(changes['analogs'].currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
    this.analogs$.unsubscribe();
  }

  get formatted(): string {
    if (!this.saved) return '';

    let name = this.saved.treatmentName;

    if (this.analogs.length > 0) {
      name += " (";

      //name += this.analogs.length === 1 ? "аналог: " : "аналоги: ";

      let total = 0;
      const analogs = [];

      for (const analog of this.analogs) {
        if (total > 24) break;

        analogs.push(analog);
        total += analog.length;
      }

      name += analogs.join(", ");

      if (analogs.length != this.analogs.length) {
        name += " и др.";
      }

      name += ")";
    }

    return [name, this.saved.dosage, this.saved.schema, this.saved.duration].filter(x => x).join(', ');
  }

  get hasAnalogs(): boolean { return this.analogs.length > 0; }

  select = () => this.onSelect.emit();
  copy = () => this.onCopy.emit();
  remove = () => this.onRemove.emit();

  edit() {
    this.form.patchValue({ ...this.saved }, { emitEvent: false });
    this.editing = true;
  }

  cancel() {

    if (this.item.id > 0) {
      this.saved = { ...this.item };
      this.form.patchValue({ ...this.saved }, { emitEvent: false });
      this.editing = false;
    } else {
      this.onRemove.emit();
    }
  }

  accept() {
    Object.entries(this.form.controls).forEach(x => {
      x[1].markAsTouched();
      x[1].markAsDirty();
    });

    if (this.form.invalid) return;

    this.onChange.emit({ ...this.form.getRawValue(), id: this.item.id });

    this.editing = false;
  }

  manageAnalogs(): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true };

    const modalRef = this.ngbModal.open(TreatmentAnalogModalComponent, options);
    const componentRef: TreatmentAnalogModalComponent = modalRef.componentInstance;

    componentRef.treatmentName = this.item.treatmentName;
    componentRef.analogs = this.analogs;

    this.analogs$.subscribe(analogs => componentRef.analogs = analogs);

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });

    componentRef.onAdd.subscribe((value: string): void => {
      this.onAnalogAdded.emit(value);
    });

    componentRef.onRemove.subscribe((value: string): void => {
      this.onAnalogRemoved.emit(value);
    });
  }
}
