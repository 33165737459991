import { Component, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { SystemSettingPayload } from '../../resolvers/system-setting-resolver';
import { AddSettingResponse, Company, SystemSettingViewModelItem, SystemUser } from '../../../../generated/models';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SystemSettingEditModalComponent, SystemSettingsModalPayload } from '../system-setting-edit-modal/system-setting-edit-modal.component';
import { SystemSettingsService } from '../../../../generated/services';
import { DeleteSettingValuesResponse } from '../../../../generated/models/delete-setting-values-response';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mp-system-setting',
  templateUrl: './system-setting.component.html',
  styleUrls: ['./system-setting.component.scss'],
  host: { class: "page" }
})
export class SystemSettingComponent implements OnInit {
  private _destroy$: Subject<void> = new Subject<void>();

  id: string;
  title: string;

  private _selectedValues: SystemSettingViewModelItem[] = [];

  values: SystemSettingViewModelItem[] = [];
  companies: Company[] = [];
  users: SystemUser[] = [];

  public get removeBtmDisabled(): boolean { return this._selectedValues.length === 0; }

  public get allDisbaled(): boolean { return this.values.length === 0; }
  public get allChecked(): boolean { return this.values.length > 0 && this.values.length === this._selectedValues.length; }
  public get indeterminate(): boolean { return this._selectedValues.length > 0 && this.values.length !== this._selectedValues.length; }

  public checkbox: FormControl;

  controlType = "string";

  values$: Observable<SystemSettingViewModelItem[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private systemSettingsService: SystemSettingsService,
    private toastrService: ToastrService
  ) {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(
      (value: boolean): void => {
        if (value) {
          this._selectedValues = [...this.values];
        } else {
          this._selectedValues = [];
        }
      }
    );
  }

  ngOnInit() {

    this.activatedRoute.data.pipe(takeUntil(this._destroy$))
      .subscribe((data: { payload: SystemSettingPayload }): void => {
        this.companies.push(...data.payload.companies);
        this.users.push(...data.payload.employee);

        this.id = data.payload.systemSettingModel.id;
        this.title = data.payload.systemSettingModel.name;
        this.values.push(...data.payload.systemSettingModel.items);

        this.controlType = data.payload.systemSettingModel.controlType;

        data.payload.systemSettingModel.items.length === 0 ? this.checkbox.disable() : this.checkbox.enable();

        this.values$ = of(data.payload.systemSettingModel.items);
      });

  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  selected(value: SystemSettingViewModelItem): boolean {
    return this._selectedValues.some((x: SystemSettingViewModelItem): boolean => x.id === value.id);
  }

  toggle(value: SystemSettingViewModelItem, checked: boolean): void {
    const index: number = this._selectedValues.findIndex((x: SystemSettingViewModelItem): boolean => x.id === value.id);

    if (checked && index === -1) {
      this._selectedValues.push(value);
    }

    if (!checked && index !== -1) {
      this._selectedValues.splice(index, 1);
    }
  }

  edit(value: SystemSettingViewModelItem): void {
    const options: NgbModalOptions = { backdrop: 'static', size: 'lg' };
    const modalRef: NgbModalRef = this.modalService.open(SystemSettingEditModalComponent, options);

    const componentRef: SystemSettingEditModalComponent = modalRef.componentInstance;

    componentRef.setting = value;
    componentRef.companies = [...this.companies];
    componentRef.users = [...this.users];
    componentRef.controlType = this.controlType;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((payload: SystemSettingsModalPayload): void => {
      this.systemSettingsService.ChangeSystemSettingValue({
        id: value.id,
        request: {
          value: payload.value,
          userId: payload.user,
          companyId: payload.company
        }
      })
        .subscribe(
          (): void => {
            this.values$ = this.systemSettingsService.SystemSettingInfo(this.id).pipe(map(x => x.items));
            modalRef.close();
          },
          (response: HttpErrorResponse): void => {
            switch (response.status) {
              case 400: {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }
              case 403:
                {
                  this.toastrService.error("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
                  return;
                }
              default: {
                this.toastrService.error("Не удалось сохранить настройку", "Ошибка");
                return;
              }
            }
          }
        );

    });
  }

  add(): void {
    const options: NgbModalOptions = { backdrop: 'static', size: 'lg' };
    const modalRef: NgbModalRef = this.modalService.open(SystemSettingEditModalComponent, options);
    const componentRef: SystemSettingEditModalComponent = modalRef.componentInstance;

    componentRef.setting = {};
    componentRef.companies = this.companies;
    componentRef.users = this.users;
    componentRef.controlType = this.controlType;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((payload: SystemSettingsModalPayload): void => {

      this.systemSettingsService.AddSystemSettingValue({
        name: this.id,
        request: {
          value: payload.value,
          companyId: payload.company,
          userId: payload.user
        }
      }).subscribe(
        (response: AddSettingResponse): void => {
          this.values$ = this.systemSettingsService.SystemSettingInfo(this.id).pipe(map(x => x.items));

          this.checkbox.enable({ emitEvent: false });

          modalRef.close();
        },
        (response: HttpErrorResponse): void => {
          switch (response.status) {
            case 400: {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }
            case 403:
              {
                this.toastrService.error("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
                return;
              }
            default: {
              this.toastrService.error("Не удалось добавить настройку", "Ошибка");
              return;
            }
          }
        }
      );
    });

  }

  remove(): void {
    const idsToDelete = this._selectedValues.map((x: SystemSettingViewModelItem): number => x.id);

    this.systemSettingsService.DeleteSystemSettingValues({
      name: this.id,
      Ids: idsToDelete
    }).subscribe(
      (response: DeleteSettingValuesResponse) => {
        this._selectedValues = [];
        this.values$ = this.systemSettingsService.SystemSettingInfo(this.id).pipe(map(x => x.items));

        this.values.length > 0 ? this.checkbox.disable() : this.checkbox.enable();
      },
      (response: HttpErrorResponse): void => {
        switch (response.status) {
          case 403:
            {
              this.toastrService.error("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
            }
            break;
          default: {
            this.toastrService.error("Не удалось удалить выбранные настройки", "Ошибка");
          }
            break;

        }
      }
    );
  }

}
