import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConnectedPatient } from '../../../../generated/models';

@Component({
  selector: 'mp-duplicate-list-item',
  templateUrl: './duplicate-list-item.component.html',
  styleUrls: ['./duplicate-list-item.component.scss']
})
export class DuplicateListItemComponent implements OnInit {
  @Input() patient: ConnectedPatient;

  @Output() onMerge = new EventEmitter<void>();
  @Output() onOpen = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  public get phone(): string {
    if (!this.patient) return "";

    if (!this.patient.phone || this.patient.phone.length !== 11) return this.patient.phone;

    return `+7 (${this.patient.phone.substr(1, 3)}) ${this.patient.phone.substr(4, 3)}-${this.patient.phone.substr(7, 2)}-${this.patient.phone.substr(9, 2)}`
  }

  mergeClick = () => this.onMerge.emit();
  open = () => this.onOpen.emit();
}
