import { Component, OnInit, Input } from '@angular/core';
import { PatientSearchResult } from '../../../../generated/models';

import * as moment from 'moment';

@Component({
  selector: 'mp-select-client-item',
  templateUrl: './select-client-item.component.html',
  styleUrls: ['./select-client-item.component.scss']
})
export class SelectClientItemComponent implements OnInit {
  phone: string;
  age: number;

  @Input() selected: boolean;
  @Input() patient: PatientSearchResult;

  get paymentSource(): string {

    if (this.patient.paymentSource === 2) {
      if (this.patient.insuranceCompanyName) {
        return `ОМС - ${this.patient.insuranceCompanyName}`;
      }

      return 'ОМС';
    }

    if (this.patient.paymentSource === 3) {
      if (this.patient.insuranceCompanyName) {
        return `ДМС - ${this.patient.insuranceCompanyName}`;
      }

      return 'ДМС';
    }

    if (this.patient.paymentSource === 4) {
      return 'Контракт';
    }

    if (this.patient.paymentSource === 5) {
      return 'Бюджет';
    }

  }

  constructor() { }

  ngOnInit() {
    if (this.patient) {
      this.phone = this.patient.phone;

      if (this.patient.phone && this.patient.phone.startsWith("7") && this.patient.phone.length === 11) {
        this.phone = `7 (${this.patient.phone.substr(1, 3)}) ${this.patient.phone.substr(4, 3)} ${this.patient.phone.substr(7, 4)}`;
      }

      if (this.patient.dob) {
        this.age = moment().diff(moment(this.patient.dob, "DD.MM.YYYY"), 'years');
      }
    }
  }
}
