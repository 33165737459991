import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonTestResult, PersonTestResultAttachment } from '../../../../generated/models';

@Component({
  selector: 'mp-result-list-item',
  templateUrl: './result-list-item.component.html',
  styleUrls: ['./result-list-item.component.scss']
})
export class ResultListItemComponent implements OnInit {

  @Input() item: PersonTestResult

  @Output() onOpen = new EventEmitter<{ id: number, testOrderId: number }>();

  @Output() onPrint = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  open = (attachment: PersonTestResultAttachment, protocol: PersonTestResult) => this.onOpen.emit({ id: attachment.id, testOrderId: protocol.id });

  print = () => this.onPrint.emit();

}
