import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesComponent } from './components/roles/roles.component';
import { RoleComponent } from './components/role/role.component';
import { Routes, RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { ControlsModule } from "@skytecs/controls";
import { LayoutModule } from "@skytecs/layout";
import { RoleListItemComponent } from './components/role-list-item/role-list-item.component';
import { ToastrModule } from 'ngx-toastr';
import { PermissionNames } from '../../models/permission-names';
import { NgbDropdown, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RoleResolver } from './resolvers/role-resolver';
import { RolePermissionItemComponent } from './components/role-permission-item/role-permission-item.component';
import { RolePermissionModalComponent } from './components/role-permission-modal/role-permission-modal.component';

const routes: Routes = [
  {
    path: "new",
    component: RoleComponent,
    resolve: {
      payload: RoleResolver
    },
    data: {
      title: "Новая роль",
      requirements: [PermissionNames.ViewRoles]
    }
  },
  {
    path: ":id",
    component: RoleComponent,
    resolve: {
      payload: RoleResolver
    },
    data: {
      title: "Роль",
      requirements: [PermissionNames.ViewRoles]
    }
  },
  {
    path: "",
    component: RolesComponent,
    data: {
      title: "Список ролей",
      requirements: [PermissionNames.ViewRoles]
    }
  }
];

@NgModule({
  entryComponents: [
    RolePermissionModalComponent
  ],
  declarations: [
    RolesComponent,
    RoleComponent,
    RoleListItemComponent,
    RolePermissionItemComponent,
    RolePermissionModalComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    ReactiveFormsModule,
    ToastrModule,

    NgbDropdownModule,
    NgbModalModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ]
})
export class RoleSettingsModule { }
