/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WageSchema } from '../models/wage-schema';
import { CreateWageSchemaRequest } from '../models/create-wage-schema-request';
import { UpdateWageSchemaRequest } from '../models/update-wage-schema-request';
@Injectable({
  providedIn: 'root',
})
class WageSchemasService extends __BaseService {
  static readonly WageSchemasPath = '/api/v1/WageSchemas';
  static readonly CreateWageSchemaPath = '/api/v1/WageSchemas';
  static readonly WageSchemaPath = '/api/v1/WageSchemas/{id}';
  static readonly UpdateWageSchemaPath = '/api/v1/WageSchemas/{id}';
  static readonly RemoveWageSchemaPath = '/api/v1/WageSchemas/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WageSchemasService.WageSchemasParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * @return Success
   */
  WageSchemasResponse(params: WageSchemasService.WageSchemasParams): __Observable<__StrictHttpResponse<Array<WageSchema>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WageSchemas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WageSchema>>;
      })
    );
  }
  /**
   * @param params The `WageSchemasService.WageSchemasParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * @return Success
   */
  WageSchemas(params: WageSchemasService.WageSchemasParams): __Observable<Array<WageSchema>> {
    return this.WageSchemasResponse(params).pipe(
      __map(_r => _r.body as Array<WageSchema>)
    );
  }

  /**
   * @param request undefined
   */
  CreateWageSchemaResponse(request?: CreateWageSchemaRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/WageSchemas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  CreateWageSchema(request?: CreateWageSchemaRequest): __Observable<null> {
    return this.CreateWageSchemaResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  WageSchemaResponse(id: number): __Observable<__StrictHttpResponse<WageSchema>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/WageSchemas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WageSchema>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  WageSchema(id: number): __Observable<WageSchema> {
    return this.WageSchemaResponse(id).pipe(
      __map(_r => _r.body as WageSchema)
    );
  }

  /**
   * @param params The `WageSchemasService.UpdateWageSchemaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateWageSchemaResponse(params: WageSchemasService.UpdateWageSchemaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/WageSchemas/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `WageSchemasService.UpdateWageSchemaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateWageSchema(params: WageSchemasService.UpdateWageSchemaParams): __Observable<null> {
    return this.UpdateWageSchemaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveWageSchemaResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/WageSchemas/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveWageSchema(id: number): __Observable<null> {
    return this.RemoveWageSchemaResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WageSchemasService {

  /**
   * Parameters for WageSchemas
   */
  export interface WageSchemasParams {
    Size?: number;
    Page?: number;
  }

  /**
   * Parameters for UpdateWageSchema
   */
  export interface UpdateWageSchemaParams {
    id: number;
    request?: UpdateWageSchemaRequest;
  }
}

export { WageSchemasService }
