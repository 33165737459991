import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';

@Component({
  selector: 'mp-general-parameter',
  templateUrl: './general-parameter.component.html',
  styleUrls: ['./general-parameter.component.scss']
})
export class GeneralParameterComponent implements OnInit {

  @Input() title = '';
  @Input() value = '';
  @Input() actionHint = 'нажмите, чтобы скопировать';
  @Input() icon = 'clone';

  @Output() onAction = new EventEmitter<void>();


  constructor() { }

  ngOnInit() { }

  act = () => this.onAction.emit();

}
