/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Company } from '../models/company';
import { ServicesResponse } from '../models/services-response';
import { CompanyServiceGroup } from '../models/company-service-group';
@Injectable({
  providedIn: 'root',
})
class CompaniesService extends __BaseService {
  static readonly CompaniesPath = '/api/Companies';
  static readonly MyCompaniesPath = '/api/Companies/my';
  static readonly AvailableServicesAsyncPath = '/api/Companies/{id}/services';
  static readonly CurrentAvailableServicesAsyncPath = '/api/Companies/current/services';
  static readonly AvailabelServiceGroupsAsyncPath = '/api/Companies/{id}/groups';
  static readonly CurrentAvailableServiceGroupsAsyncPath = '/api/Companies/current/groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CompaniesService.CompaniesParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Date`:
   *
   * @return Success
   */
  CompaniesResponse(params: CompaniesService.CompaniesParams): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.CompaniesParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Date`:
   *
   * @return Success
   */
  Companies(params: CompaniesService.CompaniesParams): __Observable<Array<Company>> {
    return this.CompaniesResponse(params).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param Date undefined
   * @return Success
   */
  MyCompaniesResponse(Date?: string): __Observable<__StrictHttpResponse<Array<Company>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Date != null) __params = __params.set('Date', Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies/my`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Company>>;
      })
    );
  }
  /**
   * @param Date undefined
   * @return Success
   */
  MyCompanies(Date?: string): __Observable<Array<Company>> {
    return this.MyCompaniesResponse(Date).pipe(
      __map(_r => _r.body as Array<Company>)
    );
  }

  /**
   * @param params The `CompaniesService.AvailableServicesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * @return Success
   */
  AvailableServicesAsyncResponse(params: CompaniesService.AvailableServicesAsyncParams): __Observable<__StrictHttpResponse<ServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesResponse>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.AvailableServicesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * @return Success
   */
  AvailableServicesAsync(params: CompaniesService.AvailableServicesAsyncParams): __Observable<ServicesResponse> {
    return this.AvailableServicesAsyncResponse(params).pipe(
      __map(_r => _r.body as ServicesResponse)
    );
  }

  /**
   * @param Date undefined
   * @return Success
   */
  CurrentAvailableServicesAsyncResponse(Date?: string): __Observable<__StrictHttpResponse<ServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Date != null) __params = __params.set('Date', Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies/current/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesResponse>;
      })
    );
  }
  /**
   * @param Date undefined
   * @return Success
   */
  CurrentAvailableServicesAsync(Date?: string): __Observable<ServicesResponse> {
    return this.CurrentAvailableServicesAsyncResponse(Date).pipe(
      __map(_r => _r.body as ServicesResponse)
    );
  }

  /**
   * @param params The `CompaniesService.AvailabelServiceGroupsAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * @return Success
   */
  AvailabelServiceGroupsAsyncResponse(params: CompaniesService.AvailabelServiceGroupsAsyncParams): __Observable<__StrictHttpResponse<Array<CompanyServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies/${params.id}/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyServiceGroup>>;
      })
    );
  }
  /**
   * @param params The `CompaniesService.AvailabelServiceGroupsAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Date`:
   *
   * @return Success
   */
  AvailabelServiceGroupsAsync(params: CompaniesService.AvailabelServiceGroupsAsyncParams): __Observable<Array<CompanyServiceGroup>> {
    return this.AvailabelServiceGroupsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<CompanyServiceGroup>)
    );
  }

  /**
   * @param Date undefined
   * @return Success
   */
  CurrentAvailableServiceGroupsAsyncResponse(Date?: string): __Observable<__StrictHttpResponse<Array<CompanyServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Date != null) __params = __params.set('Date', Date.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Companies/current/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyServiceGroup>>;
      })
    );
  }
  /**
   * @param Date undefined
   * @return Success
   */
  CurrentAvailableServiceGroupsAsync(Date?: string): __Observable<Array<CompanyServiceGroup>> {
    return this.CurrentAvailableServiceGroupsAsyncResponse(Date).pipe(
      __map(_r => _r.body as Array<CompanyServiceGroup>)
    );
  }
}

module CompaniesService {

  /**
   * Parameters for Companies
   */
  export interface CompaniesParams {
    Type?: any;
    Date?: string;
  }

  /**
   * Parameters for AvailableServicesAsync
   */
  export interface AvailableServicesAsyncParams {
    id: number;
    Date?: string;
  }

  /**
   * Parameters for AvailabelServiceGroupsAsync
   */
  export interface AvailabelServiceGroupsAsyncParams {
    id: number;
    Date?: string;
  }
}

export { CompaniesService }
