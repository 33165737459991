export class TreatmentPrescriptionPayload {
  id: number;
  treatmentName: string;
  dosage?: string;
  schema?: string;
  duration?: string;
  listOrder?: number;

  analogs: string[];
}
