import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { AbsenceReason } from '../../../../generated/models';
import { AbsenceReasonsService } from '../../../../generated/services';

@Component({
  selector: 'mp-absence-reasons',
  templateUrl: './absence-reasons.component.html',
  styleUrls: ['./absence-reasons.component.scss'],
  host: { class: 'page' }
})
export class AbsenceReasonsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = false;

  reasons: AbsenceReason[] = [];

  reasons$ = new BehaviorSubject([]);

  form = new FormGroup({
    search: new FormControl("")
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private reasonsService: AbsenceReasonsService,
    private toastrService: ToastrService,
    private modal: NgbModal) {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { search: string }): void => {
        const queryParams: Params = {};

        if (!value.search) {
          this.reasons$.next([...this.reasons]);
        } else {
          queryParams.search = value.search;

          const regex = new RegExp(value.search, 'i');
          this.reasons$.next(this.reasons.filter(x => regex.test(x.name)));
        }

        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
      });

  }

  ngOnInit() {
    const paramMap = this.activatedRoute.snapshot.queryParamMap;

    if (paramMap.has('search')) {
      this.form.patchValue({
        search: paramMap.get('search')
      });
    }

    this.load();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  create(): void {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  update(reason: AbsenceReason): void {
    this.router.navigate([reason.id], { relativeTo: this.activatedRoute });
  }

  remove(reason: AbsenceReason): void {
    const options: NgbModalOptions = { backdrop: 'static', size: 'sm', centered: true };
    const modalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Причина неявки "${reason.name}" будет удалена. Продолжить?`;

    modalRef.result.then(
      (): void => {
        this.reasonsService.RemoveAsync(reason.id)
          .subscribe(
            async (): Promise<void> => {
              this.toastrService.success('Причина перерыва удалена', 'Успешно');

              this.load();
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {

                for (const error of response.error.errors) {
                  switch (error.status) {
                    case 1: this.toastrService.warning('Доступ запрещен', 'Ошибка'); break;
                    case 2: this.toastrService.warning('Необходимо указать название', 'Ошибка'); break;
                    case 3: this.toastrService.warning('Длина названия не может превышать 512 символов', 'Ошибка'); break;
                    case 4: this.toastrService.warning('Причина не найдена', 'Ошибка'); break;

                    default: this.toastrService.warning('Не удалось сохранить причину неявки', 'Ошибка');
                  }
                }
              } else {
                this.toastrService.error(`Не удалось удалить причину перерыва "${reason.name}"`, 'Ошибка');
              }

            }
          );
      },
      (): void => { }
    );

  }

  private async load(): Promise<void> {
    const value: { search: string } = this.form.getRawValue();

    this.loading = true;

    this.reasons = await this.reasonsService.ReasonsAsync().toPromise();

    if (!value.search) {
      this.reasons$.next([...this.reasons]);
    } else {
      const regex = new RegExp(value.search, 'i');
      this.reasons$.next(this.reasons.filter(x => regex.test(x.name)));
    }

    this.loading = false;

  }

}
