export class TreatmentsHelper {
  static getDosage(dosage?: string, dosageUnit?: string): string {
    return `${dosage || ''} ${dosageUnit || ''}`.trim();
  }

  static getTimes(value: number) {
    if (value === 1) return 'раз';
    if (value % 100 == 12 || value % 100 === 13 || value % 100 === 14) return 'раз';
    if (value % 10 === 2 || value % 10 === 3 || value % 10 === 4) return 'раза';
    return 'раз';
  }

  static getDuration(duration?: string, durationUnit?: string): string {
    if (!durationUnit) {
      return duration || '';
    }

    const value = parseInt(duration);

    if (isNaN(value)) {
      return `${duration || ''} ${durationUnit}`.trim();
    }

    //TODO: put more checks here

    return `${value} ${durationUnit}`.trim();
  }

  static getQuantity(quantity?: string, quantityUnit?: string): string {
    if (quantity || quantityUnit) {
      return `${quantity || ''} ${quantityUnit || ''}`.trim();
    }
    return '';
  }

  static getRepeats(repeats?: string, unit?: string): string {

    if (!repeats && !unit) {
      return repeats || '';
    }

    const value = parseInt(repeats);

    if (isNaN(value)) {
      return `${repeats || ''} раз в ${unit}`;
    }

    if (unit.includes('раз')) {
      return `${repeats} ${unit}`;
    }

    //TODO: put more checks here
    return `${repeats} раз в ${unit}`;
  }

  static getPeriodUnit(unit: 0 | 1 | 2 | 3 | 4 | 5, value?: number) {
    if (unit === 1) {
      if (!value) return 'час';
      if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) return `часов`;
      if (value % 10 === 1) return `час`;
      if (value % 10 === 2 || value % 10 == 3 || value % 10 === 4) return `часа`;
      return `часов`;
    }

    if (unit === 2) {
      if (!value) return 'день';
      if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) return `дней`;
      if (value % 10 === 1) return `день`;
      if (value % 10 === 2 || value % 10 == 3 || value % 10 === 4) return `дня`;
      return `дней`;
    }

    if (unit === 3) {
      if (!value) return 'неделя';
      if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) return `недель`;
      if (value % 10 === 1) return `неделя`;
      if (value % 10 === 2 || value % 10 == 3 || value % 10 === 4) return `недели`;
      return `недель`;
    }

    if (unit === 4) {
      if (!value) return 'месяц';
      if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) return `месяцев`;
      if (value % 10 === 1) return `месяц`;
      if (value % 10 === 2 || value % 10 == 3 || value % 10 === 4) return `месяца`;
      return `месяцев`;
    }

    if (unit === 5) {
      if (!value) return 'год';
      if (value % 100 === 11 || value % 100 === 12 || value % 100 === 13) return `лет`;
      if (value % 10 === 1) return `год`;
      if (value % 10 === 2 || value % 10 == 3 || value % 10 === 4) return `года`;
      return `лет`;
    }

    return '';
  }

  static ageUnit(value: number, unit: 0 | 1 | 2 | 3 | 4) {
    if (!value) return '';

    if (unit === 1) {
      if (value % 100 === 11) return `дней`;
      if (value % 10 === 1) return `дня`;
      return `дней`;
    }

    if (unit === 2) {
      if (value % 100 === 11) return `недель`;
      if (value % 10 === 1) return `недели`;
      return `недель`;
    }

    if (unit === 3) {
      if (value % 100 === 11) return `месяцев`;
      if (value % 10 === 1) return `месяца`;
      return `месяцев`;
    }

    if (unit === 4) {
      if (value % 100 === 11) return `лет`;
      if (value % 10 === 1) return `года`;
      return `лет`;
    }

    return '';
  }

  static genderRestriction(gender: 0 | 1 | 2 | 3) {
    if (gender === 1) {
      return 'Только женщины';
    }

    if (gender === 2) {
      'Только мужчины';
    }

    return '';
  }

  static agePeriodRestriction(from: number, fromUnit: 0 | 1 | 2 | 3 | 4, to: number, toUnit: 0 | 1 | 2 | 3 | 4) {
    if (from > 0 && to > 0) {
      if (fromUnit === toUnit) {
        return `от ${from} до ${to} ${this.ageUnit(to, toUnit)}`
      }

      return `от ${from} ${this.ageUnit(from, fromUnit)} до ${to} ${this.ageUnit(to, toUnit)}`;
    }

    if (from > 0) {
      return `от ${from} ${this.ageUnit(from, fromUnit)}`;
    }

    if (to > 0) {
      return `до ${to} ${this.ageUnit(to, toUnit)}`;
    }

    return '';

  }

  static getSchema(quantity?: string, quantityUnit?: string,
    repeats?: string, repeatsUnitName?: string,
    method?: string, time?: string, mealRelation?: string, postpone?: string): string {
    const schemaParts: string[] = [];

    schemaParts.push(this.getQuantity(quantity, quantityUnit));
    schemaParts.push(this.getRepeats(repeats, repeatsUnitName));
    schemaParts.push(method);
    schemaParts.push(time);
    schemaParts.push(mealRelation)
    schemaParts.push(postpone);

    return schemaParts.filter(x => x).join(' ');
  }
}
