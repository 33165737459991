import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordNeighbor } from '../../../../generated/models';

@Component({
  selector: 'mp-revision-navigation-group',
  templateUrl: './revision-navigation-group.component.html',
  styleUrls: ['./revision-navigation-group.component.scss']
})
export class RevisionNavigationGroupComponent implements OnInit {
  @Input() records: RecordNeighbor[];
  @Input() direction: NavigatorDirection;
  @Input() readonly: boolean;

  @Output()
  onNavigate: EventEmitter<number> = new EventEmitter<number>();

  get title(): string {
    switch (this.direction) {
      case NavigatorDirection.BACKWARD: return 'Предыдущая';
      case NavigatorDirection.FORWARD: return 'Следующая';

      default: return '';
    }
  }

  get titlePlural(): string {
    switch (this.direction) {
      case NavigatorDirection.BACKWARD: return 'Предыдущие';
      case NavigatorDirection.FORWARD: return 'Следующие';

      default: return '';
    }
  }

  constructor() { }

  ngOnInit() { }

  onDropdownItemClicked(item: RecordNeighbor): void {
    this.onNavigate.emit(item.id);
  }

  onRecordBtnClicked(): void {
    var next = this.records.length > 0 ? this.records[0] : null;
    if (!!next && next.id > 0) {
      this.onNavigate.emit(next.id);
    }
  }

  onRecordSelected(record: RecordNeighbor): void {
    this.onNavigate.emit(record.id);
  }

  getFirstNeighbor(): RecordNeighbor {
    return this.records.length > 0 ? this.records[0] : null;
  }

  get isForward(): boolean {
    return this.direction === NavigatorDirection.FORWARD;
  }
}

export enum NavigatorDirection {
  FORWARD,
  BACKWARD
}
