import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClinicalRecommendation, EmployeeRecentManipulation, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-recent-prescriptions-recommendations',
  templateUrl: './recent-prescriptions-recommendations.component.html',
  styleUrls: ['./recent-prescriptions-recommendations.component.scss']
})
export class RecentPrescriptionsRecommendationsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() items: EmployeeRecentManipulation[] = [];
  @Input() selected: SectionsServicePrescription[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];

  @Output() onSelect = new EventEmitter<EmployeeRecentManipulation>();

  results = new BehaviorSubject<EmployeeRecentManipulation[]>([]);

  form = new FormGroup({
    search: new FormControl('')
  });
  
  constructor() {

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: FiltersValue): void => {
        if (!value.search) {
          this.results.next(this.items);
        } else {
          const regex = new RegExp(value.search, 'i')
          this.results.next(this.items.filter(x => regex.test(x.manipulationName)));
        }
      });

  }

  ngOnInit() {
    this.results.next(this.items);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  isSelected = (item: EmployeeRecentManipulation) => this.selected.some(x => x.name == item.manipulationName);

  select = (item: EmployeeRecentManipulation) => this.onSelect.emit(item);

  recommendationsFor = (item: EmployeeRecentManipulation, type: 0 | 1) => this.recommendations
    .filter(x => x.type === type && x.manipulationName === item.manipulationName)
    .map(x => ({ title: x.clinicalRecommendationName, text: x.text }));
}

interface FiltersValue {
  search: string;
}
