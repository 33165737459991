import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from "moment";
import { takeUntil } from 'rxjs/operators';
import { PromotionResolverPayload } from '../../resolvers/promotion.resolver';
import { PromotionResponse } from '../../../../generated/models';
import { PromotionsService } from '../../../../generated/services/promotions.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';

@Component({
  selector: 'mp-promotion-editor',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  host: { class: "page" }
})
export class PromotionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  title = 'Новый канал';

  promotionForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    effective: new FormControl(undefined, [Validators.required]),
    expires: new FormControl(undefined),
  });

  constructor(
    private promotionsService: PromotionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private location: Location
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { payload: PromotionResolverPayload }): void => {
          this.id = data.payload.promotion.id;

          this.title = this.id > 0 ? `${data.payload.promotion.name}` : "Новый канал";

          this.promotionForm.patchValue({
            name: data.payload.promotion.name,
            effective: moment(data.payload.promotion.effective, "DD.MM.YYYY"),
            expires: moment(data.payload.promotion.expires, "DD.MM.YYYY")
          });
        });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  acceptChanges(): void {
    const value: FormValue = this.promotionForm.getRawValue();

    if (this.id > 0) {
      this.promotionsService.Edit({
        promotionId: this.id, model: {
          name: value.name,
          effective: value.effective.format("DD.MM.YYYY"),
          expires: value.expires ? value.expires.format("DD.MM.YYYY") : ""
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("", "Канал сохранен");
            this.back()
          },
          (error: HttpErrorResponse): void => {
            this.toastrService.error("Не удалось сохранить канал", "Ошибка")
          }
        );
    } else {
      this.promotionsService.Create({
        name: value.name,
        effective: value.effective.format("DD.MM.YYYY"),
        expires: value.expires ? value.expires.format("DD.MM.YYYY") : ""
      })
        .subscribe(
          (created: PromotionResponse): void => {
            this.back();
            //this.router.navigate(["../", created.id], { relativeTo: this.activatedRoute });
          },
          (error: HttpErrorResponse): void => {
            this.toastrService.error("Не удалось сохранить канал", "Ошибка")
          }
        );
    }
  }

  back = () => this.location.back();
}

interface FormValue {
  name: string;
  effective: moment.Moment;
  expires: moment.Moment;
}
