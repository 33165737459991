import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleBreakReasonsComponent } from './components/schedule-break-reasons/schedule-break-reasons.component';
import { ScheduleBreakReasonComponent } from './components/schedule-break-reason/schedule-break-reason.component';
import { ScheduleBreakReasonListItemComponent } from './components/schedule-break-reason-list-item/schedule-break-reason-list-item.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { ScheduleBreakReasonResolver } from './resolvers/schedule-break-reason-resolver';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: 'new',
    component: ScheduleBreakReasonComponent,
    resolve: {
      payload: ScheduleBreakReasonResolver
    },
    data: {
      title: 'Новая причина перерыва',
      requirements: [PermissionNames.ManageScheduleBreakReasons]
    }
  },
  {
    path: ':id',
    component: ScheduleBreakReasonComponent,
    resolve: {
      payload: ScheduleBreakReasonResolver
    },
    data: {
      title: 'Причина перерыва',
      requirements: [PermissionNames.ManageScheduleBreakReasons]
    }
  },
  {
    path: '',
    component: ScheduleBreakReasonsComponent,
    data: {
      title: 'Причины перерывов',
      requirements: [PermissionNames.ManageScheduleBreakReasons]
    }
  }
];

@NgModule({
  declarations: [ScheduleBreakReasonsComponent, ScheduleBreakReasonComponent, ScheduleBreakReasonListItemComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    InfiniteScrollModule,
    ToastrModule,
    NgbModalModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ]
})
export class ScheduleBreakReasonSettingsModule { }
