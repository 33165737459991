/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonInfo } from '../models/person-info';
import { PersonQuizRun } from '../models/person-quiz-run';
import { AddQuizRunResponse } from '../models/add-quiz-run-response';
import { AddQuizRunRequest } from '../models/add-quiz-run-request';
import { Medcard } from '../models/medcard';
import { PersonQuizRunResult } from '../models/person-quiz-run-result';
import { PersonsVisit } from '../models/persons-visit';
import { ChangePersonsVisitRequest } from '../models/change-persons-visit-request';
import { CreateAccountRequest } from '../models/create-account-request';
import { LinkAccountRequest } from '../models/link-account-request';
import { PeopleMergeRequest } from '../models/people-merge-request';
import { DuplicateSearchResponse } from '../models/duplicate-search-response';
import { ConnectedPatient } from '../models/connected-patient';
import { Representative } from '../models/representative';
import { LabResult } from '../models/lab-result';
import { Person } from '../models/person';
@Injectable({
  providedIn: 'root',
})
class PeopleService extends __BaseService {
  static readonly PersonAsyncPath = '/api/v1/People/{id}';
  static readonly QuizRunsPath = '/api/v1/People/{id}/quizruns';
  static readonly AddQuizRunPath = '/api/v1/People/{id}/quizruns';
  static readonly MedcardsPath = '/api/v1/People/{id}/medcards';
  static readonly QuizRunPath = '/api/v1/People/{id}/quizruns/{runId}';
  static readonly VisitsPath = '/api/v1/People/{id}/visits';
  static readonly ChangePatientsVisitPath = '/api/v1/People/{id}/visits/{visitId}';
  static readonly CreateAccountPath = '/api/v1/People/{id}/account';
  static readonly RemoveAccountPath = '/api/v1/People/{id}/account';
  static readonly LinkAccountAsyncPath = '/api/v1/People/{id}/accounts/link';
  static readonly RestorePasswordPath = '/api/v1/People/{id}/restore-password';
  static readonly MergePath = '/api/v1/People/{id}/merge';
  static readonly DuplicatesSearchPath = '/api/v1/People/search/duplicates';
  static readonly GetDuplicatesPath = '/api/v1/People/{id}/duplicates';
  static readonly GetChildrenPath = '/api/v1/People/{id}/children';
  static readonly GetRepresentativesPath = '/api/v1/People/{id}/representatives';
  static readonly GetLabResultsPath = '/api/v1/People/{id}/lab';
  static readonly PeopleAsyncPath = '/api/v1/People';
  static readonly FindRepresentativesAsyncPath = '/api/v1/People/representatives';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  PersonAsyncResponse(id: number): __Observable<__StrictHttpResponse<PersonInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonInfo>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PersonAsync(id: number): __Observable<PersonInfo> {
    return this.PersonAsyncResponse(id).pipe(
      __map(_r => _r.body as PersonInfo)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  QuizRunsResponse(id: number): __Observable<__StrictHttpResponse<Array<PersonQuizRun>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/quizruns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonQuizRun>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  QuizRuns(id: number): __Observable<Array<PersonQuizRun>> {
    return this.QuizRunsResponse(id).pipe(
      __map(_r => _r.body as Array<PersonQuizRun>)
    );
  }

  /**
   * @param params The `PeopleService.AddQuizRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddQuizRunResponse(params: PeopleService.AddQuizRunParams): __Observable<__StrictHttpResponse<AddQuizRunResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/People/${params.id}/quizruns`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddQuizRunResponse>;
      })
    );
  }
  /**
   * @param params The `PeopleService.AddQuizRunParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddQuizRun(params: PeopleService.AddQuizRunParams): __Observable<AddQuizRunResponse> {
    return this.AddQuizRunResponse(params).pipe(
      __map(_r => _r.body as AddQuizRunResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  MedcardsResponse(id: number): __Observable<__StrictHttpResponse<Array<Medcard>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/medcards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Medcard>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Medcards(id: number): __Observable<Array<Medcard>> {
    return this.MedcardsResponse(id).pipe(
      __map(_r => _r.body as Array<Medcard>)
    );
  }

  /**
   * @param params The `PeopleService.QuizRunParams` containing the following parameters:
   *
   * - `runId`:
   *
   * - `id`:
   *
   * @return Success
   */
  QuizRunResponse(params: PeopleService.QuizRunParams): __Observable<__StrictHttpResponse<PersonQuizRunResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${params.id}/quizruns/${params.runId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PersonQuizRunResult>;
      })
    );
  }
  /**
   * @param params The `PeopleService.QuizRunParams` containing the following parameters:
   *
   * - `runId`:
   *
   * - `id`:
   *
   * @return Success
   */
  QuizRun(params: PeopleService.QuizRunParams): __Observable<PersonQuizRunResult> {
    return this.QuizRunResponse(params).pipe(
      __map(_r => _r.body as PersonQuizRunResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  VisitsResponse(id: number): __Observable<__StrictHttpResponse<Array<PersonsVisit>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonsVisit>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Visits(id: number): __Observable<Array<PersonsVisit>> {
    return this.VisitsResponse(id).pipe(
      __map(_r => _r.body as Array<PersonsVisit>)
    );
  }

  /**
   * @param params The `PeopleService.ChangePatientsVisitParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangePatientsVisitResponse(params: PeopleService.ChangePatientsVisitParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/People/${params.id}/visits/${params.visitId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PeopleService.ChangePatientsVisitParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangePatientsVisit(params: PeopleService.ChangePatientsVisitParams): __Observable<null> {
    return this.ChangePatientsVisitResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PeopleService.CreateAccountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateAccountResponse(params: PeopleService.CreateAccountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/People/${params.id}/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PeopleService.CreateAccountParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateAccount(params: PeopleService.CreateAccountParams): __Observable<null> {
    return this.CreateAccountResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveAccountResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/People/${id}/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveAccount(id: number): __Observable<null> {
    return this.RemoveAccountResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PeopleService.LinkAccountAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  LinkAccountAsyncResponse(params: PeopleService.LinkAccountAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/People/${params.id}/accounts/link`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PeopleService.LinkAccountAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  LinkAccountAsync(params: PeopleService.LinkAccountAsyncParams): __Observable<null> {
    return this.LinkAccountAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RestorePasswordResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/People/${id}/restore-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RestorePassword(id: number): __Observable<null> {
    return this.RestorePasswordResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PeopleService.MergeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  MergeResponse(params: PeopleService.MergeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/People/${params.id}/merge`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PeopleService.MergeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Merge(params: PeopleService.MergeParams): __Observable<null> {
    return this.MergeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PeopleService.DuplicatesSearchParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `MiddleName`:
   *
   * - `LastName`:
   *
   * - `Gender`:
   *
   * - `FirstName`:
   *
   * - `BirthDate`:
   *
   * @return Success
   */
  DuplicatesSearchResponse(params: PeopleService.DuplicatesSearchParams): __Observable<__StrictHttpResponse<DuplicateSearchResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Phone != null) __params = __params.set('Phone', params.Phone.toString());
    if (params.MiddleName != null) __params = __params.set('MiddleName', params.MiddleName.toString());
    if (params.LastName != null) __params = __params.set('LastName', params.LastName.toString());
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.FirstName != null) __params = __params.set('FirstName', params.FirstName.toString());
    if (params.BirthDate != null) __params = __params.set('BirthDate', params.BirthDate.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/search/duplicates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DuplicateSearchResponse>;
      })
    );
  }
  /**
   * @param params The `PeopleService.DuplicatesSearchParams` containing the following parameters:
   *
   * - `Phone`:
   *
   * - `MiddleName`:
   *
   * - `LastName`:
   *
   * - `Gender`:
   *
   * - `FirstName`:
   *
   * - `BirthDate`:
   *
   * @return Success
   */
  DuplicatesSearch(params: PeopleService.DuplicatesSearchParams): __Observable<DuplicateSearchResponse> {
    return this.DuplicatesSearchResponse(params).pipe(
      __map(_r => _r.body as DuplicateSearchResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetDuplicatesResponse(id: number): __Observable<__StrictHttpResponse<Array<ConnectedPatient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/duplicates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ConnectedPatient>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetDuplicates(id: number): __Observable<Array<ConnectedPatient>> {
    return this.GetDuplicatesResponse(id).pipe(
      __map(_r => _r.body as Array<ConnectedPatient>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetChildrenResponse(id: number): __Observable<__StrictHttpResponse<Array<ConnectedPatient>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/children`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ConnectedPatient>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetChildren(id: number): __Observable<Array<ConnectedPatient>> {
    return this.GetChildrenResponse(id).pipe(
      __map(_r => _r.body as Array<ConnectedPatient>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetRepresentativesResponse(id: number): __Observable<__StrictHttpResponse<Array<Representative>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/representatives`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Representative>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetRepresentatives(id: number): __Observable<Array<Representative>> {
    return this.GetRepresentativesResponse(id).pipe(
      __map(_r => _r.body as Array<Representative>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetLabResultsResponse(id: number): __Observable<__StrictHttpResponse<Array<LabResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/${id}/lab`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<LabResult>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GetLabResults(id: number): __Observable<Array<LabResult>> {
    return this.GetLabResultsResponse(id).pipe(
      __map(_r => _r.body as Array<LabResult>)
    );
  }

  /**
   * @param params The `PeopleService.PeopleAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  PeopleAsyncResponse(params: PeopleService.PeopleAsyncParams): __Observable<__StrictHttpResponse<Array<Person>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Person>>;
      })
    );
  }
  /**
   * @param params The `PeopleService.PeopleAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  PeopleAsync(params: PeopleService.PeopleAsyncParams): __Observable<Array<Person>> {
    return this.PeopleAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Person>)
    );
  }

  /**
   * @param Phone undefined
   * @return Success
   */
  FindRepresentativesAsyncResponse(Phone?: string): __Observable<__StrictHttpResponse<Array<PersonInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Phone != null) __params = __params.set('Phone', Phone.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/People/representatives`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonInfo>>;
      })
    );
  }
  /**
   * @param Phone undefined
   * @return Success
   */
  FindRepresentativesAsync(Phone?: string): __Observable<Array<PersonInfo>> {
    return this.FindRepresentativesAsyncResponse(Phone).pipe(
      __map(_r => _r.body as Array<PersonInfo>)
    );
  }
}

module PeopleService {

  /**
   * Parameters for AddQuizRun
   */
  export interface AddQuizRunParams {
    id: number;
    request?: AddQuizRunRequest;
  }

  /**
   * Parameters for QuizRun
   */
  export interface QuizRunParams {
    runId: string;
    id: number;
  }

  /**
   * Parameters for ChangePatientsVisit
   */
  export interface ChangePatientsVisitParams {
    visitId: number;
    id: number;
    request?: ChangePersonsVisitRequest;
  }

  /**
   * Parameters for CreateAccount
   */
  export interface CreateAccountParams {
    id: number;
    request?: CreateAccountRequest;
  }

  /**
   * Parameters for LinkAccountAsync
   */
  export interface LinkAccountAsyncParams {
    id: number;
    request?: LinkAccountRequest;
  }

  /**
   * Parameters for Merge
   */
  export interface MergeParams {
    id: number;
    request?: PeopleMergeRequest;
  }

  /**
   * Parameters for DuplicatesSearch
   */
  export interface DuplicatesSearchParams {
    Phone?: string;
    MiddleName?: string;
    LastName?: string;
    Gender?: any;
    FirstName?: string;
    BirthDate?: string;
  }

  /**
   * Parameters for PeopleAsync
   */
  export interface PeopleAsyncParams {
    To?: string;
    From?: string;
  }
}

export { PeopleService }
