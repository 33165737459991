import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClinicalRecommendation, EmployeeFavoriteManipulation, SectionsServicePrescription } from '../../../../generated/models';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'mp-prescriptions-favorites',
  templateUrl: './prescriptions-favorites.component.html',
  styleUrls: ['./prescriptions-favorites.component.scss']
})
export class PrescriptionsFavoritesComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() selected: SectionsServicePrescription[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];
  @Input() items: EmployeeFavoriteManipulation[] = [];

  @Input() loading = false;

  @Output() onSelect = new EventEmitter<EmployeeFavoriteManipulation>();
  @Output() onUpdate = new EventEmitter<EmployeeFavoriteManipulation[]>();

  items$ = new BehaviorSubject<EmployeeFavoriteManipulation[]>([]);

  editing = false;
  deleting = false;

  edited: EmployeeFavoriteManipulation[] = [];

  form = new FormGroup({
    search: new FormControl('')
  });

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: FiltersValue) => {
          if (!value.search) {
            this.items$.next([...this.items]);
          } else {
            const regex = new RegExp(value.search, 'i');

            this.items$.next(this.items.filter(x => regex.test(x.manipulationName)));
          }
        }
      )
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      const items: EmployeeFavoriteManipulation[] = changes['items'].currentValue;
      const value: FiltersValue = this.form.getRawValue();

      const regex = new RegExp(value.search, 'i');

      this.items$.next(value.search ? items.filter(x => regex.test(x.manipulationName)) : [...items]);

      this.edited = [...items];
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  recommendationsFor = (item: EmployeeFavoriteManipulation, type: 0 | 1) => this.recommendations.filter(x => x.type === type && x.manipulationName === item.manipulationName);

  isSelected = (item: EmployeeFavoriteManipulation) => this.selected.some(x => x.name === item.manipulationName);
  select = (item: EmployeeFavoriteManipulation) => this.onSelect.emit(item);

  edit() {
    if (this.loading) return;

    this.edited = [...this.items];

    this.editing = true;
  }

  confirm() {
    if (this.loading) return;

    this.onUpdate.emit(this.edited);

    this.editing = false;
  }

  cancel() {
    if (this.loading) return;

    this.editing = false;
    this.edited = [...this.items];
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.edited, event.previousIndex, event.currentIndex);
  }

  remove(item: EmployeeFavoriteManipulation) {
    this.edited = this.edited.filter(x => x.manipulationId !== item.manipulationId);
  }

}

interface FiltersValue {
  search: string;
}
