import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

import * as moment from "moment";

import { ResultsModalComponent } from '../results/results.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CallCenterService, PeopleService, PatientsService, MyService, PersonMarksService } from 'projects/Clinic/src/app/generated/services';
import { Patient, Attachment } from '../../models/patient';
import { Visits } from '../../models/visits';
import { Assignments } from '../../models/assignments';
import { PhoneCalls } from '../../models/phone-calls';
import { Results } from '../../models/results';
import { PatientPayload } from '../../resolvers/patient-resolver';
import { Subject } from 'rxjs';
import { takeUntil, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { Medcard, PersonsVisit, ConnectedPatient, PatientSearchResult, Representative, LabResult, AccessTokenResponse, PersonMark, ColorMark } from '../../../../generated/models';
import { CreateCabinetModalComponent, CreateCabinetModel } from '../create-cabinet-modal/create-cabinet-modal.component';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { VisitEditModalComponent } from '../visit-edit-modal/visit-edit-modal.component';
import { RepresentativeSelectModalComponent } from '../representative-select-modal/representative-select-modal.component';
import { ConfirmationModalService } from "../../../shared/services/confirmation-modal-service";
import { UserStorage } from "../../../../services/user-storage";
import { PermissionNames } from "../../../../models/permission-names";
import { PersonMarkModalService } from "../../../person-marks/services/person-mark-modal.service";
import { PersonMarkModalComponent, PersonMarkModalPayload } from "../../../person-marks/components/person-mark-modal/person-mark-modal.component";
import { DocumentPreviewService } from "../../../shared/services/document-preview-service";

@Component({
  selector: 'mp-call-center-patients-id',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
  host: { class: "page" }
})
export class PatientComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  colorMarks: ColorMark[] = [];

  medcards: Medcard[] = [];
  visits: PersonsVisit[] = [];
  children: ConnectedPatient[] = [];
  representative: Representative;
  duplicates: ConnectedPatient[] = [];

  saveState: number;
  panelState: number;
  panelTitle: string;

  searchRequest: string;
  patientInitials: string;
  lastName: string;

  attachments: Attachment[] = [];

  selectedVisits: Visits[] = [];
  selectedRecords: number[] = [];
  selectedPhoneCalls: PhoneCalls[] = [];
  selectedAssignments: Assignments[] = [];
  selectedResults: number[] = [];

  recordsDateFrom: moment.Moment;
  recordsDateTo: moment.Moment;

  resultsDateFrom: moment.Moment;
  resultsDateTo: moment.Moment;

  phoneCallsDateFrom: moment.Moment;
  phoneCallsDateTo: moment.Moment;

  visitsDateFrom: moment.Moment;
  visitsDateTo: moment.Moment;

  patientId: number;
  patientCopy: Patient;
  representativeId: number;

  isFormChanged = false;
  submitted = false;

  hasAccount: boolean;
  representativeHasAccount: boolean;

  labResults: LabResult[] = [];
  marks: PersonMark[] = [];

  canAddMark = false;
  canManageMarks = false;

  get title(): string {
    const value: PatientFormValue = this.generalForm.getRawValue();
    return `Пациент: ${value.lastName} ${value.firstName} ${value.middleName}`;
  }
  get hasRepresentative(): boolean { return !!this.representative; }

  generalForm = new FormGroup({
    lastName: new FormControl("", [Validators.required]),
    firstName: new FormControl("", [Validators.required]),
    middleName: new FormControl("", []),
    dob: new FormControl("", [Validators.required]),
    gender: new FormControl(""),
    login: new FormControl(""),
    phone: new FormControl(""),
    inn: new FormControl("")
  });

  ageControl = new FormControl(undefined);

  constructor(
    private userStorage: UserStorage,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private service: CallCenterService,
    private peopleService: PeopleService,
    private patientsService: PatientsService,
    private toastrService: ToastrService,
    private myService: MyService,
    private router: Router,
    private confirmationModalService: ConfirmationModalService,
    private personMarksService: PersonMarksService,
    private personMarkModalService: PersonMarkModalService,
    private previewService: DocumentPreviewService
  ) {
    this.canAddMark = this.userStorage.hasPermission(PermissionNames.AddPersonMarks);
    this.canManageMarks = this.userStorage.hasPermission(PermissionNames.ManagePersonMarks);

    this.generalForm.get("login").disable({ emitEvent: false });
    this.generalForm.get("phone").disable({ emitEvent: false });

    this.generalForm.get("dob").valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: moment.Moment) => this.ageControl.setValue(value ? moment().diff(value, "years") : undefined));

    this.ageControl.disable();

    this.patientCopy = new Patient();

    this.visitsDateTo = moment();
    this.visitsDateFrom = moment().add(-5, "days");
  }

  async ngOnInit() {
    this.activatedRoute.data.subscribe(async (data: { payload: PatientPayload }): Promise<void> => {
      this.patientId = data.payload.patient.id;
      this.hasAccount = data.payload.patient.hasAccount;

      this.colorMarks = data.payload.colorMarks;

      const value: PatientFormValue = {
        firstName: data.payload.patient.firstname,
        middleName: data.payload.patient.middlename,
        lastName: data.payload.patient.lastname,
        dob: data.payload.patient.dob ? moment(data.payload.patient.dob, "DD.MM.YYYY") : undefined,
        login: data.payload.patient.login,
        gender: data.payload.patient.gender,
        phone: data.payload.patient.phone,
        inn: data.payload.patient.inn
      };

      this.lastName = value.lastName;

      if (value.dob) {
        this.ageControl.setValue(moment().diff(value.dob, "years"));
      }

      if (data.payload.patient.hasAccount) {
        this.generalForm.get("login").enable({ emitEvent: false });
        this.generalForm.get("phone").enable({ emitEvent: false });
      }

      this.generalForm.patchValue(value, { emitEvent: false });

      this.patientInitials = `${value.firstName.charAt(0)}.`
      if (value.middleName) {
        this.patientInitials += `${value.middleName.charAt(0)}.`;
      }

      this.medcards = data.payload.medcards;

      this.visits = data.payload.visits;
      this.children = data.payload.children;
      this.labResults = data.payload.labResults;
      this.duplicates = await this.loadDuplicates();

      for (const representative of data.payload.representative) {
        if (!representative.legalRepresentative) continue;

        if (!this.representative) {
          this.representative = representative;
          continue;
        }

        if (!representative.lastVisit) {
          continue;
        }

        if (!this.representative.lastVisit) {
          this.representative = representative;
          continue;
        }

        const selectedRepLatVisit = moment(this.representative.lastVisit, 'DD.MM.YYYY');
        const currentRepLastVisit = moment(representative.lastVisit, 'DD.MM.YYYY');

        if (currentRepLastVisit.isAfter(selectedRepLatVisit)) {
          this.representative = representative;
        }
      }

      this.representativeHasAccount = this.representative && this.representative.hasAccount;

      this.loadMarks();

    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async loadDuplicates(): Promise<ConnectedPatient[]> {
    return this.peopleService.GetDuplicates(this.patientId).toPromise();
  }

  async accept(values: PatientFormValue): Promise<void> {

    this.service.UpdatePatient({
      id: this.patientId,
      request: {
        firstname: values.firstName,
        middlename: values.middleName,
        lastname: values.lastName,
        dob: values.dob ? values.dob.format("DD.MM.YYYY") : "",
        gender: values.gender,
        email: values.login,
        phone: values.phone,
        inn: values.inn
      }
    })
      .subscribe(
        async (): Promise<void> => {
          this.lastName = values.lastName;
          this.patientInitials = `${values.firstName.charAt(0)}.`;
          if (values.middleName) {
            this.patientInitials += `${values.middleName.charAt(0)}.`;
          }
          this.toastrService.success("Данные пациента обновлены", "Успешно");

          this.duplicates = await this.loadDuplicates();
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }

          this.toastrService.error("Не удалось обновить данные пациента", "Ошибка");
        }
      );
  }

  /** Открыть модальное окно создания личного кабинета */
  createAccount(): void {
    const options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      windowClass: "modal-small"
    };

    const modalRef: NgbModalRef = this.modal.open(CreateCabinetModalComponent, options);
    const componentInstance: CreateCabinetModalComponent = modalRef.componentInstance;

    componentInstance.onSubmit
      .subscribe(
        (value: CreateCabinetModel): void => {
          this.peopleService.CreateAccount({
            id: this.patientId,
            request: { email: value.email, phone: value.phone }
          })
            .subscribe(
              (): void => {
                //if (!this._hasRepresentative) {
                this.hasAccount = true;
                this.generalForm.patchValue({ login: value.email, phone: value.phone }, { emitEvent: false, onlySelf: true });

                this.generalForm.get("login").enable({ emitEvent: false });
                this.generalForm.get("phone").enable({ emitEvent: false });

                this.generalForm.get("login").markAsPristine();
                this.generalForm.get("phone").markAsPristine();
                //}
                //else {
                //  this.toastrService.success("Личный кабинет представителя создан", "Успешно")
                //}

                modalRef.dismiss();
              },
              (response: HttpErrorResponse): void => {
                modalRef.componentInstance.processing = false;
                if (response.status === 400) {
                  switch (response.error.status) {
                    case 7:
                      modalRef.componentInstance.form.get("email").setErrors({ required: true });
                      break;
                    case 8:
                      modalRef.componentInstance.form.get("phone").setErrors({ required: true });
                      break;
                    case 9:
                      modalRef.componentInstance.form.get("phone").setErrors({ inUse: true });
                      break;
                    case 10:
                      modalRef.componentInstance.form.get("email").setErrors({ inUse: true });
                      break;
                  }

                  this.toastrService.warning(response.error.message, "Ошибка");
                  return;
                }

                this.toastrService.error("Не удалось зарегистрировать кабинет. Повторите операцию позже", "Ошибка");
              }
            )
        }
      );
  }

  createRepresentativeAccount(): void {
    const modalRef: NgbModalRef = this.modal.open(CreateCabinetModalComponent, { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-small" });

    modalRef.componentInstance.onSubmit
      .subscribe(
        (value: CreateCabinetModel): void => {
          this.peopleService.CreateAccount({ id: this.representative.id, request: { email: value.email, phone: value.phone } })
            .subscribe(
              (): void => {
                this.representativeHasAccount = true;
                this.toastrService.success("Личный кабинет представителя создан", "Успешно")

                modalRef.close();
                this.redirectToRepresentative();
              },
              (response: HttpErrorResponse): void => {
                modalRef.componentInstance.processing = false;

                if (response.status === 400) {
                  switch (response.error.status) {
                    case 7:
                      modalRef.componentInstance.form.get("email").setErrors({ required: true });
                      break;
                    case 8:
                      modalRef.componentInstance.form.get("phone").setErrors({ required: true });
                      break;
                    case 9:
                      modalRef.componentInstance.form.get("phone").setErrors({ inUse: true });
                      break;
                    case 10:
                      modalRef.componentInstance.form.get("email").setErrors({ inUse: true });
                      break;
                  }

                  this.toastrService.warning(response.error.message, "Ошибка");
                  return;
                }

                this.toastrService.error("Не удалось зарегистрировать кабинет представителя. Повторите операцию позже", "Ошибка");
              }
            )
        }
      );
  }

  redirectToRepresentative = () => window.open(`${location.origin}/call-center/patients/${this.representative.id}`, '_self');

  /** Вызвать окно подтверждения и удалить личный кабинет */
  removeAccount(): void {
    const options: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
      centered: true,
      windowClass: "modal-confirm"
    };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);

    modalRef.componentInstance.message = "Удалить личный кабинет пациента?";

    modalRef.result.then(
      (): void => {
        this.peopleService.RemoveAccount(this.patientId)
          .subscribe(
            (): void => {
              this.hasAccount = false;

              this.generalForm.patchValue({ login: "", phone: "" }, { emitEvent: false, onlySelf: true });

              this.generalForm.get("login").disable({ emitEvent: false, onlySelf: true });
              this.generalForm.get("phone").disable({ emitEvent: false, onlySelf: true });

              this.generalForm.get("login").markAsPristine();
              this.generalForm.get("phone").markAsPristine();
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }

              this.toastrService.error("Не удалось удалить личный кабинет пользователя", "Ошибка");
            }
          );
      },
      (): void => { }
    );

  }

  /** Вызвать метод сброса пароля */
  resetPassword(): void {
    const options: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
      centered: true,
      windowClass: "modal-confirm"
    };

    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);

    modalRef.componentInstance.message = "Текущий пароль личного кабинета будет заменен сгенерированным и отправлен пользователю. Продолжить? ";
    modalRef.componentInstance.confirmBtnText = "Продолжить?";

    modalRef.result.then(
      (): void => {
        this.peopleService.RestorePassword(this.patientId)
          .subscribe(
            (): void => {
              this.toastrService.success("Новый пароль отправлен пользователю", "Успешно");
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }

              this.toastrService.error("Не удалось сбросить пароль. попробуйте повторить позже", "Ошибка");
            }
          );


      },
      (): void => { }
    );
  }

  /**
   *  Открыть форму посещения для указанного посещения
   * @param visit
   */
  open = (visit: PersonsVisit) => window.open(`${location.origin}/visits/${visit.id}`, "_blank");

  /**
   * Открыть попап для редактирования указанного посещения 
   */
  edit(visit: PersonsVisit): void {
    const modalRef: NgbModalRef = this.modal.open(VisitEditModalComponent, { size: 'lg', backdrop: 'static' });

    modalRef.componentInstance.visit = visit;

    modalRef.result.then(
      (value: { phone: string, lastName: string }) => {
        console.log(value)
        this.peopleService.ChangePatientsVisit({
          visitId: visit.id,
          id: this.patientId,
          request: value
        }).subscribe(
          (response) => {
            let index = this.visits.findIndex(x => x.id === visit.id);
            if (index !== -1) {
              this.visits[index].lastname = value.lastName;
              this.visits[index].phone = value.phone;
            }
          },
          (error) => {
            this.toastrService.error("Ошибка сохранения посещения", "Ошибка")
          })
      },
      () => { }
    )
  }

  updateVisitsRange = (range: { from: moment.Moment, to: moment.Moment }): void => {
    this.visitsDateFrom = range.from;
    this.visitsDateTo = range.to;
  }

  updateRecordsRange = (range: { from: moment.Moment, to: moment.Moment }): void => {
    this.recordsDateFrom = range.from;
    this.recordsDateTo = range.to;
  }

  updateResultsRange = (range: { from: moment.Moment, to: moment.Moment }): void => {
    this.resultsDateFrom = range.from;
    this.resultsDateTo = range.to;
  }

  updatePhoneCallsRange = (range: { from: moment.Moment, to: moment.Moment }): void => {
    this.phoneCallsDateFrom = range.from;
    this.phoneCallsDateTo = range.to;
  }

  correctPhone(phone: string): string {
    if (!phone) return "";
    if (phone.length < 10 || phone.length > 11) return phone;
    var res = phone.replace(/\D+/g, '');
    if (res.length === 11) {
      res = res.substring(1);
    }
    res = '7(' + res.substring(0, 3) + ')' + res.substring(3, 6) + '-' + res.substring(6, 8) + '-' + res.substring(8, 10);
    return res;
  }

  isValidPhone(phone: string): boolean {
    if (phone == null) return false;
    if (phone.replace(/\D+/g, '').length == 7 && phone.length == 7) {
      return true;
    }
    var reg = /\b7\(\d{3}\)\d{3}-\d{2}-\d{2}\b/;
    //var validRegEx = /^((8|\+7|7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    var flag = reg.test(phone);
    if (!flag) {
      //this.patientForm.controls["phone"].setErrors({});
    }
    return flag;

  }

  panelStateChanged = panelState => this.panelState = panelState;

  sendResultsClick(results: Results[]) {
    const modal: NgbModalRef = this.modal.open(ResultsModalComponent, { centered: true, backdrop: 'static' });

    modal.result.then(() => { }, () => { });

    modal.componentInstance.results = results;
    modal.componentInstance.title = "Отправка результатов";
  }

  changeResultSelection = (value: { id: number, value: boolean }) => {
    if (!value.id) return;

    const index: number = this.selectedResults.findIndex(x => x === value.id);

    if (value.value && index === -1) this.selectedResults.push(value.id);
    if (!value.value && index !== -1) this.selectedResults.splice(index, 1);

  }

  selectConnectedPatient = (patient: ConnectedPatient) => window.open(`${location.origin}/call-center/patients/${patient.id}`, "_blank");

  async mergePatient(patient: ConnectedPatient) {
    const confirm = await this.confirmationModalService.open({ message: 'Выполнить слияние с текущим пациентом?', confirmBtnText: 'Да' });

    if (!confirm) return;

    try {
      await this.peopleService.Merge({ id: this.patientId, request: { victimId: patient.id } }).toPromise();
      const deletedIndex = this.duplicates.findIndex(p => p.id === patient.id);
      if (deletedIndex !== -1) {
        this.duplicates.splice(deletedIndex, 1);
      }
      this.toastrService.success("Двойник успешно объединён", "Успешно");

    } catch (e) {
      const response = e as HttpErrorResponse;

      if (response.status === 400) {
        this.toastrService.error(response.error.message, "Ошибка")
      } else {
        this.toastrService.error("Ошибка объединения", "Ошибка");
      }
    }
  }

  connectRepresentative(medcard: Medcard) {
    const modalRef: NgbModalRef = this.modal.open(RepresentativeSelectModalComponent, { size: 'lg', backdrop: 'static', centered: true, windowClass: "modal-default-size" });

    modalRef.componentInstance.selectedPatientId = medcard.representativeId;

    modalRef.componentInstance.onClose.subscribe(() => modalRef.dismiss());

    modalRef.componentInstance.onSelect
      .pipe(
        tap((): void => { modalRef.close() }),
        switchMap((selected: PatientSearchResult) => {
          const modifiedIndex = this.medcards.findIndex(x => x.id === medcard.id);
          if (modifiedIndex !== -1) {
            this.medcards[modifiedIndex].hasRepresentative = true;
            this.medcards[modifiedIndex].representativeId = selected.id;
            this.medcards[modifiedIndex].representativeName = `${selected.lastname} ${selected.firstname} ${selected.middlename}`;
          }

          return this.patientsService.AddRepresentative({ id: medcard.id, request: { representativeId: selected.personId } });
        })
      )
      .subscribe(
        (): void => { },
        (error: HttpErrorResponse): void => {
          const modifiedIndex = this.medcards.findIndex(x => x.id === medcard.id);
          if (modifiedIndex !== -1) {
            this.medcards[modifiedIndex].hasRepresentative = false;
            this.medcards[modifiedIndex].representativeId = 0;
            this.medcards[modifiedIndex].representativeName = "";
          }

          if (error.status === 400) {
            this.toastrService.error(error.error.message, "Ошибка");
          }
          else {
            this.toastrService.error("Произошла ошибка при сохранении", "Ошибка");
          }
        }
      )
  }

  async removeRepresentative(medcard: Medcard) {
    const confirm = await this.confirmationModalService.open({ message: 'Отвязать представителя?', confirmBtnText: 'Да' });

    if (!confirm) return;

    this.patientsService.RemoveRepresentative(medcard.id)
      .subscribe(
        (): void => {
          const modifiedIndex: number = this.medcards.findIndex(x => x.id === medcard.id);
          if (modifiedIndex !== -1) {
            this.medcards[modifiedIndex].hasRepresentative = false;
            this.medcards[modifiedIndex].representativeId = 0;
            this.medcards[modifiedIndex].representativeName = "";
          }
        },
        (error: HttpErrorResponse): void => {
          if (error.status === 400) {
            this.toastrService.error(error.error.message, "Ошибка");
          }
          else {
            this.toastrService.error("Представитель не удален", "Ошибка")
          }
        }
      )

  }

  openAttachment(data: { id: number, testOrderId: number }) {
    const parameters = { id: data.id.toString(), testOrderId: data.testOrderId.toString() };

    this.myService.AccessToken({ parameters })
      .subscribe(
        (response: AccessTokenResponse) => {

          const component = this.previewService.open(`/api/clinic/v1/observations/attachment?token=${response.token}`);

          component.onDownload.subscribe(async () => {
            const response = await this.myService.AccessToken({ parameters }).toPromise();
            window.open(`/api/clinic/v1/observations/attachment?token=${response.token}&download=true`, "_blank");
          });
        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.toastrService.error(error.error.message, "Ошибка");
          }
          else {
            this.toastrService.error("Ошибка при отправке", "Ошибка");
          }
        }
      );
  }

  loadMarks() {
    this.personMarksService.PersonMarksAsync(this.patientId)
      .subscribe((response: PersonMark[]) => this.marks = response);
  }

  canRemoveMark = (mark: PersonMark) => this.canManageMarks || (this.canAddMark && mark.authorId === this.userStorage.profile.id);

  handleColorMarkError(response: HttpErrorResponse, message = '') {
    if (response.status === 400 && response.error) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Требуется разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Метка не найдена', 'Ошибка'); break;
          case 2: this.toastrService.warning('Метка пациента не найдена', 'Ошибка'); break;
          case 3: this.toastrService.warning('Пациент не найден', 'Ошибка'); break;
          case 4: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 5: this.toastrService.warning('Указанная метка уже добавлена', 'Ошибка'); break;
          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }
    } else {
      this.toastrService.error(message, 'Ошибка');
    }
  }

  addMark() {
    const modalRef = this.personMarkModalService.open();
    const componentRef: PersonMarkModalComponent = modalRef.componentInstance;

    componentRef.colorMarks = this.colorMarks;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((value: PersonMarkModalPayload) => {
      componentRef.processing = true;

      this.personMarksService.CreatePersonMarkAsync({ personId: this.patientId, request: { colorMarkId: value.colorMarkId, description: value.description } })
        .subscribe(
          () => {
            this.toastrService.success('Метка добавлена', 'Успешно');
            componentRef.processing = false;
            modalRef.close();

            this.loadMarks();
          },
          (response: HttpErrorResponse) => {
            componentRef.processing = false;
            this.handleColorMarkError(response, 'Не удалось добавить метку');
          }
        );


    });

  }

  async removeMark(mark: PersonMark) {
    const confirmed = await this.confirmationModalService.open({ message: `Метка ${mark.colorMarkName} будет удалена. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.personMarksService.RemovePersonMarkAsync({ personId: this.patientId, id: mark.id })
      .subscribe(
        () => {
          this.toastrService.success(`Метка ${mark.colorMarkName} удалена`, 'Успешно');

          this.loadMarks();
        },
        (response: HttpErrorResponse) => {
          this.handleColorMarkError(response, 'Не удалось удалить метку');
        }
      );

  }
}

interface PatientFormValue {
  lastName: string;
  firstName: string;
  middleName: string;
  dob: moment.Moment;
  gender: 0 | 1 | 2 | 3;
  login: string;
  phone: string;
  inn: string;
}


