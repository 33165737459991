import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { RecordSectionMetric } from '../../../../generated/models';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-section-metric',
  templateUrl: './section-metric.component.html',
  styleUrls: ['./section-metric.component.scss'],
  host: { class: 'mb-2 p-1' }
})
export class SectionMetricComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() disabled = false;
  @Input() required = false;
  @Input() dirty = false;

  @Input() metric: RecordSectionMetric = {};

  @Output() onChanges = new EventEmitter<string>();

  control = new FormControl();

  constructor() {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => this.onChanges.emit(value));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['metric']) {
      const metric: RecordSectionMetric = changes['metric'].currentValue;
      if (this.control.value != metric.value) {
        this.control.setValue(metric.value, { emitEvent: false });
      }

      if (metric.format === 3) {
        this.control.disable({ emitEvent: false });
      }
    }

    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled) {
        this.control.disable({ emitEvent: false });
      } else if (this.metric.format !== 3) {
        this.control.enable({ emitEvent: false });
      }
    }

    if (changes['required']) {
      const required: boolean = changes['required'].currentValue;

      if (required) {
        this.control.setValidators([Validators.required]);
      } else {
        this.control.clearValidators();
      }

      this.control.updateValueAndValidity({ emitEvent: false });
    }

    if (changes['dirty']) {
      const dirty: boolean = changes['dirty'].currentValue;

      if (dirty) {
        this.control.markAsDirty();
        this.control.markAsTouched();
      }

    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
