import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Pbx, PbxOperatorViewModel } from '../../../../generated/models';

@Component({
  selector: 'mp-phone-number-item',
  templateUrl: './phone-number-item.component.html',
  styleUrls: ['./phone-number-item.component.scss']
})
export class PhoneNumberItemComponent implements OnInit {

  @Input() operator: PbxOperatorViewModel;

  @Input() pbxList: Pbx[] = [];

  @Output()
  onRemove = new EventEmitter<void>();

  get pbxName(): string {
    if (!this.operator) return '';

    const pbx = this.pbxList.find(x => x.id === this.operator.pbx);

    return pbx ? pbx.name : '';
  }

  get phone(): string {
    if (!this.operator || !this.operator.directPhone) return '';
    if (this.operator.directPhone.length === 11) {
      const phone = this.operator.directPhone;

      return `+7 (${phone.substr(1, 3)}) ${phone.substr(4, 3)}-${phone.substr(7, 2)}-${phone.substr(9,2)}`;
    }

    return this.operator.directPhone;
  }

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }

}
