import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CodeSystem } from '../../../../generated/models';

@Component({
  selector: 'mp-code-systems-list-item',
  templateUrl: './code-systems-list-item.component.html',
  styleUrls: ['./code-systems-list-item.component.scss']
})
export class CodeSystemsListItemComponent implements OnInit {

  @Input() codeSystem: CodeSystem;

  @Output() onEdit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  public edit(): void {
    this.onEdit.emit();
  }

  public delete(): void {
    this.onDelete.emit();
  }

}
