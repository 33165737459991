import { EditPartnerPlanModel, RateTypeOption, RateDiscountBehaviorOption, ServiceCategory, ServiceCategoriesResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { PartnerPlansService, ServiceCategoriesService } from '../../../generated/services';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class PartnerPlanResolver implements Resolve<PartnerPlanResolverPayload> {

  public constructor(
    private service: PartnerPlansService,
    private categoriesService: ServiceCategoriesService
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PartnerPlanResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let planLoader: Observable<EditPartnerPlanModel> = null;
    let categoriesLoader: Observable<ServiceCategory[]> = null;

    if (id) {
      planLoader = this.service.Get(id);
      categoriesLoader = this.categoriesService.Categories({ Page: 1, Size: 0 })
        .pipe(
          map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
        );
    } else {
      const plan: any = {};

      planLoader = from([plan]);
      categoriesLoader = this.categoriesService.Categories({ Page: 1, Size: 0 })
        .pipe(
          map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
        );
    }

    return forkJoin({
      plan: planLoader,
      types: this.service.Types(),
      behaviors: this.service.Behaviours(),
      categories: categoriesLoader
    });
  }
}

export class PartnerPlanResolverPayload {
  public plan: EditPartnerPlanModel;
  public types: RateTypeOption[];
  public behaviors: RateDiscountBehaviorOption[];
  public categories: ServiceCategory[]

  public constructor() {
    this.types = [];
    this.behaviors = [];
    this.categories = [];
  }
}
