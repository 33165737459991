/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ManipulationService } from '../models/manipulation-service';
import { CreateManipulationServiceResponse } from '../models/create-manipulation-service-response';
import { CreateManipulationServiceRequest } from '../models/create-manipulation-service-request';
@Injectable({
  providedIn: 'root',
})
class ManipulationServicesService extends __BaseService {
  static readonly ServiceManipulationServicesAsyncPath = '/api/v2/services/{serviceId}/manipulations';
  static readonly ManipulationManipulationServicesAsyncPath = '/api/v2/manipulations/{manipulationId}/services';
  static readonly ManipulationServiceAsyncPath = '/api/v2/ManipulationServices/{id}';
  static readonly RemoveManipulationServiceAsyncPath = '/api/v2/ManipulationServices/{id}';
  static readonly CreateManipulationServiceAsyncPath = '/api/v2/ManipulationServices';
  static readonly MarkManipulationServiceAsDefaultAsyncPath = '/api/v2/manipulations/{manipulationId}/services/{serviceId}/mark-default';
  static readonly UnmarkManipulationServiceAsDefaultAsyncPath = '/api/v2/manipulations/{manipulationId}/services/{serviceId}/unmark-default';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceManipulationServicesAsyncResponse(serviceId: number): __Observable<__StrictHttpResponse<Array<ManipulationService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/${serviceId}/manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManipulationService>>;
      })
    );
  }
  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceManipulationServicesAsync(serviceId: number): __Observable<Array<ManipulationService>> {
    return this.ServiceManipulationServicesAsyncResponse(serviceId).pipe(
      __map(_r => _r.body as Array<ManipulationService>)
    );
  }

  /**
   * @param manipulationId undefined
   * @return Success
   */
  ManipulationManipulationServicesAsyncResponse(manipulationId: number): __Observable<__StrictHttpResponse<Array<ManipulationService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/manipulations/${manipulationId}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ManipulationService>>;
      })
    );
  }
  /**
   * @param manipulationId undefined
   * @return Success
   */
  ManipulationManipulationServicesAsync(manipulationId: number): __Observable<Array<ManipulationService>> {
    return this.ManipulationManipulationServicesAsyncResponse(manipulationId).pipe(
      __map(_r => _r.body as Array<ManipulationService>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManipulationServiceAsyncResponse(id: number): __Observable<__StrictHttpResponse<ManipulationService>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/ManipulationServices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManipulationService>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ManipulationServiceAsync(id: number): __Observable<ManipulationService> {
    return this.ManipulationServiceAsyncResponse(id).pipe(
      __map(_r => _r.body as ManipulationService)
    );
  }

  /**
   * @param id undefined
   */
  RemoveManipulationServiceAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/ManipulationServices/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveManipulationServiceAsync(id: number): __Observable<null> {
    return this.RemoveManipulationServiceAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateManipulationServiceAsyncResponse(request?: CreateManipulationServiceRequest): __Observable<__StrictHttpResponse<CreateManipulationServiceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/ManipulationServices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateManipulationServiceResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateManipulationServiceAsync(request?: CreateManipulationServiceRequest): __Observable<CreateManipulationServiceResponse> {
    return this.CreateManipulationServiceAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateManipulationServiceResponse)
    );
  }

  /**
   * @param params The `ManipulationServicesService.MarkManipulationServiceAsDefaultAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `manipulationId`:
   */
  MarkManipulationServiceAsDefaultAsyncResponse(params: ManipulationServicesService.MarkManipulationServiceAsDefaultAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/manipulations/${params.manipulationId}/services/${params.serviceId}/mark-default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ManipulationServicesService.MarkManipulationServiceAsDefaultAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `manipulationId`:
   */
  MarkManipulationServiceAsDefaultAsync(params: ManipulationServicesService.MarkManipulationServiceAsDefaultAsyncParams): __Observable<null> {
    return this.MarkManipulationServiceAsDefaultAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ManipulationServicesService.UnmarkManipulationServiceAsDefaultAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `manipulationId`:
   */
  UnmarkManipulationServiceAsDefaultAsyncResponse(params: ManipulationServicesService.UnmarkManipulationServiceAsDefaultAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/manipulations/${params.manipulationId}/services/${params.serviceId}/unmark-default`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ManipulationServicesService.UnmarkManipulationServiceAsDefaultAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `manipulationId`:
   */
  UnmarkManipulationServiceAsDefaultAsync(params: ManipulationServicesService.UnmarkManipulationServiceAsDefaultAsyncParams): __Observable<null> {
    return this.UnmarkManipulationServiceAsDefaultAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ManipulationServicesService {

  /**
   * Parameters for MarkManipulationServiceAsDefaultAsync
   */
  export interface MarkManipulationServiceAsDefaultAsyncParams {
    serviceId: number;
    manipulationId: number;
  }

  /**
   * Parameters for UnmarkManipulationServiceAsDefaultAsync
   */
  export interface UnmarkManipulationServiceAsDefaultAsyncParams {
    serviceId: number;
    manipulationId: number;
  }
}

export { ManipulationServicesService }
