import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-signature-loading-modal',
  templateUrl: './signature-loading-modal.component.html',
  styleUrls: ['./signature-loading-modal.component.scss']
})
export class SignatureLoadingModalComponent implements OnInit {

  @Input() message = "";

  constructor() { }

  ngOnInit() { }

}
