import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Speciality } from '../../../../generated/models';

@Component({
  selector: 'mp-speciality-item',
  templateUrl: './speciality-item.component.html',
  styleUrls: ['./speciality-item.component.scss']
})
export class SpecialityItemComponent implements OnInit {
  @Input() item: Speciality;
  @Input() canRemove = false;

  @Output() onRemove = new EventEmitter<void>();
  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();
}
