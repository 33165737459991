import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Company } from '../../../../generated/models';

@Component({
  selector: 'mp-parsed-patient',
  templateUrl: './parsed-patient.component.html',
  styleUrls: ['./parsed-patient.component.scss']
})
export class ParsedPatientComponent implements OnInit, OnDestroy, OnChanges {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input()
  patient: CheckingPatient;

  @Input()
  index: number;
  @Input()
  dirty: boolean;

  @Input()
  dmsInsuranceCompanies: Company[] = [];

  @Output()
  onChange: EventEmitter<CheckingPatient> = new EventEmitter<CheckingPatient>();

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      middlename: new FormControl("", []),
      lastname: new FormControl("", [Validators.required]),
      gender: new FormControl(undefined, [Validators.required]),
      dob: new FormControl(undefined, [Validators.required]),
      dmsCertificate: new FormControl("", [Validators.required]),
      dmsInsuranceCompanyId: new FormControl(undefined, [Validators.required])
    });

    this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      debounceTime(500)
    ).subscribe(
      (value: FormValue): void => {
        const payload: CheckingPatient = {
          id: this.patient.id,
          ...value,
          dob: value.dob ? value.dob.format("DD.MM.YYYY") : ""
        };

        this.onChange.emit(payload);
      }
    );
  }

  ngOnInit() {

    if (this.patient) {
      this.form.patchValue({ ...this.patient, dob: this.patient.dob ? moment(this.patient.dob, 'DD.MM.YYYY') : undefined }, { emitEvent: false });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dirty']) {
      const dirty: boolean = changes['dirty'].currentValue;

      if (dirty) {
        Object.entries(this.form.controls).forEach(x => {
          x[1].markAsDirty();
          x[1].markAsTouched();
        });
      }
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  remove(): void {
    this.onRemove.emit();
  }
}

export interface CheckingPatient {
  id: number;
  lastname: string;
  firstname: string;
  middlename: string;
  dob: string;
  gender: string;
  dmsCertificate: string;
  dmsInsuranceCompanyId: number;
}

interface FormValue {
  id: number;
  lastname: string;
  firstname: string;
  middlename: string;
  dob: moment.Moment;
  gender: string;
  dmsCertificate: string;
  dmsInsuranceCompanyId: number;
}
