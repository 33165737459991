import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DiseaseOutcome } from '../../../../generated/models';

@Component({
  selector: 'mp-oms-outcome-modal',
  templateUrl: './oms-outcome-modal.component.html',
  styleUrls: ['./oms-outcome-modal.component.scss']
})
export class OmsOutcomeModalComponent implements OnInit {

  @Input() title: string;
  @Input() outcomes: DiseaseOutcome[];
  @Input() selected: number;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  public select = (outcome: DiseaseOutcome): void => {
    this.activeModal.close(outcome)
  }

  public selectNone = (): void => {
    this.activeModal.close({ id: null });
  }

  public dismiss = (): void => {
    this.activeModal.dismiss();
  }

}
