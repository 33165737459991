import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { Company } from "../../../generated/models";
import { CompaniesService } from "../../../generated/services";

@Injectable({ providedIn: 'root' })
export class ImportPatientsResolver implements Resolve<ImportPatientsPayload> {
  constructor(private companiesService: CompaniesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ImportPatientsPayload> {
    const dmsInsuranceCompaniesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: 5 });

    return forkJoin({
      dmsInsuranceCompanies: dmsInsuranceCompaniesLoader
    });
  }
}

export interface ImportPatientsPayload {
  dmsInsuranceCompanies: Company[];
}
