import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mp-manage-organization-modal',
  templateUrl: './manage-organization.modal.component.html',
  styleUrls: ['./manage-organization.modal.component.scss']
})
export class ManageOrganizationModalComponent implements OnInit {
  public title: string;

  @Input() name: string;

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    this.title = "Удаление организации";
  }

  ngOnInit() {

    this.title = `Удаление организации "${this.name}"`;
  }

  public confirm(): void {
    this.onConfirm.emit();
  }

  public cancel(): void {
    this.onCancel.emit();
  }

}
