import { Injectable } from '@angular/core';
import { AccountService } from '../generated/services';
import { Observable, BehaviorSubject } from 'rxjs';
import { Profile } from '../generated/models';
import { tap, filter, map } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class UserStorage implements Resolve<Profile>{
  LOCAL_SETTINGS_KEY = 'MP_LOCAL_SETTINGS';

  profile: Profile;

  subject: BehaviorSubject<Profile> = new BehaviorSubject(null);

  constructor(private service: AccountService) { }

  resolve = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> => this.load();

  load = (): Observable<Profile> => this.service.My().pipe(tap((profile: Profile) => {
    this.profile = profile;
    this.subject.next(profile);
  }));

  hasPermission = (name: string) => !!this.profile && this.profile.permissions.some(x => (!x.companyId || x.companyId === this.profile.companyId) && x.name === name);

  get profileSource(): Observable<Profile> { return this.profile ? this.subject.pipe(filter(profile => !!profile)) : this.load(); }

  get localSettings(): Observable<LocalSettings> {
    return this.profileSource.pipe(map(profile => {
      const item = localStorage.getItem(this.LOCAL_SETTINGS_KEY + `_${profile.id}`);

      if (!item) return {};

      try {
        return JSON.parse(item);
      }
      catch (e) {
        return {};
      }
    }));
  }

  setWorkspaceInfoPanelOpened(state: boolean) {
    if (!this.profile) throw "Profile is not loaded yet";

    let settings: LocalSettings = {};

    const item = localStorage.getItem(this.LOCAL_SETTINGS_KEY + `_${this.profile.id}`);

    if (!item) {
      settings = {};
    } else {
      try {
        settings = JSON.parse(item);
      } catch (e) {
        settings = {};
      }
    }

    settings = { ...settings, workspaceInfoPanelOpened: state };

    localStorage.setItem(this.LOCAL_SETTINGS_KEY + `_${this.profile.id}`, JSON.stringify(settings));
  }

}
export interface LocalSettings {
  workspaceInfoPanelOpened?: boolean;
}
