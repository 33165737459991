import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';

@Component({
  selector: 'mp-employee-absence-modal',
  templateUrl: './employee-absence.modal.component.html',
  styleUrls: ['./employee-absence.modal.component.scss']
})
export class EmployeeAbsenceModalComponent implements OnInit {

  public title: string;

  public form: FormGroup;

  @Input("employeeId") employeeId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private employeesService: EmployeesService
  ) {
    this.form = new FormGroup({
      type: new FormControl("", [Validators.required]),
      from: new FormControl("", [Validators.required]),
      to: new FormControl("", [])
    });
  }

  ngOnInit() {
    this.title = "Новый отпуск или больничный";
  }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.employeesService.AddAbsence({
      id: this.employeeId,
      model: {
        type: this.form.get("type").value,
        from: this.form.get("from").value.format("DD.MM.YYYY"),
        to: this.form.get("to").value ? this.form.get("to").value.format("DD.MM.YYYY") : ""
      }
    })
      .subscribe(
        (): void => {
          this.activeModal.close();
        }
      );

  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

}
