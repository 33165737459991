import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DiagnosisRecommendation } from '../../../../generated/models';

@Component({
  selector: 'mp-recommendation-modal',
  templateUrl: './recommendation-modal.component.html',
  styleUrls: ['./recommendation-modal.component.scss']
})
export class RecommendationModalComponent implements OnInit, OnChanges {

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<{ name: string }>();

  @Input() processing = false;
  @Input() recommendation: DiagnosisRecommendation = {};

  form = new FormGroup({
    name: new FormControl()
  });

  constructor() { }

  ngOnInit() {
    this.form.patchValue({ ...this.recommendation });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['processing']) {
      const processing = changes['processing'].currentValue;

      if (processing) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    }

    if (changes['recommendation']) {
      const recommendation = changes['recommendation'].currentValue;
      this.form.patchValue({ ...recommendation });
    }
  }

  get canConfirm() { return this.form.valid; }

  cancel = () => this.onCancel.emit();
  confirm = () => this.onConfirm.emit(this.form.getRawValue());

}
