import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UsersTemplate } from '../../../../generated/models';
import { EmployeesService, MyService, PatientsService, PriceSetsService, VisitsService } from '../../../../generated/services';

@Component({
  selector: 'mp-add-service-modal',
  templateUrl: './add-service-modal.component.html',
  styleUrls: ['./add-service-modal.component.scss']
})
export class AddServiceModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() visitId: number;
  @Input() doctorId: number;
  @Input() processing: boolean = false;

  @Output()
  onConfirm: EventEmitter<ConfirmEvent> = new EventEmitter<ConfirmEvent>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  step = 0;
  selectedItem: AddServiceItem = null;
  items: AddServiceItem[] = [];
  templates: UsersTemplate[] = [];

  serviceSearchControl = new FormControl();
  templateSearchControl = new FormControl();

  services$: BehaviorSubject<AddServiceItem[]> = new BehaviorSubject<AddServiceItem[]>([]);
  templates$: BehaviorSubject<UsersTemplate[]> = new BehaviorSubject<UsersTemplate[]>([]);

  constructor(private employeesService: EmployeesService,
    private myService: MyService,
    private visitsService: VisitsService,
    private patientsService: PatientsService,
    private priceSetsService: PriceSetsService) {

  }

  async ngOnInit(): Promise<void> {

    const stage1 = await forkJoin({
      visit: this.visitsService.Visit(this.visitId),
      patient: this.patientsService.PatientForVisit(this.visitId),
      services: this.employeesService.Services({ id: this.doctorId, Size: 0 }),
      templates: this.myService.Templates(),
    }).toPromise();

    this.templates = stage1.templates;

    const visit = stage1.visit;
    const patient = stage1.patient;
    const services = stage1.services.items;

    const stage2 = await forkJoin({
      prices: this.priceSetsService.Prices({ id: visit.priceSetId, PatientId: patient.id })
    }).toPromise();

    const prices = stage2.prices.items;

    const items: AddServiceItem[] = [];

    for (const service of services) {
      const price = prices.find(x => x.serviceId === service.serviceId);

      if (!price) continue;

      if ((service.maxAge && service.maxAge > parseInt(patient.age))
        || (service.minAge && service.minAge < parseInt(patient.age))) continue;

      items.push({ id: service.serviceId, name: service.serviceName, price: this.getPriceWithDiscount(price.amount, visit.discountAmount) });
    }

    this.items = items.sort((x, y) => x.name.localeCompare(y.name));

    this.serviceSearchControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: string): void => {
        if (!value) {
          this.services$.next(this.items);
        } else {
          const regex = new RegExp(value, 'i');

          this.services$.next(this.items.filter(x => regex.test(x.name)));
        }

      });

    this.services$.next(this.items);

    this.templateSearchControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: string): void => {
        if (!value) {
          this.templates$.next(this.items);
        } else {
          const regex = new RegExp(value, 'i');

          this.templates$.next(this.items.filter(x => regex.test(x.name)));
        }
      });

    this.templates$.next(this.templates);

    this.step = 1;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  selected(item: AddServiceItem): boolean {
    return item && this.selectedItem && this.selectedItem.id === item.id;
  }

  select(item: AddServiceItem): void {
    this.selectedItem = item;
    if (this.templates.length == 1) {
      this.onConfirm.emit({ serviceId: this.selectedItem.id, templateId: this.templates[0].id });
    } else {
      this.step = 2;
    }
  }

  confirm(item: UsersTemplate): void {
    this.onConfirm.emit({ serviceId: this.selectedItem.id, templateId: item.id });
  }

  cancel(): void {
    this.onCancel.emit();
  }

  getPriceWithDiscount(price: number, discountAmount: number): number {
    return price - price * (discountAmount / 100.0);
  }
}

export interface AddServiceItem {
  id: number,
  name: string,
  price: number
}

export interface ConfirmEvent {
  serviceId: number;
  templateId: number;
}
