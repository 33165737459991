import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ReportDiscount } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-sales-modal',
  templateUrl: './sales-modal.component.html',
  styleUrls: ['./sales-modal.component.scss']
})
export class SalesModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  private _selected: ReportDiscount[] = [];
  private _discountsSubject: BehaviorSubject<ReportDiscount[]> = new BehaviorSubject<ReportDiscount[]>([]);

  @Input() title: string;
  @Input() selected: ReportDiscount[] = [];
  @Input() discounts: ReportDiscount[] = [];

  @Output()
  onConfirm: EventEmitter<ReportDiscount[]> = new EventEmitter<ReportDiscount[]>();
  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  get result(): Observable<ReportDiscount[]> { return this._discountsSubject; }

  loading = false;
  search: FormControl;

  constructor() {
    this.search = new FormControl("");

    this.search.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: string): void => {
          if (!value) {
            this._discountsSubject.next([...this.discounts]);
          } else {
            const search = new RegExp(value, "i");
            const filtered: ReportDiscount[] = this.discounts.filter((x: ReportDiscount): boolean => search.test(x.name));
            this._discountsSubject.next(filtered);
          }
        }
      );
  }

  ngOnInit() {
    this._selected = this.selected;
    this.search.setValue("");
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }
  isSelected(item: ReportDiscount): boolean {
    return this._selected.some((x: ReportDiscount): boolean => x.id === item.id);
  }

  toggle(item: ReportDiscount): void {
    const index: number = this._selected.findIndex((x: ReportDiscount): boolean => x.id === item.id);

    if (index === -1) {
      this._selected.push(item);
    } else {
      this._selected.splice(index, 1);
    }
  }

  submit(): void {
    this.onConfirm.emit([...this._selected]);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
