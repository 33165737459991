import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClinicalRecommendation, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-selected-prescriptions',
  templateUrl: './selected-prescriptions.component.html',
  styleUrls: ['./selected-prescriptions.component.scss']
})
export class SelectedPrescriptionsComponent {
  @Input() items: SectionsServicePrescription[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];

  @Output() onDelete = new EventEmitter<SectionsServicePrescription>();
  @Output() onSelectedItemsReorder = new EventEmitter<PrescriptionOrderChangedEvent>();

  name = (item: SectionsServicePrescription) => item.name;

  delete = (item: SectionsServicePrescription) => this.onDelete.emit(item);
  drop = (event: CdkDragDrop<string[]>) => this.onSelectedItemsReorder.emit({ previousIndex: event.previousIndex, currentIndex: event.currentIndex });

  recommendationsFor = (item: SectionsServicePrescription, type: 0 | 1) => this.recommendations
    .filter(x => x.type === type && x.manipulationName == item.name)
    .map(x => ({ title: x.clinicalRecommendationName, text: x.text }));
}

export class PrescriptionOrderChangedEvent {
  previousIndex: number;
  currentIndex: number;
}
