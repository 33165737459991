import { Component, OnInit } from '@angular/core';
import { Service } from 'projects/Clinic/src/app/generated/models/service';
import { ServicesService } from 'projects/Clinic/src/app/generated/services';
import { ServicesResponse, ServiceCategory, ServiceArchive } from 'projects/Clinic/src/app/generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router, ParamMap } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RemoveServiceModalComponent } from '../remove-service.modal/remove-service.modal.component';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ScrollHistoryService } from '../../../shared/services/scroll-history-service';

@Component({
  selector: 'mp-services-list',
  templateUrl: './service-items.component.html',
  styleUrls: ['./service-items.component.scss'],
  host: { class: "page" }
})
export class ServiceItemsComponent implements OnInit {
  services: Service[] = [];
  categories: ServiceCategory[] = [];

  loading = false;

  page = 1;
  size = 50;

  archived = false;
  category = 0;
  search: string;

  selected: { [id: number]: boolean } = {};

  scrollDistance = 1;

  searchForm: FormGroup;

  constructor(
    private servicesService: ServicesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private readonly toastrService: ToastrService,
    private readonly scrollHistoryService: ScrollHistoryService
  ) {
    this.searchForm = new FormGroup({
      search: new FormControl(""),
      category: new FormControl(""),
      archived: new FormControl("")
    });

    this.searchForm.get("category").setValue(this.category);
  }

  async ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    this.archived = queryMap.has("includeArchived") && queryMap.get("includeArchived") == "true";
    this.searchForm.get("archived").setValue(this.archived);

    this.category = queryMap.has("category") && (+queryMap.get("category") > 0) ? (+queryMap.get("category")) : 0;
    this.searchForm.get("category").setValue(this.category);

    this.search = queryMap.get("search");
    this.searchForm.get("search").setValue(this.search);

    this.loading = true;
    await this.initiateScroll();
    this.loading = false;

    this.activatedRoute.data.subscribe((data: { payload: ServiceCategory[] }): void => {
      this.categories = data.payload;
    });

    this.searchForm.valueChanges.subscribe(
      async (values: any): Promise<void> => {
        this.page = 1;
        this.services = [];

        this.archived = values.archived;
        this.category = values.category;
        this.search = values.search;

        const queryParams: Params = {};

        if (this.category && this.category > 0) {
          queryParams.category = this.category
        }

        if (this.archived) {
          queryParams.includeArchived = this.archived;
        }

        if (this.search) {
          queryParams.search = this.search;
        }

        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

        this.loading = true;
        this.load();
      });
  }

  async initiateScroll() {
    const position = this.scrollHistoryService.retrievePosition('services');

    if (!position) {
      this.load();
      return;
    }

    const payload = { Page: 1, Size: position.page * this.size, IncludeArchived: this.archived, CategoryId: this.category, Search: this.search };

    this.page = position.page;

    try {
      const response = await this.servicesService.Services(payload).toPromise();
      this.services.push(...response.services);


      setTimeout(() => {
        console.info(document.getElementById(`Service${position.id}`))
        document.getElementById(`Service${position.id}`).scrollIntoView({ behavior: 'auto' });
      }, 0);

      this.scrollHistoryService.resetPosition('services');

    } catch (e) { }
    this.loading = false;
  }

  onScrollDown() {
    this.page++;
    this.load();
  }

  create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  edit(service: Service): void {

    this.scrollHistoryService.savePosition('services', this.page, service.id);

    this.router.navigate([service.id], { relativeTo: this.activatedRoute });
  }

  async remove(service: Service): Promise<void> {

    const data = await this.servicesService.ArchiveInfo(service.id).toPromise();

    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(RemoveServiceModalComponent, options);
    const componentRef: RemoveServiceModalComponent = modalRef.componentInstance;

    componentRef.fullName = data.code ? `${data.code} ${data.name} ` : data.name;
    componentRef.canRemove = !data.totalContracts;
    componentRef.archived = data.archived;

    componentRef.onClose.subscribe(() => {
      modalRef.close();
    });

    componentRef.onArchive.subscribe(() => {
      this.servicesService.Archive(service.id)
        .subscribe(
          () => {
            modalRef.close();

            this.toastrService.success(`Услуга ${componentRef.fullName} перемещена в архив`, 'Успешно');

            this.page = 1;
            this.services = [];

            this.loading = true;
            this.load();
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              this.toastrService.warning(response.error, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось переместить услугу в архив', 'Ошибка');
          }
        );
    });

    componentRef.onRemove.subscribe(() => {
      this.servicesService.Remove(service.id)
        .subscribe(
          () => {
            modalRef.close();

            this.toastrService.success(`Услуга ${componentRef.fullName} удалена`, 'Успешно');

            this.page = 1;
            this.services = [];

            this.loading = true;
            this.load();
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              this.toastrService.warning(response.error, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось переместить услугу в архив', 'Ошибка');
          }
        );
    });

  }

  async load(): Promise<void> {
    const payload = { Page: this.page, Size: this.size, IncludeArchived: this.archived, CategoryId: this.category, Search: this.search };

    try {
      const response = await this.servicesService.Services(payload).toPromise();
      this.services.push(...response.services);
    } catch (e) { }

    this.loading = false;
  }

}
