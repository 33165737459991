import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-laboratory',
  templateUrl: './laboratory.component.html',
  styleUrls: ['./laboratory.component.scss']
})
export class LaboratoryComponent implements OnInit {
  @Input() labResults: LabResult[];

  @Output() onOpenAttachment = new EventEmitter<{id:number, testOrderId: number}>();
  @Output() onEmailResult = new EventEmitter<LabResult>();

  constructor() { }

  ngOnInit() { }

  openAttachment = (id: number, result: LabResult) => this.onOpenAttachment.emit({ id, testOrderId: result.id });

  emailResult = (item: LabResult) => this.onEmailResult.emit(item);
  get labResultsEmpty(): boolean { return this.labResults.length === 0; }
}
