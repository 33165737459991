/** Имена разрешений */
export enum PermissionNames {
  /** Доступ к отчетам */
  ViewReports = "ViewReports",
  /** Управление пользователями */
  ManageEmployees = "ManageEmployees",
  /** Доступ к списку посещений */
  ViewVisits = "ViewVisits",
  /** Доступ к результатам анализов */
  ViewResults = "ViewResults",
  /** Доступ к рабочему месту врачу */
  ViewWorkspace = "ViewWorkspace",
  /** Доступ к рабочему месту оператора*/
  ViewCallCenter = "ViewCallCenter",
  /** Достук к кассам */
  ViewCashbox = "ViewCashbox",
  /** Доступ к расписанию */
  ViewSchedules = "ViewSchedules",
  /** Доступ в лабораторию (?) */
  ViewLab = "ViewLab",
  /** Просматривать всех пациентов в списке в рабочем месте врача */
  ViewAllPatientsInWorkspace = "ViewAllPatientsInWorkspace",
  /** Открывать документы других врачей */
  ViewDocumentsOfOtherDoctors = "ViewDocumentsOfOtherDoctors",
  /** Инспектировать чужие документы */
  ReviewRecords = "ReviewRecords",
  /** Снимать подпись с документа */
  CanRemoveSignatureFromRecord = "CanRemoveSignatureFromRecord",
  /** Создавать посещения */
  CreateVisit = "CreateVisit",
  /** Редактировать посещения */
  EditVisit = "EditVisit",
  /** Изменять опросники */
  EditQuestionnaires = "EditQuestionnaires",
  /** Изменять каналы привлечения пациентов*/
  EditPromotions = "EditPromotions",
  /** Изменять внешние каналы*/
  EditExternalConnections = "EditExternalConnections",
  /** Отправлять опросники */
  SendQuestionnaires = "SendQuestionnaires",

  /** ОТправлять заявки*/
  SendLabOrders = "SendLabOrders",

  /** Изменять документы других врачей */
  EditDocumentsOfOtherDoctors = "EditDocumentsOfOtherDoctors",

  /**  Управлять структурой общих шаблонов*/
  ManageTemplates = "ManageTemplates",
  /** Владеть персональными шаблонами */
  OwnPersonalTemplates = "OwnPersonalTemplates",
  /** Управлять содержимым шаблона */
  EditTemplates = "EditTemplates",
  /** Просматривать роли */
  ViewRoles = "ViewRoles",
  /** Редактировать роли */
  EditRoles = "EditRoles",
  /** Создавать роли */
  CreateRoles = "CreateRoles",
  /** Удалять роли */
  DeleteRoles = "DeleteRoles",

  /** Изменять категории услуг */
  EditCategories = "EditCategories",
  /** Изменять специальности*/
  EditSpecialities = "EditSpecialities",

  /** Просматривать шаблоны (перенесено) */
  ViewTemplates = "ViewTemplates",

  /** Удалять результаты исследований */
  DeleteTestOrderResults = "DeleteTestOrderResults",

  ///Reports
  /* */
  ViewUsersWorkReportBase = "ViewUsersWorkReportBase",
  ViewSummaryServicesReport = "ViewSummaryServicesReport",
  ViewLabCustomersReport = "ViewLabCustomersReport",
  ViewJournalOfVisitsBase = "ViewJournalOfVisitsBase",
  ViewShiftReport = "ViewShiftReport",
  ViewDetailedPartnersReportForDoctor = "ViewDetailedPartnersReportForDoctor",
  ViewDetailedPartnersSummaryReport = "ViewDetailedPartnersSummaryReport",
  ViewDiscountsReport = "ViewDiscountsReport",
  ViewPromotionsReport = "ViewPromotionsReport",
  ViewDiscountCardsReport = "ViewDiscountCardsReport",
  ViewMarketingReport = "ViewMarketingReport",
  ViewDoctorWagesReport = "ViewDoctorWagesReport",
  /* Просматривать отчет Повторная явка */
  ViewAppointmentDateReport = "ViewAppointmentDateReport",
  /* Просматривать отчет Рейтинги врачей */
  ViewDoctorRatingsReport = "ViewDoctorRatingsReport",
  /* Просматривать отчет Счета */
  ViewBillsReport = "ViewBillsReport",
  /* Просматривать отчет Акты */
  ViewActsReport = "ViewActsReport",
  /* Просматривать отчет по клинико-экспертной работе */
  ViewDocumentReviewReport = "ViewDocumentReviewReport",
  /* Просматривать отчет по клинико-экспертной работе с детализацией*/
  ViewDocumentReviewDetailedReport = "ViewDocumentReviewDetailedReport",
  /* Просматривать отчет Карточка услуги */
  ViewServiceCardReport = "ViewServiceCardReport",

  /**  Изменять финансовые данные посещения при напечатанном чеке */
  EditVisitFinances = "EditVisitFinances",

  /**  Изменять настройки приложения */
  EditApplicationSettings = "EditApplicationSettings",
  /** Изменять личные данные пациентов - использовать EditPersonalData вместо этого! */
  ManagePersons = "ManagePersons",
  /** Изменять личные данные пациентов */
  EditPersonalData = "EditPersonalData",

  /** Управлять ролями сотрудников*/
  ManageUserRoles = "ManageUserRoles",

  /** Управлять прайс-листами*/
  ManagePriceSets = "ManagePriceSets",
  /** Изменять личные данные пациентов */

  /** Управлять услугами */
  ManageServices = "ManageServices",
  /** Управлять партнерскими программами */
  ManagePartnerPlans = "ManagePartnerPlans",
  /** Управлять расписаниями */
  ManageSchedules = "ManageSchedules",
  /** Управлять компаниями */
  ManageCompanies = "ManageCompanies",
  /** Управлять скидками и купонами */
  ManageDiscounts = "ManageDiscounts",
  /** Управлять тедлефонными номерами */
  ManagePbxOperators = "ManagePbxOperators",
  /** Управлять ИДС */
  ManageAgreementsForIntervention = "ManageAgreementsForIntervention",

  /** Управлять схемами оплаты */
  ManageWageSchemas = "ManageWageSchemas",

  /** Управлять системами кодировки*/
  ManageCodeSystems = "ManageCodeSystems",

  /** Импортировать пациентов */
  ImportPatients = "ImportPatients",
  /** Управлять причинами перерывов в распиании */
  ManageScheduleBreakReasons = "ManageScheduleBreakReasons",

  /** Управлять причинами возвратов */
  ManagerRefundReasons = "ManageRefundReasons",

  /** Добавлять новые услуги из рабочего места врача*/
  AddServicesFromWorkspace = "AddServicesFromWorkspace",

  /** Подписывать документы ЭЦП */
  AddDigitalSignature = "AddDigitalSignature",

  /** Добавлять и удалять перервы в расписании */
  ManageScheduleBreaks = "ManageScheduleBreaks",

  /** Управлять услугами */
  ManagerServices = "ManageServices",

  /** Управлять ценами */
  ManagePrices = "ManagePrices",

  /** Управлять цветовыми метками */
  ManagerColorMarks = "ManageColorMarks",

  /** Удалять подписанные протоколы */
  RemoveSignedProtocols = "RemoveSignedProtocols",

  /** Управлять настройками номеров */
  ManagePhoneNumbers = "ManagePhoneNumbers",

  /** Видит номера телефонов*/
  ViewPhoneInReports = "ViewPhoneInReports",

  /** Прозводить операции с балансом пользователей */
  OperateOnPatientBalance = "OperateOnPatientBalance",

  /** Управлять причинами неявки */
  ManageAbsenceReasons = "ManageAbsenceReasons",

  /** Генерировать справки для налогового вычета*/
  ManageTaxDeductions = "ManageTaxDeductions",
  /** Удалять сгенерированные справки для налогового вычета из архива*/
  RemoveTaxDeductions = "RemoveTaxDeductions",

  /** Удалять отчеты о звонках старше 24 часов и принадлежащие другим пользователям*/
  RemoveCallReports = 'RemoveCallReports',

  /** Управлять процедурами*/
  ManageManipulations = 'ManageManipulations',

  /** Управлять диагнозами*/
  ManageDiagnoses = 'ManageDiagnoses',

  /** Добавлять метки пациентов*/
  AddPersonMarks = 'AddPersonMarks',

  /** Удалять чужие метки пациентов*/
  ManagePersonMarks = 'ManagePersonMarks',

  /** Управлять показателями пациентов*/
  ManageMetrics = 'ManageMetrics'
}
