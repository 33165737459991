/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrintingResponse } from '../models/printing-response';
import { UpdateTaskRequest } from '../models/update-task-request';
import { CheckStatusResponse } from '../models/check-status-response';
@Injectable({
  providedIn: 'root',
})
class PrintingService extends __BaseService {
  static readonly PrintPath = '/api/Printing/{id}';
  static readonly UpdateTaskPath = '/api/Printing/{id}';
  static readonly CancelTaskPath = '/api/Printing/{id}';
  static readonly CheckStatusPath = '/api/Printing/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  PrintResponse(id: number): __Observable<__StrictHttpResponse<PrintingResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Printing/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintingResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Print(id: number): __Observable<PrintingResponse> {
    return this.PrintResponse(id).pipe(
      __map(_r => _r.body as PrintingResponse)
    );
  }

  /**
   * @param params The `PrintingService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateTaskResponse(params: PrintingService.UpdateTaskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Printing/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PrintingService.UpdateTaskParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateTask(params: PrintingService.UpdateTaskParams): __Observable<null> {
    return this.UpdateTaskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  CancelTaskResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Printing/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CancelTask(id: number): __Observable<null> {
    return this.CancelTaskResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  CheckStatusResponse(id: number): __Observable<__StrictHttpResponse<CheckStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Printing/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CheckStatusResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  CheckStatus(id: number): __Observable<CheckStatusResponse> {
    return this.CheckStatusResponse(id).pipe(
      __map(_r => _r.body as CheckStatusResponse)
    );
  }
}

module PrintingService {

  /**
   * Parameters for UpdateTask
   */
  export interface UpdateTaskParams {
    id: number;
    request?: UpdateTaskRequest;
  }
}

export { PrintingService }
