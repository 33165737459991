import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { StandardsService, ServicesService } from 'projects/Clinic/src/app/generated/services';
import { Standard } from 'projects/Clinic/src/app/generated/models/standard';
import { StandardsResponse } from 'projects/Clinic/src/app/generated/models/standards-response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceStandardsResponse, ServiceStandard } from 'projects/Clinic/src/app/generated/models';
import { StandardsStorageService } from '../../../../services/standards-storage.service';

@Component({
  selector: 'mp-service-standard-modal',
  templateUrl: './service-standard.modal.component.html',
  styleUrls: ['./service-standard.modal.component.scss']
})
export class ServiceStandardModalComponent implements OnInit {
  public title: string;
  modalScrollDistance = 0;

  private _page: number;
  private _size: number;

  private _standard: Standard;

  private _standards: Standard[];
  private _blockedStandards: number[];

  public form: FormGroup;

  @Input("serviceId") serviceId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private standardsService: StandardsService,
    private servicesService: ServicesService,
    private standardsStorageService: StandardsStorageService
  ) {
    this.title = "Стандарт услуги";

    this._page = 1;
    this._size = 20;

    this._standards = [];
    this._blockedStandards = [];

    this.form = new FormGroup({
      search: new FormControl(""),
      includeExpired: new FormControl("")
    });

    this.form.get("includeExpired").setValue(false);
    this.form.get("search").setValue("");

    this.form.valueChanges.subscribe((): void => {
      this._page = 1;
      this._standards = [];
      this._standard = undefined;
      this.load();
    });
  }

  private load(): void {

    this.standardsStorageService.getAllStandards({
      search: this.form.get("search").value,
      includeExpired: this.form.get("includeExpired").value
    }).subscribe(
      (response: Standard[]): void => {
        this._standards.push(...response);
      })

    this.servicesService.Standards({
      id: this.serviceId,
      Size: 0,
      IncludeExpired: true
    })
      .subscribe(
        (response: ServiceStandardsResponse): void => {
          this._blockedStandards = response.standards.map((x: ServiceStandard): number => x.standardId);
        });
  }

  ngOnInit() {

    this.load();

  }

  public onScrollDown(): void {
    this._page++;

    this.load();
  }


  public submit(): void {
    if (!this.standard) {
      return;
    }

    this.servicesService.AddStandard({ id: this.serviceId, model: { standardId: this._standard.id } })
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (response: any): void => {
          if (response.status == 400 && response.error) {

            if (response.error["standard"]) {
              const standardErrors: string[] = response.error["standard"];
            }
          }
        }
      );

  }
  public cancel(): void {
    this.activeModal.dismiss();
  }

  public select(standard: Standard): void {
    if (this._blockedStandards.some((x: number): boolean => standard.id === x)) {
      return;
    }

    this._standard = standard;
  }

  public selected(standard: Standard): boolean {
    return this._blockedStandards.some((x: number): boolean => standard.id === x) || (this._standard && this._standard.id === standard.id);
  }

  public get standards(): Standard[] { return this._standards; }
  public get standard(): Standard { return this._standard; }
}
