import { Component, OnInit, OnDestroy, Injector } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { MessageReceiverService, AcceptedCall, CompletedCall, CancelledCall, EventNames } from '../../services/message.receiver.service';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserStorage } from '../../services/user-storage';
import { PermissionNames } from '../../models/permission-names';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeCompanyModalComponent } from '../change-company-modal/change-company-modal.component';
import { IncomingCallNotificationComponent } from '../incoming-call-notification/incoming-call-notification.component';
import { HealthcheckService, HealthcheckStatus } from '../../services/healthcheck-service';
import { Router } from '@angular/router';
import { NotificationItem } from "@skytecs/layout";
import { DiagnosesWebworkerService } from '../../services/diagnoses-webworker-service';
import { IdbBaseService } from '../../services/idb-base-service';
import { ActiveCallService } from '../../modules/active-call/services/active-call-service';
import { ActiveCallModalComponent } from '../../modules/active-call/components/active-call-modal/active-call-modal.component';
import { WidgetRef, WidgetService } from '../../modules/shared/services/widget-service';

import { SEARCH_CONTROL_DEBOUNCE } from '@skytecs/controls';

@Component({
  selector: 'mp-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  callNotifications: { [calId: string]: number } = {};

  userName = '';
  companyName = '';
  items: any[] = [];

  notifications: NotificationItem[] = [];

  get healthcheckObservable(): Observable<HealthcheckStatus> { return this.healthcheckMonitor.status }

  constructor(
    private injector: Injector,
    private router: Router,
    private toastrService: ToastrService,
    messageReceiverService: MessageReceiverService,
    private healthcheckMonitor: HealthcheckService,
    private activeCallService: ActiveCallService,
    private userStorage: UserStorage,
    private modal: NgbModal,
    private widgetService: WidgetService,
    diagnosesWebworkerService: DiagnosesWebworkerService,
    idbBaseService: IdbBaseService,
  ) {

    idbBaseService.init();
    diagnosesWebworkerService.init();
    if (userStorage.profile.searchFormDebounce > 0) {
      injector.get(SEARCH_CONTROL_DEBOUNCE).next(userStorage.profile.searchFormDebounce);
    }

    document.addEventListener('message', (e: CustomEvent) => {
      messageReceiverService.events.next((e.detail as any));
    });

    if (userStorage.hasPermission(PermissionNames.ViewWorkspace)) {
      this.notifications.push({
        icon: 'bell',
        color: 'primary',
        total: 0,
        type: 'reviews',
        title: 'Непросмотренные карты оценки качества',
        on: false
      });
    }

    this.notifications.push({
      icon: 'headphones',
      color: 'success',
      type: 'manage_active_call',
      title: 'Текущий звонок',
      total: 0,
      on: false
    });

    messageReceiverService.forEvent(EventNames.CompletedCall)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: CompletedCall) => this.toastrService.clear(this.callNotifications[event.callId]));

    messageReceiverService.forEvent(EventNames.NotificationsUpdated)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: { unrevisedRecordReviewsCount: number }): void => {
        this.notifications.find(x => x.type === 'reviews').total = event.unrevisedRecordReviewsCount;
      });

    messageReceiverService.forEvent(EventNames.CancelledCall)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: CancelledCall) => this.toastrService.clear(this.callNotifications[event.callId]));

    if (userStorage.profile.canReceiveCalls) {
      if (this.userStorage.profile.enableCallCapturePanel) {
        let widgetRef: WidgetRef<ActiveCallModalComponent> = this.widgetService.open(ActiveCallModalComponent, { bodyClass: 'with-widget' });

        messageReceiverService.forEvent(EventNames.AcceptedCall)
          .pipe(takeUntil(this.destroy$))
          .subscribe((event: AcceptedCall): void => {
            activeCallService.start(event.phone);
          });

        activeCallService.completed$
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            messageReceiverService.events.next(({ name: 'call_report_finished' } as any));
          });

      } else {
        messageReceiverService.forEvent(EventNames.AcceptedCall)
          .pipe(takeUntil(this.destroy$))
          .subscribe((event: AcceptedCall): void => {
            const message: string = event.phone;
            const title = "Входящий звонок";

            const toastr: ActiveToast<any> = this.toastrService.info(message, title, {
              toastComponent: IncomingCallNotificationComponent,
              timeOut: this.userStorage.profile.acceptedCallNotificationDuration * 1000,
              disableTimeOut: this.userStorage.profile.acceptedCallNotificationDuration === 0,
              tapToDismiss: false,
              progressBar: true
            });

            this.callNotifications[event.callId] = toastr.toastId;

            toastr.onHidden
              .pipe(takeUntil(this.destroy$))
              .subscribe(() => delete (this.callNotifications[event.callId]));
          });
      }
    }
  }

  ngOnInit() {
    this.userName = this.userStorage.profile.userName;
    this.companyName = this.userStorage.profile.companyName;

    if (this.userStorage.hasPermission(PermissionNames.ViewWorkspace)) {
      const notification = this.notifications.find(x => x.type === 'reviews');
      if (notification) {
        notification.total = this.userStorage.profile.unrevisedRecordReviewsCount;
      }
    }

    const permissions: string[] = this.userStorage.profile.permissions
      .filter(x => !x.companyId || x.companyId === this.userStorage.profile.companyId)
      .map(x => x.name);

    const check = (name) => permissions.some(x => x === name);

    if (check(PermissionNames.ViewVisits)) {
      this.items.push({ id: "visits", text: "Посещения", url: `/visits`, outer: false });
    }

    if (check(PermissionNames.ViewReports)) {
      this.items.push({ id: "reports", text: "Отчеты", url: `/reports`, outer: false });
    }

    const settingsItems: any[] = [];

    if (check(PermissionNames.EditApplicationSettings)) {
      settingsItems.push({ id: "settings", text: "Параметры приложения", url: `/settings/system`, outer: false });
    };

    if (check(PermissionNames.ManageEmployees)) {
      settingsItems.push({ id: "employees", text: "Сотрудники и партнеры", url: `/settings/employees`, outer: false });
    }

    if (check(PermissionNames.EditSpecialities)) {
      settingsItems.push({ id: "specialities", text: "Специальности", url: `/settings/specialities`, outer: false });
    }

    if (check(PermissionNames.EditCategories)) {
      settingsItems.push({ id: "categories", text: "Категории услуг", url: '/settings/categories', outer: false });
    }

    if (check(PermissionNames.ManageManipulations)) {
      settingsItems.push({ id: "Manipulations", text: "Назначения", url: `/settings/manipulations`, outer: false });
    }

    if (check(PermissionNames.ManagerServices)) {
      settingsItems.push({ id: "services", text: "Услуги", url: `/settings/services`, outer: false });
      settingsItems.push({ id: "service-groups", text: "Пакеты услуг", url: `/settings/service-groups`, outer: false });
    }

    if (check(PermissionNames.ManagerColorMarks)) {
      settingsItems.push({ id: "ColorMarks", text: "Цветовые метки", url: `/settings/color-marks`, outer: false });
    }

    if (check(PermissionNames.ManagePartnerPlans)) {
      settingsItems.push({ id: "partner-plans", text: "Партнерские программы", url: `/settings/partner-plans`, outer: false });
    }

    if (check(PermissionNames.ManageAbsenceReasons)) {
      settingsItems.push({ id: "absenceReasons", text: "Причины неявки", url: "/settings/absence-reasons", outer: false });
    }

    if (check(PermissionNames.ManageScheduleBreakReasons)) {
      settingsItems.push({ id: "breakReasons", text: "Причины перерывов", url: "/settings/break-reasons", outer: false });
    }

    if (this.userStorage.hasPermission(PermissionNames.ManagerRefundReasons)) {
      settingsItems.push({ id: "refundReasons", text: "Причины возвратов", url: "/settings/refund-reasons", outer: false });
    }

    if (check(PermissionNames.ManageSchedules)) {
      settingsItems.push({ id: "schedules", text: "Расписания", url: `/settings/schedules`, outer: false });
    }

    if (check(PermissionNames.ManageCompanies)) {
      settingsItems.push({ id: "organizations", text: "Организации", url: `/settings/organizations`, outer: false });
    }

    if (check(PermissionNames.ManagePhoneNumbers)) {
      settingsItems.push({ id: "phone-numbers", text: "Телефонные номера", url: `/settings/phone-numbers`, outer: false });
    }

    if (check(PermissionNames.ManageDiagnoses)) {
      settingsItems.push({ id: "diagnoses", text: "МКБ-10", url: "/settings/diagnoses", outer: false });
    }

    if (check(PermissionNames.ManageDiscounts)) {
      settingsItems.push({ id: "discounts", text: "Купоны и скидки", url: "/settings/discounts", outer: false });
    }

    if ([PermissionNames.EditTemplates, PermissionNames.ManageTemplates, PermissionNames.OwnPersonalTemplates].some(x => check(x))) {
      settingsItems.push({ id: "templates", text: "Шаблоны документов", url: "/settings/templates", outer: false });
    }

    if (check(PermissionNames.ManageMetrics)) {
      settingsItems.push({ id: "metrics", text: "Показатели пациентов", url: "/settings/metrics", outer: false });
    }

    if (check(PermissionNames.ViewRoles)) {
      settingsItems.push({ id: "roles", text: "Роли", url: "/settings/roles", outer: false });
    }

    if (check(PermissionNames.EditQuestionnaires)) {
      settingsItems.push({ id: "questionnaires", text: "Опросники", url: `/settings/questionnaires`, outer: false });
    }

    if (check(PermissionNames.EditPromotions)) {
      settingsItems.push({ id: "promotions", text: "Каналы привлечения", url: `/settings/promotions`, outer: false });
    }

    if (check(PermissionNames.EditExternalConnections)) {
      settingsItems.push({ id: "external-connections", text: "Внешние системы", url: `/settings/external-connections`, outer: false });
    }

    if (check(PermissionNames.ManagePriceSets)) {
      settingsItems.push({ id: "price-sets", text: "Прайс-листы", url: `/settings/price-sets`, outer: false });
    }

    if (check(PermissionNames.ManageWageSchemas)) {
      settingsItems.push({ id: "wage-schemas", text: "Схемы оплаты", url: `/settings/wage-schemas`, outer: false });
    }

    if (check(PermissionNames.ManageCodeSystems)) {
      settingsItems.push({ id: "code-systems", text: "Системы кодировки", url: `/settings/code-systems`, outer: false });
    }

    if (settingsItems.length > 0) {
      this.items.push({ id: "settings", text: "Настройки", url: ``, outer: false, children: settingsItems });
    }

    if (check(PermissionNames.ViewWorkspace) && check(PermissionNames.ReviewRecords)) {

      this.items.push({
        id: "workspace",
        text: "Рабочее место",
        url: "",
        outer: false,
        children: [
          { id: "workspace-dashboard", text: "Список посещений", url: `/workspace`, outer: false },
          { id: "workspace-reviews", text: "Клинико-экспертная работа", url: `/quality-control`, outer: false },
        ]
      });

    }
    else {
      if (check(PermissionNames.ViewWorkspace)) {
        this.items.push({
          id: "workspace",
          text: "Рабочее место",
          url: "",
          outer: false,
          children: [
            { id: "workspace-dashboard", text: "Список посещений", url: `/workspace`, outer: false },
            { id: "workspace-reviews", text: "Клинико-экспертная работа", url: `/quality-control`, outer: false },
          ]
        });
      } else if (check(PermissionNames.ReviewRecords)) {
        this.items.push({ id: "workspace-reviews", text: "Клинико-экспертная работа", url: `/quality-control`, outer: false });
      }
    }

    if (check(PermissionNames.ViewCallCenter)) {
      this.items.push({
        id: "call-center",
        text: "Колл-центр",
        children: [
          { id: 'call-center-attendings', text: 'Контроль явки', url: '/call-center/attendance' },
          { id: 'call-center-visits', text: 'Запланированные посещения', url: '/call-center/visits/planned' }
        ],
        outer: false
      });
    }

    if (check(PermissionNames.ViewResults)) {
      this.items.push({ id: "observations", text: "Результаты исследований", url: `/observations`, outer: false });
    }

    if (check(PermissionNames.ViewSchedules)) {
      this.items.push({ id: "schedule", text: "Расписание", url: `/schedules/week`, outer: false });
    }

    const importItems: any[] = [];

    if (check(PermissionNames.ImportPatients)) {
      importItems.push({ id: "import-patients", text: "Импорт пациентов", url: `/import/patients`, outer: false });
    }

    if (importItems.length > 0) {
      this.items.push({
        id: "import",
        text: "Импорт",
        url: "",
        outer: false,
        children: importItems
      });
    }
  }

  changeCompany = () => this.modal.open(ChangeCompanyModalComponent, { backdrop: true, size: "lg" });

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clickUser = () => this.router.navigate(["profile"]);

  clickNotification(name: string): void {
    if (name === 'reviews') {
      this.router.navigate(["quality-control", "records"], { queryParams: { onlyUnrevised: true, onlyClosed: false, isPreventive: true } });
    }

    //if (name === 'manage_active_call' && this.activeCallService.started) { }
  }
}

interface MenuItem {
  id: string;
  text: string;
  url: string;
  outer: false;
}
