import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'mp-receipt-parameters-modal',
  templateUrl: './receipt-parameters-modal.component.html',
  styleUrls: ['./receipt-parameters-modal.component.scss']
})
export class ReceiptParametersModalComponent implements OnInit {

  @Output() onDismissed: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFormSubmitted: EventEmitter<CorrectionReceiptParameters> = new EventEmitter<CorrectionReceiptParameters>();

  correctionReceiptTypes: SelectOptionItem[] = [
    { value: 0, text: "Коррекция прихода" },
    { value: 1, text: "Коррекция расхода" }
  ];

  taxTypes: SelectOptionItem[] = [
    { value: 1, text: "Без НДС" },
    { value: 2, text: "0%" },
    { value: 3, text: "10%" },
    { value: 4, text: "18%" },
    { value: 5, text: "10/110" },
    { value: 6, text: "18/118" },
    { value: 7, text: "20%" },
    { value: 8, text: "20/120" },
  ];

  taxationTypes: SelectOptionItem[] = [
    { value: 1, text: "Общая" },
    { value: 2, text: "Упрощенная(Доход)" },
    { value: 3, text: "Упрощенная(Доход минус Расход)" },
    { value: 4, text: "ЕНВД" },
  ];

  correctionTypes: SelectOptionItem[] = [
    { value: 0, text: "Самостоятельно" },
    { value: 1, text: "По предписанию" },
  ];

  public form: FormGroup;

  constructor() {

    this.form = new FormGroup({
      correctionReceiptType: new FormControl('', [Validators.required]),
      correctionType: new FormControl('', [Validators.required]),
      documentDate: new FormControl(''),
      documentNumber: new FormControl(''),
      correctionBase: new FormControl(''),
      sum: new FormControl('', [Validators.required]),
      taxationType: new FormControl('', [Validators.required]),
      taxType: new FormControl('', [Validators.required]),
      isPayedByCard: new FormControl(false)
    });
  }

  ngOnInit() { }

  public dismiss(): void {
    this.onDismissed.emit();
  }

  public submit(): void {
    for (const control in this.form.controls) {
      this.form.get(control).markAsTouched();
    }

    if (this.form.invalid) {
      return;
    }

    const formValue: CorrectionReceiptParameters = this.form.getRawValue();

    const date = this.form.get('documentDate').value;
    if (date) {
      formValue.documentDate = date.format('DD.MM.YYYY');
    }

    this.onFormSubmitted.emit(formValue);
  }
}

interface SelectOptionItem {
  value: number;
  text: string;
}

export interface CorrectionReceiptParameters {
  correctionReceiptType: 0 | 1;
  correctionType: 0 | 1;
  documentDate: string;
  documentNumber: string;
  correctionBase: string;
  sum: number;
  taxationType: 1 | 2 | 3 | 4;
  taxType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  isPayedByCard: boolean;
}
