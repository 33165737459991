import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppointmentHistoryItem } from '../../../../generated/models';

@Component({
  selector: 'mp-appointment-history',
  templateUrl: './appointment-history.component.html',
  styleUrls: ['./appointment-history.component.scss']
})
export class AppointmentHistoryComponent {
  @Input() loading = false;

  @Output() onCopy = new EventEmitter<string>();

  @Input() items: AppointmentHistoryItem[] = [];
  @Input() reservation: { reservedByName?: string, reservedTill?: string } = undefined;

  copy = (value: string) => this.onCopy.emit(value);
}
