import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ResourceWeekScheduleViewModel, Speciality } from '../../../../generated/models';


@Component({
  selector: 'mp-resource-info',
  templateUrl: './resource-info.component.html',
  styleUrls: ['./resource-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceInfoComponent implements OnInit {

  @Input()
  resource: ResourceWeekScheduleViewModel;

  get name(): string { return this.resource ? this.resource.resourceName : ''; }
  get initials(): string {
    if (!this.resource) return '';

    if (!this.resource.organizationAbbr) {
      return this.resource.organizationName.split(" ").filter(x => !!x).map(x => x[0].toUpperCase()).join("")
    }

    return this.resource.organizationAbbr;
  }
  get specialities(): string {
    if (!this.resource || !this.resource.specialities) return '';
    return this.resource.specialities.map((x: Speciality): string => x.name).join(" / ")
  }

  constructor() { }

  ngOnInit() { }

}
