import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemSettingsListComponent } from './components/system-settings-list/system-settings-list.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { SystemSettingsListItemComponent } from './components/system-settings-list-item/system-settings-list-item.component';
import { SystemSettingComponent } from './components/system-setting/system-setting.component';
import { SystemSettingResolver } from './resolvers/system-setting-resolver';
import { SystemSettingListItemComponent } from './components/system-setting-list-item/system-setting-list-item.component';
import { SystemSettingEditModalComponent } from './components/system-setting-edit-modal/system-setting-edit-modal.component';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';

const routes: Routes = [
  {
    path: "",
    component: SystemSettingsListComponent,
    data: {
      title: "Настройки системы",
      requirements: [PermissionNames.EditApplicationSettings]

    }
  },
  {
    path: ":id",
    component: SystemSettingComponent,
    data: {
      title: "Настройка",
      requirements: [PermissionNames.EditApplicationSettings]
    },
    resolve: {
      payload: SystemSettingResolver
    }
  }
]

@NgModule({
  declarations: [
    SystemSettingsListComponent,
    SystemSettingsListItemComponent,
    SystemSettingComponent,
    SystemSettingListItemComponent,
    SystemSettingEditModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgbModalModule,
    NgxMaskModule.forRoot({}),
    ToastrModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    SystemSettingEditModalComponent
  ]
})
export class SystemSettingsModule { }
