import { Component, OnInit, Input } from '@angular/core';
import { Performer } from 'projects/Clinic/src/app/generated/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-visits-performer-select-modal',
  templateUrl: './visits-performer-select-modal.component.html',
  styleUrls: ['./visits-performer-select-modal.component.scss'],
  host: { class: "modal-default" }
})
export class VisitsPerformerSelectModalComponent implements OnInit {
  @Input() serviceName = '';
  @Input() selectedPerformerId: number;
  @Input() specialityId: number;
  @Input() specialityName = '';

  @Input() performers: Performer[] = [];

  private _selected: Performer;

  get disabled(): boolean { return !this._selected; }

  constructor(private modal: NgbActiveModal) { }

  ngOnInit() {
    if (this.selectedPerformerId) {
      this._selected = this.performers.find((x: Performer): boolean => x.id === this.selectedPerformerId);
    }
  }

  ageConstraints(performer: Performer): string {
    if (!performer.minAge && !performer.maxAge) return "Без возрастных ограничений";

    const parts: string[] = [];

    if (performer.minAge) parts.push(`от ${performer.minAge}`);
    if (performer.maxAge) parts.push(`до ${performer.maxAge}`);

    return parts.join(" ");
  }

  dismiss = () => this.modal.dismiss();

  selected = (performer: Performer) => this._selected && this._selected.id === performer.id;

  select(performer: Performer) {
    this._selected = performer;
    this.modal.close(performer);
  }

  confirm = () => this.modal.close(this._selected);
}
