import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Template, Speciality, Attending } from '../../../../generated/models';
import { TemplatesService } from "../../../../generated/services";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TemplatesPayload } from '../../resolvers/templates-resolver';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ScrollHistoryService } from '../../../shared/services/scroll-history-service';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
  host: { class: "page" }

})
export class TemplatesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  selectedTemplates: Template[] = [];

  specialities: Speciality[] = [
    { id: -1, name: "Все специальности" },
    { id: 0, name: "Без специальности" }
  ];

  attendings: Attending[] = [];

  page = 1;
  size = 20;

  loading = false;
  templates: Template[] = [];

  showAdvancedSearch = false;

  canOwnPersonalTemplates = false;
  canManageTemplates = false;

  form = new FormGroup({
    search: new FormControl(""),
    speciality: new FormControl(-1),
    archived: new FormControl(false),
    owner: new FormControl(-1)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templatesService: TemplatesService,
    private userStorage: UserStorage,
    private toastrService: ToastrService,
    private readonly scrollHistoryService: ScrollHistoryService,
    private confirmationService: ConfirmationModalService
  ) {

    this.attendings = [
      { id: -1, name: "Все специалисты" },
      { id: 0, name: "Общие" },
      { id: userStorage.profile.id, name: `Мои шаблоны (${userStorage.profile.userName})` }
    ];

    this.canManageTemplates = userStorage.hasPermission(PermissionNames.ManageTemplates);
    this.canOwnPersonalTemplates = userStorage.hasPermission(PermissionNames.OwnPersonalTemplates);

  }

  async ngOnInit() {
    const specialityId: number = this.activatedRoute.snapshot.queryParamMap.has("speciality") ?
      parseInt(this.activatedRoute.snapshot.queryParamMap.get("speciality"), 10) :
      -1;

    const ownerId: number = this.activatedRoute.snapshot.queryParamMap.has("owner") ?
      parseInt(this.activatedRoute.snapshot.queryParamMap.get("owner"), 10) :
      -1;

    const archived: boolean = this.activatedRoute.snapshot.queryParamMap.has("archived");

    this.form.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search"),
      speciality: specialityId,
      archived: archived,
      owner: ownerId
    });

    this.showAdvancedSearch = specialityId !== -1 || archived || ownerId !== -1;

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: { payload: TemplatesPayload }): void => {
        this.specialities.push(...data.payload.specialities);

        this.attendings.push(...data.payload.attendings.filter((x: Attending): boolean => x.id !== this.userStorage.profile.id));
      });
    this.loading = true;

    const position = this.scrollHistoryService.retrievePosition('templates');
    if (position) {
      this.page = position.page;
      this.templates = await this.load(1, position.page * this.size);

      setTimeout(() => {
        const elem = document.getElementById(`Template${position.id}`);
        if (elem) {
          elem.scrollIntoView({ behavior: 'auto' });
        }
      }, 0);

      this.scrollHistoryService.resetPosition('templates');
    } else {
      this.templates = await this.load();
    }

    this.loading = false;

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: any): void => {
          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search
          }

          if (value.speciality != -1) {
            queryParams.speciality = value.speciality;
          }

          if (value.owner != -1) {
            queryParams.owner = value.owner;
          }

          if (value.archived) {
            queryParams.archived = true;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
      )
      .subscribe(
        async (): Promise<void> => {
          this.loading = true;
          this.templates = [];
          this.page = 1;
          this.templates = await this.load();
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          this.handleError(response);
          this.loading = false
        }
      );

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get actionsAvailable(): boolean { return this.canManageTemplates || this.canOwnPersonalTemplates; }

  get hasSelected(): boolean { return this.selectedTemplates.length > 0; }
  get isSingleSelected(): boolean { return this.selectedTemplates.length === 1; }
  get hasArchivedSelected(): boolean { return this.selectedTemplates.some((x: Template): boolean => x.archived); }
  get hasActiveSelected(): boolean { return this.selectedTemplates.some((x: Template): boolean => !x.archived); }

  create = () => this.router.navigate(["new"], { relativeTo: this.activatedRoute });

  edit(template: Template): void {
    this.scrollHistoryService.savePosition('templates', this.page, template.id);

    this.router.navigate([template.id.toString()], { relativeTo: this.activatedRoute })
  }

  toggleAdvancedSearch = () => this.showAdvancedSearch = !this.showAdvancedSearch;

  async remove(template: Template) {
    const confirmed = await this.confirmationService.open({ message: `Шаблон "${template.name}" будет удален. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.templatesService.Remove(template.id)
      .subscribe(
        async () => {
          this.page = 1;
          this.templates = await this.load();

          this.toastrService.success("Шаблон удален", "Успешно");
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось удалить шаблон', 'Ошибка');
        }
      );
  }

  async archive(template: Template) {
    const confirmed = await this.confirmationService.open({ message: `Шаблон '${template.name}' будет перенесен в архив. Продолжить?`, confirmBtnText: 'Продолжить' })

    if (!confirmed) return;

    this.templatesService.Archive(template.id)
      .subscribe(
        async () => {
          this.toastrService.success("Шаблон перенесен архив", "Успешно");
          this.page = 1;

          const templates = await this.load();

          this.templates = [...templates];
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error("Не удалось отправить шаблон в архив", "Ошибка");
        }
      );
  }

  async restote(template: Template) {
    const confirmed = await this.confirmationService.open({ message: `Шаблон '${template.name}' будет восстановлен. Продолжить?`, confirmBtnText: 'Продолжить' })

    if (!confirmed) return;

    this.templatesService.Restore(template.id)
      .subscribe(
        async () => {
          this.toastrService.success("Шаблон восстановлен", "Успешно");

          this.page = 1;
          const templates = await this.load();
          this.templates = [...templates];
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error("Не удалось восстановить шаблон из архива", "Ошибка");
        }
      );
  }

  async copy(template: Template) {
    const confirmed = await this.confirmationService.open({ message: `Содержимое шаблона '${template.name}' будет скопировано в новый шаблон. Продолжить?`, confirmBtnText: 'Продолжить' })

    if (!confirmed) return;

    this.templatesService.Copy(template.id)
      .subscribe(
        async () => {
          this.toastrService.success("Шаблон скопирован", "Успешно");

          this.page = 1;

          const templates = await this.load();
          this.templates = [...templates];
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error("Не удалось копировать шаблон", "Ошибка");
        }
      );
  }

  canRemove = (template: Template) => this.canManageTemplates || (this.canOwnPersonalTemplates && this.userStorage.profile.id === template.ownerId);
  canArchive = (template: Template) => this.canManageTemplates || (this.canOwnPersonalTemplates && this.userStorage.profile.id === template.ownerId);
  canEdit = (template: Template) => true;
  canCopy = (template: Template) => this.canManageTemplates || this.canOwnPersonalTemplates;

  async onScrollDown() {
    this.page++;

    const portion = await this.load();

    this.templates.push(...portion);
  }

  async load(page?: number, size?: number): Promise<Template[]> {
    const values: any = this.form.getRawValue();

    const response = await this.templatesService.Templates({
      Page: page || this.page,
      Size: size || this.size,
      Search: values.search,
      SpecialityId: values.speciality != -1 ? values.speciality : null,
      Archived: values.archived,
      OwnerId: values.owner != -1 ? values.owner : null,
    }).toPromise();

    return response.items;
  }

  private handleError(response: HttpErrorResponse): void {

    if (response.status === 500) {
      this.toastrService.error("Данные ошибки сохранены", "Ошибка");
      return;
    }

    if (response.status === 403) {
      this.toastrService.error("Для выполнения действия требуется разрешение", "Запрещено");
      return;
    }

    this.toastrService.error(response.error.message, "Ошибка");
  }
}
