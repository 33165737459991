import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TemplateSection } from '../../../../generated/models';

import * as moment from "moment";

import { moveItemInArray } from '@angular/cdk/drag-drop';
import { TemplateStructureItemChange } from '../template-structure-item/template-structure-item.component';

@Component({
  selector: 'mp-template-structure-modal',
  templateUrl: './template-structure-modal.component.html',
  styleUrls: ['./template-structure-modal.component.scss']
})
export class TemplateStructureModalComponent implements OnInit {
  title = "Структура документа";

  @Input() sections: TemplateSection[];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<TemplateSection[]>();

  constructor() { }

  ngOnInit() { }

  submit = () => this.onConfirm.emit(this.sections);
  cancel = () => this.onCancel.emit();

  add(): void {
    const newSection: TemplateSection = {
      id: -1 * moment().valueOf(),
      type: 1,
      name: "",
      index: this.sections.length + 1,
      metrics: []
    };

    this.sections.push(newSection);
  }

  onDrop = (value: any) => moveItemInArray(this.sections, value.previousIndex, value.currentIndex);

  change(item: TemplateSection, changes: TemplateStructureItemChange): void {
    if (changes) {
      item.name = changes.name;
      item.type = changes.type as (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8);
    }
  }

  remove(item: TemplateSection) {
    const index: number = this.sections.findIndex(x => x.id === item.id);

    if (index !== -1) {
      this.sections.splice(index, 1);
    }
  }
}
