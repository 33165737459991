import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PersonTestResult, PersonTestResultAttachment } from '../../../../generated/models';

@Component({
  selector: 'mp-lab-protocol-parameter',
  templateUrl: './lab-protocol-parameter.component.html',
  styleUrls: ['./lab-protocol-parameter.component.scss']
})
export class LabProtocolParameterComponent implements OnInit {

  @Input() protocol: PersonTestResult = {};

  @Output() onOpenAttachment = new EventEmitter<PersonTestResultAttachment>();

  constructor() { }

  ngOnInit() { }

  selectAttachment = (attachment: PersonTestResultAttachment) => this.onOpenAttachment.emit(attachment);
}
