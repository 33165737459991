import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FiscalPrinter } from '../../../../generated/models';

@Component({
  selector: 'mp-external-device',
  templateUrl: './external-device.component.html',
  styleUrls: ['./external-device.component.scss']
})
export class ExternalDeviceComponent implements OnInit {

  @Input() device: FiscalPrinter;

  @Output()
  onSelect: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  select(): void {
    this.onSelect.emit();
  }
}
