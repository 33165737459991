import { Component, Input, OnInit } from '@angular/core';
import { Attendance } from '../../../../generated/models';

@Component({
  selector: 'mp-attendance-statistics',
  templateUrl: './attendance-statistics.component.html',
  styleUrls: ['./attendance-statistics.component.scss']
})
export class AttendanceStatisticsComponent {

  @Input() appointed: Attendance[] = [];
  @Input() planned: Attendance[] = [];

  get appointedTotal(): number { return this.appointed ? this.appointed.length : 0; }
  get appointedArrived(): number { return this.appointed ? this.appointed.filter(x => x.visits.length > 0).length : 0; }
  get appointedPercentage(): string { return this.appointed ? `${this.percentage(this.appointed)}%` : '-'; }
  
  get plannedTotal(): number { return this.planned ? this.planned.length : 0; }
  get plannedArrived(): number { return this.planned ? this.planned.filter(x => x.visits.length > 0).length : 0; }
  get plannedPercentage(): string { return this.planned ? `${this.percentage(this.planned)}%` : '-'; }

  percentage = (array: Attendance[]) => array.length === 0 ? 0 : Math.round(array.filter(x => x.visits.length > 0).length / array.length * 100);
}
