import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RolesService, CompaniesService, EmployeesService } from 'projects/Clinic/src/app/generated/services';
import { Role, Company } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-employee-role-modal',
  templateUrl: './employee-role-modal.component.html',
  styleUrls: ['./employee-role-modal.component.scss']
})
export class EmployeeRoleModalComponent implements OnInit {
  private _roles: Role[];
  private _companies: Company[];

  public title: string;
  public form: FormGroup;

  @Input("employeeId") employeeId: number;

  constructor(
    private activeModal: NgbActiveModal,
    private rolesService: RolesService,
    private companiesService: CompaniesService,
    private employeesService: EmployeesService
  ) {
    this._roles = [];
    this._companies = [];

    this.title = "Новая роль в организации";

    this.form = new FormGroup({
      role: new FormControl("", [Validators.required]),
      company: new FormControl("", [Validators.required]),
      global: new FormControl(false)
    });

    this.form.get("global").valueChanges.subscribe(
      (global: boolean): void => {
        if (global) {
          this.form.get("company").setValue("");
          this.form.get("company").disable();
        } else {
          this.form.get("company").enable();
        }
      }
    );

  }

  ngOnInit() {

    this.rolesService.Roles({})
      .subscribe(
        (roles: Role[]): void => {
          this._roles = roles;
        }
      );

    this.companiesService.Companies({ Type: 4 })
      .subscribe(
        (companies: Company[]): void => {
          this._companies = companies;
        }
      );
  }

  public submit(): void {

    if (this.form.invalid) {
      return;
    }

    const global: boolean = this.form.get("global").value;

    this.employeesService.AddRole({
      id: this.employeeId,
      model: {
        roleId: this.form.get("role").value,
        companyId: global ? null : this.form.get("company").value,
        global: global
      }
    })
      .subscribe(
        (): void => {
          this.activeModal.close();
        }
      );

  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public get roles(): Role[] { return this._roles; }
  public get companies(): Company[] { return this._companies; }
}
