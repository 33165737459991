import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SystemSettingsService } from '../../../../generated/services';
import { SystemSetting } from '../../../../generated/models/system-setting';
import { share, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-system-settings-list',
  templateUrl: './system-settings-list.component.html',
  styleUrls: ['./system-settings-list.component.scss'],
  host: { class: "page" }
})
export class SystemSettingsListComponent implements OnInit {
  destroy$ = new Subject<void>();
  settings$: Observable<SystemSetting[]>;

  filters = new FormGroup({
    search: new FormControl(""),
    includeOnlyValue: new FormControl("")
  });

  constructor(private systemSettingsService: SystemSettingsService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {

    this.filters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: FormValue): void => {
        const queryParams: Params = {};

        if (value.search) {
          queryParams.search = value.search;
        }

        if (value.includeOnlyValue) {
          queryParams.includeOnlyValue = true;
        }

        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

        this.settings$ = this.systemSettingsService.SystemSettingsList({ Query: value.search, HasValue: value.includeOnlyValue })
          .pipe(share());

      })
  }

  ngOnInit() {
    this.filters.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search"),
      includeOnlyValue: this.activatedRoute.snapshot.queryParamMap.get("includeOnlyValue")
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  edit = (setting: SystemSetting) => this.router.navigate([setting.id.toString()], { relativeTo: this.activatedRoute });
}

interface FormValue {
  search: string;
  includeOnlyValue: boolean;
}
