/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Manipulation } from '../models/manipulation';
import { CreateManipulationResponse } from '../models/create-manipulation-response';
import { CreateManipulationRequest } from '../models/create-manipulation-request';
import { UpdateManipulationRequest } from '../models/update-manipulation-request';
@Injectable({
  providedIn: 'root',
})
class ManipulationsService extends __BaseService {
  static readonly ManipulationsAsyncPath = '/api/v2/Manipulations';
  static readonly CreateManipulationAsyncPath = '/api/v2/Manipulations';
  static readonly ManipulationAsyncPath = '/api/v2/Manipulations/{id}';
  static readonly UpdateManipulationAsyncPath = '/api/v2/Manipulations/{id}';
  static readonly RemoveManipulationAsyncPath = '/api/v2/Manipulations/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ManipulationsService.ManipulationsAsyncParams` containing the following parameters:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `EmptyOnly`:
   *
   * @return Success
   */
  ManipulationsAsyncResponse(params: ManipulationsService.ManipulationsAsyncParams): __Observable<__StrictHttpResponse<Array<Manipulation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.SpecialityId != null) __params = __params.set('SpecialityId', params.SpecialityId.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.EmptyOnly != null) __params = __params.set('EmptyOnly', params.EmptyOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/Manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Manipulation>>;
      })
    );
  }
  /**
   * @param params The `ManipulationsService.ManipulationsAsyncParams` containing the following parameters:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `EmptyOnly`:
   *
   * @return Success
   */
  ManipulationsAsync(params: ManipulationsService.ManipulationsAsyncParams): __Observable<Array<Manipulation>> {
    return this.ManipulationsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Manipulation>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateManipulationAsyncResponse(request?: CreateManipulationRequest): __Observable<__StrictHttpResponse<CreateManipulationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/Manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateManipulationResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateManipulationAsync(request?: CreateManipulationRequest): __Observable<CreateManipulationResponse> {
    return this.CreateManipulationAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateManipulationResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManipulationAsyncResponse(id: number): __Observable<__StrictHttpResponse<Manipulation>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/Manipulations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Manipulation>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ManipulationAsync(id: number): __Observable<Manipulation> {
    return this.ManipulationAsyncResponse(id).pipe(
      __map(_r => _r.body as Manipulation)
    );
  }

  /**
   * @param params The `ManipulationsService.UpdateManipulationAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateManipulationAsyncResponse(params: ManipulationsService.UpdateManipulationAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/Manipulations/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ManipulationsService.UpdateManipulationAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateManipulationAsync(params: ManipulationsService.UpdateManipulationAsyncParams): __Observable<null> {
    return this.UpdateManipulationAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveManipulationAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/Manipulations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveManipulationAsync(id: number): __Observable<null> {
    return this.RemoveManipulationAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ManipulationsService {

  /**
   * Parameters for ManipulationsAsync
   */
  export interface ManipulationsAsyncParams {
    SpecialityId?: number;
    Size?: number;
    Search?: string;
    Page?: number;
    EmptyOnly?: boolean;
  }

  /**
   * Parameters for UpdateManipulationAsync
   */
  export interface UpdateManipulationAsyncParams {
    id: number;
    request?: UpdateManipulationRequest;
  }
}

export { ManipulationsService }
