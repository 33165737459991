import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SystemSettingViewModelItem } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mp-system-setting-list-item',
  templateUrl: './system-setting-list-item.component.html',
  styleUrls: ['./system-setting-list-item.component.scss']
})
export class SystemSettingListItemComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() systemSetting: SystemSettingViewModelItem = {};
  @Input() controlType = "string";

  @Input()
  set checked(value: boolean) { this.checkbox.setValue(value, { emitEvent: false }); }

  @Output()
  onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  checkbox: FormControl;

  constructor() {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: boolean): void => {
          this.onCheck.emit(value);
        },
        (): void => { }
      );
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  get displayed(): string {
    switch (this.controlType) {
      case "boolean": return this.systemSetting.value === "True" ? "Да" : "Нет";
      default: return this.systemSetting.value;
    }
  }

}
