import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { PatientPersonalInfo } from '../../models/patient.personal.info';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { AppointmentViewModel, NameStandartizationResponse, ResourcesDayScheduleViewModel, ScheduleBreakReason, ScheduleService } from 'projects/Clinic/src/app/generated/models';
import { AppointmentsV2Service, ScheduleBreakReasonsService, ScheduleHistoryService, SchedulesService, StandartizationService } from 'projects/Clinic/src/app/generated/services';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentHistoryItem } from '../../../../generated/models/appointment-history-item';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { AddBreakModalComponent } from '../add-break-modal/add-break-modal.component';
import { UserStorage } from '../../../../services/user-storage';
import { map, takeUntil } from 'rxjs/operators';

enum Mode {
  idle = 0,
  select = 1,
  edit = 2
}

@Component({
  selector: 'mp-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss', './datepicker.style.css']
})

export class DayComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @HostListener('window:popstate', ['$event'])
  onPopState = () => this.returnToWeek.emit(true);

  @Output()
  returnToWeek = new EventEmitter<boolean>();

  @Output()
  onSelectPatient = new EventEmitter<PatientPersonalInfo>();

  @Output()
  onDateChanged = new EventEmitter<string>();

  @Input() date: string;
  @Input() loading = false;
  @Input() canManageBreaks = false;
  @Input() patient: PatientPersonalInfo;
  @Input() resources: ResourcesDayScheduleViewModel[] = [];

  form: FormGroup = new FormGroup({
    firstName: new FormControl("", [/*Validators.required*/]),
    middleName: new FormControl("", [/*Validators.required*/]),
    lastName: new FormControl("", [/*Validators.required*/]),
    dob: new FormControl("", []),
    phone: new FormControl("", [Validators.required]),
    comment: new FormControl(""),
    name: new FormControl("")
  });

  dateControl = new FormControl("");

  error: string;
  scheduleHeadDate: string;

  appointments: AppointmentViewModel[] = [];
  history: Array<AppointmentHistoryItem> = [];
  reservation: { reservedByName?: string, reservedTill?: string } = null;
  loadingSlots: AppointmentViewModel[] = [];

  historyLoading = false;
  showRecommendations = false;
  showNameField = false;

  selectedSlots: SlotResource[] = [];

  private _selectedResourceId = -1;
  private activeSlotIndex = 0;

  mode: Mode = Mode.idle;

  patientId: number;
  patientReadonly = false;

  private _savedPatient: PatientPersonalInfo = null;

  services: { [resourceId: number]: ScheduleService[] } = {};

  get hasLoading(): boolean { return this.loadingSlots.length > 0; }

  constructor(
    private userStorage: UserStorage,
    private scheduleService: SchedulesService,
    private scheduleBreakReasonsService: ScheduleBreakReasonsService,
    private scheduleHistoryService: ScheduleHistoryService,
    private appointmentsService: AppointmentsV2Service,
    private standartizationService: StandartizationService,
    private modal: NgbModal,
    private toastrService: ToastrService
  ) {

    moment.locale("ru");

    this.showNameField = userStorage.profile.scheduleUseSingleControlForName;

    this.dateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: string): void => {
          this.selectedSlots = [];
          this.appointments = [];
          this._selectedResourceId = -1;

          this.onDateChanged.emit(value);
        }
      );

    this.form.get('name').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (this.showNameField) {
          this.form.patchValue({ lastName: "", firstName: "", middleName: "" });
        }
      });
  }

  ngOnInit() {
    moment.locale("ru");

    if (this.showNameField) {
      this.form.get('name').setValidators(Validators.required);
    } else {
      this.form.get('lastName').setValidators(Validators.required);
      this.form.get('firstName').setValidators(Validators.required);
      this.form.get('middleName').setValidators(Validators.required);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resources']) {
      if (this.resources.length > 0 && this._selectedResourceId === -1) {
        this.changeResource(this.resources[0]);
      }
    }

    if (changes['date']) {
      const date: moment.Moment = moment(changes['date'].currentValue, "YYYY-MM-DD");

      this.scheduleHeadDate = date.format("dddd, DD.MM.YYYY");
      this.dateControl.setValue(date.format("DD.MM.YYYY"), { emitEvent: false });
    }

    if (changes['patient']) {
      const value: PatientPersonalInfo = changes['patient'].currentValue;

      if (value) {
        this._savedPatient = { ...value };
      } else {
        this._savedPatient = undefined;
        this.enablePatientInput();
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get isInEditMode(): boolean { return this.mode === Mode.edit; }
  get isInSelectMode(): boolean { return this.mode === Mode.select; }

  get activeSlot(): SlotResource {
    return this.selectedSlots[this.activeSlotIndex];
  }

  get showSlotNav(): boolean {
    return this.mode !== Mode.edit && this.selectedSlots.length > 1;
  }

  getStringTime(date: Date): string {
    return moment(date, 'DD.MM.YYYY HH:mm').format("HH:mm");
  }

  getStringDate(date: Date): string {
    return moment(date, 'DD.MM.YYYY HH:mm').format("DD.MM.YYYY");
  }

  resourceSelected(resource: ResourcesDayScheduleViewModel): boolean {
    return resource.resourceId === this._selectedResourceId;
  }

  /**
   * !!!!!Пересмотреть подход
   * @param slot
   */
  public doctorControlNameFor(slot: AppointmentViewModel): string {
    return `doctorSelect-${slot.scheduleId}-${moment(slot.start, 'DD.MM.YYYY HH:mm').valueOf()}`;
  }

  /**
   * !!!!!Пересмотреть подход
   * @param slot
   */
  public serviceControlNameFor(slot: AppointmentViewModel): string {
    return `serviceSelect-${slot.scheduleId}-${moment(slot.start, 'DD.MM.YYYY HH:mm').valueOf()}`;
  }

  public get invalidDoctorSlots(): Array<number> {
    const res: Array<number> = [];

    for (let i = 0; i < this.selectedSlots.length; i++) {
      const control: AbstractControl = this.form.get(this.doctorControlNameFor(this.selectedSlots[i].slot));

      if (control && control.invalid) {
        res.push(i);
      }
    }

    return res;
  }

  public get missingServiceSlots(): Array<number> {
    const res: Array<number> = [];

    for (let i = 0; i < this.selectedSlots.length; i++) {
      const control: AbstractControl = this.form.get(this.serviceControlNameFor(this.selectedSlots[i].slot));

      if (control && control.invalid) {
        res.push(i);
      }
    }

    return res;
  }

  clean(): void {
    this.form.controls["dob"].setErrors(null);
  }

  stubsCount(): number[] {
    if (!this.appointments || this.appointments.length % 6 === 0) return [];
    return Array.from(Array(6 - this.appointments.length % 6).keys());
  }

  async changeResource(resource: ResourcesDayScheduleViewModel) {
    this._selectedResourceId = resource.resourceId;

    this.appointments = resource.appointments;
    this.showRecommendations = resource.isCompactBooking;

    if (resource.requiresService && !this.services[this._selectedResourceId]) {
      this.services[this._selectedResourceId] = await this.scheduleService.ScheduleServices({ scheduleId: this.appointments[0].scheduleId }).toPromise();
    }
  }

  servicesFor(slotResource: SlotResource): ScheduleService[] {
    if (!slotResource.resource.requiresService) return [];

    return this.services[slotResource.resource.resourceId] || [];
  }

  nextSlot(): void {
    this.activeSlotIndex = Math.min(this.activeSlotIndex + 1, this.selectedSlots.length - 1);
  }
  prevSlot(): void {
    this.activeSlotIndex = Math.max(this.activeSlotIndex - 1, 0);
  }

  goToSlot(index: number) {
    if (index >= 0 && index < this.selectedSlots.length) {
      this.activeSlotIndex = index;
    }
  }

  private updateValidators(slotResource: SlotResource) {
    const doctorsControl: FormControl = new FormControl(undefined, [Validators.required]);
    const serviceControl: FormControl = new FormControl(undefined, slotResource.resource.requiresService ? [Validators.required] : []);

    if (slotResource.slot.doctorId > 0) {
      doctorsControl.setValue(slotResource.slot.doctorId);
    } else {
      doctorsControl.setValue(slotResource.slot.doctors[0].value);
    }

    if (slotResource.slot.serviceId > 0) {
      serviceControl.setValue(slotResource.slot.serviceId);
    } else if (slotResource.resource.serviceId > 0) {
      serviceControl.setValue(slotResource.resource.serviceId);
    }

    this.form.addControl(this.doctorControlNameFor(slotResource.slot), doctorsControl);
    this.form.addControl(this.serviceControlNameFor(slotResource.slot), serviceControl);
  }

  private removeValidators(appointment: AppointmentViewModel) {
    this.form.removeControl(this.doctorControlNameFor(appointment));
    this.form.removeControl(this.serviceControlNameFor(appointment));
  }

  isSelected(slot: AppointmentViewModel): boolean {
    return this.selectedSlots.some(x => x.slot.scheduleId === slot.scheduleId && x.slot.start.substr(11) === slot.start.substr(11));
  }

  isLoading(appointment: AppointmentViewModel): boolean {
    if (!appointment) return false;
    return this.loadingSlots.some(x => x.scheduleId === appointment.scheduleId && x.start.substr(11) === appointment.start.substr(11));
  }

  submit() {
    switch (this.mode) {
      case Mode.select: this.clickedClaim(); break;
      case Mode.edit: this.save(); break;
    }
  }

  private async clickIdle(slot: AppointmentViewModel, currentResource: ResourcesDayScheduleViewModel) {

    if (this.showNameField) {
      document.getElementById('Name').focus();
    }
    else {
      document.getElementById('LastName').focus();
    }

    if (slot.claimed || slot.confirmed) {

      try {
        const appointment = await this.reserve(slot);

        if (appointment.reserved) {
          this.mode = Mode.edit;
          this.enablePatientInput();

          this.fillPatientInfoFromSlot(slot);

          this.selectedSlots.push({ slot: slot, resource: currentResource });
          this.activeSlotIndex = this.selectedSlots.length - 1;
          this.updateValidators({ slot: slot, resource: currentResource });
        }
      } catch (e) {
        console.error(e)

        this.markAsLoaded(slot);
        this.toastrService.error("Не удалось заблокировать слот", "Ошибка");
      }

      return;
    }

    if (slot.inThePast) return;

    this.mode = Mode.select;

    if (this._savedPatient) {

      //this.disablePatientInput();

      if (this._savedPatient.firstName) this.form.get('firstName').disable();
      if (this._savedPatient.middleName) this.form.get('middleName').disable();
      if (this._savedPatient.lastName) this.form.get('lastName').disable();
      if (this._savedPatient.phone) this.form.get('phone').disable();
      if (this._savedPatient.dob) this.form.get('dob').disable();

      this.patientId = this._savedPatient.id;
      this.patientReadonly = false;

      const value: FormValue = {
        firstName: this._savedPatient.firstName,
        middleName: this._savedPatient.middleName,
        lastName: this._savedPatient.lastName,
        phone: this._savedPatient.phone,
        name: this._savedPatient.name,
        dob: moment(this._savedPatient.dob, 'DD.MM.YYYY'),
        comment: ""
      }

      if (value.firstName || value.lastName) {
        value.name = [value.lastName, value.firstName, value.middleName].filter(x => x).join(" ").trim();
      }

      this.form.patchValue(value);
    }

    this.clickSlot(slot);
  }

  private async clickSelected(slot: AppointmentViewModel, currentResource: ResourcesDayScheduleViewModel): Promise<void> {
    if (this.showNameField) {
      document.getElementById('Name').focus();
    }
    else {
      document.getElementById('LastName').focus();
    }

    if (!slot.claimed && !slot.inThePast && !this.isSelected(slot)) {

      try {
        const appointment = await this.reserve(slot);

        if (appointment.reserved) {
          this.selectedSlots.push({ slot: slot, resource: currentResource });
          this.activeSlotIndex = this.selectedSlots.length - 1;
          this.updateValidators({ slot: slot, resource: currentResource });
        }
      } catch (e) {
        console.error(e)

        this.markAsLoaded(slot);
        this.toastrService.error("Не удалось заблокировать слот", "Ошибка");
      }

      return;
    }

    if (!this.isSelected(slot)) return;

    if (this.selectedSlots.length === 1) {
      this.mode = Mode.idle;
      this.enablePatientInput();
    }

    this.release(slot);
  }

  private async clickEdited(slot: AppointmentViewModel): Promise<void> {
    if (this.selectedSlots.length === 0) {
      return;
    }

    const clickedNewSlot: boolean = !this.isSelected(slot) && (!slot.inThePast || slot.claimed);

    if (!clickedNewSlot) {
      this.mode = Mode.idle;
      this.release(slot);
      return;
    }

    if (this.selectedSlots.length === 1) {
      this.mode = Mode.idle;

      this.release(this.selectedSlots[0].slot);

      for (let i = 0; i < this.selectedSlots.length; i++) {
        this.removeValidators(this.selectedSlots[i].slot);
      }

      this.selectedSlots = [];
      this.activeSlotIndex = 0;

      return;
    }

    this.mode = Mode.idle;
    this.clickSlot(slot);
  }

  async clickSlot(slot: AppointmentViewModel): Promise<void> {

    if (slot.outOfDoctors && !slot.break && !slot.unscheduledBreak) {
      return;
    }

    if (slot.inThePast && !slot.claimed) {
      return;
    }

    if (this.isLoading(slot)) {
      return;
    }

    const currentResource = this.resources.find(x => x.resourceId === this._selectedResourceId);

    switch (this.mode) {
      case Mode.idle: {
        this.clickIdle(slot, currentResource);
        return;
      }
      case Mode.select: {
        this.clickSelected(slot, currentResource);
        return;
      }
      case Mode.edit: {
        this.clickEdited(slot);
        return;
      }
    }
  }

  selectPatient(): void {
    this.disablePatientInput();

    const value: FormValue = this.form.getRawValue();

    const selected: PatientPersonalInfo = {
      id: this.patientId,
      firstName: value.firstName,
      middleName: value.middleName,
      lastName: value.lastName,
      name: value.name,
      phone: value.phone,
      dob: value.dob ? value.dob.format("DD.MM.YYYY") : undefined
    };

    this.onSelectPatient.emit(selected);
  }

  fillNeededInfo(appointment: AppointmentViewModel): { doctorId: number; serviceId: number } {
    return {
      doctorId: this.form.get(this.doctorControlNameFor(appointment)).value,
      serviceId: this.form.get(this.serviceControlNameFor(appointment)).value
    };
  }

  specialities(resource: ResourcesDayScheduleViewModel): string {
    if (!resource || !resource.specialities) return '';

    return resource.specialities.map(x => x.name).join(', ');
  }

  //Reserve slot for usage time
  async reserve(slot: AppointmentViewModel): Promise<AppointmentViewModel> {
    this.markAsLoading(slot);

    const request = {
      id: slot.id,
      start: slot.start
    };

    const response = await this.scheduleService.ReserveSlot({ scheduleId: slot.scheduleId, request }).toPromise();

    const appointment: AppointmentViewModel = this.findSame(response);

    if (!appointment) {
      return null;
    }

    appointment.id = response.id;
    appointment.reserved = response.reserved;
    appointment.blocked = response.blocked;

    this.markAsLoaded(appointment);

    return appointment;
  }

  //Free slot from reservation
  release(slot: AppointmentViewModel): void {
    this.markAsLoading(slot);

    this.scheduleService.ReleaseSlot(
      {
        scheduleId: slot.scheduleId,
        id: slot.id
      })
      .subscribe((response: AppointmentViewModel): void => {
        const appointment: AppointmentViewModel = this.findSame(response);

        if (!appointment) {
          return;
        }

        appointment.reserved = response.reserved;
        appointment.blocked = response.blocked;

        this.markAsLoaded(appointment);

        const index = this.selectedSlots
          .findIndex(x => x.slot.scheduleId === appointment.scheduleId && x.slot.start.substr(11) === appointment.start.substr(11));

        if (index !== -1) {
          this.selectedSlots.splice(index, 1);

          this.removeValidators(appointment);
          this.prevSlot();
          if (this.selectedSlots.length === 0) {
            this.mode = Mode.idle;
            this.clearPatientInfo();
          }
        }

      },
        () => {
          this.markAsLoaded(slot);
          this.toastrService.error("Не удалось освободить слот", "Ошибка");
        })
  }

  //Set selected slots as a break
  async break(): Promise<void> {

    if (this.selectedSlots.length === 0) {
      return;
    }

    const marker = this.selectedSlots[0];

    if (marker.slot.break || marker.slot.unscheduledBreak) {
      for (const item of this.selectedSlots) {
        this.markAsLoading(item.slot);
      }

      for (const item of this.selectedSlots) {
        const slot = item.slot;

        try {
          await this.scheduleService.RemoveBreakAsync(slot.id).toPromise();
          slot.break = false;
          slot.unscheduledBreak = false;
        } catch (e) {
          if (e.status === 400) {
            this.toastrService.warning(e.error.message, "Ошибка");
          }
          else {
            this.toastrService.error("Не удалось удалить перерыв", "Ошибка");
          }
        }

      }

      for (const item of this.selectedSlots) {
        this.release(item.slot);
      }

      for (const item of this.selectedSlots) {
        this.markAsLoaded(item.slot);
      }

      this.activeSlotIndex = 0;
      this.mode = Mode.idle;
    } else {
      this.scheduleBreakReasonsService.ReasonsAsync({ Page: 1, Size: 50, Search: '' })
        .subscribe(
          (response: ScheduleBreakReason[]): void => {
            const options: NgbModalOptions = { backdrop: 'static', size: 'lg', centered: true };
            const modalRef = this.modal.open(AddBreakModalComponent, options);
            const componentRef: AddBreakModalComponent = modalRef.componentInstance;

            componentRef.reasons = response;

            componentRef.onCancel.subscribe((): void => {
              modalRef.close();
            });

            componentRef.onConfirm.subscribe(
              async (reason: ScheduleBreakReason): Promise<void> => {
                modalRef.close();

                for (const item of this.selectedSlots) {
                  this.markAsLoading(item.slot);
                }

                for (const item of this.selectedSlots) {
                  const slot = item.slot;
                  try {
                    await this.scheduleService.AddBreakAsync({ id: slot.id, request: { reasonId: reason.id } }).toPromise();

                    slot.break = true;
                    slot.unscheduledBreak = true;
                  } catch (e) {
                    if (e.status === 400) {
                      this.toastrService.warning(e.error.message, "Ошибка");
                    }
                    else {
                      this.toastrService.error("Не удалось назначить перерыв", "Ошибка");
                    }
                  }
                }

                for (const item of this.selectedSlots) {
                  this.release(item.slot);
                }

                for (const item of this.selectedSlots) {
                  this.markAsLoaded(item.slot);
                }

                this.activeSlotIndex = 0;
                this.mode = Mode.idle;

              });
          },
          (): void => {
            this.toastrService.error('Не удалось загрузить причины перерывов', 'Ошибка')
          }
        );
    }
  }

  private handleScheduleError(response: HttpErrorResponse, generalMessage: string) {
    this.toastrService.error(generalMessage, "");

    switch (response.status) {
      case 400: {
        this.error = response.error.message;
      }
        break;
      case 404:
        {
          this.error = "Слот не найден";
        }
        break;
      case 500:
        {
          this.error = `${generalMessage}. Попробуйте повторить действие через несколько минут.`;
        }
        break;
      default: {
        this.error = generalMessage;
      }
    }
  }

  //Claim slot for current patient
  claim(slot: AppointmentViewModel) {

    const value: FormValue = this.form.getRawValue();

    this.setFormTouched();
    this.checkPatientForm();

    const { doctorId, serviceId } = this.fillNeededInfo(slot);

    const selected: SlotResource = this.selectedSlots.find((x: SlotResource): boolean => x.slot.id === slot.id);
    const services: ScheduleService[] = this.services[selected.resource.resourceId];

    if (selected.resource.requiresService && services) {
      const service: ScheduleService = services.find(x => x.id === serviceId);

      if (!service) {
        this.toastrService.warning('Необходимо выбрать услугу', '');
        return;
      }
    }

    if (this.form.invalid) {
      return;
    }

    this.markAsLoading(slot);

    const dob = value.dob && moment(value.dob).isValid() ? moment(value.dob) : undefined;

    this.appointmentsService.ClaimAsync({
      id: slot.id,
      request: {
        id: slot.id,
        serviceId: serviceId,
        doctorId: slot.doctors.length === 1 ? parseInt(slot.doctors[0].value, 10) : doctorId,
        patientId: this.patientId,
        patientFirstName: value.firstName,
        patientMiddleName: value.middleName,
        patientLastName: value.lastName,
        patientPhone: value.phone,
        patientDateOfBirth: dob ? dob.format("DD.MM.YYYY") : "",
        patientName: value.name,
        comment: value.comment
      }
    })
      .subscribe((response: AppointmentViewModel) => {
        const appointment: AppointmentViewModel = this.findSame(response);

        if (!appointment) {
          return;
        }

        appointment.claimed = true;
        appointment.blocked = false;
        appointment.patientId = this.patientId;
        appointment.patientLastName = response.patientLastName;
        appointment.patientFirstName = response.patientFirstName;
        appointment.patientMiddleName = response.patientMiddleName;
        appointment.patientPhone = value.phone;
        appointment.patientDateOfBirth = dob ? dob.format("DD.MM.YYYY") : "";

        appointment.doctorId = response.doctorId;
        appointment.serviceId = response.serviceId;

        appointment.comment = response.comment;

        this.release(appointment);
        this.markAsLoaded(appointment);

        this.mode = Mode.idle;
      },
        (response: HttpErrorResponse) => {
          this.markAsLoaded(slot);
          this.handleScheduleError(response, "Не удалось записать пациента");
        });
  }

  //Confirm current patients info for this slot
  confirm(): void {
    const slot = this.selectedSlots[this.activeSlotIndex].slot;

    this.markAsLoading(slot);

    this.scheduleService.ConfirmSlot(
      {
        id: slot.id,
        scheduleId: slot.scheduleId,
        request: {
          patientId: slot.patientId
        }
      }
    )
      .subscribe(
        (response: AppointmentViewModel) => {
          const appointment = this.findSame(response);

          if (!appointment) {
            return;
          }

          appointment.confirmed = true;

          this.markAsLoaded(appointment);
        },
        (): void => {
          this.markAsLoaded(slot);
          this.toastrService.error("Не удалось подтвердить запись", "Ошибка");
        }
      );
  }

  deny(): void {
    const slot = this.selectedSlots[this.activeSlotIndex].slot;

    this.markAsLoading(slot);

    this.scheduleService.DenySlot(
      {
        id: slot.id,
        scheduleId: slot.scheduleId
      })
      .subscribe(
        (response: AppointmentViewModel) => {
          const appointment = this.findSame(response);

          if (!appointment) {
            return;
          }

          appointment.confirmed = false;
          this.markAsLoaded(appointment);
        },
        (): void => {
          this.toastrService.error("Не удалось снять подтверждение", "Ошибка");
        }
      );
  }

  //Save changed patients info
  async save(): Promise<boolean> {
    const value: FormValue = this.form.getRawValue();

    this.setFormTouched();
    const slot = this.selectedSlots[this.activeSlotIndex].slot;

    this.checkPatientForm();

    const { doctorId, serviceId } = this.fillNeededInfo(slot);

    const selected: SlotResource = this.selectedSlots.find((x: SlotResource): boolean => x.slot.id === slot.id);
    const services: ScheduleService[] = this.services[selected.resource.resourceId];

    if (selected.resource.requiresService && services) {
      const service: ScheduleService = services.find(x => x.id === serviceId);

      if (!service) {
        this.toastrService.warning('Необходимо выбрать услугу', '');
        return false;
      }
    }

    if (this.form.invalid) {
      return false;
    }

    const dob = value.dob && moment(value.dob).isValid() ? moment(value.dob) : undefined;

    this.markAsLoading(slot);
    try {
      const response = await this.appointmentsService.UpdateAsync({
        id: slot.id,
        request: {
          serviceId: serviceId,
          doctorId: slot.doctors.length === 1 ? parseInt(slot.doctors[0].value, 10) : doctorId,
          patientFirstName: value.firstName,
          patientMiddleName: value.middleName,
          patientLastName: value.lastName,
          patientName: value.name,
          patientPhone: value.phone,
          patientDateOfBirth: dob ? dob.format("DD.MM.YYYY") : "",
          comment: value.comment
        }

      }).toPromise();

      const appointment = this.findSame(response);

      if (!appointment) {
        return false;
      }

      appointment.patientId = this.patientId;
      appointment.patientLastName = value.lastName;
      appointment.patientFirstName = value.firstName;
      appointment.patientMiddleName = value.middleName;
      appointment.patientPhone = value.phone;
      appointment.patientDateOfBirth = dob ? dob.format("DD.MM.YYYY") : "";

      appointment.doctorId = response.doctorId; appointment.claimed = true;
      appointment.blocked = false;

      appointment.serviceId = response.serviceId;
      appointment.doctorId = response.doctorId;

      appointment.comment = response.comment;
    } catch (e) {
      const response = e as HttpErrorResponse;
      this.handleScheduleError(response, "Не удалось сохранить запись");
      return false;
    }

    this.markAsLoaded(slot);
    return true;
  }

  reject(): void {
    const options: NgbModalOptions = { size: 'sm', backdrop: 'static', centered: true };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    const slot = this.selectedSlots[this.activeSlotIndex].slot;
    const name = slot.patientLastName + ' ' + slot.patientFirstName + ' ' + slot.patientMiddleName;
    const time = moment(slot.start, 'DD.MM.YYYY HH:mm').format("HH:mm DD.MM.YYYY (dddd)");
    componentRef.message = `Удалить запись ${name} на ${time}?`;

    modalRef.result.then(
      (result: boolean): void => {

        this.markAsLoading(slot);

        this.scheduleService.RejectSlot({
          id: slot.id,
          scheduleId: slot.scheduleId,
        })
          .subscribe(
            (response: AppointmentViewModel) => {
              const appointment = this.findSame(response);

              if (!appointment) {
                return;
              }

              appointment.claimed = false;
              appointment.confirmed = false;
              appointment.patientId = undefined;
              appointment.patientLastName = '';
              appointment.patientFirstName = '';
              appointment.patientMiddleName = '';
              appointment.patientPhone = '';
              appointment.patientDateOfBirth = '';

              this.markAsLoaded(appointment);
              this.release(appointment);

              this.activeSlotIndex = 0;
              this.mode = Mode.idle;
            },
            () => {
              this.markAsLoaded(slot);
              this.toastrService.error("Не удалось очистить слот", "Ошибка");
            });

      },
      () => { }
    );
  }

  async notify(): Promise<void> {
    const options: NgbModalOptions = { backdrop: 'static', centered: true };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    const value: FormValue = this.form.getRawValue();

    const firstname = value.firstName;
    const lastname = value.lastName;
    const middlename = value.middleName;
    const phone = value.phone;

    const slot = this.selectedSlots[this.activeSlotIndex].slot;
    const name = lastname + ' ' + firstname + ' ' + middlename;
    const time = moment(slot.start, 'DD.MM.YYYY HH:mm').format("HH:mm DD.MM.YYYY (dddd)");

    const formatted = phone.length === 10 ?
      `+7&nbsp;(${phone.substr(0, 3)})&nbsp;${phone.substr(3, 3)}-${phone.substr(6, 2)}-${phone.substr(8, 2)}` :
      `+7&nbsp;(${phone.substr(2, 3)})&nbsp;${phone.substr(5, 3)}-${phone.substr(8, 2)}-${phone.substr(10, 2)}`;

    componentRef.message = `Отправить уведомление о записи <b>${name}</b> на <b class="nowrap">${time}</b> на номер <b class="nowrap">${formatted}</b>?`;
    componentRef.confirmBtnText = "Отправить";

    try {
      await modalRef.result;

      const success = await this.save();

      if (!success) return;

      try {
        await this.scheduleService.NotifyAsync({ id: slot.id, scheduleId: slot.scheduleId }).toPromise();
        this.toastrService.success("Уведомление отправлено", "Успешно");
      } catch (e) {
        const response = e as HttpErrorResponse;
        if (response.status === 400 && response.error) {
          this.toastrService.warning(response.error.message, "Ошибка");
        }
        else {
          this.toastrService.error("Не удалось отправить уведомление", "Ошибка");
        }
      }
    } catch (e) { }
  }

  clickedClaim(): void {
    this.setFormTouched();
    this.checkPatientForm();
    if (this.form.invalid) {
      return;
    }

    for (const selected of this.selectedSlots) {
      this.claim(selected.slot);
    }
  }

  checkPatientForm = (): void => {
    const value: FormValue = this.form.getRawValue();
    if (value.dob) {
      const min: moment.Moment = moment("01.01.1900", "DD.MM.YYYY");
      const max: moment.Moment = moment().startOf("day");

      const dob = moment(value.dob);

      if (dob.isBefore(min)) {
        this.form.controls['dob'].setErrors({ inThePast: true });
      }
      if (dob.isAfter(max)) {
        this.form.controls['dob'].setErrors({ inTheFuture: true });
      }

    }
  }

  disablePatientInput(): void {
    this.form.get('firstName').disable();
    this.form.get('middleName').disable();
    this.form.get('lastName').disable();
    this.form.get('phone').disable();
    this.form.get('dob').disable();
  }

  enablePatientInput(): void {
    this.form.get('firstName').enable();
    this.form.get('middleName').enable();
    this.form.get('lastName').enable();
    this.form.get('phone').enable();
    this.form.get('dob').enable();
  }

  clearPatientInfo(): void {
    this.patientId = undefined;

    [
      'firstName',
      'middleName',
      'lastName',
      'name',
      'phone',
      'dob',
      'comment'
    ].map(x => this.form.get(x))
      .forEach(x => {
        x.reset();
        x.markAsUntouched();
        x.markAsPristine();
        x.enable();
      });
  }

  fillPatientInfoFromSlot(slot: AppointmentViewModel) {
    const value: FormValue = {
      firstName: slot.patientFirstName,
      middleName: slot.patientMiddleName,
      lastName: slot.patientLastName,
      phone: slot.patientPhone,
      dob: moment(slot.patientDateOfBirth, 'DD.MM.YYYY'),
      comment: slot.comment
    }

    if (value.firstName || value.lastName) {
      value.name = [value.lastName, value.firstName, value.middleName].filter(x => x).join(" ").trim();
    }

    this.form.patchValue(value, { emitEvent: false });
  }

  setFormTouched(): void {
    Object.entries(this.form.controls)
      .forEach((x: [string, AbstractControl]): void => {
        x[1].markAsDirty();
        x[1].markAsTouched();
      });
  }

  clearErrors(): void {
    this.error = "";
  }

  removeActiveSlot(): void {
    this.release(this.selectedSlots[this.activeSlotIndex].slot);
  }

  selectResourceFromActiveSlot(): void {
    this._selectedResourceId = this.activeSlot.resource.resourceId;
  }

  async loadStory(slot: AppointmentViewModel): Promise<void> {
    this.historyLoading = true;

    try {
      const response = await this.scheduleHistoryService.HistoryAsync({
        scheduleId: slot.scheduleId,
        Date: slot.start
      }).toPromise();

      this.history = response.items;

      if (response.reservation
        && response.reservation.reservedTill
        && moment(response.reservation.reservedTill, 'DD.MM.YYYY HH:mm').isAfter(moment())) {
        this.reservation = {
          reservedByName: response.reservation.reservedByName,
          reservedTill: response.reservation.reservedTill
        };
      } else {
        this.reservation = null;
      }

    } catch (e) { }

    this.historyLoading = false;

  }

  returnPressed = (): void => this.returnToWeek.emit(true);
  /**
   * / Указать, что слот загружается
   * @param appointment
   */
  private markAsLoading(appointment: AppointmentViewModel): void {
    if (!appointment) {
      return;
    }

    if (this.loadingSlots.some(x => x.scheduleId === appointment.scheduleId && x.start.substr(11) === appointment.start.substr(11))) {
      return;
    }

    this.loadingSlots.push(appointment);
  }
  /**
   * Указать, что слот загружен
   * @param appointment
   */
  private markAsLoaded(appointment: AppointmentViewModel): void {
    if (!appointment) {
      return;
    }

    const index: number = this.loadingSlots.findIndex(x => x.scheduleId === appointment.scheduleId && x.start.substr(11) === appointment.start.substr(11));

    if (index !== -1) {
      this.loadingSlots.splice(index, 1);
    }
  }

  private findSame(appointment: AppointmentViewModel): AppointmentViewModel {
    for (const resource of this.resources) {
      const result = resource.appointments.find(x => x.scheduleId === appointment.scheduleId && x.start.substr(11) === appointment.start.substr(11));

      if (result) {
        return result;
      }
    }

    return undefined;
  }

  loader = (raw: string) => this.standartizationService.NameAsync({ raw });
  selectSuggestion = (x: NameStandartizationResponse) => this.form.patchValue({ lastName: x.lastName, firstName: x.firstName, middleName: x.middleName });

  copyPhone(value: string) {
    if (!value) return;

    const textarea: HTMLTextAreaElement = document.createElement("textarea");
    textarea.value = value;

    textarea.style.position = "fixed";
    textarea.style.top = "-1000px";

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    this.toastrService.success(`${value}`, 'Скопировано');
  }

  pastePhone(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');

    if (pastedText && pastedText.startsWith('+7')) {
      this.form.get('phone').setValue(pastedText.replace('+7', '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('7') && pastedText.length === 11) {
      this.form.get('phone').setValue(pastedText.replace(/^7/, '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('8') && pastedText.length === 11) {
      this.form.get('phone').setValue(pastedText.replace(/^8/, '').replace(/\D/g, ''));
    }
  }

  priceFor(slot: SlotResource) {
    const control = this.form.get(this.serviceControlNameFor(slot.slot));

    if (!control || !control.value) return null;

    const service = this.servicesFor(slot).find(x => x.id === parseInt(control.value, 10));

    if (!service) return null;

    return service.price;
  }

  formattedPriceFor(slot: SlotResource) {
    const price = this.priceFor(slot);

    return price ? price.toLocaleString('ru-RU', {
      style: 'decimal',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }) : null;
  }
}

interface SlotResource {
  slot: AppointmentViewModel;
  resource: ResourcesDayScheduleViewModel;
}

interface FormValue {
  firstName?: string;
  middleName?: string;
  lastName?: string;

  name?: string;

  dob?: moment.Moment;
  phone?: string;
  comment?: string;
}
