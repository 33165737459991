import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ClinicalRecommendation, DiseaseOutcome, RecordNeighborsResponse, RecordReviewDefectDescription, RecordReviewDescription, RecordReviewRate, RecordReviewSectionDescription, RecordSection, ReviewSectionComment } from '../../../../generated/models';
import { ClinicalRecommendationsService, MyService, PatientsService, RecordsService, ReviewsService } from '../../../../generated/services';
import { UserStorage } from '../../../../services/user-storage';
import { RevisionPayload } from '../../resolvers/revision-resolver';

import * as moment from 'moment';
import { ReviewSettings } from '../../../../generated/models/review-settings';
import { PermissionNames } from '../../../../models/permission-names';
import { NavigatorDirection } from '../revision-navigation-group/revision-navigation-group.component';

@Component({
  selector: 'mp-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.scss'],
  host: { class: "page" }
})
export class RevisionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  breadcrumbs: { url: string, title: string }[] = [];

  neighbors: RecordNeighborsResponse = { next: [], previous: [] };

  recordId: number;
  reviewId: number;

  title = '';

  authorId: number;
  author = '';
  recordSigned = '';

  patient = '';
  patientAge: number;

  created = '';

  dirty = false;
  documentChanged = false;
  reviewSettings: ReviewSettings;
  description: RecordReviewDescription;

  sectionsLoading = false;

  sections: RecordSection[] = [];
  defects: RecordReviewDefectDescription = {};

  sectionReviews: SectionReview[] = [];

  rateValues: RecordReviewRate[] = [];

  recommendations: ClinicalRecommendation[] = [];
  comments: ReviewSectionComment[] = [];
  outcomes: DiseaseOutcome[] = [];

  mode: "review" | "record" | "mixed" = "record";

  criterias: string[] = [
    'complaints', 'anamnesis', 'examination', 'preliminaryDiagnosis',
    'diagnostics', 'treatment', 'primaryDisgnosis', 'timing',
    'quality', 'effectivenes'];

  orphans: string[] = [];

  result: ReviewResult = {};
  saved: ReviewResult = {};

  savedFormValue: FormValue = {};

  selectedDefects: Array<0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192> = [];
  savedDefectes: Array<0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048| 4096 | 8192> = [];

  reviewForm: FormGroup;
  nextRecordControl: FormControl;
  previousRecordControl: FormControl;

  reviewer = '';
  isAuthor = false;
  isReviewer = false;

  readonly = true;

  revised = false;
  signed = false;

  canReview = false;

  defectDescriptions: { value: number; text: string }[] = [];

  previousNeighborsFlag = NavigatorDirection.BACKWARD;
  nextNeighborsFlag = NavigatorDirection.FORWARD;

  get isNew(): boolean {
    return this.signed && !this.revised && this.isAuthor;
  }

  get isRevised(): boolean {
    return this.signed && this.revised && !this.isAuthor;
  }

  constructor(
    private router: Router,
    private myService: MyService,
    private activatedRoute: ActivatedRoute,
    private userStorage: UserStorage,
    private reviewsService: ReviewsService,
    private recommendationsService: ClinicalRecommendationsService,
    private patientsService: PatientsService,
    private toastrService: ToastrService,
    private recordsService: RecordsService) {

    this.reviewForm = new FormGroup({
      reviewType: new FormControl(2, [Validators.required]),
      reviewDate: new FormControl(undefined, [Validators.required]),
      defectFatal: new FormControl(false),
      otherReason: new FormControl("", [Validators.required]),
      recommendations: new FormControl("")
    });

    this.previousRecordControl = new FormControl(undefined);
    this.nextRecordControl = new FormControl(undefined);

    this.reviewForm.disable({ emitEvent: false });

    this.canReview = this.userStorage.hasPermission(PermissionNames.ReviewRecords);
  }

  async ngOnInit() {
    this.sectionsLoading = true;

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationEnd),
        switchMap(e => this.recordsService.RecordNeighboursAsync(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)))
      )
      .subscribe((response: RecordNeighborsResponse) => this.neighbors = response);

    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationEnd),
        switchMap(() => this.loadSections(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)))
      )
      .subscribe(() => { });

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: { payload: RevisionPayload }): Promise<void> => {
        this.rateValues = data.payload.rates;

        if (this.neighbors.previous.length > 0) {
          this.previousRecordControl = new FormControl(this.neighbors.previous[0].id);
        }

        if (this.neighbors.next.length > 0) {
          this.nextRecordControl = new FormControl(this.neighbors.next[0].id);
        }

        this.reviewSettings = data.payload.settings;
        this.description = data.payload.description;
        this.defects = data.payload.defects;
        this.outcomes = data.payload.outcomes;

        this.defectDescriptions.push({ value: 1, text: this.defects.failureToComply });
        this.defectDescriptions.push({ value: 2, text: this.defects.lackOfTools });
        this.defectDescriptions.push({ value: 4, text: this.defects.incompleteExamination });
        this.defectDescriptions.push({ value: 8, text: this.defects.objectiveDifficultiesInDiagnosis });
        this.defectDescriptions.push({ value: 16, text: this.defects.wrongConsultant });
        this.defectDescriptions.push({ value: 32, text: this.defects.objectiveDifficultiesOfTreatment });
        this.defectDescriptions.push({ value: 64, text: this.defects.lackOfContinuity });
        this.defectDescriptions.push({ value: 128, text: this.defects.insufficientQualifications });
        this.defectDescriptions.push({ value: 256, text: this.defects.other });
        this.defectDescriptions.push({ value: 512, text: this.defects.returnDateUnset });
        this.defectDescriptions.push({ value: 1024, text: this.defects.returnDateNotJustified });
        this.defectDescriptions.push({ value: 2048, text: this.defects.notSentToAnotherSpecialist });

        this.recordId = data.payload.record.id;

        this.title = data.payload.record.title;

        this.author = data.payload.record.signedByName;
        this.authorId = data.payload.record.signedById;
        this.patient = data.payload.record.patientName;
        this.created = data.payload.record.created;
        this.recordSigned = data.payload.record.signedDate;
        this.signed = !!data.payload.review.signDate;

        if (data.payload.review.id > 0) {
          if (data.payload.record.id !== data.payload.review.recordId) {
            this.router.navigate(['../../../', data.payload.review.recordId, 'reviews', data.payload.review.id], { relativeTo: this.activatedRoute });
            return;
          }

          this.reviewId = data.payload.review.id;
          this.reviewer = data.payload.review.reviewerName;
          this.comments = data.payload.review.comments || [];

          this.isAuthor = data.payload.record.signedById === this.userStorage.profile.id;
          this.isReviewer = data.payload.review.reviewerId === this.userStorage.profile.id && this.canReview;

          this.revised = !!data.payload.review.revised;

          this.readonly = !this.isReviewer || !this.canReview;

          this.result = {
            anamnesis: data.payload.review.anamnesis,
            complaints: data.payload.review.complaints,
            diagnostics: data.payload.review.diagnostics,
            effectivenes: data.payload.review.effectivenes,
            examination: data.payload.review.examination,
            preliminaryDiagnosis: data.payload.review.preliminaryDiagnosis,
            primaryDisgnosis: data.payload.review.primaryDisgnosis,
            quality: data.payload.review.quality,
            timing: data.payload.review.timing,
            treatment: data.payload.review.treatment,
          };

          if (!this.readonly) {
            this.reviewForm.enable({ emitEvent: false });
          }

          this.defectsChanged([...data.payload.review.defects]);

          this.savedDefectes = [...data.payload.review.defects];

          this.savedFormValue = {
            reviewType: data.payload.review.reviewType,
            reviewDate: moment(data.payload.review.reviewDate, "DD.MM.YYYY"),
            otherReason: data.payload.review.defectCauseDescription,
            defectFatal: data.payload.review.defectFatal,
            recommendations: data.payload.review.recommendations,
          };

          this.saved = { ...this.result };

        } else {
          this.reviewer = this.userStorage.profile.userName;
          this.readonly = false;

          this.savedFormValue = {
            reviewDate: moment()
          }

          this.reviewForm.enable({ emitEvent: false });
          this.reviewForm.get('otherReason').disable({ emitEvent: false });
        }

        this.reviewForm.patchValue({ ...this.savedFormValue });

        this.breadcrumbs = [
          { url: '/', title: 'Главная' },
          { url: '/quality-control', title: 'Клинико-экспертная работа' },
          { url: '/quality-control/records', title: 'Список протоколов' }
        ];

        const patient = await this.patientsService.Patient(data.payload.record.patientId).toPromise();

        this.patientAge = parseInt(patient.age);

        this.reviewForm.valueChanges
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.documentChanged = true);
      });

    this.loadSections(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(() => { });
    this.loadNeighbours(parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10)).subscribe(() => { });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get total(): number { return 10; }
  get rated(): number { return Object.values(this.result).filter(x => x !== undefined && x !== null).length; }
  get fatal(): boolean { return this.reviewForm.get('defectFatal').value; }

  get hasDefects(): boolean { return this.selectedDefects.length > 0; }
  get hasOtherDefects(): boolean { return this.selectedDefects.includes(256); }
  get otherDefects(): string { return this.reviewForm.getRawValue().otherReason; }

  get recommendationsValue(): string { return this.reviewForm.getRawValue().recommendations; }

  get average(): string {
    const values = Object.values(this.result)
      .filter(x => x !== undefined && x != null)
      .map(x => parseFloat(x))

    if (values.length === 0) {
      return "-";
    }

    return (values.reduce((x: number, y: number): number => x + y, 0) / values.length).toFixed(2);
  }

  loadSections = (recordId: number) => this.recordsService.Sections(recordId)
    .pipe(
      tap(response => {
        this.sections = response.filter(x => x.show);
        this.sectionReviews = this.sections.map(x => ({
          section: x,
          criteria: this.criteriaName(x),
          comment: (this.comments.find(s => x.id === s.sectionId) || {}).text
        }));

        for (const criteria of this.criterias) {
          if (!this.sections.some(x => this.criteriaName(x) === criteria)) {
            this.orphans.push(criteria);

            this.sectionReviews.push({ section: undefined, criteria, comment: '' });
          }
        }
      }),
      switchMap((response): Observable<ClinicalRecommendation[]> => {
        const primaryDiagnosis = response.find(x => x.show && x.type === 2 && x.diagnosisType === 1);
        if (primaryDiagnosis && primaryDiagnosis.diagnosisCode) {
          return this.recommendationsService.GetByDiagnosisCode(primaryDiagnosis.diagnosisCode)
        }

        return of([]);
      }),
      tap(response => { this.recommendations = response; }),
      tap(() => { this.sectionsLoading = false; })
    );

  loadNeighbours = (recordId: number) => this.recordsService.RecordNeighboursAsync(recordId)
    .pipe(tap(response => { this.neighbors = response; }));

  setReviewMode(): void { this.mode = "review"; }
  setRecordMode(): void { this.mode = "record"; }
  setMixedMode(): void { this.mode = "mixed"; }

  toggleMode(): void {
    this.mode = this.mode === 'review' ? 'record' : 'review';
  }

  defectsChanged(defects: Array<0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192>): void {
    this.selectedDefects = [...defects];

    if (this.selectedDefects.some(x => x === 256)) {
      this.reviewForm.get('otherReason').enable({ emitEvent: false });
    } else {
      this.reviewForm.get('otherReason').setValue("");
      this.reviewForm.get('otherReason').disable({ emitEvent: false });
    }
  }

  async openProtocol(id: number): Promise<void> {
    this.sectionsLoading = true;

    this.sections = [];
    this.sectionReviews = [];
    this.result = {};
    this.neighbors = { next: [], previous: [] };

    const response = await this.reviewsService.RecordReviewsAsync(id).toPromise();

    const review = response.find(x => x.reviewerId === this.userStorage.profile.id);

    if (review) {
      this.router.navigate(['quality-control', 'records', id, 'reviews', review.id]);
    } else {
      this.router.navigate(['quality-control', 'records', id, 'reviews', 'new']);
    }
  }

  navigateToProtocol(event: number): void {
    if (event != null) {
      this.openProtocol(event);
    }
  }

  reset(): void {
    this.result = { ...this.saved };
    this.reviewForm.patchValue({ ...this.savedFormValue });

    this.defectsChanged([...this.savedDefectes]);
  }

  cancel(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }

  async saveDraft(): Promise<void> {
    await this.submit(false);
  }

  async saveAndSign(): Promise<void> {
    await this.submit(true);
  }

  async submit(sign = false): Promise<boolean> {
    this.dirty = true;
    Object.entries(this.reviewForm.controls).forEach((x: [string, AbstractControl]): void => { x[1].markAsDirty(); });
    const value: FormValue = this.reviewForm.getRawValue();

    const errors: string[] = [];

    if (this.rated !== 10) {
      errors.push('Необходимо выставить оценки по всем критериям')
    }

    if (this.reviewForm.invalid) {
      if (this.reviewForm.hasError('required', 'reviewDate')) {
        errors.push('Необходимо указать дату проверки');
      }

      if (this.reviewForm.hasError('required', 'otherReason')) {
        errors.push('Необходимо указать причину дефекта (другое)');
      }
    }

    if (errors.length > 0 && sign) {
      const message = errors.join("<br/>");

      this.toastrService.warning(message, 'Не удалось сохранить карту оценки', { timeOut: 8000, enableHtml: true });
      return false;
    }

    if (this.reviewId > 0) {

      try {
        await this.reviewsService.UpdateRecordReviewAsync({
          id: this.reviewId,
          request: {
            ...this.result,
            defects: [...this.selectedDefects],
            otherReason: value.otherReason,
            defectFatal: value.defectFatal,
            recommendations: value.recommendations,
            reveiwType: value.reviewType,
            reviewDate: value.reviewDate.format("DD.MM.YYYY"),
            comments: this.comments,

            sign
          }
        }).toPromise();

        if (sign) {
          this.toastrService.success("Результаты проверки качества протокола сохранены", "Успешно");
        } else {
          this.toastrService.success("Черновик сохранен", "Успешно");
        }

        this.saved = { ...this.result };
        this.savedFormValue = { ...value };
        this.savedDefectes = [...this.selectedDefects];

        this.signed = sign;
        return true;
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400 && response.error) {
          for (const error of response.error) {
            this.toastrService.warning(error.message, "Ошибка");
          }
        }
        else {
          this.toastrService.error("Не удалось сохранить результаты проверки качества протокола", "Ошибка");
        }

        return false;
      }
    }
    else {

      try {
        const response = await this.reviewsService.CreateRecordReviewAsync({
          recordId: this.recordId,
          request: {
            ...this.result,
            defects: [...this.selectedDefects],
            otherReason: value.otherReason,
            defectFatal: value.defectFatal,
            recommendations: value.recommendations,
            reviewType: value.reviewType,
            reviewDate: value.reviewDate.format("DD.MM.YYYY"),
            comments: this.comments,

            sign
          }
        }).toPromise();

        if (sign) {
          this.toastrService.success("Результаты проверки качества протокола сохранены", "Успешно");
        } else {
          this.toastrService.success("Черновик сохранен", "Успешно");
        }

        this.router.navigate(['..', response.reviewId], { relativeTo: this.activatedRoute });
        return true;
      }
      catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400 && response.error) {
          for (const error of response.error) {
            this.toastrService.warning(error.message, "Ошибка");
          }
        }
        else {
          this.toastrService.error("Не удалось сохранить результаты проверки качества протокола", "Ошибка");
        }

        return false;
      }
    }
  }

  async submitAndPrint(): Promise<void> {

    if (this.signed) {
      await this.print();
      return;
    }

    const saved = await this.submit(false);
    if (saved) {
      await this.print();
    }
  }

  async print(): Promise<void> {
    const parameters: { [key: string]: string } = {
      recordReviewId: `${this.reviewId}`,
      name: 'RecordReviewReport'
    };

    const type = 'html';
    const download = false;

    const response = await this.myService.AccessToken({ parameters: parameters }).toPromise();
    const url = `api/v1/Reports/Prepared?token=${response.token}&type=${type}${(download ? '&download=true' : '')}`;

    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = url;

    document.body.append(frame);
    frame.contentWindow.print();
  }

  criteriaDescription(name: string): RecordReviewSectionDescription {
    return this.description[name];
  }

  criteriaValue(name: string): string {
    return this.result[name];
  }

  setCriteria(name: string, value: number): void {
    this.documentChanged = true;
    this.result[name] = value;
  }

  updateCriteria(value: ReviewResult): void {
    this.result = { ...this.result, ...value };
  }

  criteriaName(section: RecordSection): string {

    for (const criteria of this.criterias) {
      if (this.reviewSettings.reviewCriteriaTemplates[criteria]) {
        const regex = new RegExp(this.reviewSettings.reviewCriteriaTemplates[criteria], 'i');
        if (regex.test(section.title)) return criteria;
      }
    }

    return undefined;
  }

  updateComment(section: RecordSection, value: string): void {
    let comment = this.comments.find(x => x.sectionId === section.id);
    if (!comment) {
      comment = {
        sectionId: section.id
      };
      this.comments.push(comment);
    }
    comment.text = value;
  }

  async revise(): Promise<void> {
    if (!this.isAuthor) return;

    try {
      await this.reviewsService.ReviseReviewAsync({ id: this.reviewId, request: {} }).toPromise();

      this.revised = true;

      this.toastrService.success("Метка \"Ознакомлен\" добавлена", "Успешно");
    } catch (e) {
      const response = e as HttpErrorResponse;

      if (response.status === 400 && response.error) {
        for (const error of response.error) {
          this.toastrService.warning(error.message, "Ошибка");
        }
      }
      else {
        this.toastrService.error("Не удалось добавить метку \"Ознакомлен\"", "Ошибка");
      }
    }
  }

  defectSelected(defect: { value: 0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 }): boolean {
    if (defect.value === 256) return false;
    return this.selectedDefects.includes(defect.value);
  }

  get showNextRecordControlGroup(): boolean {
    return this.neighbors.next.length > 0;
  }

  get showPrevRecordControlGroup(): boolean {
    return this.neighbors.previous.length > 0;
  }
}

export interface SectionRate {
  id: number;
  rate: number;
}

export interface ReviewResult {

  complaints?: number;
  anamnesis?: number;
  examination?: number;
  preliminaryDiagnosis?: number;
  diagnostics?: number;
  treatment?: number;
  primaryDisgnosis?: number;
  timing?: number;
  quality?: number;
  effectivenes?: number;
}

interface FormValue {
  reviewType?: 0 | 1 | 2;
  reviewDate?: moment.Moment;
  defectFatal?: boolean;
  otherReason?: string;
  recommendations?: string;
}

export interface SectionReview {
  section?: RecordSection;
  criteria?: string;
  comment?: string;
}
