import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";

import moment from "moment";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";

@Component({
  selector: 'mp-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatepickerComponent),
    multi: true
  }]
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() tabindex = 1;
  disabled = false;

  maskedControl = new FormControl();
  pickedControl = new FormControl();

  onChange = (value: moment.Moment) => { };

  constructor() {
    this.maskedControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((x, y) => x === y)
      ).subscribe((value: string) => {
        this.pickedControl.setValue(value, { emitEvent: false });
        this.change(value);
       
      });

    this.pickedControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((x, y) => x === y)
      ).subscribe((value: string) => {
        this.maskedControl.setValue(value, { emitEvent: true });

      });

  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  writeValue(obj: moment.Moment): void {
    let value = "";

    if (obj && obj.isValid()) {
      value = obj.format('DD.MM.YYYY');
    }

    this.maskedControl.setValue(value, { emitEvent: false });
    this.pickedControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    if (fn) {
      this.onChange = fn;
    }
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.maskedControl.disable({ emitEvent: false });
      this.pickedControl.disable({ emitEvent: false });
    } else {
      this.maskedControl.enable({ emitEvent: false });
      this.pickedControl.enable({ emitEvent: false });
    }
  }

  change(value: string) {

    if (value && value.length === 10 && moment(value, 'DD.MM.YYYY').isValid()) {
      this.onChange(moment(value, 'DD.MM.YYYY'));
      return;
    }

    this.onChange(undefined);
  }
}
