import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ServiceCategoryPayload } from '../../resolvers/service-category-resolver';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ServiceCategoriesService } from '../../../../generated/services';
import { CreateServiceCategoryResponse } from '../../../../generated/models';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'mp-service-category',
  templateUrl: './service-category.component.html',
  styleUrls: ['./service-category.component.scss'],
  host: { class: "page" }
})
export class ServiceCategoryComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();

  private _id: number;
  private _processing: boolean;
  private _title: string;

  private _canCreate: boolean;
  private _canEdit: boolean;
  private _readonly: boolean;

  public get canCreate(): boolean { return this._canCreate = this._canCreate; }
  public get canEdit(): boolean { return this._canEdit; }
  public get readonly(): boolean { return this._readonly; }
  public get processing(): boolean { return this._processing; }

  public get title(): string { return this._title; }

  public form: FormGroup;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userStorage: UserStorage,
    private toastrService: ToastrService,
    private serviceCategoriuesService: ServiceCategoriesService,
    private titleService: Title

  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required])
    });

    this._canCreate = userStorage.hasPermission(PermissionNames.EditCategories);
    this._canEdit = userStorage.hasPermission(PermissionNames.EditCategories);
  }

  ngOnInit() {
    this._id = this.activatedRoute.snapshot.paramMap.has("id") ?
      parseInt(this.activatedRoute.snapshot.paramMap.get("id"), 10) : undefined;

    this._readonly = (this._id > 0 && !this._canEdit) || (!this._id && !this._canCreate);

    this.activatedRoute.data
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (data: { payload: ServiceCategoryPayload }): void => {
          this.form.patchValue(data.payload.category);
          this._title = data.payload.category.id > 0 ? `Категория: ${data.payload.category.name}` : "Новая категория";
        },
        (): void => { }
      );

    if (this._readonly) {
      this.form.enabled ? this.form.disable() : undefined;
    } else {
      this.form.disabled ? this.form.enable() : undefined;
    }
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public cancel(): void {
    this.router.navigate([".."], { relativeTo: this.activatedRoute });
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }

    if (this.readonly) {
      return;
    }

    if (this._processing) {
      return;
    }

    if (this._id > 0) {
      this.update();
    } else {
      this.create();
    }
  }

  private create(): void {
    this._processing = true;

    const value: { name: string } = this.form.getRawValue();

    this.serviceCategoriuesService.Create({
      name: value.name
    })
      .subscribe(
        (response: CreateServiceCategoryResponse): void => {
          this.toastrService.success("", "Категория создана");
          this._processing = false;
          this.router.navigate(["..", response.id.toString()], { relativeTo: this.activatedRoute });
        },
        (response: HttpErrorResponse): void => {
          this._processing = false;
          this.handleErrorResponse(response)
        }
      );
  }

  private update(): void {
    this._processing = true;

    const value: { name: string } = this.form.getRawValue();

    this.serviceCategoriuesService.Update({
      id: this._id,
      request: {
        name: value.name
      }
    })
      .subscribe(
        (): void => {
          this.toastrService.success("", "Категория сохранена");
          this._processing = false;
          this._title = `Категория: ${this.form.get("name").value}`;
        },
        (response: HttpErrorResponse): void => {
          this._processing = false;
          this.handleErrorResponse(response)
        }
      );
  }

  private handleErrorResponse(response: HttpErrorResponse): void {

    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }

    if (response.status === 403) {
      this.toastrService.error("Для выполнения данного действия необходимо разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }
}
