import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClinicalRecommendation, EmployeeFavoriteManipulation, Manipulation, SectionsServicePrescription } from '../../../../generated/models';
import { Observable, Subject, from } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManipulationsService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-prescriptions-manipulations',
  templateUrl: './prescriptions-manipulations.component.html',
  styleUrls: ['./prescriptions-manipulations.component.scss']
})
export class PrescriptionsManipulationsComponent implements OnInit, OnDestroy {

  @Input() selected: SectionsServicePrescription[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];
  @Input() favourites: EmployeeFavoriteManipulation[] = [];

  @Output() onSelect = new EventEmitter<Manipulation>();
  @Output() onToggleStar = new EventEmitter<Manipulation>();

  destroy$ = new Subject<void>();

  form = new FormGroup({
    search: new FormControl('')
  });

  page = 1;
  size = 100;
  loading = false;
  items: Manipulation[] = [];

  constructor(private manipulationsService: ManipulationsService,
    private toastrService: ToastrService
  ) {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.page = 1;
          this.loading = true;
          this.items = [];
        }),
        switchMap((value: FiltersValue): Observable<Manipulation[]> => {
          return manipulationsService.ManipulationsAsync({ Page: this.page, Size: this.size, Search: value.search })
            .pipe(catchError((response: HttpErrorResponse) => {

              this.toastrService.error('Не удалось загрузить назначения', 'Ошибка');

              return from([]);
            }));
        })
      ).subscribe(
        (response: Manipulation[]) => {

          this.items = response;
          this.loading = false;
        }
      );

  }

  ngOnInit() {
    this.form.patchValue({});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScrollDown() {
    this.page++;

    const value: FiltersValue = this.form.getRawValue();

    this.manipulationsService.ManipulationsAsync({ Search: value.search, Page: this.page, Size: this.size })
      .subscribe(
        (response: Manipulation[]) => {
          this.items.push(...response);
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить назначения', 'Ошибка');
        }
      );

  }

  favourite = (item: Manipulation) => this.favourites.some(x => x.manipulationId === item.id);
  toggleFavourite = (item: Manipulation) => this.onToggleStar.emit(item);
  favouriteTitle = (item: Manipulation) => 'Добавить в избранное';

  isSelected = (item: Manipulation) => this.selected.some(x => x.name === item.name);
  select = (item: Manipulation) => this.onSelect.emit(item);

  recommendationsFor = (item: Manipulation, type: 0 | 1) => this.recommendations.filter(x => x.type === type && x.manipulationName === item.name);
}

interface FiltersValue {
  search: string;
}
