import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attending, Company, Speciality } from '../../../generated/models';
import { CompaniesService, EmployeesService, SpecialitiesService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class ReviewsResolver implements Resolve<ReviewsPayload> {

  constructor(private companiesService: CompaniesService,
    private employeesService: EmployeesService,
    private specialitiesService: SpecialitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReviewsPayload> {

    const companiesLoader: Observable<Company[]> = this.companiesService.MyCompanies();
    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 }).pipe(map(x => x.items));
    const doctorsLoader: Observable<Attending[]> = this.employeesService.Attendings({ Page: 1, Size: 0 }).pipe(map(x => x.items));

    return forkJoin({
      companies: companiesLoader,
      specialities: specialitiesLoader,
      doctors: doctorsLoader
    });
  }
}

export interface ReviewsPayload {
  companies: Company[];
  specialities: Speciality[];
  doctors: Attending[];
}
