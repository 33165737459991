import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ClinicalRecommendation, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-referral-section-item',
  templateUrl: './referral-section-item.component.html',
  styleUrls: ['./referral-section-item.component.scss'],
  host: { class: 'label label-outlined label-primary' }
})
export class ReferralSectionItemComponent implements OnInit {

  @Input() item: SectionsServicePrescription;
  @Input() recommendations: Recommendation[] = [];
  @Input() selected = false;

  constructor() { }

  ngOnInit() { }

  @HostBinding('title')
  get title() { return this.item ? this.item.name : ''; }

  @HostBinding("class.label-outlined-fill")
  get filled(): boolean { return this.selected; }

  get hasQuality(): boolean { return this.recommendations.some(x => x.type === 0); }
  get hasRecs(): boolean { return this.recommendations.some(x => x.type === 1); }

  get qualcons(): Recommendation[] { return this.recommendations.filter(x => x.type === 1); }
  get clinrecs(): Recommendation[] { return this.recommendations.filter(x => x.type === 0); }

}

export interface Recommendation {
  title: string;
  text: string;
  type: 0 | 1;
}
