/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Appointment } from '../models/appointment';
import { Attendance } from '../models/attendance';
@Injectable({
  providedIn: 'root',
})
class AppointmentsService extends __BaseService {
  static readonly FindAppointmentsAsyncPath = '/api/v1/Appointments';
  static readonly FullfilledAsyncPath = '/api/v1/Appointments/fullfilled';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AppointmentsService.FindAppointmentsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  FindAppointmentsAsyncResponse(params: AppointmentsService.FindAppointmentsAsyncParams): __Observable<__StrictHttpResponse<Array<Appointment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Appointments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Appointment>>;
      })
    );
  }
  /**
   * @param params The `AppointmentsService.FindAppointmentsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  FindAppointmentsAsync(params: AppointmentsService.FindAppointmentsAsyncParams): __Observable<Array<Appointment>> {
    return this.FindAppointmentsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Appointment>)
    );
  }

  /**
   * @param params The `AppointmentsService.FullfilledAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  FullfilledAsyncResponse(params: AppointmentsService.FullfilledAsyncParams): __Observable<__StrictHttpResponse<Array<Attendance>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    (params.SpecialityIds || []).forEach(val => {if (val != null) __params = __params.append('SpecialityIds', val.toString())});
    if (params.IncludeArrived != null) __params = __params.set('IncludeArrived', params.IncludeArrived.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Appointments/fullfilled`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Attendance>>;
      })
    );
  }
  /**
   * @param params The `AppointmentsService.FullfilledAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  FullfilledAsync(params: AppointmentsService.FullfilledAsyncParams): __Observable<Array<Attendance>> {
    return this.FullfilledAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Attendance>)
    );
  }
}

module AppointmentsService {

  /**
   * Parameters for FindAppointmentsAsync
   */
  export interface FindAppointmentsAsyncParams {
    To?: string;
    From?: string;
  }

  /**
   * Parameters for FullfilledAsync
   */
  export interface FullfilledAsyncParams {
    To?: string;
    SpecialityIds?: Array<number>;
    IncludeArrived?: boolean;
    From?: string;
    DoctorIds?: Array<number>;
    CompanyIds?: Array<number>;
  }
}

export { AppointmentsService }
