import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mp-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input() message = '';
  @Input() confirmBtnText = 'Удалить';

  @Output() onConfirm = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();

  confirm = () => this.onConfirm.emit();
  cancel = () => this.onCancel.emit();
}
