import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationModalComponent } from "../components/confirmation-modal/confirmation-modal.component";

@Injectable({ providedIn: 'root' })
export class ConfirmationModalService {
  opened = false;

  constructor(private modal: NgbModal) { }

  open(options: ConfirmationModalOptions): Promise<boolean> {
    if (this.opened) return;

    this.opened = true;

    const modalRef = this.modal.open(ConfirmationModalComponent, { backdrop: 'static', size: 'sm', centered: true });
    const componentRef: ConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = options.message;

    if (options.confirmBtnText) {
      componentRef.confirmBtnText = options.confirmBtnText;
    }

    componentRef.onCancel.subscribe(() => {
      this.opened = false;
      modalRef.dismiss();
    });

    componentRef.onConfirm.subscribe(() => {
      this.opened = false;
      modalRef.close();
    });

    return modalRef.result.then(() => true, () => false);
  }

}

export interface ConfirmationModalOptions {
  message: string,
  confirmBtnText?: string;
}
