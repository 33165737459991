/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PromotionsResponse } from '../models/promotions-response';
import { PromotionResponse } from '../models/promotion-response';
import { PromotionRequest } from '../models/promotion-request';
import { ManagePromotionModel } from '../models/manage-promotion-model';
@Injectable({
  providedIn: 'root',
})
class PromotionsService extends __BaseService {
  static readonly PromotionsPath = '/api/Promotions';
  static readonly CreatePath = '/api/Promotions';
  static readonly GetPath = '/api/Promotions/{id}';
  static readonly EditPath = '/api/Promotions/{promotionId}';
  static readonly RemovePath = '/api/Promotions/{promotionId}';
  static readonly ManagePath = '/api/Promotions/{id}/manage';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PromotionsService.PromotionsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  PromotionsResponse(params: PromotionsService.PromotionsParams): __Observable<__StrictHttpResponse<PromotionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Promotions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromotionsResponse>;
      })
    );
  }
  /**
   * @param params The `PromotionsService.PromotionsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  Promotions(params: PromotionsService.PromotionsParams): __Observable<PromotionsResponse> {
    return this.PromotionsResponse(params).pipe(
      __map(_r => _r.body as PromotionsResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: PromotionRequest): __Observable<__StrictHttpResponse<PromotionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Promotions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromotionResponse>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: PromotionRequest): __Observable<PromotionResponse> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as PromotionResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<PromotionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Promotions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromotionResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<PromotionResponse> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as PromotionResponse)
    );
  }

  /**
   * @param params The `PromotionsService.EditParams` containing the following parameters:
   *
   * - `promotionId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditResponse(params: PromotionsService.EditParams): __Observable<__StrictHttpResponse<PromotionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Promotions/${params.promotionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PromotionResponse>;
      })
    );
  }
  /**
   * @param params The `PromotionsService.EditParams` containing the following parameters:
   *
   * - `promotionId`:
   *
   * - `model`:
   *
   * @return Success
   */
  Edit(params: PromotionsService.EditParams): __Observable<PromotionResponse> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as PromotionResponse)
    );
  }

  /**
   * @param promotionId undefined
   */
  RemoveResponse(promotionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Promotions/${promotionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param promotionId undefined
   */
  Remove(promotionId: number): __Observable<null> {
    return this.RemoveResponse(promotionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManagePromotionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Promotions/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManagePromotionModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManagePromotionModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManagePromotionModel)
    );
  }
}

module PromotionsService {

  /**
   * Parameters for Promotions
   */
  export interface PromotionsParams {
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    promotionId: number;
    model?: PromotionRequest;
  }
}

export { PromotionsService }
