import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Company } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-company-icon',
  templateUrl: './company-icon.component.html',
  styleUrls: ['./company-icon.component.scss']
})
export class CompanyIconComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() company: Company;
  @Input() checked = false;

  @Output() onToggle = new EventEmitter<boolean>();

  checkbox = new FormControl(false);

  constructor() {

    this.checkbox.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => this.onToggle.emit(value));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['checked']) {
      this.checkbox.setValue(changes['checked'].currentValue, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
