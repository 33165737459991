/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CodeSystemValue } from '../models/code-system-value';
import { CodeSystemValueModel } from '../models/code-system-value-model';
@Injectable({
  providedIn: 'root',
})
class ValueSetsService extends __BaseService {
  static readonly ValuesPath = '/api/v1/ValueSets/{type}/{id}';
  static readonly CreatePath = '/api/v1/ValueSets/{type}';
  static readonly DeletePath = '/api/v1/ValueSets/{valueId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ValueSetsService.ValuesParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `id`:
   *
   * @return Success
   */
  ValuesResponse(params: ValueSetsService.ValuesParams): __Observable<__StrictHttpResponse<Array<CodeSystemValue>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ValueSets/${params.type}/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CodeSystemValue>>;
      })
    );
  }
  /**
   * @param params The `ValueSetsService.ValuesParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `id`:
   *
   * @return Success
   */
  Values(params: ValueSetsService.ValuesParams): __Observable<Array<CodeSystemValue>> {
    return this.ValuesResponse(params).pipe(
      __map(_r => _r.body as Array<CodeSystemValue>)
    );
  }

  /**
   * @param params The `ValueSetsService.CreateParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `request`:
   */
  CreateResponse(params: ValueSetsService.CreateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ValueSets/${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ValueSetsService.CreateParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `request`:
   */
  Create(params: ValueSetsService.CreateParams): __Observable<null> {
    return this.CreateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param valueId undefined
   */
  DeleteResponse(valueId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/ValueSets/${valueId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param valueId undefined
   */
  Delete(valueId: number): __Observable<null> {
    return this.DeleteResponse(valueId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ValueSetsService {

  /**
   * Parameters for Values
   */
  export interface ValuesParams {
    type: string;
    id: number;
  }

  /**
   * Parameters for Create
   */
  export interface CreateParams {
    type: string;
    request?: CodeSystemValueModel;
  }
}

export { ValueSetsService }
