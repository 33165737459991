import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MyTreatment, MyTreatmentAnalog, RecentTreatmentPrescription, TreatmentRecommendation } from '../../../../generated/models';
import { MyService, MyTreatmentsService, TreatmentRecommendationsService } from '../../../../generated/services';
import { TreatmentPrescriptionPayload } from '../../models/treatment-prescription-payload';
import { TreatmentsHelper } from '../../models/treatments-helper';

import * as moment from "moment";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-treatment-prescriptions-modal',
  templateUrl: './treatment-prescriptions-modal.component.html',
  styleUrls: ['./treatment-prescriptions-modal.component.scss']
})
export class TreatmentPrescriptionsModalComponent implements OnInit {
  @Input() prescriptions: TreatmentPrescriptionPayload[] = [];

  @Input() date: moment.Moment;
  @Input() dob: moment.Moment;

  @Input() gender = '';

  @Input() codes: string[] = [];

  @Output() onCancel = new EventEmitter<void>();

  @Output() onConfirm = new EventEmitter<TreatmentPrescriptionPayload[]>();

  title = "Лечение";
  currentTab = 1;
  collection: TreatmentPrescriptionPayload[] = [];

  recent: RecentTreatmentPrescription[] = [];

  recentLoading = false;
  dirty = false;

  recommendations: TreatmentRecommendation[] = [];
  recommendationsLoading = false;

  myTreatments: MyTreatment[] = [];
  myTreatmentsLoading = false;

  analogs: { [treatment: string]: string[] } = {};

  constructor(private myService: MyService,
    private confirmationService: ConfirmationModalService,
    private recommendationsService: TreatmentRecommendationsService,
    private myTreatmentsService: MyTreatmentsService,
    private toastrService: ToastrService) { }

  async ngOnInit() {
    this.collection = this.prescriptions.map(x => ({ ...x }));

    this.myTreatmentsService.AnalogsAsync()
      .subscribe(
        (response: MyTreatmentAnalog[]) => {
          for (const item of response) {
            if (item.treatment === item.analog) continue;

            if (!this.analogs[item.treatment]) {
              this.analogs[item.treatment] = [];
            }

            if (!this.analogs[item.treatment].includes(item.analog)) {
              this.analogs[item.treatment].push(item.analog);
            }

            if (!this.analogs[item.analog]) {
              this.analogs[item.analog] = [];
            }

            if (!this.analogs[item.analog].includes(item.treatment)) {
              this.analogs[item.analog].push(item.treatment);
            }
          }
        });


    this.recentLoading = true;

    this.myService.RecentTreatmentsAsync().subscribe(
      (recent: RecentTreatmentPrescription[]) => {
        this.recent = recent;
        this.recentLoading = false;
      },
      () => {
        this.recentLoading = false;
      }
    );

    if (this.codes && this.codes.length > 0) {
      this.recommendationsLoading = true;
      this.currentTab = 3;

      const recommendedTreatmentsRequest = {
        Dob: this.dob && this.dob.isValid() ? this.dob.format("DD.MM.YYYY") : "",
        Date: this.date && this.date.isValid() ? this.date.format("DD.MM.YYYY") : "",
        DiagnosisCode: this.codes,
        Gender: undefined
      };

      if (this.gender === 'f') {
        recommendedTreatmentsRequest.Gender = 1;
      }

      if (this.gender === 'm') {
        recommendedTreatmentsRequest.Gender = 2;
      }

      this.recommendationsService.TreatmentsAsync(recommendedTreatmentsRequest).subscribe(
        (response: TreatmentRecommendation[]): void => {
          this.recommendations = response;
          this.recommendationsLoading = false;

          if (this.recommendations.length === 0 && this.currentTab === 3) {
            this.currentTab = 1;
          }
        },
        (response: HttpErrorResponse): void => {
          this.recommendationsLoading = false;

          this.toastrService.error("Ошибка", "Не удалось загрузить рекомендованные лекарственные назначения")
        }
      );
    }

    this.myTreatmentsLoading = true;

    this.myTreatmentsService.MyTreatmentsAsync()
      .subscribe(
        (response: MyTreatment[]) => {
          this.myTreatments = response;
          this.myTreatmentsLoading = false;
        },
        () => {
          this.myTreatmentsLoading = false;
        }
      );
  }

  selectTab(tab: 1 | 2 | 3): void {
    this.currentTab = tab;
  }

  isCurrentTab(tab: 1 | 2 | 3): boolean {
    return this.currentTab === tab;
  }

  cancel(): void {
    this.onCancel.emit();
  }

  confirm(): void {
    this.dirty = true;

    if (this.collection.some(x => !x.treatmentName)) return;

    const result = this.collection.map((x, i) => ({ ...x, listOrder: i }));
    this.onConfirm.emit(result);
  }

  handleMyPrescriptionError(response: HttpErrorResponse, message: string) {
    if (response.status === 400) {

      for (const error of response.error.errors) {
        switch (error.status) {
          case 6: this.toastrService.warning('Для выполнения операции отсутствуют необходимые разрешения', 'Ошибка'); break;
          case 7: this.toastrService.warning('Необходимо указать название', 'Ошибка'); break;
          case 4: this.toastrService.warning('Сотрудник не найден', 'Ошибка'); break;
          case 8: this.toastrService.warning('Лечение не найдено', 'Ошибка'); break;
          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }

  async makeFav(item: TreatmentPrescriptionPayload) {

    try {
      await this.myTreatmentsService.CreateMyTreatmentAsync({ treatmentName: item.treatmentName, dosage: item.dosage, duration: item.duration, schema: item.schema }).toPromise();

      this.toastrService.success(`Лечение ${item.treatmentName} добавлено в избранное`, 'Успешно');

      this.myTreatments = await this.myTreatmentsService.MyTreatmentsAsync().toPromise();

    } catch (e) {
      this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось добавить лечение в избранное');
    }
  }

  async makeRecommendedFav(recommendation: TreatmentRecommendation) {
    const schema = TreatmentsHelper.getSchema(recommendation.quantity, recommendation.quantityUnit,
      recommendation.repeats, recommendation.repeatsUnitName,
      recommendation.method, recommendation.time, recommendation.mealRelation, recommendation.postpone);

    const dosage = TreatmentsHelper.getDosage(recommendation.dosage, recommendation.dosageUnit);

    let duration = TreatmentsHelper.getDuration(recommendation.duration, recommendation.durationUnitName)

    if (duration.toLowerCase() === 'индивидуально') duration = '';


    try {
      await this.myTreatmentsService.CreateMyTreatmentAsync({ treatmentName: recommendation.treatmentName, dosage, duration, schema }).toPromise();

      this.toastrService.success(`Лечение ${recommendation.treatmentName} добавлено в избранное`, 'Успешно');

      this.myTreatments = await this.myTreatmentsService.MyTreatmentsAsync().toPromise();

    } catch (e) {
      this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось добавить лечение в избранное');
    }
  }

  addFav(item: MyTreatment): void {
    const payload: TreatmentPrescriptionPayload = {
      id: - Math.ceil(Math.random() * 1000000),
      treatmentName: item.treatmentName,
      dosage: item.dosage,
      duration: item.duration,
      schema: item.schema,
      analogs: []
    };

    this.collection.push(payload);
  }

  createFav() {
    this.myTreatments = [{ id: - Math.ceil(Math.random() * 1000000) }, ...this.myTreatments];
  }

  async copyFav(item: MyTreatment) {
    console.info(item);

    try {
      await this.myTreatmentsService.CreateMyTreatmentAsync({ treatmentName: item.treatmentName, dosage: item.dosage, duration: item.duration, schema: item.schema }).toPromise();

      this.toastrService.success(`Копия лечения ${item.treatmentName} добавлена`, 'Успешно');

      this.myTreatments = await this.myTreatmentsService.MyTreatmentsAsync().toPromise();

    } catch (e) {
      this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось скопировать лечение');
    }
  }

  async saveFav(item: MyTreatment) {
    if (item.id > 0) {
      await this.updateFav(item);
    } else {
      await this.insertFav(item);
    }

  }

  async insertFav(item: MyTreatment) {
    const index = this.myTreatments.findIndex(x => x.id === item.id);

    try {
      const response = await this.myTreatmentsService.CreateMyTreatmentAsync({ treatmentName: item.treatmentName, dosage: item.dosage, duration: item.duration, schema: item.schema }).toPromise();

      this.toastrService.success(`Лечение ${item.treatmentName} сохранено`, 'Успешно');

      if (index !== -1) {
        this.myTreatments.splice(index, 1, { ...item, id: response.id });

        this.myTreatments = [...this.myTreatments];
      }
    }
    catch (e) {
      this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось обновить лечение');

      if (index !== -1) {
        this.myTreatments.splice(index, 1, { ...item });
      }
    }
  }

  async updateFav(item: MyTreatment) {
    try {
      await this.myTreatmentsService.UpdateMyTreatmentAsync({
        id: item.id,
        request: {
          treatmentName: item.treatmentName,
          dosage: item.dosage,
          schema: item.schema,
          duration: item.duration
        }
      }).toPromise();

      this.toastrService.success(`Лечение ${item.treatmentName} сохранено`, 'Успешно');
      this.myTreatments = await this.myTreatmentsService.MyTreatmentsAsync().toPromise();
    }
    catch (e) {
      this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось обновить лечение');
      const index = this.myTreatments.findIndex(x => x.id === item.id);

      if (index !== -1) {
        this.myTreatments.splice(index, 1, { ...this.myTreatments[index] });
      }
    }
  }

  async removeFav(item: MyTreatment) {
    if (item.id < 0) {
      this.myTreatments = this.myTreatments.filter(x => x.id !== item.id);
      return;
    }

    const confirmed = await this.confirmationService.open({ message: `Лечение ${item.treatmentName} будет удалено из избранного. Продолжить?`, confirmBtnText: 'Удалить' });

    if (confirmed) {
      try {
        await this.myTreatmentsService.RemoveMyTreatmentAsync(item.id).toPromise();
        this.toastrService.success(`Лечение ${item.treatmentName} удалено`, 'Успешно');
        this.myTreatments = await this.myTreatmentsService.MyTreatmentsAsync().toPromise();
      }
      catch (e) {
        this.handleMyPrescriptionError(e as HttpErrorResponse, 'Не удалось удалить лечение');
      }

    }
  }

  addPrescription(): void {
    this.collection.push({ id: -Math.ceil(Math.random() * 1000000), treatmentName: '', analogs: [] });
  }

  async updatePrescription(source: TreatmentPrescriptionPayload, payload: TreatmentPrescriptionPayload) {
    source.treatmentName = payload.treatmentName;
    source.dosage = payload.dosage;
    source.schema = payload.schema;
    source.duration = payload.duration;

    const response = await this.myTreatmentsService.FindAnalogsAsync(source.treatmentName).toPromise();
    source.analogs = response.map(x => x.analog);
  }

  removePrescription(source: TreatmentPrescriptionPayload): void {
    const index: number = this.collection.findIndex(x => x.id === source.id);

    if (index !== -1) {
      this.collection.splice(index, 1);
    }
  }

  async addRecentPrescription(item: RecentTreatmentPrescription): Promise<void> {
    const payload: TreatmentPrescriptionPayload = { ...item, id: -Math.ceil(Math.random() * 1000000), treatmentName: item.treatmentName, analogs: [] };

    this.collection.unshift(payload);

    // load analogs
    const response = await this.myTreatmentsService.FindAnalogsAsync(payload.treatmentName).toPromise();
    payload.analogs = response.map(x => x.analog);
  }

  async addRecommended(recommendation: TreatmentRecommendation): Promise<void> {

    const schema = TreatmentsHelper.getSchema(recommendation.quantity, recommendation.quantityUnit,
      recommendation.repeats, recommendation.repeatsUnitName,
      recommendation.method, recommendation.time, recommendation.mealRelation, recommendation.postpone);

    let duration = TreatmentsHelper.getDuration(recommendation.duration, recommendation.durationUnitName)

    if (duration.toLowerCase() === 'индивидуально') duration = '';

    const item: TreatmentPrescriptionPayload = {
      id: - Math.ceil(Math.random() * 1000000),
      treatmentName: recommendation.treatmentName,
      dosage: TreatmentsHelper.getDosage(recommendation.dosage, recommendation.dosageUnit),
      duration: duration,
      schema: schema,
      analogs: []
    };

    this.collection.push(item);
  }

  analogsFor(item: { treatmentName: string }): string[] {
    if (this.analogs[item.treatmentName]) return this.analogs[item.treatmentName] || [];

    return [];
  }

  async addAnalog(treatment: string, analog: string): Promise<void> {
    if (treatment && !this.analogs[treatment]) {
      this.analogs[treatment] = [];
    }

    if (analog && !this.analogs[analog]) {
      this.analogs[analog] = [];
    }

    if (!treatment || !analog) return;

    await this.myTreatmentsService.CreateTreatmentAnalogAsync({ treatment: treatment, analog: analog }).toPromise();

    if (!this.analogs[treatment].includes(analog)) {
      this.analogs[treatment].push(analog);
    }

    if (!this.analogs[analog].includes(treatment)) {
      this.analogs[analog].push(treatment);
    }
  }

  async removeAnalog(treatment: string, analog: string): Promise<void> {
    await this.myTreatmentsService.RemoveTreatmentAnalogAsync({ Treatment: treatment, Analog: analog }).toPromise();

    if (this.analogs[treatment] && this.analogs[treatment].includes(analog)) {
      this.analogs[treatment].splice(this.analogs[treatment].findIndex(x => x === analog), 1)
    }

    if (this.analogs[analog] && this.analogs[analog].includes(treatment)) {
      this.analogs[analog].splice(this.analogs[analog].findIndex(x => x === treatment), 1)
    }
  }

  async selectAnalog(source: TreatmentPrescriptionPayload, analog: string): Promise<void> {
    const index = this.collection.findIndex(x => x.id === source.id);

    if (index === -1) return;

    this.collection[index] = { ...source, treatmentName: analog, analogs: [] };
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.collection, event.previousIndex, event.currentIndex);
  }
}
