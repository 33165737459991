/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Record } from '../models/record';
import { CreateRecordRequest } from '../models/create-record-request';
import { SaveRecordRequest } from '../models/save-record-request';
import { RecordSection } from '../models/record-section';
import { SavedTemplateGroup } from '../models/saved-template-group';
import { SignManyResponse } from '../models/sign-many-response';
import { SignManyRequest } from '../models/sign-many-request';
import { UnsignManyResponse } from '../models/unsign-many-response';
import { AllowedDiagnosesResponse } from '../models/allowed-diagnoses-response';
import { RecordNeighborsResponse } from '../models/record-neighbors-response';
import { ChangeRecordTemplateRequest } from '../models/change-record-template-request';
@Injectable({
  providedIn: 'root',
})
class RecordsService extends __BaseService {
  static readonly CreatePath = '/api/clinic/v1/records';
  static readonly RemoveManyPath = '/api/clinic/v1/records';
  static readonly RecordPath = '/api/clinic/v1/records/{id}';
  static readonly SaveRecordPath = '/api/clinic/v1/records/{id}';
  static readonly SectionsPath = '/api/clinic/v1/records/{id}/sections';
  static readonly ParametersPath = '/api/clinic/v1/records/{id}/parameters';
  static readonly SaveRecordDraftPath = '/api/clinic/v1/records/{id}/draft';
  static readonly SignManyPath = '/api/clinic/v1/records/sign';
  static readonly UnsignManyPath = '/api/clinic/v1/records/unsign';
  static readonly PreviewRecordPath = '/api/v2/records/preview';
  static readonly PreviewRecord_1Path = '/records/preview';
  static readonly RestoreSectionPath = '/api/clinic/v1/records/{recordId}/sections/{sectionId}/restore';
  static readonly RestoreAllPath = '/api/clinic/v1/records/{id}/sections/restore';
  static readonly DiagnosesForSectionPath = '/api/clinic/v1/records/sections/{id}/diagnoses';
  static readonly SealEditionAsyncPath = '/api/clinic/v1/records/editions/{id}/seal';
  static readonly SignedRecordArchivePath = '/api/clinic/v1/records/{id}/signed';
  static readonly RecordNeighboursAsyncPath = '/api/clinic/v1/records/{id}/neighbours';
  static readonly ChangeRecordTemplateAsyncPath = '/api/clinic/v1/records/{id}/template';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: CreateRecordRequest): __Observable<__StrictHttpResponse<Record>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Record>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: CreateRecordRequest): __Observable<Record> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as Record)
    );
  }

  /**
   * @param RecordId undefined
   */
  RemoveManyResponse(RecordId?: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (RecordId || []).forEach(val => {if (val != null) __params = __params.append('RecordId', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/clinic/v1/records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param RecordId undefined
   */
  RemoveMany(RecordId?: Array<number>): __Observable<null> {
    return this.RemoveManyResponse(RecordId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RecordResponse(id: number): __Observable<__StrictHttpResponse<Record>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Record>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Record(id: number): __Observable<Record> {
    return this.RecordResponse(id).pipe(
      __map(_r => _r.body as Record)
    );
  }

  /**
   * @param params The `RecordsService.SaveRecordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SaveRecordResponse(params: RecordsService.SaveRecordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/clinic/v1/records/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecordsService.SaveRecordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SaveRecord(params: RecordsService.SaveRecordParams): __Observable<null> {
    return this.SaveRecordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  SectionsResponse(id: number): __Observable<__StrictHttpResponse<Array<RecordSection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/${id}/sections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecordSection>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Sections(id: number): __Observable<Array<RecordSection>> {
    return this.SectionsResponse(id).pipe(
      __map(_r => _r.body as Array<RecordSection>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ParametersResponse(id: number): __Observable<__StrictHttpResponse<Array<SavedTemplateGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/${id}/parameters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SavedTemplateGroup>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Parameters(id: number): __Observable<Array<SavedTemplateGroup>> {
    return this.ParametersResponse(id).pipe(
      __map(_r => _r.body as Array<SavedTemplateGroup>)
    );
  }

  /**
   * @param params The `RecordsService.SaveRecordDraftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SaveRecordDraftResponse(params: RecordsService.SaveRecordDraftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/clinic/v1/records/${params.id}/draft`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecordsService.SaveRecordDraftParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SaveRecordDraft(params: RecordsService.SaveRecordDraftParams): __Observable<null> {
    return this.SaveRecordDraftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  SignManyResponse(request?: SignManyRequest): __Observable<__StrictHttpResponse<SignManyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/sign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SignManyResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  SignMany(request?: SignManyRequest): __Observable<SignManyResponse> {
    return this.SignManyResponse(request).pipe(
      __map(_r => _r.body as SignManyResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  UnsignManyResponse(request?: SignManyRequest): __Observable<__StrictHttpResponse<UnsignManyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/unsign`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UnsignManyResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  UnsignMany(request?: SignManyRequest): __Observable<UnsignManyResponse> {
    return this.UnsignManyResponse(request).pipe(
      __map(_r => _r.body as UnsignManyResponse)
    );
  }

  /**
   * @param params The `RecordsService.PreviewRecordParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   *
   * @return Success
   */
  PreviewRecordResponse(params: RecordsService.PreviewRecordParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    if (params.Expires != null) __params = __params.set('Expires', params.Expires.toString());
    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/records/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `RecordsService.PreviewRecordParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   *
   * @return Success
   */
  PreviewRecord(params: RecordsService.PreviewRecordParams): __Observable<Blob> {
    return this.PreviewRecordResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `RecordsService.PreviewRecord_1Params` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   *
   * @return Success
   */
  PreviewRecord_1Response(params: RecordsService.PreviewRecord_1Params): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    if (params.Expires != null) __params = __params.set('Expires', params.Expires.toString());
    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/records/preview`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `RecordsService.PreviewRecord_1Params` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   *
   * @return Success
   */
  PreviewRecord_1(params: RecordsService.PreviewRecord_1Params): __Observable<Blob> {
    return this.PreviewRecord_1Response(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `RecordsService.RestoreSectionParams` containing the following parameters:
   *
   * - `sectionId`:
   *
   * - `recordId`:
   *
   * @return Success
   */
  RestoreSectionResponse(params: RecordsService.RestoreSectionParams): __Observable<__StrictHttpResponse<RecordSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/${params.recordId}/sections/${params.sectionId}/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordSection>;
      })
    );
  }
  /**
   * @param params The `RecordsService.RestoreSectionParams` containing the following parameters:
   *
   * - `sectionId`:
   *
   * - `recordId`:
   *
   * @return Success
   */
  RestoreSection(params: RecordsService.RestoreSectionParams): __Observable<RecordSection> {
    return this.RestoreSectionResponse(params).pipe(
      __map(_r => _r.body as RecordSection)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RestoreAllResponse(id: number): __Observable<__StrictHttpResponse<Array<RecordSection>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/${id}/sections/restore`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecordSection>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  RestoreAll(id: number): __Observable<Array<RecordSection>> {
    return this.RestoreAllResponse(id).pipe(
      __map(_r => _r.body as Array<RecordSection>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DiagnosesForSectionResponse(id: number): __Observable<__StrictHttpResponse<AllowedDiagnosesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/sections/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AllowedDiagnosesResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DiagnosesForSection(id: number): __Observable<AllowedDiagnosesResponse> {
    return this.DiagnosesForSectionResponse(id).pipe(
      __map(_r => _r.body as AllowedDiagnosesResponse)
    );
  }

  /**
   * @param params The `RecordsService.SealEditionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `SignatureValidTill`:
   *
   * - `SignatureValidFrom`:
   *
   * - `SignatureName`:
   *
   * - `SignatureId`:
   *
   * - `Signature`:
   *
   * - `Document`:
   */
  SealEditionAsyncResponse(params: RecordsService.SealEditionAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.SignatureValidTill != null) { __formData.append('SignatureValidTill', params.SignatureValidTill as string | Blob);}
    if (params.SignatureValidFrom != null) { __formData.append('SignatureValidFrom', params.SignatureValidFrom as string | Blob);}
    if (params.SignatureName != null) { __formData.append('SignatureName', params.SignatureName as string | Blob);}
    if (params.SignatureId != null) { __formData.append('SignatureId', params.SignatureId as string | Blob);}
    if (params.Signature != null) { __formData.append('Signature', params.Signature as string | Blob);}
    if (params.Document != null) { __formData.append('Document', params.Document as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/editions/${params.id}/seal`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecordsService.SealEditionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `SignatureValidTill`:
   *
   * - `SignatureValidFrom`:
   *
   * - `SignatureName`:
   *
   * - `SignatureId`:
   *
   * - `Signature`:
   *
   * - `Document`:
   */
  SealEditionAsync(params: RecordsService.SealEditionAsyncParams): __Observable<null> {
    return this.SealEditionAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RecordsService.SignedRecordArchiveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Token`:
   *
   * @return Success
   */
  SignedRecordArchiveResponse(params: RecordsService.SignedRecordArchiveParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/${params.id}/signed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `RecordsService.SignedRecordArchiveParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Token`:
   *
   * @return Success
   */
  SignedRecordArchive(params: RecordsService.SignedRecordArchiveParams): __Observable<Blob> {
    return this.SignedRecordArchiveResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  RecordNeighboursAsyncResponse(id: number): __Observable<__StrictHttpResponse<RecordNeighborsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/clinic/v1/records/${id}/neighbours`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordNeighborsResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  RecordNeighboursAsync(id: number): __Observable<RecordNeighborsResponse> {
    return this.RecordNeighboursAsyncResponse(id).pipe(
      __map(_r => _r.body as RecordNeighborsResponse)
    );
  }

  /**
   * @param params The `RecordsService.ChangeRecordTemplateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeRecordTemplateAsyncResponse(params: RecordsService.ChangeRecordTemplateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/clinic/v1/records/${params.id}/template`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `RecordsService.ChangeRecordTemplateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeRecordTemplateAsync(params: RecordsService.ChangeRecordTemplateAsyncParams): __Observable<null> {
    return this.ChangeRecordTemplateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RecordsService {

  /**
   * Parameters for SaveRecord
   */
  export interface SaveRecordParams {
    id: number;
    request?: SaveRecordRequest;
  }

  /**
   * Parameters for SaveRecordDraft
   */
  export interface SaveRecordDraftParams {
    id: number;
    request?: SaveRecordRequest;
  }

  /**
   * Parameters for PreviewRecord
   */
  export interface PreviewRecordParams {
    Type?: string;
    Token?: string;
    Expires?: number;
    Download?: boolean;
  }

  /**
   * Parameters for PreviewRecord_1
   */
  export interface PreviewRecord_1Params {
    Type?: string;
    Token?: string;
    Expires?: number;
    Download?: boolean;
  }

  /**
   * Parameters for RestoreSection
   */
  export interface RestoreSectionParams {
    sectionId: number;
    recordId: number;
  }

  /**
   * Parameters for SealEditionAsync
   */
  export interface SealEditionAsyncParams {
    id: number;
    SignatureValidTill?: string;
    SignatureValidFrom?: string;
    SignatureName?: string;
    SignatureId?: string;
    Signature?: Blob;
    Document?: Blob;
  }

  /**
   * Parameters for SignedRecordArchive
   */
  export interface SignedRecordArchiveParams {
    id: string;
    Token?: string;
  }

  /**
   * Parameters for ChangeRecordTemplateAsync
   */
  export interface ChangeRecordTemplateAsyncParams {
    id: number;
    request?: ChangeRecordTemplateRequest;
  }
}

export { RecordsService }
