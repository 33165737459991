/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DiscountsResponse } from '../models/discounts-response';
import { DiscountResponse } from '../models/discount-response';
import { DiscountRequest } from '../models/discount-request';
import { AvailableDiscount } from '../models/available-discount';
import { ManageDiscountModel } from '../models/manage-discount-model';
import { DiscountCompany } from '../models/discount-company';
import { EnableDiscountInCompanyRequest } from '../models/enable-discount-in-company-request';
import { DisableDiscountInCompanyRequest } from '../models/disable-discount-in-company-request';
@Injectable({
  providedIn: 'root',
})
class DiscountsService extends __BaseService {
  static readonly DiscountsPath = '/api/Discounts';
  static readonly CreatePath = '/api/Discounts';
  static readonly CommonDiscountsPath = '/api/Discounts/common';
  static readonly GetPath = '/api/Discounts/{id}';
  static readonly EditPath = '/api/Discounts/{discountId}';
  static readonly RemovePath = '/api/Discounts/{discountId}';
  static readonly ManagePath = '/api/Discounts/{id}/manage';
  static readonly DiscountCompaniesAsyncPath = '/api/Discounts/{discountId}/companies';
  static readonly EnableDiscountInCompanyAsyncPath = '/api/Discounts/{discountId}/companies/enable';
  static readonly DisableDiscountInCompanyAsyncPath = '/api/Discounts/{discountId}/companies/disable';
  static readonly EnableDiscountInAllCompaniesAsyncPath = '/api/Discounts/{discountId}/companies/all/enable';
  static readonly DisableDiscountInAllCompaniesAsyncPath = '/api/Discounts/{discountId}/companies/all/disable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DiscountsService.DiscountsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  DiscountsResponse(params: DiscountsService.DiscountsParams): __Observable<__StrictHttpResponse<DiscountsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Discounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiscountsResponse>;
      })
    );
  }
  /**
   * @param params The `DiscountsService.DiscountsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  Discounts(params: DiscountsService.DiscountsParams): __Observable<DiscountsResponse> {
    return this.DiscountsResponse(params).pipe(
      __map(_r => _r.body as DiscountsResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: DiscountRequest): __Observable<__StrictHttpResponse<DiscountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Discounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiscountResponse>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: DiscountRequest): __Observable<DiscountResponse> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as DiscountResponse)
    );
  }

  /**
   * @param params The `DiscountsService.CommonDiscountsParams` containing the following parameters:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  CommonDiscountsResponse(params: DiscountsService.CommonDiscountsParams): __Observable<__StrictHttpResponse<Array<AvailableDiscount>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Date != null) __params = __params.set('Date', params.Date.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Discounts/common`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableDiscount>>;
      })
    );
  }
  /**
   * @param params The `DiscountsService.CommonDiscountsParams` containing the following parameters:
   *
   * - `Date`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  CommonDiscounts(params: DiscountsService.CommonDiscountsParams): __Observable<Array<AvailableDiscount>> {
    return this.CommonDiscountsResponse(params).pipe(
      __map(_r => _r.body as Array<AvailableDiscount>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<DiscountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Discounts/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiscountResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<DiscountResponse> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as DiscountResponse)
    );
  }

  /**
   * @param params The `DiscountsService.EditParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditResponse(params: DiscountsService.EditParams): __Observable<__StrictHttpResponse<DiscountResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Discounts/${params.discountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiscountResponse>;
      })
    );
  }
  /**
   * @param params The `DiscountsService.EditParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `model`:
   *
   * @return Success
   */
  Edit(params: DiscountsService.EditParams): __Observable<DiscountResponse> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as DiscountResponse)
    );
  }

  /**
   * @param discountId undefined
   */
  RemoveResponse(discountId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Discounts/${discountId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param discountId undefined
   */
  Remove(discountId: number): __Observable<null> {
    return this.RemoveResponse(discountId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManageDiscountModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Discounts/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManageDiscountModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManageDiscountModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManageDiscountModel)
    );
  }

  /**
   * @param discountId undefined
   * @return Success
   */
  DiscountCompaniesAsyncResponse(discountId: number): __Observable<__StrictHttpResponse<Array<DiscountCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Discounts/${discountId}/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiscountCompany>>;
      })
    );
  }
  /**
   * @param discountId undefined
   * @return Success
   */
  DiscountCompaniesAsync(discountId: number): __Observable<Array<DiscountCompany>> {
    return this.DiscountCompaniesAsyncResponse(discountId).pipe(
      __map(_r => _r.body as Array<DiscountCompany>)
    );
  }

  /**
   * @param params The `DiscountsService.EnableDiscountInCompanyAsyncParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `request`:
   */
  EnableDiscountInCompanyAsyncResponse(params: DiscountsService.EnableDiscountInCompanyAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Discounts/${params.discountId}/companies/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DiscountsService.EnableDiscountInCompanyAsyncParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `request`:
   */
  EnableDiscountInCompanyAsync(params: DiscountsService.EnableDiscountInCompanyAsyncParams): __Observable<null> {
    return this.EnableDiscountInCompanyAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `DiscountsService.DisableDiscountInCompanyAsyncParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `request`:
   */
  DisableDiscountInCompanyAsyncResponse(params: DiscountsService.DisableDiscountInCompanyAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Discounts/${params.discountId}/companies/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DiscountsService.DisableDiscountInCompanyAsyncParams` containing the following parameters:
   *
   * - `discountId`:
   *
   * - `request`:
   */
  DisableDiscountInCompanyAsync(params: DiscountsService.DisableDiscountInCompanyAsyncParams): __Observable<null> {
    return this.DisableDiscountInCompanyAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param discountId undefined
   */
  EnableDiscountInAllCompaniesAsyncResponse(discountId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Discounts/${discountId}/companies/all/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param discountId undefined
   */
  EnableDiscountInAllCompaniesAsync(discountId: number): __Observable<null> {
    return this.EnableDiscountInAllCompaniesAsyncResponse(discountId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param discountId undefined
   */
  DisableDiscountInAllCompaniesAsyncResponse(discountId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Discounts/${discountId}/companies/all/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param discountId undefined
   */
  DisableDiscountInAllCompaniesAsync(discountId: number): __Observable<null> {
    return this.DisableDiscountInAllCompaniesAsyncResponse(discountId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DiscountsService {

  /**
   * Parameters for Discounts
   */
  export interface DiscountsParams {
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for CommonDiscounts
   */
  export interface CommonDiscountsParams {
    Date?: string;
    CompanyId?: number;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    discountId: number;
    model?: DiscountRequest;
  }

  /**
   * Parameters for EnableDiscountInCompanyAsync
   */
  export interface EnableDiscountInCompanyAsyncParams {
    discountId: number;
    request?: EnableDiscountInCompanyRequest;
  }

  /**
   * Parameters for DisableDiscountInCompanyAsync
   */
  export interface DisableDiscountInCompanyAsyncParams {
    discountId: number;
    request?: DisableDiscountInCompanyRequest;
  }
}

export { DiscountsService }
