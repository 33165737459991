import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { DiseaseOutcome, TemplateSection } from '../../../../generated/models';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'mp-template-section',
  templateUrl: './template-section.component.html',
  styleUrls: ['./template-section.component.scss']
})
export class TemplateSectionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() section: TemplateSection;
  @Input() canChangeStructure = false;
  @Input() canChangeContent = false;

  @Input() outcomes: DiseaseOutcome[] = [];

  @Output() onChange = new EventEmitter<TemplateSection>();
  @Output() onManageMetrics = new EventEmitter<void>();

  disabled = false;

  form = new FormGroup({
    name: new FormControl(""),
    required: new FormControl(false),
    includeInExtract: new FormControl(false),
    show: new FormControl(false),
    hideTitle: new FormControl(false),
    formatting: new FormControl(false),
    readonly: new FormControl(false)
  });

  constructor() {
    this.form.get('required').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: boolean) => {
          if (value) {
            this.form.get('show').setValue(true);
            this.form.get('show').enabled ? this.form.get('show').disable() : undefined;
          } else {
            this.form.get('show').disabled ? this.form.get('show').enable() : undefined;
          }
        }
      );
  }

  ngOnInit() {
    this.form.patchValue({ ...this.section });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$),)
      .subscribe(() => {
        const value: any = this.form.getRawValue();

        const changed: TemplateSection = { ...this.section };

        changed.name = value.name;
        changed.required = value.required;
        changed.show = value.show;
        changed.formatting = value.formatting;
        changed.hideTitle = value.hideTitle;
        changed.includeInExtract = value.includeInExtract;
        changed.readonly = value.readonly;

        this.onChange.emit(changed);
      }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['canChangeStructure']) {
      const value: boolean = changes['canChangeStructure'].currentValue;

      this.disabled = !value;

      if (!value) {
        this.form.enabled ? this.form.disable() : undefined;
      } else {
        this.form.disabled ? this.form.enable() : undefined;

        if (this.section.required) {
          const showControl: AbstractControl = this.form.get("show");

          showControl.setValue(true);
          showControl.enabled ? showControl.disable() : undefined;
        }
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onAppointmentDateSectionChanged = (period: number) => this.onChange.emit({ ...this.section, appointmentPeriod: period });
  onTextSectionChanged = (value: string) => this.onChange.emit({ ...this.section, text: value });
  onDiagnosisTypeChanged = (type: 0 | 1 | 2 | 3) => this.onChange.emit({ ...this.section, diagnosisType: type });
  onDiagnosisCodesChanged = (value: string) => this.onChange.emit({ ...this.section, allowedDiagnoses: value });

  changeDiagnosis = (value: { name: string, code: string }) => this.onChange.emit({
    ...this.section,
    defaultDiagnosisCode: value.code,
    defaultDiagnosisName: value.name
  })

  manageMetrics = () => this.onManageMetrics.emit();

  changeOutcomeSection = (value: TemplateSection) => this.onChange.emit({
    ...this.section,
    nextAppointmentInDays: value.nextAppointmentInDays,
    defaultOutcomeDescription: value.defaultOutcomeDescription,
    defaultOutcomeStatus: value.defaultOutcomeStatus
  });

  get displayType(): string {
    switch (this.section.type) {
      case 1: return "Секция с текстовым полем";
      case 2: return "Секция с выбором диагноза";
      case 3: return "Секция с назначениями";
      case 4: return "Дата следующего посещения";
      case 5: return "Завершение случая";
      case 6: return "Секция лечения";
      case 7: return 'Завершение случая и дата следующего посещения';
      case 8: return 'Секция показателей';
      default: return "";
    }
  }
}
