import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Document } from 'projects/Clinic/src/app/generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-anamnesis-list',
  templateUrl: './anamnesis-list.component.html',
  styleUrls: ['./anamnesis-list.component.scss']
})
export class AnamnesisListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @ViewChild("fileUpload") fileUpload: ElementRef<HTMLInputElement>;

  @Input() documents: Document[] = [];
  @Input() canPrint = false;
  @Input() loading = false;

  @Output()addFile = new EventEmitter<File>();

  @Output()onRemove = new EventEmitter<Document>()
  @Output()onPrint = new EventEmitter<Document>()
  @Output()onDownload = new EventEmitter<Document>();
  @Output()onOpen= new EventEmitter<Document>();

  uploader = new FormControl();

  constructor() {
    this.uploader.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        const files: FileList = this.fileUpload.nativeElement.files;

        for (let i = 0; i < files.length; i++) {
          this.addFile.emit(files[i]);
        }
      });
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  openFileDialog = () => this.fileUpload.nativeElement.click();

  print = (document: Document) => this.onPrint.emit(document);
  download = (document: Document) => this.onDownload.emit(document);
  remove = (document: Document) => this.onRemove.emit(document);
  open= (document: Document) => this.onOpen.emit(document);
}
