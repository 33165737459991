import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreatmentPrescriptionPayload } from '../../models/treatment-prescription-payload';
import { TreatmentAnalogModalComponent } from '../treatment-analog-modal/treatment-analog-modal.component';

@Component({
  selector: 'mp-selected-treatment-prescription',
  templateUrl: './selected-treatment-prescription.component.html',
  styleUrls: ['./selected-treatment-prescription.component.scss']
})
export class SelectedTreatmentPrescriptionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();
  analogs$ = new Subject<string[]>();

  @Input() set dirty(value: boolean) {
    if (value) {
      Object.entries(this.form.controls).forEach(x => {
        x[1].markAsTouched();
        x[1].markAsDirty;
      });
    }
  }
  @Input() item: TreatmentPrescriptionPayload;

  @Input() analogs: string[] = [];

  @Output() onChange = new EventEmitter<TreatmentPrescriptionPayload>();
  @Output() onAnalogAdded = new EventEmitter<string>();
  @Output() onAnalogRemoved = new EventEmitter<string>();
  @Output() onAnalogSelected = new EventEmitter<string>();
  @Output() onRemove = new EventEmitter<void>();
  @Output() onFav = new EventEmitter<void>();

  @HostBinding('class.invalid') get invalid(): boolean { return this.form.invalid && this.form.touched; }

  form: FormGroup = new FormGroup({
    treatmentName: new FormControl('', [Validators.required]),
    dosage: new FormControl('', []),
    schema: new FormControl('', []),
    duration: new FormControl('', [])
  });

  editing = false;
  local: TreatmentPrescriptionPayload;

  constructor(private ngbModal: NgbModal) {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        this.local = { ...this.local, ...this.form.getRawValue() };
      });
  }

  ngOnInit() {
    this.editing = this.item.id <= 0 && !this.item.treatmentName;
    this.local = { ...this.item };

    this.form.patchValue(this.local, { emitEvent: false });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['analogs']) {
      this.analogs$.next(changes['analogs'].currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  edit = () => this.editing = true;
  remove = () => this.onRemove.emit();
  fav = () => this.onFav.emit();

  cancel(): void {
    this.local = { ...this.item };
    if (this.local.id <= 0 && !this.local.treatmentName) {
      this.onRemove.emit();
      return;
    }
    this.form.patchValue(this.local, { emitEvent: false });
    this.editing = false;
  }

  confirm(): void {
    Object.entries(this.form.controls).forEach(x => x[1].markAsTouched());

    if (this.form.invalid) {
      return;
    }

    this.onChange.emit(this.local);
    this.editing = false;
  }

  get formated(): string {
    if (!this.local) return '';

    return [this.local.treatmentName, this.local.dosage, this.local.schema, this.local.duration].filter(x => x).join(', ');
  }

  selectAnalog = (analog: string) => this.onAnalogSelected.emit(analog);

  addTreatmentAnalog(): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true };

    const modalRef = this.ngbModal.open(TreatmentAnalogModalComponent, options);
    const componentRef: TreatmentAnalogModalComponent = modalRef.componentInstance;

    componentRef.treatmentName = this.item.treatmentName;
    componentRef.analogs = this.analogs;

    this.analogs$.subscribe(analogs => componentRef.analogs = analogs);

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onAdd.subscribe((value: string) => this.onAnalogAdded.emit(value));
    componentRef.onRemove.subscribe((value: string) => this.onAnalogRemoved.emit(value));
  }
}
