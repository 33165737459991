import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ReportEmployee } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-list-item',
  templateUrl: './employee-list-item.component.html',
  styleUrls: ['./employee-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeListItemComponent implements OnInit {

  @Input() employee: ReportEmployee;

  @Input() selected: boolean;

  @Output()
  public onToggle: EventEmitter<void> = new EventEmitter<void>();

  public get name(): string {
    const parts: string[] = [];
    if (!this.employee) {
      return "";
    }

    parts.push(this.employee.lastname);
    if (this.employee.firstname) {
      parts.push(this.employee.firstname);
    }
    if (this.employee.middlename) {
      parts.push(this.employee.middlename);
    }
    if (this.employee.position) {
      parts.push(`(${this.employee.position})`);
    }

    return parts.join(" ").trim();
  }

  constructor() { }

  ngOnInit() { }

  public toggle(): void {
    this.onToggle.emit();
  }

}
