import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable, from } from 'rxjs';
import { GetOrdersResponse, GetOrdersResponseItem, Company, AccessTokenResponse, GetOrdersResponseItemAttachment } from '../../../../generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { UserStorage } from '../../../../services/user-storage';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ObservationsService, MyService, InvoicesService, BarcodePrinterService } from '../../../../generated/services';
import { ToastrService } from 'ngx-toastr';
import { takeUntil, tap, switchMap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';

import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageObservationModalComponent } from '../manage-observation.modal/manage-observation.modal.component';
import { LabModel } from '../../../../generated/models/lab-model';
import { ReferralModel } from '../../../../generated/models/referral-model';
import { ObservationPayload } from '../../resolvers/observations.resolver';
import { SendOrdersResponse } from '../../../../generated/models/send-orders-response';
import { ObservationCommentModalComponent } from '../observation-comment-modal/observation-comment-modal.component';
import { PermissionNames } from '../../../../models/permission-names';
import { DocumentPreviewService } from '../../../shared/services/document-preview-service';

@Component({
  selector: 'mp-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss'],
  host: { class: "page" }
})
export class ObservationsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  items: GetOrdersResponseItem[] = [];
  selectedItems: GetOrdersResponseItem[] = [];
  medicalCenters: Company[] = [];
  labs: LabModel[] = [];
  referrals: ReferralModel[] = [];

  loading = false;
  sending = false;

  page = 1;

  get hasSelected(): boolean { return this.selectedItems.length > 0; }
  get indeterminate(): boolean { return this.selectedItems.length > 0 && this.selectedItems.length !== this.items.length; }
  get allSelected(): boolean { return this.selectedItems.length === this.items.length; }

  canManageAttachments = false;
  canSyncLabResults = false;
  canRemoveLabResults = false;
  canSendResults = false;
  canPrintBarcode = false;

  searchForm = new FormGroup({
    search: new FormControl(""),
    range: new FormControl(),
    medicalCenter: new FormControl(""),
    lab: new FormControl(""),
    referral: new FormControl("")
  });

  constructor(userStorage: UserStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private observationsService: ObservationsService,
    private myService: MyService,
    private invoicesService: InvoicesService,
    private barcodePrinterController: BarcodePrinterService,
    private toastrService: ToastrService,
    private documentPreveiewService: DocumentPreviewService,
    private modal: NgbModal) {

    this.canPrintBarcode = !!userStorage.profile.barcodePrinterId;
    this.canSendResults = userStorage.hasPermission(PermissionNames.SendLabOrders);

    this.canManageAttachments = userStorage.hasPermission(PermissionNames.DeleteTestOrderResults);

    this.canSyncLabResults = userStorage.profile.canSyncLabResults;
    this.canRemoveLabResults = userStorage.profile.canRemoveLabResults;

    this.searchForm.get("range").setValue({ from: moment().startOf("day"), to: moment() });

    this.searchForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FormValue): void => {
          const params: Params = {};

          if (value.search) {
            params['search'] = value.search;
          }

          if (value.range.from && value.range.from.isValid()) {
            params['from'] = value.range.from.format('DD.MM.YYYY');
          }

          if (value.range.to && value.range.to.isValid()) {
            params['to'] = value.range.to.format('DD.MM.YYYY');
          }

          if (value.medicalCenter) {
            params['company'] = value.medicalCenter;
          }

          if (value.lab) {
            params['lab'] = value.lab;
          }

          if (value.referral) {
            params['doctor'] = value.referral;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
        }),
        tap((): void => {
          this.page = 1;
          this.items = [];
          this.loading = true;
        }),
        switchMap((value: FormValue): Observable<GetOrdersResponse> => this.observationsService.GetOrders({
          Page: this.page,
          From: value.range.from && value.range.from.isValid() ? value.range.from.format("DD.MM.YYYY") : "",
          To: value.range.to && value.range.to.isValid() ? value.range.to.format("DD.MM.YYYY") : "",
          Search: value.search,
          Companies: value.medicalCenter !== 0 ? [value.medicalCenter] : [],
          Labs: value.lab !== 0 ? [value.lab] : [],
          Referrals: value.referral !== 0 ? [value.referral] : []
        }).pipe(catchError(() => {
          return from([{ items: [] }]);
        }))),
        tap((response: GetOrdersResponse) => this.items = response.items)
      )
      .subscribe(
        () => this.loading = false,
        (response: HttpErrorResponse): void => {
          this.loading = false;
          this.handleErrorResponse(response);
        }
      );
  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParamMap;

    this.searchForm.patchValue({
      search: params.get("search"),
      range: {
        from: params.has('from') ? moment(params.get('from'), 'DD.MM.YYYY') : moment(),
        to: params.has('to') ? moment(params.get('to'), 'DD.MM.YYYY') : moment()
      },
      medicalCenter: params.has('company') ? parseInt(params.get('company')) : undefined,
      lab: params.has('lab') ? parseInt(params.get('lab')) : undefined,
      referral: params.has('doctor') ? parseInt(params.get('doctor')) : undefined
    });

    this.activatedRoute.data.subscribe((data: { payload: ObservationPayload }): void => {
      this.medicalCenters = data.payload.companies;
      this.labs = data.payload.labs;
      this.referrals = data.payload.referrals;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onScrollDown() {
    this.page++;
    this.load();
  }

  selected = (item: GetOrdersResponseItem) => this.selectedItems.some(x => x.id === item.id);

  toggle(item: GetOrdersResponseItem, value: boolean): void {
    const index = this.selectedItems.findIndex(x => x.id === item.id);

    if (value && index === -1) {
      this.selectedItems.push(item);
    }

    if (!value && index !== -1) {
      this.selectedItems.splice(index, 1);
    }
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }
    if (response.status === 403) {
      this.toastrService.error("Для выполнения данного действия требуется разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }

  async getAttachment(id: number, item: GetOrdersResponseItem): Promise<void> {
    const response = await this.myService.AccessToken({ parameters: { id: id.toString(), testOrderId: item.id.toString() } }).toPromise();
    const componentRef = this.documentPreveiewService.open(`/api/clinic/v1/observations/attachment?token=${response.token}`);

    componentRef.onDownload.subscribe(async () => {
      const response = await this.myService.AccessToken({ parameters: { id: id.toString() } }).toPromise();
      window.open(`/api/clinic/v1/observations/attachment?token=${response.token}&download=true`, "_blank");
    });
  }

  async openManager(observation: GetOrdersResponseItem): Promise<void> {
    const modalRef: NgbModalRef = this.modal.open(ManageObservationModalComponent, { backdrop: "static", size: "lg" });
    const componentRef: ManageObservationModalComponent = modalRef.componentInstance;

    componentRef.title = `Удаление результатов исследования пациента <strong>${observation.lastName} ${observation.firstName} ${observation.middleName}</strong>`
    componentRef.message = `Все полученные результаты исследования будут удалены и запрошены повторно.`;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(() => {
      this.observationsService.Retry(observation.id)
        .subscribe(
          () => {
            observation.attachments = [];

            this.toastrService.success('Результаты удалены и запрошены повторно', 'Успешно');
            modalRef.close();
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось запросить результаты исследования повторно", "Ошибка");
          }
        );
    });
  }

  load(): void {
    const value: FormValue = this.searchForm.getRawValue();

    const from: string = value.range.from && value.range.from.isValid() ? value.range.from.format("DD.MM.YYYY") : "";
    const to: string = value.range.to && value.range.to.isValid() ? value.range.to.format("DD.MM.YYYY") : "";

    this.observationsService.GetOrders({
      Page: this.page,
      From: from,
      To: to,
      Search: value.search,
      Companies: value.medicalCenter > 0 ? [value.medicalCenter] : [],
      Labs: value.lab > 0 ? [value.lab] : [],
      Referrals: value.referral > 0 ? [value.referral] : []
    })
      .subscribe(
        (response: GetOrdersResponse): void => {
          this.items.push(...response.items);
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          this.loading = false;
          this.handleErrorResponse(response);
        });
  }

  printInvoice(item: GetOrdersResponseItem): void {

    this.invoicesService.SetPrinted(item.invoiceNumber)
      .pipe(switchMap(() => this.myService.AccessToken({ parameters: { name: item.reportName, id: `${item.invoiceNumber}` } })))
      .subscribe(
        (response: AccessTokenResponse): void => {
          const frame: HTMLIFrameElement = document.createElement("iframe");

          frame.style.display = "none";
          frame.src = `api/v1/Reports/Prepared?token=${response.token}&type=pdf`;

          document.body.append(frame);
          frame.contentWindow.print();

          this.items.filter(x => x.invoiceNumber === item.invoiceNumber).forEach(x => x.isInvoicePrinted = true);
        }
      );
  }

  send() {
    this.sending = true;

    const value: FormValue = this.searchForm.getRawValue();
    const from: string = value.range.from && value.range.from.isValid() ? value.range.from.format("DD.MM.YYYY") : "";
    const to: string = value.range.to && value.range.to.isValid() ? value.range.to.format("DD.MM.YYYY") : "";

    this.invoicesService.SendOrders({ From: from, To: to })
      .subscribe(
        (response: SendOrdersResponse): void => {
          if (response.sent === 0 && response.failed === 0) {
            this.toastrService.success("Нет заявок для отправки", 'Успешно');
          }
          else if (response.sent > 0 && response.failed === 0) {
            this.toastrService.success(`Заявок отправлено: ${response.sent}`, 'Успешно');
          }
          else if (response.sent > 0 && response.failed > 0) {
            this.toastrService.warning(`Заявок отправлено: ${response.sent}. \n Не удалось отправить: ${response.failed}`, 'Ошибка!');
          }
          else {
            this.toastrService.error(` Не удалось отправить: ${response.failed} `, 'Ошибка!');
          }

          this.page = 1;
          this.loading = true;
          this.items = [];
          this.load();
          this.sending = false;
        },
        () => {
          this.toastrService.error("Ошибка");
          this.sending = false;
        }
      );
  }

  printBarcode(order: GetOrdersResponseItem): void {
    this.barcodePrinterController.CreateBarcodes({ orders: [order.id] })
      .subscribe(
        () => this.toastrService.success("Печать этикетки начата"),
        () => this.toastrService.error("Не удалось напечатать этикетку", "Ошибка"));
  }

  editComment(observation: GetOrdersResponseItem) {
    const modalRef = this.modal.open(ObservationCommentModalComponent, { backdrop: 'static' });
    const componentRef: ObservationCommentModalComponent = modalRef.componentInstance;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onSubmit.subscribe((comment: string) => {
      this.observationsService.UpdateComment({ id: observation.id, request: { comment: comment } })
        .subscribe(
          (): void => {
            this.toastrService.success("Комментарий обновлен", "Успешно");
            observation.comments = comment;
            modalRef.close();
          },
          (errorResponse: HttpErrorResponse): void => {
            if (errorResponse.status === 400) {
              this.toastrService.warning(errorResponse.error.message, "Ошибка");
            } else {
              this.toastrService.error("Не удалось обновить комментарий", "Ошибка");
            }
          }
        );

    });

    componentRef.comment = observation.comments;
  }

  removeOrder(order: GetOrdersResponseItem): void {
    const modalRef: NgbModalRef = this.modal.open(ManageObservationModalComponent, { backdrop: "static", size: "lg" });
    const componentRef: ManageObservationModalComponent = modalRef.componentInstance;

    componentRef.title = `Удаление заявки на исследования пациента <strong>${order.lastName} ${order.firstName} ${order.middleName}</strong>`
    componentRef.message = `Заявка будет удалена.`;

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(() => {
      this.observationsService.RemovePendingOrder(order.id).subscribe(
        (): void => {
          modalRef.close();
          this.toastrService.success('Заявка удалена', 'Успешно');

          this.page = 1;
          this.items = [];
          this.loading = true;

          this.load();
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, 'Ошибка');
            return;
          }

          this.toastrService.warning('Не удалось удалить заявку', 'Ошибка');
        }
      );


    });
  }

  addAttachment(order: GetOrdersResponseItem, file: File): void {
    this.observationsService.AddAttachments({ id: order.id, file: file })
      .subscribe(
        (): void => {
          this.observationsService.Attachments(order.id)
            .subscribe(
              (items: GetOrdersResponseItemAttachment[]): void => {
                order.attachments = items;
                order.resultsReceived = moment().unix();
              },
              (): void => {
              }
            );
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, 'Ошибка');
            return;
          }

          if (response.status === 413) {
            this.toastrService.warning('Превышен максимально допустимый размер файла', 'Ошибка');
            return;
          }

          this.toastrService.error("Не удалось загрузить файл", "Ошибка");
        }
      );
  }

  disableResultsSync(order: GetOrdersResponseItem): void {
    const modalRef: NgbModalRef = this.modal.open(ManageObservationModalComponent, { backdrop: "static", size: "lg" });
    
    const componentRef: ManageObservationModalComponent = modalRef.componentInstance;
    componentRef.title = `Остановка загрузки результатов`
    componentRef.message = `Автоматическая загрузка результатов для этой заяки будет отключена.`;
    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe(() => {        
      this.observationsService.DisableResultsSync({ id: order.id, request: { disable: true } })
        .subscribe(
          (): void => {
            modalRef.close();
            order.resultsSyncDisabled = true;
            this.toastrService.success('Загрузка остановлена', 'Успешно');
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, 'Ошибка');
              return;
            }

            this.toastrService.error("Не удалось остановить загрузку результатов", "Ошибка");
          }
        );
    });
  }
}

interface FormValue {
  search: string;
  range: {
    from: moment.Moment;
    to: moment.Moment;
  };
  medicalCenter: number;
  lab: number;
  referral: number;
}
