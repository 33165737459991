import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, NavigationError } from "@angular/router";
import { registerLocaleData, DecimalPipe } from "@angular/common";
import { ApiModule } from "./generated/api.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import localeRu from "@angular/common/locales/ru";

registerLocaleData(localeRu, 'ru');

import { AppComponent } from "./app.component";
import { AppRoutingModule } from './app-routing.module';
import { ProfileComponent } from './components/profile/profile.component';
import { MessageReceiverService } from './services/message.receiver.service';
import { CookieService } from 'ngx-cookie-service';
import { SettingsStorage } from './services/settings-storage.service';
import { SettingsGuard } from './security/settings.guard';
import { UserStorage } from './services/user-storage';
import { IncomingCallNotificationComponent } from './components/incoming-call-notification/incoming-call-notification.component';
import { SharedModule } from './modules/shared/shared.module';

import {
  SecurityModule,
  REFRESH_TOKEN_URL,
  SIGNIN_URL,
  PASSPORT_URL,
  REFRESH_TOKEN_PERIOD,
  JwtInterceptor,
  HttpErrorInterceptor,
  RefreshTokenService
} from '@skytecs/security';

import { ControlsModule, SEARCH_CONTROL_DEBOUNCE } from '@skytecs/controls';

import { LayoutModule } from '@skytecs/layout';
import { AuthenticatedComponent } from './components/authenticated/authenticated.component';
import { AnonymousComponent } from './components/anonymous/anonymous.component';
import { FormsModule } from '@angular/forms';
import { ChooseCompanyComponent } from './components/choose-company/choose-company.component';
import { ChangeCompanyModalComponent } from './components/change-company-modal/change-company-modal.component';
import { NgbModalModule, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { HEALTHCHECK_PING_URL, HEALTHCHECK_SUCCESS_TIMEOUT, HEALTHCHECK_FAILURE_TIMEOUT, HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME, HEALTHCHECK_WAITING_PERIOD, HEALTHCHECK_ENABLED } from './services/healthcheck-service';
import { ConnectionLostNotificationComponent } from './components/connection-lost-notification/connection-lost-notification.component';
import { ConnectionEstablishedNotificationComponent } from './components/connection-established-notification/connection-established-notification.component';
import { ConnectionStatusPanelComponent } from './components/connection-status-panel/connection-status-panel.component';
import { DeleteConfirmationModalComponent } from './components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ActiveCallModule } from "./modules/active-call/active-call.module";
import { PersonMarksModule } from "./modules/person-marks/person-marks.module";

@NgModule({
  declarations: [
    AppComponent,
    AuthenticatedComponent,
    AnonymousComponent,
    ProfileComponent,
    IncomingCallNotificationComponent,
    ChooseCompanyComponent,
    ChangeCompanyModalComponent,
    ConnectionLostNotificationComponent,
    ConnectionEstablishedNotificationComponent,
    ConnectionStatusPanelComponent,
    DeleteConfirmationModalComponent
  ],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    ApiModule,
    ToastrModule.forRoot(),
    FormsModule,
    SecurityModule,
    ControlsModule,
    LayoutModule,
    NgbModalModule,
    SharedModule,
    PersonMarksModule,

    ActiveCallModule
  ],
  providers: [
    SettingsStorage,
    UserStorage,
    CookieService,
    MessageReceiverService,
    NgbDatepickerConfig,
    RefreshTokenService,

    { provide: LOCALE_ID, useValue: "ru" },
    { provide: PASSPORT_URL, useValue: new BehaviorSubject('') },
    { provide: REFRESH_TOKEN_URL, useValue: new BehaviorSubject('') },
    { provide: SIGNIN_URL, useValue: new BehaviorSubject('') },
    { provide: REFRESH_TOKEN_PERIOD, useValue: new BehaviorSubject(0) },
    { provide: HEALTHCHECK_PING_URL, useValue: new BehaviorSubject<string>("") },
    { provide: HEALTHCHECK_SUCCESS_TIMEOUT, useValue: new BehaviorSubject<number>(0) },
    { provide: HEALTHCHECK_FAILURE_TIMEOUT, useValue: new BehaviorSubject<number>(0) },
    { provide: HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME, useValue: new BehaviorSubject<number>(0) },
    { provide: HEALTHCHECK_WAITING_PERIOD, useValue: new BehaviorSubject<number>(0) },
    { provide: HEALTHCHECK_ENABLED, useValue: new BehaviorSubject<boolean>(false) },
    { provide: SEARCH_CONTROL_DEBOUNCE, useValue: new BehaviorSubject<number>(1000) },

    DecimalPipe,
    SettingsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: ErrorHandlerInterceptor,
    //  multi: true
    //},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    IncomingCallNotificationComponent,
    ChangeCompanyModalComponent,
    ConnectionLostNotificationComponent,
    ConnectionEstablishedNotificationComponent,
    DeleteConfirmationModalComponent
  ],
  exports: []
})
export class AppModule {
  constructor(refreshTokenService: RefreshTokenService,
    router: Router,
    datepickerConfig: NgbDatepickerConfig,
    private titleService: Title,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
  ) {
    datepickerConfig.minDate = { day: 1, month: 1, year: 1900 };
    datepickerConfig.maxDate = { day: 31, month: 12, year: 2100 };

    refreshTokenService.init();

    router.events.subscribe((e) => {
      if (e instanceof NavigationError) {
        console.trace(e);

        if (e.error instanceof HttpErrorResponse) {
          if (e.error.status === 400) {
            this.toastrService.warning(e.error.error.message, "Ошибка");
          } else if (e.error.status === 401 || e.error.status === 403) {
            this.toastrService.warning("Отказано в доступе", "Ошибка");
          } else {
            this.toastrService.warning("Не удалось выполнить операцию", "Ошибка");
          }

        }
        router.navigate([]);
        return;
      }

      if (e instanceof NavigationEnd) {
        let snapshot: ActivatedRouteSnapshot = this.route.snapshot;
        while (snapshot.children.length > 0) {
          snapshot = snapshot.children[0];
        }

        const title = "Мои Пациенты | " + snapshot.data["title"];
        this.titleService.setTitle(title);
      }
    });
  }
}

registerLocaleData(localeRu);
