import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonTestResult, PersonTestResultAttachment } from '../../../../generated/models';

@Component({
  selector: 'mp-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent implements OnInit {

  @Input() items: PersonTestResult[] = [];

  @Input() loading = false;

  @Output() onOpenAttachment = new EventEmitter<{ id: number, testOrderId: number }>();
  @Output() onPrint = new EventEmitter<PersonTestResult>();

  constructor() { }

  ngOnInit() { }

  openAttachment = (attachment: {id:number, testOrderId: number}) => this.onOpenAttachment.emit(attachment);
  print = (result: PersonTestResult) => this.onPrint.emit(result);
}
