import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';

@Component({
  selector: 'mp-visit-duplicate-modal',
  templateUrl: './visit-duplicate-modal.component.html',
  styleUrls: ['./visit-duplicate-modal.component.scss']
})
export class VisitDuplicateModalComponent implements OnInit {
  @Input() name = '';
  @Input() birthYear = '';
  @Input() lastVisit = '';

  constructor(private activeModal: NgbActiveModal) {
    moment.locale('ru')
  }

  ngOnInit() { }

  confirm = () => this.activeModal.close(true);

  discard = () => this.activeModal.close(false);

  get lastVisitFormatted(): string { return moment(this.lastVisit, 'DD.MM.YYYY').format('DD MMMM YYYY') }
  get birthYearExtracted(): string { return moment(this.birthYear, 'DD.MM.YYYY').year().toString(); }
}
