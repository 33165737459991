import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { SpecialitiesService, EmployeesService } from '../../../generated/services';
import { Speciality, Attending, AttendingsResponse, SpecialitiesResponse } from '../../../generated/models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: "root" })
export class TemplatesResolver implements Resolve<TemplatesPayload> {

  public constructor(
    private specialitiesService: SpecialitiesService,
    private employeesService: EmployeesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TemplatesPayload> {
    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(
        map((response: SpecialitiesResponse): Speciality[] => response.items)
      );

    const attendingsLoader: Observable<Attending[]> = this.employeesService.Attendings({ Page: 1, Size: 0 })
      .pipe(
        map((response: AttendingsResponse): Attending[] => response.items)
      );

    return forkJoin({
      specialities: specialitiesLoader,
      attendings: attendingsLoader
    });
  }
}

export interface TemplatesPayload {
  specialities: Speciality[];
  attendings: Attending[];
}
