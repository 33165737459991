/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResourcesInfoResponse } from '../models/resources-info-response';
import { CreateResourceResponse } from '../models/create-resource-response';
import { CreateResourceRequest } from '../models/create-resource-request';
import { Resource } from '../models/resource';
import { UpdateResourceRequest } from '../models/update-resource-request';
import { AvailableResourceService } from '../models/available-resource-service';
import { AvailableResourceEmployee } from '../models/available-resource-employee';
import { ResourceUserViewModel } from '../models/resource-user-view-model';
import { AddResourceEmployeeRequest } from '../models/add-resource-employee-request';
import { AvailableResourceSpeciality } from '../models/available-resource-speciality';
import { ResourceSpecialityViewModel } from '../models/resource-speciality-view-model';
import { AddResourceSpecialityRequest } from '../models/add-resource-speciality-request';
import { ResourceCategoryViewModel } from '../models/resource-category-view-model';
import { AddResourceCategoryRequest } from '../models/add-resource-category-request';
import { ScheduleItemViewModel } from '../models/schedule-item-view-model';
import { UpdateShiftRequest } from '../models/update-shift-request';
import { CreateShiftRequest } from '../models/create-shift-request';
import { BreakViewModel } from '../models/break-view-model';
import { CreateBreakRequest } from '../models/create-break-request';
import { UpdateBreakRequest } from '../models/update-break-request';
@Injectable({
  providedIn: 'root',
})
class ScheduleSettingsService extends __BaseService {
  static readonly ResourcesPath = '/api/ScheduleSettings';
  static readonly CreateResourcePath = '/api/ScheduleSettings';
  static readonly ResourcePath = '/api/ScheduleSettings/{id}';
  static readonly UpdateResourcePath = '/api/ScheduleSettings/{id}';
  static readonly DeleteResourcePath = '/api/ScheduleSettings/{id}';
  static readonly AvailableServicesAsyncPath = '/api/ScheduleSettings/{resourceId}/services/available';
  static readonly AvailableEmployeesAsyncPath = '/api/ScheduleSettings/{resourceId}/employees/available';
  static readonly EmployeesPath = '/api/ScheduleSettings/{resourceId}/employees';
  static readonly CreateEmployeePath = '/api/ScheduleSettings/{resourceId}/employees';
  static readonly DeleteEmployeePath = '/api/ScheduleSettings/{resourceId}/employees/{id}';
  static readonly AvailableSpecialitiesAsyncPath = '/api/ScheduleSettings/{resourceId}/specialities/available';
  static readonly SpecialitiesPath = '/api/ScheduleSettings/{resourceId}/specialities';
  static readonly CreateSpecialityPath = '/api/ScheduleSettings/{resourceId}/specialities';
  static readonly DeleteSpecialityPath = '/api/ScheduleSettings/{resourceId}/specialities/{id}';
  static readonly CategoriesPath = '/api/ScheduleSettings/{resourceId}/categories';
  static readonly CreateCategoryPath = '/api/ScheduleSettings/{resourceId}/categories';
  static readonly DeleteCategoryPath = '/api/ScheduleSettings/{resourceId}/categories/{id}';
  static readonly ShiftAsyncPath = '/api/ScheduleSettings/{resourceId}/shifts/{id}';
  static readonly EditShiftPath = '/api/ScheduleSettings/{resourceId}/shifts/{id}';
  static readonly DeleteShiftPath = '/api/ScheduleSettings/{resourceId}/shifts/{id}';
  static readonly ShiftsPath = '/api/ScheduleSettings/{resourceId}/shifts';
  static readonly CreateShiftPath = '/api/ScheduleSettings/{resourceId}/shifts';
  static readonly BreaksPath = '/api/ScheduleSettings/{resourceId}/breaks';
  static readonly CreateBreakPath = '/api/ScheduleSettings/{resourceId}/breaks';
  static readonly EditBreakPath = '/api/ScheduleSettings/{resourceId}/breaks/{id}';
  static readonly DeleteBreakPath = '/api/ScheduleSettings/{resourceId}/breaks/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ScheduleSettingsService.ResourcesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `OrganizationId`:
   *
   * - `IncludedArchived`:
   *
   * @return Success
   */
  ResourcesResponse(params: ScheduleSettingsService.ResourcesParams): __Observable<__StrictHttpResponse<ResourcesInfoResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OrganizationId != null) __params = __params.set('OrganizationId', params.OrganizationId.toString());
    if (params.IncludedArchived != null) __params = __params.set('IncludedArchived', params.IncludedArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResourcesInfoResponse>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.ResourcesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `OrganizationId`:
   *
   * - `IncludedArchived`:
   *
   * @return Success
   */
  Resources(params: ScheduleSettingsService.ResourcesParams): __Observable<ResourcesInfoResponse> {
    return this.ResourcesResponse(params).pipe(
      __map(_r => _r.body as ResourcesInfoResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResourceResponse(request?: CreateResourceRequest): __Observable<__StrictHttpResponse<CreateResourceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleSettings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateResourceResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateResource(request?: CreateResourceRequest): __Observable<CreateResourceResponse> {
    return this.CreateResourceResponse(request).pipe(
      __map(_r => _r.body as CreateResourceResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ResourceResponse(id: number): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Resource(id: number): __Observable<Resource> {
    return this.ResourceResponse(id).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.UpdateResourceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResourceResponse(params: ScheduleSettingsService.UpdateResourceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ScheduleSettings/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.UpdateResourceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResource(params: ScheduleSettingsService.UpdateResourceParams): __Observable<null> {
    return this.UpdateResourceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteResourceResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteResource(id: number): __Observable<null> {
    return this.DeleteResourceResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableServicesAsyncResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<AvailableResourceService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/services/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableResourceService>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableServicesAsync(resourceId: number): __Observable<Array<AvailableResourceService>> {
    return this.AvailableServicesAsyncResponse(resourceId).pipe(
      __map(_r => _r.body as Array<AvailableResourceService>)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableEmployeesAsyncResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<AvailableResourceEmployee>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/employees/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableResourceEmployee>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableEmployeesAsync(resourceId: number): __Observable<Array<AvailableResourceEmployee>> {
    return this.AvailableEmployeesAsyncResponse(resourceId).pipe(
      __map(_r => _r.body as Array<AvailableResourceEmployee>)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  EmployeesResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<ResourceUserViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResourceUserViewModel>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  Employees(resourceId: number): __Observable<Array<ResourceUserViewModel>> {
    return this.EmployeesResponse(resourceId).pipe(
      __map(_r => _r.body as Array<ResourceUserViewModel>)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.CreateEmployeeParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateEmployeeResponse(params: ScheduleSettingsService.CreateEmployeeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.CreateEmployeeParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateEmployee(params: ScheduleSettingsService.CreateEmployeeParams): __Observable<null> {
    return this.CreateEmployeeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.DeleteEmployeeParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteEmployeeResponse(params: ScheduleSettingsService.DeleteEmployeeParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/employees/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.DeleteEmployeeParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteEmployee(params: ScheduleSettingsService.DeleteEmployeeParams): __Observable<null> {
    return this.DeleteEmployeeResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableSpecialitiesAsyncResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<AvailableResourceSpeciality>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/specialities/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableResourceSpeciality>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  AvailableSpecialitiesAsync(resourceId: number): __Observable<Array<AvailableResourceSpeciality>> {
    return this.AvailableSpecialitiesAsyncResponse(resourceId).pipe(
      __map(_r => _r.body as Array<AvailableResourceSpeciality>)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  SpecialitiesResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<ResourceSpecialityViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResourceSpecialityViewModel>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  Specialities(resourceId: number): __Observable<Array<ResourceSpecialityViewModel>> {
    return this.SpecialitiesResponse(resourceId).pipe(
      __map(_r => _r.body as Array<ResourceSpecialityViewModel>)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.CreateSpecialityParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateSpecialityResponse(params: ScheduleSettingsService.CreateSpecialityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.CreateSpecialityParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateSpeciality(params: ScheduleSettingsService.CreateSpecialityParams): __Observable<null> {
    return this.CreateSpecialityResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.DeleteSpecialityParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteSpecialityResponse(params: ScheduleSettingsService.DeleteSpecialityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/specialities/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.DeleteSpecialityParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteSpeciality(params: ScheduleSettingsService.DeleteSpecialityParams): __Observable<null> {
    return this.DeleteSpecialityResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  CategoriesResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<ResourceCategoryViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ResourceCategoryViewModel>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  Categories(resourceId: number): __Observable<Array<ResourceCategoryViewModel>> {
    return this.CategoriesResponse(resourceId).pipe(
      __map(_r => _r.body as Array<ResourceCategoryViewModel>)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.CreateCategoryParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateCategoryResponse(params: ScheduleSettingsService.CreateCategoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.CreateCategoryParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateCategory(params: ScheduleSettingsService.CreateCategoryParams): __Observable<null> {
    return this.CreateCategoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.DeleteCategoryParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteCategoryResponse(params: ScheduleSettingsService.DeleteCategoryParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/categories/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.DeleteCategoryParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteCategory(params: ScheduleSettingsService.DeleteCategoryParams): __Observable<null> {
    return this.DeleteCategoryResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.ShiftAsyncParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ShiftAsyncResponse(params: ScheduleSettingsService.ShiftAsyncParams): __Observable<__StrictHttpResponse<ScheduleItemViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/shifts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ScheduleItemViewModel>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.ShiftAsyncParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * @return Success
   */
  ShiftAsync(params: ScheduleSettingsService.ShiftAsyncParams): __Observable<ScheduleItemViewModel> {
    return this.ShiftAsyncResponse(params).pipe(
      __map(_r => _r.body as ScheduleItemViewModel)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.EditShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditShiftResponse(params: ScheduleSettingsService.EditShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/shifts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.EditShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditShift(params: ScheduleSettingsService.EditShiftParams): __Observable<null> {
    return this.EditShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.DeleteShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteShiftResponse(params: ScheduleSettingsService.DeleteShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/shifts/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.DeleteShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteShift(params: ScheduleSettingsService.DeleteShiftParams): __Observable<null> {
    return this.DeleteShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  ShiftsResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<ScheduleItemViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/shifts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ScheduleItemViewModel>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  Shifts(resourceId: number): __Observable<Array<ScheduleItemViewModel>> {
    return this.ShiftsResponse(resourceId).pipe(
      __map(_r => _r.body as Array<ScheduleItemViewModel>)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.CreateShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateShiftResponse(params: ScheduleSettingsService.CreateShiftParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/shifts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.CreateShiftParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateShift(params: ScheduleSettingsService.CreateShiftParams): __Observable<null> {
    return this.CreateShiftResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param resourceId undefined
   * @return Success
   */
  BreaksResponse(resourceId: number): __Observable<__StrictHttpResponse<Array<BreakViewModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ScheduleSettings/${resourceId}/breaks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BreakViewModel>>;
      })
    );
  }
  /**
   * @param resourceId undefined
   * @return Success
   */
  Breaks(resourceId: number): __Observable<Array<BreakViewModel>> {
    return this.BreaksResponse(resourceId).pipe(
      __map(_r => _r.body as Array<BreakViewModel>)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.CreateBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateBreakResponse(params: ScheduleSettingsService.CreateBreakParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/breaks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.CreateBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `request`:
   */
  CreateBreak(params: ScheduleSettingsService.CreateBreakParams): __Observable<null> {
    return this.CreateBreakResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.EditBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditBreakResponse(params: ScheduleSettingsService.EditBreakParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/breaks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.EditBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditBreak(params: ScheduleSettingsService.EditBreakParams): __Observable<null> {
    return this.EditBreakResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ScheduleSettingsService.DeleteBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteBreakResponse(params: ScheduleSettingsService.DeleteBreakParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ScheduleSettings/${params.resourceId}/breaks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ScheduleSettingsService.DeleteBreakParams` containing the following parameters:
   *
   * - `resourceId`:
   *
   * - `id`:
   */
  DeleteBreak(params: ScheduleSettingsService.DeleteBreakParams): __Observable<null> {
    return this.DeleteBreakResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ScheduleSettingsService {

  /**
   * Parameters for Resources
   */
  export interface ResourcesParams {
    Size?: number;
    Search?: string;
    Page?: number;
    OrganizationId?: number;
    IncludedArchived?: boolean;
  }

  /**
   * Parameters for UpdateResource
   */
  export interface UpdateResourceParams {
    id: number;
    request?: UpdateResourceRequest;
  }

  /**
   * Parameters for CreateEmployee
   */
  export interface CreateEmployeeParams {
    resourceId: number;
    request?: AddResourceEmployeeRequest;
  }

  /**
   * Parameters for DeleteEmployee
   */
  export interface DeleteEmployeeParams {
    resourceId: number;
    id: number;
  }

  /**
   * Parameters for CreateSpeciality
   */
  export interface CreateSpecialityParams {
    resourceId: number;
    request?: AddResourceSpecialityRequest;
  }

  /**
   * Parameters for DeleteSpeciality
   */
  export interface DeleteSpecialityParams {
    resourceId: number;
    id: number;
  }

  /**
   * Parameters for CreateCategory
   */
  export interface CreateCategoryParams {
    resourceId: number;
    request?: AddResourceCategoryRequest;
  }

  /**
   * Parameters for DeleteCategory
   */
  export interface DeleteCategoryParams {
    resourceId: number;
    id: number;
  }

  /**
   * Parameters for ShiftAsync
   */
  export interface ShiftAsyncParams {
    resourceId: number;
    id: number;
  }

  /**
   * Parameters for EditShift
   */
  export interface EditShiftParams {
    resourceId: number;
    id: number;
    request?: UpdateShiftRequest;
  }

  /**
   * Parameters for DeleteShift
   */
  export interface DeleteShiftParams {
    resourceId: number;
    id: number;
  }

  /**
   * Parameters for CreateShift
   */
  export interface CreateShiftParams {
    resourceId: number;
    request?: CreateShiftRequest;
  }

  /**
   * Parameters for CreateBreak
   */
  export interface CreateBreakParams {
    resourceId: number;
    request?: CreateBreakRequest;
  }

  /**
   * Parameters for EditBreak
   */
  export interface EditBreakParams {
    resourceId: number;
    id: number;
    request?: UpdateBreakRequest;
  }

  /**
   * Parameters for DeleteBreak
   */
  export interface DeleteBreakParams {
    resourceId: number;
    id: number;
  }
}

export { ScheduleSettingsService }
