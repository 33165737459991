import { Injectable, Injector } from '@angular/core';
import { SettingsService } from '../generated/services';
import { Observable } from 'rxjs';
import { GlobalSettings } from '../generated/models';
import { tap, map } from 'rxjs/operators';
import { PASSPORT_URL, REFRESH_TOKEN_URL, SIGNIN_URL, REFRESH_TOKEN_PERIOD } from '@skytecs/security';
import { HEALTHCHECK_PING_URL, HEALTHCHECK_WAITING_PERIOD, HEALTHCHECK_SUCCESS_TIMEOUT, HEALTHCHECK_FAILURE_TIMEOUT, HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME, HEALTHCHECK_ENABLED } from './healthcheck-service';

@Injectable()
export class SettingsStorage {
  private _settings: GlobalSettings;

  constructor(private service: SettingsService, private injector: Injector) { }

  public populate(): Observable<boolean> {
    return this.service.Global()
      .pipe(
        tap((settings: GlobalSettings): void => {
          this._settings = settings;
          this.updateTokens();
        }),
        map((): boolean => true)
      );
  }
  updateTokens() {
    this.injector.get(PASSPORT_URL).next(this._settings.passportUrl);
    this.injector.get(SIGNIN_URL).next(this._settings.signinUrl);
    this.injector.get(REFRESH_TOKEN_URL).next(this._settings.refreshTokenUrl);
    this.injector.get(REFRESH_TOKEN_PERIOD).next(this._settings.refreshTokenPeriod);

    this.injector.get(HEALTHCHECK_PING_URL).next("/api/ping");

    this.injector.get(HEALTHCHECK_WAITING_PERIOD).next(this._settings.healthcheck.waitingPeriod);
    this.injector.get(HEALTHCHECK_SUCCESS_TIMEOUT).next(this._settings.healthcheck.successTimeout);
    this.injector.get(HEALTHCHECK_FAILURE_TIMEOUT).next(this._settings.healthcheck.failureTimeout);
    this.injector.get(HEALTHCHECK_SUCCESS_NOTIFICATION_LIFETIME).next(this._settings.healthcheck.successNotificationLifitime);

    this.injector.get(HEALTHCHECK_ENABLED).next(this._settings.healthcheck.enabled);
  }

  public get global(): GlobalSettings { return this._settings; }
}
