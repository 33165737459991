import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PartnerPlanListItem, Company, Employee, EmployeesResponse } from 'projects/Clinic/src/app/generated/models';
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';

@Component({
  selector: 'mp-employee-partner-modal',
  templateUrl: './employee-partner-modal.component.html',
  styleUrls: ['./employee-partner-modal.component.scss']
})
export class EmployeePartnerModalComponent implements OnInit {
  @Input("employeeId") employeeId: number;

  @Input("plans") plans: PartnerPlanListItem[];
  @Input("companies") companies: Company[];

  public title: string;
  public form: FormGroup;
  public filters: FormGroup;

  private _page: number;
  private _size: number;

  private _employees: Employee[];

  private _selected: Employee;

  constructor(
    private activeModal: NgbActiveModal,
    private employeesService: EmployeesService
  ) {
    this.title = "Добавление привлеченного врача";
    this._page = 1;
    this._size = 20;
    this._employees = [];

    this.form = new FormGroup({
      plan: new FormControl("", [Validators.required]),
      partner: new FormControl("", [Validators.required])
    });

    this.filters = new FormGroup({
      company: new FormControl(""),
      search: new FormControl("")
    });

    this.filters.get("company").setValue(0);

    this.filters.valueChanges.subscribe(
      (): void => {
        this._page = 1;
        this._employees = [];
        this.loadEmployees();
      });
  }

  ngOnInit() {
    this.loadEmployees();
  }

  private loadEmployees(): void {
    this.employeesService.AvailablePartners({
      id: this.employeeId,
      Page: this._page,
      Size: this._size,
      Search: this.filters.get("search").value
    })
      .subscribe(
        (response: EmployeesResponse): void => {
          this._employees.push(...response.items);
        }
      );
  }

  public onScrollDown(): void {
    this._page++;
    this.loadEmployees();
  }

  public submit(): void {
    if (this.form.invalid) {
      return
    }

    this.employeesService.AddPartner({
      id: this.employeeId,
      model: {
        partnerId: this.form.get("partner").value,
        planId: this.form.get("plan").value
      }
    })
      .subscribe(
        (): void => {
          this.activeModal.close();
        }
      );
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public selected(employee: Employee): boolean {
    return this._selected && this._selected.id === employee.id;
  }

  public select(employee: Employee): void {
    this._selected = employee;
    this.form.get("partner").setValue(employee.id);
  }

  public employeesCompanies(employee: Employee): string {
    return employee.companies.join(", ");

  }

  public get employees(): Employee[] { return this._employees; }
  public get selectedPartner(): string { return this._selected ? `${this._selected.lastname} ${this._selected.firstname} ${this._selected.middlename}` : "" }
}
