import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'mp-patient-search',
  templateUrl: './patient-search.component.html',
  styleUrls: ['./patient-search.component.scss']
})
export class PatientSearchComponent implements OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() canSearch: boolean;
  @Input() canClear: boolean;

  @Output()
  onClear = new EventEmitter<void>();

  @Output()
  onSearch = new EventEmitter<string>();

  control = new FormControl("", []);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["canSearch"]) {
      changes["canSearch"].currentValue ? this.control.enable({ emitEvent: false }) : this.control.disable({ emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  search(): void {
    const value: string = this.control.value;
    this.control.setValue("");

    this.onSearch.emit(value);
  }

  clear = () => this.onClear.emit();
}
