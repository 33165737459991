/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Person } from '../models/person';
import { UpdatePersonRequest } from '../models/update-person-request';
import { SearchPatientsResult } from '../models/search-patients-result';
import { VisitsViewModel } from '../models/visits-view-model';
@Injectable({
  providedIn: 'root',
})
class CallCenterService extends __BaseService {
  static readonly PatientPath = '/api/v1/CallCenter/persons/{id}';
  static readonly UpdatePatientPath = '/api/v1/CallCenter/persons/{id}';
  static readonly FindPath = '/api/v1/CallCenter/patients';
  static readonly RecordsPath = '/api/v1/CallCenter/{id}/records';
  static readonly AssignmentsPath = '/api/v1/CallCenter/{id}/assignments';
  static readonly PhoneCallsPath = '/api/v1/CallCenter/{id}/phonecalls';
  static readonly ResultsPath = '/api/v1/CallCenter/{id}/results';
  static readonly UpdateVisitsPath = '/api/v1/CallCenter/{id}/visits';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  PatientResponse(id: number): __Observable<__StrictHttpResponse<Person>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/persons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Person>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Patient(id: number): __Observable<Person> {
    return this.PatientResponse(id).pipe(
      __map(_r => _r.body as Person)
    );
  }

  /**
   * @param params The `CallCenterService.UpdatePatientParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdatePatientResponse(params: CallCenterService.UpdatePatientParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/CallCenter/persons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CallCenterService.UpdatePatientParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdatePatient(params: CallCenterService.UpdatePatientParams): __Observable<null> {
    return this.UpdatePatientResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Phone undefined
   * @return Success
   */
  FindResponse(Phone?: string): __Observable<__StrictHttpResponse<Array<SearchPatientsResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Phone != null) __params = __params.set('Phone', Phone.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/patients`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SearchPatientsResult>>;
      })
    );
  }
  /**
   * @param Phone undefined
   * @return Success
   */
  Find(Phone?: string): __Observable<Array<SearchPatientsResult>> {
    return this.FindResponse(Phone).pipe(
      __map(_r => _r.body as Array<SearchPatientsResult>)
    );
  }

  /**
   * @param id undefined
   */
  RecordsResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/${id}/records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Records(id: number): __Observable<null> {
    return this.RecordsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  AssignmentsResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/${id}/assignments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Assignments(id: number): __Observable<null> {
    return this.AssignmentsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  PhoneCallsResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/${id}/phonecalls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  PhoneCalls(id: number): __Observable<null> {
    return this.PhoneCallsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ResultsResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/CallCenter/${id}/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Results(id: number): __Observable<null> {
    return this.ResultsResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `CallCenterService.UpdateVisitsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `models`:
   */
  UpdateVisitsResponse(params: CallCenterService.UpdateVisitsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.models;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/CallCenter/${params.id}/visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CallCenterService.UpdateVisitsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `models`:
   */
  UpdateVisits(params: CallCenterService.UpdateVisitsParams): __Observable<null> {
    return this.UpdateVisitsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CallCenterService {

  /**
   * Parameters for UpdatePatient
   */
  export interface UpdatePatientParams {
    id: number;
    request?: UpdatePersonRequest;
  }

  /**
   * Parameters for UpdateVisits
   */
  export interface UpdateVisitsParams {
    id: string;
    models?: Array<VisitsViewModel>;
  }
}

export { CallCenterService }
