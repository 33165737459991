import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { PromotionResolver } from './resolvers/promotion.resolver';
import { PromotionComponent } from './components/promotion/promotion.component';
import { PromotionsComponent } from './components/promotions/promotions.component';
import { ManagePromotionModalComponent } from './components/manage-promotion.modal/manage-promotion.modal.component';
import { ToastrModule } from 'ngx-toastr';
import { PermissionNames } from '../../models/permission-names';
import { PromotionsListItemComponent } from './components/promotions-list-item/promotions-list-item.component';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: PromotionResolver
    },
    component: PromotionComponent,
    data: {
      title: "Новый канал привлечения пациентов",
      requirements: [PermissionNames.EditPromotions]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: PromotionResolver
    },
    component: PromotionComponent,
    data: {
      title: "Канал привлечения пациентов",
      requirements: [PermissionNames.EditPromotions]
    }
  },
  {
    path: "",
    component: PromotionsComponent,
    data: {
      title: "Каналы привлечения пациентов",
      requirements: [PermissionNames.EditPromotions]
    }
  }
];

@NgModule({
  declarations: [
    PromotionComponent,
    PromotionsComponent,
    ManagePromotionModalComponent,
    PromotionsListItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManagePromotionModalComponent
  ]
})
export class PromotionSettingsModule { }
