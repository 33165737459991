import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Attending, Company, ReviewCandidateReveiw, ReviewCandidatesResponse, Speciality } from '../../../../generated/models';
import { ReviewCandidate } from '../../../../generated/models/review-candidate';
import { MyService, ReviewsService } from '../../../../generated/services';
import { ReviewsPayload } from '../../resolvers/reviews-resolver';
import { ReviewsFiltersModalComponent } from '../reviews-filters-modal/reviews-filters-modal.component';

import * as moment from "moment";
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { from, Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  host: { class: "page" }
})
export class ReviewsComponent implements OnInit {

  filtersSubject = new Subject<ReviewsFilters>();

  breadcrumbs: { url: string; title: string }[] = [
    { url: '/', title: 'Главная' },
    { url: '/quality-control', title: 'Клинико-экспертная работа' }
  ];

  title = 'Список протоколов';

  candidates: ReviewCandidate[] = [];

  companies: Company[] = [];
  specialities: Speciality[] = [];
  doctors: Attending[] = [];

  filters: ReviewsFilters = {};

  displayedFilters: { key: string, value: string }[] = [];

  page = 1;
  size = 35;

  loading = false;

  canReviewRecords = false;
  canViewWorkspace = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private reviewsService: ReviewsService,
    private userStorage: UserStorage,
    private myService: MyService,
    private toastrService: ToastrService
  ) {
    this.canReviewRecords = userStorage.hasPermission(PermissionNames.ReviewRecords);
    this.canViewWorkspace = userStorage.hasPermission(PermissionNames.ViewWorkspace);
  }

  async ngOnInit() {

    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const companies: number[] = queryMap.getAll("company").map(x => parseInt(x));
    const specialities: number[] = queryMap.getAll("speciality").map(x => parseInt(x));
    const doctors: number[] = this.canReviewRecords ?
      queryMap.getAll("doctor").map(x => parseInt(x))
      : [this.userStorage.profile.id];

    let gender: 0 | 1 | 2 = 0;
    switch (queryMap.get("patientGender")) {
      case "1": gender = 1; break;
      case "2": gender = 2; break;
    }

    const patientDob: { from: moment.Moment; to: moment.Moment } = { from: undefined, to: undefined };

    if (queryMap.has("dobFrom")) {
      patientDob.from = moment(queryMap.get("dobFrom"), "DD.MM.YYYY");
    }

    if (queryMap.has("dobTo")) {
      patientDob.to = moment(queryMap.get("dobTo"), "DD.MM.YYYY");
    }

    const visitDate: { from: moment.Moment; to: moment.Moment } = { from: undefined, to: undefined };

    if (queryMap.has("visitFrom")) {
      visitDate.from = moment(queryMap.get("visitFrom"), "DD.MM.YYYY");
    }

    if (queryMap.has("visitTo")) {
      visitDate.to = moment(queryMap.get("visitTo"), "DD.MM.YYYY");
    }

    const reviewDate: { from: moment.Moment; to: moment.Moment } = { from: undefined, to: undefined };

    if (queryMap.has("reviewedFrom")) {
      reviewDate.from = moment(queryMap.get("reviewedFrom"), "DD.MM.YYYY");
    }

    if (queryMap.has("reviewedTo")) {
      reviewDate.to = moment(queryMap.get("reviewedTo"), "DD.MM.YYYY");
    }

    this.activatedRoute.data.subscribe(async (data: { payload: ReviewsPayload }): Promise<void> => {
      this.companies = data.payload.companies;
      this.specialities = data.payload.specialities;
      this.doctors = this.canReviewRecords ?
        data.payload.doctors
        : data.payload.doctors.filter(x => x.id === this.userStorage.profile.id);

    });

    this.filters = {
      ...this.filters,
      companies: companies,
      specialities: specialities,
      doctors: doctors,
      patientName: queryMap.get("patientName"),
      patientGender: gender,
      patientDob: patientDob,
      visitDate: visitDate,
      reviewDate: reviewDate,

      onlyClosed: !queryMap.has("onlyClosed") || queryMap.get("onlyClosed") === 'true',

      onlyReviewed: (queryMap.has("onlyReviewd") && queryMap.get("onlyReviewd") === 'true') || !this.canReviewRecords,
      onlyUnrevised: queryMap.has("onlyUnrevised") || queryMap.get("onlyUnrevised") === 'true',

      isPreventive: queryMap.has("isPreventive") && queryMap.get("isPreventive") === 'true',
      onlyDrafts: queryMap.has("onlyDrafts") && queryMap.get("onlyDrafts") === 'true',

      diagnosis: queryMap.get('diagnosis')
    };

    this.filtersSubject
      .pipe(
        tap(() => {
          this.page = 1;
          this.loading = true;
          this.candidates = [];
          this.title = `Список протоколов`;
        }),
        switchMap(filters => this.reviewsService.CandidatesAsync({
          Page: this.page,
          Size: this.size,
          Companies: filters.companies,
          Doctors: filters.doctors,
          Specialities: filters.specialities,
          PatientName: filters.patientName,
          DobFrom: filters.patientDob && filters.patientDob.from && filters.patientDob.from.isValid() ?
            filters.patientDob.from.format("DD.MM.YYYY") : undefined,
          DobTo: filters.patientDob && filters.patientDob.to && filters.patientDob.to.isValid() ?
            filters.patientDob.to.format("DD.MM.YYYY") : undefined,

          VisitFrom: filters.visitDate && filters.visitDate.from && filters.visitDate.from.isValid() ?
            filters.visitDate.from.format("DD.MM.YYYY") : undefined,
          VisitTo: filters.visitDate && filters.visitDate.to && filters.visitDate.to.isValid() ?
            filters.visitDate.to.format("DD.MM.YYYY") : undefined,

          ReviewedFrom: filters.reviewDate && filters.reviewDate.from && filters.reviewDate.from.isValid() ?
            filters.reviewDate.from.format("DD.MM.YYYY") : undefined,
          ReviewedTo: filters.reviewDate && filters.reviewDate.to && filters.reviewDate.to.isValid() ?
            filters.reviewDate.to.format("DD.MM.YYYY") : undefined,

          Gender: filters.patientGender,

          OnlyClosed: filters.onlyClosed,
          OnlyReviewed: filters.onlyReviewed || !this.canReviewRecords,
          OnlyUnrevised: filters.onlyUnrevised,
          OnlyDrafts: filters.onlyDrafts,
          VisitReason: filters.isPreventive ? null : 0,
          Diagnosis: filters.diagnosis
        }).pipe(
          catchError((error: HttpErrorResponse): Observable<ReviewCandidatesResponse> => {
            console.error(error);
            this.toastrService.error('Не удалось загрузить список протоколов', 'Ошибка');

            return from([{ total: 0, items: [] }]);
          })
        ))
      ).subscribe((response: ReviewCandidatesResponse) => {
        this.candidates = response.items;
        this.loading = false;
        this.title = `Список протоколов (${response.total})`;
      });

  }

  isAuthor(candidate: ReviewCandidate): boolean {
    return candidate && candidate.authorId === this.userStorage.profile.id;
  }

  async onScrollDown(): Promise<void> {
    this.page++;
    const loaded = await this.load();
    this.candidates.push(...loaded.items);
  }

  async load(): Promise<{ items: ReviewCandidate[], total: number }> {
    this.loading = true;

    const response = await this.reviewsService.CandidatesAsync({
      Page: this.page,
      Size: this.size,
      Companies: this.filters.companies,
      Doctors: this.filters.doctors,
      Specialities: this.filters.specialities,
      PatientName: this.filters.patientName,
      DobFrom: this.filters.patientDob && this.filters.patientDob.from && this.filters.patientDob.from.isValid() ?
        this.filters.patientDob.from.format("DD.MM.YYYY") : undefined,
      DobTo: this.filters.patientDob && this.filters.patientDob.to && this.filters.patientDob.to.isValid() ?
        this.filters.patientDob.to.format("DD.MM.YYYY") : undefined,

      VisitFrom: this.filters.visitDate && this.filters.visitDate.from && this.filters.visitDate.from.isValid() ?
        this.filters.visitDate.from.format("DD.MM.YYYY") : undefined,
      VisitTo: this.filters.visitDate && this.filters.visitDate.to && this.filters.visitDate.to.isValid() ?
        this.filters.visitDate.to.format("DD.MM.YYYY") : undefined,

      ReviewedFrom: this.filters.reviewDate && this.filters.reviewDate.from && this.filters.reviewDate.from.isValid() ?
        this.filters.reviewDate.from.format("DD.MM.YYYY") : undefined,
      ReviewedTo: this.filters.reviewDate && this.filters.reviewDate.to && this.filters.reviewDate.to.isValid() ?
        this.filters.reviewDate.to.format("DD.MM.YYYY") : undefined,

      Gender: this.filters.patientGender,

      OnlyClosed: this.filters.onlyClosed,
      OnlyReviewed: this.filters.onlyReviewed || !this.canReviewRecords,
      OnlyUnrevised: this.filters.onlyUnrevised,
      OnlyDrafts: this.filters.onlyDrafts,
      VisitReason: this.filters.isPreventive ? null : 0,
      Diagnosis: this.filters.diagnosis
    }).toPromise();

    this.loading = false;

    return { items: response.items, total: response.total };
  }

  changeFilters(): void {
    const options: NgbModalOptions = { size: "lg", backdrop: "static" };
    const modalRef: NgbModalRef = this.modal.open(ReviewsFiltersModalComponent, options);
    const componentRef: ReviewsFiltersModalComponent = modalRef.componentInstance;

    componentRef.companies = this.companies;
    componentRef.specialities = this.specialities;
    componentRef.doctors = this.doctors;

    componentRef.filters = { ...this.filters };

    componentRef.onConfirm.subscribe(async (filters: ReviewsFilters): Promise<void> => {
      await this.filtersChanged(filters);
      modalRef.close();
    });

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

  }

  async filtersChanged(filters: ReviewsFilters): Promise<void> {
    this.filters = { ...filters };

    const parts: { key: string; value: string }[] = [];
    const params: Params = {};

    if (this.filters.companies && this.filters.companies.length > 0) {
      const names = this.companies.filter(x => this.filters.companies.includes(x.id)).map(x => x.name);
      parts.push({ key: "Медцентры", value: names.join(', ') });
      params['company'] = [...this.filters.companies];
    }

    if (this.filters.specialities && this.filters.specialities.length > 0) {
      const names = this.specialities.filter(x => this.filters.specialities.includes(x.id)).map(x => x.name);
      parts.push({ key: "Специальности", value: names.join(', ') });
      params['speciality'] = [...this.filters.specialities];
    }

    if (this.filters.doctors && this.filters.doctors.length > 0) {
      const names = this.doctors.filter(x => this.filters.doctors.includes(x.id)).map(x => x.name);
      parts.push({ key: "Специалисты", value: names.join(', ') });
      params['doctor'] = [...this.filters.doctors];
    }

    if (this.filters.patientName) {
      params["patientName"] = this.filters.patientName;
    }

    if (this.filters.patientGender > 0) {
      params["patientGender"] = this.filters.patientGender
    }

    if (this.filters.patientDob) {
      if (this.filters.patientDob.from && this.filters.patientDob.from.isValid()) {
        params["dobFrom"] = this.filters.patientDob.from.format("DD.MM.YYYY");
      }
      if (this.filters.patientDob.to && this.filters.patientDob.to.isValid()) {
        params["dobTo"] = this.filters.patientDob.to.format("DD.MM.YYYY");
      }
    }

    if (this.filters.visitDate) {
      if (this.filters.visitDate.from && this.filters.visitDate.from.isValid()) {
        params["visitFrom"] = this.filters.visitDate.from.format("DD.MM.YYYY");
      }
      if (this.filters.visitDate.to && this.filters.visitDate.to.isValid()) {
        params["visitTo"] = this.filters.visitDate.to.format("DD.MM.YYYY");
      }
    }

    if (this.filters.reviewDate) {
      if (this.filters.reviewDate.from && this.filters.reviewDate.from.isValid()) {
        params["reviewedFrom"] = this.filters.reviewDate.from.format("DD.MM.YYYY");
      }
      if (this.filters.reviewDate.to && this.filters.reviewDate.to.isValid()) {
        params["reviewedTo"] = this.filters.reviewDate.to.format("DD.MM.YYYY");
      }
    }

    if (!this.filters.onlyClosed) {
      params['onlyClosed'] = 'false';
    }

    if (this.filters.onlyReviewed) {
      params['onlyReviewd'] = 'true';
    }

    if (this.filters.onlyUnrevised) {
      params['onlyUnrevised'] = 'true';
    }

    if (this.filters.isPreventive) {
      params['isPreventive'] = 'true';
    }

    if (this.filters.onlyDrafts) {
      params['onlyDrafts'] = 'true';
    }

    if (filters.diagnosis) {
      params['diagnosis'] = filters.diagnosis;
    }

    this.displayedFilters = parts;
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });

    this.filtersSubject.next(filters);
  }

  openReview(candidate: ReviewCandidate, review: ReviewCandidateReveiw): void {
    window.open(`/quality-control/records/${candidate.recordId}/reviews/${review.id}`, "_blank");
  }

  printReview(review: ReviewCandidateReveiw): void {
    const parameters: { [key: string]: string } = {
      recordReviewId: `${review.id}`,
      includeRecord: 'true',
      name: 'RecordReviewReport'
    };

    this.getUrl(parameters, 'html', false).subscribe(x => this.download(x));
  }

  async addReview(candidate: ReviewCandidate): Promise<void> {
    const response = await this.reviewsService.RecordReviewsAsync(candidate.recordId).toPromise();

    const review = response.find(x => x.reviewerId === this.userStorage.profile.id);

    if (review) {
      window.open(`/quality-control/records/${candidate.recordId}/reviews/${review.id}`, "_blank");
    } else {
      window.open(`/quality-control/records/${candidate.recordId}/reviews/new`, "_blank");
    }
  }

  getUrl(parameters: { [key: string]: string }, type: string, download = false): Observable<string> {
    return this.myService.AccessToken({
      parameters: parameters
    }).pipe(
      map(response => `api/v1/Reports/Prepared?token=${response.token}&type=${type}${(download ? '&download=true' : '')}`)
    );
  }

  download(url: string): void {
    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = url;

    document.body.append(frame);
    frame.contentWindow.print();
  }
}

export interface ReviewsFilters {
  companies?: number[];
  specialities?: number[];
  doctors?: number[];
  patientName?: string;
  patientDob?: {
    from: moment.Moment;
    to: moment.Moment;
  };
  visitDate?: {
    from: moment.Moment;
    to: moment.Moment;
  };
  reviewDate?: {
    from: moment.Moment;
    to: moment.Moment;
  };
  patientGender?: 0 | 1 | 2;
  onlyClosed?: boolean;
  onlyReviewed?: boolean;
  isPreventive?: boolean;
  onlyUnrevised?: boolean;
  onlyDrafts?: boolean;
  diagnosis?: string;
}
