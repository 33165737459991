import { Component, Input, Output, EventEmitter, OnInit, HostListener, OnDestroy } from "@angular/core";

import { PrescriptionsService, MyService, PriceSetsService, MyFavoritesService, MyRecentService } from 'projects/Clinic/src/app/generated/services';
import { SectionItem, SubsectionItem, PrescriptionItem, FavoritePrescriptionItem, PrescriptionService, ClinicalRecommendation, SectionsServicePrescription, Manipulation, EmployeeFavoriteManipulation, CreateMyFavoriteManipulationResponse, EmployeeRecentManipulation } from 'projects/Clinic/src/app/generated/models';
import { FormControl, FormGroup } from '@angular/forms';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Subject, BehaviorSubject, Observable, of, from } from 'rxjs';
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { moveItemInArray } from "@angular/cdk/drag-drop";
import { PrescriptionOrderChangedEvent } from "../selected-prescriptions/selected-prescriptions.component";

@Component({
  selector: "mp-referrals-modal",
  templateUrl: "./referrals-modal.component.html",
  styleUrls: ["./referrals-modal.component.scss"]
})

export class ReferralsModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  favouritesChange$ = new Subject<void>();

  recent: EmployeeRecentManipulation[] = [];

  clinicalRecommendations: ClinicalRecommendation[] = [];
  qualityControlRecommendations: ClinicalRecommendation[] = [];

  recommendations: ClinicalRecommendation[] = [];
  allTypes: SectionItem[] = [];
  allClasses: SubsectionItem[] = [];
  allReferrals: PrescriptionItem[] = [];

  searchDropdownClasses: SubsectionItem[] = [];

  filteredReferrals: PrescriptionItem[] = [];
  searchedReferrals: PrescriptionItem[] = [];
  searchResults: Array<{ type: SectionItem, class: SubsectionItem, results: Array<PrescriptionItem> }> = [];

  selectedReferrals: SectionsServicePrescription[] = [];
  arrayToDelete: FavoritePrescriptionItem[] = [];

  favourites: EmployeeFavoriteManipulation[] = [];
  private _favourites$ = new BehaviorSubject<EmployeeFavoriteManipulation[]>(null);

  services: PrescriptionService[] = [];
  private _services$ = new BehaviorSubject<PrescriptionService[]>(null);

  servicesSize = 50;

  currentSection = 1;
  recentLoading = false;

  loading = false;

  stars: { [id: number]: boolean } = {};
  prices: { [id: number]: number } = {};

  priceSetId: number;

  get favourites$(): Observable<FavoritePrescriptionItem[]> { return this._favourites$.pipe(filter(x => x !== null)); }
  get services$(): Observable<PrescriptionService[]> { return this._services$.pipe(filter(x => x !== null)); }

  @Input() title = '';
  @Input("selected") selectedItems: SectionsServicePrescription[] = [];

  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<PrescriptionItem[]>();

  favoriteForm = new FormGroup({});

  manipulationsFilters = new FormGroup({
    search: new FormControl('')
  });

  constructor(
    private prescriptionsService: PrescriptionsService,
    private myService: MyService,
    private priceSetsService: PriceSetsService,
    private toastrService: ToastrService,
    private myFavoritesService: MyFavoritesService,
    private myRecentService: MyRecentService
  ) { }

  async ngOnInit() {
    this.selectedReferrals = (this.selectedItems || []).sort((a, b) => a.listOrder - b.listOrder).map(x => x);
    this.loadFavoriteManipulations();

    const response = await this.priceSetsService.DefaultPrices().toPromise();

    response.items.forEach(x => this.prices[x.serviceId] = x.amount);

    this.recentLoading = true;
    try {
      this.recent = await this.myRecentService.MyRecentManipulationsAsync().toPromise();
    } catch (e) {
      this.toastrService.error('Не удалось загрузить последние использованные назначения', 'Ошибка');
    }
    this.recentLoading = false;

    this.manipulationsFilters.patchValue({});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  deleteFromSelected(item: SectionsServicePrescription): void {
    const index = this.selectedReferrals.findIndex(x => x.name === item.name);

    if (index !== -1) this.selectedReferrals.splice(index, 1);
  }

  reorderSelected = (event: PrescriptionOrderChangedEvent) => moveItemInArray(this.selectedReferrals, event.previousIndex, event.currentIndex);

  cancel = () => this.onClose.emit();

  ok(): void {
    this.selectedReferrals.forEach((x, index) => x.listOrder = index);
    this.onSubmit.emit(this.selectedReferrals);
  }

  sectionShown = (index: number) => this.currentSection === index;
  isCurrentSection = x => this.currentSection === x;

  changeSection(num: number) {
    if (num === this.currentSection) return;
    this.currentSection = num;
  }

  toggleFavoriteManipulation(item: Manipulation) {
    const index = this.favourites.findIndex(x => x.manipulationId === item.id);

    if (index === -1) {
      this.myFavoritesService.CreateMyFavoriteManipulationAsync({ manipulationId: item.id })
        .subscribe(
          (response: CreateMyFavoriteManipulationResponse) => {
            this.loadFavoriteManipulations();

            this.toastrService.success(`Назначение '${item.name}' добавлено в избранное`, 'Успешно');
          },
          (response: HttpErrorResponse) => {
            this.toastrService.error('Не удалось добавить назначение в избранное', 'Ошибка');
          }
        );
    } else {
      this.myFavoritesService.RemoveMyFavoriteManipulationAsync(this.favourites[index].id)
        .subscribe(
          () => {
            this.loadFavoriteManipulations();
            this.toastrService.success(`Назначение '${item.name}' удалено из избранного`, 'Успешно')
          },
          (response: HttpErrorResponse) => {
            this.toastrService.error('Не удалось удалить назначение из избранного', 'Ошибка');
          }
        );
    }
  }

  updateFavorites(items: EmployeeFavoriteManipulation[]) {
    this.loading = true;
    this.favourites = [];

    this.myFavoritesService.UpdateMyFavoriteManipulationsAsync({ items: items.map((x, i) => ({ id: x.id, index: i })) })
      .subscribe(
        () => {
          this.toastrService.success('Список избранных назначений обновлен', 'Успешно');

          this.loadFavoriteManipulations();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось обновить список избранных назначений', 'Ошибка');

          this.loadFavoriteManipulations();
        }
      );

  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) this.ok();
  }

  loadFavoriteManipulations(): void {
    this.loading = true;

    this.myFavoritesService.MyFavoriteManipulationsAsync()
      .subscribe(
        (response: EmployeeFavoriteManipulation[]): void => {
          response.forEach(x => this.stars[x.manipulationId] = true);

          this.favourites = response;
          this.loading = false;

          this.favouritesChange$.next();
        }
      );
  }

  selectFavourite(item: EmployeeFavoriteManipulation) {
    if (!this.selectedReferrals.some(x => x.name === item.manipulationName)) {
      this.selectedReferrals.push({ name: item.manipulationName, manipulationId: item.manipulationId });
    }
  }

  selectRecommendation(item: ClinicalRecommendation) {
    if (!this.selectedReferrals.some(x => x.name === item.manipulationName)) {
      this.selectedReferrals.push({ name: item.manipulationName, manipulationId: item.manipulationId });
    }
  }

  selectRecent(item: EmployeeRecentManipulation): void {
    if (!this.selectedReferrals.some(x => x.name === item.manipulationName)) {
      this.selectedReferrals.push({ name: item.manipulationName, manipulationId: item.manipulationId });
    }
  }

  selectManipulation(item: Manipulation) {
    if (!this.selectedReferrals.some(x => x.name === item.name)) {
      this.selectedReferrals.push({ name: item.name, manipulationId: item.id });
    }

  }
}
