import { Component, OnInit, Input } from '@angular/core';
import { PersonsVisit } from '../../../../generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-visit-edit-modal',
  templateUrl: './visit-edit-modal.component.html',
  styleUrls: ['./visit-edit-modal.component.scss'],
  host: { class: "modal-default" }
})
export class VisitEditModalComponent implements OnInit {
  @Input() visit: PersonsVisit

  form = new FormGroup({
    phone: new FormControl(""),
    lastName: new FormControl("")
  });
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.form.patchValue({ ...this.visit });
  }

  ok = () => this.activeModal.close(this.form.getRawValue());
  dismiss = () => this.activeModal.dismiss();
}

