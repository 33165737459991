import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonTaxDeduction, TaxDeductionCandidate } from '../../../../generated/models';
import { PeopleService, TaxDeductionsService } from '../../../../generated/services';

import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';

@Component({
  selector: 'mp-tax-deductions-modal',
  templateUrl: './tax-deductions-modal.component.html',
  styleUrls: ['./tax-deductions-modal.component.scss']
})
export class TaxDeductionsModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() patientName = '';
  @Input() patientInn = '';
  @Input() patientId: number;
  @Input() personId: number;

  @Output() onCancel = new EventEmitter<void>();

  candidates: TaxDeductionCandidate[] = [];
  selected: TaxDeductionCandidate[] = [];

  groups: PersonTaxDeduction[] = [];

  deductionsLoading = false;
  loading = false;
  processing = false;
  tab = 0;

  canRemove = false;

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  filters = new FormGroup({
    period: new FormControl({
      from: moment().add(-1, 'year').startOf('year'),
      to: moment().add(-1, 'year').endOf('year')
    })
  });

  form = new FormGroup({
    payerName: new FormControl('', [Validators.required]),
    payerInn: new FormControl('', [Validators.required])
  });

  get displayTotal(): string { return this.selected.map(x => x.total).reduce((a, b) => a + b, 0).toLocaleString('ru-RU', this.currencyFormat); }

  get differentCompanies(): number { return this.selected.filter((x, i) => i === this.selected.findIndex(y => x.companyShortName == y.companyShortName)).length; }

  constructor(
    private userSrorage: UserStorage,
    private taxDeductionsService: TaxDeductionsService,
    private confirmationsService: ConfirmationModalService,
    private toastrService: ToastrService,
    private peopleService: PeopleService) {

    this.canRemove = userSrorage.hasPermission(PermissionNames.RemoveTaxDeductions);

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => { this.loading = true; }),
        switchMap((value: FiltersValue) => this.taxDeductionsService.PersonTaxDeductionCandidatesAsync({
          id: this.personId,
          From: value.period && value.period.from && value.period.from.isValid() ? value.period.from.format('DD.MM.YYYY') : '',
          To: value.period && value.period.to && value.period.to.isValid() ? value.period.to.format('DD.MM.YYYY') : '',
        })),
        tap(() => { this.loading = false; }),
      ).subscribe((response: TaxDeductionCandidate[]): void => {
        this.candidates = response;
        this.selected = [];
        this.candidates
          .filter(x => !x.receiptNotPrinted && x.debt === 0 && !x.hash)
          .forEach(x => this.change(x, true));
      });
  }

  ngOnInit() {
    this.loading = true;

    const value: FiltersValue = this.filters.getRawValue();

    this.taxDeductionsService.PersonTaxDeductionCandidatesAsync({
      id: this.personId,
      From: value.period.from.format('DD.MM.YYYY'),
      To: value.period.to.format('DD.MM.YYYY')
    })
      .subscribe(
        (response: TaxDeductionCandidate[]): void => {
          this.candidates = response;

          this.selected = [];
          this.candidates
            .filter(x => !x.receiptNotPrinted && x.debt === 0)
            .forEach(x => this.change(x, true));

          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );

    this.loadDeductions();

    document.getElementById('Dummy').focus();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  selectTab = (tab: number) => this.tab = tab;
  tabSelected = (tab: number) => this.tab === tab;

  change(candidate: TaxDeductionCandidate, value: boolean) {
    if (candidate.hash) return;

    if (!value) {
      this.selected = this.selected.filter(x => x.id !== candidate.id);
    } else {
      if (this.selected.length === 0) {
        if (candidate.parentName) {
          this.form.patchValue({
            payerName: candidate.parentName,
            payerInn: candidate.parentInn
          });
        } else {
          this.form.patchValue({
            payerName: candidate.patientName,
            payerInn: candidate.patientInn
          });
        }
      }

      this.selected = [...this.selected, candidate];
    }
  }

  async loadDeductions() {
    this.deductionsLoading = true;

    try {
      this.groups = await this.taxDeductionsService.PersonTaxDeductionsAsync(this.personId).toPromise();

      this.candidates.forEach(x => {
        x.hash = null;
        x.printed = null;
      });

      for (const item of this.groups) {
        for (const visit of item.visits) {
          const candidate = this.candidates.find(x => x.id === visit);

          if (candidate) {
            candidate.printed = item.created;
            candidate.hash = item.hash;
          }
        }
      }

    } catch (e) {
      this.toastrService.error('Не удалось загрузить архив справок', 'Ошибка');
    }

    this.deductionsLoading = false;
  }

  toggle(candidate: TaxDeductionCandidate): void {
    this.change(candidate, !this.isSelected(candidate))
  }

  isSelected(candidate: TaxDeductionCandidate): boolean {
    return this.selected.some(x => x.id === candidate.id);
  }

  cancel(): void {
    this.onCancel.emit();
  }

  async printDeductionForVisit(candidate: TaxDeductionCandidate) {
    if (!candidate || !candidate.hash) return;

    await this.printTaxDeduction({ hash: candidate.hash });
  }

  async printTaxDeduction(item: PersonTaxDeduction) {
    try {
      const response = await this.taxDeductionsService.PersonTaxDeductionContentAsync({ personId: this.personId, hash: item.hash }).toPromise();

      const url = URL.createObjectURL(response);

      const frame: HTMLIFrameElement = document.createElement("iframe");

      frame.style.display = "none";
      frame.src = url;

      frame.onload = () => {
        URL.revokeObjectURL(url);
        console.debug("object removed");
      }

      document.body.append(frame);
      frame.contentWindow.print();
    } catch (e) {
      this.toastrService.error('Не удалось загрузить справку', 'Ошибка');
    }
  }

  async removeTaxDeduction(item: PersonTaxDeduction) {
    const confirmed = await this.confirmationsService.open({ message: 'Выданная справка будет удалена. Продолжить?', confirmBtnText: 'Удалить' });

    if (!confirmed) return;

    try {
      await this.taxDeductionsService.DeletePersonTaxDeductionAsync({ personId: this.personId, hash: item.hash }).toPromise();
      this.toastrService.success('Выданная справка удалена', 'Успешно');
    }
    catch (e) {
      this.toastrService.error('Не удалось удалить справку', 'Ошибка');
      return;
    }

    await this.loadDeductions();
  }

  async create(): Promise<void> {
    Object.entries(this.form.controls).forEach(x => x[1].markAsDirty());

    if (this.form.invalid) return;

    if (this.selected.length === 0) return;

    if (this.processing) return;

    this.processing = true;

    const value: FormValue = this.form.getRawValue();

    try {
      const response = await this.taxDeductionsService.CreatePersonTaxDeductionAsync({
        id: this.personId,
        request: {
          payerInn: value.payerInn,
          payerName: value.payerName,
          visits: this.selected.map(x => x.id)
        }
      }).toPromise();

      this.print(response.content, response.contentType);
      this.processing = false;

      this.loadDeductions();

    } catch (e) {
      this.processing = false;

      const response = e as HttpErrorResponse;

      if (response.status === 400) {
        for (const error of response.error.errors) {
          switch (error.status) {
            case 1: this.toastrService.error('Необходимо указать имя плательщика', 'Ошибка'); break;
            case 2: this.toastrService.error('Для некоторых услуг не указана цена', 'Ошибка'); break;
            case 3: this.toastrService.error('Пациент не найден', 'Ошибка'); break;
            case 4: this.toastrService.error('Необходимо указать ИНН плательщика', 'Ошибка'); break;
            case 5: this.toastrService.error('Необходимо выбрать посещения', 'Ошибка'); break;
            case 6: this.toastrService.error('Выбраны посещения с долгом', 'Ошибка'); break;
            case 7: this.toastrService.error('Необходимо указать ИНН организации', 'Ошибка'); break;
            case 9: this.toastrService.error('Справка для налогового вычета не найдена', 'Ошибка'); break;
            case 8: this.toastrService.error('Для некоторых посещений уже выданы справки', 'Ошибка'); break;
            case -1: this.toastrService.error('Для выполнения операции требуется разрешение', 'Ошибка'); break;

            default: this.toastrService.error('Не удалось сформировать справку', 'Ошибка'); break;
          }
        }
        return;
      }

      this.toastrService.error('Не удалось сформировать справку', 'Ошибка');
      return;
    }

    await this.loadDeductions();
  }


  private print(content: string, type: string) {
    const byteCharacters = atob(content);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: type });

    const url = URL.createObjectURL(blob);

    const frame: HTMLIFrameElement = document.createElement("iframe");

    frame.style.display = "none";
    frame.src = url;

    frame.onload = () => {
      URL.revokeObjectURL(url);
      console.debug("object removed");
    }

    document.body.append(frame);
    frame.contentWindow.print();
  }
}

interface FiltersValue {
  period: {
    from: moment.Moment;
    to: moment.Moment;
  }
}

interface FormValue {
  payerName?: string;
  payerInn?: string;
}

interface CompanyGroup {
  name: string;
  inn: string;
  deductions: PersonTaxDeduction[];
}
