import { Component, OnInit, Input } from '@angular/core';
import { Speciality } from 'projects/Clinic/src/app/generated/models/speciality';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';
import { EmployeeSpeciality } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-employee-speciality-modal',
  templateUrl: './employee-speciality.modal.component.html',
  styleUrls: ['./employee-speciality.modal.component.scss']
})
export class EmployeeSpecialityModalComponent implements OnInit {
  public title: string;

  @Input("employeeId") employeeId: number;
  @Input("specialities") specialities: Speciality[];

  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private employeesService: EmployeesService
  ) {
    this.title = "Новая специальность";

    this.form = new FormGroup({
      speciality: new FormControl("", [Validators.required])
    });
  }

  ngOnInit() { }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.employeesService.AddSpeciality({
      id: this.employeeId,
      model: {
        specialityId: this.form.get("speciality").value
      }
    })
      .subscribe(
        (result: EmployeeSpeciality): void => {
          this.activeModal.close();
        });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
