import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company } from '../../../../generated/models';
import { ReportsFiltersValue } from '../reports/reports.component';

@Component({
  selector: 'mp-reports-filters',
  templateUrl: './reports-filters.component.html',
  styleUrls: ['./reports-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportsFiltersComponent implements OnInit, OnChanges {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input()
  companies: Company[] = [];
  @Input()
  filters: ReportsFiltersValue = {};

  @Output()
  onFiltersChanged: EventEmitter<ReportsFiltersValue> = new EventEmitter<ReportsFiltersValue>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      dateRange: new FormControl({ from: moment(), to: moment() }, [])
    });

    this.form.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: { dateRange: { from: moment.Moment; to: moment.Moment } }): void => {
        console.info('change')
        const filters: ReportsFiltersValue = { ...this.filters, dateRange: value.dateRange };
        this.onFiltersChanged.emit(filters);
      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["filters"]) {
      console.info('changed')
      const value: ReportsFiltersValue = changes["filters"].currentValue;

      this.form.patchValue(value, { emitEvent: false });

    }
  }

  companySelected(company: Company): boolean {
    return this.filters.selectedCompanies.some(x => x.id === company.id);
  }

  toggleCompany(company: Company, selected: boolean): void {
    if (selected) {
      const value: ReportsFiltersValue = {
        ...this.filters,
        selectedCompanies: [...this.filters.selectedCompanies, company]
      };

      this.onFiltersChanged.emit(value);
    }
    else {
      const value: ReportsFiltersValue = {
        ...this.filters,
        selectedCompanies: this.filters.selectedCompanies.filter(x => x.id !== company.id)
      };

      this.onFiltersChanged.emit(value);
    }
  }
}
