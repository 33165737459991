/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RecordReviewRate } from '../models/record-review-rate';
import { RecordReviewDefectDescription } from '../models/record-review-defect-description';
import { RecordReviewDescription } from '../models/record-review-description';
import { ReviewCandidate } from '../models/review-candidate';
import { ReviewCandidatesResponse } from '../models/review-candidates-response';
import { RecordReviewSummary } from '../models/record-review-summary';
import { CreateRecordReviewResponse } from '../models/create-record-review-response';
import { CreateRecordReviewRequest } from '../models/create-record-review-request';
import { RecordReview } from '../models/record-review';
import { UpdateRecordReviewRequest } from '../models/update-record-review-request';
@Injectable({
  providedIn: 'root',
})
class ReviewsService extends __BaseService {
  static readonly RatesPath = '/api/v1/Reviews/rates';
  static readonly DefectsPath = '/api/v1/Reviews/defects';
  static readonly DescriptionAsyncPath = '/api/v1/Reviews/description';
  static readonly CandidatesPath = '/api/v1/Reviews/candidates';
  static readonly CandidatesAsyncPath = '/api/v2/reviews/candidates';
  static readonly RecordReviewsAsyncPath = '/api/v1/records/{recordId}/reviews';
  static readonly CreateRecordReviewAsyncPath = '/api/v1/records/{recordId}/reviews';
  static readonly ReviewAsyncPath = '/api/v1/Reviews/{id}';
  static readonly UpdateRecordReviewAsyncPath = '/api/v1/Reviews/{id}';
  static readonly ReviseReviewAsyncPath = '/api/v1/Reviews/reviews/{id}/revise';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  RatesResponse(): __Observable<__StrictHttpResponse<Array<RecordReviewRate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/rates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecordReviewRate>>;
      })
    );
  }
  /**
   * @return Success
   */
  Rates(): __Observable<Array<RecordReviewRate>> {
    return this.RatesResponse().pipe(
      __map(_r => _r.body as Array<RecordReviewRate>)
    );
  }

  /**
   * @return Success
   */
  DefectsResponse(): __Observable<__StrictHttpResponse<RecordReviewDefectDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/defects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordReviewDefectDescription>;
      })
    );
  }
  /**
   * @return Success
   */
  Defects(): __Observable<RecordReviewDefectDescription> {
    return this.DefectsResponse().pipe(
      __map(_r => _r.body as RecordReviewDefectDescription)
    );
  }

  /**
   * @return Success
   */
  DescriptionAsyncResponse(): __Observable<__StrictHttpResponse<RecordReviewDescription>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/description`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordReviewDescription>;
      })
    );
  }
  /**
   * @return Success
   */
  DescriptionAsync(): __Observable<RecordReviewDescription> {
    return this.DescriptionAsyncResponse().pipe(
      __map(_r => _r.body as RecordReviewDescription)
    );
  }

  /**
   * @param params The `ReviewsService.CandidatesParams` containing the following parameters:
   *
   * - `VisitTo`:
   *
   * - `VisitReason`:
   *
   * - `VisitFrom`:
   *
   * - `Specialities`:
   *
   * - `Size`:
   *
   * - `ReviewedTo`:
   *
   * - `ReviewedFrom`:
   *
   * - `PatientName`:
   *
   * - `Page`:
   *
   * - `OnlyUnrevised`:
   *
   * - `OnlyReviewed`:
   *
   * - `OnlyDrafts`:
   *
   * - `OnlyClosed`:
   *
   * - `Gender`:
   *
   * - `Doctors`:
   *
   * - `DobTo`:
   *
   * - `DobFrom`:
   *
   * - `Diagnosis`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  CandidatesResponse(params: ReviewsService.CandidatesParams): __Observable<__StrictHttpResponse<Array<ReviewCandidate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.VisitTo != null) __params = __params.set('VisitTo', params.VisitTo.toString());
    if (params.VisitReason != null) __params = __params.set('VisitReason', params.VisitReason.toString());
    if (params.VisitFrom != null) __params = __params.set('VisitFrom', params.VisitFrom.toString());
    (params.Specialities || []).forEach(val => {if (val != null) __params = __params.append('Specialities', val.toString())});
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.ReviewedTo != null) __params = __params.set('ReviewedTo', params.ReviewedTo.toString());
    if (params.ReviewedFrom != null) __params = __params.set('ReviewedFrom', params.ReviewedFrom.toString());
    if (params.PatientName != null) __params = __params.set('PatientName', params.PatientName.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OnlyUnrevised != null) __params = __params.set('OnlyUnrevised', params.OnlyUnrevised.toString());
    if (params.OnlyReviewed != null) __params = __params.set('OnlyReviewed', params.OnlyReviewed.toString());
    if (params.OnlyDrafts != null) __params = __params.set('OnlyDrafts', params.OnlyDrafts.toString());
    if (params.OnlyClosed != null) __params = __params.set('OnlyClosed', params.OnlyClosed.toString());
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    (params.Doctors || []).forEach(val => {if (val != null) __params = __params.append('Doctors', val.toString())});
    if (params.DobTo != null) __params = __params.set('DobTo', params.DobTo.toString());
    if (params.DobFrom != null) __params = __params.set('DobFrom', params.DobFrom.toString());
    if (params.Diagnosis != null) __params = __params.set('Diagnosis', params.Diagnosis.toString());
    (params.Companies || []).forEach(val => {if (val != null) __params = __params.append('Companies', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/candidates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReviewCandidate>>;
      })
    );
  }
  /**
   * @param params The `ReviewsService.CandidatesParams` containing the following parameters:
   *
   * - `VisitTo`:
   *
   * - `VisitReason`:
   *
   * - `VisitFrom`:
   *
   * - `Specialities`:
   *
   * - `Size`:
   *
   * - `ReviewedTo`:
   *
   * - `ReviewedFrom`:
   *
   * - `PatientName`:
   *
   * - `Page`:
   *
   * - `OnlyUnrevised`:
   *
   * - `OnlyReviewed`:
   *
   * - `OnlyDrafts`:
   *
   * - `OnlyClosed`:
   *
   * - `Gender`:
   *
   * - `Doctors`:
   *
   * - `DobTo`:
   *
   * - `DobFrom`:
   *
   * - `Diagnosis`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  Candidates(params: ReviewsService.CandidatesParams): __Observable<Array<ReviewCandidate>> {
    return this.CandidatesResponse(params).pipe(
      __map(_r => _r.body as Array<ReviewCandidate>)
    );
  }

  /**
   * @param params The `ReviewsService.CandidatesAsyncParams` containing the following parameters:
   *
   * - `VisitTo`:
   *
   * - `VisitReason`:
   *
   * - `VisitFrom`:
   *
   * - `Specialities`:
   *
   * - `Size`:
   *
   * - `ReviewedTo`:
   *
   * - `ReviewedFrom`:
   *
   * - `PatientName`:
   *
   * - `Page`:
   *
   * - `OnlyUnrevised`:
   *
   * - `OnlyReviewed`:
   *
   * - `OnlyDrafts`:
   *
   * - `OnlyClosed`:
   *
   * - `Gender`:
   *
   * - `Doctors`:
   *
   * - `DobTo`:
   *
   * - `DobFrom`:
   *
   * - `Diagnosis`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  CandidatesAsyncResponse(params: ReviewsService.CandidatesAsyncParams): __Observable<__StrictHttpResponse<ReviewCandidatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.VisitTo != null) __params = __params.set('VisitTo', params.VisitTo.toString());
    if (params.VisitReason != null) __params = __params.set('VisitReason', params.VisitReason.toString());
    if (params.VisitFrom != null) __params = __params.set('VisitFrom', params.VisitFrom.toString());
    (params.Specialities || []).forEach(val => {if (val != null) __params = __params.append('Specialities', val.toString())});
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.ReviewedTo != null) __params = __params.set('ReviewedTo', params.ReviewedTo.toString());
    if (params.ReviewedFrom != null) __params = __params.set('ReviewedFrom', params.ReviewedFrom.toString());
    if (params.PatientName != null) __params = __params.set('PatientName', params.PatientName.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.OnlyUnrevised != null) __params = __params.set('OnlyUnrevised', params.OnlyUnrevised.toString());
    if (params.OnlyReviewed != null) __params = __params.set('OnlyReviewed', params.OnlyReviewed.toString());
    if (params.OnlyDrafts != null) __params = __params.set('OnlyDrafts', params.OnlyDrafts.toString());
    if (params.OnlyClosed != null) __params = __params.set('OnlyClosed', params.OnlyClosed.toString());
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    (params.Doctors || []).forEach(val => {if (val != null) __params = __params.append('Doctors', val.toString())});
    if (params.DobTo != null) __params = __params.set('DobTo', params.DobTo.toString());
    if (params.DobFrom != null) __params = __params.set('DobFrom', params.DobFrom.toString());
    if (params.Diagnosis != null) __params = __params.set('Diagnosis', params.Diagnosis.toString());
    (params.Companies || []).forEach(val => {if (val != null) __params = __params.append('Companies', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/reviews/candidates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewCandidatesResponse>;
      })
    );
  }
  /**
   * @param params The `ReviewsService.CandidatesAsyncParams` containing the following parameters:
   *
   * - `VisitTo`:
   *
   * - `VisitReason`:
   *
   * - `VisitFrom`:
   *
   * - `Specialities`:
   *
   * - `Size`:
   *
   * - `ReviewedTo`:
   *
   * - `ReviewedFrom`:
   *
   * - `PatientName`:
   *
   * - `Page`:
   *
   * - `OnlyUnrevised`:
   *
   * - `OnlyReviewed`:
   *
   * - `OnlyDrafts`:
   *
   * - `OnlyClosed`:
   *
   * - `Gender`:
   *
   * - `Doctors`:
   *
   * - `DobTo`:
   *
   * - `DobFrom`:
   *
   * - `Diagnosis`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  CandidatesAsync(params: ReviewsService.CandidatesAsyncParams): __Observable<ReviewCandidatesResponse> {
    return this.CandidatesAsyncResponse(params).pipe(
      __map(_r => _r.body as ReviewCandidatesResponse)
    );
  }

  /**
   * @param recordId undefined
   * @return Success
   */
  RecordReviewsAsyncResponse(recordId: number): __Observable<__StrictHttpResponse<Array<RecordReviewSummary>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/records/${recordId}/reviews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecordReviewSummary>>;
      })
    );
  }
  /**
   * @param recordId undefined
   * @return Success
   */
  RecordReviewsAsync(recordId: number): __Observable<Array<RecordReviewSummary>> {
    return this.RecordReviewsAsyncResponse(recordId).pipe(
      __map(_r => _r.body as Array<RecordReviewSummary>)
    );
  }

  /**
   * @param params The `ReviewsService.CreateRecordReviewAsyncParams` containing the following parameters:
   *
   * - `recordId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateRecordReviewAsyncResponse(params: ReviewsService.CreateRecordReviewAsyncParams): __Observable<__StrictHttpResponse<CreateRecordReviewResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/records/${params.recordId}/reviews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateRecordReviewResponse>;
      })
    );
  }
  /**
   * @param params The `ReviewsService.CreateRecordReviewAsyncParams` containing the following parameters:
   *
   * - `recordId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateRecordReviewAsync(params: ReviewsService.CreateRecordReviewAsyncParams): __Observable<CreateRecordReviewResponse> {
    return this.CreateRecordReviewAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateRecordReviewResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ReviewAsyncResponse(id: number): __Observable<__StrictHttpResponse<RecordReview>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RecordReview>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ReviewAsync(id: number): __Observable<RecordReview> {
    return this.ReviewAsyncResponse(id).pipe(
      __map(_r => _r.body as RecordReview)
    );
  }

  /**
   * @param params The `ReviewsService.UpdateRecordReviewAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateRecordReviewAsyncResponse(params: ReviewsService.UpdateRecordReviewAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/Reviews/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReviewsService.UpdateRecordReviewAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateRecordReviewAsync(params: ReviewsService.UpdateRecordReviewAsyncParams): __Observable<null> {
    return this.UpdateRecordReviewAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ReviewsService.ReviseReviewAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ReviseReviewAsyncResponse(params: ReviewsService.ReviseReviewAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.request != null) __params = __params.set('request', params.request.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reviews/reviews/${params.id}/revise`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReviewsService.ReviseReviewAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ReviseReviewAsync(params: ReviewsService.ReviseReviewAsyncParams): __Observable<null> {
    return this.ReviseReviewAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReviewsService {

  /**
   * Parameters for Candidates
   */
  export interface CandidatesParams {
    VisitTo?: string;
    VisitReason?: any;
    VisitFrom?: string;
    Specialities?: Array<number>;
    Size?: number;
    ReviewedTo?: string;
    ReviewedFrom?: string;
    PatientName?: string;
    Page?: number;
    OnlyUnrevised?: boolean;
    OnlyReviewed?: boolean;
    OnlyDrafts?: boolean;
    OnlyClosed?: boolean;
    Gender?: any;
    Doctors?: Array<number>;
    DobTo?: string;
    DobFrom?: string;
    Diagnosis?: string;
    Companies?: Array<number>;
  }

  /**
   * Parameters for CandidatesAsync
   */
  export interface CandidatesAsyncParams {
    VisitTo?: string;
    VisitReason?: any;
    VisitFrom?: string;
    Specialities?: Array<number>;
    Size?: number;
    ReviewedTo?: string;
    ReviewedFrom?: string;
    PatientName?: string;
    Page?: number;
    OnlyUnrevised?: boolean;
    OnlyReviewed?: boolean;
    OnlyDrafts?: boolean;
    OnlyClosed?: boolean;
    Gender?: any;
    Doctors?: Array<number>;
    DobTo?: string;
    DobFrom?: string;
    Diagnosis?: string;
    Companies?: Array<number>;
  }

  /**
   * Parameters for CreateRecordReviewAsync
   */
  export interface CreateRecordReviewAsyncParams {
    recordId: number;
    request?: CreateRecordReviewRequest;
  }

  /**
   * Parameters for UpdateRecordReviewAsync
   */
  export interface UpdateRecordReviewAsyncParams {
    id: number;
    request?: UpdateRecordReviewRequest;
  }

  /**
   * Parameters for ReviseReviewAsync
   */
  export interface ReviseReviewAsyncParams {
    id: number;
    request?: any;
  }
}

export { ReviewsService }
