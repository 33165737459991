import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { ServiceCategory, ServiceCategoriesResponse, RemoveServiceCategoriesResponse } from '../../../../generated/models';
import { takeUntil, tap, switchMap, map } from 'rxjs/operators';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ServiceCategoriesService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-service-categories',
  templateUrl: './service-categories.component.html',
  styleUrls: ['./service-categories.component.scss'],
  host: { class: "page" }
})
export class ServiceCategoriesComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();

  private _items: ServiceCategory[];
  private _selectedItems: ServiceCategory[];

  private _loading: boolean;

  private _canCreate: boolean;
  private _canEdit: boolean;
  private _canRemove: boolean;

  public get categories(): ServiceCategory[] { return this._items; }

  public get canCreate(): boolean { return this._canCreate; }
  public get canEdit(): boolean { return this._canEdit; }
  public get canRemove(): boolean { return this._canRemove; }

  public get loading(): boolean { return this._loading; }
  public get hasSelected(): boolean { return this._selectedItems.length > 0; }
  public get indeterminate(): boolean { return this._selectedItems.length > 0 && this._selectedItems.length !== this._items.length; }
  public get allSelected(): boolean { return this._items.length > 0 && this._selectedItems.length === this._items.length; }

  public form: FormGroup;
  public checkbox: FormControl;

  constructor(
    private userStorage: UserStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private serviceCategoriesService: ServiceCategoriesService,
    private toastrService: ToastrService
  ) {
    this._items = [];
    this._selectedItems = [];

    this._canCreate = userStorage.hasPermission(PermissionNames.EditCategories);
    this._canEdit = userStorage.hasPermission(PermissionNames.EditCategories);
    this._canRemove = userStorage.hasPermission(PermissionNames.EditCategories);


    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: boolean): void => {
          if (value) {
            this._selectedItems = [...this._items];
          } else {
            this._selectedItems = [];
          }
        },
        (): void => { }
      );

    this.form = new FormGroup({
      search: new FormControl("")
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy),
        tap((value: { search: string }): void => {
          const params: Params = {};
          if (!!value.search) {
            params.search = value.search;
          }

          router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: params
          });
        }),
        tap((): void => {
          this._items = [];
          this._loading = true;
        }),
        switchMap((value: { search: string }): Observable<ServiceCategoriesResponse> => this.serviceCategoriesService.Categories(
          {
            Page: 1,
            Size: 0,
            Search: value.search
          })
        ),
        tap((response: ServiceCategoriesResponse): void => {
          this._items = response.categories;

          this._items.length === 0 && this.checkbox.enabled ? this.checkbox.disable() : undefined;
          this._items.length > 0 && this.checkbox.disabled ? this.checkbox.enable() : undefined;
        })
      )
      .subscribe(
        (): void => {
          this._loading = false;
        },
        (response: HttpErrorResponse): void => {
          this._loading = false;
          this.handleErrorResponse(response);
        }
      );
  }

  ngOnInit() {
    this.form.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search")
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public selected(category: ServiceCategory): boolean {
    return this._selectedItems.some((x: ServiceCategory): boolean => x.id === category.id);
  }

  public toggle(category: ServiceCategory, value: boolean): void {
    const index: number = this._selectedItems.findIndex((x: ServiceCategory): boolean => x.id === category.id);

    if (value && index === -1) {
      this._selectedItems.push(category);
    }

    if (!value && index !== -1) {
      this._selectedItems.splice(index, -1);
    }
  }

  public create(): void {
    if (!this._canCreate) {
      return;
    }

    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  public remove(): void {
    if (!this._canRemove) {
      return;
    }

    if (!this.hasSelected) {
      return;
    }

    const ids: number[] = this._selectedItems.map((x: ServiceCategory): number => x.id);

    this.serviceCategoriesService.RemoveServiceCategories(ids)
      .subscribe(
        (response: RemoveServiceCategoriesResponse): void => {

          for (let id of response.removed) {
            const index: number = this._items.findIndex((x: ServiceCategory): boolean => x.id === id);

            if (index !== -1) {
              this._items.splice(index, 1);
            }
          }

          if (response.failed.length > 0) {
            this.toastrService.warning("Не удалось удалить категории, к которым относятся услуги. Перенесите услуги из выбранной категории, чтобы удалить", "Категори с услугами");
          }

          this._selectedItems = [];

          this.checkbox.setValue(false);
        },
        (response: HttpErrorResponse): void => {
          this.handleErrorResponse(response);
        }
      );

  }

  public edit(category: ServiceCategory): void {
    this.router.navigate([category.id.toString()], { relativeTo: this.activatedRoute });
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }
    if (response.status === 40) {
      this.toastrService.error("Для выполнения данного действия требуется разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }
}
