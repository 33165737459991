import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentsService } from '../../../../generated/services';

import * as moment from 'moment';
import { Appointment } from '../../../../generated/models';
import { DashboardFilters } from '../dashboard-filters/dashboard-filters.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: { class: 'page' }
})
export class DashboardComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  title = 'Колл-центр';

  loading = false;
  appointments: Appointment[] = [];

  filters: DashboardFilters = {
    period: {
      from: moment(),
      to: moment().add(1, 'day')
    }
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appointmentsService: AppointmentsService
  ) { }

  async ngOnInit(): Promise<void> {

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(async () => {

        const queryMap = this.activatedRoute.snapshot.queryParamMap;

        const filters: DashboardFilters = {
          period: {
            from: moment(),
            to: moment().add(1, 'day')
          }
        };

        if (queryMap.has('from') && moment(queryMap.get('from'), 'DD.MM.YYYY').isValid()) {
          filters.period.from = moment(queryMap.get('from'), 'DD.MM.YYYY');
        }

        if (queryMap.has('to') && moment(queryMap.get('to'), 'DD.MM.YYYY').isValid()) {
          console.info(this.filters.period.to);
          filters.period.to = moment(queryMap.get('to'), 'DD.MM.YYYY');
        }

        this.changeFilters(filters);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async changeFilters(value: DashboardFilters): Promise<void> {
    this.filters = { ...this.filters, ...value };

    const params: Params = {};

    if (this.filters.period.from && this.filters.period.from.isValid()) {
      params['from'] = this.filters.period.from.format('DD.MM.YYYY')
    }

    if (this.filters.period.to && this.filters.period.to.isValid()) {
      params['to'] = this.filters.period.to.format('DD.MM.YYYY')
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params })

    this.loading = true;

    this.appointments = await this.appointmentsService.FindAppointmentsAsync({
      From: this.filters.period.from.format('DD.MM.YYYY'),
      To: this.filters.period.to.format('DD.MM.YYYY')
    }).toPromise();

    this.loading = false;
  }
}


