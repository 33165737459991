import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mp-manage-observation-modal',
  templateUrl: './manage-observation.modal.component.html',
  styleUrls: ['./manage-observation.modal.component.scss']
})
export class ManageObservationModalComponent {
  @Input() title: string;
  @Input() message: string;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<void>();

  remove = () => this.onConfirm.emit();
  cancel = () => this.onCancel.emit();
}
