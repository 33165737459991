/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ClinicalRecommendation } from '../models/clinical-recommendation';
import { Icd10ResponseItem } from '../models/icd-10response-item';
import { CreateRecommendedManipulationResponse } from '../models/create-recommended-manipulation-response';
import { CreateRecommendedManipulationRequest } from '../models/create-recommended-manipulation-request';
@Injectable({
  providedIn: 'root',
})
class ClinicalRecommendationsService extends __BaseService {
  static readonly GetByDiagnosisCodePath = '/api/v1/diagnoses/{code}/recommendations';
  static readonly ImportAsyncPath = '/api/v1/ClinicalRecommendations/import';
  static readonly DiagnosesAsyncPath = '/api/v1/ClinicalRecommendations/diagnoses';
  static readonly CreateRecommendedManipulationAsyncPath = '/api/v1/ClinicalRecommendations/{recommendationId}/manipulations';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param code undefined
   * @return Success
   */
  GetByDiagnosisCodeResponse(code: string): __Observable<__StrictHttpResponse<Array<ClinicalRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/diagnoses/${code}/recommendations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ClinicalRecommendation>>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  GetByDiagnosisCode(code: string): __Observable<Array<ClinicalRecommendation>> {
    return this.GetByDiagnosisCodeResponse(code).pipe(
      __map(_r => _r.body as Array<ClinicalRecommendation>)
    );
  }

  /**
   * @param file undefined
   */
  ImportAsyncResponse(file?: Blob): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ClinicalRecommendations/import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param file undefined
   */
  ImportAsync(file?: Blob): __Observable<null> {
    return this.ImportAsyncResponse(file).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ClinicalRecommendationsService.DiagnosesAsyncParams` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Age`:
   *
   * @return Success
   */
  DiagnosesAsyncResponse(params: ClinicalRecommendationsService.DiagnosesAsyncParams): __Observable<__StrictHttpResponse<Array<Icd10ResponseItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.Age != null) __params = __params.set('Age', params.Age.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ClinicalRecommendations/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Icd10ResponseItem>>;
      })
    );
  }
  /**
   * @param params The `ClinicalRecommendationsService.DiagnosesAsyncParams` containing the following parameters:
   *
   * - `Gender`:
   *
   * - `Age`:
   *
   * @return Success
   */
  DiagnosesAsync(params: ClinicalRecommendationsService.DiagnosesAsyncParams): __Observable<Array<Icd10ResponseItem>> {
    return this.DiagnosesAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Icd10ResponseItem>)
    );
  }

  /**
   * @param params The `ClinicalRecommendationsService.CreateRecommendedManipulationAsyncParams` containing the following parameters:
   *
   * - `recommendationId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateRecommendedManipulationAsyncResponse(params: ClinicalRecommendationsService.CreateRecommendedManipulationAsyncParams): __Observable<__StrictHttpResponse<CreateRecommendedManipulationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ClinicalRecommendations/${params.recommendationId}/manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateRecommendedManipulationResponse>;
      })
    );
  }
  /**
   * @param params The `ClinicalRecommendationsService.CreateRecommendedManipulationAsyncParams` containing the following parameters:
   *
   * - `recommendationId`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateRecommendedManipulationAsync(params: ClinicalRecommendationsService.CreateRecommendedManipulationAsyncParams): __Observable<CreateRecommendedManipulationResponse> {
    return this.CreateRecommendedManipulationAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateRecommendedManipulationResponse)
    );
  }
}

module ClinicalRecommendationsService {

  /**
   * Parameters for DiagnosesAsync
   */
  export interface DiagnosesAsyncParams {
    Gender?: string;
    Age?: number;
  }

  /**
   * Parameters for CreateRecommendedManipulationAsync
   */
  export interface CreateRecommendedManipulationAsyncParams {
    recommendationId: number;
    request?: CreateRecommendedManipulationRequest;
  }
}

export { ClinicalRecommendationsService }
