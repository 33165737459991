import { Component, OnInit, Input } from '@angular/core';
import { Employee } from 'projects/Clinic/src/app/generated/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';

@Component({
  selector: 'mp-manage-employee',
  templateUrl: './manage-employee.component.html',
  styleUrls: ['./manage-employee.component.scss']
})
export class ManageEmployeeComponent implements OnInit {

  @Input("employee") employee: Employee

  public title: string;
  constructor(
    private activeModal: NgbActiveModal,
    private employeesService: EmployeesService
  ) {
    this.title = "Управление сотрудником";
  }

  ngOnInit() { }

  public delete(): void {
    this.employeesService.Delete(this.employee.id)
      .subscribe(
        (): void => {
          this.activeModal.close({ status: 1 });
        });
  }

  public fire(): void {
    this.employeesService.Fire(this.employee.id)
      .subscribe(
        (): void => {
          this.activeModal.close({ status: 2 });
        });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
