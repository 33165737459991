import { Component, OnInit, Input } from '@angular/core';
import { HealthcheckStatus } from '../../services/healthcheck-service';
import { Observable, timer } from 'rxjs';

@Component({
  selector: 'mp-connection-status-panel',
  templateUrl: './connection-status-panel.component.html',
  styleUrls: ['./connection-status-panel.component.scss']
})
export class ConnectionStatusPanelComponent implements OnInit {

  @Input("status") statusObservable: Observable<HealthcheckStatus>;

  private _status: HealthcheckStatus;
  private _show: boolean = false;
  private _errorState: boolean = false;
  private _timer: Observable<number> = new Observable<number>();

  constructor() {
    
  }

  ngOnInit() {
    this.statusObservable.subscribe(
      (status: HealthcheckStatus) => {
        this._status = status;
        if (status.connectionEstablished) {
          if (this._errorState) {
            this._timer = timer(status.establishedTimeout);
            this._timer.subscribe(() => {
              this._show = false;
            })
            this._show = true;
          }
          this._errorState = false;
        }
        if (status.connectionLost) {
          if (!this._errorState) {
            this._show = true;
            this._timer = new Observable<number>();
          }
          this._errorState = true;
        }
      }
    )
  }

  public get status(): HealthcheckStatus { return this._status }
  public get show(): boolean { return this._show }

  public hidePanel(): void {
    if (this._errorState) {
      this._show = false;
    }
  }

}
