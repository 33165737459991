import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { TemplateSection } from '../../../../generated/models';

@Component({
  selector: 'mp-text-section',
  templateUrl: './text-section.component.html',
  styleUrls: ['./text-section.component.scss']
})
export class TextSectionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() section: TemplateSection = {};
  @Input() disabled = false;

  @Output() onChange = new EventEmitter<string>();

  editorOptions = {
    plugins: ["image", "table", "autoresize", "lists"],
    language: "ru",
    toolbar: "undo | sizeselect | bold italic | align | numlist bullist | image",
    statusbar: true,
    inline: false,
    menubar: true,
    max_height: 500
  };

  textControl = new FormControl("");

  constructor() {
    window['tinymce'].overrideDefaults({ base_url: '/tinymce/', suffix: '.min' });
  }

  ngOnInit() {
    this.textControl.setValue(this.section.text);

    this.textControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe((value: string) => this.onChange.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      const value: boolean = changes['disabled'].currentValue;

      if (value) {
        this.textControl.enabled ? this.textControl.disable({ emitEvent: false }) : undefined;
      } else {
        this.textControl.disabled ? this.textControl.enable({ emitEvent: false }) : undefined;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
