import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { AvailableDiscount } from '../../../../generated/models';
import { FormControl, Validators, FormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';


@Component({
  selector: 'mp-discount-field',
  templateUrl: './discount-field.component.html',
  styleUrls: ['./discount-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiscountFieldComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  private _value: DiscountValue;

  private _disabled: boolean;

  private _discounts: AvailableDiscount[] = [];

  reasonTitle = '';

  showReason = false;
  showAmount = false;

  fixedAmount = false;

  @Input() submitted = false;

  @Input() disabled = false;

  @Input()
  set discounts(value: AvailableDiscount[]) {
    this._discounts = [
      {
        id: 0,
        amount: undefined,
        hasReason: false,
        reason: "",
        name: "Нет скидки"
      },
      ...value || []
    ];

    this.update();
  }

  get discounts(): AvailableDiscount[] { return this._discounts; }

  @Input()
  public set discount(value: DiscountValue) {

    if (value) {
      this._value = { ...value };

      this.form.patchValue(this._value, { emitEvent: false });
    }
    else {
      this._value = {
        id: 0,
        amount: undefined,
        reason: ""
      };
    }

    this.form.patchValue(this._value, { emitEvent: false });

    this.update();
  }

  @Output()
  onChange = new EventEmitter<DiscountValue>();

  @Output()
  onReasonStatusChange = new EventEmitter<boolean>();

  form: FormGroup;

  constructor() {
    const discountControl: FormControl = new FormControl(undefined);
    const reasonControl: FormControl = new FormControl("", [Validators.required]);
    const amountControl: FormControl = new FormControl(undefined, [Validators.required, Validators.min(1), Validators.max(100)]);

    this.form = new FormGroup({
      id: discountControl,
      reason: reasonControl,
      amount: amountControl
    });

    discountControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((id: number): boolean => id !== this._value.id)
      )
      .subscribe(
        (value: number): void => {
          const discount: AvailableDiscount = this._discounts.find((x: AvailableDiscount): boolean => x.id === value);

          if (discount) {
            this.form.patchValue({ reason: "", amount: discount.amount }, { emitEvent: false });
          } else {
            this.form.patchValue({ reason: "", amount: undefined }, { emitEvent: false });
          }

          this.update();
        }
      );

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(() => this.onChange.emit({ ...this.form.getRawValue() }));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitted'] && changes['submitted'].currentValue) {
      Object.entries(this.form.controls).forEach((x => x[1].markAsDirty()));
    }

    if (changes['disabled']) {
      if (changes['disabled'].currentValue) this.form.disable({ emitEvent: false });
      else this.form.enable({ emitEvent: false });

      this.update();
    }

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private update(): void {

    const formValue: DiscountValue = this.form.getRawValue();

    const discount: AvailableDiscount = this._discounts.find((x: AvailableDiscount): boolean => x.id === formValue.id);

    if (discount && discount.id > 0) {
      this.showReason = discount.hasReason;
      this.showAmount = true;
      this.reasonTitle = discount.reason;
      this.fixedAmount = discount.fixed;
    } else {
      this.showReason = false;
      this.showAmount = false;
      this.reasonTitle = "";
      this.fixedAmount = false;
    }

    this.showReason && !this._disabled ? this.form.get("reason").enable({ emitEvent: false }) : this.form.get("reason").disable({ emitEvent: false });
    this.showAmount && !this._disabled && !this.fixedAmount ? this.form.get("amount").enable({ emitEvent: false }) : this.form.get("amount").disable({ emitEvent: false });

    this.onReasonStatusChange.emit(this.showReason);
  }
}

export interface DiscountValue {
  id: number;
  reason: string;
  amount: number;
}
