import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PersonMark } from '../../../../generated/models';

@Component({
  selector: 'mp-person-mark',
  templateUrl: './person-mark.component.html',
  styleUrls: ['./person-mark.component.scss'],
  host: { class: 'shadow-sm' }
})
export class PersonMarkComponent implements OnInit {

  @Input() mark: PersonMark = {};

  @Input() add = false;
  @Input() canAct = false;
  @Input() icon = '';

  @Output() onAction = new EventEmitter<void>();

  @HostBinding('style.background-color') get bacgroundColor() { return this.mark ? this.mark.colorMarkValue : ''; }
  @HostBinding('class.add') get hasAddClass() { return this.add; }

  constructor() { }

  ngOnInit() { }

  act = () => this.canAct ? this.onAction.emit() : undefined;
}
