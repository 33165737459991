import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ClinicalRecommendation, DiseaseOutcome, RecordReviewDescription, RecordReviewSectionDescription, SectionsTreatmentPrescription } from '../../../../generated/models';
import { ReviewResult, SectionReview } from '../revision/revision.component';

@Component({
  selector: 'mp-section-review',
  templateUrl: './section-review.component.html',
  styleUrls: ['./section-review.component.scss']
})
export class SectionReviewComponent implements OnInit, OnDestroy, OnChanges {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() data: SectionReview = {};
  @Input() dirty: boolean;
  @Input() age: number;
  @Input() comment = '';
  @Input() gender = '';

  @Input() outcomes: DiseaseOutcome[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];
  @Input() result: ReviewResult = {};
  @Input() descriptions: RecordReviewDescription = {};

  @Input() readonly = false;

  @Output()
  onUpdate: EventEmitter<{ name: string, value: number }> = new EventEmitter<{ name: string; value: number }>();

  @Output()
  onComment: EventEmitter<string> = new EventEmitter<string>();

  commentControl = new FormControl('');

  description: RecordReviewSectionDescription = null;
  value: number = null;
  title: string = null;
  selected = null;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    this.description = this.data.criteria ? this.descriptions[this.data.criteria] : null;
    this.value = this.data.criteria ? this.result[this.data.criteria] : null;

    this.title = this.description ? this.description.text : null;
    if (this.value !== null && this.description) {
      const score = this.description.scores.find(x => x.value === this.value);

      this.selected = score ? score.text : null;
    }

    if (changes['comment']) {
      this.commentControl.setValue(changes['comment'].currentValue, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.commentControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: string): void => {
        this.onComment.emit(value);
      });


  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  update(value: number): void {
    if (this.data.criteria) {
      this.onUpdate.emit({ value, name: this.data.criteria });
    }
  }

  get clinicalRecommendations(): ClinicalRecommendation[] { return this.recommendations.filter(x => x.type === 0); }
  get qualityCriterias(): ClinicalRecommendation[] { return this.recommendations.filter(x => x.type === 1); }

  get type(): string {
    if (!this.data.section) return '';

    switch (this.data.section.type) {
      case 1: return "text";
      case 2: return "diagnosis";
      case 3: return "referrals";
      case 4: return "date";
      case 5: return "closing";
      case 6: return "treatments";
      default: return "";
    }
  }

  get diagnosisType(): string {
    if (!this.data.section) {
      return "";
    }

    switch (this.data.section.diagnosisType) {
      case 0: return "Предварительный";
      case 1: return "Основной";
      case 3: return "Сопутствующий";
      case 2: return "Осложнение";

      default: return "";
    }
  }

  get caseResult(): string {
    if (!this.data.section) return '';

    if (this.data.section.type === 7 && this.data.section.outcomeType === 2) {
      const outcome = this.outcomes.find(x => x.id === this.data.section.closedWithStatus);

      return outcome ? outcome.name : '';
    }

    if (this.data.section.type === 5) {
      const outcome = this.outcomes.find(x => x.id === this.data.section.caseResultId);

      return outcome ? outcome.name : '';
    }

    return '';
  }

  showCR(): boolean {
    return this.clinicalRecommendations.some(x => this.showRecommendation(x));
  }

  showQC(): boolean {
    return this.qualityCriterias.some(x => this.showRecommendation(x));
  }

  showRecommendation(recommendation: ClinicalRecommendation): boolean {
    if (recommendation.maxAge && recommendation.maxAge < this.age) return false;
    if (recommendation.minAge && recommendation.minAge > this.age) return false;
    if (recommendation.gender === 'f' && this.gender !== 'f') return false;
    if (recommendation.gender === 'm' && this.gender !== 'm') return false;

    return true;
  }

  recommendationMissed(recommendation: ClinicalRecommendation): boolean {
    if (!recommendation.mandatory) return false;

    if (recommendation.serviceCode) {
      return !this.data.section.referrals.some(x => x.serviceId === recommendation.serviceId);
    }

    return !this.data.section.referrals.some(x => !x.serviceId && x.name === recommendation.serviceName);
  }

  recommendationAdded(recommendation: ClinicalRecommendation): boolean {

    if (recommendation.serviceCode) {
      return this.data.section.referrals.some(x => x.serviceId === recommendation.serviceId);
    }

    return this.data.section.referrals.some(x => !x.serviceId && x.name === recommendation.serviceName);
  }

  display(treatment: SectionsTreatmentPrescription): string {
    if (!treatment) return '';

    const parts = [treatment.treatmentName, treatment.dosage, treatment.schema, treatment.duration];

    return parts.filter(x => !!x).join(', ').trim();
  }
}
