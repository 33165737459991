import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeSystemsComponent } from './components/code-systems/code-systems.component';
import { CodeSystemsListItemComponent } from './components/code-systems-list-item/code-systems-list-item.component';
import { RouterModule, Routes } from '@angular/router';
import { PermissionNames } from '../../models/permission-names';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CodeSystemComponent } from './components/code-system/code-system.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CodeSystemResolver } from './resolvers/code-system-resolver';

const routes: Routes = [
  {
    path: "",
    component: CodeSystemsComponent,
    data: {
      title: "Системы кодировки",
      requirements: [PermissionNames.ManageCodeSystems]
    }
  },
  {
    path: "new",
    component: CodeSystemComponent,
    data: {
      title: "Новая кодовая система",
      requirements: [PermissionNames.ManageCodeSystems]
    },
    resolve: {
      payload: CodeSystemResolver
    }
  },
  {
    path: ":id",
    component: CodeSystemComponent,
    data: {
      title: "Кодовая система",
      requirements: [PermissionNames.ManageCodeSystems]
    },
    resolve: {
      payload: CodeSystemResolver
    }
  }
]

@NgModule({
  declarations: [
    CodeSystemsComponent,
    CodeSystemsListItemComponent,
    CodeSystemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,

    InfiniteScrollModule,
    LayoutModule,
    ControlsModule
  ]
})
export class CodeSystemsSettingsModule { }
