import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-signature-info-modal',
  templateUrl: './signature-info-modal.component.html',
  styleUrls: ['./signature-info-modal.component.scss']
})
export class SignatureInfoModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
