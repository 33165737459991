import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ColorMark } from '../../../../generated/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-person-mark-modal',
  templateUrl: './person-mark-modal.component.html',
  styleUrls: ['./person-mark-modal.component.scss']
})
export class PersonMarkModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() colorMarks: ColorMark[] = [];
  @Input() processing = false;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<PersonMarkModalPayload>();

  selectedColor = '';
  selectedIcon = '&nbsp;';

  form = new FormGroup({
    colorMarkId: new FormControl(null, [Validators.required]),
    description: new FormControl('')
  });

  get canSubmit() { return this.form.valid && !this.processing; }

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: PersonMarkModalPayload) => {
          if (value.colorMarkId > 0 && this.colorMarks.some(x => x.id === value.colorMarkId)) {
            const mark = this.colorMarks.find(x => x.id === value.colorMarkId);

            this.selectedColor = mark.color;
          }
          else {
            this.selectedColor = '';
          }

        }
      );

  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  cancel = () => this.onCancel.emit();

  confirm() {
    if (this.form.invalid) return;

    this.onConfirm.emit(this.form.getRawValue());
  }
}

export interface PersonMarkModalPayload {
  colorMarkId: number;
  description: string;
}
