import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplatesService } from '../../../../generated/services';
import { Speciality, Attending, CreateSectionRequest, SavedTemplateGroup, Metric, TemplateSectionMetric, DiseaseOutcome } from '../../../../generated/models';
import { TemplatePayload } from '../../resolvers/template-resolver';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { TemplateSection } from '../../../../generated/models/template-section';
import { UpdateSectionRequest } from '../../../../generated/models/update-section-request';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TemplateStructureModalComponent } from '../template-structure-modal/template-structure-modal.component';
import { MetricsModalComponent } from '../metrics-modal/metrics-modal.component';
import moment from 'moment';

@Component({
  selector: 'mp-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
  host: { class: "page" }
})
export class TemplateComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  submitted = false;

  specialities: Speciality[] = [{ id: 0, name: "Любая специальность" }];
  attendings: Attending[] = [{ id: 0, name: "Любой специалист" }];

  sections: TemplateSection[] = [];
  placeholders: SavedTemplateGroup[] = [];
  metrics: Metric[] = [];
  outcomes: DiseaseOutcome[] = [];

  canEdit = false;
  canOwn = false;
  canManage = false;

  canChangeStructure = false;
  canChangeContent = false;
  canSwitchPrivacy = false;

  processing = false;
  loading = false;

  structureEditorOpened = false;

  title: string;

  form: FormGroup = new FormGroup({
    name: new FormControl("", [Validators.required]),
    primary: new FormControl(false),
    speciality: new FormControl(0),
    owner: new FormControl(0)
  });

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private templatesService: TemplatesService,
    private toastrService: ToastrService,
    private userStorage: UserStorage,
    private modalService: NgbModal
  ) {

    this.canOwn = userStorage.hasPermission(PermissionNames.OwnPersonalTemplates);
    this.canManage = userStorage.hasPermission(PermissionNames.ManageTemplates);
    this.canEdit = userStorage.hasPermission(PermissionNames.EditTemplates);

  }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get("id"));

    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data: { payload: TemplatePayload }): void => {
        this.specialities.push(...data.payload.specialities);
        this.placeholders.push(...data.payload.placeholders);
        this.metrics.push(...data.payload.metrics);
        this.outcomes.push(...data.payload.outcomes);

        if (data.payload.attendings.some(x => x.id === this.userStorage.profile.id)) {
          this.attendings.push({ id: this.userStorage.profile.id, name: `Я (${this.userStorage.profile.userName})` });
        }

        this.attendings.push(...data.payload.attendings.filter(x => x.id !== this.userStorage.profile.id));

        if (data.payload.template.ownerId > 0 && !this.attendings.some(x => x.id === data.payload.template.ownerId)) {
          this.attendings.push({ id: data.payload.template.ownerId, name: data.payload.template.ownerName });
        }

        //Изменять личный шаблон может
        if (data.payload.template.ownerId > 0) {
          //Пользователь с разрешением ManageTemplate - безусловно
          if (this.userStorage.hasPermission(PermissionNames.ManageTemplates)) {
            this.canChangeContent = true;
            this.canChangeStructure = true;
            this.canSwitchPrivacy = true;
          }
          ///Пользователь с разрешением OwnPersonalTemplates - при условии, что это его шаблон
          else if (this.userStorage.hasPermission(PermissionNames.OwnPersonalTemplates)) {
            const isOwner = this.userStorage.profile.id === data.payload.template.ownerId;

            this.canChangeContent = isOwner;
            this.canChangeStructure = isOwner;
            this.canSwitchPrivacy = false;
          }
        }
        //Изменять общие шаблоны может
        else {
          //Пользователь с разрешением ManageTemplate - безусловно
          if (this.userStorage.hasPermission(PermissionNames.ManageTemplates)) {
            this.canChangeContent = true;
            this.canChangeStructure = true;
            this.canSwitchPrivacy = true;
          }
          //Пользователь с разрешением EditTemplates - только контент
          else if (this.userStorage.hasPermission(PermissionNames.EditTemplates)) {
            this.canChangeContent = true;
            this.canChangeStructure = false;
            this.canSwitchPrivacy = false;
          }
        }

        if (!this.canChangeStructure) {
          this.form.enabled ? this.form.disable() : undefined;
        } else {
          this.form.disabled ? this.form.enable() : undefined;
        }

        const ownerControl: AbstractControl = this.form.get("owner");

        if (this.canSwitchPrivacy) {
          ownerControl.disabled ? ownerControl.enable() : undefined;
        } else {
          ownerControl.enabled ? ownerControl.disable() : undefined;
        }

        this.form.patchValue({
          name: data.payload.template.name,
          primary: data.payload.template.primary,
          speciality: data.payload.template.specialityId || 0,
          owner: data.payload.template.ownerId || 0
        });

        this.canEdit = this.userStorage.hasPermission(PermissionNames.ManageTemplates)
          || !data.payload.template.ownerId
          || data.payload.template.ownerId === this.userStorage.profile.id;

        this.title = data.payload.template.id > 0 ? `Шаблон: ${data.payload.template.name}` : "Новый шаблон";

      });

    if (this.id) {
      this.loadSections();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get canSave(): boolean { return this.canChangeContent || this.canChangeStructure; }

  cancel = () => this.router.navigate(['..'], { relativeTo: this.activatedRoute });

  editStructure() {
    if (this.structureEditorOpened) return;

    this.structureEditorOpened = true;
    const modalRef: NgbModalRef = this.modalService.open(TemplateStructureModalComponent, { size: "lg", backdrop: "static" });
    const componentRef: TemplateStructureModalComponent = modalRef.componentInstance;

    componentRef.sections = this.sections.map(x => ({ ...x }));

    componentRef.onCancel.subscribe(() => {
      this.structureEditorOpened = false;
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((sections: TemplateSection[]) => {
      this.sections = sections.map(x => ({ ...x }));
      this.structureEditorOpened = false;
      modalRef.close();
    });
  }

  onSectionChange(section: TemplateSection, changed: TemplateSection) {
    section.name = changed.name;

    section.required = changed.required;
    section.show = changed.show;
    section.includeInExtract = changed.includeInExtract;
    section.hideTitle = changed.hideTitle;
    section.formatting = changed.formatting;

    section.text = changed.text;
    section.diagnosisType = changed.diagnosisType;
    section.appointmentPeriod = changed.appointmentPeriod;

    section.allowedDiagnoses = changed.allowedDiagnoses;

    section.readonly = changed.readonly;

    section.nextAppointmentInDays = changed.nextAppointmentInDays;
    section.defaultOutcomeDescription = changed.defaultOutcomeDescription;
    section.defaultOutcomeStatus = changed.defaultOutcomeStatus;

    section.defaultDiagnosisCode = changed.defaultDiagnosisCode;
    section.defaultDiagnosisName = changed.defaultDiagnosisName;
  }

  onSelectPlaceholder(value: string) {
    const textarea: HTMLTextAreaElement = document.createElement("textarea");
    textarea.value = value;

    textarea.style.position = "fixed";
    textarea.style.top = "-1000px";

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");

    document.body.removeChild(textarea);

    this.toastrService.success("", "Скопировано");
  }

  manageMetrics(section: TemplateSection) {
    const modalRef = this.modalService.open(MetricsModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: MetricsModalComponent = modalRef.componentInstance;

    componentRef.metrics = this.metrics;
    componentRef.selected = section.metrics.map(x => x.metricId);
    componentRef.occupied = this.sections.filter(x => x.id !== section.id)
      .map(x => x.metrics).reduce((x, y) => x.concat(y), []).map(x => x.metricId);

    console.info(componentRef.occupied);

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe((ids: number[]) => {

      const sectionMetrics: TemplateSectionMetric[] = [];

      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const metric = this.metrics.find(x => x.id == id);

        if (!metric) continue;

        sectionMetrics.push({
          id: -1 * moment().unix(),
          metricId: id,
          metricSystemName: metric.systemName,
          metricDisplayName: metric.displayName,
          metricUnits: metric.units,
          index: i
        });
      }

      section.metrics = [...sectionMetrics];

      modalRef.close();
    });
  }

  save() {
    this.submitted = true;

    Object.entries(this.form.controls).forEach(x => x[1].markAsDirty());

    if (this.form.invalid) return;

    if (!this.canSave) return;

    if (this.sections.some(x => x.type === 2 && !!x.defaultDiagnosisCode && !x.defaultDiagnosisName)) {
      return false;
    }

    if (this.id > 0) {
      this.update();
    } else {
      this.create();
    }

  }

  async create(): Promise<void> {
    const value: FormValue = this.form.getRawValue();

    this.processing = true;

    const sections: CreateSectionRequest[] = this.sections.map((x: TemplateSection, index: number): CreateSectionRequest => ({
      name: x.name,
      type: x.type,
      required: x.required,
      show: x.required,
      includeInExtract: x.includeInExtract,
      hideTitle: x.hideTitle,
      formatting: x.formatting,
      appointmentPeriod: x.appointmentPeriod,
      diagnosisType: x.diagnosisType,
      index: index,
      text: x.text,

      defaultDiagnosisCode: x.defaultDiagnosisCode,
      defaultDiagnosisName: x.defaultDiagnosisName,
      allowedDiagnoses: x.allowedDiagnoses,

      readonly: x.readonly,

      nextAppointmentInDays: x.nextAppointmentInDays,
      defaultOutcomeDescription: x.defaultOutcomeDescription,
      defaultOutcomeStatus: x.defaultOutcomeStatus,
      metrics: x.metrics.map((m, i) => ({ ...m, index: i + 1 }))
    }));

    try {
      const response = await this.templatesService.Create({
        name: value.name,
        primary: value.primary,
        specialityId: value.speciality > 0 ? value.speciality : undefined,
        ownerId: value.owner > 0 ? value.owner : undefined,
        sections: sections
      }).toPromise();

      this.toastrService.success("", "Сохранено");
      this.router.navigate(["..", response.id.toString()], { relativeTo: this.activatedRoute });
      this.processing = false;

    } catch (e) {
      this.handleError(e as HttpErrorResponse)
    }
  }

  async update(): Promise<void> {
    const value: any = this.form.getRawValue();
    this.processing = true;

    const sections: UpdateSectionRequest[] = this.sections.map((x: TemplateSection, index: number): UpdateSectionRequest => ({
      id: x.id,
      name: x.name,
      type: x.type,
      required: x.required,
      show: x.show,
      includeInExtract: x.includeInExtract,
      hideTitle: x.hideTitle,
      formatting: x.formatting,
      appointmentPeriod: x.appointmentPeriod,
      diagnosisType: x.diagnosisType,
      index: index,
      text: x.text,

      defaultDiagnosisCode: x.defaultDiagnosisCode,
      defaultDiagnosisName: x.defaultDiagnosisName,
      allowedDiagnoses: x.allowedDiagnoses,
      readonly: x.readonly,

      nextAppointmentInDays: x.nextAppointmentInDays,
      defaultOutcomeDescription: x.defaultOutcomeDescription,
      defaultOutcomeStatus: x.defaultOutcomeStatus,

      metrics: x.metrics.map((m, i) => ({ ...m, index: i + 1 }))
    }));

    try {
      await this.templatesService.Update({
        id: this.id,
        request: {
          name: value.name,
          primary: value.primary,
          specialityId: value.speciality > 0 ? value.speciality : undefined,
          ownerId: value.owner > 0 ? value.owner : undefined,
          sections: sections
        }
      }).toPromise();

      const response = await this.templatesService.Sections(this.id).toPromise();

      this.toastrService.success("", "Сохранено");
      this.processing = false;
      this.sections = response;

      this.title = `Шаблон: ${this.form.get("name").value}`;

    } catch (e) {
      this.handleError(e as HttpErrorResponse);
    }

  }

  private handleError(response: HttpErrorResponse): void {
    this.processing = false;

    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }

    if (response.status === 403) {
      this.toastrService.error("Для выполнения данного действия требуется разрешение", "Запрещено");
      return;
    }
    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }

  private loadSections(): void {
    this.loading = true;

    this.templatesService.Sections(this.id)
      .subscribe(
        (sections: TemplateSection[]) => {
          this.loading = false;

          this.sections = sections;
        },
        (response: HttpErrorResponse) => {
          this.loading = false;
        }
      );


  }
}

interface FormValue {
  name: string;
  primary: boolean;
  speciality: number;
  owner: number;
}
