import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-reject-confirm-modal',
  templateUrl: './reject-confirm-modal.component.html',
  styleUrls: ['./reject-confirm-modal.component.scss']
})
export class RejectConfirmModalComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;



  @Input("name") name: string;
  @Input("time") time: string;

  constructor(
    private _currentModal: NgbActiveModal) { }

  ngOnInit() {
  }

  dismiss() {
    this._currentModal.dismiss();
  }

  close() {
    this._currentModal.close(true);
  }

}
