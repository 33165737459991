import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecordSection } from '../../../../generated/models';

@Component({
  selector: 'mp-appointment-section-editor',
  templateUrl: './appointment-section-editor.component.html',
  styleUrls: ['./appointment-section-editor.component.scss']
})
export class AppointmentSectionEditorComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() base: string;
  @Input() disabled = false;
  @Input() dirty = false;
  @Input() appointmentReasonRequired = false;
  @Input() section: RecordSection;

  @Output()
  onChange = new EventEmitter<RecordSection>();

  form = new FormGroup({
    date: new FormControl(undefined),
    reason: new FormControl('')
  });

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const value: FormValue = this.form.getRawValue();

        this.onChange.emit({
          ...this.section,
          appointmentDate: value.date && value.date.isValid() ? value.date.format("DD.MM.YYYY") : "",
          appointmentReason: value.reason
        });
      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled && this.form.enabled) this.form.disable({ emitEvent: false });
      if (!disabled && this.form.disabled) this.form.enable({ emitEvent: false });
    }

    if (changes['section']) {
      const section: RecordSection = changes['section'].currentValue;

      if (section) {
        this.form.patchValue({
          date: section.appointmentDate ? moment(section.appointmentDate, 'DD.MM.YYYY') : undefined,
          reason: section.appointmentReason
        }, { emitEvent: false });

      } else {
        this.form.patchValue({ date: undefined, reason: '' }, { emitEvent: false });
      }
    }

    if (changes['appointmentReasonRequired']) {
      const appointmentReasonRequired: boolean = changes['appointmentReasonRequired'].currentValue;

      if (appointmentReasonRequired) {
        this.form.get('reason').setValidators(Validators.required);
      } else {
        this.form.get('reason').clearValidators();
      }
    }

    if (changes['dirty']) {
      const dirty = changes['dirty'].currentValue;

      if (dirty) {
        Object.entries(this.form.controls).forEach(x => x[1].markAsDirty());
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clear = () => this.setDate(null);

  addWeek = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "week"));
  addFortnight = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(2, "week"));
  addMonth = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "month"));
  add6Month = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(6, "month"));
  addYear = () => this.setDate(moment(this.base, "DD.MM.YYYY").add(1, "year"));

  setDate = (date: moment.Moment) => this.form.get('date').setValue(date);
}

interface FormValue {
  date: moment.Moment;
  reason: string;
}
