import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PartnerPlanResolver } from './resolvers/partner-plan.resolver';
import { PartnerPlanComponent } from './components/partner-plan/partner-plan.component';
import { PartnerPlansComponent } from './components/partner-plans/partner-plans.component';
import { ManagePartnerPlanModalComponent } from './components/manage-partner-plan.modal/manage-partner-plan.modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { PlanServiceModalComponent } from './components/plan-service-modal/plan-service-modal.component';
import { PermissionNames } from '../../models/permission-names';
import { SharedModule } from '../shared/shared.module';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: PartnerPlanResolver
    },
    component: PartnerPlanComponent,
    data: {
      title: "Новая партнерская программа",
      requirements: [PermissionNames.ManagePartnerPlans]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: PartnerPlanResolver
    },
    component: PartnerPlanComponent,
    data: {
      title: "Партнерская программа",
      requirements: [PermissionNames.ManagePartnerPlans]
    }
  },
  {
    path: "",
    component: PartnerPlansComponent,
    data: {
      title: "Партнерские программы",
      requirements: [PermissionNames.ManagePartnerPlans]
    }
  }
];

@NgModule({
  declarations: [
    PartnerPlanComponent,
    PartnerPlansComponent,
    ManagePartnerPlanModalComponent,
    PlanServiceModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,

    LayoutModule,
    ControlsModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManagePartnerPlanModalComponent,
    PlanServiceModalComponent
  ]
})
export class PartnerPlanSettingsModule { }
