import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportService } from '../../../../generated/models';

@Component({
  selector: 'mp-service-list-item',
  templateUrl: './service-list-item.component.html',
  styleUrls: ['./service-list-item.component.scss']
})
export class ServiceListItemComponent implements OnInit {

  @Input() service: ReportService;
  @Input() selected: boolean;

  @Output()
  public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  public toggle(): void {
    this.onToggle.emit(!this.selected);
  }
}
