import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardFiltersComponent } from './components/dashboard-filters/dashboard-filters.component';

import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { DashboardListItemComponent } from './components/dashboard-list-item/dashboard-list-item.component';
import { AttendanceComponent } from './components/attendance/attendance.component';
import { AttendanceFiltersComponent } from './components/attendance-filters/attendance-filters.component';
import { AttendanceItemComponent } from './components/attendance-item/attendance-item.component';
import { PermissionNames } from '../../models/permission-names';
import { CallModalComponent } from './components/call-modal/call-modal.component';
import { NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { AttendanceResolver } from './resolvers/attendance-resolver';
import { NgxSelectModule } from 'ngx-select-ex';
import { AttendanceStatisticsComponent } from './components/attendance-statistics/attendance-statistics.component';
import { CallReportItemComponent } from './components/call-report-item/call-report-item.component';
import { PlannedVisitsComponent } from './components/planned-visits/planned-visits.component';
import { PlannedVisitsResolver } from './resolvers/planned-visits-resolver';
import { AttendanceTypeSelectComponent } from './components/attendance-type-select/attendance-type-select.component';
import { PlannedVisitItemComponent } from './components/planned-visit-item/planned-visit-item.component';
import { CallComponent } from './components/call/call.component';
import { PatientSearchResultComponent } from './components/patient-search-result/patient-search-result.component';

const routes: Routes = [
  {
    path: "dashboard",
    component: DashboardComponent,
    data: {
      title: "Колл-центр",
      requirements: [PermissionNames.ViewCallCenter]
    }
  },
  {
    path: "attendance",
    component: AttendanceComponent,
    data: {
      title: "Контроль явки",
      requirements: [PermissionNames.ViewCallCenter]
    },
    resolve: {
      payload: AttendanceResolver
    }
  },
  {
    path: 'visits/planned',
    component: PlannedVisitsComponent,
    data: {
      title: 'Запланированные посещения',
      requirements: [PermissionNames.ViewCallCenter]
    },
    resolve: {
      payload: PlannedVisitsResolver
    }
  }
];

@NgModule({
  declarations: [DashboardComponent, DashboardFiltersComponent, DashboardListItemComponent, AttendanceComponent, AttendanceFiltersComponent, AttendanceItemComponent, CallModalComponent, AttendanceStatisticsComponent, CallReportItemComponent, PlannedVisitsComponent, AttendanceTypeSelectComponent, PlannedVisitItemComponent, CallComponent, PatientSearchResultComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,

    LayoutModule,
    ControlsModule,
    SharedModule,

    NgbModalModule,
    ToastrModule,
    NgxSelectModule,
    NgbPopoverModule,
    NgbDropdownModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    CallModalComponent
  ]
})
export class CallCenterWorkspaceModule { }
