import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, Role, SpecialitiesResponse, Speciality } from '../../../generated/models';
import { CompaniesService, EmployeesService, RolesService, SpecialitiesService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class EmployeesResolver implements Resolve<EmployeesPayload> {

  constructor(
    private specialitiesService: SpecialitiesService,
    private rolesService: RolesService,
    private companiesService: CompaniesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EmployeesPayload> {
    let specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(
        map((response: SpecialitiesResponse) => response.items)
      );

    let rolesLoader: Observable<Role[]> = this.rolesService.Roles({ Page: 1, Size: 0 });

    let companiesLoader: Observable<Company[]> = this.companiesService.Companies({});

    return forkJoin({
      specialities: specialitiesLoader,
      roles: rolesLoader,
      companies: companiesLoader
    })
  }

}

export class EmployeesPayload {
  public specialities: Speciality[];
  public roles: Role[];
  public companies: Company[];
}
