/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FiscalPrinter } from '../models/fiscal-printer';
import { BarcodePrinter } from '../models/barcode-printer';
import { DocumentPrinter } from '../models/document-printer';
import { ExternalConnectionsResponse } from '../models/external-connections-response';
import { AddExternalConnectionResponse } from '../models/add-external-connection-response';
import { AddExternalConnectionRequest } from '../models/add-external-connection-request';
import { ExternalConnectionResponse } from '../models/external-connection-response';
import { EditExternalConnectionRequest } from '../models/edit-external-connection-request';
import { ManageExternalConnectionModel } from '../models/manage-external-connection-model';
import { ApiUser } from '../models/api-user';
@Injectable({
  providedIn: 'root',
})
class ExternalConnectionsService extends __BaseService {
  static readonly CashboxesPath = '/api/v1/ExternalConnections/cashboxes';
  static readonly BarcodePrintersPath = '/api/v1/ExternalConnections/barcode-printers';
  static readonly DocumentPrintersPath = '/api/v1/ExternalConnections/document-printers';
  static readonly ExternalConnectionsPath = '/api/v1/ExternalConnections';
  static readonly CreatePath = '/api/v1/ExternalConnections';
  static readonly GetPath = '/api/v1/ExternalConnections/{id}';
  static readonly EditPath = '/api/v1/ExternalConnections/{id}';
  static readonly ManagePath = '/api/v1/ExternalConnections/{id}/manage';
  static readonly RemovePath = '/api/v1/ExternalConnections/{externalConnectionId}';
  static readonly ApiUsersPath = '/api/v1/ExternalConnections/users';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param CompanyId undefined
   * @return Success
   */
  CashboxesResponse(CompanyId?: number): __Observable<__StrictHttpResponse<Array<FiscalPrinter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (CompanyId != null) __params = __params.set('CompanyId', CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/cashboxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FiscalPrinter>>;
      })
    );
  }
  /**
   * @param CompanyId undefined
   * @return Success
   */
  Cashboxes(CompanyId?: number): __Observable<Array<FiscalPrinter>> {
    return this.CashboxesResponse(CompanyId).pipe(
      __map(_r => _r.body as Array<FiscalPrinter>)
    );
  }

  /**
   * @return Success
   */
  BarcodePrintersResponse(): __Observable<__StrictHttpResponse<Array<BarcodePrinter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/barcode-printers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<BarcodePrinter>>;
      })
    );
  }
  /**
   * @return Success
   */
  BarcodePrinters(): __Observable<Array<BarcodePrinter>> {
    return this.BarcodePrintersResponse().pipe(
      __map(_r => _r.body as Array<BarcodePrinter>)
    );
  }

  /**
   * @return Success
   */
  DocumentPrintersResponse(): __Observable<__StrictHttpResponse<Array<DocumentPrinter>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/document-printers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DocumentPrinter>>;
      })
    );
  }
  /**
   * @return Success
   */
  DocumentPrinters(): __Observable<Array<DocumentPrinter>> {
    return this.DocumentPrintersResponse().pipe(
      __map(_r => _r.body as Array<DocumentPrinter>)
    );
  }

  /**
   * @param params The `ExternalConnectionsService.ExternalConnectionsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  ExternalConnectionsResponse(params: ExternalConnectionsService.ExternalConnectionsParams): __Observable<__StrictHttpResponse<ExternalConnectionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalConnectionsResponse>;
      })
    );
  }
  /**
   * @param params The `ExternalConnectionsService.ExternalConnectionsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  ExternalConnections(params: ExternalConnectionsService.ExternalConnectionsParams): __Observable<ExternalConnectionsResponse> {
    return this.ExternalConnectionsResponse(params).pipe(
      __map(_r => _r.body as ExternalConnectionsResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: AddExternalConnectionRequest): __Observable<__StrictHttpResponse<AddExternalConnectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/ExternalConnections`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddExternalConnectionResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: AddExternalConnectionRequest): __Observable<AddExternalConnectionResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as AddExternalConnectionResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<ExternalConnectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalConnectionResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<ExternalConnectionResponse> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as ExternalConnectionResponse)
    );
  }

  /**
   * @param params The `ExternalConnectionsService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  EditResponse(params: ExternalConnectionsService.EditParams): __Observable<__StrictHttpResponse<ExternalConnectionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/ExternalConnections/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExternalConnectionResponse>;
      })
    );
  }
  /**
   * @param params The `ExternalConnectionsService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  Edit(params: ExternalConnectionsService.EditParams): __Observable<ExternalConnectionResponse> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as ExternalConnectionResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManageExternalConnectionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManageExternalConnectionModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManageExternalConnectionModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManageExternalConnectionModel)
    );
  }

  /**
   * @param externalConnectionId undefined
   */
  RemoveResponse(externalConnectionId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/ExternalConnections/${externalConnectionId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param externalConnectionId undefined
   */
  Remove(externalConnectionId: number): __Observable<null> {
    return this.RemoveResponse(externalConnectionId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  ApiUsersResponse(): __Observable<__StrictHttpResponse<Array<ApiUser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/ExternalConnections/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ApiUser>>;
      })
    );
  }
  /**
   * @return Success
   */
  ApiUsers(): __Observable<Array<ApiUser>> {
    return this.ApiUsersResponse().pipe(
      __map(_r => _r.body as Array<ApiUser>)
    );
  }
}

module ExternalConnectionsService {

  /**
   * Parameters for ExternalConnections
   */
  export interface ExternalConnectionsParams {
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    id: number;
    request?: EditExternalConnectionRequest;
  }
}

export { ExternalConnectionsService }
