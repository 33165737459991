import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SystemSettingsService, CompaniesService, EmployeesService } from '../../../generated/services';
import { Observable, of, forkJoin } from 'rxjs';
import { SystemSettingViewModel, Company, SystemUser } from '../../../generated/models';

@Injectable({ providedIn: "root" })
export class SystemSettingResolver implements Resolve<SystemSettingPayload> {

  constructor(
    private systemSettingsService: SystemSettingsService,
    private companiesService: CompaniesService,
    private employeeService: EmployeesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SystemSettingPayload> {
    const id: string = route.paramMap.get("id");

    let systemSettingsLoader: Observable<SystemSettingViewModel> = of<SystemSettingViewModel>({});

    if (id) {
      systemSettingsLoader = this.systemSettingsService.SystemSettingInfo(id);
    }

    const companiesLoader: Observable<Company[]> = this.companiesService.MyCompanies();

    const employeeLoader: Observable<SystemUser[]> = this.employeeService.SystemUsers();

    return forkJoin({
      systemSettingModel: systemSettingsLoader,
      companies: companiesLoader,
      employee: employeeLoader
    })
  }

}

export interface SystemSettingPayload {
  systemSettingModel: SystemSettingViewModel;
  companies: Company[];
  employee: SystemUser[];
}
