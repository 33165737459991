import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PersonLabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-lab-results-item',
  templateUrl: './lab-results-item.component.html',
  styleUrls: ['./lab-results-item.component.scss']
})
export class LabResultsItemComponent implements OnInit {

  @Input() test: PersonLabResult = {};

  @Output() onCopy = new EventEmitter<PersonLabResult>();

  displayValue = '';
  showInfo = false;

  @HostBinding('class.warning') warning = false;

  low = false;
  high = false;

  constructor() { }

  ngOnInit() {
    this.displayValue = this.value(this.test);
    this.showInfo = !!this.test && (!!this.test.normalValue || !!this.test.comment || !!this.test.code);

    const value = parseFloat(this.test.value);
    const minValue = parseFloat(this.test.normalValueMin);
    const maxValue = parseFloat(this.test.normalValueMax);

    this.low = !isNaN(value) && !isNaN(minValue) && value < minValue;
    this.high = !isNaN(value) && !isNaN(maxValue) && value > maxValue;

  }

  value(test: PersonLabResult) {
    if (!test || !test.value) return '';

    if (/\s*(не обнаружено)\s*/i.test(test.value)) {
      return 'Н';
    }

    if (!test.units) {
      return test.value;
    }

    if (test.units === '%') {
      return `${test.value}%`;
    }

    //todo: add value and unit convertion

    return [test.value, test.units].join(' ');
  }

  copy = (test: PersonLabResult) => this.onCopy.emit(test);
}
