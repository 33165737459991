import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-visit-payments',
  templateUrl: './visit-payments.component.html',
  styleUrls: ['./visit-payments.component.scss']
})
export class VisitPaymentsComponent implements OnInit, OnDestroy, OnChanges {
  destroy$ = new Subject<void>();

  @Input() disabled = false;
  @Input() discount = 0;
  @Input() total = 0;
  @Input() paymentType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  @Input() payed = 0;
  @Input() balance = 0;

  @Input() canChangePaymentSource = false;

  @Input() omsEnabled = false;
  @Input() dmsEnabled = false;
  @Input() contractEnabled = false;
  @Input() budgetEnabled = false;
  @Input() balanceEnabled = false;

  @Output()
  onPaymentChange = new EventEmitter<number>();

  @Output()
  onPaymentTypeChange = new EventEmitter<number>();

  paymentTypes: PaymentType[] = [];

  debt: number;

  paymentTypeControl = new FormControl(1);
  paymentControl = new FormControl();
  totalControl = new FormControl();

  form = new FormGroup({
    payment: this.paymentControl,
    type: this.paymentTypeControl,
    total: this.totalControl
  });

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: FormValue): void => {
        this.debt = value.total - value.payment;
        this.onPaymentChange.emit(value.type === 1 ? value.payment : value.total);
      });

    this.paymentTypeControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number) => this.onPaymentTypeChange.emit(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const value: FormValue = this.form.getRawValue();

    if (changes["payed"]) {
      value.payment = changes["payed"].currentValue;
    }

    if (changes["total"]) {
      value.total = changes["total"].currentValue;
    }

    if (changes["paymentType"]) {
      value.type = changes["paymentType"].currentValue;
    }

    if (changes['disabled']) {
      const disabled = changes['disabled'].currentValue;

      if (disabled) {
        this.form.enabled ? this.form.disable({ emitEvent: false }) : undefined;
      } else {
        this.form.disabled ? this.form.enable({ emitEvent: false }) : undefined;
      }
    }

    this.form.patchValue(value, { emitEvent: false });
    this.debt = value.total - value.payment;

    this.paymentTypes = [];

    if (this.canChangePaymentSource || ([1, 2, 7].includes(this.paymentType))) {
      this.paymentTypes.push({ id: 1, text: 'Оплата наличными' });
      this.paymentTypes.push({ id: 2, text: 'Оплата картой' });

      if (this.balanceEnabled || this.paymentType === 7) {
        this.paymentTypes.push({ id: 7, text: "Баланс" });
      }
    }

    if (this.omsEnabled && (this.canChangePaymentSource || this.paymentType === 3)) {
      this.paymentTypes.push({ id: 3, text: 'ОМС' });
    }

    if (this.dmsEnabled && (this.canChangePaymentSource || this.paymentType === 4)) {
      this.paymentTypes.push({ id: 4, text: 'ДМС' });
    }

    if (this.contractEnabled && (this.canChangePaymentSource || this.paymentType === 5)) {
      this.paymentTypes.push({ id: 5, text: 'Договор' });
    }

    if (this.budgetEnabled && (this.canChangePaymentSource || this.paymentType === 6)) {
      this.paymentTypes.push({ id: 6, text: 'Бюджет' });
    }

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get remainingBalance(): number { return (this.balance + this.payed) - this.total; }
  get displayedTotal(): string { return this.totalControl.value > 0 ? this.totalControl.value.toLocaleString('ru-RU', this.currencyFormat) : "-"; }
  get displayedBalance(): string { return this.balance.toLocaleString('ru-RU', this.currencyFormat); }
  get displayedDebt(): string { return (this.debt) ? Math.abs(this.debt).toLocaleString('ru-RU', this.currencyFormat) : ""; }
  get displayedRemaningBalance(): string { return this.remainingBalance.toLocaleString('ru-RU', this.currencyFormat); }

  get hasDebt(): boolean { return this.debt > 0; }
  get hasChange(): boolean { return this.debt < 0; }

  get isEven(): boolean { return this.debt === 0; }
  get showPaymentControls(): boolean { return this.paymentTypeControl.value === 1; }
  get showTotal(): boolean { return [1, 2, 7].includes(this.paymentTypeControl.value); }
  get showBalance(): boolean { return this.paymentTypeControl.value === 7; }
}

interface PaymentType {
  id: number;
  text: string;
}

interface FormValue {
  payment: number;
  type: 1 | 2 | 3 | 4;
  total: number;
}
