import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FavoritePrescriptionItem } from '../../../../generated/models';

@Component({
  selector: 'mp-prescriptions-favorites-item',
  templateUrl: './prescriptions-favorites-item.component.html',
  styleUrls: ['./prescriptions-favorites-item.component.scss']
})
export class PrescriptionsFavoritesItemComponent implements OnInit {

  @Input() item: FavoritePrescriptionItem = {};

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();
}
