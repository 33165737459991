import { Component, OnInit, Input } from '@angular/core';
import { UsersTemplate } from '../../../../generated/models';

@Component({
  selector: 'mp-templates-list-item',
  templateUrl: './templates-list-item.component.html',
  styleUrls: ['./templates-list-item.component.scss']
})
export class TemplatesListItemComponent implements OnInit {

  @Input() item: UsersTemplate;

  constructor() { }

  ngOnInit() { }

}
