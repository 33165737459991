import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Attending, Company, Speciality } from '../../../../generated/models';

@Component({
  selector: 'mp-attendance-filters',
  templateUrl: './attendance-filters.component.html',
  styleUrls: ['./attendance-filters.component.scss']
})
export class AttendanceFiltersComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() filters: AttendanceFilters = {
    period: { from: undefined, to: undefined },
    date: undefined,
    companies: [],
    doctors: [],
    specialities: [],
  };

  @Input() companies: Company[] = [];
  @Input() doctors: Attending[] = [];
  @Input() specialities: Speciality[] = [];

  @Output() onFiltersChanged = new EventEmitter<AttendanceFilters>();
  @Output() onLocalFiltersChanged = new EventEmitter<AttendanceFilters>();

  form: FormGroup = new FormGroup({
    period: new FormControl(),
    date: new FormControl(),
    companies: new FormControl([]),
    doctors: new FormControl([]),
    specialities: new FormControl([]),
  });

  constructor() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: AttendanceFilters, b: AttendanceFilters): boolean => {
          if (!a.period.from && !!b.period.from) return false;
          if (!a.period.to && !!b.period.to) return false;

          if (!!a.period && !a.period.from.isSame(b.period.from, 'date')) return false;
          if (!!a.period.to && !a.period.to.isSame(b.period.to, 'date')) return false;

          //if (!a.date && !!b.date) return false;

          //if (!a.date.isSame(b.date)) return false;

          if (!a.companies && !!b.companies || !!a.companies && !b.companies) return false;

          if (JSON.stringify(a.companies.sort()) !== JSON.stringify(b.companies.sort())) return false;

          if (JSON.stringify(a.doctors.sort()) !== JSON.stringify(b.doctors.sort())) return false;
          if (JSON.stringify(a.specialities.sort()) !== JSON.stringify(b.specialities.sort())) return false;

          return true;
        })
      )
      .subscribe((value: AttendanceFilters) => this.onFiltersChanged.emit(value));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      this.form.patchValue(changes['filters'].currentValue, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

export interface AttendanceFilters {
  period: {
    from: moment.Moment;
    to: moment.Moment;
  },
  date: moment.Moment;
  companies: number[];
  doctors: number[];
  specialities: number[];
}
