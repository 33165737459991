import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { getUserCertificates, createAttachedSignature, createHash, Certificate, createDetachedSignature } from 'crypto-pro';
import { FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MyService } from '../../../../generated/services';

import * as moment from 'moment';

@Component({
  selector: 'mp-sign-document-modal',
  templateUrl: './sign-document-modal.component.html',
  styleUrls: ['./sign-document-modal.component.scss'],
  /*changeDetection: ChangeDetectionStrategy.OnPush*/
})
export class SignDocumentModalComponent implements OnInit {

  @Input() path: string;
  @Input() canPrint = false;
  @Input() filename: string;
  @Input() recordId: number;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  loading = true;
  hasError = false;

  certificates: Certificate[] = [];

  url: SafeResourceUrl;

  @ViewChild("Content") content: ElementRef<HTMLFrameElement>;

  certificateControl: FormControl;

  constructor(private domSanitizer: DomSanitizer, private httpClient: HttpClient, private myService: MyService) {

    this.certificateControl = new FormControl(undefined, [Validators.required]);

    getUserCertificates().then(
      (certificates) => {
        this.certificates = certificates;
        this.loading = false;
      },
      () => {
        this.hasError = true;
        this.loading = false;
      }
    );
  }

  ngOnInit() {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.path);
  }

  get disabled(): boolean {
    return !this.certificateControl.value;
  }

  async submit(): Promise<void> {
    if (this.certificateControl.invalid) return;

    const certificate: Certificate = this.certificateControl.value;

    const tokenResult = await this.myService.AccessToken({
      parameters: {
        id: this.recordId.toString(),
        signature: JSON.stringify({
          Id: certificate.thumbprint,
          Owner: certificate.name,
          ValidFrom: moment(certificate.validFrom).format('DD.MM.YYYY'),
          ValidTill: moment(certificate.validTo).format("DD.MM.YYYY")
        })
      }
    }).toPromise();

    const path = `/records/preview?token=${tokenResult.token}&type=pdf`;

    const buffer = await this.httpClient.get(path, { responseType: 'arraybuffer' }).toPromise();
    const hash = await createHash(buffer)

    const value = await createDetachedSignature(certificate.thumbprint, hash);

    const download = (file: File, name: string) => {
      const url = URL.createObjectURL(file);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;

      document.body.appendChild(a);
      a.click();

      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }

    download(new File([value], `${this.filename}.sgn`), `${this.filename}.sgn`);
    download(new File([buffer], `${this.filename}.pdf`), `${this.filename}.pdf`);

  }

  close = (): void => {
    this.onClose.emit();
  }
}
