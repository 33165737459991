import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ServiceGroup } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-groups-modal',
  templateUrl: './groups-modal.component.html',
  styleUrls: ['./groups-modal.component.scss']
})
export class GroupsModalComponent implements OnInit {
  destroy$ = new Subject<void>();
  result$ = new BehaviorSubject<ServiceGroup[]>([]);

  @Input() items: ServiceGroup[] = [];
  @Output() onConfirm = new EventEmitter<ServiceGroup>();
  @Output() onCancel = new EventEmitter<void>();

  item: ServiceGroup;

  search = new FormControl('');

  constructor() {
    this.search.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (!value) {
          this.result$.next([...this.items]);
          return;
        }

        const regex = new RegExp(value, 'i');

        this.result$.next([...this.items.filter(x => regex.test(x.name))]);

      })
  }

  ngOnInit() {
    this.result$.next(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.result$.next(changes['items'].currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  selected = (item: ServiceGroup) => this.item && this.item.id === item.id;
  select = (item: ServiceGroup) => this.item = item;

  cancel = () => this.onCancel.emit();
  confirm = () => this.onConfirm.emit(this.item);

  get canConfirm(): boolean { return !!this.item; }

}
