import { Component, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { VisitResult, SocialStatus } from '../../../../generated/models';
import { SkytecsValidators } from "@skytecs/controls";

@Component({
  selector: 'mp-oms',
  templateUrl: './oms.component.html',
  styleUrls: ['./oms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OmsComponent implements OnDestroy, OnChanges {
  destroy$ = new Subject<void>();

  disabilities: { id: number, name: string }[] = [{ id: 0, name: "Не указано" }, { id: 1, name: "I группа" }, { id: 2, name: "II группа" }, { id: 3, name: "III группа" }];
  citizenships: { id: number, name: string }[] = [{ id: 0, name: "Не указано" }, { id: 1, name: "Город" }, { id: 2, name: "Село" }];

  form = new FormGroup({
    snils: new FormControl("", [SkytecsValidators.snils()]),
    disability: new FormControl(undefined),
    socialStatus: new FormControl(undefined),
    result: new FormControl(undefined),
    citizenship: new FormControl(undefined)
  });

  @Input() disabled = false;

  @Input() data: OmsData;

  @Output()
  onChange = new EventEmitter<OmsData>();

  @Input() results: VisitResult[] = [];
  @Input() socialStatuses: SocialStatus[] = [];

  constructor() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(200),
        distinctUntilChanged((x: OmsData, y: OmsData): boolean => {
          return x.citizenship === y.citizenship
            && x.disability === y.disability
            && x.result === y.result
            && x.snils === y.snils
            && x.socialStatus === y.socialStatus;

        }))
      .subscribe((value: OmsData) => this.onChange.emit({ ...value }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['disabled']) {
      const value: boolean = changes['disabled'].currentValue;
      value ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
    }

    if (changes['data']) {
      const value: OmsData = changes['data'].currentValue;
      if (value) {
        this.form.patchValue(value, { emitEvent: false });
      } else {
        this.form.patchValue({}, { emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

export interface OmsData {
  snils?: string;
  disability?: 0 | 1 | 2 | 3;
  socialStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
  citizenship?: 0 | 1 | 2;
  result?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}
