/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonConsultation } from '../models/person-consultation';
import { PersonDiagnosis } from '../models/person-diagnosis';
import { PersonTestResult } from '../models/person-test-result';
import { PersonLabOrder } from '../models/person-lab-order';
@Injectable({
  providedIn: 'root',
})
class PersonsService extends __BaseService {
  static readonly ConsultationsAsyncPath = '/api/v2/persons/{personId}/consultations';
  static readonly DiagnosesAsyncPath = '/api/v2/persons/{personId}/diagnoses';
  static readonly ResultsAsyncPath = '/api/v2/persons/{personId}/results';
  static readonly LabOrdersAsyncPath = '/api/v2/persons/{personId}/lab/orders';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param personId undefined
   * @return Success
   */
  ConsultationsAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonConsultation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/consultations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonConsultation>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  ConsultationsAsync(personId: number): __Observable<Array<PersonConsultation>> {
    return this.ConsultationsAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonConsultation>)
    );
  }

  /**
   * @param personId undefined
   * @return Success
   */
  DiagnosesAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonDiagnosis>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonDiagnosis>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  DiagnosesAsync(personId: number): __Observable<Array<PersonDiagnosis>> {
    return this.DiagnosesAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonDiagnosis>)
    );
  }

  /**
   * @param personId undefined
   * @return Success
   */
  ResultsAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonTestResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/results`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonTestResult>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  ResultsAsync(personId: number): __Observable<Array<PersonTestResult>> {
    return this.ResultsAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonTestResult>)
    );
  }

  /**
   * @param personId undefined
   * @return Success
   */
  LabOrdersAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonLabOrder>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/lab/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonLabOrder>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  LabOrdersAsync(personId: number): __Observable<Array<PersonLabOrder>> {
    return this.LabOrdersAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonLabOrder>)
    );
  }
}

module PersonsService {
}

export { PersonsService }
