/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VisitReportResponse } from '../models/visit-report-response';
import { PreliminaryInvoiceRequest } from '../models/preliminary-invoice-request';
@Injectable({
  providedIn: 'root',
})
class VisitReportsService extends __BaseService {
  static readonly PrintVisitDocumentsPath = '/api/visits/{visitId}/reports/documents.{type}';
  static readonly PrintVisitContractPath = '/api/visits/{visitId}/reports/contract.{type}';
  static readonly PrintInvoiceReportsGroupPath = '/api/visits/{visitId}/reports/invoice.{type}';
  static readonly PrintAgreementReportGroupPath = '/api/visits/{visitId}/reports/agreements.{type}';
  static readonly PrintAgreementReportPath = '/api/visits/{visitId}/reports/agreement.{type}';
  static readonly PrintConsentReportsGroupPath = '/api/visits/{visitId}/reports/consent.{type}';
  static readonly BuildPreliminaryInvoiceAsyncPath = '/api/v2/visits/invoice/preliminary';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VisitReportsService.PrintVisitDocumentsParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintVisitDocumentsResponse(params: VisitReportsService.PrintVisitDocumentsParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/documents.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintVisitDocumentsParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintVisitDocuments(params: VisitReportsService.PrintVisitDocumentsParams): __Observable<VisitReportResponse> {
    return this.PrintVisitDocumentsResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param params The `VisitReportsService.PrintVisitContractParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintVisitContractResponse(params: VisitReportsService.PrintVisitContractParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/contract.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintVisitContractParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintVisitContract(params: VisitReportsService.PrintVisitContractParams): __Observable<VisitReportResponse> {
    return this.PrintVisitContractResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param params The `VisitReportsService.PrintInvoiceReportsGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintInvoiceReportsGroupResponse(params: VisitReportsService.PrintInvoiceReportsGroupParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/invoice.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintInvoiceReportsGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintInvoiceReportsGroup(params: VisitReportsService.PrintInvoiceReportsGroupParams): __Observable<VisitReportResponse> {
    return this.PrintInvoiceReportsGroupResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param params The `VisitReportsService.PrintAgreementReportGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintAgreementReportGroupResponse(params: VisitReportsService.PrintAgreementReportGroupParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/agreements.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintAgreementReportGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintAgreementReportGroup(params: VisitReportsService.PrintAgreementReportGroupParams): __Observable<VisitReportResponse> {
    return this.PrintAgreementReportGroupResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param params The `VisitReportsService.PrintAgreementReportParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintAgreementReportResponse(params: VisitReportsService.PrintAgreementReportParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/agreement.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintAgreementReportParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintAgreementReport(params: VisitReportsService.PrintAgreementReportParams): __Observable<VisitReportResponse> {
    return this.PrintAgreementReportResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param params The `VisitReportsService.PrintConsentReportsGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintConsentReportsGroupResponse(params: VisitReportsService.PrintConsentReportsGroupParams): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.asCopy != null) __params = __params.set('asCopy', params.asCopy.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${params.visitId}/reports/consent.${params.type}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param params The `VisitReportsService.PrintConsentReportsGroupParams` containing the following parameters:
   *
   * - `visitId`:
   *
   * - `type`:
   *
   * - `asCopy`:
   *
   * @return Success
   */
  PrintConsentReportsGroup(params: VisitReportsService.PrintConsentReportsGroupParams): __Observable<VisitReportResponse> {
    return this.PrintConsentReportsGroupResponse(params).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  BuildPreliminaryInvoiceAsyncResponse(request?: PreliminaryInvoiceRequest): __Observable<__StrictHttpResponse<VisitReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/visits/invoice/preliminary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  BuildPreliminaryInvoiceAsync(request?: PreliminaryInvoiceRequest): __Observable<VisitReportResponse> {
    return this.BuildPreliminaryInvoiceAsyncResponse(request).pipe(
      __map(_r => _r.body as VisitReportResponse)
    );
  }
}

module VisitReportsService {

  /**
   * Parameters for PrintVisitDocuments
   */
  export interface PrintVisitDocumentsParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }

  /**
   * Parameters for PrintVisitContract
   */
  export interface PrintVisitContractParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }

  /**
   * Parameters for PrintInvoiceReportsGroup
   */
  export interface PrintInvoiceReportsGroupParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }

  /**
   * Parameters for PrintAgreementReportGroup
   */
  export interface PrintAgreementReportGroupParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }

  /**
   * Parameters for PrintAgreementReport
   */
  export interface PrintAgreementReportParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }

  /**
   * Parameters for PrintConsentReportsGroup
   */
  export interface PrintConsentReportsGroupParams {
    visitId: number;
    type: string;
    asCopy?: boolean;
  }
}

export { VisitReportsService }
