import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeCompany } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-company',
  templateUrl: './employee-company.component.html',
  styleUrls: ['./employee-company.component.scss']
})
export class EmployeeCompanyComponent implements OnInit {

  @Input() company: EmployeeCompany;

  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get cashboxName(): string {
    return !this.company || !this.company.cashboxId ? 'Чековый принтер не выбран' : `Чековый принтер ${this.company.cashboxName}`;
  }

  get documentPrinterName(): string {
    return !this.company || !this.company.documentPrinterId ? 'Принтер не выбран' : `Принтер ${this.company.documentPrinterName}`;
  }

  get barcodePrinterName(): string {
    return !this.company || !this.company.barcodePrinterId ? 'Штрих-принтер не выбран' : `Штрих-принтер ${this.company.barcodePrinterName}`;
  }

  remove(): void {
    this.onRemove.emit();
  }
}
