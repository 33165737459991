import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { RecordSection } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'mp-text-section-editor',
  templateUrl: './text-section-editor.component.html',
  styleUrls: ['./text-section-editor.component.scss']
})
export class TextSectionEditorComponent implements OnInit, OnChanges {
  @Input() section: RecordSection;
  @Input() disabled: boolean;
  @Input() readonly: boolean;

  @Output() onChange = new EventEmitter<RecordSection>();

  get modules() {
    if (!this.section) return {};

    if (this.section.allowFormatting) {
      return ({ toolbar: [['bold', 'italic', 'underline'], ['clean'], [{ 'list': 'ordered' }, { 'list': 'bullet' }]] });
    }

    return ({ toolbar: false });
  }

  control = new FormControl("");;
  constructor() {
    this.control.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((value: string) => this.onChange.emit({ ...this.section, content: value }));
  }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      const value: RecordSection = changes['section'].currentValue;
      if (value && this.control.value !== value.content) this.control.setValue(value.content, { emitEvent: false });
    }

    if (changes['disabled']) {
      const value: boolean = changes['disabled'].currentValue;
      value ? this.control.disable({ emitEvent: false }) : this.control.enable({ emitEvent: false });
    }
  }
}
