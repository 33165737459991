import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppointmentHistoryItem } from '../../../../generated/models';

@Component({
  selector: 'mp-appointment-action-item',
  templateUrl: './appointment-action-item.component.html',
  styleUrls: ['./appointment-action-item.component.scss']
})
export class AppointmentActionItemComponent {
  @Input() item: AppointmentHistoryItem;
  @Output() onCopy = new EventEmitter<string>();


  copy = () => this.onCopy.emit(this.item.phone);
}
