import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ConnectedPatient } from '../../../../generated/models';

@Component({
  selector: 'mp-connected-patients-list-item',
  templateUrl: './connected-patients-list-item.component.html',
  styleUrls: ['./connected-patients-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectedPatientsListItemComponent implements OnInit {

  @Input() patient: ConnectedPatient;
  @Output() onOpen = new EventEmitter<void>();

  constructor() { }
  ngOnInit() { }

  get phone(): string {
    if (!this.patient) return "";

    if (!this.patient.phone || this.patient.phone.length !== 11) return this.patient.phone;

    return `+7 (${this.patient.phone.substr(1, 3)}) ${this.patient.phone.substr(4, 3)}-${this.patient.phone.substr(7, 2)}-${this.patient.phone.substr(9, 2)}`
  }

  open = () => this.onOpen.emit();
}
