import { OnInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeePayload } from '../../resolvers/employee.resolver';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import * as moment from "moment";
import { EmployeesService, CompaniesService, EmployeeCompaniesService } from 'projects/Clinic/src/app/generated/services';
import { EmployeeEditorModel } from 'projects/Clinic/src/app/generated/models/employee-editor-model';
import { EmployeeSpeciality } from 'projects/Clinic/src/app/generated/models/employee-speciality';
import { PartnerPlanListItem } from 'projects/Clinic/src/app/generated/models/partner-plan-list-item';
import { EmployeeSpecialitiesResponse } from 'projects/Clinic/src/app/generated/models/employee-specialities-response';
import { EmployeesAvailableSpecialitiesResponse, EmployeePartner, EmployeeAbsence, EmployeePartnersReponse, EmployeeAbsencesResponse, EmployeeService, EmployeeServicesResponse, Speciality, ServiceCategory, Company, EmployeeCompany, EmployeeCompaniesResponse, EmployeeAppointment, EmployeeAppointmentsResponse, EmployeeGenerateApiKeyResponse, EmployeeRole, EmployeeRolesResponse, EditEmployeeServiceModel, CodeSystemValue, CodeSystem, EmployeeWageSchema, WageSchema, EmployeeWageSchemasResponse } from 'projects/Clinic/src/app/generated/models';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeSpecialityModalComponent } from '../employee-speciality.modal/employee-speciality.modal.component';
import { EmployeeAbsenceModalComponent } from '../employee-absence.modal/employee-absence.modal.component';
import { EmployeeCompanyModalComponent, EmployeeCompanyPayload } from '../employee-company.modal/employee-company.modal.component';
import { EmployeeServiceModalComponent } from '../employee-service.modal/employee-service.modal.component';
import { EmployeePartnerModalComponent } from '../employee-partner-modal/employee-partner-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { EmployeeRoleModalComponent } from '../employee-role-modal/employee-role-modal.component';
import { ToastrService } from 'ngx-toastr';
import { EmployeePbxNumber } from '../../../../generated/models/employee-pbx-number';
import { EmployeePbxPhoneNumbersResponse } from '../../../../generated/models/employee-pbx-phone-numbers-response';
import { EmployeePhoneModalComponent } from '../employee-phone-modal/employee-phone-modal.component';
import { PbxOperatorViewModel } from '../../../../generated/models/pbx-operator-view-model';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ValueSetsService } from '../../../../generated/services/value-sets.service';
import { EmployeeCodeSystemModalComponent } from '../employee-code-system-modal/employee-code-system-modal.component';
import { EmployeeWageSchemaModalComponent, EmployeeWageSchemaModalPayload } from '../employee-wage-schema-modal/employee-wage-schema-modal.component';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mp-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
  host: { class: "page" },
})
export class EmployeeComponent implements OnInit {
  title: string;
  id: number;
  blocked = false;

  submitted: boolean;

  canGenerateApiKey = false;
  canBlock = false;
  canManageRoles = false;

  canManageWageSchemas: boolean;

  plans: PartnerPlanListItem[] = [];
  specialities: Speciality[] = [];
  categories: ServiceCategory[] = [];
  companies: Company[] = [];
  phones: PbxOperatorViewModel[] = [];
  codeSystems: CodeSystem[] = [];
  wageSchemas: WageSchema[];

  employeesSpecialities: EmployeeSpeciality[] = [];
  partners: EmployeePartner[] = [];
  absences: EmployeeAbsence[] = [];
  services: EmployeeService[] = [];
  employeesCompanies: EmployeeCompany[] = [];
  employeesAppointments: EmployeeAppointment[] = [];
  employeesRoles: EmployeeRole[] = [];
  employeesPbxNumbers: EmployeePbxNumber[] = [];
  employeeCodeSystemValues: CodeSystemValue[] = [];

  size = 50;

  specialitiesPage = 1;
  partnersPage = 1;
  absencesPage = 1;
  servicePage = 1;
  appointmentPage = 1;
  rolesPage = 1;
  pbxPhonePage = 1;

  get isNew(): boolean { return !this.id; }

  form: FormGroup;

  companiesForm: FormGroup;
  servicesForm: FormGroup;
  absencesForm: FormGroup;
  partnersForm: FormGroup;
  appointmentsForm: FormGroup;
  phonesForm: FormGroup;

  get hasAccount(): boolean {
    return this.form.get("hasAccount").value;
  }

  employeeWageSchemas: EmployeeWageSchema[] = [];

  employeesCompanies$: BehaviorSubject<EmployeeCompany[]> = new BehaviorSubject([]);

  constructor(
    private activatedRoute: ActivatedRoute,
    private employeesService: EmployeesService,
    private employeeCompaniesService: EmployeeCompaniesService,
    private companiesService: CompaniesService,
    private valueSetsService: ValueSetsService,
    private router: Router,
    private modal: NgbModal,
    private toastrService: ToastrService,
    private userStorage: UserStorage
  ) {
    this.title = "Сотрудник"

    this.canManageRoles = userStorage.hasPermission(PermissionNames.ManageUserRoles);
    this.canManageWageSchemas = userStorage.hasPermission(PermissionNames.ManageWageSchemas);

    this.form = new FormGroup({
      lastname: new FormControl("", [Validators.required]),
      firstname: new FormControl("", [Validators.required]),
      middlename: new FormControl(""),

      position: new FormControl(""),
      compensationType: new FormControl(null),
      snils: new FormControl(""),

      partnerPlan: new FormControl(null),

      hired: new FormControl("", [Validators.required]),
      fired: new FormControl(""),

      hasAccount: new FormControl(""),
      login: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [Validators.required]),
      apiKey: new FormControl("")
    });

    this.form.get("apiKey").disable();

    this.servicesForm = new FormGroup({
      search: new FormControl(""),
      show: new FormControl(0),
      category: new FormControl(0),
      speciality: new FormControl(0)
    });

    this.absencesForm = new FormGroup({
      period: new FormControl({ from: moment().add(-6, "years"), to: moment().add(1, "months") })
    });

    this.partnersForm = new FormGroup({
      search: new FormControl(""),
      company: new FormControl(0),
      plan: new FormControl(0)
    });


    this.companiesForm = new FormGroup({
      search: new FormControl("")
    });

    this.appointmentsForm = new FormGroup({
      search: new FormControl(""),
      period: new FormControl({ from: moment().subtract(1, "months"), to: moment() }),
      confirmed: new FormControl(true),
      canceled: new FormControl(true),
      pending: new FormControl(true)
    });

    this.phonesForm = new FormGroup({
      search: new FormControl("")
    });

    this.servicesForm.valueChanges.subscribe(
      (value: any): void => {
        this.services = [];
        this.servicePage = 1;

        this.loadServices();
      }
    );

    this.absencesForm.valueChanges.subscribe(
      (): void => {
        this.absences = [];
        this.absencesPage = 1;

        this.loadAbsences();
      }
    );

    this.partnersForm.valueChanges.subscribe(
      (): void => {
        this.partners = [];
        this.partnersPage = 1;

        this.loadPartners();
      }
    );

    this.companiesForm.valueChanges.subscribe(
      (value: { search: string }): void => {

        if (!value.search) {
          this.employeesCompanies$.next([...this.employeesCompanies]);
        } else {
          const regex = new RegExp(value.search, 'i');

          this.employeesCompanies$.next(this.employeesCompanies.filter(x => regex.test(x.companyName)));
        }
      }
    );

    this.appointmentsForm.valueChanges.subscribe(
      (): void => {
        this.appointmentPage = 1;
        this.employeesAppointments = [];

        this.loadAppointments();
      }
    );

    this.phonesForm.valueChanges.subscribe(
      (): void => {
        this.pbxPhonePage = 1;
        this.employeesPbxNumbers = [];

        this.loadPbxPhoneNumbers();
      }
    )

    this.form.get("hasAccount").valueChanges.subscribe(
      (value: boolean): void => {
        if (value) {
          this.form.get("login").enable();
          this.form.get("phone").enable();
          this.canGenerateApiKey = true;

        } else {
          this.form.get("login").disable();
          this.form.get("phone").disable();

          this.form.get("login").reset("");
          this.form.get("phone").reset("");
          this.form.get("apiKey").reset("");

          this.canGenerateApiKey = false;
        }
      }
    );
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe(
      (data: { payload: EmployeePayload }): void => {
        this.id = data.payload.employee.id;

        if (this.id) {
          this.setupForm(data.payload.employee);
        } else {
          this.title = `Сотрудник: новый`;
          this.form.patchValue({ hasAccount: false });
        }

        this.plans = data.payload.plans;
        if (this.id > 0) {
          if (!this.plans.some(x => x.id === data.payload.employee.partnerPlanId)) {
            this.plans.unshift({ id: data.payload.employee.partnerPlanId, name: data.payload.employee.partnerPlanName });
          }
        }

        this.categories = data.payload.categories;
        this.specialities = data.payload.specialities;
        this.companies = data.payload.companies;
        this.phones = data.payload.phones;
        this.codeSystems = data.payload.codeSystems;

        this.wageSchemas = data.payload.wageSchemas;

        if (this.id) {
          this.loadSpecialities();
          this.loadPartners();
          this.loadAbsences();
          this.loadServices();
          this.loadCompanies();
          this.loadAppointments();
          this.loadRoles();
          this.loadPbxPhoneNumbers();
          this.loadWageSchemas();
          this.loadCodeSystemValues();
        }
      }
    );
  }

  handleEmployeeServiceError(response: HttpErrorResponse, message: string) {

    console.debug(response);

    if (response.status !== 400 || !response.error) {
      this.toastrService.error(message, 'Ошибка');
      return;
    }

    switch (response.error.status) {
      case -1: this.toastrService.warning('Для выполнения действия отсутствуют необходимые разрешения', 'Ошибка'); break;
      case 1: this.toastrService.warning('Сотрудник или партнер не найден', 'Ошибка'); break;
      case 2: this.toastrService.warning('Необходимо указать дату приема сотрудника на работу', 'Ошибка'); break;
      case 3: this.toastrService.warning('Необходимо указать адрес электронной почты', 'Ошибка'); break;
      case 4: this.toastrService.warning('Необходимо указать номер телефона', 'Ошибка'); break;
      case 5: this.toastrService.warning('Адрес электронной почты используется другим сотрудником', 'Ошибка'); break;
      case 6: this.toastrService.warning('Сервис недоступен. Попробуйте повторить запрос позднее', 'Ошибка'); break;
      case 7: this.toastrService.warning('Учетная запись не найдена', 'Ошибка'); break;
      case 8: this.toastrService.warning('Необходимо указать фамилию сотрудника', 'Ошибка'); break;
      case 9: this.toastrService.warning('Необходимо указать имя сотрудника', 'Ошибка'); break;
      case 10: this.toastrService.warning('Организация не найдена', 'Ошибка'); break;
      case 11: this.toastrService.warning('Организация выбранного типа не может быть использована', 'Ошибка'); break;
      case 12: this.toastrService.warning('Оператор не найден', 'Ошибка'); break;
      case 13: this.toastrService.warning('Номер телефона уже назначен для выбранного пользователя и компании', 'Ошибка'); break;
      case 14: this.toastrService.warning('Номер телефона сотрудника не найден', 'Ошибка'); break;
      case 15: this.toastrService.warning('Указанный способ передачи сообщения не поддерживается', 'Ошибка'); break;
      case 16: this.toastrService.warning('Шаблон SMS-сообщения для передачи нового пароля пользователю не задан', 'Ошибка'); break;
      case 17: this.toastrService.warning('Тема письма для передачи нового пароля пользователю не задана', 'Ошибка'); break;
      case 18: this.toastrService.warning('Шаблон письма для передачи нового пароля пользователю не задана', 'Ошибка'); break;
      case 19: this.toastrService.warning('Номер телефона уже используется другой учетной записью', 'Ошибка'); break;
      case 20: this.toastrService.warning('Адрес электронной почты уже используется другой учетной записью', 'Ошибка'); break;
      case 21: this.toastrService.warning('Ключ API уже используется другой учетной записью', 'Ошибка'); break;
      case 22: this.toastrService.warning('Указанная схема оплаты не найдена', 'Ошибка'); break;
      case 23: this.toastrService.warning('Необходимо указать дату начала действия схемы оплаты для сотрудника', 'Ошибка'); break;
      case 24: this.toastrService.warning('Неверный формат даты начала действия схемы оплаты. Ожидается дата в формате dd.MM.yyyy', 'Ошибка'); break;
      case 25: this.toastrService.warning('Неверный формат даты окончания действия схемы оплаты. Ожидается дата в формате dd.MM.yyyy', 'Ошибка'); break;
      case 26: this.toastrService.warning('Схема оплаты сотрудника не найдена', 'Ошибка'); break;
      case 27: this.toastrService.warning('Схема оплаты не принадлежит указанному пользователю', 'Ошибка'); break;
      case 28: this.toastrService.warning('Партнерская программа не найдена', 'Ошибка'); break;

      default: this.toastrService.warning(message, 'Ошибка'); break;
    }
  }

  acceptChanges(): void {

    const hasAccount: boolean = this.form.get("hasAccount").value;

    if (this.id) {
      this.employeesService.Update({
        id: this.id,
        model: {
          firstname: this.form.get("firstname").value,
          middlename: this.form.get("middlename").value,
          lastname: this.form.get("lastname").value,
          position: this.form.get("position").value,
          compensationType: this.form.get("compensationType").value,
          snils: this.form.get("snils").value,

          partnerPlanId: this.form.get("partnerPlan").value,

          hired: (this.form.get("hired").value && this.form.get("hired").value.isValid()) ? this.form.get("hired").value.format("DD.MM.YYYY") : "",
          fired: (this.form.get("fired").value && this.form.get("fired").value.isValid()) ? this.form.get("fired").value.format("DD.MM.YYYY") : "",

          hasAccount: hasAccount,

          login: hasAccount ? this.form.get("login").value : "",
          phone: hasAccount ? this.form.get("phone").value : "",
          apiKey: hasAccount && this.form.get('apiKey').value ? this.form.get("apiKey").value : null
        }
      })
        .subscribe(
          (): void => {
            this.title = `Сотрудник: ${this.form.get("lastname").value} ${this.form.get("firstname").value} ${this.form.get("middlename").value}`;

            this.toastrService.success("Сотрудник сохранен", "Успешно");
            this.canBlock = hasAccount;
          },

          (response: HttpErrorResponse): void => {
            this.form.markAsDirty();

            this.handleEmployeeServiceError(response, "Не удалось сохранить сотрудника");
          }
        );
    } else {
      this.employeesService.Create({
        firstname: this.form.get("firstname").value,
        middlename: this.form.get("middlename").value,
        lastname: this.form.get("lastname").value,
        position: this.form.get("position").value,
        compensationType: this.form.get("compensationType").value,
        snils: this.form.get("snils").value,

        partnerPlanId: this.form.get("partnerPlan").value,

        hired: (this.form.get("hired").value && this.form.get("hired").value.isValid()) ? this.form.get("hired").value.format("DD.MM.YYYY") : "",
        fired: (this.form.get("fired").value && this.form.get("fired").value.isValid()) ? this.form.get("fired").value.format("DD.MM.YYYY") : "",

        hasAccount: hasAccount,

        login: hasAccount ? this.form.get("login").value : "",
        phone: hasAccount ? this.form.get("phone").value : "",
        apiKey: hasAccount && this.form.get('apiKey').value ? this.form.get("apiKey").value : null
      })
        .subscribe(
          (created: EmployeeEditorModel): void => {
            this.toastrService.success("Сотрудник добавлен", "Успешно");
            this.router.navigate(["settings", "employees", created.id]);
          },
          (response: HttpErrorResponse): void => {
            this.form.markAsDirty();

            this.handleEmployeeServiceError(response, "Не удалось добавить сотрудника");
          }
        );
    }
  }

  public onScrollPartners(): void {
    this.partnersPage++;
    this.loadPartners();
  }

  public onScrollAbsences(): void {
    this.absencesPage++;
    this.loadAbsences();
  }

  public onScrollServices(): void {
    this.servicePage++;
    this.loadServices();
  }

  public onScrollAppointments(): void {
    this.appointmentPage++;
    this.loadAppointments();
  }

  public onScrollRoles(): void {
    this.rolesPage++;

    this.loadRoles();
  }

  async generateApiKey() {
    const response = await this.employeesService.GenerateApiKey(this.id).toPromise();

    this.form.get("apiKey").setValue(response.apiKey);
  }

  public addSpeciality(): void {
    this.employeesService.AvailableSpecialities(this.id)
      .subscribe(
        (response: EmployeesAvailableSpecialitiesResponse): void => {
          const modalRef: NgbModalRef = this.modal.open(EmployeeSpecialityModalComponent, {
            backdrop: "static",
            size: "lg"
          });

          modalRef.componentInstance.specialities = response.specialities;
          modalRef.componentInstance.employeeId = this.id;

          modalRef.result.then(
            (): void => {
              this.specialitiesPage = 1;
              this.employeesSpecialities = [];
              this.loadSpecialities();

              this.servicePage = 1;
              this.services = [];
              this.loadServices();
            },
            (): void => { }
          );
        });

  }

  public addAbsence(): void {
    const modalRef: NgbModalRef = this.modal.open(EmployeeAbsenceModalComponent, { backdrop: "static", size: "lg" });

    modalRef.result.then(
      (): void => {
        this.absences = [];
        this.absencesPage = 1;
        this.loadAbsences();
      },
      (): void => { }
    );

    modalRef.componentInstance.employeeId = this.id;

  }

  public addCompany(): void {
    const company: EmployeeCompany = {
      employeeId: this.id,
    };

    this.employeeCompaniesService.AvailableCompanies(this.id)
      .subscribe((companies: Company[]): void => {

        const options: NgbModalOptions = { backdrop: "static", size: "lg" };
        const modalRef: NgbModalRef = this.modal.open(EmployeeCompanyModalComponent, options);

        const componentRef: EmployeeCompanyModalComponent = modalRef.componentInstance;

        componentRef.company = company;
        componentRef.companies = companies;

        componentRef.onCancel.subscribe(() => {
          modalRef.close();
        });

        componentRef.onSubmit.subscribe((payload: EmployeeCompanyPayload) => {
          this.employeeCompaniesService.CreateAsync({
            id: this.id,
            request: {
              partner: payload.hasPartner,
              primary: payload.primary,
              receivingPatients: payload.receivingPatients,

              companyId: payload.companyId,
              cashboxId: payload.cashboxId,
              barcodePrinterId: payload.barcodePrinterId,
              documentPrinterId: payload.documentPrinterId
            }
          }).subscribe(
            (): void => {
              this.loadCompanies();

              modalRef.close();
            },
            (response: HttpErrorResponse): void => {
              this.toastrService.warning("Не удалось добавить компанию", "Ошибка");
            }
          );

        });
      });
  }

  public addRole(): void {
    const modalRef: NgbModalRef = this.modal.open(EmployeeRoleModalComponent, { backdrop: "static", size: "lg" });

    modalRef.componentInstance.employeeId = this.id;

    modalRef.result.then(
      (): void => {
        this.rolesPage = 1;
        this.employeesRoles = [];

        this.loadRoles();

        this.loadCompanies();
      },
      (): void => { }
    );

  }

  public addPhoneNumber(): void {
    const modalRef: NgbModalRef = this.modal.open(EmployeePhoneModalComponent, { backdrop: 'static', size: 'lg' });

    modalRef.componentInstance.companies = this.companies.filter((x: Company): boolean => x.type === 4);
    modalRef.componentInstance.pbxOperators = this.phones;

    modalRef.componentInstance.onClose.subscribe((): void => {
      modalRef.close();
    });

    modalRef.componentInstance.onSubmit.subscribe((value: { company: number, pbxOperator: number }): void => {

      this.employeesService.AddPhoneNumber({
        id: this.id,
        request: {
          companyId: value.company,
          pbxOperatorId: value.pbxOperator
        }
      })
        .subscribe(
          (response: EmployeePbxNumber): void => {
            modalRef.close();

            const index: number = this.employeesPbxNumbers.findIndex((x: EmployeePbxNumber): boolean => x.id === response.id);

            if (index === -1) {
              this.employeesPbxNumbers.unshift({ ...response });
            } else {
              this.employeesPbxNumbers.splice(index, 1, { ...response });
            }
          },
          (response: HttpErrorResponse): void => {
            switch (response.status) {
              case 400: {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }
              case 403: {
                this.toastrService.warning("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
                return;
              }
              default: {
                this.toastrService.warning("Не удалось добавить номер", "Ошибка");
              }
                break;
            }
          }
        );


    });
  }

  public addPartner(): void {

    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(EmployeePartnerModalComponent, options);
    const componentRef: EmployeePartnerModalComponent = modalRef.componentInstance;

    componentRef.plans = this.plans;
    componentRef.companies = this.companies;
    componentRef.employeeId = this.id;

    modalRef.result.then(
      (): void => {
        this.partnersPage = 1;
        this.partners = [];
        this.loadPartners();
      },
      (): void => { }
    );

  }

  addWageSchema(): void {
    const options: NgbModalOptions = { size: "lg", backdrop: "static" };
    const modalRef: NgbModalRef = this.modal.open(EmployeeWageSchemaModalComponent, options);
    const componentRef: EmployeeWageSchemaModalComponent = modalRef.componentInstance;

    componentRef.schemas = this.wageSchemas;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfitm.subscribe((payload: EmployeeWageSchemaModalPayload): void => {
      this.employeesService.AddWageSchema({
        id: this.id,
        request: {
          wageSchemaId: payload.schema,
          effective: payload.effective.format("DD.MM.YYYY"),
          expires: payload.expires ? payload.expires.format("DD.MM.YYYY") : ""
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("Схема оплаты добавлена", "Успешно");
            modalRef.close();
            this.loadWageSchemas();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
            }
            else {
              this.toastrService.error("Не удалось добавить схему оплаты", "Ошибка");
            }
          }
        )

    });
  }

  public addCodeSystemValue(): void {
    const modalRef: NgbModalRef = this.modal.open(EmployeeCodeSystemModalComponent, { backdrop: 'static', size: 'lg' });

    modalRef.componentInstance.codeSystems = this.codeSystems;

    modalRef.componentInstance.onClose.subscribe((): void => {
      modalRef.close();
    });

    modalRef.componentInstance.onSubmit.subscribe((value: { codeSystem: number, value: string }): void => {
      this.valueSetsService.Create({
        type: 'User', request: {
          codeSystemId: value.codeSystem,
          value: value.value,
          targetId: this.id
        }
      }).subscribe((): void => {
        modalRef.close();
        this.loadCodeSystemValues();
      },
        (response: HttpErrorResponse): void => {
          switch (response.status) {
            case 400: {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }
            case 403: {
              this.toastrService.warning("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
              return;
            }
            default: {
              this.toastrService.warning("Не удалось добавить номер", "Ошибка");
            }
              break;
          }
        })
    });
  }

  public editCompany(company: EmployeeCompany): void {
    this.companiesService.Companies({})
      .subscribe((companies: Company[]): void => {

        const options: NgbModalOptions = { backdrop: "static", size: "lg" };
        const modalRef: NgbModalRef = this.modal.open(EmployeeCompanyModalComponent, options);
        const componentRef: EmployeeCompanyModalComponent = modalRef.componentInstance

        componentRef.company = company;
        componentRef.companies = companies;

        componentRef.onCancel.subscribe(() => {
          modalRef.close();
        });

        componentRef.onSubmit.subscribe((payload: EmployeeCompanyPayload): void => {

          this.employeeCompaniesService.UpdateCompany({
            employeeId: this.id,
            companyId: company.companyId,
            request: {
              partner: payload.hasPartner,
              primary: payload.primary,
              receivingPatients: payload.receivingPatients,

              cashboxId: payload.cashboxId,
              barcodePrinterId: payload.barcodePrinterId,
              documentPrinterId: payload.documentPrinterId
            }
          })
            .subscribe(
              (): void => {
                this.loadCompanies();

                modalRef.close();
              },
              (response: HttpErrorResponse): void => {
                this.toastrService.warning("Не удалось обновить компанию", "Ошибка");
              }
            );
        });
      });
  }

  public enableService(service: EmployeeService): void {
    this.employeesService.EnableService({
      id: this.id,
      serviceId: service.serviceId
    })
      .subscribe(
        (): void => {
          service.forbidden = false;
        }
      );
  }

  public disableService(service: EmployeeService): void {
    this.employeesService.DisableService({
      id: this.id,
      serviceId: service.serviceId
    })
      .subscribe(
        (): void => {
          service.forbidden = true;
        }
      );
  }

  public editService(service: EmployeeService): void {
    const modalRef: NgbModalRef = this.modal.open(EmployeeServiceModalComponent, { backdrop: "static", size: "lg" });
    modalRef.componentInstance.service = service;

    modalRef.result.then(
      (updated: EditEmployeeServiceModel): void => {
        service.minAge = updated.minAge;
        service.maxAge = updated.maxAge;
      },
      (): void => { }
    );
  }

  public deleteCompany(company: EmployeeCompany): void {
    this.employeeCompaniesService.DeleteCompany({
      employeeId: this.id,
      companyId: company.companyId
    })
      .subscribe(
        (): void => {
          this.loadCompanies();
        });

  }

  public deleteSpeciality(speciality: EmployeeSpeciality): void {
    this.employeesService.DeleteSpeciality({
      id: this.id,
      specialityId: speciality.id
    })
      .subscribe(
        (): void => {
          this.specialitiesPage = 1;
          this.employeesSpecialities = [];
          this.loadSpecialities();

          this.servicePage = 1;
          this.services = [];
          this.loadServices();
        });
  }

  public deletePartner(partner: EmployeePartner): void {
    this.employeesService.DeletePartner({
      id: this.id,
      partnerId: partner.id
    })
      .subscribe(
        (): void => {
          this.partnersPage = 1;
          this.partners = [];

          this.loadPartners();
        });
  }

  public deleteAbsence(absence: EmployeeAbsence): void {
    this.employeesService.DeleteAbsence({
      id: this.id,
      absenceId: absence.id
    })
      .subscribe(
        (): void => {
          this.absencesPage = 1;
          this.absences = [];

          this.loadAbsences();
        }
      );
  }

  public deleteRole(role: EmployeeRole): void {
    this.employeesService.DeleteRole({
      id: this.id,
      roleId: role.id
    })
      .subscribe(
        (): void => {
          this.rolesPage = 1;
          this.employeesRoles = [];
          this.loadRoles();

          this.loadCompanies();
        }
      );
  }

  public deletePbxPhone(phone: EmployeePbxNumber): void {
    this.employeesService.DeletePhoneNumber({
      id: this.id,
      phoneId: phone.id
    })
      .subscribe(
        (): void => {
          const index: number = this.employeesPbxNumbers.findIndex((x: EmployeePbxNumber): boolean => x.id === phone.id);

          if (index !== -1) {
            this.employeesPbxNumbers.splice(index, 1);
          }
        },
        (response: HttpErrorResponse): void => {
          switch (response.status) {
            case 400: {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }
            case 403: {
              this.toastrService.warning("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
              return;
            }
            default: {
              this.toastrService.warning("Не удалось добавить номер", "Ошибка");
            }
              break;
          }

        }
      );
  }

  public deleteCodeSystem(item: CodeSystemValue): void {
    this.valueSetsService.Delete(item.id).subscribe(() => {
      const index: number = this.employeeCodeSystemValues.findIndex((x: CodeSystemValue): boolean => x.id === item.id);

      if (index !== -1) {
        this.employeeCodeSystemValues.splice(index, 1);
      }

      this.toastrService.success("Код удален", "Успешно")
    },
      (response: HttpErrorResponse): void => {
        switch (response.status) {
          case 400: {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }
          default: {
            this.toastrService.warning("Не удалось добавить код", "Ошибка");
          }
            break;
        }
      })
  }

  removeWageSchema(schema: EmployeeWageSchema): void {
    const options: NgbModalOptions = { centered: true, size: "sm", backdrop: "static" };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Схема оплаты ${schema.wageSchemaName} будет удалена из списка схем оплаты пользователя. продолжить?`;
    componentRef.confirmBtnText = "Продолжить";

    modalRef.result
      .then(
        (): void => {
          this.employeesService.RemoveWageSchema({ id: this.id, schemaId: schema.id })
            .subscribe(
              (): void => {
                this.toastrService.success("Схема оплаты удалена", "Успешно");
                this.loadWageSchemas();
              },
              (response: HttpErrorResponse): void => {
                if (response.status === 400) {
                  this.toastrService.warning(response.error.message, "Ошибка")
                }
                else {
                  this.toastrService.error("Не удалось удалить схему оплаты", "Ошибка")
                }
              }
            );
        },
        (): void => { }
      );

  }

  public block(): void {
    this.employeesService.Block(this.id)
      .subscribe(
        (): void => {
          this.blocked = true;
        });
  }

  public unblock(): void {
    this.employeesService.Unblock(this.id)
      .subscribe(
        (): void => {
          this.blocked = false;
        });
  }

  async loadCompanies(): Promise<void> {
    this.employeesCompanies = await this.employeeCompaniesService.CompaniesAsync(this.id)
      .pipe(map(x => x.items))
      .toPromise();

    const value = this.companiesForm.getRawValue();

    if (value.search) {
      const regex = new RegExp(value.search, 'i');
      this.employeesCompanies$.next(this.employeesCompanies.filter(x => regex.test(x.companyName)))
    } else {
      this.employeesCompanies$.next([...this.employeesCompanies]);
    }
  }

  private loadSpecialities(): void {

    this.employeesService.Specialities(
      {
        id: this.id,
        Page: this.specialitiesPage,
        Size: this.size
      })
      .subscribe(
        (response: EmployeeSpecialitiesResponse): void => {
          this.employeesSpecialities.push(...response.items);
        }
      );

  }

  private loadRoles(): void {
    this.employeesService.Roles({
      id: this.id,
      Page: this.rolesPage,
      Size: this.size
    })
      .subscribe(
        (response: EmployeeRolesResponse): void => {
          this.employeesRoles.push(...response.items);
        });

  }

  private loadPartners(): void {
    this.employeesService.Partners({
      id: this.id,
      Page: this.partnersPage,
      Size: this.size,
      Search: this.partnersForm.get("search").value,
      PlanId: this.partnersForm.get("plan").value > 0 ? this.partnersForm.get("plan").value : "",
      CompanyId: this.partnersForm.get("company").value > 0 ? this.partnersForm.get("company").value : ""

    })
      .subscribe(
        (response: EmployeePartnersReponse): void => {
          this.partners.push(...response.items);
        }
      );
  }

  private loadAbsences(): void {
    this.employeesService.Absences({
      id: this.id,
      Page: this.absencesPage,
      Size: this.size,
      From: (this.absencesForm.get("period").value.from) ? this.absencesForm.get("period").value.from.format("DD.MM.YYYY") : "",
      To: (this.absencesForm.get("period").value.to) ? this.absencesForm.get("period").value.to.format("DD.MM.YYYY") : "",
    }).subscribe(
      (response: EmployeeAbsencesResponse): void => {
        this.absences.push(...response.items);
      }
    );

  }

  async loadServices(): Promise<void> {

    const response = await this.employeesService.Services({
      id: this.id,
      Size: this.size,
      Page: this.servicePage,
      Search: this.servicesForm.get("search").value,
      CategoryId: this.servicesForm.get("category").value > 0 ? this.servicesForm.get("category").value : "",
      SpecialityId: this.servicesForm.get("speciality").value > 0 ? this.servicesForm.get("speciality").value : ""
    }).toPromise();

    this.services.push(...response.items);
  }

  private loadAppointments(): void {
    this.employeesService.Appointments({
      id: this.id,
      HideCanceled: !this.appointmentsForm.get("canceled").value,
      HideConfirmed: !this.appointmentsForm.get("confirmed").value,
      HidePending: !this.appointmentsForm.get("pending").value,
      Search: this.appointmentsForm.get("search").value,
      Page: this.appointmentPage,
      Size: this.size,
      From: (this.appointmentsForm.get("period").value.from) ? this.appointmentsForm.get("period").value.from.format("DD.MM.YYYY") : "",
      To: (this.appointmentsForm.get("period").value.to) ? this.appointmentsForm.get("period").value.to.format("DD.MM.YYYY") : "",
    })
      .subscribe(
        (response: EmployeeAppointmentsResponse): void => {
          this.employeesAppointments.push(...response.items);
        }
      );

  }

  private loadPbxPhoneNumbers(): void {
    this.employeesService.PbxPhoneNumbers({
      id: this.id,
      Page: this.pbxPhonePage,
      Size: this.size,
      Search: this.phonesForm.get("search").value
    })
      .subscribe(
        (response: EmployeePbxPhoneNumbersResponse): void => {
          this.employeesPbxNumbers.push(...response.items);
        }
      )
  }

  private loadWageSchemas(): void {
    this.employeesService.WageSchemas(this.id)
      .subscribe(
        (response: EmployeeWageSchemasResponse): void => {
          this.employeeWageSchemas = response.items;
        }
      );
  }

  private loadCodeSystemValues(): void {
    this.valueSetsService.Values({
      type: 'User',
      id: this.id
    }).subscribe((response: CodeSystemValue[]): void => {
      this.employeeCodeSystemValues = response;
    })
  }

  private setupForm(employee: EmployeeEditorModel): void {
    this.title = `Сотрудник: ${employee.lastname} ${employee.firstname} ${employee.middlename}`;

    const patch: any = {
      lastname: employee.lastname,
      firstname: employee.firstname,
      middlename: employee.middlename,

      position: employee.position,
      compensationType: employee.compensationType,
      snils: employee.snils,

      login: employee.login,
      apiKey: employee.apiKey,
      phone: employee.phone,
      partnerPlan: employee.partnerPlanId,

      hasAccount: employee.hasAccount
    };

    if (employee.hired) {
      patch.hired = moment(employee.hired, "DD.MM.YYYY");
    }

    if (employee.fired) {
      patch.fired = moment(employee.fired, "DD.MM.YYYY");
    }

    this.form.patchValue(patch);

    this.blocked = employee.blocked;

    if (employee.hasAccount) {
      this.canGenerateApiKey = true;
      this.canBlock = true;
    } else {
      this.form.get("login").disable();
      this.form.get("phone").disable();
      this.form.get("apiKey").disable();
    }
  }

  public sendNewPassword(delivery: 1 | 2): void {
    this.employeesService.SendNewPassword({ id: this.id, request: { delivery: delivery } })
      .subscribe(
        (): void => {
          this.toastrService.success("Новый пароль отправлен сотруднику", "Успешно");
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }

          this.toastrService.error("Не удалось отправить новый пароль сотруднику", "Ошибка");
        }
      )
  }

  public markAsSubmitted(): void {
    this.submitted = true;
  }
}
