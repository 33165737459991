import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DoctorsPrescriptions, ServiceListItem, ServiceListManipulation } from '../services-list/services-list.component';

@Component({
  selector: 'mp-visit-service-prescriptions',
  templateUrl: './visit-service-prescriptions.component.html',
  styleUrls: ['./visit-service-prescriptions.component.scss']
})
export class VisitServicePrescriptionsComponent implements OnInit {
  @Input() items: DoctorsPrescriptions[] = [];

  @Input() canAddService = false;
  @Input() showLimits = false;

  @Output() onSelect = new EventEmitter<{ service: ServiceListItem, manipulation: ServiceListManipulation }>();
  @Output() onSelectLabTests = new EventEmitter<{ service: ServiceListItem, manipulation: ServiceListManipulation }[]>();

  activeIds: number[] = [];

  constructor() { }

  ngOnInit() { }

  select(service: ServiceListItem, manipulation: ServiceListManipulation) {
    if (!this.canAddService) return;
    this.onSelect.emit({ service, manipulation });
  }

  selectLabTests(group: DoctorsPrescriptions) {
    const tests: { service: ServiceListItem, manipulation: ServiceListManipulation }[] = [];

    for (const manipulation of group.prescriptions) {
      if (manipulation.services.length === 0) continue;
      if (manipulation.services.some(x => !!x.fulfilled)) continue;
      if (!manipulation.services.some(x => x.isDefault) && manipulation.services.length !== 1) continue;

      const service = manipulation.services.find(x => x.isDefault) || manipulation.services[0];

      if (!service.kdlCode) continue;
      if (tests.some(t => t.service.serviceId === service.serviceId)) continue;

      tests.push({ manipulation, service });
    }

    if (tests.length > 0) {
      this.onSelectLabTests.emit(tests);
    }

  }

  panelChange(data: { panelId: number, nextState: boolean }) {
    if (data.nextState) {
      this.activeIds.push(data.panelId);
    } else {
      this.activeIds = this.activeIds.filter(x => x !== data.panelId);
    }
  }

  selectAll(group: DoctorsPrescriptions) {
    const tests: { service: ServiceListItem, manipulation: ServiceListManipulation }[] = [];

    for (const manipulation of group.prescriptions) {
      if (manipulation.services.length === 0) continue;
      if (manipulation.services.some(x => !!x.fulfilled)) continue;
      if (!manipulation.services.some(x => x.isDefault) && manipulation.services.length !== 1) continue;

      const service = manipulation.services.find(x => x.isDefault) || manipulation.services[0];

      if (tests.some(t => t.service.serviceId === service.serviceId)) continue;

      tests.push({ manipulation, service });
    }

    if (tests.length > 0) {
      this.onSelectLabTests.emit(tests);
    }
  }

  isActionRequired(manipulation: ServiceListManipulation) {
    return !!manipulation && !!manipulation.services
      && manipulation.services.length > 1
      && !manipulation.services.some(x => x.isDefault)
      && !manipulation.services.some(x => x.selected);
  }

  hasSelected(manipulation: ServiceListManipulation) {
    return !!manipulation && !!manipulation.services
      && manipulation.services.some(x => x.selected);
  }
}


