import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SettingsGuard } from './security/settings.guard';
import { UserStorage } from './services/user-storage';
import { MessageReceiverService } from './services/message.receiver.service';

import { AuthenticatedComponent } from "./components/authenticated/authenticated.component";
import { AnonymousComponent } from "./components/anonymous/anonymous.component";

import { AuthGuard, SigninComponent, BypassComponent, SignoutComponent } from "@skytecs/security";

import { CallCenterModule } from "./modules/call-center/call-center.module";
import { WorkspaceModule } from './modules/workspace/workspace.module';
import { ReportsModule } from './modules/reports/reports.module';
import { ObservationsModule } from './modules/observations/observations.module';
import { SchedulesModule } from './modules/schedules/schedules.module';
import { VisitsModule } from './modules/visits/visits.module';
import { EmployeeSettingsModule } from './modules/employee-settings/employee-settings.module';
import { ServiceSettingsModule } from './modules/service-settings/service-settings.module';
import { PartnerPlanSettingsModule } from './modules/partner-plan-settings/partner-plan-settings.module';
import { ChooseCompanyComponent } from './components/choose-company/choose-company.component';
import { QuestionnaireSettingsModule } from './modules/questionnaire-settings/questionnaire-settings.module';
import { PermissionsGuard } from './security/permissions.guard';
import { TemplateSettingsModule } from './modules/template-settings/template-settings.module';
import { ScheduleSettingsModule } from './modules/schedule-settings/schedule-settings.module';
import { OrganizationSettingsModule } from './modules/organization-settings/organization-settings.module';
import { RoleSettingsModule } from './modules/role-settings/role-settings.module';
import { ServiceCategorySettingsModule } from './modules/service-category-settings/service-category-settings.module';
import { SpecialitySettingsModule } from './modules/speciality-settings/speciality-settings.module';
import { DiscountSettingsModule } from './modules/discount-settings/discount-settings.module';
import { PromotionSettingsModule } from './modules/promotions-settings/promotion-settings.module';
import { PhoneNumbersSettingsModule } from './modules/phone-numbers-settings/phone-numbers-settings.module';
import { MainRedirectGuard } from './security/main-redirect.guard';
import { SystemSettingsModule } from './modules/system-settings/system-settings.module';
import { ExternalConnectionSettingsModule } from './modules/external-connection-settings/external-connection-settings.module';
import { ProfileModule } from './modules/profile/profile.module';
import { PriceSetSettingsModule } from './modules/price-set-settings/price-set-settings.module';
import { IdsTemplatesSettingsModule } from './modules/ids-templates-settings/ids-templates-settings.module';
import { PermissionNames } from './models/permission-names';
import { ReviewsModule } from './modules/reviews/reviews.module';
import { WageSchemaSettingsModule } from './modules/wage-schema-settings/wage-schema-settings.module';
import { CodeSystemsSettingsModule } from './modules/code-systems-settings/code-systems-settings.module';
import { ImportModule } from "./modules/import/import.module";
import { ScheduleBreakReasonSettingsModule } from "./modules/schedule-break-reason-settings/schedule-break-reason-settings.module";
import { RefundReasonsSettingsModule } from "./modules/refund-reasons-settings/refund-reasons-settings.module";
import { ColorMarksSettingsModule } from "./modules/color-marks-settings/color-marks-settings.module";
import { AbsenceReasonsSettingsModule } from "./modules/absence-reasons-settings/absence-reasons-settings.module";
import { CallCenterWorkspaceModule } from "./modules/call-center-workspace/call-center-workspace.module";
import { ManipulationsSettingsModule } from "./modules/manipulations-settings/manipulations-settings.module";
import { DiagnosesSettingsModule } from "./modules/diagnoses-settings/diagnoses-settings.module";
import { MetricsSettingsModule } from "./modules/metrics-settings/metrics-settings.module";
import { ServiceGroupSettingsModule } from "./modules/service-group-settings/service-group-settings.module";

const routes: Routes = [
  {
    path: "",
    canActivate: [SettingsGuard],
    children: [
      {
        path: "",
        component: AuthenticatedComponent,
        canActivate: [AuthGuard],
        canActivateChild: [PermissionsGuard],
        resolve: {
          profile: UserStorage,
          centrifuge: MessageReceiverService
        },
        children: [
          {
            path: "settings",
            children: [
              {
                path: "employees",
                loadChildren: () => EmployeeSettingsModule
              },
              {
                path: 'manipulations',
                loadChildren: () => ManipulationsSettingsModule
              },
              {
                path: "services",
                loadChildren: () => ServiceSettingsModule
              },
              {
                path: "service-groups",
                loadChildren:()=> ServiceGroupSettingsModule
              },
              {
                path: "partner-plans",
                loadChildren: () => PartnerPlanSettingsModule
              },
              {
                path: "questionnaires",
                loadChildren: () => QuestionnaireSettingsModule
              },
              {
                path: "schedules",
                loadChildren: () => ScheduleSettingsModule
              },
              {
                path: "organizations",
                loadChildren: () => OrganizationSettingsModule
              },
              {
                path: "templates",
                loadChildren: () => TemplateSettingsModule
              },
              {
                path: "metrics",
                loadChildren: () => MetricsSettingsModule
              },
              {
                path: "roles",
                loadChildren: () => RoleSettingsModule
              },
              {
                path: "categories",
                loadChildren: () => ServiceCategorySettingsModule
              },
              {
                path: "specialities",
                loadChildren: () => SpecialitySettingsModule
              },
              {
                path: "diagnoses",
                loadChildren: () => DiagnosesSettingsModule
              },
              {
                path: "discounts",
                loadChildren: () => DiscountSettingsModule
              },
              {
                path: "promotions",
                loadChildren: () => PromotionSettingsModule
              },
              {
                path: "phone-numbers",
                loadChildren: () => PhoneNumbersSettingsModule
              },
              {
                path: "system",
                loadChildren: () => SystemSettingsModule
              },
              {
                path: "external-connections",
                loadChildren: () => ExternalConnectionSettingsModule
              },
              {
                path: "price-sets",
                loadChildren: () => PriceSetSettingsModule
              },
              {
                path: "wage-schemas",
                loadChildren: () => WageSchemaSettingsModule
              },
              {
                path: "code-systems",
                loadChildren: () => CodeSystemsSettingsModule
              },
              {
                path: 'absence-reasons',
                loadChildren: () => AbsenceReasonsSettingsModule
              },
              {
                path: 'break-reasons',
                loadChildren: () => ScheduleBreakReasonSettingsModule
              },
              {
                path: 'refund-reasons',
                loadChildren: () => RefundReasonsSettingsModule
              },
              {
                path: 'color-marks',
                loadChildren: () => ColorMarksSettingsModule
              }
            ]
          },
          {
            path: "observations",
            loadChildren: () => ObservationsModule
          },
          {
            path: 'call-center',
            loadChildren: () => CallCenterWorkspaceModule
          },
          {
            path: "call-center",
            loadChildren: () => CallCenterModule
          },
          {
            path: "workspace",
            data: {
              title: "Рабочее место",
              requirements: ["ViewWorkspace"]
            },
            loadChildren: () => WorkspaceModule
          },
          {
            path: "quality-control",
            data: {
              title: "Клинико-экспертная работа",
              requirements: [[PermissionNames.ReviewRecords, PermissionNames.ViewWorkspace]]
            },
            loadChildren: () => ReviewsModule
          },
          {
            path: "reports",
            data: { title: "Отчеты" },
            loadChildren: () => ReportsModule
          },
          {
            path: "schedules",
            loadChildren: () => SchedulesModule
          },
          {
            path: "visits",
            loadChildren: () => VisitsModule
          },
          {
            path: "profile",
            loadChildren: () => ProfileModule
          },
          {
            path: "import",
            loadChildren: () => ImportModule
          },
          {
            path: "",
            canActivate: [MainRedirectGuard],
            component: AuthenticatedComponent
          }
        ]
      },
      {
        path: "",
        component: AnonymousComponent,
        children: [
          {
            path: "signin",
            component: SigninComponent,
            data: { title: "Вход" }
          },
          {
            path: "bypass",
            component: ChooseCompanyComponent,
            data: { title: "Вход" }
          },
          {
            path: "signout",
            component: SignoutComponent,
            data: { title: "Выход" }
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
