import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxDeductionsModalComponent } from './components/tax-deductions-modal/tax-deductions-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ControlsModule } from '@skytecs/controls';
import { ToastrModule } from 'ngx-toastr';
import { TaxDeductionsVisitComponent } from './components/tax-deductions-visit/tax-deductions-visit.component';
import { SharedModule } from '../shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { TaxDeductionHistoryItemComponent } from './components/tax-deduction-history-item/tax-deduction-history-item.component';

@NgModule({
  declarations: [TaxDeductionsModalComponent, TaxDeductionsVisitComponent, TaxDeductionHistoryItemComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModalModule,
    ControlsModule,
    ToastrModule,
    NgxMaskModule.forRoot({}),
    SharedModule
  ],
  exports: [

  ]
})
export class TaxDeductionsModule { }
