import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Service } from '../../../../generated/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrls: ['./service-modal.component.scss']
})
export class ServiceModalComponent implements OnInit {

  destroy$ = new Subject<void>();
  result$ = new BehaviorSubject<Service[]>([]);

  @Input() services: Service[] = [];
  @Output() onConfirm = new EventEmitter<Service>();
  @Output() onCancel = new EventEmitter<void>();

  service: Service;

  search = new FormControl('');

  constructor() {
    this.search.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => {
        if (!value) {
          this.result$.next([...this.services]);
          return;
        }

        const regex = new RegExp(value, 'i');

        this.result$.next([...this.services.filter(x => regex.test(x.name) || regex.test(`${x.code}`))]);

      })
  }

  ngOnInit() {
    this.result$.next(this.services);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['services']) {
      this.result$.next(changes['services'].currentValue);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  selected = (service: Service) => this.service && this.service.id === service.id;
  select = (service: Service) => this.service = service;

  cancel = () => this.onCancel.emit();
  confirm = () => this.onConfirm.emit(this.service);

  get canConfirm(): boolean { return !!this.service; }

}
