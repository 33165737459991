import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { RecordReviewSummary } from '../../../../generated/models';

@Component({
  selector: 'mp-reviews-modal',
  templateUrl: './reviews-modal.component.html',
  styleUrls: ['./reviews-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "modal-default"
  }
})
export class ReviewsModalComponent implements OnInit {

  @Input() reviews: RecordReviewSummary[] = [];

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onAdd: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onOpen: EventEmitter<RecordReviewSummary> = new EventEmitter<RecordReviewSummary>();

  @Output()
  onPrint: EventEmitter<RecordReviewSummary> = new EventEmitter<RecordReviewSummary>();

  constructor() { }

  ngOnInit() { }

  cancel(): void {
    this.onCancel.emit();
  }

  add(): void {
    this.onAdd.emit();
  }

  open(review: RecordReviewSummary): void {
    this.onOpen.emit(review);
  }

  print(review: RecordReviewSummary): void {
    this.onPrint.emit(review);
  }
}
