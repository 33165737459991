import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { PersonalalBalanceOperation, PersonalBalance } from '../../../../generated/models';
import { PaymentsService, PersonalBalanceService, RefundsService } from '../../../../generated/services';

@Component({
  selector: 'mp-balance-management-modal',
  templateUrl: './balance-management-modal.component.html',
  styleUrls: ['./balance-management-modal.component.scss']
})
export class BalanceManagementModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  operations: PersonalalBalanceOperation[] = [];
  total: number;

  operationsLoading = false;
  operationProcessing = false;

  @Input() personId: number;
  @Input() patientName = '';
  @Input() cashboxName = '';
  @Input() isRep = false;

  @Output() onCancel = new EventEmitter<void>();

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  form: FormGroup = new FormGroup({
    operationType: new FormControl(1, [Validators.required]),
    sum: new FormControl(undefined, [Validators.required, Validators.min(0)]),
    paymentType: new FormControl(1, [Validators.required])
  });

  constructor(private balanceService: PersonalBalanceService,
    private toastrService: ToastrService,
    private paymentsService: PaymentsService,
    private refundsService: RefundsService
  ) { }

  ngOnInit() {

    this.balanceService.GetPersonalBalanceAsync(this.personId)
      .subscribe(
        (response: PersonalBalance): void => {
          this.total = response.total;
        },
        (): void => { }
      );

    this.operationsLoading = true;
    this.balanceService.GetPersonalBalanceOperationsAsync(this.personId)
      .subscribe(
        (response: PersonalalBalanceOperation[]): void => {
          this.operations = response;
          this.operationsLoading = false;
        },
        (): void => {
          this.operationsLoading = false;
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  get formatted(): string {
    if (!this.total) return '0.00';

    return this.total.toLocaleString('ru-RU', this.currencyFormat);
  }

  async add(){
    const value: BalanceOperationEditorModel = this.form.getRawValue();

    if (this.form.invalid) return;

    this.operationProcessing = true;
    this.form.disable({ emitEvent: false });

    try {

      if (value.operationType === 1) {
        await this.paymentsService.CreateBalancePaymentAsync({
          id: this.personId,
          request: {
            paymentType: value.paymentType,
            total: value.sum
          }
        }).toPromise();
      }

      if (value.operationType === 2) {
        await this.refundsService.CreateBalanceRefundAsync({
          id: this.personId,
          request: {
            paymentType: value.paymentType,
            total: value.sum
          }
        }).toPromise();
      }

      this.toastrService.success("Операция добавлена", "Успешно");
      this.form.reset();
      this.form.enable({ emitEvent: false });

    } catch (e) {
      const response = e as HttpErrorResponse;

      if (!response || response.status !== 400 || !response.error.hasErrors) {
        this.toastrService.error("Не удалось добавить операцию", "Ошибка");
      } else {
        for (const error of response.error.errors) {
          switch (error.status) {
            case 12: this.toastrService.warning('Недостаточно средств на счету', 'Ошибка'); break;

            default: this.toastrService.warning('Не удалось добавить операцию', "Ошибка"); break;
          }
        }
      }

      this.operationProcessing = false;
      this.operationsLoading = true;

      this.form.enable({ emitEvent: false });
    }
    this.operationProcessing = false;
    this.operationsLoading = true;

    await this.balanceService.GetPersonalBalanceOperationsAsync(this.personId)
      .subscribe(
        (response: PersonalalBalanceOperation[]): void => {
          this.operations = response;

          this.operationsLoading = false
        },
        (): void => {
          this.operationsLoading = false
        }
      );

    this.balanceService.GetPersonalBalanceAsync(this.personId)
      .subscribe(
        (response: PersonalBalance): void => {
          this.total = response.total;
        }
      );
  }

  cancel = () => this.onCancel.emit();
}

export interface BalanceOperationEditorModel {
  operationType: 0 | 1 | 2;
  sum: number;
  paymentType: 0 | 1 | 2;
}
