import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompanySignComponent } from './components/company-sign/company-sign.component';
import { ColorpickerComponent } from './components/colorpicker/colorpicker.component';
import { NgbButtonsModule, NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecommendationsHintComponent } from './components/recommendations-hint/recommendations-hint.component';
import { AppointmentActionLabelComponent } from './components/appointment-action-label/appointment-action-label.component';
import { AppointmentActionItemComponent } from './components/appointment-action-item/appointment-action-item.component';
import { AppointmentHistoryComponent } from './components/appointment-history/appointment-history.component';

import { ControlsModule } from "@skytecs/controls";
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DocumentPreviewComponent } from './components/document-preview/document-preview.component';
import { CompanyIconComponent } from './components/company-icon/company-icon.component';

@NgModule({
  declarations: [
    CompanySignComponent,
    CompanyIconComponent,
    ColorpickerComponent,
    RecommendationsHintComponent,
    AppointmentActionLabelComponent,
    AppointmentActionItemComponent,
    AppointmentHistoryComponent,
    ConfirmationModalComponent,
    DocumentPreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgbPopoverModule,
    NgbButtonsModule,
    ControlsModule
  ],
  exports: [
    CompanySignComponent,
    ColorpickerComponent,
    RecommendationsHintComponent,
    AppointmentActionLabelComponent,
    AppointmentActionItemComponent,
    AppointmentHistoryComponent,
    CompanyIconComponent
  ],
  entryComponents: [ConfirmationModalComponent, DocumentPreviewComponent]
})
export class SharedModule { }
