import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Document } from 'projects/Clinic/src/app/generated/models';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';

import * as moment from 'moment';

@Component({
  selector: 'mp-document-list-item',
  templateUrl: './document-list-item.component.html',
  styleUrls: ['./document-list-item.component.scss']
})
export class DocumentListItemComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() item: Document;
  @Input() checked = false;

  @Output() onDownload = new EventEmitter<void>();
  @Output() onPrint = new EventEmitter<void>();
  @Output() onDelete = new EventEmitter<void>();

  get date(): string {
    return this.item ? moment(this.item.date).format('DD.MM.YYYY HH:mm') : '';
  }

  checkbox = new FormControl(false);

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checked']) {
      this.checkbox.setValue(changes['checked'].currentValue, { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  download = () => this.onDownload.emit();
  print = () => this.onPrint.emit();
  delete = () => this.onDelete.emit();

}
