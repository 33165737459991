import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Permission, RolePermission } from '../../../../generated/models';

import * as moment from "moment";

@Component({
  selector: 'mp-role-permission-modal',
  templateUrl: './role-permission-modal.component.html',
  styleUrls: ['./role-permission-modal.component.scss']
})
export class RolePermissionModalComponent implements OnInit {
  title = "Добавить разрешение";

  @Input() permissions: Permission[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<RolePermission>();

  form = new FormGroup({
    permission: new FormControl(undefined, [Validators.required]),
    granted: new FormControl(true)
  });

  constructor() { }

  ngOnInit() { }

  submit(): void {
    if (this.form.invalid) return;

    const value: { permission: number, granted: boolean } = this.form.value;
    const selected = this.permissions.find((x: Permission): boolean => x.id === value.permission);

    const rolePermission: RolePermission = {
      id: -1 * moment().valueOf(),
      permissionId: selected.id,
      name: selected.name,
      granted: value.granted
    };

    this.onConfirm.emit(rolePermission);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
