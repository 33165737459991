/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PriceSetsResponse } from '../models/price-sets-response';
import { CreatePriceSetRequest } from '../models/create-price-set-request';
import { PriceSetPricesResponse } from '../models/price-set-prices-response';
import { PriceSetGroupServicePrice } from '../models/price-set-group-service-price';
import { PriceSetDetailedItem } from '../models/price-set-detailed-item';
import { PriceSetServiceResponse } from '../models/price-set-service-response';
import { PriceSetEditorModel } from '../models/price-set-editor-model';
import { UpdatePriceSetRequest } from '../models/update-price-set-request';
@Injectable({
  providedIn: 'root',
})
class PriceSetsService extends __BaseService {
  static readonly PriceSetsPath = '/api/PriceSets';
  static readonly CreatePath = '/api/PriceSets';
  static readonly PricesPath = '/api/PriceSets/{id}/prices';
  static readonly ServiceGroupsPricesPath = '/api/PriceSets/{id}/groups/prices';
  static readonly ServiceGroupPricesPath = '/api/PriceSets/{id}/groups/{groupId}/prices';
  static readonly ExportPath = '/api/PriceSets/{id}/export/{format}';
  static readonly ImportPath = '/api/PriceSets/{id}/import/{format}';
  static readonly DefaultPricesPath = '/api/PriceSets/default/prices';
  static readonly GetDefaultPriceSetDataPath = '/api/PriceSets/default/detailed';
  static readonly ServicesPath = '/api/PriceSets/{id}/services';
  static readonly PriceSetPath = '/api/PriceSets/{id}';
  static readonly UpdatePath = '/api/PriceSets/{id}';
  static readonly RemovePath = '/api/PriceSets/{id}';
  static readonly CopyPath = '/api/PriceSets/{id}/copy';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PriceSetsService.PriceSetsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `InsuranceCompanyId`:
   *
   * - `IncludeExpired`:
   *
   * - `IncludeCommon`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  PriceSetsResponse(params: PriceSetsService.PriceSetsParams): __Observable<__StrictHttpResponse<PriceSetsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.InsuranceCompanyId != null) __params = __params.set('InsuranceCompanyId', params.InsuranceCompanyId.toString());
    if (params.IncludeExpired != null) __params = __params.set('IncludeExpired', params.IncludeExpired.toString());
    if (params.IncludeCommon != null) __params = __params.set('IncludeCommon', params.IncludeCommon.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceSetsResponse>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.PriceSetsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `InsuranceCompanyId`:
   *
   * - `IncludeExpired`:
   *
   * - `IncludeCommon`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  PriceSets(params: PriceSetsService.PriceSetsParams): __Observable<PriceSetsResponse> {
    return this.PriceSetsResponse(params).pipe(
      __map(_r => _r.body as PriceSetsResponse)
    );
  }

  /**
   * @param request undefined
   */
  CreateResponse(request?: CreatePriceSetRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PriceSets`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  Create(request?: CreatePriceSetRequest): __Observable<null> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PriceSetsService.PricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `PatientId`:
   *
   * @return Success
   */
  PricesResponse(params: PriceSetsService.PricesParams): __Observable<__StrictHttpResponse<PriceSetPricesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.PatientId != null) __params = __params.set('PatientId', params.PatientId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${params.id}/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceSetPricesResponse>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.PricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `PatientId`:
   *
   * @return Success
   */
  Prices(params: PriceSetsService.PricesParams): __Observable<PriceSetPricesResponse> {
    return this.PricesResponse(params).pipe(
      __map(_r => _r.body as PriceSetPricesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupsPricesResponse(id: number): __Observable<__StrictHttpResponse<Array<PriceSetGroupServicePrice>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${id}/groups/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PriceSetGroupServicePrice>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupsPrices(id: number): __Observable<Array<PriceSetGroupServicePrice>> {
    return this.ServiceGroupsPricesResponse(id).pipe(
      __map(_r => _r.body as Array<PriceSetGroupServicePrice>)
    );
  }

  /**
   * @param params The `PriceSetsService.ServiceGroupPricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `groupId`:
   *
   * @return Success
   */
  ServiceGroupPricesResponse(params: PriceSetsService.ServiceGroupPricesParams): __Observable<__StrictHttpResponse<Array<PriceSetGroupServicePrice>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${params.id}/groups/${params.groupId}/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PriceSetGroupServicePrice>>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.ServiceGroupPricesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `groupId`:
   *
   * @return Success
   */
  ServiceGroupPrices(params: PriceSetsService.ServiceGroupPricesParams): __Observable<Array<PriceSetGroupServicePrice>> {
    return this.ServiceGroupPricesResponse(params).pipe(
      __map(_r => _r.body as Array<PriceSetGroupServicePrice>)
    );
  }

  /**
   * @param params The `PriceSetsService.ExportParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`:
   *
   * @return Success
   */
  ExportResponse(params: PriceSetsService.ExportParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${params.id}/export/${params.format}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.ExportParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`:
   *
   * @return Success
   */
  Export(params: PriceSetsService.ExportParams): __Observable<Blob> {
    return this.ExportResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `PriceSetsService.ImportParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`:
   *
   * - `file`:
   *
   * @return Success
   */
  ImportResponse(params: PriceSetsService.ImportParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;


    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PriceSets/${params.id}/import/${params.format}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.ImportParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `format`:
   *
   * - `file`:
   *
   * @return Success
   */
  Import(params: PriceSetsService.ImportParams): __Observable<string> {
    return this.ImportResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  DefaultPricesResponse(): __Observable<__StrictHttpResponse<PriceSetPricesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/default/prices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceSetPricesResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  DefaultPrices(): __Observable<PriceSetPricesResponse> {
    return this.DefaultPricesResponse().pipe(
      __map(_r => _r.body as PriceSetPricesResponse)
    );
  }

  /**
   * @param companyId undefined
   * @return Success
   */
  GetDefaultPriceSetDataResponse(companyId?: number): __Observable<__StrictHttpResponse<Array<PriceSetDetailedItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (companyId != null) __params = __params.set('companyId', companyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/default/detailed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PriceSetDetailedItem>>;
      })
    );
  }
  /**
   * @param companyId undefined
   * @return Success
   */
  GetDefaultPriceSetData(companyId?: number): __Observable<Array<PriceSetDetailedItem>> {
    return this.GetDefaultPriceSetDataResponse(companyId).pipe(
      __map(_r => _r.body as Array<PriceSetDetailedItem>)
    );
  }

  /**
   * @param params The `PriceSetsService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  ServicesResponse(params: PriceSetsService.ServicesParams): __Observable<__StrictHttpResponse<PriceSetServiceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceSetServiceResponse>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  Services(params: PriceSetsService.ServicesParams): __Observable<PriceSetServiceResponse> {
    return this.ServicesResponse(params).pipe(
      __map(_r => _r.body as PriceSetServiceResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PriceSetResponse(id: number): __Observable<__StrictHttpResponse<PriceSetEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PriceSets/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PriceSetEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PriceSet(id: number): __Observable<PriceSetEditorModel> {
    return this.PriceSetResponse(id).pipe(
      __map(_r => _r.body as PriceSetEditorModel)
    );
  }

  /**
   * @param params The `PriceSetsService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: PriceSetsService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PriceSets/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PriceSetsService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: PriceSetsService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PriceSets/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Remove(id: number): __Observable<null> {
    return this.RemoveResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  CopyResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PriceSets/${id}/copy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Copy(id: number): __Observable<null> {
    return this.CopyResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PriceSetsService {

  /**
   * Parameters for PriceSets
   */
  export interface PriceSetsParams {
    Size?: number;
    Search?: string;
    Page?: number;
    InsuranceCompanyId?: number;
    IncludeExpired?: boolean;
    IncludeCommon?: boolean;
    CompanyId?: number;
  }

  /**
   * Parameters for Prices
   */
  export interface PricesParams {
    id: number;
    PatientId?: number;
  }

  /**
   * Parameters for ServiceGroupPrices
   */
  export interface ServiceGroupPricesParams {
    id: number;
    groupId: number;
  }

  /**
   * Parameters for Export
   */
  export interface ExportParams {
    id: number;
    format: string;
  }

  /**
   * Parameters for Import
   */
  export interface ImportParams {
    id: number;
    format: string;
    file?: Blob;
  }

  /**
   * Parameters for Services
   */
  export interface ServicesParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
    CategoryId?: number;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: UpdatePriceSetRequest;
  }
}

export { PriceSetsService }
