import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Attending, Company, Speciality } from "../../../generated/models";
import { CompaniesService, EmployeesService, SpecialitiesService } from "../../../generated/services";

import * as moment from 'moment';

@Injectable({ providedIn: "root" })
export class PlannedVisitsResolver implements Resolve<PlannedVisitsPayload> {

  public constructor(private companiesService: CompaniesService,
    private employeesService: EmployeesService,
    private specialitiesService: SpecialitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PlannedVisitsPayload> {

    return forkJoin({
      companies: this.companiesService.Companies({ Type: 4, Date: moment().format('DD.MM.YYYY') }),
      doctors: this.employeesService.Attendings({ Page: 1, Size: 999 }).pipe(map(x => x.items)),
      specialities: this.specialitiesService.Specialities({ Page: 1, Size: 999 }).pipe(map(x => x.items))
    });
  }

}

export interface PlannedVisitsPayload {
  companies: Company[];
  doctors: Attending[];
  specialities: Speciality[];
}
