import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuestionnairesService } from 'projects/Clinic/src/app/generated/services';
import { Observable, forkJoin, Subject } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';

import * as moment from "moment";
import { QuestionnaireResolverPayload } from '../../resolvers/questionnaire.resolver';
import { ServiceCategory, QuestionResponse, OptionResponse } from 'projects/Clinic/src/app/generated/models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireQuestionModalComponent } from '../questionnaire-question.modal/questionnaire-question.modal.component';
import { EditQuestionnaireModel } from '../../../../generated/models/edit-questionnaire-model';
import { ThresholdResponse } from '../../../../generated/models/threshold-response';
import { QuestionnaireThresholdModalComponent } from '../questionnaire-threshold.modal/questionnaire-threshold.modal.component';
import { QuestionnaireOptionModalComponent } from '../questionnaire-option.modal/questionnaire-option.modal.component';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-questionnaire-editor',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss'],
  host: { class: "page" }
})
export class QuestionnaireComponent implements OnInit, OnDestroy {
  private _destroy: Subject<void> = new Subject<void>();

  private _id: number;
  private _title: string;

  public questionnaireForm: FormGroup;

  private _selected: { [id: number]: boolean };

  public scrollDistance: number = 1;

  private _questions: Array<QuestionResponse>;
  private _options: Array<OptionResponse>;
  private _thresholds: Array<ThresholdResponse>;

  public get title(): string { return this._title; }

  constructor(
    private questionnairesService: QuestionnairesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private toastr: ToastrService
  ) {
    this._selected = {};
    this.questionnaireForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      description: new FormControl(""),
      company: new FormControl(""),
      date: new FormControl(""),
    });

    this._questions = [];
    this._options = [];
    this._thresholds = [];

  }

  ngOnInit() {

    this.activatedRoute.data
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (data: { payload: QuestionnaireResolverPayload }): void => {
          this._id = data.payload.questionnaire.id;

          this._title = this._id > 0 ? `Опросник: ${data.payload.questionnaire.title}` : "Новый опросник";

          this.questionnaireForm.patchValue({
            name: data.payload.questionnaire.title,
            description: data.payload.questionnaire.description,
            company: data.payload.questionnaire.medicalCenter,
            date: data.payload.questionnaire.date > 0 ? moment.unix(data.payload.questionnaire.date) : undefined
          });

          this._questions = data.payload.questionnaire.questions || [];
          this._options = data.payload.questionnaire.options || [];
          this._thresholds = data.payload.questionnaire.thresholds || [];
        });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public get questions(): Array<QuestionResponse> { return this._questions };
  public set questions(value: Array<QuestionResponse>) { this._questions = value }

  public get options(): Array<OptionResponse> { return this._options };
  public set options(value: Array<OptionResponse>) { this._options = value }

  public get thresholds(): Array<ThresholdResponse> { return this._thresholds };
  public set thresholds(value: Array<ThresholdResponse>) { this._thresholds = value }

  public acceptChanges(): void {
    const value: FormValue = this.questionnaireForm.getRawValue();

    if (this._id) {

      this.questionnairesService.Edit({
        questionnaireId: this._id, model: {
          title: value.name,
          description: value.description,
          medicalCenter: value.company,
          date: value.date && value.date.isValid() ? value.date.unix() : undefined
        }
      })
        .subscribe(
          (): void => {
            this.toastr.success("", "Опросник сохранен");

            this._title = `Опросник: ${value.name}`;
          },

          (): void => {
            this.toastr.error("Не удалось сохранить опросник", "Ошибка");
          }
        );
    } else {
      this.questionnairesService.Create({
        title: value.name,
        description: value.description,
        medicalCenter: value.company,
        date: value.date && value.date.isValid() ? value.date.unix() : undefined
      })
        .subscribe(
          (created: EditQuestionnaireModel): void => {
            this.router.navigate(["../", created.id], { relativeTo: this.activatedRoute });
          },
          (): void => {
            this.toastr.error("Не удалось создать опросник", "Ошибка");
          }
        );
    }
  }

  public addQuestion(): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireQuestionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 0;

    modalRef.result.then(
      (res: any): void => {
        this.questions.push({ id: res.id, description: res.description, title: res.title, isText: res.isText, number: res.number });
        this.questions.sort((x, y) => { return x.number - y.number; });
      },
      (): void => { }
    );
  }

  public editQuestion(question: QuestionResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireQuestionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 1;
    modalRef.componentInstance.questionId = question.id;
    modalRef.componentInstance.title = question.title;
    modalRef.componentInstance.description = question.description;
    modalRef.componentInstance.isText = question.isText;
    modalRef.componentInstance.number = question.number;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.questions.length; i++) {
          if (this.questions[i].id == question.id) {
            ind = i;
          }
        }
        this.questions.splice(ind, 1, res);
        this.questions.sort((x, y) => { return x.number - y.number; });
      },
      (): void => { }
    );
  }

  public deleteQuestion(question: QuestionResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireQuestionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 2;
    modalRef.componentInstance.questionId = question.id;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.questions.length; i++) {
          if (this.questions[i].id == question.id) {
            ind = i;
          }
        }
        this.questions.splice(ind, 1);
      },
      (): void => { }
    );
  }

  public addOption(): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireOptionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 0;

    modalRef.result.then(
      (res: any): void => {
        this.options.push({ id: res.id, number: res.number, title: res.title, questionNumber: res.questionNumber, weight: res.weight });
        this.options.sort((x, y) => {
          return Number(y.questionNumber < x.questionNumber) - Number(x.questionNumber < y.questionNumber)
            || Number(y.number < x.number) - Number(x.number < y.number);
        });
      },
      (): void => { }
    );
  }

  public editOption(option: OptionResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireOptionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 1;
    modalRef.componentInstance.questionId = option.id;
    modalRef.componentInstance.title = option.title;
    modalRef.componentInstance.number = option.number;
    modalRef.componentInstance.weight = option.weight;
    modalRef.componentInstance.questionNumber = option.questionNumber;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i].id == option.id) {
            ind = i;
          }
        }
        this.options.splice(ind, 1, res);
        this.options.sort((x, y) => {
          return Number(y.questionNumber < x.questionNumber) - Number(x.questionNumber < y.questionNumber)
            || Number(y.number < x.number) - Number(x.number < y.number);
        });
      },
      (): void => { }
    );
  }

  public deleteOption(option: OptionResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireOptionModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 2;
    modalRef.componentInstance.questionId = option.id;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i].id == option.id) {
            ind = i;
          }
        }
        this.options.splice(ind, 1);
      },
      (): void => { }
    );
  }

  public addThreshold(): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireThresholdModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 0;

    modalRef.result.then(
      (res: any): void => {
        this.thresholds.push({ id: res.id, value: res.value, title: res.title });
      },
      (): void => { }
    );
  }

  public editThreshold(threshold: ThresholdResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireThresholdModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 1;
    modalRef.componentInstance.questionId = threshold.id;
    modalRef.componentInstance.title = threshold.title;
    modalRef.componentInstance.value = threshold.value;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.thresholds.length; i++) {
          if (this.thresholds[i].id == threshold.id) {
            ind = i;
          }
        }
        this.thresholds.splice(ind, 1, res);
      },
      (): void => { }
    );
  }

  public deleteThreshold(threshold: ThresholdResponse): void {
    const modalRef: NgbModalRef = this.modal.open(QuestionnaireThresholdModalComponent, {
      backdrop: "static",
      size: "lg"
    });

    modalRef.componentInstance.questionnaireId = this._id;
    modalRef.componentInstance.mode = 2;
    modalRef.componentInstance.questionId = threshold.id;

    modalRef.result.then(
      (res: any): void => {
        var ind = 0;
        for (var i = 0; i < this.thresholds.length; i++) {
          if (this.thresholds[i].id == threshold.id) {
            ind = i;
          }
        }
        this.thresholds.splice(ind, 1);
      },
      (): void => { }
    );
  }

  public get selected(): { [id: number]: boolean } { return this._selected; }
}

interface FormValue {
  name: string;
  description: string;
  company: string;
  date: moment.Moment;
}
