import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { WorkspaceListItem } from '../../../../generated/models';

@Component({
  selector: 'mp-dashbord-list-item',
  templateUrl: './dashbord-list-item.component.html',
  styleUrls: ['./dashbord-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashbordListItemComponent implements OnInit {
  @Input() visit: WorkspaceListItem;

  @Input() canAddRecord: boolean;
  @Input() canAddService: boolean;
  @Input() canOpenCard: boolean;
  @Input() canCallSelectedLineParticipant: boolean;

  @Input() canSeePhone = false;

  @Input() error = '';

  @Output() onAddRecord = new EventEmitter<void>();
  @Output() onAddService = new EventEmitter<void>();
  @Output() onOpenCard = new EventEmitter<void>();
  @Output() onCall = new EventEmitter<void>();

  paymentSourceTitle = '';

  get name(): string {
    return [this.visit.lastName, this.visit.firstName, this.visit.middleName].join(" ").trim();
  }

  get serviceName(): string {
    if (!this.visit) return '';
    if (!this.visit.contractItemId) return 'Нет услуги';
    return this.visit.serviceName;
  }

  get phone(): string {
    if (this.canSeePhone) {
      return this.visit.phone && this.visit.phone.length === 11 ?
        `+7(${this.visit.phone.substr(1, 3)}) ${this.visit.phone.substr(4, 3)}-${this.visit.phone.substr(7, 2)}-${this.visit.phone.substr(9, 2)}`
        : this.visit.phone;
    }
    return '';

  }

  constructor() { }

  ngOnInit() {
    if (this.visit) {
      let title = '';

      switch (this.visit.paymentSource) {
        case 1: title = 'Платные услуги'; break;
        case 2: title = 'ОМС'; break;
        case 3: title = 'ДМС'; break;
        case 4: title = 'Договор'; break;
        case 5: title = 'Бюджет'; break;
      }

      this.paymentSourceTitle = this.error ? `${title} - ${this.error}` : title;
    }
  }


  addRecord() {
    if (!this.canAddRecord) return;
    this.onAddRecord.emit();
  }

  addService() {
    if (!this.canAddService) return;
    this.onAddService.emit();
  }

  openCard() {
    if (!this.canOpenCard) return;
    this.onOpenCard.emit();
  }

  call = () => this.onCall.emit();

}
