import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PatientsComponent } from './components/patients/patients.component';
import { PatientComponent } from './components/patient/patient.component';

import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from "@skytecs/controls"
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ResultsModalComponent } from './components/results/results.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PatientListItemComponent } from './components/patient-list-item/patient-list-item.component';
import { NgxMaskModule } from 'ngx-mask';
import { PatientResolver } from './resolvers/patient-resolver';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';
import { MedcardListItemComponent } from './components/medcard-list-item/medcard-list-item.component';
import { CreateCabinetModalComponent } from './components/create-cabinet-modal/create-cabinet-modal.component';
import { DeleteConfirmationModalComponent } from '../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { VisitListItemComponent } from './components/visit-list-item/visit-list-item.component';
import { PatientVisitsComponent } from './components/patient-visits/patient-visits.component';
import { VisitEditModalComponent } from './components/visit-edit-modal/visit-edit-modal.component';
import { ConnectedPatientsListItemComponent } from './components/connected-patients-list-item/connected-patients-list-item.component';
import { ConnectedPatientsComponent } from './components/connected-patients/connected-patients.component';
import { DuplicateListItemComponent } from './components/duplicate-list-item/duplicate-list-item.component';
import { RepresentativeSelectModalComponent } from './components/representative-select-modal/representative-select-modal.component';
import { RepresentativeSelectItemComponent } from './components/representative-select-item/representative-select-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LaboratoryComponent } from './components/laboratory/laboratory.component';
import { LaboratoryListItemComponent } from './components/laboratory-list-item/laboratory-list-item.component';
import { SharedModule } from '../shared/shared.module';
import { PersonMarksModule } from '../person-marks/person-marks.module';

const callCenterRoutes: Routes = [
  {
    path: "patients",

    children: [
      {
        path: ":id",
        component: PatientComponent,
        data: {
          title: "Call-center: Пациент",
          requirements: [PermissionNames.EditPersonalData]
        },
        resolve: {
          payload: PatientResolver
        }
      },
      {
        path: "",
        component: PatientsComponent,
        data: {
          title: "Call-center: Список пациентов",
          requirements: [PermissionNames.ViewSchedules]
        }
      }
    ]

  }
];

@NgModule({
  declarations: [
    PatientsComponent,
    PatientComponent,
    ResultsModalComponent,
    PatientListItemComponent,
    MedcardListItemComponent,
    CreateCabinetModalComponent,
    VisitListItemComponent,
    PatientVisitsComponent,
    VisitEditModalComponent,
    ConnectedPatientsListItemComponent,
    ConnectedPatientsComponent,
    DuplicateListItemComponent,
    RepresentativeSelectModalComponent,
    RepresentativeSelectItemComponent,
    LaboratoryComponent,
    LaboratoryListItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(callCenterRoutes),
    LayoutModule,
    ControlsModule,
    ReactiveFormsModule,
    NgbModalModule,
    EditorModule,
    NgxMaskModule.forRoot({}),
    ToastrModule,
    InfiniteScrollModule,
    SharedModule,
    PersonMarksModule
  ],
  entryComponents: [
    ResultsModalComponent,
    CreateCabinetModalComponent,
    VisitEditModalComponent,
    RepresentativeSelectModalComponent
  ],
  exports: [
    RouterModule
  ]
})
export class CallCenterModule { }
