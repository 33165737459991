import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, from, Observable } from 'rxjs';
import { DiseaseOutcomeService, MyService, PatientsService, RecordsService, ReviewsService } from '../../../generated/services';
import { DiseaseOutcome, Record, RecordReview, RecordReviewDefectDescription, RecordReviewRate } from '../../../generated/models';
import { RecordReviewDescription } from '../../../generated/models/record-review-description';
import { ReviewSettings } from '../../../generated/models/review-settings';

@Injectable({ providedIn: "root" })
export class RevisionResolver implements Resolve<RevisionPayload> {
  constructor(private recordsService: RecordsService,
    private reviewsService: ReviewsService,
    private myService: MyService,
    private patientsService: PatientsService,
    private outcomesService: DiseaseOutcomeService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): RevisionPayload | Observable<RevisionPayload> | Promise<RevisionPayload> {

    const id: number = parseInt(route.paramMap.get('id'), 10);

    const ratesLoader: Observable<RecordReviewRate[]> = this.reviewsService.Rates();
    const recordLoader: Observable<Record> = this.recordsService.Record(id);
    const descriptionLoader: Observable<RecordReviewDescription> = this.reviewsService.DescriptionAsync();
    const defectsLoader: Observable<RecordReviewDefectDescription> = this.reviewsService.Defects();
    const settingsLoader: Observable<ReviewSettings> = this.myService.ReviewSettingsAsync();
    const outcomesLoader: Observable<DiseaseOutcome[]> = this.outcomesService.DiseaseOutcomes();

    let reviewLoader: Observable<RecordReview> = from([{}]);

    if (route.paramMap.has('reviewId')) {
      reviewLoader = this.reviewsService.ReviewAsync(parseInt(route.paramMap.get('reviewId'), 10));
    }

    return forkJoin({
      record: recordLoader,
      description: descriptionLoader,
      review: reviewLoader,
      rates: ratesLoader,
      defects: defectsLoader,
      settings: settingsLoader,
      outcomes: outcomesLoader
    });
  }
}

export interface RevisionPayload {
  record: Record;
  description: RecordReviewDescription;
  review: RecordReview;
  rates: RecordReviewRate[];
  defects: RecordReviewDefectDescription;
  settings: ReviewSettings;
  outcomes: DiseaseOutcome[];
}
