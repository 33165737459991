import { Component, OnInit, OnDestroy } from '@angular/core';
import { PartnerPlansService } from 'projects/Clinic/src/app/generated/services';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { PartnerPlanListItem } from 'projects/Clinic/src/app/generated/models/partner-plan-list-item';
import { PartnerPlansResponse } from 'projects/Clinic/src/app/generated/models/partner-plans-response';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManagePartnerPlanModalComponent } from '../manage-partner-plan.modal/manage-partner-plan.modal.component';
import { ManagePartnerPlanModel } from 'projects/Clinic/src/app/generated/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportsUtilityService } from '../../../shared/services/reports-url-service';
import { PreviewModalComponent } from '../../../shared/components/preview-modal/preview-modal.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mp-partner-plan-list',
  templateUrl: './partner-plans.component.html',
  styleUrls: ['./partner-plans.component.scss'],
  host: { class: "page" }
})
export class PartnerPlansComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  plans: PartnerPlanListItem[] = [];

  page = 1;
  size = 50;

  scrollDistance = 1;
  filters: FormGroup;

  constructor(
    private plansService: PartnerPlansService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private reportsUtilityService: ReportsUtilityService,
    private toastrService: ToastrService,
    private sanitaizer: DomSanitizer,
  ) {
    this.filters = new FormGroup({
      search: new FormControl(""),
      activeOnly: new FormControl("")
    });

    this.filters.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((): void => {
          this.page = 1;
          this.plans = [];
        }),
        tap((value: FiltersValue): void => {
          const queryParams: Params = {};

          queryParams.search = value.search;

          if (!value.activeOnly) {
            queryParams.activeOnly = false;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        })
      )
      .subscribe((): void => {
        this.loadPage();
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const value: FiltersValue = {
      search: '',
      activeOnly: true
    };

    if (queryMap.has("search")) {
      value.search = queryMap.get("search");
    }

    if (queryMap.get('activeOnly') === 'false') {
      value.activeOnly = false;
    }

    this.filters.patchValue(value);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  private loadPage(): void {
    const value: FiltersValue = this.filters.getRawValue();

    this.plansService.Rates({ Page: this.page, Size: this.size, Search: value.search, ActiveOnly: value.activeOnly })
      .subscribe((result: PartnerPlansResponse): void => {
        this.plans = this.plans.concat(result.plans);
      });
  }

  onScrollDown(): void {
    this.page++;

    this.loadPage();
  }

  openManager(plan: PartnerPlanListItem): void {
    if (this.modal.hasOpenModals()) {
      return;
    }

    this.plansService.Manage(plan.id)
      .subscribe(
        (manageModel: ManagePartnerPlanModel): void => {
          const options: NgbModalOptions = { backdrop: "static", size: "lg" };
          const modalRef: NgbModalRef = this.modal.open(ManagePartnerPlanModalComponent, options);
          const componentRef: ManagePartnerPlanModalComponent = modalRef.componentInstance;

          const remove = manageModel.totalVisits === 0;

          componentRef.model = manageModel;
          componentRef.confirmDisabled = !remove && manageModel.archived;

          if (remove) {
            componentRef.message = `<p>Партнерская программа 	&laquo;<strong>${manageModel.name}</strong>&raquo; будет удалена.</p><p>Продолжить?</p>`;
          } else if (manageModel.archived) {
            componentRef.message = `<p>Удаление партнерской программы 	&laquo;<strong>${manageModel.name}</strong>&raquo; ` +
              `невозможно: программа используется.</p><p>Программа находится в архиве.</p>`;
          } else {
            componentRef.message = `<p>Удаление партнерской программы 	&laquo;<strong>${manageModel.name}</strong>&raquo; ` +
              `невозможно: программа используется.</p><p>Переместить программу в архив?</p>`;
          }

          componentRef.onCancel.subscribe((): void => {
            modalRef.close();
          });

          componentRef.onConfirm.subscribe((): void => {

            if (remove) {
              this.plansService.Remove(plan.id).subscribe(
                (): void => {
                  modalRef.close();

                  this.toastrService.success(`Партнерская программа ${manageModel.name} удалена`, "Успешно");

                  this.page = 1;
                  this.plans = [];

                  this.loadPage();
                },
                (response: HttpErrorResponse): void => {
                  if (response.status === 400) {
                    this.toastrService.warning(response.error.message, "Ошибка");
                    return;
                  }

                  this.toastrService.error("Не удалось удалить партнерскую программу", "Ошибка");
                }
              );

            } else {
              this.plansService.Archive(plan.id).subscribe(
                (): void => {
                  modalRef.close();

                  this.toastrService.success(`Партнерская программа ${manageModel.name} отправлена в архив`, "Успешно");

                  this.page = 1;
                  this.plans = [];

                  this.loadPage();
                },
                (response: HttpErrorResponse): void => {
                  if (response.status === 400) {
                    this.toastrService.warning(response.error.message, "Ошибка");
                    return;
                  }

                  this.toastrService.error("Не удалось отправить партнерскую программу в архив", "Ошибка");
                }
              );
            }
          });
        });
  }

  planTypeName(plan: PartnerPlanListItem): string {
    switch (plan.rateType) {
      case 1: return "В рублях";
      case 2: return "В процентах";
      default: return "";
    }
  }

  planTypeIcon(plan: PartnerPlanListItem): string {
    switch (plan.rateType) {
      case 1: return "₽";
      case 2: return "%";
      default: return "";
    }
  }

  edit(plan: PartnerPlanListItem): void {
    this.router.navigate([plan.id], { relativeTo: this.activatedRoute });
  }

  print(plan: PartnerPlanListItem): void {
    const parameters: { [key: string]: string } = {
      id: plan.id.toString(),
      name: 'PartnersPlanReport'
    };

    this.reportsUtilityService.getUrl(parameters)
      .subscribe(url => {
        const options: NgbModalOptions = {
          centered: true,
          backdrop: 'static',
          size: 'lg'
        };
        const modalRef: NgbModalRef = this.modal.open(PreviewModalComponent, options);
        const componentRef: PreviewModalComponent = modalRef.componentInstance;

        componentRef.title = "Просмотр отчета";
        componentRef.pdfSrc = this.sanitaizer.bypassSecurityTrustResourceUrl(url);

        componentRef.onDownload
          .subscribe((type: string): void => {
            this.reportsUtilityService.downloadReport(parameters, type);
          });

        componentRef.onCancel.subscribe(() => {
          modalRef.close();
        });

      });
  }

  create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }
}

interface FiltersValue {
  search: string;
  activeOnly: boolean;
}
