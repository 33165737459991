import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Template } from '../../../../generated/models';

@Component({
  selector: 'mp-template-list-item',
  templateUrl: './template-list-item.component.html',
  styleUrls: ['./template-list-item.component.scss']
})
export class TemplateListItemComponent implements OnInit {

  @Input() template: Template;

  @Input() canArchive: boolean;
  @Input() canRemove: boolean;
  @Input() canEdit: boolean;
  @Input() canCopy: boolean;

  @Output() onCopy = new EventEmitter<void>();
  @Output() onEdit = new EventEmitter<void>();
  @Output() onRestore = new EventEmitter<void>();
  @Output() onArchive = new EventEmitter<void>();
  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.canRemove ? this.onRemove.emit() : undefined;
  edit = () => this.canEdit ? this.onEdit.emit() : undefined;
  copy = () => this.canCopy ? this.onCopy.emit() : undefined;
  archive = () => this.canArchive ? this.onArchive.emit() : undefined;
  restore = () => this.canArchive ? this.onRestore.emit() : undefined;
}
