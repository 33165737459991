import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { DiagnosisNode } from "../../../generated/models";
import { Observable, forkJoin, from } from "rxjs";
import { DiagnosesService } from "../../../generated/services";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DiagnosisResolver implements Resolve<DiagnosisResolverPayload> {

  constructor(private diagnosesService: DiagnosesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiagnosisResolverPayload> {
    const id = parseInt(route.paramMap.get("id"), 10);

    let diagnosisLoader: Observable<DiagnosisNode> = from([{}]);

    if (id > 0) {
      diagnosisLoader = this.diagnosesService.DiagnosisAsync(id);
    }

    return forkJoin({
      diagnosis: diagnosisLoader
    });
  }
}

export interface DiagnosisResolverPayload {
  diagnosis: DiagnosisNode
}
