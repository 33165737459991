import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbsenceReason } from '../../../../generated/models';

@Component({
  selector: 'mp-absence-reason-item',
  templateUrl: './absence-reason-item.component.html',
  styleUrls: ['./absence-reason-item.component.scss']
})
export class AbsenceReasonItemComponent implements OnInit {

  @Input() reason: AbsenceReason;

  @Output()
  onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }
}
