/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AbsenceReason } from '../models/absence-reason';
import { CreateAbsenceReasonResponse } from '../models/create-absence-reason-response';
import { CreateAbsenceReasonRequest } from '../models/create-absence-reason-request';
import { UpdateAbsenceReasonRequest } from '../models/update-absence-reason-request';
@Injectable({
  providedIn: 'root',
})
class AbsenceReasonsService extends __BaseService {
  static readonly ReasonsAsyncPath = '/api/v1/AbsenceReasons';
  static readonly CreateAsyncPath = '/api/v1/AbsenceReasons';
  static readonly ReasonAsyncPath = '/api/v1/AbsenceReasons/{id}';
  static readonly UpdateAsyncPath = '/api/v1/AbsenceReasons/{id}';
  static readonly RemoveAsyncPath = '/api/v1/AbsenceReasons/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  ReasonsAsyncResponse(): __Observable<__StrictHttpResponse<Array<AbsenceReason>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/AbsenceReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AbsenceReason>>;
      })
    );
  }
  /**
   * @return Success
   */
  ReasonsAsync(): __Observable<Array<AbsenceReason>> {
    return this.ReasonsAsyncResponse().pipe(
      __map(_r => _r.body as Array<AbsenceReason>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAsyncResponse(request?: CreateAbsenceReasonRequest): __Observable<__StrictHttpResponse<CreateAbsenceReasonResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/AbsenceReasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateAbsenceReasonResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAsync(request?: CreateAbsenceReasonRequest): __Observable<CreateAbsenceReasonResponse> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateAbsenceReasonResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ReasonAsyncResponse(id: number): __Observable<__StrictHttpResponse<AbsenceReason>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/AbsenceReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AbsenceReason>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ReasonAsync(id: number): __Observable<AbsenceReason> {
    return this.ReasonAsyncResponse(id).pipe(
      __map(_r => _r.body as AbsenceReason)
    );
  }

  /**
   * @param params The `AbsenceReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsyncResponse(params: AbsenceReasonsService.UpdateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/AbsenceReasons/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AbsenceReasonsService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsync(params: AbsenceReasonsService.UpdateAsyncParams): __Observable<null> {
    return this.UpdateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/AbsenceReasons/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveAsync(id: number): __Observable<null> {
    return this.RemoveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AbsenceReasonsService {

  /**
   * Parameters for UpdateAsync
   */
  export interface UpdateAsyncParams {
    id: number;
    request?: UpdateAbsenceReasonRequest;
  }
}

export { AbsenceReasonsService }
