import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorMarkComponent } from './components/color-mark/color-mark.component';
import { ColorMarksComponent } from './components/color-marks/color-marks.component';
import { ColorMarkItemComponent } from './components/color-mark-item/color-mark-item.component';
import { ManageColorMarkComponent } from './components/manage-color-mark/manage-color-mark.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { RouterModule, Routes } from '@angular/router';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { ColorMarkResolver } from './resolvers/color-mark-resolver';
import { PermissionNames } from '../../models/permission-names';
import { SharedModule } from '../shared/shared.module';


const routes: Routes = [
  {
    path: 'new',
    component: ColorMarkComponent,
    data: {
      title: "Цветовая метка",
      requirements: [PermissionNames.ManagerColorMarks]
    },
    resolve: {
      payload: ColorMarkResolver
    }
  },
  {
    path: ':id',
    component: ColorMarkComponent,
    data: {
      title: "Причина возврата",
      requirements: [PermissionNames.ManagerColorMarks]
    },
    resolve: {
      payload: ColorMarkResolver
    }
  },
  {
    path: '',
    component: ColorMarksComponent,
    data: {
      title: "Причины возврата",
      requirements: [PermissionNames.ManagerColorMarks]
    }
  }
];

@NgModule({
  declarations: [ColorMarkComponent, ColorMarksComponent, ColorMarkItemComponent, ManageColorMarkComponent],
  entryComponents: [ManageColorMarkComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,
    SharedModule,

    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,
    InfiniteScrollModule,

    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class ColorMarksSettingsModule { }
