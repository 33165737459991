import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EmployeeCompany } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-companies',
  templateUrl: './employee-companies.component.html',
  styleUrls: ['./employee-companies.component.scss']

})
export class EmployeeCompaniesComponent implements OnInit {

  @Input()
  companies: EmployeeCompany[] = [];

  @Output()
  onRemove: EventEmitter<EmployeeCompany> = new EventEmitter<EmployeeCompany>();

  @Output()
  onEdit: EventEmitter<EmployeeCompany> = new EventEmitter<EmployeeCompany>();

  constructor() { }

  ngOnInit() { }

  edit(company: EmployeeCompany): void {
    this.onEdit.emit(company);
  }

  remove(company: EmployeeCompany): void {
    this.onRemove.emit(company);
  }

}
