import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Manipulation } from '../../../../generated/models';

@Component({
  selector: 'mp-manipulation-list-item',
  templateUrl: './manipulation-list-item.component.html',
  styleUrls: ['./manipulation-list-item.component.scss']
})
export class ManipulationListItemComponent implements OnInit {

  @Input() item: Manipulation;

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();
}
