import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Metric, SavedTemplateGroup, SavedTemplateItem } from '../../../../generated/models';

@Component({
  selector: 'mp-placeholders',
  templateUrl: './placeholders.component.html',
  styleUrls: ['./placeholders.component.scss']
})
export class PlaceholdersComponent implements OnInit {
  show = false;
  opened: { [id: string]: boolean; } = {};

  @Input() placeholders: SavedTemplateGroup[] = [];
  @Input() metrics: Metric[] = [];

  @Output() onSelect = new EventEmitter<string>();

  constructor() { }

  ngOnInit() { }

  toggle = () => this.show = !this.show;
  toggleGroup = (group: SavedTemplateGroup) => this.opened[group.name] = !this.opened[group.name];
  copy = (item: SavedTemplateItem) => this.onSelect.emit(item.value);
  copyMetric = (item: Metric) => this.onSelect.emit(`[{${item.systemName}}]`);
  isOpened = (group: SavedTemplateGroup) => !!this.opened[group.name];
}
