import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, from, Observable } from 'rxjs';
import { CodeSystem } from '../../../generated/models';
import { CodeSystemsService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class CodeSystemResolver implements Resolve<CodeSystemResolverPayload> {
  public constructor(
    private codeSystemsService: CodeSystemsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CodeSystemResolverPayload> {
    const id: number = parseInt(route.paramMap.get("id"));

    let codeSystemLoader: Observable<CodeSystem> = null;

    if (id) {
      codeSystemLoader = this.codeSystemsService.CodeSystem(id);
    } else {
      codeSystemLoader = from([{}]);
    }

    return forkJoin({
      codeSystem: codeSystemLoader
    });
  }
}

export class CodeSystemResolverPayload {
  public codeSystem: CodeSystem;
}
