import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Locus, LocusContainer, SelectedLocusContainer, SelectedService } from 'projects/Clinic/src/app/generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-visits-container-select-modal',
  templateUrl: './visits-container-select-modal.component.html',
  styleUrls: ['./visits-container-select-modal.component.scss'],
  host: { class: "modal-default" }
})
export class VisitsContainerSelectModalComponent implements OnInit {
  @Input() serviceName = '';
  @Input() locuses: Locus[] = [];
  @Input() selectedContainers: SelectedLocusContainer[] = [];
  @Input() selectedService: SelectedService;

  private selectedLocusContainers: SelectedLocusContainer[] = [];

  form = new FormGroup({});

  constructor(private modal: NgbActiveModal) { }

  ngOnInit() {
    this.selectedLocusContainers = [...this.selectedContainers];

    for (const locus of this.locuses) {
      for (const container of locus.containers) {

        const value = this.selectedContainers.some(x => x.containerId === container.id && x.locusId === locus.id) || (locus.required && container.required);

        const control = new FormControl(value);

        if (locus.required && container.required) control.disable();

        control.valueChanges.subscribe((value: boolean): void => {
          const index = this.selectedLocusContainers.findIndex(x => x.containerId === container.id && x.locusId === container.locusId);

          if (index === -1 && value) {
            this.selectedLocusContainers.push({
              containerId: container.id,
              containerName: container.name,
              locusId: locus.id,
              locusName: locus.name,
              selectedServiceId: this.selectedService.id
            });
          }

          if (index !== -1 && !value) this.selectedLocusContainers.splice(index, 1);
        });

        this.form.addControl(this.nameForControl(container), control);
      }
    }
  }

  dismiss = () => this.modal.dismiss();
  ok = () => this.modal.close(this.selectedLocusContainers);
  nameForControl = (container: LocusContainer): string => `locus${container.locusId}container${container.id}`;
}
