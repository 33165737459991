import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { DiagnosesService } from "../../../generated/services";
import { DiagnosisNode } from "../../../generated/models";
@Injectable({ providedIn: "root" })
export class DiagnosesResolver implements Resolve<DiagnosesResolverPayload> {

  constructor(private diagnoseService: DiagnosesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiagnosesResolverPayload> {

    const groupsLoader = this.diagnoseService.GroupsAsync();

    return forkJoin({
      groups: groupsLoader
    });
  }
}

export interface DiagnosesResolverPayload {
  groups: DiagnosisNode[];
}
