/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TaxDeductionCandidate } from '../models/tax-deduction-candidate';
import { CreateTaxDeductionResponse } from '../models/create-tax-deduction-response';
import { CreateTaxDeductionRequest } from '../models/create-tax-deduction-request';
import { PersonTaxDeduction } from '../models/person-tax-deduction';
@Injectable({
  providedIn: 'root',
})
class TaxDeductionsService extends __BaseService {
  static readonly CandidatesAsyncPath = '/api/v1/patients/{id}/tax-deductions/candidates';
  static readonly PersonTaxDeductionCandidatesAsyncPath = '/api/v1/people/{id}/tax-deductions/candidates';
  static readonly CreateTaxDeductionAsyncPath = '/api/v1/patients/{id}/tax-deductions';
  static readonly CreatePersonTaxDeductionAsyncPath = '/api/v1/people/{id}/tax-deductions';
  static readonly PersonTaxDeductionsAsyncPath = '/api/v1/people/{id}/tax-deductions';
  static readonly PersonTaxDeductionContentAsyncPath = '/api/v1/people/{personId}/tax-deductions/{hash}';
  static readonly DeletePersonTaxDeductionAsyncPath = '/api/v1/people/{personId}/tax-deductions/{hash}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TaxDeductionsService.CandidatesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  CandidatesAsyncResponse(params: TaxDeductionsService.CandidatesAsyncParams): __Observable<__StrictHttpResponse<Array<TaxDeductionCandidate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/patients/${params.id}/tax-deductions/candidates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TaxDeductionCandidate>>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.CandidatesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  CandidatesAsync(params: TaxDeductionsService.CandidatesAsyncParams): __Observable<Array<TaxDeductionCandidate>> {
    return this.CandidatesAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<TaxDeductionCandidate>)
    );
  }

  /**
   * @param params The `TaxDeductionsService.PersonTaxDeductionCandidatesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  PersonTaxDeductionCandidatesAsyncResponse(params: TaxDeductionsService.PersonTaxDeductionCandidatesAsyncParams): __Observable<__StrictHttpResponse<Array<TaxDeductionCandidate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/people/${params.id}/tax-deductions/candidates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TaxDeductionCandidate>>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.PersonTaxDeductionCandidatesAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  PersonTaxDeductionCandidatesAsync(params: TaxDeductionsService.PersonTaxDeductionCandidatesAsyncParams): __Observable<Array<TaxDeductionCandidate>> {
    return this.PersonTaxDeductionCandidatesAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<TaxDeductionCandidate>)
    );
  }

  /**
   * @param params The `TaxDeductionsService.CreateTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateTaxDeductionAsyncResponse(params: TaxDeductionsService.CreateTaxDeductionAsyncParams): __Observable<__StrictHttpResponse<CreateTaxDeductionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/patients/${params.id}/tax-deductions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateTaxDeductionResponse>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.CreateTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateTaxDeductionAsync(params: TaxDeductionsService.CreateTaxDeductionAsyncParams): __Observable<CreateTaxDeductionResponse> {
    return this.CreateTaxDeductionAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateTaxDeductionResponse)
    );
  }

  /**
   * @param params The `TaxDeductionsService.CreatePersonTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreatePersonTaxDeductionAsyncResponse(params: TaxDeductionsService.CreatePersonTaxDeductionAsyncParams): __Observable<__StrictHttpResponse<CreateTaxDeductionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/people/${params.id}/tax-deductions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateTaxDeductionResponse>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.CreatePersonTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreatePersonTaxDeductionAsync(params: TaxDeductionsService.CreatePersonTaxDeductionAsyncParams): __Observable<CreateTaxDeductionResponse> {
    return this.CreatePersonTaxDeductionAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateTaxDeductionResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PersonTaxDeductionsAsyncResponse(id: number): __Observable<__StrictHttpResponse<Array<PersonTaxDeduction>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/people/${id}/tax-deductions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonTaxDeduction>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PersonTaxDeductionsAsync(id: number): __Observable<Array<PersonTaxDeduction>> {
    return this.PersonTaxDeductionsAsyncResponse(id).pipe(
      __map(_r => _r.body as Array<PersonTaxDeduction>)
    );
  }

  /**
   * @param params The `TaxDeductionsService.PersonTaxDeductionContentAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   *
   * @return Success
   */
  PersonTaxDeductionContentAsyncResponse(params: TaxDeductionsService.PersonTaxDeductionContentAsyncParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/people/${params.personId}/tax-deductions/${params.hash}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.PersonTaxDeductionContentAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   *
   * @return Success
   */
  PersonTaxDeductionContentAsync(params: TaxDeductionsService.PersonTaxDeductionContentAsyncParams): __Observable<Blob> {
    return this.PersonTaxDeductionContentAsyncResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `TaxDeductionsService.DeletePersonTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   */
  DeletePersonTaxDeductionAsyncResponse(params: TaxDeductionsService.DeletePersonTaxDeductionAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/people/${params.personId}/tax-deductions/${params.hash}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `TaxDeductionsService.DeletePersonTaxDeductionAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   */
  DeletePersonTaxDeductionAsync(params: TaxDeductionsService.DeletePersonTaxDeductionAsyncParams): __Observable<null> {
    return this.DeletePersonTaxDeductionAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TaxDeductionsService {

  /**
   * Parameters for CandidatesAsync
   */
  export interface CandidatesAsyncParams {
    id: number;
    To?: string;
    From?: string;
  }

  /**
   * Parameters for PersonTaxDeductionCandidatesAsync
   */
  export interface PersonTaxDeductionCandidatesAsyncParams {
    id: number;
    To?: string;
    From?: string;
  }

  /**
   * Parameters for CreateTaxDeductionAsync
   */
  export interface CreateTaxDeductionAsyncParams {
    id: number;
    request?: CreateTaxDeductionRequest;
  }

  /**
   * Parameters for CreatePersonTaxDeductionAsync
   */
  export interface CreatePersonTaxDeductionAsyncParams {
    id: number;
    request?: CreateTaxDeductionRequest;
  }

  /**
   * Parameters for PersonTaxDeductionContentAsync
   */
  export interface PersonTaxDeductionContentAsyncParams {
    personId: number;
    hash: string;
  }

  /**
   * Parameters for DeletePersonTaxDeductionAsync
   */
  export interface DeletePersonTaxDeductionAsyncParams {
    personId: number;
    hash: string;
  }
}

export { TaxDeductionsService }
