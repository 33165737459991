import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PatientSearchResponse, PatientSearchResult } from '../../../../generated/models';
import { CallReportsService, PatientsService } from '../../../../generated/services';
import { ActiveCallService, Call, CallAction } from '../../services/active-call-service';
import { ActiveCallReportModalComponent, ActiveCallReportPayload } from '../active-call-report-modal/active-call-report-modal.component';

@Component({
  selector: 'mp-active-call-modal',
  templateUrl: './active-call-modal.component.html',
  styleUrls: ['./active-call-modal.component.scss']
})
export class ActiveCallModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Output() onAction = new EventEmitter<string>();

  @HostBinding('class.started') get started(): boolean { return !!this.call; }
  @HostBinding('class.accepted') get accepted(): boolean { return this.call && this.call.accepted; }
  @HostBinding('class.long') long = false;

  @HostBinding('class.shown') get shown() { return !!this.call; }

  patientLoading = false;

  patients: PatientSearchResult[] = [];

  calls: Call[] = [];
  call: Call;
  get empty(): boolean { return !this.call; }

  get formatted(): string {
    return this.call && this.call.interlocutor ? this.format(this.call.interlocutor.phone) : '';
  }

  get actions() { return this.call ? this.call.actions : []; }
  get formattedActions(): string { return this.actions.map(x => this.actionName(x)).join(', '); }

  get patientId(): number { return this.call ? this.call.interlocutor.id : undefined; }
  get patientName(): string {
    return this.call ? [this.call.interlocutor.lastname, this.call.interlocutor.firstname, this.call.interlocutor.middlename].join(' ') : '';
  }

  filters = new FormGroup({
    search: new FormControl('', [])
  });

  constructor(
    private activeCallService: ActiveCallService,
    private patientsService: PatientsService,
    private callReportsService: CallReportsService,
    private router: Router,
    private toastrService: ToastrService,
    private modal: NgbModal) {

    activeCallService.calls$
      .pipe(takeUntil(this.destroy$))
      .subscribe(calls => { this.calls = calls; console.info(this.calls) });

    activeCallService.active$
      .pipe(takeUntil(this.destroy$))
      .subscribe(call => {
        document.body.classList.add('with-widget');

        this.call = call;
        if (!call.interlocutor.id) {
          this.filters.patchValue({ search: call.interlocutor.phone });
        }
      });

    activeCallService.cancelled$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.call = undefined;
        document.body.classList.remove('with-widget');
      });

    activeCallService.completed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.call = undefined;
        document.body.classList.remove('with-widget');
      });

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((a: { search: string }, b: { search: string }) => {
          return a.search === b.search;
        }),
        tap(() => {
          this.patientLoading = true;
          this.patients = [];
        }),
        switchMap((value: { search: string }): Observable<PatientSearchResponse> =>
          this.patientsService.Search({ Page: 1, Size: 999, Search: value.search })
            .pipe(catchError(() => from([{ items: [] }])))
        )
      )
      .subscribe((response: PatientSearchResponse) => {
        const patients = response.items.filter((x, i, array) => {
          if (x.lastVisit) return true;

          const name = [x.lastname, x.firstname, x.middlename].join(' ');

          /// не показывать пациент без посещений, если есть двойники с посещениями
          if (array.some(p => [p.lastname, p.firstname, p.middlename].join(' ') === name && p.phone === x.phone && !!p.lastVisit)) return false;

          // показывать только первого пациента без посещений, если нет двойников с посещениями
          return array.findIndex(p => [p.lastname, p.firstname, p.middlename].join(' ') === name && p.phone === x.phone) === i;
        });

        this.patients = patients;
        this.patientLoading = false;

        if (this.call && !this.call.interlocutor.id && this.patients.length === 1) {
          this.setPatient(this.patients[0]);
        }
      });
  }

  ngOnInit() {
    document.body.classList.remove('with-widget');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setPatient(x: PatientSearchResult) {
    this.activeCallService.setInterlocutor(x.id, x.lastname, x.firstname, x.middlename, x.dob);

    this.long = false;
  }

  reset() {
    this.patientLoading = false;
    this.patients = [];
    this.long = false;
  }

  toggle = () => this.long = !this.long;
  accept = () => this.activeCallService.accept();
  cancel = () => this.activeCallService.cancel(true);

  actionName(action: CallAction): string {
    if (!action) return '';

    switch (action.ActionType) {
      case 1: return 'Консультация';
      case 2: return 'Запись на прием';
      case 3: return 'Результаты анализов';
      case 4: return 'Другое';
      default: return '';
    }
  }

  format(phone: string): string {
    if (phone && phone.length === 11)
      return `+7 (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`;
    return phone;
  }

  consult() {
    this.activeCallService.addAction(1);
  }

  openSchedules() {
    this.activeCallService.addAction(2);

    this.router.navigate(['schedules', 'week'], {});
  }
  openObservations() {
    this.activeCallService.addAction(3);
    if (!this.call) {
      this.router.navigate(['observations']);
    } else if (!this.call.interlocutor.id) {
      this.router.navigate(['observations'], { queryParams: { search: this.call.interlocutor.phone } });
    } else {
      const name = [this.call.interlocutor.lastname, this.call.interlocutor.firstname, this.call.interlocutor.middlename].join(' ');
      this.router.navigate(['observations'], { queryParams: { search: name } });
    }
  }

  conclude() {
    const options: NgbModalOptions = { backdrop: 'static', centered: true, size: 'sm' };
    const modalRef = this.modal.open(ActiveCallReportModalComponent, options);
    const componentRef: ActiveCallReportModalComponent = modalRef.componentInstance;

    componentRef.actions = this.call.actions;

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe(async (payload: ActiveCallReportPayload) => {

      try {
        await this.callReportsService.CreateIncomingCallReport({
          comment: payload.comment,
          phone: this.call.interlocutor.phone,
          actions: this.call.actions.map(x => ({ actionType: x.ActionType }))
        }).toPromise();

        this.activeCallService.complete(true);
        modalRef.close();

        this.reset();

        this.toastrService.success("Отчет сохранен", 'Успешно');
      } catch (e) {
        this.toastrService.error('Не удалось сохранить отчет', 'Ошибка');
        componentRef.processing = false;
      }
    });
  }

  selected = (patient: PatientSearchResult) => this.call && this.call.interlocutor.id === patient.id;
  select = (patient: PatientSearchResult) => this.setPatient(patient);

  activate = (call: Call) => this.activeCallService.activate(call.id);
}
