/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Appointment } from '../models/appointment';
import { AppointmentViewModel } from '../models/appointment-view-model';
import { ClaimSlotV2Request } from '../models/claim-slot-v2request';
import { UpdateSlotV2Request } from '../models/update-slot-v2request';
@Injectable({
  providedIn: 'root',
})
class AppointmentsV2Service extends __BaseService {
  static readonly FindAppointmentsAsyncPath = '/api/v2/appointments';
  static readonly ClaimAsyncPath = '/api/v2/appointments/{id}/claim';
  static readonly UpdateAsyncPath = '/api/v2/appointments/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AppointmentsV2Service.FindAppointmentsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  FindAppointmentsAsyncResponse(params: AppointmentsV2Service.FindAppointmentsAsyncParams): __Observable<__StrictHttpResponse<Array<Appointment>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/appointments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Appointment>>;
      })
    );
  }
  /**
   * @param params The `AppointmentsV2Service.FindAppointmentsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `From`:
   *
   * @return Success
   */
  FindAppointmentsAsync(params: AppointmentsV2Service.FindAppointmentsAsyncParams): __Observable<Array<Appointment>> {
    return this.FindAppointmentsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Appointment>)
    );
  }

  /**
   * @param params The `AppointmentsV2Service.ClaimAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ClaimAsyncResponse(params: AppointmentsV2Service.ClaimAsyncParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/appointments/${params.id}/claim`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `AppointmentsV2Service.ClaimAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  ClaimAsync(params: AppointmentsV2Service.ClaimAsyncParams): __Observable<AppointmentViewModel> {
    return this.ClaimAsyncResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }

  /**
   * @param params The `AppointmentsV2Service.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  UpdateAsyncResponse(params: AppointmentsV2Service.UpdateAsyncParams): __Observable<__StrictHttpResponse<AppointmentViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/appointments/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AppointmentViewModel>;
      })
    );
  }
  /**
   * @param params The `AppointmentsV2Service.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  UpdateAsync(params: AppointmentsV2Service.UpdateAsyncParams): __Observable<AppointmentViewModel> {
    return this.UpdateAsyncResponse(params).pipe(
      __map(_r => _r.body as AppointmentViewModel)
    );
  }
}

module AppointmentsV2Service {

  /**
   * Parameters for FindAppointmentsAsync
   */
  export interface FindAppointmentsAsyncParams {
    To?: string;
    From?: string;
  }

  /**
   * Parameters for ClaimAsync
   */
  export interface ClaimAsyncParams {
    id: number;
    request?: ClaimSlotV2Request;
  }

  /**
   * Parameters for UpdateAsync
   */
  export interface UpdateAsyncParams {
    id: number;
    request?: UpdateSlotV2Request;
  }
}

export { AppointmentsV2Service }
