import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SystemSetting } from '../../../../generated/models';

@Component({
  selector: 'mp-system-settings-list-item',
  templateUrl: './system-settings-list-item.component.html',
  styleUrls: ['./system-settings-list-item.component.scss']
})
export class SystemSettingsListItemComponent implements OnInit {

  @Input("setting") setting: SystemSetting;

  constructor() { }

  ngOnInit() {
  }

}
