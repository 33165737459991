/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MedicalFileResponse } from '../models/medical-file-response';
import { EstablishedDiagnosesResponse } from '../models/established-diagnoses-response';
import { DocumentsResponse } from '../models/documents-response';
import { MultipleDocumentsRequest } from '../models/multiple-documents-request';
import { VisitsSummaryResponse } from '../models/visits-summary-response';
import { TestResultsResponse } from '../models/test-results-response';
import { ChangeCommentRequest } from '../models/change-comment-request';
@Injectable({
  providedIn: 'root',
})
class MedicalFilesService extends __BaseService {
  static readonly MedicalFilePath = '/api/MedicalFiles/{id}';
  static readonly DiagnosesPath = '/api/MedicalFiles/{id}/diagnoses';
  static readonly DocumentsPath = '/api/MedicalFiles/{id}/documents';
  static readonly DeleteDocumentsPath = '/api/MedicalFiles/{id}/documents';
  static readonly VisitsPath = '/api/MedicalFiles/{id}/visits';
  static readonly TestResultsPath = '/api/MedicalFiles/{id}/testresults';
  static readonly ChangeCommentPath = '/api/MedicalFiles/{id}/comment';
  static readonly UploadDocumentPath = '/api/MedicalFiles/{id}/documents/upload';
  static readonly PrintDocumentsPath = '/api/MedicalFiles/{id}/documents/print';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   * @return Success
   */
  MedicalFileResponse(id: string): __Observable<__StrictHttpResponse<MedicalFileResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MedicalFiles/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MedicalFileResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  MedicalFile(id: string): __Observable<MedicalFileResponse> {
    return this.MedicalFileResponse(id).pipe(
      __map(_r => _r.body as MedicalFileResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DiagnosesResponse(id: string): __Observable<__StrictHttpResponse<EstablishedDiagnosesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MedicalFiles/${id}/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EstablishedDiagnosesResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Diagnoses(id: string): __Observable<EstablishedDiagnosesResponse> {
    return this.DiagnosesResponse(id).pipe(
      __map(_r => _r.body as EstablishedDiagnosesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DocumentsResponse(id: string): __Observable<__StrictHttpResponse<DocumentsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MedicalFiles/${id}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DocumentsResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Documents(id: string): __Observable<DocumentsResponse> {
    return this.DocumentsResponse(id).pipe(
      __map(_r => _r.body as DocumentsResponse)
    );
  }

  /**
   * @param params The `MedicalFilesService.DeleteDocumentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  DeleteDocumentsResponse(params: MedicalFilesService.DeleteDocumentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MedicalFiles/${params.id}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MedicalFilesService.DeleteDocumentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  DeleteDocuments(params: MedicalFilesService.DeleteDocumentsParams): __Observable<null> {
    return this.DeleteDocumentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  VisitsResponse(id: string): __Observable<__StrictHttpResponse<VisitsSummaryResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MedicalFiles/${id}/visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitsSummaryResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Visits(id: string): __Observable<VisitsSummaryResponse> {
    return this.VisitsResponse(id).pipe(
      __map(_r => _r.body as VisitsSummaryResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  TestResultsResponse(id: string): __Observable<__StrictHttpResponse<TestResultsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/MedicalFiles/${id}/testresults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TestResultsResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  TestResults(id: string): __Observable<TestResultsResponse> {
    return this.TestResultsResponse(id).pipe(
      __map(_r => _r.body as TestResultsResponse)
    );
  }

  /**
   * @param params The `MedicalFilesService.ChangeCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeCommentResponse(params: MedicalFilesService.ChangeCommentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MedicalFiles/${params.id}/comment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MedicalFilesService.ChangeCommentParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  ChangeComment(params: MedicalFilesService.ChangeCommentParams): __Observable<null> {
    return this.ChangeCommentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  UploadDocumentResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/MedicalFiles/${id}/documents/upload`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  UploadDocument(id: string): __Observable<null> {
    return this.UploadDocumentResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `MedicalFilesService.PrintDocumentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  PrintDocumentsResponse(params: MedicalFilesService.PrintDocumentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/MedicalFiles/${params.id}/documents/print`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MedicalFilesService.PrintDocumentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  PrintDocuments(params: MedicalFilesService.PrintDocumentsParams): __Observable<null> {
    return this.PrintDocumentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MedicalFilesService {

  /**
   * Parameters for DeleteDocuments
   */
  export interface DeleteDocumentsParams {
    id: string;
    request?: MultipleDocumentsRequest;
  }

  /**
   * Parameters for ChangeComment
   */
  export interface ChangeCommentParams {
    id: string;
    request?: ChangeCommentRequest;
  }

  /**
   * Parameters for PrintDocuments
   */
  export interface PrintDocumentsParams {
    id: string;
    request?: MultipleDocumentsRequest;
  }
}

export { MedicalFilesService }
