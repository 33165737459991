import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompaniesService, ObservationsService } from '../../../generated/services';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { LabModel, LabsResponse, ReferralsResponse, Company } from '../../../generated/models';
import { ReferralModel } from '../../../generated/models/referral-model';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ObservationsResolver implements Resolve<ObservationPayload> {

  constructor(private service: ObservationsService, private companiesService: CompaniesService) { }


  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ObservationPayload> {
    const companiesLoader: Observable<Company[]> = this.companiesService.MyCompanies(moment().format('DD.MM.YYYY'));

    const labsLoader: Observable<LabModel[]> = this.service.Labs()
      .pipe(
        map((response: LabsResponse): LabModel[] => response.labs)
      );

    const referralsLoader: Observable<ReferralModel[]> = this.service.Referrals()
      .pipe(
        map((response: ReferralsResponse): ReferralModel[] => response.referrals)
      );

    return forkJoin({
      companies: companiesLoader,
      labs: labsLoader,
      referrals: referralsLoader
    });
  }
}

export class ObservationPayload {
  public labs: LabModel[];
  public companies: Company[];
  public referrals: ReferralModel[];
}
