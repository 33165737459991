import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: "mp-comment-editor-modal",
  templateUrl: "./comment-editor.component.html",
  styleUrls: ["./comment-editor.component.scss"]
})

export class CommentEditorModalComponent implements OnInit {

  private _title: string;
  private _submitButtonText: string;

  private _modal: NgbActiveModal;

  private _loading: boolean;
  private _loaded: boolean;

  @Input("title") title: string;
  @Input("txt") txt: string;

  form: FormGroup;

  public constructor(
    modal: NgbActiveModal,
  ) {
    this._modal = modal;
  }

  ngOnInit(): void {

    this.form = new FormGroup({
      comment: new FormControl(this.txt, [Validators.maxLength(255)])
    });
  }

  public get submitButtonText(): string { return this._submitButtonText; }
  public get loading(): boolean { return this._loading; }
  public get loaded(): boolean { return this._loaded; }

  public ok = (): void => {
    let request: string = this.form.controls['comment'].value;

    if (!this.form.valid) {
      return;
    }

    this._modal.close(this.form.get("comment").value);
  }

  public cancel = (): void => {
    this._modal.dismiss();
  }
}
