import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { WageSchema } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-wage-schema-modal',
  templateUrl: './employee-wage-schema-modal.component.html',
  styleUrls: ['./employee-wage-schema-modal.component.scss']
})
export class EmployeeWageSchemaModalComponent implements OnInit {
  title = "Схема оплаты"

  @Input()
  schemas: WageSchema[] = [];

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfitm: EventEmitter<EmployeeWageSchemaModalPayload> = new EventEmitter<EmployeeWageSchemaModalPayload>();

  form: FormGroup;
  constructor() {
    this.form = new FormGroup({
      schema: new FormControl(undefined, [Validators.required]),
      effective: new FormControl("", [Validators.required]),
      expires: new FormControl("", [])
    });
  }

  ngOnInit() { }

  cancel(): void {
    this.onCancel.emit();
  }

  confirm(): void {
    Object.entries(this.form.controls).forEach(x => x[1].markAsDirty());

    if (this.form.invalid) {
      return;
    }

    const value: EmployeeWageSchemaModalPayload = this.form.getRawValue();

    this.onConfitm.emit(value);
  }

}

export interface EmployeeWageSchemaModalPayload {
  schema: number;
  effective: moment.Moment;
  expires: moment.Moment;
}
