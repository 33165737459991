import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Resource} from '../../../../generated/models';

@Component({
  selector: 'mp-schedule-list-item',
  templateUrl: './schedule-list-item.component.html',
  styleUrls: ['./schedule-list-item.component.scss']
})
export class ScheduleListItemComponent implements OnInit {
  @Input() resource: Resource;

  @Output()
  onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = (): void => this.onRemove.emit();
}
