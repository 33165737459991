import { Component, OnInit, forwardRef, HostBinding, HostListener, ViewChild, ElementRef, Input, OnDestroy , Attribute} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private _destroy: Subject<any> = new Subject<any>();

  private _disabled: boolean;
  private _indeterminate: boolean;

  private _changeValueFn: (value: boolean) => any;
  private _changeStatusFn: () => any;

  @HostBinding('class.focused') focused: boolean = false;


  @HostListener("focus", [])
  onFocus(): void {
    this.input.nativeElement.focus();
    this.focused = true;
  }

  @HostListener("focusout", [])
  onFocusout(): void {
    this.focused = false;
  }

  @Input()
  tabindex: number;

  @Input()
   public set indeterminate(value: boolean) {
    this._indeterminate = value;
    if (value) {
      this.checkbox.setValue(false, { emitEvent: false });
    }
  }

  @Input()
   public set checked(value: boolean) {
    this.checkbox.setValue(value, { emitEvent: false });
  }

  @ViewChild("input") input: ElementRef<HTMLElement>;

  public checkbox: FormControl;
  constructor() {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: boolean): void => {
          this._indeterminate = false;

          if (this._changeValueFn) {
            this._changeValueFn(value);
          }
        },
        (): void => { }
      );
  }

  ngOnInit() { 
    this.input.nativeElement.addEventListener("focus", () =>{
      this.focused = true;
    });

    this.input.nativeElement.addEventListener("focusout", () =>{
      this.focused = false;
    });

  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public blur(): void {
    if (this._changeStatusFn) {
      this._changeStatusFn();
    }
  }

  public get disabled(): boolean { return this._disabled; }
  public get indeterminate(): boolean { return this._indeterminate; }

  writeValue(value: boolean): void {
    this.checkbox.setValue(value);
  }
  registerOnChange(fn: any): void {
    this._changeValueFn = fn;
  }
  registerOnTouched(fn: any): void {
    this._changeStatusFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;

    if (isDisabled) {
      this.checkbox.enabled ? this.checkbox.disable({ emitEvent: false }) : undefined;
    } else {
      this.checkbox.disabled ? this.checkbox.enable({ emitEvent: false }) : undefined;
    }
  }
}
