import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from 'rxjs';
import { SettingsStorage } from '../services/settings-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class SettingsGuard implements CanActivate {

  constructor(private settingsStorage: SettingsStorage) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.settingsStorage.populate();
  }
}
