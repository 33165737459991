import { OrganizationResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { OrganizationsService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class OrganizationResolver implements Resolve<OrganizationResolverPayload> {

  public constructor(
    private service: OrganizationsService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrganizationResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let organizationLoader: Observable<OrganizationResponse> = null;

    if (id) {
      organizationLoader = this.service.Get(id);
    } else {
      const organization: any = {};

      organizationLoader = from([organization]);
    }

    return forkJoin({
      organization: organizationLoader,
    });
  }
}

export class OrganizationResolverPayload {
  public organization: OrganizationResponse;

  public constructor() {
  }
}
