import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportPatientsComponent } from './components/import-patients/import-patients.component';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from "@skytecs/controls";
import { ParsedPatientComponent } from './components/parsed-patient/parsed-patient.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ImportPatientsResolver } from './resolvers/import-patients-resolver';
import { PermissionNames } from '../../models/permission-names';

const routes: Routes = [
  {
    path: 'patients',
    component: ImportPatientsComponent,
    data: {
      title: 'Импорт пациентов',
      requirements: [PermissionNames.ImportPatients]
    },
    resolve: {
      payload: ImportPatientsResolver
    }
  }
];

@NgModule({
  declarations: [ImportPatientsComponent, ParsedPatientComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    LayoutModule,
    ControlsModule,
    ReactiveFormsModule,
    ToastrModule
  ],
  exports: [
    RouterModule
  ]
})
export class ImportModule { }
