import { Component, OnInit, OnDestroy } from '@angular/core';
import { Role, RolePermission, PermissionsResponse, Permission, UpdateRolePermissionRequest, CreateRolePermissionRequest, CreateRoleResponse } from '../../../../generated/models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, from, Observable } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { RolePayload } from '../../resolvers/role-resolver';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RolePermissionModalComponent } from '../role-permission-modal/role-permission-modal.component';
import { PermissionsService, RolesService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
  host: { class: "page" }
})
export class RoleComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<any>();

  id: number;
  processing = false;
  modalOpened = false;

  title = "Новая роль";

  get permissions(): RolePermission[] { return [...this.form.getRawValue().permissions]; }
  selectedPermissions: RolePermission[] = [];

  canCreate = false;
  canEdit = false;

  readonly = false;

  form = new FormGroup({
    name: new FormControl("", [Validators.required]),
    description: new FormControl(""),
    permissions: new FormControl([])
  });

  checkbox = new FormControl(false);

  get hasSelected(): boolean { return this.selectedPermissions.length > 0; }

  get canSave(): boolean {
    if (this.id > 0) return this.canEdit;
    return this.canCreate;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userStorage: UserStorage,
    private modal: NgbModal,
    private permissionsService: PermissionsService,
    private toastrService: ToastrService,
    private rolesService: RolesService
  ) {

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(
        (value: boolean): void => {
          if (value) {
            this.selectedPermissions = [...this.permissions];
          } else {
            this.selectedPermissions = [];
          }

        },
        (): void => { }
      );

    this.canCreate = this.userStorage.hasPermission(PermissionNames.CreateRoles);
    this.canEdit = this.userStorage.hasPermission(PermissionNames.EditRoles);
  }

  ngOnInit() {
    this.id = parseInt(this.activatedRoute.snapshot.paramMap.get("id"), 10);

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { payload: RolePayload }): void => {
          this.form.patchValue({
            ...data.payload.role,
            permissions: [...(data.payload.role || {}).permissions || []]
          });

          this.title = data.payload.role.id > 0 ? `Роль: ${data.payload.role.name}` : "Новая роль";
        },
        (): void => { }
      );

    if ((this.id > 0 && this.canEdit) || (!this.id && this.canCreate)) {
      this.form.disabled ? this.form.enable() : undefined;
    } else {
      this.form.enabled ? this.form.disable() : undefined;
      this.readonly = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  selected = (permission: RolePermission) => this.selectedPermissions.some(x => x.id === permission.id);

  toggle(permission: RolePermission, value: boolean): void {
    const index: number = this.selectedPermissions.findIndex(x => x.id === permission.id);
    if (value && index === -1) {
      this.selectedPermissions.push({ ...permission });
    }

    if (!value && index !== -1) {
      this.selectedPermissions.splice(index, 1);
    }
  }

  remove(): void {
    if (!this.canCreate && !this.id) return;

    if (!this.canEdit && this.id > 0) return;

    if (this.selectedPermissions.length === 0) return;

    const permissions = this.permissions.filter(x => !this.selectedPermissions.some((s: RolePermission): boolean => s.id === x.id));

    this.form.markAsDirty();
    this.form.patchValue({ permissions: [...permissions] }, { emitEvent: true });

    this.checkbox.setValue(false);
  }

  change(value: boolean) {
    const permissions = this.permissions.map(x => ({ ...x, granted: this.selected(x) ? value : x.granted }));

    this.checkbox.setValue(false);

    this.form.markAsDirty();
    this.form.patchValue({ permissions: [...permissions] }, { emitEvent: true });
  }

  grant = () => this.change(true);
  deny = () => this.change(false);

  async addPermission() {
    if (this.modalOpened) return;
    this.modalOpened = true;

    const response = await this.permissionsService.Permissions({ Size: 0, Page: 1 }).toPromise();

    const modalRef = this.modal.open(RolePermissionModalComponent, { size: "lg", backdrop: 'static' });
    const componentRef: RolePermissionModalComponent = modalRef.componentInstance;

    componentRef.permissions = response.items.filter(x => !this.permissions.some(p => x.id === p.permissionId));

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
      this.modalOpened = false;
    });

    componentRef.onConfirm.subscribe(async (payload: RolePermission) => {
      const index = this.permissions.findIndex(x => x.permissionId === payload.permissionId);

      const permissions = [...this.permissions];

      if (index === -1) {
        permissions.unshift(payload);
      } else {
        const moved: RolePermission[] = this.permissions.splice(index, 1);

        moved[0].granted = payload.granted;

        permissions.unshift(moved[0]);
      }

      this.form.markAsDirty();
      this.form.markAsTouched();
      this.form.patchValue({ permissions: [...permissions] }, { emitEvent: true });


      modalRef.close();
      this.modalOpened = false;
    });
  }

  save(): void {
    if (this.form.invalid) return;

    if (!this.canSave) return;

    if (this.id > 0) {
      this.update();
    } else {
      this.create();
    }
  }

  cancel(): void {
    this.router.navigate([".."], { relativeTo: this.activatedRoute });
  }

  private update(): void {
    this.processing = true;

    const value: { name: string, description: string } = this.form.getRawValue();

    const permissions: UpdateRolePermissionRequest[] = this.permissions
      .map((x: RolePermission): UpdateRolePermissionRequest => ({
        id: x.id,
        permissionId: x.permissionId,
        granted: x.granted
      }));

    this.rolesService.Update({
      id: this.id,
      request: {
        name: value.name,
        decsription: value.description,
        permissions: permissions
      }
    })
      .subscribe(
        (): void => {
          this.toastrService.success("", "Роль сохранена");
          this.processing = false;
          this.title = `Роль: ${this.form.get("name").value}`;
        },
        (response: HttpErrorResponse): void => {
          this.handleErrorResponse(response);
          this.processing = false;
        }
      );
  }
  private create(): void {
    this.processing = true;

    const value: { name: string, description: string } = this.form.getRawValue();

    const permissions: CreateRolePermissionRequest[] = this.permissions
      .map((x: RolePermission): CreateRolePermissionRequest => ({ permissionId: x.permissionId, granted: x.granted }));

    this.rolesService.Create({
      name: value.name,
      description: value.description,
      permissions: permissions
    })
      .subscribe(
        (response: CreateRoleResponse): void => {
          this.toastrService.success("", "Роль создана");
          this.router.navigate(["..", response.id.toString()], { relativeTo: this.activatedRoute });
          this.processing = false;
        },
        (response: HttpErrorResponse): void => {
          this.handleErrorResponse(response);
          this.processing = false;
        }
      );

  }
  private handleErrorResponse(response: HttpErrorResponse): void {

    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }

    if (response.status === 403) {
      this.toastrService.error("Для выполнения данного действия необходимо разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }
}
