import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeTemplateModalComponent } from './components/change-template-modal/change-template-modal.component';
import { ChangeTemplateModalItemComponent } from './components/change-template-modal-item/change-template-modal-item.component';
import { LabResultsModalComponent } from './components/lab-results-modal/lab-results-modal.component';
import { LabResultsModalItemComponent } from './components/lab-results-modal-item/lab-results-modal-item.component';

import { ControlsModule } from '@skytecs/controls';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ChangeTemplateModalComponent, ChangeTemplateModalItemComponent, LabResultsModalComponent, LabResultsModalItemComponent],
  imports: [
    CommonModule,
    NgbPopoverModule,
    ControlsModule
  ],
  exports: [ChangeTemplateModalComponent, LabResultsModalComponent]
})
export class RecordEditorModule { }
