import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RefundReasonsService } from '../../../../generated/services';
import { RefundReasonResolverPayload } from '../../resolvers/refund-reason-resolver';

@Component({
  selector: 'mp-refund-reason',
  templateUrl: './refund-reason.component.html',
  styleUrls: ['./refund-reason.component.scss'],
  host: { class: "page" }
})
export class RefundReasonComponent implements OnInit {

  id: number;

  title = "Новая причина возврата";

  breadcrumbs = [
    { url: '/', title: 'Главная' },
    { url: '/settings/refund-reasons', title: 'Причины возвратов' }
  ];

  form: FormGroup;

  constructor(
    private service: RefundReasonsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.form = new FormGroup({
      description: new FormControl("", [Validators.required]),
      archived: new FormControl(false)
    });
  }

  async ngOnInit(): Promise<void> {

    this.activatedRoute.data
      .subscribe((data: { payload: RefundReasonResolverPayload }): void => {
        this.id = data.payload.refundReason.id;

        if (this.id) {
          this.title = `Причина возврата: ${data.payload.refundReason.description}`;
        }

        this.form.patchValue({ ...data.payload.refundReason }, { emitEvent: false });
      });

  }

  async acceptChanges(): Promise<void> {
    Object.entries(this.form.controls).forEach(x => x[1].markAsTouched());

    if (!this.form.valid) return;

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      try {
        await this.service.UpdateAsync({ id: this.id, request: { ...value } }).toPromise();
        this.title = `Причина возврата: ${value.description}`;
        this.toastrService.success('Причина возdрата обновлена', 'Успешно');
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          const message = response.error.errors.map(x => x.message).join('<br/>');

          this.toastrService.warning(message, 'Ошибка');
          return;
        }

        this.toastrService.error('Не удалось обновить причину возврата', 'Ошибка');
      }
    }
    else {
      try {
        const result = await this.service.CreateAsync({ ...value }).toPromise();
        this.toastrService.success('Причина вовзрата добавлена', 'Успешно');
        this.router.navigate(['..', result.refundReasonId], { relativeTo: this.activatedRoute });
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          const message = response.error.errors.map(x => x.message).join('<br/>');

          this.toastrService.warning(message, 'Ошибка');
          return;
        }

        this.toastrService.error('Не удалось добавить причину возврата', 'Ошибка');
      }
    }
  }

}

interface FormValue {
  description: string;
  archived: boolean;
}
