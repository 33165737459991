import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CompaniesService } from '../../../generated/services';
import { Observable } from 'rxjs';
import { Company } from '../../../generated/models';

@Injectable({
  providedIn: 'root'
})
export class ScheduleItemsResolver implements Resolve<Company[]> {

  constructor(private service: CompaniesService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company[]> {
    return this.service.MyCompanies();
  }
}
