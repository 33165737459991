import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManageOrganizationModel, ExternalConnectionResponse, ExternalConnectionsResponse } from '../../../../generated/models';
import { UserStorage } from '../../../../services/user-storage';
import { ExternalConnectionsService } from '../../../../generated/services';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
  selector: 'mp-external-connection-list',
  templateUrl: './external-connections.component.html',
  styleUrls: ['./external-connections.component.scss'],
  host: { class: "page" }
})
export class ExternalConnectionsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  externalConnections: ExternalConnectionResponse[] = [];
  selected: { [id: number]: boolean } = {};

  page = 1;
  size = 50;
  total = 0;

  scrollDistance = 1;

  filters = new FormGroup({
    search: new FormControl(""),
    activeOnly: new FormControl(true)
  });

  constructor(
    private externalConnectionsService: ExternalConnectionsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private userStorage: UserStorage
  ) {
    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value: Filters): void => {

        const queryParams: Params = {};

        if (value.search) {
          queryParams.search = value.search;
        }

        if (!value.activeOnly) {
          queryParams.activeOnly = false;
        }

        this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

        this.page = 1;
        this.externalConnections = [];

        this.loadPage();
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const filters: Filters = {
      search: queryMap.get('search'),
      activeOnly: !queryMap.has('activeOnly') || queryMap.get('activeOnly') == 'true'
    }

    this.filters.patchValue(filters);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async loadPage(): Promise<void> {
    const value: Filters = this.filters.getRawValue();

    const response = await this.externalConnectionsService.ExternalConnections({
      Page: this.page,
      Size: this.size,
      Search: value.search,
      ActiveOnly: value.activeOnly
    }).toPromise();

    this.externalConnections = [...this.externalConnections, ...response.externalConnections];
    this.total = this.total;
  }

  onScrollDown(): void {
    this.page++;

    this.loadPage();
  }

  async openManager(externalConnection: ExternalConnectionResponse): Promise<void> {
    const options: NgbModalOptions = { backdrop: "static", size: "sm" };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Удалить запись из списка?";

    modalRef.result.then(
      async (): Promise<void> => {
        await this.externalConnectionsService.Remove(externalConnection.id).toPromise();

        this.page = 1;
        this.externalConnections = [];

        this.loadPage();
      },
      (): void => { }
    );
  }


  public edit = (promotion: ExternalConnectionResponse): void => {
    this.router.navigate([promotion.id], { relativeTo: this.activatedRoute });
  }

  public create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }
}

interface Filters {
  search: string;
  activeOnly: boolean;
}
