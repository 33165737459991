/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Icd10Version } from '../models/icd-10version';
import { Icd10ResponseItem } from '../models/icd-10response-item';
import { DiagnosesNormalizationResponse } from '../models/diagnoses-normalization-response';
import { DiagnosesNormalizationRequest } from '../models/diagnoses-normalization-request';
import { DiagnosesCodesResponse } from '../models/diagnoses-codes-response';
import { DiagnosesCodesRequest } from '../models/diagnoses-codes-request';
import { DiagnosisNode } from '../models/diagnosis-node';
@Injectable({
  providedIn: 'root',
})
class DiagnosesService extends __BaseService {
  static readonly VersionAsyncPath = '/api/v1/Diagnoses/version';
  static readonly AllIcd10Path = '/api/v1/Diagnoses';
  static readonly GetDiagnosesGroupsPath = '/api/v1/Diagnoses/groupes';
  static readonly SearchAsyncPath = '/api/v1/Diagnoses/search';
  static readonly NormolizePath = '/api/v1/Diagnoses/normolize';
  static readonly NormolizeAsyncPath = '/api/v2/diagnoses/normolize';
  static readonly CodesPath = '/api/v1/Diagnoses/codes';
  static readonly CodesAsyncPath = '/api/v2/diagnoses/codes';
  static readonly DiagnosisNodesAsyncPath = '/api/v2/diagnoses';
  static readonly GroupsAsyncPath = '/api/v2/diagnoses/groups';
  static readonly DiagnosisAsyncPath = '/api/v2/diagnoses/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  VersionAsyncResponse(): __Observable<__StrictHttpResponse<Icd10Version>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses/version`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Icd10Version>;
      })
    );
  }
  /**
   * @return Success
   */
  VersionAsync(): __Observable<Icd10Version> {
    return this.VersionAsyncResponse().pipe(
      __map(_r => _r.body as Icd10Version)
    );
  }

  /**
   * @return Success
   */
  AllIcd10Response(): __Observable<__StrictHttpResponse<Array<Icd10ResponseItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Icd10ResponseItem>>;
      })
    );
  }
  /**
   * @return Success
   */
  AllIcd10(): __Observable<Array<Icd10ResponseItem>> {
    return this.AllIcd10Response().pipe(
      __map(_r => _r.body as Array<Icd10ResponseItem>)
    );
  }

  /**
   * @return Success
   */
  GetDiagnosesGroupsResponse(): __Observable<__StrictHttpResponse<Array<Icd10ResponseItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses/groupes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Icd10ResponseItem>>;
      })
    );
  }
  /**
   * @return Success
   */
  GetDiagnosesGroups(): __Observable<Array<Icd10ResponseItem>> {
    return this.GetDiagnosesGroupsResponse().pipe(
      __map(_r => _r.body as Array<Icd10ResponseItem>)
    );
  }

  /**
   * @param search undefined
   * @return Success
   */
  SearchAsyncResponse(search?: string): __Observable<__StrictHttpResponse<Array<Icd10ResponseItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (search != null) __params = __params.set('search', search.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Icd10ResponseItem>>;
      })
    );
  }
  /**
   * @param search undefined
   * @return Success
   */
  SearchAsync(search?: string): __Observable<Array<Icd10ResponseItem>> {
    return this.SearchAsyncResponse(search).pipe(
      __map(_r => _r.body as Array<Icd10ResponseItem>)
    );
  }

  /**
   * @param Codes undefined
   * @return Success
   */
  NormolizeResponse(Codes?: string): __Observable<__StrictHttpResponse<DiagnosesNormalizationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Codes != null) __params = __params.set('Codes', Codes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses/normolize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiagnosesNormalizationResponse>;
      })
    );
  }
  /**
   * @param Codes undefined
   * @return Success
   */
  Normolize(Codes?: string): __Observable<DiagnosesNormalizationResponse> {
    return this.NormolizeResponse(Codes).pipe(
      __map(_r => _r.body as DiagnosesNormalizationResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  NormolizeAsyncResponse(request?: DiagnosesNormalizationRequest): __Observable<__StrictHttpResponse<DiagnosesNormalizationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/diagnoses/normolize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiagnosesNormalizationResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  NormolizeAsync(request?: DiagnosesNormalizationRequest): __Observable<DiagnosesNormalizationResponse> {
    return this.NormolizeAsyncResponse(request).pipe(
      __map(_r => _r.body as DiagnosesNormalizationResponse)
    );
  }

  /**
   * @param Codes undefined
   * @return Success
   */
  CodesResponse(Codes?: string): __Observable<__StrictHttpResponse<DiagnosesCodesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Codes != null) __params = __params.set('Codes', Codes.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Diagnoses/codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiagnosesCodesResponse>;
      })
    );
  }
  /**
   * @param Codes undefined
   * @return Success
   */
  Codes(Codes?: string): __Observable<DiagnosesCodesResponse> {
    return this.CodesResponse(Codes).pipe(
      __map(_r => _r.body as DiagnosesCodesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CodesAsyncResponse(request?: DiagnosesCodesRequest): __Observable<__StrictHttpResponse<DiagnosesCodesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/diagnoses/codes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiagnosesCodesResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CodesAsync(request?: DiagnosesCodesRequest): __Observable<DiagnosesCodesResponse> {
    return this.CodesAsyncResponse(request).pipe(
      __map(_r => _r.body as DiagnosesCodesResponse)
    );
  }

  /**
   * @param params The `DiagnosesService.DiagnosisNodesAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `ParentId`:
   *
   * - `Page`:
   *
   * @return Success
   */
  DiagnosisNodesAsyncResponse(params: DiagnosesService.DiagnosisNodesAsyncParams): __Observable<__StrictHttpResponse<Array<DiagnosisNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.ParentId != null) __params = __params.set('ParentId', params.ParentId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiagnosisNode>>;
      })
    );
  }
  /**
   * @param params The `DiagnosesService.DiagnosisNodesAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `ParentId`:
   *
   * - `Page`:
   *
   * @return Success
   */
  DiagnosisNodesAsync(params: DiagnosesService.DiagnosisNodesAsyncParams): __Observable<Array<DiagnosisNode>> {
    return this.DiagnosisNodesAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<DiagnosisNode>)
    );
  }

  /**
   * @return Success
   */
  GroupsAsyncResponse(): __Observable<__StrictHttpResponse<Array<DiagnosisNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnoses/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiagnosisNode>>;
      })
    );
  }
  /**
   * @return Success
   */
  GroupsAsync(): __Observable<Array<DiagnosisNode>> {
    return this.GroupsAsyncResponse().pipe(
      __map(_r => _r.body as Array<DiagnosisNode>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DiagnosisAsyncResponse(id: number): __Observable<__StrictHttpResponse<DiagnosisNode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnoses/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiagnosisNode>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  DiagnosisAsync(id: number): __Observable<DiagnosisNode> {
    return this.DiagnosisAsyncResponse(id).pipe(
      __map(_r => _r.body as DiagnosisNode)
    );
  }
}

module DiagnosesService {

  /**
   * Parameters for DiagnosisNodesAsync
   */
  export interface DiagnosisNodesAsyncParams {
    Size?: number;
    Search?: string;
    ParentId?: number;
    Page?: number;
  }
}

export { DiagnosesService }
