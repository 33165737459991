/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { VisitsResponse } from '../models/visits-response';
import { CreateVisitResponse } from '../models/create-visit-response';
import { AddVisitModel } from '../models/add-visit-model';
import { VisitEditorModel } from '../models/visit-editor-model';
import { EditVisitModel } from '../models/edit-visit-model';
import { ServicesResponse } from '../models/services-response';
import { CompanyServiceGroup } from '../models/company-service-group';
import { SelectedService } from '../models/selected-service';
import { AddedServicesResponse } from '../models/added-services-response';
import { AddServicesModel } from '../models/add-services-model';
import { AvailableDiscount } from '../models/available-discount';
import { VisitSpendings } from '../models/visit-spendings';
import { VisitResult } from '../models/visit-result';
import { PrintingStatusResponse } from '../models/printing-status-response';
@Injectable({
  providedIn: 'root',
})
class VisitsService extends __BaseService {
  static readonly VisitsPath = '/api/Visits';
  static readonly CreatePath = '/api/Visits';
  static readonly NewPath = '/api/Visits/new';
  static readonly VisitPath = '/api/Visits/{id}';
  static readonly UpdatePath = '/api/Visits/{id}';
  static readonly AvailableServicesPath = '/api/Visits/{id}/services/available';
  static readonly AvailableServiceGroupsAsyncPath = '/api/Visits/{id}/service-groups/available';
  static readonly ServicesPath = '/api/Visits/{id}/services';
  static readonly AddServicesPath = '/api/Visits/{id}/services';
  static readonly DiscountsPath = '/api/Visits/{id}/discounts';
  static readonly SpendingsPath = '/api/Visits/{id}/spendings';
  static readonly AvailableResultsPath = '/api/Visits/availableResults';
  static readonly PrintInvoicePath = '/api/Visits/{id}/invoice';
  static readonly PrintContractPath = '/api/Visits/{id}/contract';
  static readonly CreateOrdersPath = '/api/Visits/{id}/orders';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `VisitsService.VisitsParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `Ids`:
   *
   * - `From`:
   *
   * @return Success
   */
  VisitsResponse(params: VisitsService.VisitsParams): __Observable<__StrictHttpResponse<VisitsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    (params.Ids || []).forEach(val => {if (val != null) __params = __params.append('Ids', val.toString())});
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitsResponse>;
      })
    );
  }
  /**
   * @param params The `VisitsService.VisitsParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `Ids`:
   *
   * - `From`:
   *
   * @return Success
   */
  Visits(params: VisitsService.VisitsParams): __Observable<VisitsResponse> {
    return this.VisitsResponse(params).pipe(
      __map(_r => _r.body as VisitsResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: AddVisitModel): __Observable<__StrictHttpResponse<CreateVisitResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateVisitResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: AddVisitModel): __Observable<CreateVisitResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as CreateVisitResponse)
    );
  }

  /**
   * @param ParentId undefined
   * @return Success
   */
  NewResponse(ParentId?: number): __Observable<__StrictHttpResponse<VisitEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (ParentId != null) __params = __params.set('ParentId', ParentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/new`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitEditorModel>;
      })
    );
  }
  /**
   * @param ParentId undefined
   * @return Success
   */
  New(ParentId?: number): __Observable<VisitEditorModel> {
    return this.NewResponse(ParentId).pipe(
      __map(_r => _r.body as VisitEditorModel)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  VisitResponse(id: number): __Observable<__StrictHttpResponse<VisitEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Visit(id: number): __Observable<VisitEditorModel> {
    return this.VisitResponse(id).pipe(
      __map(_r => _r.body as VisitEditorModel)
    );
  }

  /**
   * @param params The `VisitsService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: VisitsService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Visits/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `VisitsService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: VisitsService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AvailableServicesResponse(id: number): __Observable<__StrictHttpResponse<ServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}/services/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServicesResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AvailableServices(id: number): __Observable<ServicesResponse> {
    return this.AvailableServicesResponse(id).pipe(
      __map(_r => _r.body as ServicesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AvailableServiceGroupsAsyncResponse(id: number): __Observable<__StrictHttpResponse<Array<CompanyServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}/service-groups/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CompanyServiceGroup>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AvailableServiceGroupsAsync(id: number): __Observable<Array<CompanyServiceGroup>> {
    return this.AvailableServiceGroupsAsyncResponse(id).pipe(
      __map(_r => _r.body as Array<CompanyServiceGroup>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ServicesResponse(id: number): __Observable<__StrictHttpResponse<Array<SelectedService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SelectedService>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Services(id: number): __Observable<Array<SelectedService>> {
    return this.ServicesResponse(id).pipe(
      __map(_r => _r.body as Array<SelectedService>)
    );
  }

  /**
   * @param params The `VisitsService.AddServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddServicesResponse(params: VisitsService.AddServicesParams): __Observable<__StrictHttpResponse<AddedServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visits/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddedServicesResponse>;
      })
    );
  }
  /**
   * @param params The `VisitsService.AddServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddServices(params: VisitsService.AddServicesParams): __Observable<AddedServicesResponse> {
    return this.AddServicesResponse(params).pipe(
      __map(_r => _r.body as AddedServicesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  DiscountsResponse(id: number): __Observable<__StrictHttpResponse<Array<AvailableDiscount>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}/discounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AvailableDiscount>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Discounts(id: number): __Observable<Array<AvailableDiscount>> {
    return this.DiscountsResponse(id).pipe(
      __map(_r => _r.body as Array<AvailableDiscount>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  SpendingsResponse(id: number): __Observable<__StrictHttpResponse<VisitSpendings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/${id}/spendings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VisitSpendings>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Spendings(id: number): __Observable<VisitSpendings> {
    return this.SpendingsResponse(id).pipe(
      __map(_r => _r.body as VisitSpendings)
    );
  }

  /**
   * @return Success
   */
  AvailableResultsResponse(): __Observable<__StrictHttpResponse<Array<VisitResult>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Visits/availableResults`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<VisitResult>>;
      })
    );
  }
  /**
   * @return Success
   */
  AvailableResults(): __Observable<Array<VisitResult>> {
    return this.AvailableResultsResponse().pipe(
      __map(_r => _r.body as Array<VisitResult>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PrintInvoiceResponse(id: number): __Observable<__StrictHttpResponse<PrintingStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visits/${id}/invoice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintingStatusResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PrintInvoice(id: number): __Observable<PrintingStatusResponse> {
    return this.PrintInvoiceResponse(id).pipe(
      __map(_r => _r.body as PrintingStatusResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PrintContractResponse(id: number): __Observable<__StrictHttpResponse<PrintingStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visits/${id}/contract`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintingStatusResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PrintContract(id: number): __Observable<PrintingStatusResponse> {
    return this.PrintContractResponse(id).pipe(
      __map(_r => _r.body as PrintingStatusResponse)
    );
  }

  /**
   * @param id undefined
   */
  CreateOrdersResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Visits/${id}/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CreateOrders(id: number): __Observable<null> {
    return this.CreateOrdersResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module VisitsService {

  /**
   * Parameters for Visits
   */
  export interface VisitsParams {
    To?: string;
    Size?: number;
    Search?: string;
    Page?: number;
    Ids?: Array<number>;
    From?: string;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: EditVisitModel;
  }

  /**
   * Parameters for AddServices
   */
  export interface AddServicesParams {
    id: number;
    request?: AddServicesModel;
  }
}

export { VisitsService }
