import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Company, PriceSet, PriceSetEditorModel, ServiceCategoriesResponse, ServiceCategory } from '../../../generated/models';
import { CompaniesService, PriceSetsService, ServiceCategoriesService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class PriceSetResolver implements Resolve<PriceSetResolverPayload> {
  public constructor(
    private priceSetsService: PriceSetsService,
    private categoriesService: ServiceCategoriesService,
    private companiesService: CompaniesService
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PriceSetResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let priceSetLoader: Observable<PriceSet> = null;
    const categoriesLoader: Observable<ServiceCategory[]> = this.categoriesService.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
      );
    const companiesLoader: Observable<Company[]> = this.companiesService.MyCompanies();
    const dmsInsuranceCompaniesLoader: Observable<Company[]> = this.companiesService.Companies({ Type: 5 });

    if (id) {
      priceSetLoader = this.priceSetsService.PriceSet(id);
    } else {
      priceSetLoader = from([{}]);
    }

    return forkJoin({
      priceSet: priceSetLoader,
      serviceCategories: categoriesLoader,
      companies: companiesLoader,
      dmsInsuranceCompanies: dmsInsuranceCompaniesLoader
    });
  }
}

export class PriceSetResolverPayload {
  public priceSet: PriceSetEditorModel;
  public serviceCategories: ServiceCategory[];
  public companies: Company[];
  public dmsInsuranceCompanies: Company[];
}
