import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'mp-diagnosis-alias-modal',
  templateUrl: './diagnosis-alias-modal.component.html',
  styleUrls: ['./diagnosis-alias-modal.component.scss']
})
export class DiagnosisAliasModalComponent implements OnInit {

  @Input() name = '';
  @Input() processing = false;

  @Output() onConfirm = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<void>();

  form = new FormGroup({
    text: new FormControl('', [Validators.required]),
    name: new FormControl('')
  })

  get canConfirm() { return this.form.valid && !this.processing; }

  constructor() {
    this.form.get('name').disable();
  }

  ngOnInit() {
    this.form.patchValue({ name: this.name });
  }

  confirm = () => this.onConfirm.emit(this.form.getRawValue().text);
  cancel = () => this.onCancel.emit();
}
