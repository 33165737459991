import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RefundReason } from '../../../../generated/models';

@Component({
  selector: 'mp-refund-modal',
  templateUrl: './refund-modal.component.html',
  styleUrls: ['./refund-modal.component.scss']
})
export class RefundModalComponent implements OnInit {
  @Input() reasons: RefundReason[] = [];
  @Input() processing = false;

  @Output()
  onCancel = new EventEmitter<void>();
  @Output()
  onConfirm = new EventEmitter<RefundReason>();

  reason: RefundReason;
  submitted = false;

  get hasError(): boolean { return this.submitted && !this.reason; }

  constructor() { }

  ngOnInit() { }

  select = (reason: RefundReason) => this.reason = reason;

  selected = (reason: RefundReason) => this.reason && this.reason.id === reason.id;

  confirm() {
    this.submitted = true;
    if (!this.reason) return;
    this.onConfirm.emit(this.reason);
  }

  cancel = () => this.onCancel.emit();
}
