import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { PbxOperatorsService } from '../../../../generated/services/pbx-operators.service';
import { PbxOperatorViewModel } from '../../../../generated/models/pbx-operator-view-model';
import { PbxOperatorsResponse } from '../../../../generated/models/pbx-operators-response';
import { NgbModalRef, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ManagePhoneNumberModalComponent } from '../manage-phone-number.modal/manage-phone-number.modal.component';
import { FormGroup, FormControl } from '@angular/forms';
import { takeUntil, tap, switchMap } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { PhoneNumbersResolverPayload } from '../../resolver/phone-numbers.resolver';
import { Pbx } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-phone-numbers',
  templateUrl: './phone-numbers.component.html',
  styleUrls: ['./phone-numbers.component.scss'],
  host: { class: "page" }
})
export class PhoneNumbersComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  pbxOperators: PbxOperatorViewModel[] = [];
  pbxList: Pbx[] = [];

  loading = false;
  page = 1;
  size = 50;

  scrollDistance = 1;

  filters = new FormGroup({
    search: new FormControl("")
  });

  constructor(
    private pbxService: PbxOperatorsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private toastrService: ToastrService
  ) {
    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: { search: string }): void => {
          const params: Params = {};
          if (value.search) {
            params.search = value.search
          }

          this.router.navigate([], { queryParams: params, relativeTo: this.activatedRoute });
        }),
        tap((): void => {
          this.loading = true;
        }),
        switchMap((value: { search: string }): Observable<PbxOperatorsResponse> => {
          this.page = 1;
          return this.pbxService.PbxOperators({ Search: value.search, Page: this.page, Size: this.size })
        })
      )
      .subscribe(
        (response: PbxOperatorsResponse): void => {
          this.pbxOperators = response.items;
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.filters.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search")
    });

    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: { payload: PhoneNumbersResolverPayload }) => {
          this.pbxList = data.payload.pbxList;
        }
      )
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  create() {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  edit(operator: PbxOperatorViewModel): void {
    this.router.navigate([operator.id], { relativeTo: this.activatedRoute });
  }

  onScrollDown = (): void => {
    this.page++;

    this.pbxService.PbxOperators({ Page: this.page, Size: this.size, Search: this.filters.getRawValue().search })
      .subscribe((result: PbxOperatorsResponse): void => {
        this.pbxOperators = this.pbxOperators.concat(result.items);
      });
  }

  openManager(operator: PbxOperatorViewModel): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg", centered: true };
    const modalRef: NgbModalRef = this.modalService.open(ManagePhoneNumberModalComponent, options);
    const componentRef: ManagePhoneNumberModalComponent = modalRef.componentInstance;

    componentRef.phone = operator.directPhone;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe(
      async (): Promise<void> => {
        try {
          await this.pbxService.DeletePbxOperator(operator.id).toPromise();
        } catch (e) {
          this.toastrService.error(`Не удалось удалить номер ${operator.directPhone}`, 'Ошибка');
          return;
        }

        modalRef.close();

        this.page = 1;
        this.pbxOperators = []
        this.loading = true;

        try {
          const response = await this.pbxService.PbxOperators({ Search: this.filters.getRawValue().search, Page: this.page, Size: this.size }).toPromise();
          this.pbxOperators = response.items;
        } catch (e) {
          this.toastrService.error(`Не удалось загрузить список номеров`, 'Ошибка');
        }

        this.loading = true;
      });

  }

}
