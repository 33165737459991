import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mp-remove-service-modal',
  templateUrl: './remove-service.modal.component.html',
  styleUrls: ['./remove-service.modal.component.scss']
})
export class RemoveServiceModalComponent implements OnInit {
  title = "Удалить услугу";

  @Input() fullName: string;
  @Input() canRemove = false;
  @Input() archived = false;


  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();
  @Output() onArchive: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {

    this.title = `Удаление услуги "${this.fullName}"`;
  }

  remove(): void {
    this.onRemove.emit();
  }

  archive(): void {
    this.onArchive.emit();
  }

  public cancel(): void {
    this.onClose.emit();
  }

}
