import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { GetOrdersResponseItem } from '../../../../generated/models';

import * as moment from 'moment';

import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-observations-list-item',
  templateUrl: './observations-list-item.component.html',
  styleUrls: ['./observations-list-item.component.scss']
})
export class ObservationsListItemComponent implements OnDestroy {
  destroy$ = new Subject<void>();

  @Input() item: GetOrdersResponseItem;

  @Input() canPrintBarcode: boolean;
  @Input() canManageAttachments: boolean;

  @Output() onAttachmentRequested = new EventEmitter<number>();
  @Output() onBarcodePrintingRequested = new EventEmitter<void>();
  @Output() onInvoicePrintingRequested = new EventEmitter<void>();
  @Output() onManagerOpened = new EventEmitter<void>();
  @Output() onCommentEdited = new EventEmitter<void>();
  @Output() onObservationRemoved = new EventEmitter<void>();
  @Output() onAttachmentAdded = new EventEmitter<File>();
  @Output() onResultsSyncDisabled = new EventEmitter<void>();

  filesControl = new FormControl();

  @ViewChild("control") fileControlElem: ElementRef<HTMLInputElement>;

  constructor() {

    this.filesControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        const files: FileList = this.fileControlElem.nativeElement.files;

        for (let i = 0; i < files.length; i++) {
          this.onAttachmentAdded.emit(files[i]);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clinicAbbr(order: GetOrdersResponseItem): string {
    if (order.clinicAbbr) return order.clinicAbbr;

    if (!order.clinicName) return "";

    return order.clinicName.split(" ").filter(x => !!x).map(x => x[0].toUpperCase()).join("");
  }

  getDate = (date: number) => date != 0 ? moment.unix(date).format("DD.MM.YYYY") : "";
  getAttachment = (attachment: { id: number }) => this.onAttachmentRequested.emit(attachment.id);
  printBarcode = () => this.onBarcodePrintingRequested.emit();
  printInvoice = () => this.onInvoicePrintingRequested.emit();
  openManager = () => this.onManagerOpened.emit();
  removeOrder = () => this.onObservationRemoved.emit();
  editComment = () => this.onCommentEdited.emit();
  disableResultsSync = () => this.onResultsSyncDisabled.emit();
  
}
