import { Injectable } from '@angular/core';
import { Pbx } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PbxOperatorsService } from '../../../generated/services';
import { Observable, forkJoin } from 'rxjs';

@Injectable({ providedIn: "root" })
export class PhoneNumbersResolver implements Resolve<PhoneNumbersResolverPayload> {
  public constructor(private service: PbxOperatorsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PhoneNumbersResolverPayload> {
    const pbxList: Observable<Array<Pbx>> = this.service.PbxList()

    return forkJoin({
      pbxList: pbxList
    })
  }
}

export interface PhoneNumbersResolverPayload {
  pbxList: Array<Pbx>;
}
