import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplatesComponent } from './components/templates/templates.component';
import { Routes, RouterModule } from '@angular/router';

import { LayoutModule } from "@skytecs/layout"
import { ControlsModule } from "@skytecs/controls";
import { ReactiveFormsModule } from '@angular/forms';
import { TemplateListItemComponent } from './components/template-list-item/template-list-item.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TemplateComponent } from './components/template/template.component';
import { TemplateResolver } from './resolvers/template-resolver';
import { NgbDropdownModule, NgbPopoverModule, NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { TemplatesResolver } from './resolvers/templates-resolver';
import { TemplateSectionComponent } from './components/template-section/template-section.component';
import { TemplateStructureModalComponent } from './components/template-structure-modal/template-structure-modal.component';
import { TemplateStructureItemComponent } from './components/template-structure-item/template-structure-item.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppointmentDateSectionComponent } from './components/appointment-date-section/appointment-date-section.component';
import { DiagnosisSectionComponent } from './components/diagnosis-section/diagnosis-section.component';
import { TextSectionComponent } from './components/text-section/text-section.component';
import { ReferralsSectionComponent } from './components/referrals-section/referrals-section.component';
import { PlaceholdersComponent } from './components/placeholders/placeholders.component';
import { ToastrModule } from 'ngx-toastr';
import { PermissionNames } from '../../models/permission-names';
import { EditorModule } from '@tinymce/tinymce-angular';
import { OutcomeSectionComponent } from './components/outcome-section/outcome-section.component';
import { MetricsModalComponent } from './components/metrics-modal/metrics-modal.component';
import { MetricsModalItemComponent } from './components/metrics-modal-item/metrics-modal-item.component';
import { TemplateSectionMetricComponent } from './components/template-section-metric/template-section-metric.component';
import { MetricSectionComponent } from './components/metric-section/metric-section.component';

const routes: Routes = [
  {
    path: "new",
    component: TemplateComponent,
    data: {
      title: "Новый шаблон",
      requirements: [[PermissionNames.ManageTemplates, PermissionNames.OwnPersonalTemplates]]
    },
    resolve: {
      payload: TemplateResolver
    }
  },
  {
    path: ":id",
    component: TemplateComponent,
    data: {
      title: "Шаблон",
      requirements: [[PermissionNames.ManageTemplates, PermissionNames.OwnPersonalTemplates]]
    },
    resolve: {
      payload: TemplateResolver
    }
  },
  {
    path: "",
    component: TemplatesComponent,
    data: {
      title: "Список шаблонов",
      requirements: [[PermissionNames.EditTemplates, PermissionNames.ManageTemplates, PermissionNames.OwnPersonalTemplates]]
    },
    resolve: {
      payload: TemplatesResolver
    }
  }
];


@NgModule({
  declarations: [
    TemplatesComponent,
    TemplateListItemComponent,
    TemplateComponent,
    TemplateSectionComponent,
    TemplateStructureModalComponent,
    TemplateStructureItemComponent,
    AppointmentDateSectionComponent,
    DiagnosisSectionComponent,
    TextSectionComponent,
    ReferralsSectionComponent,
    PlaceholdersComponent,
    OutcomeSectionComponent,
    MetricsModalComponent,
    MetricsModalItemComponent,
    TemplateSectionMetricComponent,
    MetricSectionComponent
  ],
  entryComponents: [
    TemplateStructureModalComponent,
    MetricsModalComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,

    RouterModule.forChild(routes),
    InfiniteScrollModule,
    DragDropModule,
    ToastrModule,
    EditorModule,

    NgbDropdownModule,
    NgbPopoverModule,
    NgbModalModule,
    NgbTypeaheadModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ]
})
export class TemplateSettingsModule { }
