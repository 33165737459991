import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';


@Component({
  selector: 'mp-change-watcher',
  templateUrl: './change-watcher.component.html',
  styleUrls: ['./change-watcher.component.scss']
})
export class ChangeWatcherComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() text = 'Есть несохраненные изменения';

  @Output() submitted = new EventEmitter();
  @Output() onSubmitAttempted = new EventEmitter<void>();

  saved: any = {};

  constructor() { }

  ngOnInit() {
    this.saved = this.form.getRawValue();

    this.form.valueChanges
      .subscribe(() => {
        if (this.form.pristine && this.form.untouched) {
          this.saved = this.form.getRawValue();
        }
      });
  }

  get show(): boolean {
    return this.form && this.form.dirty && JSON.stringify(this.saved) !== JSON.stringify(this.form.getRawValue());
  }

  @HostBinding('class.visible') get visible(): boolean { return this.show; }

  clear() {
    this.form.patchValue(this.saved);

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  submit() {
    this.onSubmitAttempted.emit();

    Object.entries(this.form.controls)
      .map((item: [string, AbstractControl]): AbstractControl => item[1])
      .forEach((control: AbstractControl): void => { control.markAsDirty(); })

    if (this.form.invalid) return;

    this.saved = this.form.getRawValue();

    this.submitted.emit(this.saved);

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }
}
