import { NgModule } from '@angular/core';
import { DatesingleComponent } from './components/datesingle/datesingle.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ChangeWatcherComponent } from './components/change-watcher/change-watcher.component';
import { CommonModule } from '@angular/common';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { DigitsOnlyDirective } from './directives/digits-only.directive';
import { DaterangeComponent } from './components/daterange/daterange.component';
import { SearchPanelComponent } from './components/search-panel/search-panel.component';
import { MomentAdapter } from './adapters/moment.adapter';
import { MomentFormatter } from './formatters/moment.formatter';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    DatepickerComponent,
    DatesingleComponent,
    ChangeWatcherComponent,
    PhoneInputComponent,
    DigitsOnlyDirective,
    DaterangeComponent,
    SearchPanelComponent,
    CheckboxComponent,
    SpinnerComponent,
    CheckboxGroupComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule.forRoot(),
    NgxMaskModule.forRoot({}),
    CommonModule
  ],
  exports: [
    DatepickerComponent,
    DatesingleComponent,
    ChangeWatcherComponent,
    PhoneInputComponent,
    DaterangeComponent,
    SearchPanelComponent,
    CheckboxComponent,
    SpinnerComponent,
    CheckboxGroupComponent
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: MomentAdapter },
    { provide: NgbDateParserFormatter, useClass: MomentFormatter },
  ]
})
export class ControlsModule { }
