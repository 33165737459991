import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReceiptParametersModalComponent } from './components/receipt-parameters-modal/receipt-parameters-modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ControlsModule } from '../../../../../controls/src/public-api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceiptParametersService } from './services/receipt-parameters.service';

@NgModule({
  declarations: [
    ReceiptParametersModalComponent
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    ControlsModule,
    FormsModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    ReceiptParametersModalComponent
  ],
  exports: [
    ReceiptParametersModalComponent
  ],
  providers: [
    ReceiptParametersService
  ]
})
export class CorrectionReceiptModule { }
