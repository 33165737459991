import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, from, Observable } from 'rxjs';
import { WageSchema } from '../../../generated/models';
import { WageSchemasService } from '../../../generated/services';

@Injectable({ providedIn: "root" })
export class WageSchemaResolver implements Resolve<WageSchemaPayload> {

  constructor(
    private wageSchemasService: WageSchemasService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WageSchemaPayload> {
    const id: number = parseInt(route.paramMap.get("id"), 10);

    let wageSchemaLoader: Observable<WageSchema> = null;

    if (id) {
      wageSchemaLoader = this.wageSchemasService.WageSchema(id);
    } else {
      wageSchemaLoader = from([{}]);
    }

    return forkJoin({
      wageSchema: wageSchemaLoader
    })
  }
}

export class WageSchemaPayload {
  wageSchema: WageSchema
}
