import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EditPartnerPlanModel, PartnerPlanService } from 'projects/Clinic/src/app/generated/models';
import { PartnerPlansService } from 'projects/Clinic/src/app/generated/services';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-plan-service-modal',
  templateUrl: './plan-service-modal.component.html',
  styleUrls: ['./plan-service-modal.component.scss']
})
export class PlanServiceModalComponent implements OnInit, OnDestroy {
  private _destroy: Subject<void> = new Subject<void>();
  public title: string;

  @Input() plan: EditPartnerPlanModel;
  @Input() service: PartnerPlanService;

  @Output()
  onSubmit: EventEmitter<FormValue> = new EventEmitter<FormValue>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  public get employeeBonusInvalid(): boolean {
    return this.form.get("employeesBonus").invalid && this.form.get("employeesBonus").dirty;
  }

  public get employeeBonusError(): string {
    const control: AbstractControl = this.form.get("employeesBonus");

    if (control.valid) {
      return "";
    }

    if (control.errors["min"]) {
      return `Значение не может быть меньше ${control.errors["min"].min}`;
    }

    if (control.errors["max"]) {
      return `Значение не может быть больше ${control.errors["max"].max}`;
    }
    return "";
  }

  public get partnerBonusInvalid(): boolean {
    return this.form.get("partnersBonus").invalid && this.form.get("partnersBonus").dirty;
  }

  public get partnerBonusError(): string {
    const control: AbstractControl = this.form.get("partnersBonus");

    if (control.valid) {
      return "";
    }

    if (control.errors["required"]) {
      return "Необходимо указать значение";
    }

    if (control.errors["min"]) {
      return `Значение не может быть меньше ${control.errors["min"].min}`;
    }

    if (control.errors["max"]) {
      return `Значение не может быть больше ${control.errors["max"].max}`;
    }
    return "";
  }

  public get complimentaryEmployeeBonus(): string {
    const bonus: number = this.form.get("employeesBonus").value;

    if (!bonus) {
      return "";
    }

    if (this.plan.type === 1) {
      return (bonus / this.service.defaultPrice * 100).toString();
    }

    if (this.plan.type === 2) {
      return (this.service.defaultPrice * bonus / 100).toFixed(2);
    }

    return "";
  }

  public get complimentaryPartnerBonus(): string {
    const bonus: number = this.form.get("partnersBonus").value;

    if (!bonus) {
      return "";
    }

    if (this.plan.type === 1) {
      return (bonus / this.service.defaultPrice * 100).toString();
    }

    if (this.plan.type === 2) {
      return (this.service.defaultPrice * bonus / 100).toFixed(2);
    }

    return "";
  }

  public get showComplimentaryPartnerBonus(): boolean { return this.form.get("partnersBonus").valid && this.form.get("partnersBonus").value > 0; }
  public get showComplimentaryEmployeeBonus(): boolean { return this.form.get("employeesBonus").valid && this.form.get("employeesBonus").value > 0; }

  public form: FormGroup;

  constructor() {
    this.title = `Настройка партнерских отчислений`;

    this.form = new FormGroup({
      partnersBonus: new FormControl("", []),
      employeesBonus: new FormControl("", [])
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: FormValue): void => {

          if (value.partnersBonus === null || typeof (value.partnersBonus) === "undefined") {
            if (value.employeesBonus !== null && typeof (value.employeesBonus) !== "undefined") {
              this.form.get("partnersBonus").setErrors({ required: true });
            } else {
              this.form.get("partnersBonus").setErrors(null);
            }
          }
        }
      );
  }

  ngOnInit() {

    if (this.plan.type === 1) {
      this.form.get("partnersBonus").setValidators([Validators.min(0), Validators.max(this.service.defaultPrice)]);
      this.form.get("employeesBonus").setValidators([Validators.min(0), Validators.max(this.service.defaultPrice)]);

      this.form.patchValue({
        partnersBonus: this.service.partnersTaxRub,
        employeesBonus: this.service.employeesTaxRub
      });
    } else if (this.plan.type === 2) {
      this.form.get("partnersBonus").setValidators([Validators.min(0), Validators.max(100)]);
      this.form.get("employeesBonus").setValidators([Validators.min(0), Validators.max(100)]);

      this.form.patchValue({
        partnersBonus: this.service.partnersTaxPercent,
        employeesBonus: this.service.employeeTaxPercent
      });
    }
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public cancel(): void {
    this.onCancel.emit();
  }
  public submit(): void {
    this.form.get("partnersBonus").markAsDirty();
    this.form.get("employeesBonus").markAsDirty();

    this.form.updateValueAndValidity();

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onSubmit.emit(value);
  }
}

interface FormValue {
  employeesBonus: number;
  partnersBonus: number;
}
