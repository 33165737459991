import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Locus, Performer, SelectedLocusContainer, SelectedService, ServiceDetails } from '../../../../generated/models';

@Component({
  selector: 'mp-service-details-modal',
  templateUrl: './service-details-modal.component.html',
  styleUrls: ['./service-details-modal.component.scss']
})
export class ServiceDetailsModalComponent implements OnInit {

  @Input() services: SelectedService[] = [];
  @Input() details: ServiceDetails[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<SelectedServiceDetails[]>();

  items: SelectedServiceDetails[] = [];

  get total(): number { return this.items.length; }
  get performerRequired(): number { return this.items.filter(x => x.performerRequired).length; }
  get performerSelected(): number { return this.items.filter(x => x.performerRequired && !!x.performerId).length; }

  get containersRequired(): number { return this.items.filter(x => x.containersRequired).length; }
  get containersSelected(): number { return this.items.filter(x => x.containersRequired && x.selectedLocusContainers.length > 0).length; }


  get disabled(): boolean { return this.items.some(x => !this.isValid(x)); }

  constructor() { }

  ngOnInit() {

    for (const service of this.services) {
      const item: SelectedServiceDetails = {
        id: service.id,
        serviceId: service.serviceId,
        serviceName: service.name,
        specialityId: service.specialityId,
        specialityName: service.specialityName,
        performerId: service.performer ? service.performer.id : undefined,
        performerRequired: service.specialityId > 0,
        performers: [],
        kdlCode: service.kdlCode,
        containersRequired: !!service.kdlCode && !service.isExpress,
        locuses: [],
        selectedLocusContainers: [...service.containers]
      };

      const details = this.details.find(x => x.serviceId === service.serviceId);

      if (details) {
        item.performers = [...details.performers];
        item.locuses = [...details.locuses]
      }

      this.items.push(item);
    }
  }

  confirm = () => this.onConfirm.emit(this.items);
  cancel = () => this.onCancel.emit();

  selectPerformer(item: SelectedServiceDetails, performer: Performer) {
    item.performerId = performer.id;

    if (this.items.length === 1 && !item.containersRequired) {
      this.onConfirm.emit(this.items);
    }
  }

  toggleContainer(item: SelectedServiceDetails, payload: { locusId: number, containerId: number }) {
    const index = item.selectedLocusContainers
      .findIndex(x => x.locusId === payload.locusId && x.containerId === payload.containerId);

    const locus = item.locuses.find(x => x.id === payload.locusId);

    if (!locus) return;

    if (index === -1) {

      const requiredContainers = locus.containers.filter(x => x.required || x.id === payload.containerId);

      for (const requiredContainer of requiredContainers) {
        if (item.selectedLocusContainers.some(x => x.locusId === payload.locusId && x.containerId === requiredContainer.id))
          continue;

        item.selectedLocusContainers.push({
          containerId: requiredContainer.id,
          containerName: requiredContainer.name,
          selectedServiceId: item.serviceId,
          locusId: locus.id,
          locusName: locus.name
        });
      }

      return;
    }

    const container = locus.containers.find(x => x.id === payload.containerId);

    if (!container) return;

    if (locus.required && container.required) {
      return;
    }

    item.selectedLocusContainers.splice(index, 1);
  }

  isValid(item: SelectedServiceDetails) {
    if (item.performerRequired && !item.performerId) return false;
    if (item.containersRequired && item.selectedLocusContainers.length === 0) return false;

    for (const locus of item.locuses) {
      const selected = item.selectedLocusContainers.filter(x => x.locusId === locus.id);

      if (locus.required && selected.length === 0) {
        return false;
      }

      for (const container of locus.containers) {
        if (container.required && selected.length > 0 && !selected.some(x => x.containerId === container.id)) {
          return false;
        }
      }
    }

    return true;
  }
}

export interface SelectedServiceDetails {
  id: number;
  serviceId: number;
  serviceName: string;

  specialityId?: number;
  specialityName?: string;

  performerRequired: boolean;
  performerId?: number;

  performers: Performer[];

  kdlCode: string;
  containersRequired: boolean;

  locuses: Locus[];
  selectedLocusContainers: SelectedLocusContainer[];
}
