import { RoleEditorModel } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
import { RolesService } from '../../../generated/services';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: "root" })
export class RoleResolver implements Resolve<RolePayload> {

  constructor(
    private rolesService: RolesService
  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RolePayload> {
    const id: number = route.paramMap.has("id") ? parseInt(route.paramMap.get("id")) : undefined;

    let roleLoader: Observable<RoleEditorModel> = null;
    if (id > 0) {
      roleLoader = this.rolesService.Role(id);
    } else {
      roleLoader = of({});
    }

    return forkJoin({ role: roleLoader });
  }
}

export interface RolePayload {
  role: RoleEditorModel;
}
