/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CodeSystem } from '../models/code-system';
import { CodeSystemModel } from '../models/code-system-model';
@Injectable({
  providedIn: 'root',
})
class CodeSystemsService extends __BaseService {
  static readonly CodeSystemsPath = '/api/CodeSystems';
  static readonly CreatePath = '/api/CodeSystems';
  static readonly CodeSystemPath = '/api/CodeSystems/{id}';
  static readonly EditPath = '/api/CodeSystems/{id}';
  static readonly DeletePath = '/api/CodeSystems/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `CodeSystemsService.CodeSystemsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  CodeSystemsResponse(params: CodeSystemsService.CodeSystemsParams): __Observable<__StrictHttpResponse<Array<CodeSystem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CodeSystems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CodeSystem>>;
      })
    );
  }
  /**
   * @param params The `CodeSystemsService.CodeSystemsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  CodeSystems(params: CodeSystemsService.CodeSystemsParams): __Observable<Array<CodeSystem>> {
    return this.CodeSystemsResponse(params).pipe(
      __map(_r => _r.body as Array<CodeSystem>)
    );
  }

  /**
   * @param request undefined
   */
  CreateResponse(request?: CodeSystemModel): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CodeSystems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  Create(request?: CodeSystemModel): __Observable<null> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  CodeSystemResponse(id: number): __Observable<__StrictHttpResponse<CodeSystem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/CodeSystems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CodeSystem>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  CodeSystem(id: number): __Observable<CodeSystem> {
    return this.CodeSystemResponse(id).pipe(
      __map(_r => _r.body as CodeSystem)
    );
  }

  /**
   * @param params The `CodeSystemsService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditResponse(params: CodeSystemsService.EditParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CodeSystems/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CodeSystemsService.EditParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Edit(params: CodeSystemsService.EditParams): __Observable<null> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CodeSystems/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Delete(id: number): __Observable<null> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CodeSystemsService {

  /**
   * Parameters for CodeSystems
   */
  export interface CodeSystemsParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    id: number;
    request?: CodeSystemModel;
  }
}

export { CodeSystemsService }
