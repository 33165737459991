/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateBalancePaymentRequest } from '../models/create-balance-payment-request';
@Injectable({
  providedIn: 'root',
})
class PaymentsService extends __BaseService {
  static readonly CreatePaymentAsyncPath = '/api/v2/visits/{id}/payments';
  static readonly CreatePaymentAsync_1Path = '/api/visits/{id}/receipt';
  static readonly CreateBalancePaymentAsyncPath = '/api/v2/people/{id}/balance';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param id undefined
   */
  CreatePaymentAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/visits/${id}/payments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CreatePaymentAsync(id: number): __Observable<null> {
    return this.CreatePaymentAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  CreatePaymentAsync_1Response(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/visits/${id}/receipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  CreatePaymentAsync_1(id: number): __Observable<null> {
    return this.CreatePaymentAsync_1Response(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `PaymentsService.CreateBalancePaymentAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateBalancePaymentAsyncResponse(params: PaymentsService.CreateBalancePaymentAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/people/${params.id}/balance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PaymentsService.CreateBalancePaymentAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  CreateBalancePaymentAsync(params: PaymentsService.CreateBalancePaymentAsyncParams): __Observable<null> {
    return this.CreateBalancePaymentAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PaymentsService {

  /**
   * Parameters for CreateBalancePaymentAsync
   */
  export interface CreateBalancePaymentAsyncParams {
    id: number;
    request?: CreateBalancePaymentRequest;
  }
}

export { PaymentsService }
