import { Injectable } from '@angular/core';
import { Speciality, ServiceCategory, ServiceCategoriesResponse, Company, ServiceAgreement, SpecialitiesResponse, CodeSystem, Manipulation, ServiceGroup, } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { SpecialitiesService, ServiceCategoriesService, ServicesService, CompaniesService, CodeSystemsService, ManipulationsService, ServiceGroupsService } from '../../../generated/services';
import { map } from 'rxjs/operators';
import { ServiceEditorModel } from '../../../generated/models/service-editor-model';

@Injectable({
  providedIn: "root"
})
export class ServiceItemResolver implements Resolve<ServiceItemPayload>{

  constructor(
    private specialitiesService: SpecialitiesService,
    private categoriesService: ServiceCategoriesService,
    private servicesService: ServicesService,
    private companiesServcie: CompaniesService,
    private codeSystemsService: CodeSystemsService,
    private groupsService: ServiceGroupsService
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceItemPayload> {
    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(
        map((response: SpecialitiesResponse): Speciality[] => response.items)
      );

    const categoriesLoader: Observable<ServiceCategory[]> = this.categoriesService.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((response: ServiceCategoriesResponse): ServiceCategory[] => response.categories)
      );

    const id: number = parseInt(route.paramMap.get("id"), 10);

    let serviceLoader: Observable<ServiceEditorModel> = undefined;
    if (id) {
      serviceLoader = this.servicesService.Service(id);
    } else {
      const newService: any = {};

      serviceLoader = from([newService]);
    }

    const companiesLoader: Observable<Company[]> = this.companiesServcie.Companies({});
    const agreementsLoader: Observable<ServiceAgreement[]> = this.servicesService.Agreeents();
    const codeSystemsLoader: Observable<CodeSystem[]> = this.codeSystemsService.CodeSystems({ Page: 1, Size: 0 });


    return forkJoin({
      specialities: specialitiesLoader,
      categories: categoriesLoader,
      service: serviceLoader,
      companies: companiesLoader,
      agreements: agreementsLoader,
      codeSystems: codeSystemsLoader,
      groups: this.groupsService.ServiceGroupsAsync({})
    });
  }

}

export interface ServiceItemPayload {
  specialities: Speciality[];
  categories: ServiceCategory[];
  companies: Company[];
  service: ServiceEditorModel;
  agreements: ServiceAgreement[];
  codeSystems: CodeSystem[];
  groups: ServiceGroup[];
}
