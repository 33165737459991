import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManageOrganizationModel, OrganizationsResponse } from '../../../../generated/models';
import { UserStorage } from '../../../../services/user-storage';
import { ManageOrganizationModalComponent } from '../manage-organization.modal/manage-organization.modal.component';
import { OrganizationsService } from '../../../../generated/services';
import { Organization } from '../../../../generated/models/organization';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-organization-list',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  host: { class: "page" }
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  page = 1;
  size = 50;
  loading = false;
  scrollDistance = 1;

  organizations: Organization[] = [];

  filters = new FormGroup({
    search: new FormControl(""),
    activeOnly: new FormControl(true),
    type: new FormControl(0)
  });

  constructor(
    private organizationsService: OrganizationsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal,
    private userStorage: UserStorage,
    private toastrService: ToastrService
  ) {

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((): void => {
          this.page = 1;
          this.organizations = [];
          this.loading = true;
        }),
        tap(value => this.updateRoute(value)),
        switchMap((value): Observable<OrganizationsResponse> => this.organizationsService.Organizations({
          Page: this.page,
          Size: this.size,
          Search: value.search,
          Type: value.type > 0 ? value.type : undefined,
          ActiveOnly: value.activeOnly
        }).pipe(catchError((e) => {
          console.error(e);
          this.toastrService.error('Не удалось загрузить организации', 'Ошибка');
          return from([{ organizations: [] }]);
        }))
        )
      )
      .subscribe((response: OrganizationsResponse): void => {
        this.organizations = response.organizations;
        this.loading = false;
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const value: FiltersValue = {
      search: queryMap.get("search"),
      type: (queryMap.has("type") ? parseInt(queryMap.get("type")) : 0) as 0 | 1 | 2 | 3 | 4 | 5 | 6,
      activeOnly: !queryMap.has("activeOnly") || queryMap.get("activeOnly") === "true"
    };

    this.filters.patchValue(value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  updateRoute(value: FiltersValue) {
    const queryParams: Params = {};

    if (value.search) {
      queryParams.search = value.search;
    }

    if (!value.activeOnly) {
      queryParams.activeOnly = false;
    }

    if (value.type > 0) {
      queryParams.type = value.type;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

  }

  private loadPage(): void {
    this.loading = true;

    const value: FiltersValue = this.filters.getRawValue();

    this.organizationsService.Organizations({
      Page: this.page,
      Size: this.size,
      Search: value.search,
      Type: value.type > 0 ? value.type : undefined,
      ActiveOnly: value.activeOnly
    })
      .subscribe(
        (response: OrganizationsResponse): void => {
          this.organizations.push(...response.organizations);
          this.loading = false;
        },
        (response: HttpErrorResponse) => {
          console.error(response);
          this.toastrService.error('Не удалось загрузить организации', 'Ошибка');
          this.loading = false;
        }
      );
  }

  public onScrollDown(): void {
    this.page++;

    this.loadPage();
  }

  public openManager(organization: Organization): void {

    this.organizationsService.Manage(organization.id)
      .subscribe(
        (managedOrganization: ManageOrganizationModel): void => {
          const modalOptions: NgbModalOptions = { backdrop: "static", size: "lg" };
          const modalRef: NgbModalRef = this.modal.open(ManageOrganizationModalComponent, modalOptions);

          const componentInstance: ManageOrganizationModalComponent = modalRef.componentInstance;

          componentInstance.name = managedOrganization.name;

          componentInstance.onCancel.subscribe(() => {
            modalRef.close();
          });

          componentInstance.onConfirm.subscribe(() => {
            this.organizationsService.Remove(managedOrganization.id).subscribe(
              () => {
                this.page = 1;
                this.organizations = [];

                this.loadPage();
              },
              (response: HttpErrorResponse) => {
                if (response.status === 400) {
                  this.toastrService.warning("Не удалось удалить организацию", "Ошибка");
                  return;
                }

                this.toastrService.error("Не удалось удалить организацию", "Ошибка");
              }
            );

          });

        });
  }

  public edit = (organization: Organization): void => {
    this.router.navigate([organization.id], { relativeTo: this.activatedRoute });
  }

  public create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  public getType(organization: Organization): string {
    if (organization.type === 1) return "Лечебное учреждение (направляет пациентов)";
    if (organization.type === 2) return "Лаборатория (выполняет исследования)";
    if (organization.type === 3) return "Страховая компания ОМС";
    if (organization.type === 4) return "Собственная организация";
    if (organization.type === 5) return "Страховая компания ДМС";
    if (organization.type === 6) return "Организация-заказчик";
    return "";
  }
}

interface FiltersValue {
  search: string;
  activeOnly: boolean;
  type: 0 | 1 | 2 | 3 | 4 | 5 | 6;
}
