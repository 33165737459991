import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CodeSystemsService } from '../../../../generated/services';
import { CodeSystemResolverPayload } from '../../resolvers/code-system-resolver';

@Component({
  selector: 'mp-code-system',
  templateUrl: './code-system.component.html',
  styleUrls: ['./code-system.component.scss']
})
export class CodeSystemComponent implements OnInit {

  public id: number;
  public title: string;

  public form: FormGroup;

  constructor(
    private codeSystemsService: CodeSystemsService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      identifier: new FormControl("", [Validators.required])
    })
  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: CodeSystemResolverPayload }): void => {
      if (data.payload.codeSystem.id > 0) {
        const codeSystem = data.payload.codeSystem;
        this.id = codeSystem.id;

        this.form.patchValue({
          name: codeSystem.name,
          identifier: codeSystem.identifier
        });
      }

      this.title = data.payload.codeSystem.id > 0 ? `Кодовая система: ${data.payload.codeSystem.name}` : "Новая кодовая система";
    });
  }

  public acceptChanges(): void {
    Object.entries(this.form.controls)
      .map((x: [string, AbstractControl]): AbstractControl => x[1])
      .forEach((x: AbstractControl): void => (x.markAsDirty()));

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      this.codeSystemsService.Edit({
        id: this.id,
        request: {
          name: value.name,
          identifier: value.identifier
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("", "Кодовая система сохранена");
            this.back();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось изменить кодовую систему", "Ошибка");
          }
        );
    } else {
      this.codeSystemsService.Create({
        name: value.name,
        identifier: value.identifier
      })
        .subscribe(
          (): void => {
            this.back();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось добавить кодовую систему", "Ошибка");
          }
        );
    }
  }

  public back(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }
}

interface FormValue {
  name: string;
  identifier: string;
}
