import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ColorMark } from '../../../../generated/models';
import { ColorMarksService } from '../../../../generated/services';
import { ManageColorMarkComponent } from '../manage-color-mark/manage-color-mark.component';

@Component({
  selector: 'mp-color-marks',
  templateUrl: './color-marks.component.html',
  styleUrls: ['./color-marks.component.scss']
})
export class ColorMarksComponent implements OnInit {
  private _destroy$: Subject<void> = new Subject<void>();

  loading = true;
  page = 1;
  size = 50;

  items: ColorMark[] = [];

  form: FormGroup;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: ColorMarksService,
    private modal: NgbModal,
    private toastrService: ToastrService
  ) {
    this.form = new FormGroup({
      search: new FormControl(""),
      includeArchived: new FormControl(false)
    });

    this.form.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(async (): Promise<void> => {
        const value: FormValue = this.form.getRawValue();

        const params: Params = {};

        if (value.search) {
          params.search = value.search;
        }

        if (value.includeArchived) {
          params.includeArchived = 'true';
        }

        this.router.navigate([], { queryParams: params, relativeTo: this.activatedRoute })

        this.page = 1;

        this.loading = true;
        this.items = await this.load();
        this.loading = false;
      });
  }

  async ngOnInit(): Promise<void> {

    const params = this.activatedRoute.snapshot.queryParamMap;

    this.form.patchValue({
      search: params.get('search'),
      includeArcived: params.get('includeArcived') === 'true'
    }, { emitEvent: false });

    const items = await this.load();

    this.items.push(...items);
    this.loading = false;

  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  create() {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  edit(item: ColorMark) {
    this.router.navigate([item.id], { relativeTo: this.activatedRoute });
  }

  async onScrollDown() {
    this.page++;

    this.loading = true;
    const items = await this.load();
    this.items.push(...items);
    this.loading = false;
  }

  async remove(item: ColorMark): Promise<void> {
    const data = await this.service.ColorMark(item.id).toPromise();

    const options: NgbModalOptions = { backdrop: 'static', size: 'lg', centered: true };
    const modalRef = this.modal.open(ManageColorMarkComponent, options);
    const componentRef: ManageColorMarkComponent = modalRef.componentInstance;

    componentRef.archived = data.archived;
    componentRef.inUse = data.inUse;
    componentRef.fullName = data.name;

    componentRef.onArchive.subscribe(() => {
      this.service.ArchiveAsync(item.id)
        .subscribe(
          async () => {
            modalRef.close();

            this.page = 1;
            this.loading = true;
            this.items = await this.load();
            this.loading = false;

            this.toastrService.error(`Метка ${data.name} перенесена в архив`, 'Успешно');
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              const message = response.error.errors.map(x => x.message).join('<br/>');

              this.toastrService.warning(message, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось перенести метку в архив', 'Ошибка');
          }
        );
    });

    componentRef.onRemove.subscribe(() => {
      this.service.RemoveAsync(item.id)
        .subscribe(
          async () => {
            modalRef.close();

            this.page = 1;
            this.loading = true;
            this.items = await this.load();
            this.loading = false;

            this.toastrService.error(`Метка ${data.name} удалена`, 'Успешно');
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              const message = response.error.errors.map(x => x.message).join('<br/>');

              this.toastrService.warning(message, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось удалить метку', 'Ошибка');
          }
        );
    });

    componentRef.onCancel.subscribe(() => {
      modalRef.close();
    });
  }

  async load(): Promise<ColorMark[]> {
    const value: FormValue = this.form.getRawValue();

    return await this.service.ColorMarks({ Search: value.search, IncludeArchived: value.includeArchived, Page: this.page, Size: this.size })
      .pipe(map(x => x.items))
      .toPromise();
  }

}

interface FormValue {
  search: string;
  includeArchived: boolean;
}
