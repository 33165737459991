import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnairesService } from '../../../../generated/services';
import { QuestionnairesResponse, QuestionnaireResponse } from '../../../../generated/models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'mp-questionary-modal',
  templateUrl: './questionary-modal.component.html',
  styleUrls: ['./questionary-modal.component.scss'],
  host: {
    class: "modal-default"
  }
})
export class QuestionaryModalComponent implements OnInit {

  private _items$: Observable<QuestionnaireResponse[]>;
  private _selected: QuestionnaireResponse;

  @Input() hasPhone: boolean;

  @Output()
  onPrintQuiz: EventEmitter<QuestionnaireResponse> = new EventEmitter<QuestionnaireResponse>();

  @Output()
  onSendQuiz: EventEmitter<QuestionnaireResponse> = new EventEmitter<QuestionnaireResponse>();

  public get items$(): Observable<QuestionnaireResponse[]> { return this._items$; }
  public get disabled(): boolean { return !this._selected; }

  constructor(
    private activeModal: NgbActiveModal,
    private questionairesService: QuestionnairesService
  ) { }

  ngOnInit() {
    this._items$ = this.questionairesService.Questionnaires({ ActiveOnly: true, Page: 1, Size: 0 })
      .pipe(
        map((response: QuestionnairesResponse): QuestionnaireResponse[] => response.questionnaires),
        tap(
          (data: QuestionnaireResponse[]): void => { console.info(data); }
        )
      );
  }

  public selected(item: QuestionnaireResponse): boolean {
    return this._selected && this._selected.id === item.id;
  }

  public select(item: QuestionnaireResponse): void {
    this._selected = item;
  }
  public print(): void {
    if (!this._selected) {
      return;
    }

    this.onPrintQuiz.emit(this._selected);
  }

  public send(): void {
    if (!this._selected) {
      return;
    }

    this.onSendQuiz.emit(this._selected);
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

}
