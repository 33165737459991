import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DiagnosesComponent } from './components/diagnoses/diagnoses.component';
import { PermissionNames } from '../../models/permission-names';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ControlsModule } from '@skytecs/controls';
import { LayoutModule } from '@skytecs/layout';
import { DiagnosisItemComponent } from './components/diagnosis-item/diagnosis-item.component';
import { DiagnosesResolver } from './resolvers/diagnoses-resolver';
import { DiagnosisComponent } from './components/diagnosis/diagnosis.component';
import { DiagnosisResolver } from './resolvers/diagnosis-resolver';
import { DiagnosisAliasModalComponent } from './components/diagnosis-alias-modal/diagnosis-alias-modal.component';
import { RecommendationModalComponent } from './components/recommendation-modal/recommendation-modal.component';
import { RecommendationItemComponent } from './components/recommendation-item/recommendation-item.component';
import { ManipulationModalComponent } from './components/manipulation-modal/manipulation-modal.component';

const routes: Routes = [
  {
    path: 'new',
    component: DiagnosisComponent,
    data: {
      title: "МКБ-10",
      requirements: [PermissionNames.ManageDiagnoses]
    },
    resolve: {
      payload: DiagnosisResolver
    }
  },
  {
    path: ':id',
    component: DiagnosisComponent,
    data: {
      title: "МКБ-10",
      requirements: [PermissionNames.ManageDiagnoses]
    },
    resolve: {
      payload: DiagnosisResolver
    }
  },
  {
    path: '',
    component: DiagnosesComponent,
    data: {
      title: "МКБ-10",
      requirements: [PermissionNames.ManageDiagnoses]
    },
    resolve: {
      payload: DiagnosesResolver
    }
  }
];

@NgModule({
  declarations: [DiagnosesComponent, DiagnosisItemComponent, DiagnosisComponent, DiagnosisAliasModalComponent, RecommendationModalComponent, RecommendationItemComponent, ManipulationModalComponent],
  entryComponents: [DiagnosisAliasModalComponent, RecommendationModalComponent, ManipulationModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ControlsModule,
    LayoutModule,

    SharedModule,

    NgbModalModule,
    ToastrModule,
    NgbPopoverModule,
    InfiniteScrollModule,

    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class DiagnosesSettingsModule { }
