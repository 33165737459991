import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { PersonMarkModalComponent } from "../components/person-mark-modal/person-mark-modal.component";

@Injectable({ providedIn: 'root' })
export class PersonMarkModalService {

  constructor(private modal: NgbModal) { }

  open(): NgbModalRef {
    return this.modal.open(PersonMarkModalComponent, { centered: true, backdrop: 'static' });
  }
}
