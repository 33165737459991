import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './components/reports/reports.component';
import { EmployeesModalComponent } from './components/employees-modal/employees-modal.component';
import { SalesModalComponent } from './components/sales-modal/sales-modal.component';
import { NgbModalModule, NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ReportsResolver } from './resolvers/reports-resolver';
import { EmployeeListItemComponent } from './components/employee-list-item/employee-list-item.component';
import { SelectedEmployeeItemComponent } from './components/selected-employee-item/selected-employee-item.component';
import { SalesListItemComponent } from './components/sales-list-item/sales-list-item.component';
import { PromotionsModalComponent } from './components/promotions-modal/promotions-modal.component';
import { PromotionListItemComponent } from './components/promotion-list-item/promotion-list-item.component';
import { PermissionNames } from '../../models/permission-names';
import { ServicesModalComponent } from './components/services-modal/services-modal.component';
import { ServiceListItemComponent } from './components/service-list-item/service-list-item.component';
import { ReportsFiltersComponent } from './components/reports-filters/reports-filters.component';
import { SelectedDiscountsListComponent } from './components/selected-discounts-list/selected-discounts-list.component';
import { SelectedListItemComponent } from './components/selected-list-item/selected-list-item.component';
import { SelectedPromotionsListComponent } from './components/selected-promotions-list/selected-promotions-list.component';
import { SelectedItemsListComponent } from './components/selected-items-list/selected-items-list.component';
import { ReportGroupComponent } from './components/report-group/report-group.component';
import { ActsBillsReportComponent } from './components/acts-bills-report/acts-bills-report.component';
import { CustomersModalComponent } from './components/customers-modal/customers-modal.component';
import { SharedModule } from '../shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { PreviewModalComponent } from './components/preview-modal/preview-modal.component';

const reportsRoutes: Routes = [
  {
    path: "",
    data: {
      title: "Отчеты",
      requirements: [PermissionNames.ViewReports]
    },
    component: ReportsComponent,
    resolve: {
      payload: ReportsResolver
    }
  },
];

@NgModule({
  declarations: [
    ReportsComponent,

    EmployeesModalComponent,
    SalesModalComponent,
    PreviewModalComponent,
    EmployeeListItemComponent,
    SelectedEmployeeItemComponent,
    SalesListItemComponent,
    PromotionsModalComponent,
    PromotionListItemComponent,
    ServicesModalComponent,
    ServiceListItemComponent,
    ReportsFiltersComponent,
    SelectedDiscountsListComponent,
    SelectedListItemComponent,
    SelectedPromotionsListComponent,
    SelectedItemsListComponent,
    ReportGroupComponent,
    ActsBillsReportComponent,
    CustomersModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(reportsRoutes),
    FormsModule,
    ReactiveFormsModule,

    NgbModalModule,
    PdfViewerModule,
    NgbButtonsModule,
    ToastrModule,

    LayoutModule,
    ControlsModule,
    SharedModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    EmployeesModalComponent,
    SalesModalComponent,
    PromotionsModalComponent,
    ServicesModalComponent,
    CustomersModalComponent,
    PreviewModalComponent
  ]
})
export class ReportsModule { }
