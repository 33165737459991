import { Injectable } from '@angular/core';
import { Icd10ResponseItem } from '../generated/models';
import { Observable, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { IdbBaseService } from './idb-base-service';
import { DiagnosesWebworkerService } from './diagnoses-webworker-service';

@Injectable({ providedIn: 'root' })
export class DiagnosesStorageService {
  dbObjectStoreName = "icd10";

  constructor(private idbBaseService: IdbBaseService, private diagnosesWWService: DiagnosesWebworkerService) { }

  //Условие загрузки данных с сервера
  needsUpdate = (): Observable<boolean> => this.idbBaseService.objectStoreEmpty("icd10");

  getAllIcd10(): Observable<Icd10ResponseItem[]> {
    const subject = new Subject<Icd10ResponseItem[]>();

    this.idbBaseService.dbLoaded.pipe(
      switchMap((value) => this.diagnosesWWService.dataLoaded),
      switchMap((value) => this.needsUpdate()),
      take(1),
      switchMap((result: boolean) => result ? this.diagnosesWWService.loadData() : of({})),
      switchMap(() => this.loadFromDb())
    ).subscribe((response: Icd10ResponseItem[]) => { subject.next(response) });

    return subject.asObservable();
  }

  loadFromDb(): Observable<Icd10ResponseItem[]> {
    const subject = new Subject<Icd10ResponseItem[]>();

    const request = this.idbBaseService.getObjectStore(this.dbObjectStoreName).getAll();
    request.onsuccess = (evt: any) => subject.next(evt.target.result);

    return subject.asObservable();
  }
}
