import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Company } from '../../../../../../../PatientPortal/src/app/generated/models';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PbxOperatorViewModel } from '../../../../generated/models/pbx-operator-view-model';

@Component({
  selector: 'mp-employee-phone-modal',
  templateUrl: './employee-phone-modal.component.html',
  styleUrls: ['./employee-phone-modal.component.scss']
})
export class EmployeePhoneModalComponent implements OnInit {

  public title: string;
  public form: FormGroup;

  @Input() companies: Company[];
  @Input() pbxOperators: PbxOperatorViewModel[];

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSubmit: EventEmitter<{ company: number; pbxOperator: number }> = new EventEmitter<{ company: number; pbxOperator: number }>();

  constructor() {
    this.title = "Номер телефона сотрудника";

    this.form = new FormGroup({
      company: new FormControl(0, [Validators.required]),
      pbxOperator: new FormControl(undefined, [Validators.required])
    })

  }

  ngOnInit() { }

  public cancel(): void {
    this.onClose.emit();
  }

  public numberAdditionalInfo(operator: PbxOperatorViewModel): string {
    let info = "";
    if (operator.ext) {
      info += ` - Добавочный: ${operator.ext}`
    }
    if (operator.pbxUser) {
      info += ` - Пользователь: ${operator.pbxUser}`
    }
    return info;
  }

  public submit(): void {
    this.form.get('company').markAsTouched();
    this.form.get('pbxOperator').markAsTouched();

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onSubmit.emit({
      company: value.company > 0 ? value.company : undefined,
      pbxOperator: value.pbxOperator
    });
  }

}


interface FormValue {
  company: number;
  pbxOperator: number;
}
