import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObservationsComponent } from './components/observations/observations.component';
import { Routes, RouterModule } from '@angular/router';
import { LayoutModule } from '@skytecs/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxMaskModule } from 'ngx-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SharedModule } from '../shared/shared.module';
import { ControlsModule } from '@skytecs/controls';
import { ManageObservationModalComponent } from './components/manage-observation.modal/manage-observation.modal.component';
import { ObservationsResolver } from './resolvers/observations.resolver';
import { PermissionNames } from '../../models/permission-names';
import { ObservationCommentModalComponent } from './components/observation-comment-modal/observation-comment-modal.component';
import { ObservationsListItemComponent } from './components/observations-list-item/observations-list-item.component';

const observationsRoutes: Routes = [
  {
    path: "",
    component: ObservationsComponent,
    resolve: {
      payload: ObservationsResolver
    },
    data: {
      title: "Результаты исследований",
      requirements: [PermissionNames.ViewResults]
    }
  }
];

@NgModule({
  declarations: [
    ObservationsComponent,
    ManageObservationModalComponent,
    ObservationCommentModalComponent,
    ObservationsListItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(observationsRoutes),
    ReactiveFormsModule,
    FormsModule,
    InfiniteScrollModule,
    NgbDropdownModule,
    NgxSelectModule,
    NgbAccordionModule,
    NgxMaskModule.forRoot({}),

    LayoutModule,
    ControlsModule,

    SharedModule,
    NgbModalModule,
    NgbPopoverModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManageObservationModalComponent,
    ObservationCommentModalComponent
  ]
})
export class ObservationsModule { }
