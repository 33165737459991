/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WorkspaceVisitsResponse } from '../models/workspace-visits-response';
import { CallParticipantFromQueueRequest } from '../models/call-participant-from-queue-request';
@Injectable({
  providedIn: 'root',
})
class WorkspaceService extends __BaseService {
  static readonly VisitsPath = '/api/Workspace/visits';
  static readonly CallParticipantPath = '/api/Workspace/queue/call';
  static readonly CallNextParticipantPath = '/api/Workspace/queue/call-next';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WorkspaceService.VisitsParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `From`:
   *
   * - `DoctorId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  VisitsResponse(params: WorkspaceService.VisitsParams): __Observable<__StrictHttpResponse<WorkspaceVisitsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorId || []).forEach(val => {if (val != null) __params = __params.append('DoctorId', val.toString())});
    (params.CompanyId || []).forEach(val => {if (val != null) __params = __params.append('CompanyId', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Workspace/visits`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WorkspaceVisitsResponse>;
      })
    );
  }
  /**
   * @param params The `WorkspaceService.VisitsParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `From`:
   *
   * - `DoctorId`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Visits(params: WorkspaceService.VisitsParams): __Observable<WorkspaceVisitsResponse> {
    return this.VisitsResponse(params).pipe(
      __map(_r => _r.body as WorkspaceVisitsResponse)
    );
  }

  /**
   * @param request undefined
   */
  CallParticipantResponse(request?: CallParticipantFromQueueRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workspace/queue/call`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  CallParticipant(request?: CallParticipantFromQueueRequest): __Observable<null> {
    return this.CallParticipantResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }
  CallNextParticipantResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Workspace/queue/call-next`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  CallNextParticipant(): __Observable<null> {
    return this.CallNextParticipantResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module WorkspaceService {

  /**
   * Parameters for Visits
   */
  export interface VisitsParams {
    To?: string;
    Size?: number;
    Search?: string;
    Page?: number;
    From?: string;
    DoctorId?: Array<number>;
    CompanyId?: Array<number>;
  }
}

export { WorkspaceService }
