import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Category } from "../../../generated/models";
import { Observable, forkJoin } from "rxjs";
import { CategoriesService } from "../../../generated/services";
import { map } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class ServiceGroupsResolver implements Resolve<ServiceGroupsResolverPayload>{
  constructor(private categoriesService: CategoriesService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ServiceGroupsResolverPayload> {

    return forkJoin({
      categories: this.categoriesService.Categories({ Page: 1, Size: 0 }).pipe(map(x => x.items))
    });
  }


}

export interface ServiceGroupsResolverPayload {
  categories: Category[];
}
