import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulesComponent } from './components/schedules/schedules.component';
import { DayComponent } from './components/day/day.component';
import { WeekComponent } from './components/week/week.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '@skytecs/layout';
import { NgxSelectModule } from 'ngx-select-ex';
import { ControlsModule } from '@skytecs/controls';
import { NgbButtonsModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerModule } from "@ng-bootstrap/ng-bootstrap";
import { RejectConfirmModalComponent } from './components/day/reject-confirm-modal/reject-confirm-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { SlotComponent } from './components/slot/slot.component';
import { ResourceInfoComponent } from './components/resource-info/resource-info.component';
import { PatientComponent } from './components/patient/patient.component';
import { PermissionNames } from '../../models/permission-names';
import { SlotActionsComponent } from './components/slot-actions/slot-actions.component';
import { AddBreakModalComponent } from './components/add-break-modal/add-break-modal.component';
import { BreakReasonComponent } from './components/break-reason/break-reason.component';
import { DayResourceComponent } from './components/day-resource/day-resource.component';
import { DaySlotComponent } from './components/day-slot/day-slot.component';
import { SharedModule } from '../shared/shared.module';
import { NameSuggestionsInputComponent } from './components/name-suggestions-input/name-suggestions-input.component';

const routes: Routes = [
  {
    path: ":mode",
    redirectTo: ":mode/",
    pathMatch: "full"
  },
  {
    path: ":mode/:date",
    component: SchedulesComponent,
    data: {
      title: "Расписание",
      requirements: [PermissionNames.ViewSchedules]
    }
  }
];

@NgModule({
  declarations: [
    SchedulesComponent,
    DayComponent,
    WeekComponent,
    RejectConfirmModalComponent,
    SlotComponent,
    ResourceInfoComponent,
    PatientComponent,
    SlotActionsComponent,
    AddBreakModalComponent,
    BreakReasonComponent,
    DayResourceComponent,
    DaySlotComponent,
    NameSuggestionsInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),

    NgxSelectModule,
    NgbButtonsModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbAlertModule,
    NgbPopoverModule,
    NgbTypeaheadModule,

    NgxMaskModule.forRoot({}),

    LayoutModule,
    ControlsModule,
    SharedModule
  ],
  entryComponents: [
    DayComponent,
    WeekComponent,
    RejectConfirmModalComponent,
    AddBreakModalComponent
  ],
  exports: [
    RouterModule
  ]
})
export class SchedulesModule { }
