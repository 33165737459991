import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClinicalRecommendation, PrescriptionService, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-prescriptions-services',
  templateUrl: './prescriptions-services.component.html',
  styleUrls: ['./prescriptions-services.component.scss']
})
export class PrescriptionsServicesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() stars: { [serviceId: number]: boolean } = {};
  @Input() prices: { [serviceId: number]: number } = {};
  @Input() recommendations: ClinicalRecommendation[] = [];
  @Input() selected: SectionsServicePrescription[] = [];
  @Input() services: PrescriptionService[] = [];

  @Output() onChange = new EventEmitter<ServicesSearchValue>()

  @Output() onSelect = new EventEmitter<PrescriptionService>();

  @Output() onToggleFavorite = new EventEmitter<PrescriptionService>();

  searchControl = new FormControl("");
  page = 1;

  constructor() {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string): void => {
        this.page = 1;
        this.onChange.emit({ page: this.page, search: value });
      });

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScrollDown() {
    this.page++;
    this.onChange.emit({ page: this.page, search: this.searchControl.value });
  }

  isServiceFavourite = (item: PrescriptionService) => !!this.stars[item.serviceId];
  isFavouriteSelected = (item: PrescriptionService) => this.selected.some(x => x.name === item.name);
  grossPriceFor = (id: number) => this.prices[id];
  toggleFavorite = (item: PrescriptionService) => this.onToggleFavorite.emit(item);
  select = (item: PrescriptionService) => this.onSelect.emit(item);
  hasRecommendation = (item: PrescriptionService) => this.recommendations.some(x => x.serviceId === item.serviceId);
  recommendation = (item: PrescriptionService) => this.recommendations.find(x => x.serviceId === item.serviceId);

  recommendationsFor = (item: PrescriptionService, type: 0 | 1) => this.recommendations
    .filter(x => x.type === type && x.serviceName === item.name)
    .map(x => ({ title: x.clinicalRecommendationName, text: x.text }));
}

export interface ServicesSearchValue {
  search: string;
  page: number;
}
