import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from "moment";

import { Router, ActivatedRoute, Params } from '@angular/router';

import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { TemplatesModalComponent } from '../templates-modal/templates-modal.component';
import { WorkspaceService, EmployeesService, RecordsService, InsuranceService, VisitsService } from 'projects/Clinic/src/app/generated/services';
import { WorkspaceVisitsResponse, WorkspaceListItem, Company, Attending, PermissionInCompany, Record, UsersTemplate, CheckPatientsResponse, AddedServicesResponse } from 'projects/Clinic/src/app/generated/models';
import { WorkspaceDashboardPayload } from '../../resolvers/dashboard-resolver';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, tap, switchMap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { AddServiceModalComponent, ConfirmEvent } from '../add-service-modal/add-service-modal.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  host: { class: "page" }
})
export class DashboardComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  errors: { [id: number]: string } = {};

  userId: number;
  canOpenAllDocuments = false;
  canSeePhone = false;
  canOpenDocumentsIn: number[] = [];

  page = 1;
  size = 50;

  medicalCentersList: Company[] = [];
  doctorsList: Attending[] = [];

  visits: WorkspaceListItem[] = [];

  loading = false;
  loadingFilters = false;

  callNextLineParticipant: boolean;
  callSelectedLineParticipant: boolean;

  form = new FormGroup({
    search: new FormControl(""),
    doctors: new FormControl(""),
    centers: new FormControl(""),
    period: new FormControl("")
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private workspaceService: WorkspaceService,
    private employeesService: EmployeesService,
    private userStorage: UserStorage,
    private recordsService: RecordsService,
    private toastrService: ToastrService,
    private insuranceService: InsuranceService,
    private visitsService: VisitsService
  ) {
    this.callNextLineParticipant = this.userStorage.profile.callNextLineParticipant;
    this.callSelectedLineParticipant = this.userStorage.profile.callSelectedLineParticipant;
    this.canSeePhone = this.userStorage.hasPermission(PermissionNames.ViewPhoneInReports);

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FormValue): void => {
          const params: Params = {};
          if (value.centers.length > 0) {
            params["company"] = value.centers;
          } else {
            params["company"] = [0];
          }

          if (value.doctors.length > 0) {
            params["doctor"] = value.doctors;
          } else {
            params["doctor"] = [0];
          }

          if (value.period.from && value.period.from.isValid()) {
            params["from"] = value.period.from.format("DD.MM.YYYY");
          }

          if (value.period.to && value.period.to.isValid()) {
            params["to"] = value.period.to.format("DD.MM.YYYY");
          }

          if (value.search) {
            params["search"] = value.search;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });

        }),
        tap((): void => {
          this.loading = true;
          this.visits = [];
          this.page = 1;
        }),
        switchMap((value: FormValue): Observable<WorkspaceVisitsResponse> => this.workspaceService.Visits({
          From: value.period.from.format("DD.MM.YYYY"),
          To: value.period.to.format("DD.MM.YYYY"),
          Page: 1,
          Size: this.size,
          Search: value.search,
          CompanyId: value.centers.filter((x: number): boolean => x > 0),
          DoctorId: value.doctors.filter((x: number): boolean => x > 0)
        })),
        map((response: WorkspaceVisitsResponse): WorkspaceListItem[] => response.items)
      )
      .subscribe(
        (visits: WorkspaceListItem[]) => {
          this.visits = visits;
          this.loading = false;

          const dmsVisits = visits.filter(x => x.paymentSource === 3 && !this.errors[x.patientId]);

          if (dmsVisits.length > 0) {
            this.insuranceService.CheckPatientsAsync({ ids: dmsVisits.map(x => x.patientId) })
              .subscribe(
                (response: CheckPatientsResponse): void => {
                  for (const patient of response.patients.filter(x => x.status === 1)) {
                    this.errors[patient.id] = 'Выставлен запрещенный диагноз';
                  }
                },
                (): void => { }
              );
          }

        },
        (response: HttpErrorResponse): void => {
          console.error({ status: response.status, erro: response.error });
          this.loading = false;
        }
      );
  }

  ngOnInit() {
    this.userId = this.userStorage.profile.id;
    this.canOpenDocumentsIn = this.userStorage.profile.permissions
      .filter((x: PermissionInCompany): boolean => x.companyId > 0)
      .filter((x: PermissionInCompany): boolean => x.name === PermissionNames.ViewDocumentsOfOtherDoctors || x.name === PermissionNames.ReviewRecords)
      .map((x: PermissionInCompany): number => x.companyId);

    this.canOpenAllDocuments = this.userStorage.profile.permissions
      .filter((x: PermissionInCompany): boolean => x.name === PermissionNames.ViewDocumentsOfOtherDoctors || x.name === PermissionNames.ReviewRecords)
      .some((x: PermissionInCompany): boolean => x.companyId === 0);

    this.activatedRoute.data.subscribe((data: { payload: WorkspaceDashboardPayload }): void => {
      const allowedCompanies: number[] = this.userStorage.profile.permissions
        .filter((x: PermissionInCompany): boolean => x.name === PermissionNames.ViewWorkspace)
        .map((x: PermissionInCompany): number => x.companyId);

      const canViewOthersPatients: boolean = this.userStorage.profile.permissions
        .some((x: PermissionInCompany): boolean => x.name === PermissionNames.ViewAllPatientsInWorkspace);

      this.medicalCentersList = allowedCompanies.some((x: number): boolean => x === 0) ?
        data.payload.companies :
        data.payload.companies.filter((x: Company): boolean => allowedCompanies.some((id: number): boolean => id === x.id));

      this.doctorsList = canViewOthersPatients ?
        data.payload.attendings :
        [{ id: this.userStorage.profile.id, shortName: this.userStorage.profile.userName }];

      let centers: number[] = this.activatedRoute.snapshot.queryParamMap.getAll("company").map((x: string): number => parseInt(x, 10));
      const search: string = this.activatedRoute.snapshot.queryParamMap.get("search");
      let doctors: number[] = this.activatedRoute.snapshot.queryParamMap.getAll("doctor").map((x: string): number => parseInt(x, 10));

      const from: moment.Moment = moment(this.activatedRoute.snapshot.queryParamMap.get("from"), "DD.MM.YYYY");
      const to: moment.Moment = moment(this.activatedRoute.snapshot.queryParamMap.get("to"), "DD.MM.YYYY");

      centers = centers.some((x: number): boolean => x === 0) ?
        [0] :
        centers.filter((x: number): boolean => this.medicalCentersList.some((c: Company): boolean => c.id === x));

      doctors = doctors.some((x: number): boolean => x === 0) ?
        [0] :
        doctors.filter((x: number): boolean => this.doctorsList.some((s: Attending): boolean => s.id === x));

      this.form.patchValue({
        centers: centers.length > 0 ? centers : [this.userStorage.profile.companyId],
        search: search,
        doctors: doctors.length > 0 ? doctors : [this.userStorage.profile.id],
        period: {
          from: from.isValid() ? from : moment(),
          to: to.isValid() ? to : moment(),
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  addRecord(visit: WorkspaceListItem) {
    if (visit.doctorId !== this.userId) {
      return;
    }

    const options: NgbModalOptions = {
      centered: true, backdrop: 'static', size: "lg"
    };
    const modalRef: NgbModalRef = this.modalService.open(TemplatesModalComponent, options);
    const componentRef: TemplatesModalComponent = modalRef.componentInstance;

    componentRef.contractItemId = visit.contractItemId;
    componentRef.personId = visit.personId;

    componentRef.onSubmit.subscribe(
      (template: UsersTemplate): void => {
        this.recordsService.Create({
          contractItemId: visit.contractItemId,
          templateId: template.id
        }).subscribe(
          (response: Record): void => {
            this.router.navigate(['./records', response.id], { relativeTo: this.activatedRoute.parent })
              .then((success): void => {
                if (success) {
                  modalRef.close();
                }
              });
          },
          (response: HttpErrorResponse): void => {
            modalRef.componentInstance.processing = false;

            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось добавить документ", "Ошибка");
          }
        );
      }
    );

    componentRef.onRecordOpen.subscribe((id: number): void => {
      this.router.navigate(['./records', id], { relativeTo: this.activatedRoute.parent })
        .then((success): void => {
          if (success) {
            modalRef.close();
          }
        });

    });

    componentRef.title = "Список доступных шаблонов";
  }

  addService(visit: WorkspaceListItem): void {
    if (!this.canAddService(visit)) return;

    const options: NgbModalOptions = { backdrop: 'static', centered: true, size: 'lg' };
    const modalRef = this.modalService.open(AddServiceModalComponent, options);
    const componentRef: AddServiceModalComponent = modalRef.componentInstance;

    componentRef.doctorId = this.userId;
    componentRef.visitId = visit.visitId;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((event: ConfirmEvent): void => {
      modalRef.componentInstance.processing = true;
      this.visitsService.AddServices({ id: visit.visitId, request: { serviceIds: [event.serviceId] } })
        .pipe(
          switchMap((response: AddedServicesResponse) => {
            if (response.contractItemIds.length != 1) {
              console.error("Multiple services created");
              return of({});
            }

            return this.recordsService.Create({ contractItemId: response.contractItemIds[0], templateId: event.templateId })
          })
        )
        .subscribe((response: Record) => {
          modalRef.dismiss();

          if (response.id) {
            this.router.navigate(['./records', response.id], { relativeTo: this.activatedRoute.parent });
          }
        }, (response: HttpErrorResponse): void => {
          modalRef.componentInstance.processing = false;

          if (response.status === 400) {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }

          this.toastrService.error("Не удалось добавить документ", "Ошибка");
        })
    });

  }

  openCard(visit: WorkspaceListItem): void {
    if (!this.canOpen(visit)) {
      return;
    }

    this.router.navigate(['./medicalfiles', visit.contractItemId], { relativeTo: this.activatedRoute.parent });
  }

  patientNameFor(visit: WorkspaceListItem): string {
    return [visit.lastName, visit.firstName, visit.middleName].join(" ");
  }

  onScrollDown(): void {
    this.page++;
    this.loading = true;

    const value: FormValue = this.form.getRawValue();

    this.workspaceService.Visits({
      Page: this.page,
      Size: this.size,
      CompanyId: value.centers.filter((x: number): boolean => x > 0),
      DoctorId: value.doctors.filter((x: number): boolean => x > 0),
      Search: value.search,
      From: value.period.from && value.period.from.isValid() ? value.period.from.format("DD.MM.YYYY") : "",
      To: value.period.to && value.period.to.isValid() ? value.period.to.format("DD.MM.YYYY") : ""
    })
      .subscribe(
        (response: WorkspaceVisitsResponse) => {
          this.visits.push(...response.items);

          this.loading = false;

          const dmsVisits = response.items.filter(x => x.paymentSource === 3 && !this.errors[x.patientId]);

          if (dmsVisits.length > 0) {
            this.insuranceService.CheckPatientsAsync({ ids: dmsVisits.map(x => x.patientId) })
              .subscribe(
                (response: CheckPatientsResponse): void => {
                  for (const patient of response.patients.filter(x => x.status === 1)) {
                    this.errors[patient.id] = 'Выставлен запрещенный диагноз';
                  }
                },
                (): void => { }
              );
          }
        },
        (response: HttpErrorResponse) => {
          this.loading = false;
          console.error('Observer got an error: ', response);
        }
      )

  }

  canOpen(visit: WorkspaceListItem): boolean {
    if (!visit.contractItemId) {
      return false;
    }

    if (this.canOpenAllDocuments) {
      return true;
    }

    if (visit.doctorId === this.userId) {
      return true;
    }

    if (this.canOpenDocumentsIn.includes(visit.companyId)) {
      return true;
    }

    return false;
  }

  canCallPatient(visit: WorkspaceListItem): boolean {
    if (!this.callSelectedLineParticipant) {
      return false;
    }

    return this.userId === visit.doctorId;
  }

  canAdd(visit: WorkspaceListItem): boolean {
    return visit.doctorId === this.userId;
  }

  canAddService(visit: WorkspaceListItem): boolean {
    return this.userStorage.hasPermission(PermissionNames.AddServicesFromWorkspace)
      && (visit.doctorId === this.userId || !visit.doctorId)
      && !visit.receiptPrinted;
  }

  callNext(): void {
    this.workspaceService.CallNextParticipant().subscribe();
  }

  call(visit: WorkspaceListItem): void {
    this.workspaceService.CallParticipant({ visitId: visit.visitId }).subscribe();
  }
}

interface FormValue {
  search: string;
  doctors: number[];
  centers: number[];
  period: {
    from: moment.Moment;
    to: moment.Moment;
  };

}
