/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AgreementTemplatesResponse } from '../models/agreement-templates-response';
import { CreateAgreementTemplateResponse } from '../models/create-agreement-template-response';
import { EditAgreementTemplateRequest } from '../models/edit-agreement-template-request';
import { AgreementTemplateContent } from '../models/agreement-template-content';
import { SavedTemplateGroup } from '../models/saved-template-group';
@Injectable({
  providedIn: 'root',
})
class AgreementsService extends __BaseService {
  static readonly AgreementTemplatesPath = '/api/v1/Agreements';
  static readonly CreateAgreementTemplatePath = '/api/v1/Agreements';
  static readonly AgreementTemplatePath = '/api/v1/Agreements/{id}';
  static readonly DeleteAgreementTemplatePath = '/api/v1/Agreements/{id}';
  static readonly EditAgreementTemplatePath = '/api/v1/Agreements/{id}';
  static readonly PlaceholdersPath = '/api/v1/Agreements/placeholders';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AgreementsService.AgreementTemplatesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  AgreementTemplatesResponse(params: AgreementsService.AgreementTemplatesParams): __Observable<__StrictHttpResponse<AgreementTemplatesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Agreements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgreementTemplatesResponse>;
      })
    );
  }
  /**
   * @param params The `AgreementsService.AgreementTemplatesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  AgreementTemplates(params: AgreementsService.AgreementTemplatesParams): __Observable<AgreementTemplatesResponse> {
    return this.AgreementTemplatesResponse(params).pipe(
      __map(_r => _r.body as AgreementTemplatesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAgreementTemplateResponse(request?: EditAgreementTemplateRequest): __Observable<__StrictHttpResponse<CreateAgreementTemplateResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Agreements`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateAgreementTemplateResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAgreementTemplate(request?: EditAgreementTemplateRequest): __Observable<CreateAgreementTemplateResponse> {
    return this.CreateAgreementTemplateResponse(request).pipe(
      __map(_r => _r.body as CreateAgreementTemplateResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AgreementTemplateResponse(id: number): __Observable<__StrictHttpResponse<AgreementTemplateContent>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Agreements/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AgreementTemplateContent>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AgreementTemplate(id: number): __Observable<AgreementTemplateContent> {
    return this.AgreementTemplateResponse(id).pipe(
      __map(_r => _r.body as AgreementTemplateContent)
    );
  }

  /**
   * @param id undefined
   */
  DeleteAgreementTemplateResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Agreements/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteAgreementTemplate(id: number): __Observable<null> {
    return this.DeleteAgreementTemplateResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `AgreementsService.EditAgreementTemplateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditAgreementTemplateResponse(params: AgreementsService.EditAgreementTemplateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/Agreements/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `AgreementsService.EditAgreementTemplateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  EditAgreementTemplate(params: AgreementsService.EditAgreementTemplateParams): __Observable<null> {
    return this.EditAgreementTemplateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  PlaceholdersResponse(): __Observable<__StrictHttpResponse<Array<SavedTemplateGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Agreements/placeholders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SavedTemplateGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  Placeholders(): __Observable<Array<SavedTemplateGroup>> {
    return this.PlaceholdersResponse().pipe(
      __map(_r => _r.body as Array<SavedTemplateGroup>)
    );
  }
}

module AgreementsService {

  /**
   * Parameters for AgreementTemplates
   */
  export interface AgreementTemplatesParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for EditAgreementTemplate
   */
  export interface EditAgreementTemplateParams {
    id: number;
    request?: EditAgreementTemplateRequest;
  }
}

export { AgreementsService }
