import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ServiceCategory, CreateServiceCategoryResponse } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceCategoriesService } from '../../../../generated/services';
import { ServiceUsageAllowed } from '../../../../models/service-allowed';

@Component({
  selector: 'mp-service-category-modal',
  templateUrl: './service-category-modal.component.html',
  styleUrls: ['./service-category-modal.component.scss']
})
export class ServiceCategoryModalComponent implements OnInit, AfterViewInit {

  @Input() category: ServiceCategory;
  @Input() parentId: number;
  @Input() possibleParents: { [categoryId: number]: ServiceCategory[] };

  private _canCreate: boolean;
  private _canEdit: boolean;

  private _readonly: boolean;
  private _processing: boolean;

  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private userStorage: UserStorage,
    private toastrService: ToastrService,
    private serviceCategoriesService: ServiceCategoriesService
  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      seoCategory: new FormControl(""),
      allowedFor: new FormControl("0"),
      superCategoryId: new FormControl("")
    });

    this._canCreate = this.userStorage.hasPermission(PermissionNames.EditCategories);
    this._canEdit = this.userStorage.hasPermission(PermissionNames.EditCategories);
  }

  ngOnInit() {
    this._readonly = (this.category != null && !this._canEdit) || (!this.category && !this._canCreate);

    if (this.category != null) {
      this.form.patchValue({ name: this.category.name, allowedFor: this.category.allowedFor, superCategoryId: this.category.superCategoryId })
    }

    if (this._readonly) {
      this.form.enabled ? this.form.disable() : undefined;
    } else {
      this.form.disabled ? this.form.enable() : undefined;
    }
  }

  ngAfterViewInit(): void {
    //выставляем родителя, когда select отрендерился целиком со всеми вариантами
    this.form.patchValue({ superCategoryId: this.parentId })
    if (this.category != null) {
      this.form.patchValue({ superCategoryId: this.category.superCategoryId })
    }
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }

    if (this._readonly) {
      return;
    }

    if (this._processing) {
      return;
    }

    if (this.category != null) {
      this.update();
    } else {
      this.create();
    }
  }

  private create(): void {
    this._processing = true;

    const value: ServiceCategoryForm = this.form.getRawValue();
    console.log(value)

    this.serviceCategoriesService.Create({
      name: value.name,
      superCategoryId: value.superCategoryId,
      allowedFor: value.allowedFor
    })
      .subscribe(
        (response: CreateServiceCategoryResponse): void => {
          this.toastrService.success("", "Категория создана");
          this._processing = false;

          this.activeModal.close();
        },
        (response: HttpErrorResponse): void => {
          this._processing = false;
          this.handleErrorResponse(response)
        }
      );
  }

  private update(): void {
    this._processing = true;

    const value: ServiceCategoryForm = this.form.getRawValue();

    this.serviceCategoriesService.Update({
      id: this.category.id,
      request: {
        name: value.name,
        superCategoryId: value.superCategoryId,
        allowedFor: value.allowedFor
      }
    })
      .subscribe(
        (): void => {
          this.toastrService.success("", "Категория сохранена");
          this._processing = false;

          this.activeModal.close();
        },
        (response: HttpErrorResponse): void => {
          this._processing = false;
          this.handleErrorResponse(response)
        }
      );
  }

  public getPlaceholder(category: ServiceCategory): string {
    let parentId: number = category.superCategoryId;
    let depth: number = 0;
    while (parentId != null) {
      depth++;
      let newParentId = null;
      for (let arr in this.possibleParents) {
        let index = this.possibleParents[arr].findIndex((x: ServiceCategory) => { return x.id == parentId });
        if (index !== -1) {
          newParentId = this.possibleParents[arr][index].superCategoryId
        }
      }
      parentId = newParentId
    }
    return "- ".repeat(depth);
  }

  private handleErrorResponse(response: HttpErrorResponse): void {

    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }

    if (response.status === 403) {
      this.toastrService.error("Для выполнения данного действия необходимо разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }

}

interface ServiceCategoryForm {
  name: string;
  superCategoryId: number;
  seoCategory: string;
  allowedFor: ServiceUsageAllowed
}
