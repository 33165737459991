import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Params } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, tap, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { DiscountResponse } from '../../../../generated/models';
import { DiscountsResponse } from '../../../../generated/models/discounts-response';
import { DiscountsService } from '../../../../generated/services/discounts.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-discount-list',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss'],
  host: { class: "page" }
})
export class DiscountsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  discounts: DiscountResponse[] = [];
  loading = false;

  page = 1;
  size = 100;

  scrollDistance = 1;

  filters = new FormGroup({
    search: new FormControl(""),
    activeOnly: new FormControl(true)
  });

  constructor(private discountsService: DiscountsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private deleteConfirmationsService: ConfirmationModalService
  ) {
    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((x: FiltersValue) => this.updateRoute(x)),
        tap((): void => {
          this.page = 1;
          this.discounts = [];
          this.loading = true;
        }),
        switchMap((x: FiltersValue) => {
          return this.discountsService.Discounts({ Page: this.page, Size: this.size, Search: x.search, ActiveOnly: x.activeOnly })
            .pipe(
              catchError((error: HttpErrorResponse): Observable<DiscountsResponse> => {
                console.error(error);
                this.toastrService.error('Не удалось загрузить список протоколов', 'Ошибка');

                return from([{ total: 0, discounts: [] }]);
              }));
        })
      ).subscribe((response: DiscountsResponse): void => {
        this.discounts = response.discounts;
        this.loading = false;
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    const value: FiltersValue = {
      search: queryMap.get("search"),
      activeOnly: !queryMap.has("activeOnly") || queryMap.get("activeOnly") === "true"
    };

    this.filters.patchValue(value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  updateRoute(value: FiltersValue) {
    const queryParams: Params = {};

    if (value.search) {
      queryParams.search = value.search;
    }

    if (!value.activeOnly) {
      queryParams.activeOnly = false;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
  }

  loadPage(): void {
    const value: FiltersValue = this.filters.getRawValue();

    this.discountsService.Discounts({
      Page: this.page,
      Size: this.size,
      Search: value.search,
      ActiveOnly: value.activeOnly
    })
      .subscribe(
        (result: DiscountsResponse): void => {
          this.discounts = this.discounts.concat(result.discounts);
        }
      );
  }

  onScrollDown(): void {
    this.page++;
    this.loadPage();
  }

  async openManager(discount: DiscountResponse) {
    const result = await this.deleteConfirmationsService.open({ message: `Скидка ${discount.name} будет удалена. продолжить?`, confirmBtnText: 'Удалить' });

    if (result) {
      try {
        await this.discountsService.Remove(discount.id).toPromise();

        this.page = 1;
        this.discounts = [];

        this.loadPage();

        this.toastrService.success(`Скидка ${discount.name} удалена`, "Успешно");
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 403) {
          this.toastrService.warning('Для выполнения данного действия требуется разрешение', 'Запрещено');
          return;
        }

        if (response.status === 400 && response.error.errors) {
          for (const error of response.error.errors) {
            switch (error.status) {
              case -2: this.toastrService.warning('Для выполнения данного действия требуется разрешение', 'Запрещено'); break;
              case 5: this.toastrService.warning('Скидка не найдена', 'Ошибка'); break;
              case 6: this.toastrService.warning('Удаление скидок и купонов, использованных при оформлении посещений, запрещено', 'Ошибка'); break;

              default: this.toastrService.error('Не удалось удалить скидку', 'Ошибка'); break;
            }
          }

          return;
        }

        this.toastrService.error("Не удалось удалить скидку", "Ошибка");
      }
    }
  }

  edit = (discount: DiscountResponse) => this.router.navigate([discount.id], { relativeTo: this.activatedRoute });
  create = () => this.router.navigate(["new"], { relativeTo: this.activatedRoute });
}

interface FiltersValue {
  search: string;
  activeOnly: boolean;
}
