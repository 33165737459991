import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PersonConsultation, Record } from '../../../../generated/models';

@Component({
  selector: 'mp-visit-list',
  templateUrl: './visit-list.component.html',
  styleUrls: ['./visit-list.component.scss']
})
export class VisitListComponent implements OnInit, OnChanges {
  expanded: PersonConsultation[] = [];
  checked: Record[] = [];

  @Input() items: PersonConsultation[] = [];
  @Input() current: number;
  @Input() loading = false;

  @Input() canReview = false;
  @Input() canAddService = false;
  @Input() canRemoveSigned = false;

  @Output() onAdd = new EventEmitter<PersonConsultation>();
  @Output() onAddService = new EventEmitter<PersonConsultation>();

  @Output() onOpen = new EventEmitter<Record>();
  @Output() onReview = new EventEmitter<Record>();
  @Output() onPrint = new EventEmitter<Record[]>();
  @Output() onRemove = new EventEmitter<Record[]>();
  @Output() onDownload = new EventEmitter<Record>();
  @Output() onDownloadArchive = new EventEmitter<Record>();

  get actionsDisabled(): boolean { return this.checked.length === 0; }

  constructor() { }
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {

      if (changes['items'].currentValue.some(x => x.id === this.current)) {
        this.expanded = [changes['items'].currentValue.find(x => x.id === this.current)];
      }
    }
  }

  isExpanded(visit: PersonConsultation): boolean {
    return this.expanded.some(x => x.id === visit.id);
  }

  toggle(visit: PersonConsultation): void {
    const index = this.expanded.findIndex(x => x.id === visit.id);

    if (index === -1) {
      this.expanded.push(visit);
    } else {
      this.expanded.splice(index, 1);
    }
  }

  isCurrent = (visit: PersonConsultation) => this.current === visit.id;

  isChecked = (record: Record) => this.checked.some(x => x.id === record.id);

  toggleRecord(record: Record, value: boolean): void {
    const index = this.checked.findIndex(x => x.id === record.id);

    if (value && index === -1) this.checked.push(record);

    if (!value && index !== -1) this.checked.splice(index, 1);
  }

  add = (item: PersonConsultation) => this.onAdd.emit(item);

  addService = (item: PersonConsultation) => this.onAddService.emit(item);

  open = (record: Record) => this.onOpen.emit(record);
  print = (record: Record) => this.onPrint.emit([record]);
  remove = (record: Record) => this.onRemove.emit([record]);
  download = (record: Record) => this.onDownload.emit(record);
  downloadArchive = (record: Record) => this.onDownloadArchive.emit(record);
  review = (record: Record) => this.onReview.emit(record);

}
