import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PersonMark, Record } from 'projects/Clinic/src/app/generated/models';

import * as moment from "moment";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-protocol-editor-header',
  templateUrl: './protocol-editor-header.component.html',
  styleUrls: ['./protocol-editor-header.component.scss']
})
export class ProtocolEditorHeaderComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() disabled = true;
  @Input() userId = 0;
  @Input() record: Record = {};
  @Input() marks: PersonMark[] = [];
  @Input() appointmentDate = '';
  @Input() closingDate = '';

  @Input() canAddMark = false;
  @Input() canManageMarks = false;

  @Output() onDownloadSignature = new EventEmitter<void>();
  @Output() onAddMark = new EventEmitter<void>();
  @Output() onRemoveMark = new EventEmitter<PersonMark>();
  @Output() onUpdate = new EventEmitter<Record>();

  form = new FormGroup({
    title: new FormControl(''),
    isPreventive: new FormControl(false)
  });

  get sealed(): string { return this.record && this.record.sealed ? moment.unix(this.record.sealed).format('DD.MM.YYYY HH:mm') : ''; }

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value) => {
          this.onUpdate.emit({
            ...this.record,
            title: value.title,
            visitReason: value.isPreventive ? 1 : 0
          });
        }
      );

  }

  ngOnInit() {
    this.form.patchValue({
      title: this.record.title,
      isPreventive: this.record.visitReason === 1
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['record']) {
      const record: Record = changes['record'].currentValue;

      const value = this.form.getRawValue();

      if (value.title !== record.title) {
        this.form.patchValue({ title: record.title }, { emitEvent: false });
      }

      if (value.isPreventive !== (record.visitReason === 1)) {
        this.form.patchValue({ isPreventive: (record.visitReason === 1) }, { emitEvent: false });
      }
    }

    if (changes['disabled']) {
      const disabled = changes['disabled'].currentValue;

      if (disabled) {
        this.form.disable({ emitEvent: false });
      } else {
        this.form.enable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  downloadSignature = () => this.onDownloadSignature.emit();
  addMark = () => this.onAddMark.emit();
  removeMark = (mark: PersonMark) => this.canRemove(mark) ? this.onRemoveMark.emit(mark) : undefined;

  canRemove = (mark: PersonMark) => this.canManageMarks || (this.canAddMark && mark.authorId === this.userId);
}
