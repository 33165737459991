import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecordReviewSectionDescription } from '../../../../generated/models';

@Component({
  selector: 'mp-record-review-section',
  templateUrl: './record-review-section.component.html',
  styleUrls: ['./record-review-section.component.scss']
})
export class RecordReviewSectionComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() description: RecordReviewSectionDescription;
  @Input() set value(value: number) {
    this.radio.get('control').setValue(value, { emitEvent: false });
  }

  @Input() set dirty(value: boolean) {
    if (value) {
      this.radio.get('control').markAsDirty();
    }
  }

  @Output()
  onChange: EventEmitter<number> = new EventEmitter<number>();

  radio: FormGroup;

  constructor() {
    const control = new FormControl(undefined, [Validators.required]);

    this.radio = new FormGroup({
      control: control
    });

    control.valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value: number): void => {
        this.onChange.emit(value);
      });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

}
