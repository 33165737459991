import { Injectable } from '@angular/core';
import { IdbBaseService } from './idb-base.service';
import { Observable, Subject, ReplaySubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserStorageService } from '@skytecs/security';

@Injectable({
  providedIn: 'root'
})
export class StandardsWebworkerService {

  private _dataLoaded = new ReplaySubject<boolean>();

  public get dataLoaded(): ReplaySubject<boolean> { return this._dataLoaded };

  constructor(private idbBaseService: IdbBaseService, private userStorageService: UserStorageService) {
    this.idbBaseService.dbLoaded.pipe(
      switchMap(() => { return this.needsLoading() }),
      switchMap((result) => { return result ? this.loadData() : of({}) })
    ).subscribe(
      () => {
        this._dataLoaded.next();
      })
  }

  public loadData(): Observable<void> {
    let result: Subject<void> = new Subject<void>();

    if (typeof Worker !== 'undefined') {
      const worker = new Worker('/web-workers/web-worker.js', { type: 'module' });

      worker.onmessage = ({ data }) => {

        let transaction = this.idbBaseService.db.transaction(["standardServices"], "readwrite");
        transaction.objectStore("standardServices").clear();

        for (let item of data.standards) {
          let dbItem = { "id": item.id, "code": item.code, "name": item.name, "effective": item.effective, "expires": item.expires };

          transaction.objectStore("standardServices").add(dbItem);
        }

        transaction.oncomplete = (evt) => {
          result.next();
        }

        worker.terminate();
      };

      worker.postMessage({ url: window.location.origin + "/api/Standards?Size=0&IncludeExpired=true", token: this.userStorageService.getToken() });

    } else {
      console.error("Web workers not supported")
      result.next();
    }

    return result.asObservable();
  }

  private needsLoading = (): Observable<boolean> => {
    return this.idbBaseService.objectStoreEmpty("standardServices")
  }
}
