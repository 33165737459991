import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PatientSearchResult } from '../../../../generated/models';

@Component({
  selector: 'mp-patient-search-result',
  templateUrl: './patient-search-result.component.html',
  styleUrls: ['./patient-search-result.component.scss']
})
export class PatientSearchResultComponent implements OnInit {

  @Input() patient: PatientSearchResult;

  @Output()
  onSelect = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get name(): string {
    if (!this.patient) return '';

    return [this.patient.lastname, this.patient.firstname, this.patient.middlename].filter(x => x).join(' ');
  }

  get phone(): string {
    if (!this.patient) return '';

    if (this.patient.phone.length !== 11) return this.patient.phone;

    return `+7 (${this.patient.phone.substring(1, 4)}) ${this.patient.phone.substring(4, 7)}-${this.patient.phone.substring(7, 9)}-${this.patient.phone.substring(9, 11)}`
  }

  select = () => this.onSelect.emit();
}
