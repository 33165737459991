import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptParametersModalComponent, CorrectionReceiptParameters } from '../components/receipt-parameters-modal/receipt-parameters-modal.component';
import { switchMap } from 'rxjs/operators';
import { CashboxService } from '../../../generated/services';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReceiptParametersService {

  constructor(
    private modalService: NgbModal,
    private cashboxService: CashboxService,
    private toastrService: ToastrService) { }

  public showModal() {
    const options: NgbModalOptions = { backdrop: 'static', size: 'lg' };

    const modal: NgbModalRef = this.modalService.open(ReceiptParametersModalComponent, options);

    modal.componentInstance.onDismissed.subscribe(() => {
      modal.dismiss();
    });

    modal.componentInstance.onFormSubmitted.pipe(
      switchMap((formValue: CorrectionReceiptParameters): Observable<void> => {
        return this.cashboxService.CreateCorrectionReceiptTask({
          type: formValue.correctionReceiptType,
          sum: formValue.sum,
          isPaydByCard: formValue.isPayedByCard,
          taxType: formValue.taxType,
          taxationType: formValue.taxationType,
          correctionType: formValue.correctionType,
          correctionBaseName: formValue.correctionBase,
          correctionBaseDate: formValue.documentDate,
          correctionBaseNumber: formValue.documentNumber
        })
      })
    ).subscribe(
      (): void => {
        modal.dismiss();
        this.toastrService.success('Печать чека коррекции', 'Успешно');
      },
      (error: HttpErrorResponse): void => {
        if (error.status === 400) {
          this.toastrService.error(error.error.description, "Ошибка");
        }
        else {
          this.toastrService.error("Ошибка при печати", "Ошибка");
        }
      }
    );
  }
}
