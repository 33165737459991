import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetricsComponent } from './components/metrics/metrics.component';
import { MetricListItemComponent } from './components/metric-list-item/metric-list-item.component';
import { RouterModule, Routes } from '@angular/router';

import { LayoutModule } from '@skytecs/layout';
import { SecurityModule } from '@skytecs/security';
import { ControlsModule } from '@skytecs/controls';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PermissionNames } from '../../models/permission-names';
import { MetricComponent } from './components/metric/metric.component';
import { MetricResolver } from './resolvers/metric-resolver';

const routes: Routes = [
  {
    path: '',
    component: MetricsComponent,
    data: {
      title: "Показатели",
      requirements: [PermissionNames.ManageMetrics]
    }
  },
  {
    path: 'new',
    component: MetricComponent,
    data: {
      title: "Новый показатель",
      requirements: [PermissionNames.ManageMetrics]
    },
    resolve: {
      payload: MetricResolver
    }
  },
  {
    path: ':id',
    component: MetricComponent,
    data: {
      title: "Показатель",
      requirements: [PermissionNames.ManageMetrics]
    },
    resolve: {
      payload: MetricResolver
    }
  },
];

@NgModule({
  declarations: [MetricsComponent, MetricListItemComponent, MetricComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    RouterModule.forChild(routes),

    NgbModalModule,
    ToastrModule,
    InfiniteScrollModule,

    LayoutModule,
    SecurityModule,
    ControlsModule,

    SharedModule,
  ],
  exports: [
    RouterModule
  ]
})
export class MetricsSettingsModule { }
