import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Company } from '../../../../generated/models';

@Component({
  selector: 'mp-acts-bills-report',
  templateUrl: './acts-bills-report.component.html',
  styleUrls: ['./acts-bills-report.component.scss']
})
export class ActsBillsReportComponent implements OnInit {

  @Input() filterCompanies: Company[];
  @Input() actsReportHidden: boolean;
  @Input() billsReportHidden: boolean;

  @Output() onActsReportRequested: EventEmitter<number> = new EventEmitter<number>();
  @Output() onBillsReportRequested: EventEmitter<number> = new EventEmitter<number>();

  private paymentSourceControl: FormControl;

  public form: FormGroup;
  public currentFilterCompanies: Company[] = [];
  public financesSource: { id: number, name: string }[] = [];

  constructor() {
    this.financesSource = [
      { id: 1, name: "Договор" },
      { id: 3, name: "ОМС" },
      { id: 5, name: "ДМС" }
    ];

    this.paymentSourceControl = new FormControl("");

    this.form = new FormGroup({
      paymentSource: this.paymentSourceControl,
      companyId: new FormControl("")
    });

    this.paymentSourceControl.valueChanges.subscribe(
      (value: number) => {
        this.currentFilterCompanies = this.filterCompanies.filter(x => x.type == value);
        this.form.patchValue({ companyId: null });
      }
    );
  }

  ngOnInit() {
  }

  public actsReportClick() {
    this.onActsReportRequested.emit(this.form.get("companyId").value);
  }

  public billsReportClick() {
    this.onBillsReportRequested.emit(this.form.get("companyId").value);
  }

  public get linksDisabled(): boolean { return !this.form.get("companyId").value }
}
