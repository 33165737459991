import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametersComponent } from './components/parameters/parameters.component';
import { ExpandableParameterGroupComponent } from './components/expandable-parameter-group/expandable-parameter-group.component';
import { GeneralParameterComponent } from './components/general-parameter/general-parameter.component';
import { LabProtocolParameterComponent } from './components/lab-protocol-parameter/lab-protocol-parameter.component';
import { VisitProtocolParameterComponent } from './components/visit-protocol-parameter/visit-protocol-parameter.component';
import { LabResultsParameterComponent } from './components/lab-results-parameter/lab-results-parameter.component';
import { LabResultsItemComponent } from './components/lab-results-item/lab-results-item.component';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    ParametersComponent,
    GeneralParameterComponent,
    ExpandableParameterGroupComponent,
    LabProtocolParameterComponent,
    VisitProtocolParameterComponent,
    LabResultsParameterComponent,
    LabResultsItemComponent
  ],
  imports: [
    CommonModule,
    NgbPopoverModule
  ],
  exports: [
    ParametersComponent
  ]
})
export class ProtocolInfoModule { }
