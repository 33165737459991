import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Organization } from '../../../../generated/models';

@Component({
  selector: 'mp-organization-item',
  templateUrl: './organization-item.component.html',
  styleUrls: ['./organization-item.component.scss']
})
export class OrganizationItemComponent implements OnInit {

  @Input() item: Organization;

  @Output() onManage = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  get type(): string {
    if (!this.item) return '';

    if (this.item.type === 1) return "Лечебное учреждение (направляет пациентов)";
    if (this.item.type === 2) return "Лаборатория (выполняет исследования)";
    if (this.item.type === 3) return "Страховая компания ОМС";
    if (this.item.type === 4) return "Собственная организация";
    if (this.item.type === 5) return "Страховая компания ДМС";
    if (this.item.type === 6) return "Организация-заказчик";

    return '';
  }

  manage = () => this.onManage.emit();
}
