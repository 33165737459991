import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentPreviewComponent } from "../components/document-preview/document-preview.component";

@Injectable({ providedIn: 'root' })
export class DocumentPreviewService {

  constructor(private modal: NgbModal) { }

  open(url: string) {
    const modalRef = this.modal.open(DocumentPreviewComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: DocumentPreviewComponent = modalRef.componentInstance;
    componentRef.path = url;

    return componentRef;
  }

}
