import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonLabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-lab-results-modal-item',
  templateUrl: './lab-results-modal-item.component.html',
  styleUrls: ['./lab-results-modal-item.component.scss']
})
export class LabResultsModalItemComponent implements OnInit {
  @Input() item: PersonLabResult = {};
  @Input() selected: PersonLabResult[] = [];

  @Output() onToggle = new EventEmitter<PersonLabResult>();

  showInfo = false;

  constructor() { }

  ngOnInit() {
    this.showInfo = !!this.item && (!!this.item.normalValue || !!this.item.comment);
  }

  toggle = (item: PersonLabResult) => this.onToggle.emit(item);

  isSelected = (item: PersonLabResult) => this.selected.some(x => x.name === item.name && x.value === item.value);
}
