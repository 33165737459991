import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mp-selected-list-item',
  templateUrl: './selected-list-item.component.html',
  styleUrls: ['./selected-list-item.component.scss']
})
export class SelectedListItemComponent implements OnInit {

  @Input() name: string;
  @Output()
  onRemove: EventEmitter<void> = new EventEmitter<void>();
  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }
}
