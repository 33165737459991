import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

import * as moment from 'moment';
import { ResourceDayScheduleSummary, ResourceWeekScheduleViewModel } from '../../../../generated/models';

@Component({
  selector: 'mp-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit, OnChanges {

  days: string[] = [];
  @Input() resources: ResourceWeekScheduleViewModel[] = [];

  @Input() firstDayOfWeek: string;

  @Input() loading = false;

  @Output() chosedDay = new EventEmitter<{ day: moment.Moment, resource: number }>();

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['firstDayOfWeek']) {
      const value = moment(changes['firstDayOfWeek'].currentValue, 'YYYY-MM-DD');
      this.days = [
        value.format('DD.MM.YYYY'),
        value.clone().add(1, 'day').format('DD.MM.YYYY'),
        value.clone().add(2, 'day').format('DD.MM.YYYY'),
        value.clone().add(3, 'day').format('DD.MM.YYYY'),
        value.clone().add(4, 'day').format('DD.MM.YYYY'),
        value.clone().add(5, 'day').format('DD.MM.YYYY'),
        value.clone().add(6, 'day').format('DD.MM.YYYY'),
      ];
    }
  }


  chooseDay(day: ResourceDayScheduleSummary, resource: ResourceWeekScheduleViewModel): void {
    if (day.totalSlots > 0) {
      this.chosedDay.emit({ day: moment(day.date, 'DD.MM.YYYY'), resource: resource.resourceId });
    }
  }
}
