/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AttendanceResponse } from '../models/attendance-response';
import { ExportScheduleAttendanceResponse } from '../models/export-schedule-attendance-response';
@Injectable({
  providedIn: 'root',
})
class AttendanceService extends __BaseService {
  static readonly AttendanceAsyncPath = '/api/v1/Attendance';
  static readonly ExportScheduleAttendanceAsyncPath = '/api/v1/attendance/export-schedule-attendance';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AttendanceService.AttendanceAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  AttendanceAsyncResponse(params: AttendanceService.AttendanceAsyncParams): __Observable<__StrictHttpResponse<AttendanceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    (params.SpecialityIds || []).forEach(val => {if (val != null) __params = __params.append('SpecialityIds', val.toString())});
    if (params.IncludeArrived != null) __params = __params.set('IncludeArrived', params.IncludeArrived.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Attendance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendanceResponse>;
      })
    );
  }
  /**
   * @param params The `AttendanceService.AttendanceAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  AttendanceAsync(params: AttendanceService.AttendanceAsyncParams): __Observable<AttendanceResponse> {
    return this.AttendanceAsyncResponse(params).pipe(
      __map(_r => _r.body as AttendanceResponse)
    );
  }

  /**
   * @param params The `AttendanceService.ExportScheduleAttendanceAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  ExportScheduleAttendanceAsyncResponse(params: AttendanceService.ExportScheduleAttendanceAsyncParams): __Observable<__StrictHttpResponse<ExportScheduleAttendanceResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    (params.SpecialityIds || []).forEach(val => {if (val != null) __params = __params.append('SpecialityIds', val.toString())});
    if (params.IncludeArrived != null) __params = __params.set('IncludeArrived', params.IncludeArrived.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/attendance/export-schedule-attendance`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExportScheduleAttendanceResponse>;
      })
    );
  }
  /**
   * @param params The `AttendanceService.ExportScheduleAttendanceAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `IncludeArrived`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  ExportScheduleAttendanceAsync(params: AttendanceService.ExportScheduleAttendanceAsyncParams): __Observable<ExportScheduleAttendanceResponse> {
    return this.ExportScheduleAttendanceAsyncResponse(params).pipe(
      __map(_r => _r.body as ExportScheduleAttendanceResponse)
    );
  }
}

module AttendanceService {

  /**
   * Parameters for AttendanceAsync
   */
  export interface AttendanceAsyncParams {
    To?: string;
    SpecialityIds?: Array<number>;
    IncludeArrived?: boolean;
    From?: string;
    DoctorIds?: Array<number>;
    CompanyIds?: Array<number>;
  }

  /**
   * Parameters for ExportScheduleAttendanceAsync
   */
  export interface ExportScheduleAttendanceAsyncParams {
    To?: string;
    SpecialityIds?: Array<number>;
    IncludeArrived?: boolean;
    From?: string;
    DoctorIds?: Array<number>;
    CompanyIds?: Array<number>;
  }
}

export { AttendanceService }
