import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { CodeSystem } from '../../../../generated/models';
import { CodeSystemsService } from '../../../../generated/services';

@Component({
  selector: 'mp-code-systems',
  templateUrl: './code-systems.component.html',
  styleUrls: ['./code-systems.component.scss'],
  host: { class: 'page' }
})
export class CodeSystemsComponent implements OnInit {
  private _destroy: Subject<void> = new Subject<void>();

  public codeSystems: CodeSystem[] = [];

  public loading: boolean = false;
  public page: number = 1;
  public size: number = 20;
  public search: string = "";

  public scrollDistance = 1;

  public filters: FormGroup;

  constructor(
    private codeSystemsService: CodeSystemsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private toastrService: ToastrService
  ) {
    this.filters = new FormGroup({
      search: new FormControl("")
    });

    this.filters.valueChanges
      .pipe(
        takeUntil(this._destroy),
        tap((value: FiltersValue): void => {
          this.page = 1;
          this.codeSystems = [];

          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

        })
      )
      .subscribe((): void => {
        this.loadPage();
      });
  }

  ngOnInit() {
    const queryMap: ParamMap = this.activatedRoute.snapshot.queryParamMap;

    if (queryMap.has("search")) {
      this.search = queryMap.get("search");
    }

    this.filters.patchValue({ search: this.search });
  }

  private loadPage(): void {
    this.codeSystemsService.CodeSystems({ Page: this.page, Size: this.size, Search: this.search })
      .subscribe((result: CodeSystem[]): void => {
        this.codeSystems = this.codeSystems.concat(result);
      });
  }

  public onScrollDown(): void {
    this.page++;

    this.loadPage();
  }

  public create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  public edit(item: CodeSystem): void {
    this.router.navigate([item.id], { relativeTo: this.activatedRoute });
  }

  public delete(item: CodeSystem): void {
    const options: NgbModalOptions = { backdrop: 'static', centered: true };

    const modal: NgbModalRef = this.modalService.open(DeleteConfirmationModalComponent, options);

    modal.componentInstance.message = `Удалить кодовую систему ${item.name}?`;

    modal.result.then(
      () => {
        this.codeSystemsService.Delete(item.id).subscribe(
          (): void => {
            this.codeSystems = [];
            this.page = 1;
            this.loadPage();

            this.toastrService.success('Кодовая система удалена', 'Успешно');
          },
          (error: HttpErrorResponse): void => {
            if (error.status === 400) {
              this.toastrService.error(error.error.message, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось удалить кодовую систему', 'Отмена');
          }
        )
      },
      () => { }
    );
  }
}

interface FiltersValue {
  search: string;
}
