import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mp-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent implements OnInit, OnDestroy {

  private _value: boolean;
  private _disabled: boolean;

  private _slaves: Array<CheckboxGroupComponent>;

  @Input("checked") set checked(value: boolean) { this._value = value; }
  @Input("item-id") id: number;
  @Input("owner") owner: CheckboxGroupComponent;
  @Input("disabled") disabled: boolean;

  @Output("update") onChangeHandler: EventEmitter<{ id: number, value: boolean }> = new EventEmitter<{ id: number, value: boolean }>();

  public get value(): boolean { return this._value; }
  public set value(value: boolean) { this._value = value; }

  public get indeterminate(): boolean {
    if (this._slaves.length === 0) {
      return false;
    }
    return this._slaves.some((x: CheckboxGroupComponent): boolean => x.value) && this._slaves.some((x: CheckboxGroupComponent): boolean => !x.value);
  }

  constructor() {
    this._slaves = [];
  }

  ngOnInit() {
    if (this.owner) {
      this.owner.register(this);
    }

  }

  ngOnDestroy(): void {
    if (this.owner) {
      this.owner.unregister(this);
    }
  }

  public onChange = (e: any): void => {
    if (this._slaves.length > 0) {
      if (this.indeterminate) {
        this._slaves.forEach((x: CheckboxGroupComponent): void => {
          x.checked = true;
          x.onChangeHandler.emit({ value: x.value, id: x.id });
        });
      } else {
        this._slaves.forEach((x: CheckboxGroupComponent): void => {
          x.checked = this.value;
          x.onChangeHandler.emit({ value: x.value, id: x.id });
        });
      }
    }

    if (this.owner) {
      this.owner.update();
    }

    this.onChangeHandler.emit({ value: this.value, id: this.id });
  }

  public update = (): void => {
    this._value = this._slaves.every((x: CheckboxGroupComponent): boolean => x.value);
  }

  public register = (slave: CheckboxGroupComponent): void => {
    const index: number = this._slaves.findIndex((x: CheckboxGroupComponent): boolean => x.id === slave.id);

    if (index === -1) {
      this._slaves.push(slave);
    }
  }

  public unregister = (slave: CheckboxGroupComponent): void => {
    const index: number = this._slaves.findIndex((x: CheckboxGroupComponent): boolean => x.id === slave.id);

    if (index !== -1) {
      this._slaves.splice(index, 1);
    }
  }

}
