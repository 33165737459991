import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PatientPersonalInfo } from '../../models/patient.personal.info';

@Component({
  selector: 'mp-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {

  @Input() patient: PatientPersonalInfo;

  @Output() onClear = new EventEmitter<void>();

  get name(): string {
    if (!this.patient) return '';
    if (this.patient.lastName) {
      return [this.patient.lastName, this.patient.firstName, this.patient.middleName].filter(x => x).join(" ").trim();
    }

    return this.patient.name;
  }

  get phone(): string {
    if (!this.patient || !this.patient.phone) return '';

    const phone = this.patient.phone;

    if (phone.length === 11) {
      return `+7 (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`;
    }

    if (phone.length === 10) {
      return `+7 (${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 8)}-${phone.substring(8, 10)}`;
    }

    return phone;
  }

  get show(): boolean { return !!this.patient; }

  constructor() { }

  ngOnInit() { }

  clear = () => this.onClear.emit();
}
