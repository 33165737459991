/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Metric } from '../models/metric';
import { CreateMetricResponse } from '../models/create-metric-response';
import { CreateMetricRequest } from '../models/create-metric-request';
import { UpdateMetricRequest } from '../models/update-metric-request';
@Injectable({
  providedIn: 'root',
})
class MetricsService extends __BaseService {
  static readonly MetricsAsyncPath = '/api/v2/metrics';
  static readonly CreateMetricAsyncPath = '/api/v2/metrics';
  static readonly MetricAsyncPath = '/api/v2/metrics/{id}';
  static readonly UpdateMetricAsyncPath = '/api/v2/metrics/{id}';
  static readonly RemoveMetricAsyncPath = '/api/v2/metrics/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `MetricsService.MetricsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  MetricsAsyncResponse(params: MetricsService.MetricsAsyncParams): __Observable<__StrictHttpResponse<Array<Metric>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/metrics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Metric>>;
      })
    );
  }
  /**
   * @param params The `MetricsService.MetricsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  MetricsAsync(params: MetricsService.MetricsAsyncParams): __Observable<Array<Metric>> {
    return this.MetricsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<Metric>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateMetricAsyncResponse(request?: CreateMetricRequest): __Observable<__StrictHttpResponse<CreateMetricResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/metrics`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateMetricResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateMetricAsync(request?: CreateMetricRequest): __Observable<CreateMetricResponse> {
    return this.CreateMetricAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateMetricResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  MetricAsyncResponse(id: number): __Observable<__StrictHttpResponse<Metric>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/metrics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Metric>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  MetricAsync(id: number): __Observable<Metric> {
    return this.MetricAsyncResponse(id).pipe(
      __map(_r => _r.body as Metric)
    );
  }

  /**
   * @param params The `MetricsService.UpdateMetricAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateMetricAsyncResponse(params: MetricsService.UpdateMetricAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/metrics/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `MetricsService.UpdateMetricAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateMetricAsync(params: MetricsService.UpdateMetricAsyncParams): __Observable<null> {
    return this.UpdateMetricAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveMetricAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/metrics/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveMetricAsync(id: number): __Observable<null> {
    return this.RemoveMetricAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module MetricsService {

  /**
   * Parameters for MetricsAsync
   */
  export interface MetricsAsyncParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for UpdateMetricAsync
   */
  export interface UpdateMetricAsyncParams {
    id: number;
    request?: UpdateMetricRequest;
  }
}

export { MetricsService }
