import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalanceManagementModalComponent } from './components/balance-management-modal/balance-management-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BalanceOperationItemComponent } from './components/balance-operation-item/balance-operation-item.component';
import { ControlsModule } from "@skytecs/controls";
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [BalanceManagementModalComponent, BalanceOperationItemComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbModalModule,
    ControlsModule,
    ToastrModule
  ],
  exports: [
    BalanceManagementModalComponent
  ]
})
export class BalanceManagementModule { }
