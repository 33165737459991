/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Document } from '../models/document';
import { RemoveDocumentsResponse } from '../models/remove-documents-response';
import { PrintDocumentsResponse } from '../models/print-documents-response';
import { PrintDocumentsRequest } from '../models/print-documents-request';
@Injectable({
  providedIn: 'root',
})
class DocumentsService extends __BaseService {
  static readonly DocumentPath = '/api/v1/Documents';
  static readonly AddDocumentPath = '/api/v1/Documents';
  static readonly RemoveManyPath = '/api/v1/Documents';
  static readonly AddToVisitPath = '/api/v1/visits/{id}/documents';
  static readonly AddToConsultationPath = '/api/consultations/{id}/documents';
  static readonly DeleteDocumentPath = '/api/v1/Documents/{id}';
  static readonly PrintManyPath = '/api/v1/Documents/print';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DocumentsService.DocumentParams` containing the following parameters:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   */
  DocumentResponse(params: DocumentsService.DocumentParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    if (params.Expires != null) __params = __params.set('Expires', params.Expires.toString());
    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DocumentsService.DocumentParams` containing the following parameters:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   */
  Document(params: DocumentsService.DocumentParams): __Observable<null> {
    return this.DocumentResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param file undefined
   * @return Success
   */
  AddDocumentResponse(file?: Blob): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (file != null) { __formData.append('file', file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * @param file undefined
   * @return Success
   */
  AddDocument(file?: Blob): __Observable<Document> {
    return this.AddDocumentResponse(file).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  RemoveManyResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<RemoveDocumentsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoveDocumentsResponse>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  RemoveMany(Id?: Array<number>): __Observable<RemoveDocumentsResponse> {
    return this.RemoveManyResponse(Id).pipe(
      __map(_r => _r.body as RemoveDocumentsResponse)
    );
  }

  /**
   * @param params The `DocumentsService.AddToVisitParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * @return Success
   */
  AddToVisitResponse(params: DocumentsService.AddToVisitParams): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/visits/${params.id}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * @param params The `DocumentsService.AddToVisitParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * @return Success
   */
  AddToVisit(params: DocumentsService.AddToVisitParams): __Observable<Document> {
    return this.AddToVisitResponse(params).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * @param params The `DocumentsService.AddToConsultationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * @return Success
   */
  AddToConsultationResponse(params: DocumentsService.AddToConsultationParams): __Observable<__StrictHttpResponse<Document>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.file != null) { __formData.append('file', params.file as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/consultations/${params.id}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Document>;
      })
    );
  }
  /**
   * @param params The `DocumentsService.AddToConsultationParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `file`:
   *
   * @return Success
   */
  AddToConsultation(params: DocumentsService.AddToConsultationParams): __Observable<Document> {
    return this.AddToConsultationResponse(params).pipe(
      __map(_r => _r.body as Document)
    );
  }

  /**
   * @param id undefined
   */
  DeleteDocumentResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/Documents/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeleteDocument(id: string): __Observable<null> {
    return this.DeleteDocumentResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  PrintManyResponse(request?: PrintDocumentsRequest): __Observable<__StrictHttpResponse<PrintDocumentsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Documents/print`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintDocumentsResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  PrintMany(request?: PrintDocumentsRequest): __Observable<PrintDocumentsResponse> {
    return this.PrintManyResponse(request).pipe(
      __map(_r => _r.body as PrintDocumentsResponse)
    );
  }
}

module DocumentsService {

  /**
   * Parameters for Document
   */
  export interface DocumentParams {
    Token?: string;
    Expires?: number;
    Download?: boolean;
  }

  /**
   * Parameters for AddToVisit
   */
  export interface AddToVisitParams {
    id: number;
    file?: Blob;
  }

  /**
   * Parameters for AddToConsultation
   */
  export interface AddToConsultationParams {
    id: number;
    file?: Blob;
  }
}

export { DocumentsService }
