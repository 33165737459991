import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mp-manage-phone-number-modal',
  templateUrl: './manage-phone-number.modal.component.html',
  styleUrls: ['./manage-phone-number.modal.component.scss']
})
export class ManagePhoneNumberModalComponent implements OnInit {

  @Input() phone: string;

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<void> = new EventEmitter<void>();

  title = "Удаление телефонного номера";

  get formatted(): string {
    if (!this.phone || this.phone.length !== 11) return '';

    return `+7 (${this.phone.substr(1, 3)}) ${this.phone.substr(4, 3)}-${this.phone.substr(7, 2)}-${this.phone.substr(9, 2)}`;
  }

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onConfirm.emit();
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
