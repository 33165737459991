/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PlannedVisit } from '../models/planned-visit';
@Injectable({
  providedIn: 'root',
})
class PlannedVisitsService extends __BaseService {
  static readonly PlannedVisitsAsyncPath = '/api/v1/visits/planned';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PlannedVisitsService.PlannedVisitsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  PlannedVisitsAsyncResponse(params: PlannedVisitsService.PlannedVisitsAsyncParams): __Observable<__StrictHttpResponse<Array<PlannedVisit>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.To != null) __params = __params.set('To', params.To.toString());
    (params.SpecialityIds || []).forEach(val => {if (val != null) __params = __params.append('SpecialityIds', val.toString())});
    if (params.From != null) __params = __params.set('From', params.From.toString());
    (params.DoctorIds || []).forEach(val => {if (val != null) __params = __params.append('DoctorIds', val.toString())});
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/visits/planned`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PlannedVisit>>;
      })
    );
  }
  /**
   * @param params The `PlannedVisitsService.PlannedVisitsAsyncParams` containing the following parameters:
   *
   * - `To`:
   *
   * - `SpecialityIds`:
   *
   * - `From`:
   *
   * - `DoctorIds`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  PlannedVisitsAsync(params: PlannedVisitsService.PlannedVisitsAsyncParams): __Observable<Array<PlannedVisit>> {
    return this.PlannedVisitsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<PlannedVisit>)
    );
  }
}

module PlannedVisitsService {

  /**
   * Parameters for PlannedVisitsAsync
   */
  export interface PlannedVisitsAsyncParams {
    To?: string;
    SpecialityIds?: Array<number>;
    From?: string;
    DoctorIds?: Array<number>;
    CompanyIds?: Array<number>;
  }
}

export { PlannedVisitsService }
