import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecordReviewDescription, RecordReviewRate, RecordSection } from '../../../../generated/models';
import { ReviewResult } from '../revision/revision.component';

@Component({
  selector: 'mp-section-revision',
  templateUrl: './section-revision.component.html',
  styleUrls: ['./section-revision.component.scss']
})
export class SectionRevisionComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() result: ReviewResult = {};
  @Input() descriptions: RecordReviewDescription = {};

  @Input() rates: RecordReviewRate[] = [];

  @Input() section: RecordSection;
  @Input() index: number;

  @Output()
  onRated: EventEmitter<ReviewResult> = new EventEmitter<ReviewResult>();

  constructor() {
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  get diagnosisType(): string {
    if (!this.section) {
      return "";
    }

    switch (this.section.diagnosisType) {
      case 0: return "Предварительный";
      case 1: return "Основной";
      case 3: return "Сопутствующий";
      case 2: return "Осложнение";

      default: return "";
    }
  }

  get sectionType(): string {
    if (!this.section) return "";

    switch (this.section.type) {
      case 1: return "Текстовая секция";
      case 2: return "Секция диагноза";
      case 3: return "Секция назначений";
      case 4: return "Секция даты";
      default: return "";
    }
  }

  get sectionOptions(): string {
    if (!this.section) return "";

    const parts = [];

    if (this.section.mandatory) {
      parts.push('Обязательная');
    }

    if (this.section.includeInExtract) {
      parts.push('Включена в выписку');
    }

    return parts.join(', ');
  }
}
