import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CodeSystem } from '../../../../generated/models';

@Component({
  selector: 'mp-code-system-modal',
  templateUrl: './code-system-modal.component.html',
  styleUrls: ['./code-system-modal.component.scss']
})
export class CodeSystemModalComponent implements OnInit {

  title = "Код сотрудника";

  @Input() codeSystems: CodeSystem[] = [];

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onConfirm: EventEmitter<CodeSystemModalPaylod> = new EventEmitter<CodeSystemModalPaylod>();

  form: FormGroup;

  constructor() {
    this.form = new FormGroup({
      codeSystem: new FormControl(0, [Validators.required]),
      value: new FormControl("", [Validators.required])
    })
  }

  ngOnInit() { }

  public cancel(): void {
    this.onCancel.emit();
  }

  public submit(): void {
    this.form.get('codeSystem').markAsTouched();
    this.form.get('value').markAsTouched();

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onConfirm.emit({
      codeSystem: value.codeSystem,
      value: value.value
    });
  }

}

interface FormValue {
  codeSystem: number;
  value: string;
}

export interface CodeSystemModalPaylod {
  codeSystem: number;
  value: string;
}
