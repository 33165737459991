import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhoneNumbersComponent } from './components/phone-numbers/phone-numbers.component';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls'
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { PhoneNumberResolver } from './resolver/phone-number.resolver';
import { ManagePhoneNumberModalComponent } from './components/manage-phone-number.modal/manage-phone-number.modal.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { PermissionNames } from '../../models/permission-names';
import { PhoneNumbersResolver } from './resolver/phone-numbers.resolver';
import { PhoneNumberItemComponent } from './components/phone-number-item/phone-number-item.component';

const routes: Routes = [
  {
    path: "",
    component: PhoneNumbersComponent,
    data: {
      title: "Телефонные номера",
      requirements: [PermissionNames.ManagePhoneNumbers]
    },
    resolve: {
      payload: PhoneNumbersResolver
    }
  },
  {
    path: "new",
    component: PhoneNumberComponent,
    data: {
      title: "Новый телефонный номер",
      requirements: [PermissionNames.ManagePhoneNumbers]
    },
    resolve: {
      payload: PhoneNumberResolver
    }
  },
  {
    path: ":id",
    component: PhoneNumberComponent,
    data: {
      title: "Телефонный номер",
      requirements: [PermissionNames.ManagePhoneNumbers]
    },
    resolve: {
      payload: PhoneNumberResolver
    }
  }
]

@NgModule({
  declarations: [
    PhoneNumbersComponent,
    PhoneNumberComponent,
    ManagePhoneNumberModalComponent,
    PhoneNumberItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgbModalModule,
    NgxMaskModule.forRoot({}),

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManagePhoneNumberModalComponent
  ]
})
export class PhoneNumbersSettingsModule { }
