import { Component, OnInit, Input } from '@angular/core';
import { Speciality } from 'projects/Clinic/src/app/generated/models/speciality';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService, QuestionnairesService } from 'projects/Clinic/src/app/generated/services';
import { EmployeeSpeciality } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-questionnaire-option-modal',
  templateUrl: './questionnaire-option.modal.component.html',
  styleUrls: ['./questionnaire-option.modal.component.scss']
})
export class QuestionnaireOptionModalComponent implements OnInit {
  //public name: string;

  @Input("questionnaireId") questionnaireId: number;
  @Input("mode") mode: number;
  @Input("optionId") questionId: number;
  @Input("title") title: string;
  @Input("questionNumber") questionNumber: number;
  @Input("number") number: number;
  @Input("weight") weight: number;

  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private questionnairesService: QuestionnairesService
  ) {
    // this.name = "Новый вопрос";

    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      questionNumber: new FormControl("", [Validators.required]),
      number: new FormControl("", [Validators.required]),
      weight: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit() {
    this.form.controls["name"].setValue(this.title);
    this.form.controls["questionNumber"].setValue(this.questionNumber);
    this.form.controls["number"].setValue(this.number);
    this.form.controls["weight"].setValue(this.weight);
  }

  public submit(): void {
    if (this.form.invalid) {
      return;
    }
    this.questionnairesService.AddOption({
      questionnaireId: this.questionnaireId,
      model: {
        title: this.form.get("name").value,
        number: this.form.get("number").value,
        questionNumber: this.form.get("questionNumber").value,
        weight: this.form.get("weight").value
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public edit(): void {
    if (this.form.invalid) {
      return;
    }
    this.questionnairesService.EditOption({
      questionnaireId: this.questionnaireId,
      model: {
        id: this.questionId,
        title: this.form.get("name").value,
        number: this.form.get("number").value,
        questionNumber: this.form.get("questionNumber").value,
        weight: this.form.get("weight").value
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public delete(): void {
    this.questionnairesService.DeleteOption({
      questionnaireId: this.questionnaireId,
      model: {
        id: this.questionId
      }
    })
      .subscribe(
        (result: any): void => {
          this.activeModal.close(result);
        });
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
