import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Manipulation, Speciality } from '../../../../generated/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ManipulationsResolverPayload } from '../../resolvers/manipulations-resolver';
import { Observable, Subject, from } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ManipulationsService } from '../../../../generated/services';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-manipulations',
  templateUrl: './manipulations.component.html',
  styleUrls: ['./manipulations.component.scss'],
  host: { class: "page" }
})
export class ManipulationsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  specialities: Speciality[] = [];
  items: Manipulation[] = [];

  loading = false;
  page = 1;
  size = 100;

  filters = new FormGroup({
    search: new FormControl(''),
    specialityId: new FormControl(null),
    emptyOnly: new FormControl(false)
  });

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private manipulationsService: ManipulationsService,
    private confirmationService: ConfirmationModalService
  ) {

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FilterValues) => {
          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          if (value.specialityId >= 0) {
            queryParams.speciality = value.specialityId;
          }

          if (value.emptyOnly) {
            queryParams.emptyOnly = true;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
        tap(() => {
          this.loading = true;
          this.page = 1;
          this.items = [];
        }),
        switchMap((value: FilterValues): Observable<Manipulation[]> => {
          return this.manipulationsService.ManipulationsAsync({
            Page: this.page,
            Size: this.size,
            Search: value.search,
            EmptyOnly: value.emptyOnly,
            SpecialityId: value.specialityId
          }).pipe(catchError((): Observable<Manipulation[]> => {
            return from([]);
          }));
        })
      )
      .subscribe((result: Manipulation[]) => {
        this.items = result;
        this.loading = false;
      });

  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.queryParamMap;

    const value: FilterValues = {
      search: '',
      specialityId: null,
      emptyOnly: false
    };

    if (params.has('search')) {
      value.search = params.get('search');
    }

    if (params.has('speciality'))
      value.specialityId = parseInt(params.get('speciality'));

    value.emptyOnly = params.has('emptyOnly') && params.get('emptyOnly') === 'true';

    this.filters.patchValue(value);

    this.activatedRoute.data.subscribe((data: { payload: ManipulationsResolverPayload }) => {
      this.specialities = data.payload.specialities;

    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  add = () => this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  edit = (item: Manipulation) => this.router.navigate([`${item.id}`], { relativeTo: this.activatedRoute });

  async remove(item: Manipulation) {
    const confirmed = await this.confirmationService.open({ message: `Назначение '${item.name}' будет удалено. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.manipulationsService.RemoveManipulationAsync(item.id)
      .subscribe(
        async () => {
          this.toastrService.success('Назначение удалено', 'Успешно');

          this.page = 1;
          this.loading = true;

          this.items = await this.load();
          this.loading = false;
        },
        (response: HttpErrorResponse) => {
          if (response.status === 400 && response.error && response.error.errors) {
            for (const error of response.error.errors) {
              switch (error.status) {
                case 1: this.toastrService.warning('Необходимо указать название назначения', 'Ошибка'); break;
                case 2: this.toastrService.warning('Назначение не найдено', 'Ошибка'); break;
                case 3: this.toastrService.warning('Специальность не найдена', 'Ошибка'); break;
                case 4: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;
                case 5: this.toastrService.warning('Назначение связано с услугами. Удалите привязки и повторите попытку', 'Ошибка'); break;

                default: this.toastrService.warning('Не удалось добавить назначение', 'Ошибка'); break;
              }
            }

            return;
          }

          this.toastrService.error('Не удалось удалить назначение', 'Ошибка');
        }
      );
  }

  async onScrollDown() {
    this.page += 1;
    this.loading = true;

    const items = await this.load();

    this.items.push(...items);
    this.loading = false;
  }

  async load() {
    const value: FilterValues = this.filters.getRawValue();

    return this.manipulationsService.ManipulationsAsync({
      Page: this.page,
      Size: this.size,
      Search: value.search,
      SpecialityId: value.specialityId,
      EmptyOnly: value.emptyOnly
    }).toPromise();
  }
}

interface FilterValues {
  search: string;
  specialityId: number;
  emptyOnly: boolean;
}
