import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DocumentsService } from 'projects/Clinic/src/app/generated/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'mp-document-preview-modal',
  templateUrl: './document-preview-modal.component.html',
  styleUrls: ['./document-preview-modal.component.scss']
})
export class DocumentPreviewModalComponent implements OnInit {

  @Input() path: string;
  @Input() canPrint = false;

  url: SafeResourceUrl;

  @ViewChild("Content") content: ElementRef<HTMLFrameElement>;

  constructor(
    private _modal: NgbActiveModal,
    private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.path);
  }

  print(): void {
    this.content.nativeElement.contentWindow.print();
  }

  close = (): void => {
    this._modal.close();
  }
}
