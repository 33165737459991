import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Record } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';

@Component({
  selector: 'mp-record-list-item',
  templateUrl: './record-list-item.component.html',
  styleUrls: ['./record-list-item.component.scss']
})
export class RecordListItemComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  @Input() canReview = false;
  @Input() canRemoveSigned = false;

  @Input() item: Record;

  @Input()
  public set checked(value: boolean) {
    this.checkbox.setValue(value, { emitEvent: false });
  }

  @Output()
  onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  onEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onPrint: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDownload: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDownloadArchive: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onReviews: EventEmitter<void> = new EventEmitter<void>();

  public checkbox: FormControl;

  constructor(
    private modalService: NgbModal
  ) {
    this.checkbox = new FormControl(false);

    this.checkbox.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: boolean): void => {
          this.onToggle.emit(value);
        }
      );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  edit(): void {
    this.onEdit.emit();
  }

  print(): void {
    this.onPrint.emit()
  }

  downloadArchive(): void {
    this.onDownloadArchive.emit();
  }

  download(): void {
    this.onDownload.emit()
  }

  reviews(): void {
    this.onReviews.emit();
  }

  delete(): void {
    if (this.item.signed && !this.canRemoveSigned) return;

    const options: NgbModalOptions = {
      backdrop: 'static',
      size: 'sm',
      centered: true,
      windowClass: "modal-confirm"
    };

    const modalRef: NgbModalRef = this.modalService.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = "Удалить запись из списка?";

    modalRef.result
      .then(
        () => {
          this.onDelete.emit()
        },
        () => { }
      );
  }
}
