import { EditQuestionnaireModel, QuestionnaireResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { QuestionnairesService } from '../../../generated/services/questionnaires.service';

@Injectable({ providedIn: "root" })
export class QuestionnaireResolver implements Resolve<QuestionnaireResolverPayload> {

  public constructor(
    private service: QuestionnairesService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QuestionnaireResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let questionnaireLoader: Observable<EditQuestionnaireModel> = null;

    if (id) {
      questionnaireLoader = this.service.Get(id);
    } else {
      const questionnaire: any = {};

      questionnaireLoader = from([questionnaire]);
    }

    return forkJoin({
      questionnaire: questionnaireLoader,
    });
  }
}

export class QuestionnaireResolverPayload {
  public questionnaire: QuestionnaireResponse;

  public constructor() {
  }
}
