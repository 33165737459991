import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceGroupComponent } from './components/service-group/service-group.component';
import { ServiceGroupsComponent } from './components/service-groups/service-groups.component';
import { ServiceGroupItemComponent } from './components/service-group-item/service-group-item.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { SharedModule } from '../shared/shared.module';
import { PermissionNames } from '../../models/permission-names';
import { ServiceModalComponent } from './components/service-modal/service-modal.component';
import { ServiceGroupServiceItemComponent } from './components/service-group-service-item/service-group-service-item.component';
import { ServiceGroupsResolver } from './resolvers/service-groups-resolver';
import { ServiceGroupResolver } from './resolvers/service-group-resolver';
import { PricesModalComponent } from './components/prices-modal/prices-modal.component';

const routes: Routes = [
  {
    path: '',
    component: ServiceGroupsComponent,
    data: {
      title: "Пакеты услуг",
      requirements: [PermissionNames.ManagerServices]
    },
    resolve: {
      payload: ServiceGroupsResolver
    }
  },
  {
    path: 'new',
    component: ServiceGroupComponent,
    data: {
      title: "Новый пакет",
      requirements: [PermissionNames.ManagerServices]
    },
    resolve: {
      payload: ServiceGroupResolver
    }
  },
  {
    path: ':id',
    component: ServiceGroupComponent,
    data: {
      title: "Пакет",
      requirements: [PermissionNames.ManagerServices]
    },
    resolve: {
      payload: ServiceGroupResolver
    }
  }
];

@NgModule({
  declarations: [ServiceGroupComponent, ServiceGroupsComponent, ServiceGroupItemComponent, ServiceModalComponent, ServiceGroupServiceItemComponent, PricesModalComponent],
  entryComponents: [ServiceModalComponent, PricesModalComponent],
  exports: [RouterModule],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule,
    SharedModule
  ]
})
export class ServiceGroupSettingsModule { }
