import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiagnosisNode } from '../../../../generated/models';

@Component({
  selector: 'mp-diagnosis-item',
  templateUrl: './diagnosis-item.component.html',
  styleUrls: ['./diagnosis-item.component.scss']
})
export class DiagnosisItemComponent implements OnInit {

  @Input() item: DiagnosisNode = {};

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();

}
