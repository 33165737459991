import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonDiagnosis } from '../../../../generated/models';

@Component({
  selector: 'mp-diagnosis-list-item',
  templateUrl: './diagnosis-list-item.component.html',
  styleUrls: ['./diagnosis-list-item.component.scss']
})
export class DiagnosisListItemComponent implements OnInit {

  @Input() forbidden = false;

  @Input() item: PersonDiagnosis;

  @Output() onOpen = new EventEmitter<void>();

  get className(): string {
    switch (this.item.type) {
      case 0: return "text-muted";
      case 1: return "text-primary";
      case 3: return "text-info";
      case 2: return "text-warning";
      default: return "";
    }
  }

  get typeName(): string {
    switch (this.item.type) {
      case 0: return "Предварительный";
      case 1: return "Основной";
      case 3: return "Сопутствующий";
      case 2: return "Осложнение";
      default: return "";
    }
  }

  constructor() { }

  ngOnInit() { }

  open = () => this.onOpen.emit();
}
