import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceGroupResolverPayload } from '../../resolvers/service-group-resolver';
import { PriceSetsService, ServiceGroupsService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Category, CreateServiceGroupResponse, Service, ServiceGroup, ServiceGroupService } from '../../../../generated/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceModalComponent } from '../service-modal/service-modal.component';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';
import { PricesModalComponent } from '../prices-modal/prices-modal.component';
import { title } from 'process';

@Component({
  selector: 'mp-service-group',
  templateUrl: './service-group.component.html',
  styleUrls: ['./service-group.component.scss'],
  host: { class: "page" }
})
export class ServiceGroupComponent implements OnInit {

  group: ServiceGroup = {};

  id: number = undefined;

  title = 'Новый пакет';

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    discount: new FormControl(),
    roundingType: new FormControl(0),
    categoryId: new FormControl(null),
    isArchival: new FormControl(false),
    isShowcased: new FormControl(false)
  });

  services: Service[] = [];
  categories: Category[] = [];
  serviceGroupServices: ServiceGroupService[] = [];

  servicesLoading = true;
  servicesDisabled = true;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private modal: NgbModal,
    private confirmationsService: ConfirmationModalService,
    private serviceGroupsService: ServiceGroupsService,
    private priceSetsService: PriceSetsService
  ) { }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: ServiceGroupResolverPayload }) => {
      this.id = data.payload.serviceGroup.id;

      this.group = data.payload.serviceGroup;

      this.form.patchValue(data.payload.serviceGroup);

      this.services = data.payload.services;
      this.categories = data.payload.categories;

      if (this.id > 0) {
        this.title = `Пакет «${data.payload.serviceGroup.name}»`;
        this.servicesDisabled = false;

        this.loadServices();
      }

    });
  }

  errorMessage(code: string, message: string) {

    switch (code) {

      case 'NameRequired': return 'Необходимо указать имя пакета';
      case 'RoundingTypeInvalid': return 'Неизвестный тип округления';
      case 'PermissionRequired': return 'Для выполнения операции отсутствует необходимое разрешение';
      case 'ServiceGroupNotFound': return 'Пакет не найдена';
      case 'ServiceAlreadyAdded': return 'Услуга уже добавлена в пакет';
      case 'ServiceNotFound': return 'Услуга не найдена';
      case 'CategoryNotFound': return 'Категория не найдена';
      case 'ServiceGroupServiceNotFound': return 'Услуга не найдена в данном пакете';

      default: return message;
    }
  }

  handleError(response: HttpErrorResponse, message: string) {
    if (response.status === 400 && response.error && response.error.errors) {

      for (const error of response.error.errors) {
        this.toastrService.warning(this.errorMessage(error.errorCode, message), 'Ошибка');
      }

      return;
    }

    if (response.status === 400 && response.error && Array.isArray(response.error)) {
      for (const error in response.error) {
        this.toastrService.warning(this.errorMessage(error, message), 'Ошибка');
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }

  acceptChanges() {
    Object.entries(this.form.controls).forEach(x => x[1].markAsTouched());

    if (!this.form.valid) return;

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      this.serviceGroupsService.UpdateServiceGroupAsync({ id: this.id, request: value }).subscribe(
        () => {
          this.toastrService.success(`Пакет услуг «${value.name}» обновлен`, 'Успешно');

          this.title = `Пакет «${value.name}»`;
          this.group = { ...this.group, ...value };

        },
        (response: HttpErrorResponse) => this.handleError(response, 'Не удалось обновить пакет услуг')
      );

    }
    else {
      this.serviceGroupsService.CreateServiceGroupAsync(value).subscribe(
        (response: CreateServiceGroupResponse) => {
          this.toastrService.success(`Пакет услуг	«${value.name}» добавлен`, 'Успешно');

          this.router.navigate(['..', response.id.toString()], { relativeTo: this.activatedRoute });

        },
        (response: HttpErrorResponse) => this.handleError(response, 'Не удалось создать пакет услуг')
      );

    }

  }

  async loadServices() {
    this.servicesLoading = true;

    try {
      this.serviceGroupServices = await this.serviceGroupsService.ServiceGroupServicesAsync(this.id).toPromise();
    }
    catch (e) {
      const response = e as HttpErrorResponse;

      this.toastrService.error(`Не удалось загрузить услуги`, 'Ошибка');
    }

    this.servicesLoading = false;
  }

  addService() {
    const modalRef = this.modal.open(ServiceModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
    const componentRef: ServiceModalComponent = modalRef.componentInstance;

    componentRef.services = this.services.filter(x => !this.serviceGroupServices.some(s => s.serviceId === x.id));

    componentRef.onCancel.subscribe(() => modalRef.close());

    componentRef.onConfirm.subscribe((service: Service) => {
      this.serviceGroupsService.AddServiceIntoServiceGroupAsync({ id: this.id, request: { serviceId: service.id } })
        .subscribe(
          () => {
            this.toastrService.success(`Услуга	«${service.name}» добавлена в пакет`, 'Успешно');

            this.loadServices();

            modalRef.close();
          },
          (response: HttpErrorResponse) => this.handleError(response, `Не удалось добавить услугу	«${service.name}» в пакет`)
        );
    });
  }

  async removeService(service: ServiceGroupService) {
    const confirmed = await this.confirmationsService.open({ message: `Услуга	«${service.serviceName}» будет исключена из пакета. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.serviceGroupsService.RemoveServiceFromServiceGroup({ id: this.id, serviceId: service.serviceId })
      .subscribe(
        () => {
          this.toastrService.success(`Услуга «${service.serviceName}» исключена из пакета`, 'Успешно');

          this.loadServices();
        },
        (response: HttpErrorResponse) => this.handleError(response, `Не удалось исключить услугу «${service.serviceName}» из пакета`)
      );

  }

  async prices() {
    const priceSetsResponse = await this.priceSetsService.PriceSets({ IncludeCommon: true, Size: 0, Page: 1 }).toPromise();

    const modalRef = this.modal.open(PricesModalComponent, { centered: true, size: 'lg', backdrop: 'static' });
    const componentRef: PricesModalComponent = modalRef.componentInstance;

    componentRef.priceSets = priceSetsResponse.priceSets;
    componentRef.serviceGroupServices = this.serviceGroupServices;
    componentRef.group = this.group;

    componentRef.onCancel.subscribe(() => modalRef.close());
  }

}

interface FormValue {
  name: string;
  discount?: number;
  roundingType: 0 | 1;
  categoryId: number;
}
