import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { DiagnosisRecommendation, RecommendedManipulation } from '../../../../generated/models';

@Component({
  selector: 'mp-recommendation-item',
  templateUrl: './recommendation-item.component.html',
  styleUrls: ['./recommendation-item.component.scss']
})
export class RecommendationItemComponent implements OnInit {

  @Input() item: DiagnosisRecommendation = {};
  @Input() manipulations: RecommendedManipulation[] = [];

  @HostBinding('class.expanded') expanded = false;

  @Output() onRemoveRecommendation = new EventEmitter<void>();
  @Output() onEditRecommendation = new EventEmitter<void>();
  @Output() onAddManipulation = new EventEmitter<void>();

  @Output() onOpenDocument = new EventEmitter<RecommendedManipulation>();
  @Output() onEditManipulation = new EventEmitter<RecommendedManipulation>();
  @Output() onRemoveManipulation = new EventEmitter<RecommendedManipulation>();

  get expandTitle() { return this.expanded ? 'Скрыть назначения' : 'Показать назначения'; }

  constructor() { }

  ngOnInit() { }

  expand = () => this.expanded = !this.expanded;

  editRecommendation = () => this.onEditRecommendation.emit();
  removeRecommendation = () => this.onRemoveRecommendation.emit();
  addManipulation = () => this.onAddManipulation.emit();

  openDocument = (target: RecommendedManipulation) => this.onOpenDocument.emit(target);
  editManipulation = (target: RecommendedManipulation) => this.onEditManipulation.emit(target);
  removeManipulation = (target: RecommendedManipulation) => this.onRemoveManipulation.emit(target);
}
