/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DiagnosisRecommendation } from '../models/diagnosis-recommendation';
import { CreateDiagnosisRecommendationResponse } from '../models/create-diagnosis-recommendation-response';
import { CreateDiagnosisRecommendationRequest } from '../models/create-diagnosis-recommendation-request';
import { UpdateDiagnosisRecommendationRequest } from '../models/update-diagnosis-recommendation-request';
@Injectable({
  providedIn: 'root',
})
class DiagnosisRecommendationsService extends __BaseService {
  static readonly DiagnosisRecommendationsAsyncPath = '/api/diagnoses/{code}/recommendations';
  static readonly CreateDiagnosisRecommendationAsyncPath = '/api/diagnoses/{code}/recommendations';
  static readonly UpdateDiagnosisRecommendationAsyncPath = '/api/diagnoses/recommendations/{id}';
  static readonly RemoveDiagnosisRecommendationAsyncPath = '/api/diagnoses/recommendations/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param code undefined
   * @return Success
   */
  DiagnosisRecommendationsAsyncResponse(code: string): __Observable<__StrictHttpResponse<Array<DiagnosisRecommendation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/diagnoses/${code}/recommendations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiagnosisRecommendation>>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  DiagnosisRecommendationsAsync(code: string): __Observable<Array<DiagnosisRecommendation>> {
    return this.DiagnosisRecommendationsAsyncResponse(code).pipe(
      __map(_r => _r.body as Array<DiagnosisRecommendation>)
    );
  }

  /**
   * @param params The `DiagnosisRecommendationsService.CreateDiagnosisRecommendationAsyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateDiagnosisRecommendationAsyncResponse(params: DiagnosisRecommendationsService.CreateDiagnosisRecommendationAsyncParams): __Observable<__StrictHttpResponse<CreateDiagnosisRecommendationResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/diagnoses/${params.code}/recommendations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateDiagnosisRecommendationResponse>;
      })
    );
  }
  /**
   * @param params The `DiagnosisRecommendationsService.CreateDiagnosisRecommendationAsyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateDiagnosisRecommendationAsync(params: DiagnosisRecommendationsService.CreateDiagnosisRecommendationAsyncParams): __Observable<CreateDiagnosisRecommendationResponse> {
    return this.CreateDiagnosisRecommendationAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateDiagnosisRecommendationResponse)
    );
  }

  /**
   * @param params The `DiagnosisRecommendationsService.UpdateDiagnosisRecommendationAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateDiagnosisRecommendationAsyncResponse(params: DiagnosisRecommendationsService.UpdateDiagnosisRecommendationAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/diagnoses/recommendations/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `DiagnosisRecommendationsService.UpdateDiagnosisRecommendationAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateDiagnosisRecommendationAsync(params: DiagnosisRecommendationsService.UpdateDiagnosisRecommendationAsyncParams): __Observable<null> {
    return this.UpdateDiagnosisRecommendationAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveDiagnosisRecommendationAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/diagnoses/recommendations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveDiagnosisRecommendationAsync(id: number): __Observable<null> {
    return this.RemoveDiagnosisRecommendationAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DiagnosisRecommendationsService {

  /**
   * Parameters for CreateDiagnosisRecommendationAsync
   */
  export interface CreateDiagnosisRecommendationAsyncParams {
    code: string;
    request?: CreateDiagnosisRecommendationRequest;
  }

  /**
   * Parameters for UpdateDiagnosisRecommendationAsync
   */
  export interface UpdateDiagnosisRecommendationAsyncParams {
    id: number;
    request?: UpdateDiagnosisRecommendationRequest;
  }
}

export { DiagnosisRecommendationsService }
