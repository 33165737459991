import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import * as moment from "moment";

@Component({
  selector: 'mp-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  @Input() option: VisitOption;

  @Output()
  onChange = new EventEmitter<string>();

  textControl = new FormControl();
  numberControl = new FormControl();
  dateControl = new FormControl();
  selectControl = new FormControl();
  checkboxControl = new FormControl();

  constructor() {
    this.textControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400))
      .subscribe((value: string) => this.onChange.emit(value));

    this.numberControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400))
      .subscribe((value: number) => this.onChange.emit(value !== null && value !== undefined ? `${value}` : ""));

    this.dateControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: moment.Moment): void => {
          if (value && value.isValid()) {
            this.onChange.emit(value.format("DD.MM.YYYY"));
          } else {
            this.onChange.emit("");
          }
        }
      );

    this.selectControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => this.onChange.emit(value));

    this.checkboxControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => this.onChange.emit(value ? 'Да' : 'Нет'));
  }

  ngOnInit() {
    switch (this.option.type) {
      case 1: this.textControl.setValue(this.option.value); break;
      case 2: this.numberControl.setValue(this.option.value); break;
      case 3: {
        if (this.option.value) {
          const value = moment(this.option.value, "DD.MM.YYYY");
          if (value && value.isValid()) this.dateControl.setValue(value);
        }
      } break;
      case 4: this.checkboxControl.setValue(this.option.value === 'Да'); break;
      case 5: this.selectControl.setValue(this.option.value); break;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}


export interface VisitOption {
  tag: string;
  name: string;
  type: 0 | 1 | 2 | 3 | 4 | 5;
  value: string;
  items: string[];
}
