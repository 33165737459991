/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RecommendedManipulation } from '../models/recommended-manipulation';
@Injectable({
  providedIn: 'root',
})
class RecommendedManipulationsService extends __BaseService {
  static readonly RecommendedManipulationsAsyncPath = '/api/v2/diagnosis/{code}/manipulations';
  static readonly RemoveRecommendedManipulationAsyncPath = '/api/v2/diagnoses/recommendations/manipulations/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param code undefined
   * @return Success
   */
  RecommendedManipulationsAsyncResponse(code: string): __Observable<__StrictHttpResponse<Array<RecommendedManipulation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnosis/${code}/manipulations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecommendedManipulation>>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  RecommendedManipulationsAsync(code: string): __Observable<Array<RecommendedManipulation>> {
    return this.RecommendedManipulationsAsyncResponse(code).pipe(
      __map(_r => _r.body as Array<RecommendedManipulation>)
    );
  }

  /**
   * @param id undefined
   */
  RemoveRecommendedManipulationAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/diagnoses/recommendations/manipulations/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveRecommendedManipulationAsync(id: number): __Observable<null> {
    return this.RemoveRecommendedManipulationAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module RecommendedManipulationsService {
}

export { RecommendedManipulationsService }
