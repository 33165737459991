import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Subject, Observable, of, OperatorFunction, from } from 'rxjs';
import { TemplateSection, DiagnosesNormalizationResponse, DiagnosesCodesResponse, DiagnosisCode, Icd10ResponseItem } from '../../../../generated/models';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil, distinctUntilChanged, debounceTime, tap, switchMap, filter, map } from 'rxjs/operators';
import { DiagnosisCodeValidator } from '../../validators/diagnosis-code-validator';
import { DiagnosesService } from '../../../../generated/services';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-diagnosis-section',
  templateUrl: './diagnosis-section.component.html',
  styleUrls: ['./diagnosis-section.component.scss']
})
export class DiagnosisSectionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  loading = false;
  codesLoading = false;
  showCodes = false;

  codes: DiagnosisCode[] = [];

  normolized = "";

  @Input() disabled = false;
  @Input() section: TemplateSection = {};

  @Output() onTypeChanged = new EventEmitter<0 | 1 | 2 | 3>();
  @Output() onCodesChanged = new EventEmitter<string>();

  @Output() onDiagnosisChanged = new EventEmitter<{ code: string, name: string }>();

  types: DiagnosisType[] = [
    { id: 0, text: "Предварительный" },
    { id: 1, text: "Основной" },
    { id: 2, text: "Осложнение" },
    { id: 3, text: "Сопутствующий" }
  ];

  typeControl: FormControl = new FormControl(undefined, [Validators.required]);
  codesControl: FormControl = new FormControl("", [DiagnosisCodeValidator.validator]);

  diagnosisSearchControl = new FormControl("", []);

  diagnosisForm = new FormGroup({
    code: new FormControl('', [Validators.required]),
    name: new FormControl('', [Validators.required])
  });

  get hasResults() { return this.codes.length > 0; }

  get diagnosisCode(): string { return this.diagnosisForm.get('code').value; }

  constructor(private diagnosesService: DiagnosesService, private toastrService: ToastrService) {
    this.codesControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged((x, y) => x === y),
        tap(() => {
          this.normolized = "";
          this.codes = [];
        }),
        filter(() => !this.codesControl.invalid),
        tap(() => this.loading = true),
        switchMap((value: string): Observable<DiagnosesNormalizationResponse> => value ? this.diagnosesService.NormolizeAsync({ codes: value }) : of({})),
        tap((response: DiagnosesNormalizationResponse) => {
          this.normolized = response.normolized;
          this.loading = false;
          this.codesLoading = true;
        }),
        tap((response: DiagnosesNormalizationResponse) => {
          this.onCodesChanged.emit(response.normolized);
        }),

        switchMap((response: DiagnosesNormalizationResponse): Observable<DiagnosesCodesResponse> => this.diagnosesService.CodesAsync({ codes: response.normolized })),
        tap((response: DiagnosesCodesResponse) => {
          this.codes = response.codes;
          this.codesLoading = false;
        })
      )
      .subscribe(
        () => { },
        () => {
          this.loading = false;
          this.codesLoading = false;

          this.toastrService.error("Не удалось загрузить расшифровку диапазона диагнозов", "Ошибка");
        }
      );

    this.diagnosisForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      ).subscribe((value: { name: string, code: string }) => {
        this.onDiagnosisChanged.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled) {
        this.typeControl.enabled ? this.typeControl.disable({ emitEvent: false }) : undefined;
        this.codesControl.enabled ? this.codesControl.disable({ emitEvent: false }) : undefined;
      } else {
        this.typeControl.disabled ? this.typeControl.enable({ emitEvent: false }) : undefined;
        this.codesControl.disabled ? this.codesControl.enable({ emitEvent: false }) : undefined;
      }
    }

    if (changes['section']) {
      const section: TemplateSection = changes['section'].currentValue;

      this.diagnosisForm.patchValue({ code: section.defaultDiagnosisCode, name: section.defaultDiagnosisName });
    }
  }

  ngOnInit() {
    this.typeControl.setValue(this.section.diagnosisType);
    this.codesControl.setValue(this.section.allowedDiagnoses);

    this.typeControl.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged((x, y) => x === y))
      .subscribe((value: 0 | 1 | 2 | 3) => this.onTypeChanged.emit(value));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  toggleCodesVisibility = () => this.showCodes = !this.showCodes;

  diagnosesSearch: OperatorFunction<string, Icd10ResponseItem[]> = (text$: Observable<string>) =>
    text$.pipe(
      takeUntil(this.destroy$),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(term => {
        if (!term || term.length < 3) return from([[]]);

        return this.diagnosesService.SearchAsync(term)
          .pipe(
            tap((result: Icd10ResponseItem[]) => {
              if (result.length === 1) {
                this.onDiagnosisChanged.emit({ name: result[0].name, code: result[0].code });
                this.diagnosisForm.patchValue({ name: result[0].name, code: result[0].code });
              }
            }),
            map((result: Icd10ResponseItem[]) => result.length > 10 ? result.slice(0, 10) : result)
          );
      })
    );

  formatter = (value: Icd10ResponseItem): string => value ? `${value.name}` : '';

  select = (value: { item: Icd10ResponseItem }) => {
    if (!value) return;

    this.onDiagnosisChanged.emit({ name: value.item.name, code: value.item.code });
    this.diagnosisForm.patchValue({ name: value.item.name, code: value.item.code }, { emitEvent: false });
    this.diagnosisSearchControl.setValue('');
  }

  clearDiagnosis() {
    this.onDiagnosisChanged.emit({ name: '', code: '' });
    this.diagnosisForm.patchValue({ name: '', code: '' }, { emitEvent: false });

    this.diagnosisSearchControl.setValue('');
  }
}

interface DiagnosisType {
  id: number;
  text: string;
}
