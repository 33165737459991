import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ReportPromotion } from '../../../../generated/models';

@Component({
  selector: 'mp-promotion-list-item',
  templateUrl: './promotion-list-item.component.html',
  styleUrls: ['./promotion-list-item.component.scss']
})
export class PromotionListItemComponent implements OnInit {

  @Input() item: ReportPromotion;
  @Input() selected: boolean;

  @Output()
  public onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }
  public toggle(): void {
    this.onToggle.emit(!this.selected);
  }
}
