import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MetricResolverPayload } from '../../resolvers/metric-resolver';
import { Observable, Subject, from } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CreateMetricResponse, Metric } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { MetricsService } from '../../../../generated/services';

@Component({
  selector: 'mp-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
  host: { class: "page" }
})
export class MetricComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  title = '';
  id: number = undefined;

  form = new FormGroup({
    systemName: new FormControl('', [Validators.minLength(3), Validators.pattern('^[a-z][a-z0-9_]{2,}$')], [SystemNameValidator.validator(this.metricsService)]),
    displayName: new FormControl('', [Validators.required]),
    description: new FormControl(''),
    units: new FormControl(''),
    formula: new FormControl('', [Validators.required]),
    format: new FormControl(null, [Validators.required])
  });

  get formulaRequired() { return this.form.get('format').value === 3; }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private metricsService: MetricsService
  ) {
    this.form.get('formula').disable({ emitEvent: false });

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const metric: Metric = this.form.getRawValue();
        if (metric.format === 3) {
          this.form.get('formula').enable({ emitEvent: false });
        } else {
          this.form.get('formula').disable({ emitEvent: false });
        }
      });

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: MetricResolverPayload }) => {

      this.id = data.payload.metric.id;

      if (this.id > 0) {
        this.form.get('systemName').disable({ emitEvent: false });
        this.form.get('format').disable({ emitEvent: false });
      }

      this.title = data.payload.metric.id > 0 ? `Показатель: ${data.payload.metric.displayName}` : 'Новый показатель';

      this.form.patchValue(data.payload.metric);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  create() {
    const value: Metric = this.form.getRawValue();
    this.metricsService.CreateMetricAsync({ ...value })
      .subscribe(
        (response: CreateMetricResponse) => {
          this.toastrService.success('Показатель добавлен', 'Успешно');

          this.router.navigate(['..', response.id], { relativeTo: this.activatedRoute });
        },
        (response: HttpErrorResponse) => this.handleError(response, 'Не удалось добавить показатель')
      );
  }
  acceptChanges() {

    Object.entries(this.form.controls).forEach(x => {
      x[1].markAsDirty();
      x[1].markAsTouched();
    });

    if (this.id > 0) {
      this.update();
    } else {
      this.create();
    }

  }


  update() {
    const value: Metric = this.form.getRawValue();

    this.metricsService.UpdateMetricAsync({ id: this.id, request: { ...value } })
      .subscribe(
        () => {
          this.toastrService.success('Показатель обновлен', 'Успешно');
        },
        (response: HttpErrorResponse) => this.handleError(response, 'Не удалось обновить показатель')
      );
  }


  handleError(response: HttpErrorResponse, message: string) {
    if (response.status === 400 && response.error && response.error.errors) {
      for (const error of response.error.errors) {
        switch (error.status) {
          case -2: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;

          case 1: this.toastrService.warning('Необходимо указать код показателя', 'Ошибка'); break;
          case 2: this.toastrService.warning('Необходимо указать название показателя', 'Ошибка'); break;
          case 3: this.toastrService.warning('Неизвестный тип показателя', 'Ошибка'); break;
          case 4: this.toastrService.warning('Необходимо указать формулу вычисляемого показателя', 'Ошибка'); break;
          case 5: this.toastrService.warning('Показатель с указанным кодом уже добавлен', 'Ошибка'); break;
          case 6: this.toastrService.warning('Показатель не найден', 'Ошибка'); break;
          case 7: this.toastrService.warning('Показатель используется', 'Ошибка'); break;

          default: this.toastrService.warning(message, 'Ошибка'); break;
        }
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }

}

class SystemNameValidator {

  static validator(metricsService: MetricsService) {
    return (control: AbstractControl) => control.value && control.value.length > 2 ?
      metricsService.MetricsAsync({ Page: 1, Size: 0, Search: control.value })
        .pipe(map(x => x.some(m => m.systemName === control.value) ? { exists: true } : null)) : from([null]);
  }
}
