/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { BypassSecurtyResponse } from '../models/bypass-securty-response';
import { BypassSecurityRequest } from '../models/bypass-security-request';
import { Profile } from '../models/profile';
import { AccountCompany } from '../models/account-company';
import { ChangeCompanyResponse } from '../models/change-company-response';
import { ChangeCompanyRequest } from '../models/change-company-request';
@Injectable({
  providedIn: 'root',
})
class AccountService extends __BaseService {
  static readonly SigninPath = '/api/v1/account/signin';
  static readonly RefreshPath = '/api/v1/account/refresh';
  static readonly MyPath = '/api/Account';
  static readonly CompaniesPath = '/api/Account/companies';
  static readonly MyCompaniesPath = '/api/my/companies';
  static readonly ChangeCompanyPath = '/api/my/company';
  static readonly PingPath = '/api/ping';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param model undefined
   * @return Success
   */
  SigninResponse(model?: BypassSecurityRequest): __Observable<__StrictHttpResponse<BypassSecurtyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/account/signin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BypassSecurtyResponse>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Signin(model?: BypassSecurityRequest): __Observable<BypassSecurtyResponse> {
    return this.SigninResponse(model).pipe(
      __map(_r => _r.body as BypassSecurtyResponse)
    );
  }

  /**
   * @return Success
   */
  RefreshResponse(): __Observable<__StrictHttpResponse<BypassSecurtyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/account/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BypassSecurtyResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  Refresh(): __Observable<BypassSecurtyResponse> {
    return this.RefreshResponse().pipe(
      __map(_r => _r.body as BypassSecurtyResponse)
    );
  }

  /**
   * @return Success
   */
  MyResponse(): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @return Success
   */
  My(): __Observable<Profile> {
    return this.MyResponse().pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CompaniesResponse(request?: BypassSecurityRequest): __Observable<__StrictHttpResponse<BypassSecurtyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Account/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BypassSecurtyResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Companies(request?: BypassSecurityRequest): __Observable<BypassSecurtyResponse> {
    return this.CompaniesResponse(request).pipe(
      __map(_r => _r.body as BypassSecurtyResponse)
    );
  }

  /**
   * @return Success
   */
  MyCompaniesResponse(): __Observable<__StrictHttpResponse<Array<AccountCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/my/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<AccountCompany>>;
      })
    );
  }
  /**
   * @return Success
   */
  MyCompanies(): __Observable<Array<AccountCompany>> {
    return this.MyCompaniesResponse().pipe(
      __map(_r => _r.body as Array<AccountCompany>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  ChangeCompanyResponse(request?: ChangeCompanyRequest): __Observable<__StrictHttpResponse<ChangeCompanyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/my/company`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChangeCompanyResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  ChangeCompany(request?: ChangeCompanyRequest): __Observable<ChangeCompanyResponse> {
    return this.ChangeCompanyResponse(request).pipe(
      __map(_r => _r.body as ChangeCompanyResponse)
    );
  }
  PingResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ping`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  Ping(): __Observable<null> {
    return this.PingResponse().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AccountService {
}

export { AccountService }
