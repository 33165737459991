import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { DiscountResolver } from './resolvers/discount.resolver';
import { DiscountComponent } from './components/discount/discount.component';
import { DiscountsComponent } from './components/discounts/discounts.component';
import { PermissionNames } from '../../models/permission-names';
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { DiscountSettingsItemComponent } from './components/discount-settings-item/discount-settings-item.component';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: DiscountResolver,
    },
    component: DiscountComponent,
    data: {
      title: "Новая скидка",
      requirements: [PermissionNames.ManageDiscounts]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: DiscountResolver
    },
    component: DiscountComponent,
    data: {
      title: "Скидка",
      requirements: [PermissionNames.ManageDiscounts]
    }
  },
  {
    path: "",
    component: DiscountsComponent,
    data: {
      title: "Купоны и скидки",
      requirements: [PermissionNames.ManageDiscounts]
    }
  }
];

@NgModule({
  declarations: [
    DiscountComponent,
    DiscountsComponent,
    DiscountSettingsItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule,
    SharedModule

  ],
  exports: [
    RouterModule
  ],
  entryComponents: []
})
export class DiscountSettingsModule { }
