/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CreateCallReportResponse } from '../models/create-call-report-response';
import { CreateScheduleCallReportRequest } from '../models/create-schedule-call-report-request';
import { CreateRecordCallReportRequest } from '../models/create-record-call-report-request';
import { CreateRecordInvitationCallReportRequest } from '../models/create-record-invitation-call-report-request';
import { CreateIncomingCallReportRequest } from '../models/create-incoming-call-report-request';
import { UpdateCallReportRequest } from '../models/update-call-report-request';
@Injectable({
  providedIn: 'root',
})
class CallReportsService extends __BaseService {
  static readonly CreateScheduleCallReportAsyncPath = '/api/CallReports/schedules';
  static readonly CreateScheduleCallReportAsync_1Path = '/api/CallReports';
  static readonly CreateRecordCallReportAsyncPath = '/api/CallReports/records';
  static readonly CreateRecordInvitationCallReportAsyncPath = '/api/CallReports/records/invitation';
  static readonly CreateIncomingCallReportPath = '/api/CallReports/incoming';
  static readonly UpdateCallReportAsyncPath = '/api/CallReports/{id}';
  static readonly RemoveCallReportAsyncPath = '/api/CallReports/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateScheduleCallReportAsyncResponse(request?: CreateScheduleCallReportRequest): __Observable<__StrictHttpResponse<CreateCallReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CallReports/schedules`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCallReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateScheduleCallReportAsync(request?: CreateScheduleCallReportRequest): __Observable<CreateCallReportResponse> {
    return this.CreateScheduleCallReportAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateCallReportResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateScheduleCallReportAsync_1Response(request?: CreateScheduleCallReportRequest): __Observable<__StrictHttpResponse<CreateCallReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CallReports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCallReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateScheduleCallReportAsync_1(request?: CreateScheduleCallReportRequest): __Observable<CreateCallReportResponse> {
    return this.CreateScheduleCallReportAsync_1Response(request).pipe(
      __map(_r => _r.body as CreateCallReportResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateRecordCallReportAsyncResponse(request?: CreateRecordCallReportRequest): __Observable<__StrictHttpResponse<CreateCallReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CallReports/records`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCallReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateRecordCallReportAsync(request?: CreateRecordCallReportRequest): __Observable<CreateCallReportResponse> {
    return this.CreateRecordCallReportAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateCallReportResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateRecordInvitationCallReportAsyncResponse(request?: CreateRecordInvitationCallReportRequest): __Observable<__StrictHttpResponse<CreateCallReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CallReports/records/invitation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCallReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateRecordInvitationCallReportAsync(request?: CreateRecordInvitationCallReportRequest): __Observable<CreateCallReportResponse> {
    return this.CreateRecordInvitationCallReportAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateCallReportResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateIncomingCallReportResponse(request?: CreateIncomingCallReportRequest): __Observable<__StrictHttpResponse<CreateCallReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/CallReports/incoming`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateCallReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateIncomingCallReport(request?: CreateIncomingCallReportRequest): __Observable<CreateCallReportResponse> {
    return this.CreateIncomingCallReportResponse(request).pipe(
      __map(_r => _r.body as CreateCallReportResponse)
    );
  }

  /**
   * @param params The `CallReportsService.UpdateCallReportAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateCallReportAsyncResponse(params: CallReportsService.UpdateCallReportAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/CallReports/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `CallReportsService.UpdateCallReportAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateCallReportAsync(params: CallReportsService.UpdateCallReportAsyncParams): __Observable<null> {
    return this.UpdateCallReportAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveCallReportAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/CallReports/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveCallReportAsync(id: number): __Observable<null> {
    return this.RemoveCallReportAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CallReportsService {

  /**
   * Parameters for UpdateCallReportAsync
   */
  export interface UpdateCallReportAsyncParams {
    id: number;
    request?: UpdateCallReportRequest;
  }
}

export { CallReportsService }
