import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Category } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-schedule-category-modal',
  templateUrl: './schedule-category.modal.component.html',
  styleUrls: ['./schedule-category.modal.component.scss']
})
export class ScheduleCategoryModalComponent implements OnInit {
  title = "Категория";

  form = new FormGroup({
    category: new FormControl("", [Validators.required])
  });

  @Input() categories: Category[] = [];

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onConfirm = new EventEmitter<number>();

  constructor() { }

  ngOnInit() { }

  cancel(): void {
    this.onCancel.emit();
  }

  submit(): void {
    this.form.markAsTouched();

    if (!this.form.valid) {
      return;
    }

    var value: FormValue = this.form.getRawValue();

    this.onConfirm.emit(value.category);
  }
}

interface FormValue {
  category: number;
}
