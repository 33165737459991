import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { WageSchema } from '../../../../generated/models/wage-schema';
import { WageSchemasService } from '../../../../generated/services';

@Component({
  selector: 'mp-wage-schemas',
  templateUrl: './wage-schemas.component.html',
  styleUrls: ['./wage-schemas.component.scss'],
  host: { class: "page" }
})
export class WageSchemasComponent implements OnInit {

  page = 1;
  size = 50;

  loading = false;
  wageSchemas: WageSchema[] = [];

  constructor(private wageSchemasService: WageSchemasService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modal: NgbModal
  ) { }

  async ngOnInit() {
    this.loading = true;

    this.wageSchemas = await this.load();
    this.loading = false;
  }

  async load(): Promise<WageSchema[]> {
    return this.wageSchemasService.WageSchemas({ Page: this.page, Size: this.size }).toPromise();
  }

  async onScrollDown(): Promise<void> {
    this.page++;
    const portion = await this.load();

    this.wageSchemas.push(...portion);
  }

  create(): void {
    this.router.navigate(['new'], { relativeTo: this.activatedRoute });
  }

  edit(wageSchema: WageSchema): void {
    this.router.navigate([wageSchema.id], { relativeTo: this.activatedRoute });
  }

  remove(wageSchema: WageSchema): void {
    const options: NgbModalOptions = { backdrop: "static", centered: true };
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, options);
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Схема оплаты «‎${wageSchema.name}» будет удалена без возможности восстановления. Продолжить?`;
    modalRef.result.then((): void => {
      this.wageSchemasService.RemoveWageSchema(wageSchema.id)
        .subscribe(
          async (): Promise<void> => {
            this.page = 1;
            this.wageSchemas = [];
            this.loading = true;
            this.wageSchemas = await this.load();
            this.loading = false;
          },
          (errorResponse: HttpErrorResponse): void => {

          }
        )
    });
  }
}
