import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { NameStandartizationResponse } from '../../../../generated/models';

@Component({
  selector: 'mp-name-suggestions-input',
  templateUrl: './name-suggestions-input.component.html',
  styleUrls: ['./name-suggestions-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NameSuggestionsInputComponent
    }
  ]
})
export class NameSuggestionsInputComponent implements ControlValueAccessor, OnInit {
  destroy$ = new Subject<void>();

  @Input() loader: (value: string) => Observable<NameStandartizationResponse>;
  @Output() onSelect = new EventEmitter<NameStandartizationResponse>();

  formatter = (value: NameStandartizationResponse) => value.primaryForm;
  changeFn: (string) => void;

  control = new FormControl('');

  search: OperatorFunction<string, NameStandartizationResponse[]> = (text$: Observable<string>) =>
    text$.pipe(
      takeUntil(this.destroy$),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        if (this.changeFn) {
          this.changeFn(term);
        }

        if (!term || term.length < 3 || !this.loader) {
          return from([[]]);
        }

        return this.loader(term)
          .pipe(map(x => x.primaryForm ? [x] : []));

      })
    );

  constructor() { }

  writeValue(obj: string): void {
    console.info(obj);
    this.control.setValue({ primaryForm: obj }, { emitEvent: false });
  }
  registerOnChange(fn: (string) => void): void {
    this.changeFn = fn;
  }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.control.disable({ emitEvent: false }); else this.control.enable({ emitEvent: false });
  }

  ngOnInit() { }

  select(event: NgbTypeaheadSelectItemEvent) {
    const item: NameStandartizationResponse = event.item;

    this.control.setValue(item.primaryForm);
    this.onSelect.emit(item);
  }

}
