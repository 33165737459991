import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { ScheduleItemComponent } from './components/schedule-item/schedule-item.component';
import { ScheduleItemResolver } from './resolvers/schedule-item.resolver';
import { ScheduleItemsComponent } from './components/schedule-items/schedule-items.component';
import { ScheduleItemsResolver } from './resolvers/schedule-items.resolver';
import { RemoveScheduleModalComponent } from './components/remove-schedule.modal/remove-schedule.modal.component';
import { ScheduleEmployeeModalComponent } from './components/schedule-employee.modal/schedule-employee.modal.component';
import { ScheduleSpecialityModalComponent } from './components/schedule-speciality.modal/schedule-speciality.modal.component';
import { ScheduleShiftModalComponent } from './components/schedule-shift.modal/schedule-shift.modal.component';
import { ScheduleBreakModalComponent } from './components/schedule-break.modal/schedule-break.modal.component';
import { NgxSelectModule } from 'ngx-select-ex';
import { ToastrModule } from 'ngx-toastr';
import { PermissionNames } from '../../models/permission-names';
import { SharedModule } from '../shared/shared.module';
import { ScheduleListItemComponent } from './components/schedule-list-item/schedule-list-item.component';
import { ScheduleCategoryModalComponent } from './components/schedule-category.modal/schedule-category.modal.component';
import { NgxMaskModule } from 'ngx-mask';

const routes: Routes = [
  {
    path: "new",
    component: ScheduleItemComponent,
    data: {
      title: "Расписания: Новое расписание",
      requirements: [PermissionNames.ManageSchedules]
    },
    resolve: {
      payload: ScheduleItemResolver
    }
  },
  {
    path: ":id",
    component: ScheduleItemComponent,
    data: {
      title: "Расписания: Изменение расписаний",
      requirements: [PermissionNames.ManageSchedules]
    },
    resolve: {
      payload: ScheduleItemResolver
    }
  },
  {
    path: "",
    component: ScheduleItemsComponent,
    resolve: {
      payload: ScheduleItemsResolver
    },
    data: {
      title: "Список расписаний",
      requirements: [PermissionNames.ManageSchedules]
    }
  }
];

@NgModule({
  declarations: [
    ScheduleItemsComponent,
    ScheduleItemComponent,
    ScheduleEmployeeModalComponent,
    ScheduleSpecialityModalComponent,
    ScheduleShiftModalComponent,
    ScheduleBreakModalComponent,
    RemoveScheduleModalComponent,
    ScheduleListItemComponent,
    ScheduleCategoryModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    NgxSelectModule,
    ToastrModule,
    NgxMaskModule.forRoot({}),

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ScheduleEmployeeModalComponent,
    ScheduleSpecialityModalComponent,
    ScheduleShiftModalComponent,
    ScheduleBreakModalComponent,
    RemoveScheduleModalComponent,
    ScheduleCategoryModalComponent
  ]
})
export class ScheduleSettingsModule { }
