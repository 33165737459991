import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-review-defect',
  templateUrl: './review-defect.component.html',
  styleUrls: ['./review-defect.component.scss']
})
export class ReviewDefectComponent implements OnInit {

  @Input() description = '';

  constructor() { }

  ngOnInit() { }

}
