import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'mp-recommendations-hint',
  templateUrl: './recommendations-hint.component.html',
  styleUrls: ['./recommendations-hint.component.scss']
})
export class RecommendationsHintComponent implements OnInit {

  @Input() title = '';
  @Input() label = '';
  @Input() items: RecommendationItem[] = [];

  constructor() { }

  ngOnInit() { }
}

export interface RecommendationItem {
  title: string;
  text: string;
}
