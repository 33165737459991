import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss']
})
export class DeleteConfirmationModalComponent implements OnInit {

  @Input() message: string;

  @Input() confirmBtnText: string

  constructor(
    private modal: NgbActiveModal
  ) {
    this.confirmBtnText = "Удалить";
  }

  ngOnInit() { }

  public close(): void {
    this.modal.close();
  }

  public dismiss(): void {
    this.modal.dismiss();
  }

}
