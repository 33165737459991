import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../generated/services';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStorageService } from "@skytecs/security";
import { AccountCompany } from '../../generated/models';

@Component({
  selector: 'mp-choose-company',
  templateUrl: './choose-company.component.html',
  styleUrls: ['./choose-company.component.scss']
})
export class ChooseCompanyComponent implements OnInit {
  token = '';
  companies: AccountCompany[] = [];

  constructor(
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userStorage: UserStorageService
  ) { }

  async ngOnInit(): Promise<void> {
    this.token = this.activatedRoute.snapshot.queryParams["token"];

    if (!this.token) {
      this.router.navigate(["signin"]);
      return;
    }

    try {
      const response = await this.accountService.Companies({ token: this.token }).toPromise();

      if (response.token) {
        this.userStorage.authenticate(response.token, response.expires);
        this.router.navigateByUrl(response.returnUrl);
        return;
      }

      if (response.companies.length === 0) {
        this.router.navigate(["signin"]);
        return;
      }

      this.companies = response.companies;
    } catch (e) {
      this.router.navigate(["signin"]);
    }
  }

  async selectCompany(company: AccountCompany): Promise<void> {

    try {
      const response = await this.accountService.Companies({
        token: this.token,
        companyId: company.companyId
      }).toPromise();

      if (!response.token) {
        this.router.navigate(["signin"]);
        return;
      }

      this.userStorage.authenticate(response.token, response.expires);
      this.router.navigateByUrl(response.returnUrl);
    }
    catch (e) {
      this.router.navigate(["signin"]);
    }
  }

  rolesFor(company: AccountCompany): string { return company.roles.join(", "); }
}
