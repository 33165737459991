import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReviewCandidateDiagnosis, ReviewCandidateReveiw } from '../../../../generated/models';
import { ReviewCandidate } from '../../../../generated/models/review-candidate';

@Component({
  selector: 'mp-review-candidate-item',
  templateUrl: './review-candidate-item.component.html',
  styleUrls: ['./review-candidate-item.component.scss']
})
export class ReviewCandidateItemComponent implements OnInit {

  @Input() candidate: ReviewCandidate = {};
  @Input() canAddReview = false;
  @Input() rowNumber: number;

  @Input() isAuthor = false;

  @Output()
  onOpenRecord: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onOpenReview: EventEmitter<ReviewCandidateReveiw> = new EventEmitter<ReviewCandidateReveiw>();

  @Output()
  onPrintReview: EventEmitter<ReviewCandidateReveiw> = new EventEmitter<ReviewCandidateReveiw>();

  @Output()
  onAddReview: EventEmitter<void> = new EventEmitter<void>();

  isNew(review: ReviewCandidateReveiw): boolean {
    return this.candidate && review && review.isSigned && !review.isRevised && this.isAuthor;
  }

  isRevised(review: ReviewCandidateReveiw): boolean {
    return this.candidate && review && review.isSigned && review.isRevised && !this.isAuthor;
  }

  constructor() { }

  diagnosisType(diagnosis: ReviewCandidateDiagnosis): string {
    switch (diagnosis.type) {
      case 0: return "Предварительный";
      case 1: return "Основной";
      case 2: return "Осложнение";
      case 3: return "Сопутствующий";

      default: return "";
    }

  }

  ngOnInit() { }

  openRecord(): void {
    this.onOpenRecord.emit();
  }

  addReview(): void {
    if (!this.canAddReview) {
      return;
    }

    this.onAddReview.emit();
  }

  openReview(review: ReviewCandidateReveiw): void {
    this.onOpenReview.emit(review);
  }

  printReview(review: ReviewCandidateReveiw): void {
    this.onPrintReview.emit(review);
  }

}
