/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ColorMarksResponse } from '../models/color-marks-response';
import { CreateColorMarkResponse } from '../models/create-color-mark-response';
import { CreateColorMarkRequest } from '../models/create-color-mark-request';
import { ColorMark } from '../models/color-mark';
import { UpdateColorMarkRequest } from '../models/update-color-mark-request';
@Injectable({
  providedIn: 'root',
})
class ColorMarksService extends __BaseService {
  static readonly AllColorMarksPath = '/api/ColorMarks/all';
  static readonly ColorMarksPath = '/api/ColorMarks';
  static readonly CreateAsyncPath = '/api/ColorMarks';
  static readonly ColorMarkPath = '/api/ColorMarks/{id}';
  static readonly UpdateAsyncPath = '/api/ColorMarks/{id}';
  static readonly ArchiveAsyncPath = '/api/ColorMarks/{id}/archive';
  static readonly RemoveAsyncPath = '/api/ColorMarks/id';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ColorMarksService.AllColorMarksParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  AllColorMarksResponse(params: ColorMarksService.AllColorMarksParams): __Observable<__StrictHttpResponse<Array<ColorMarksResponse>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ColorMarks/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ColorMarksResponse>>;
      })
    );
  }
  /**
   * @param params The `ColorMarksService.AllColorMarksParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  AllColorMarks(params: ColorMarksService.AllColorMarksParams): __Observable<Array<ColorMarksResponse>> {
    return this.AllColorMarksResponse(params).pipe(
      __map(_r => _r.body as Array<ColorMarksResponse>)
    );
  }

  /**
   * @param params The `ColorMarksService.ColorMarksParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  ColorMarksResponse(params: ColorMarksService.ColorMarksParams): __Observable<__StrictHttpResponse<ColorMarksResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchived != null) __params = __params.set('IncludeArchived', params.IncludeArchived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ColorMarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ColorMarksResponse>;
      })
    );
  }
  /**
   * @param params The `ColorMarksService.ColorMarksParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchived`:
   *
   * @return Success
   */
  ColorMarks(params: ColorMarksService.ColorMarksParams): __Observable<ColorMarksResponse> {
    return this.ColorMarksResponse(params).pipe(
      __map(_r => _r.body as ColorMarksResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateAsyncResponse(request?: CreateColorMarkRequest): __Observable<__StrictHttpResponse<CreateColorMarkResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ColorMarks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateColorMarkResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateAsync(request?: CreateColorMarkRequest): __Observable<CreateColorMarkResponse> {
    return this.CreateAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateColorMarkResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ColorMarkResponse(id: number): __Observable<__StrictHttpResponse<ColorMark>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ColorMarks/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ColorMark>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ColorMark(id: number): __Observable<ColorMark> {
    return this.ColorMarkResponse(id).pipe(
      __map(_r => _r.body as ColorMark)
    );
  }

  /**
   * @param params The `ColorMarksService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsyncResponse(params: ColorMarksService.UpdateAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/ColorMarks/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ColorMarksService.UpdateAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateAsync(params: ColorMarksService.UpdateAsyncParams): __Observable<null> {
    return this.UpdateAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  ArchiveAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ColorMarks/${id}/archive`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  ArchiveAsync(id: number): __Observable<null> {
    return this.ArchiveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveAsyncResponse(id?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (id != null) __params = __params.set('id', id.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/ColorMarks/id`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveAsync(id?: number): __Observable<null> {
    return this.RemoveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ColorMarksService {

  /**
   * Parameters for AllColorMarks
   */
  export interface AllColorMarksParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
  }

  /**
   * Parameters for ColorMarks
   */
  export interface ColorMarksParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchived?: boolean;
  }

  /**
   * Parameters for UpdateAsync
   */
  export interface UpdateAsyncParams {
    id: number;
    request?: UpdateColorMarkRequest;
  }
}

export { ColorMarksService }
