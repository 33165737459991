import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ManipulationService } from '../../../../generated/models';

@Component({
  selector: 'mp-service-manipulation-item',
  templateUrl: './service-manipulation-item.component.html',
  styleUrls: ['./service-manipulation-item.component.scss']
})
export class ServiceManipulationItemComponent implements OnInit {

  @Input() item: ManipulationService;

  @Output() onRemove = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove = () => this.onRemove.emit();

}
