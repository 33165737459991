import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { ExternalConnectionResolverPayload } from '../../resolvers/external-connection.resolver';
import { CompaniesService, ExternalConnectionsService } from '../../../../generated/services';
import { AddExternalConnectionResponse, Company, FilterItem } from '../../../../generated/models';

@Component({
  selector: 'mp-external-connection-editor',
  templateUrl: './external-connection.component.html',
  styleUrls: ['./external-connection.component.scss'],
  host: { class: "page" }
})
export class ExternalConnectionComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;

  types = [
    { id: 1, name: "Оборудование" },
    { id: 2, name: "Лабораторная система" },
    { id: 3, name: "Кассовый аппарат" },
    { id: 4, name: "Приложение" },
    { id: 5, name: "Штрих-принтер" },
    { id: 6, name: "Принтер документов" }
  ];

  title = 'Новая внешняя система';

  companies$: Observable<Company[]>;

  externalConnectionForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    location: new FormControl(""),
    type: new FormControl("", [Validators.required]),
    apiKey: new FormControl(""),
    companyId: new FormControl(null)
  });

  constructor(
    private externalConnectionsService: ExternalConnectionsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private companiesService: CompaniesService
  ) {
    this.companies$ = companiesService.MyCompanies();
  }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (data: { payload: ExternalConnectionResolverPayload }): void => {
          this.id = data.payload.externalConnection.id;

          this.title = this.id > 0 ? `${data.payload.externalConnection.name}` : "Новая внешняя система";

          this.externalConnectionForm.patchValue(data.payload.externalConnection);
        });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  acceptChanges(): void {
    Object.entries(this.externalConnectionForm.controls)
      .map((x: [string, AbstractControl]): AbstractControl => x[1])
      .forEach((x: AbstractControl): void => (x.markAsDirty()));

    if (this.externalConnectionForm.invalid) {
      return;
    }

    const value: FormValue = this.externalConnectionForm.getRawValue();

    if (this.id > 0) {
      this.externalConnectionsService.Edit({
        id: this.id,
        request: {
          name: value.name,
          location: value.location,
          companyId: value.companyId || undefined
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("", "Внешняя система сохранен");
            this.back();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось изменить систему", "Ошибка");
          }
        );
    } else {
      this.externalConnectionsService.Create({
        name: value.name,
        location: value.location,
        type: value.type,
        apiKey: value.apiKey,
        companyId: value.companyId || undefined
      })
        .subscribe(
          (response: AddExternalConnectionResponse): void => {
            //this.back();
            this.router.navigate(["../", response.id], { relativeTo: this.activatedRoute });
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось добавить систему", "Ошибка");
          }
        );
    }
  }

  back(): void {
    this.router.navigate(['..'], { relativeTo: this.activatedRoute });
  }
}

interface FormValue {
  name: string;
  location: string;
  type: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  apiKey: string;
  companyId: number;
}
