import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeComponent } from './components/employee/employee.component';
import { EmployeeResolver } from './resolvers/employee.resolver';
import { EmployeesComponent } from './components/employees/employees.component';
import { EmployeeSpecialityModalComponent } from './components/employee-speciality.modal/employee-speciality.modal.component';
import { EmployeeAbsenceModalComponent } from './components/employee-absence.modal/employee-absence.modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';

import { NgxMaskModule } from "ngx-mask";
import { EmployeeCompanyModalComponent } from './components/employee-company.modal/employee-company.modal.component';
import { EmployeeServiceModalComponent } from './components/employee-service.modal/employee-service.modal.component';
import { EmployeePartnerModalComponent } from './components/employee-partner-modal/employee-partner-modal.component';
import { ManageEmployeeComponent } from './components/manage-employee/manage-employee.component';
import { EmployeeRoleModalComponent } from './components/employee-role-modal/employee-role-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { EmployeePbxListItemComponent } from './components/employee-pbx-list-item/employee-pbx-list-item.component';
import { EmployeePhoneModalComponent } from './components/employee-phone-modal/employee-phone-modal.component';
import { PermissionNames } from '../../models/permission-names';
import { NgxSelectModule } from 'ngx-select-ex';
import { EmployeesResolver } from './resolvers/employees.resolver';
import { EmployeeCodeListItemComponent } from './components/employee-code-list-item/employee-code-list-item.component';
import { EmployeeCodeSystemModalComponent } from './components/employee-code-system-modal/employee-code-system-modal.component';
import { EmployeeWageSchemaModalComponent } from './components/employee-wage-schema-modal/employee-wage-schema-modal.component';
import { EmployeeCompaniesComponent } from './components/employee-companies/employee-companies.component';
import { EmployeeCompanyComponent } from './components/employee-company/employee-company.component';

const routes: Routes = [
  {
    path: "new",
    component: EmployeeComponent,
    data: {
      title: "Настройки: Новый сотрудник",
      requirements: [PermissionNames.ManageEmployees]
    },
    resolve: {
      payload: EmployeeResolver
    }
  },
  {
    path: ":id",
    component: EmployeeComponent,
    data: {
      title: "Настройки: Изменение сотрудников",
      requirements: [PermissionNames.ManageEmployees]
    },
    resolve: {
      payload: EmployeeResolver
    }
  },
  {
    path: "",
    component: EmployeesComponent,
    data: {
      title: "Настройки: Список сотрудников",
      requirements: [PermissionNames.ManageEmployees]
    },
    resolve: {
      payload: EmployeesResolver
    }
  }
];


@NgModule({
  declarations: [
    EmployeesComponent,
    EmployeeComponent,
    EmployeeSpecialityModalComponent,
    EmployeeAbsenceModalComponent,
    EmployeeCompanyModalComponent,
    EmployeeServiceModalComponent,
    EmployeePartnerModalComponent,
    ManageEmployeeComponent,
    EmployeeRoleModalComponent,
    EmployeePbxListItemComponent,
    EmployeePhoneModalComponent,
    EmployeeCodeListItemComponent,
    EmployeeCodeSystemModalComponent,
    EmployeeWageSchemaModalComponent,
    EmployeeCompaniesComponent,
    EmployeeCompanyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbPopoverModule,
    ToastrModule,

    NgxMaskModule.forRoot({}),
    NgxSelectModule,

    LayoutModule,
    ControlsModule,

    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    EmployeeSpecialityModalComponent,
    EmployeeAbsenceModalComponent,
    EmployeeCompanyModalComponent,
    EmployeeServiceModalComponent,
    EmployeePartnerModalComponent,
    EmployeeRoleModalComponent,
    EmployeePhoneModalComponent,
    EmployeeCodeSystemModalComponent,
    ManageEmployeeComponent,
    EmployeeWageSchemaModalComponent
  ]
})
export class EmployeeSettingsModule { }
