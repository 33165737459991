import { Component, OnInit } from '@angular/core';
import { AttendanceFilters } from '../attendance-filters/attendance-filters.component';

import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { Attending, Company, CreateCallReportResponse, PlannedVisit, PlannedVisitCallReport, Speciality } from '../../../../generated/models';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { PlannedVisitsPayload } from '../../resolvers/planned-visits-resolver';
import { CallReportsService, PlannedVisitsService } from '../../../../generated/services';
import { CallModalComponent, CallResult } from '../call-modal/call-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup } from '@angular/forms';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-planned-visits',
  templateUrl: './planned-visits.component.html',
  styleUrls: ['./planned-visits.component.scss']
})
export class PlannedVisitsComponent implements OnInit {
  destroy$ = new Subject<void>();

  title = 'Запланированные посещения';

  loading = false;

  companies: Company[] = [];
  doctors: Attending[] = [];
  specialities: Speciality[] = [];

  plannedVisits: PlannedVisit[] = [];
  items$ = new BehaviorSubject<PlannedVisit[]>([]);

  canRemoveCalls = false;

  filters: AttendanceFilters = {
    period: {
      from: moment(),
      to: moment().add(1, 'day')
    },
    date: moment(),
    companies: [],
    doctors: [],
    specialities: []
  };

  auxiliaryFilters = new FormGroup({
    includeScheduled: new FormControl(false),
  });

  constructor(private userStorage: UserStorage,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private modal: NgbModal,
    private plannedVisitsService: PlannedVisitsService,
    private callReportsService: CallReportsService,
    private confirmationModalSrvice: ConfirmationModalService
  ) {
    this.canRemoveCalls = userStorage.hasPermission(PermissionNames.RemoveCallReports)

    this.auxiliaryFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: AuxiliaryFiltersValue): void => {
        this.updateRoute();
        this.items$.next([...this.plannedVisits.filter(x => value.includeScheduled || x.appointments.length === 0)]);
      });
  }

  async ngOnInit(): Promise<void> {

    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (data: { payload: PlannedVisitsPayload }) => {

        this.companies = data.payload.companies;
        this.doctors = data.payload.doctors;
        this.specialities = data.payload.specialities;

        const queryMap = this.activatedRoute.snapshot.queryParamMap;

        const filters: AttendanceFilters = {
          date: moment().add(-1, 'day'),
          period: {
            from: moment().add(-1, 'day'),
            to: moment().add(-1, 'day')
          },
          companies: [],
          doctors: [],
          specialities: [],
        };

        if (queryMap.has('from') && moment(queryMap.get('from'), 'DD.MM.YYYY').isValid()) {
          filters.period.from = moment(queryMap.get('from'), 'DD.MM.YYYY');
        }

        if (queryMap.has('to') && moment(queryMap.get('to'), 'DD.MM.YYYY').isValid()) {
          filters.period.to = moment(queryMap.get('to'), 'DD.MM.YYYY');
        }

        if (queryMap.has('company')) {
          filters.companies = queryMap.getAll('company').map(x => parseInt(x, 10));
        }

        if (queryMap.has('doctor')) {
          filters.doctors = queryMap.getAll('doctor').map(x => parseInt(x, 10));
        }

        if (queryMap.has('specialities')) {
          filters.specialities = queryMap.getAll('specialities').map(x => parseInt(x, 10));
        }

        if (queryMap.has('includeScheduled') && queryMap.get('includeScheduled') === 'true') {
          this.auxiliaryFilters.patchValue({ includeScheduled: true });
        }

        this.changeFilters(filters);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  updateRoute() {
    const params: Params = {};

    const auxiliaryFiltersValue: AuxiliaryFiltersValue = this.auxiliaryFilters.getRawValue();

    if (this.filters.period.from && this.filters.period.from.isValid()) {
      params['from'] = this.filters.period.from.format('DD.MM.YYYY')
    }

    if (this.filters.period.to && this.filters.period.to.isValid()) {
      params['to'] = this.filters.period.to.format('DD.MM.YYYY')
    }

    if (this.filters.companies.length > 0) {
      params['company'] = this.filters.companies;
    }

    if (this.filters.doctors.length > 0) {
      params['doctor'] = this.filters.doctors;
    }

    if (this.filters.specialities.length > 0) {
      params['specialities'] = this.filters.specialities;
    }

    if (auxiliaryFiltersValue.includeScheduled) {
      params['includeScheduled'] = true;
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: params })
  }

  async changeFilters(value: AttendanceFilters): Promise<void> {
    this.filters = { ...this.filters, ...value };
    const auxiliaryFiltersValue: AuxiliaryFiltersValue = this.auxiliaryFilters.getRawValue();

    this.updateRoute();

    this.title = `Запланированные посещения`;

    this.loading = true;
    this.plannedVisits = await this.plannedVisitsService.PlannedVisitsAsync({
      From: this.filters.period.from.format('DD.MM.YYYY'),
      To: this.filters.period.to.clone().add(1, 'day').format('DD.MM.YYYY'),
      CompanyIds: this.filters.companies,
      DoctorIds: this.filters.doctors,
      SpecialityIds: this.filters.specialities,
    }).toPromise();
    this.loading = false;

    this.items$.next([...this.plannedVisits.filter(x => auxiliaryFiltersValue.includeScheduled || x.appointments.length === 0)]);
  }

  handleCallReportError(response: HttpErrorResponse) {
    if (response.status === 400) {
      for (const error of response.error.errors) {

        switch (error.status) {
          case -2: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;
          case 1: this.toastrService.warning('Звонок не найден', 'Ошибка'); break;
          case 2: this.toastrService.warning('Пользователь не найден', 'Ошибка'); break;
          case 3: this.toastrService.warning('Причина неявки не найдена', 'Ошибка'); break;
          case 4: this.toastrService.warning('Запись отменена', 'Ошибка'); break;

          default: this.toastrService.error('Не удалось сохранить звонок', 'Ошибка'); break;
        }
      }
    }
    else {
      this.toastrService.error('Не удалось сохранить звонок', 'Ошибка');
    }
  }

  async createCallReportAsync(item: PlannedVisit, result: CallResult): Promise<CreateCallReportResponse> {

    if (item.type === 1) {
      return await this.callReportsService.CreateRecordInvitationCallReportAsync({
        recordId: item.recordId,
        phone: item.patientPhone,
        comment: result.comment,
        status: result.outcome,
        reasonIds: result.reasons.map(x => x.id)
      }).toPromise();
    }

    throw "Unknown attendance type";
  }

  async createCall(item: PlannedVisit) {
    const componentRef = await this.openCall(item);

    componentRef.onConfirm.subscribe(async (result: CallResult) => {
      componentRef.loading = true;

      try {
        const response = await this.createCallReportAsync(item, result);

        item.calls.push({
          id: response.id,
          date: moment().format('DD.MM.YYYY HH:mm'),
          status: result.outcome,
          reasons: result.reasons.map(x => ({ reasonId: x.id, name: x.name, reasonable: x.reasonable })),
          comment: result.comment
        });

        this.toastrService.success("Отчет о звонке добавлен", "Успешно");

        componentRef.cancel();
      } catch (e) {
        this.handleCallReportError(e as HttpErrorResponse)
      }

      componentRef.loading = false;
    });
  }

  async updateCall(item: PlannedVisit, call: PlannedVisitCallReport) {
    const componentRef = await this.openCall(item);

    componentRef.report = {
      comment: call.comment,
      outcome: 6,
      reasons: call.reasons.map(x => ({ ...x, id: x.reasonId }))
    };

    componentRef.onConfirm.subscribe(async (result: CallResult) => {
      componentRef.loading = true;

      try {
        await this.callReportsService.UpdateCallReportAsync({ id: call.id, request: { reasonIds: result.reasons.map(x => x.id), comment: result.comment } }).toPromise();

        call.comment = result.comment;
        call.reasons = result.reasons.map(x => ({ reasonId: x.id, name: x.name, reasonable: x.reasonable }));

        this.toastrService.success("Отчет о звонке обновлен", "Успешно");

        componentRef.cancel();
      } catch (e) {
        this.handleCallReportError(e as HttpErrorResponse);
      }

      componentRef.loading = false;
    });

  }

  async openCall(item: PlannedVisit): Promise<CallModalComponent> {
    const modalRef = this.modal.open(CallModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: CallModalComponent = modalRef.componentInstance;

    componentRef.left = {
      title: 'Основание явки',
      items: [
        item.appointmentDate,
        [item.doctorLastName, item.doctorFirstName, item.doctorMiddleName].filter(x => x).join(' '),
        item.serviceName,
        item.appointmentReason
      ].filter(x => x),
      empty: ''
    };

    componentRef.right = {
      title: 'Запись',
      isEmpty: item.appointments.length === 0,
      empty: 'Пока нет записи',
      items: item.appointments.map(x => `${x.date} ${[x.doctorLastName, x.doctorFirstName, x.doctorMiddleName].join(' ')} ${x.serviceName || ''}`.trim())
    };

    componentRef.onCancel.subscribe(() => modalRef.close());

    return componentRef;
  }

  clickType(item: PlannedVisit) {
    if (item.type === 1) this.router.navigate(['workspace', 'records', item.recordId]);
  }

  async removeCall(item: PlannedVisit, call: PlannedVisitCallReport) {

    const confirm = await this.confirmationModalSrvice.open({ message: 'Удалить отчет о звонке?' });

    if (confirm) {
      try {
        await this.callReportsService.RemoveCallReportAsync(call.id).toPromise();
        item.calls = item.calls.filter(x => x.id !== call.id);
        this.toastrService.success('Отчет по звонку удалён', 'Успешно');
      }
      catch (e) {
        this.handleCallReportError(e as HttpErrorResponse);
      }
    }
  }

}

interface AuxiliaryFiltersValue {
  includeScheduled: boolean;
}
