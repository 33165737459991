import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TemplateSection } from '../../../../generated/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-template-structure-item',
  templateUrl: './template-structure-item.component.html',
  styleUrls: ['./template-structure-item.component.scss']
})
export class TemplateStructureItemComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  types: SectionType[] = [
    { id: 1, text: "Секция с текстовым полем" },
    { id: 2, text: "Секция с выбором диагноза" },
    { id: 3, text: "Секция с назначениями" },
    { id: 4, text: "Дата следующего посещения" },
    { id: 5, text: "Завершение случая" },
    { id: 6, text: "Секция лечения" },
    { id: 7, text: "Завершение случая и дата следующего посещения" },
    { id: 8, text: "Секция показателей" },
  ];

  @Input() section: TemplateSection = {};

  @Output() onChange = new EventEmitter<TemplateStructureItemChange>();
  @Output() onRemove = new EventEmitter<void>();

  form = new FormGroup({
    name: new FormControl(""),
    type: new FormControl(1)
  });

  get readonly(): boolean { return this.section.id > 0; }

  get displayType(): string {
    const type = this.types.find(x => x.id === this.section.type);

    return type ? type.text : '';
  }

  constructor() {
    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: TemplateStructureItemChange) => this.onChange.emit(value));
  }

  ngOnInit() {
    this.form.patchValue({
      name: this.section.name,
      type: this.section.type
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  remove = () => this.onRemove.emit();
}

interface SectionType {
  id: number;
  text: string;
}

export interface TemplateStructureItemChange {
  name: string;
  type: number;
}
