import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreatmentRecommendation } from '../../../../generated/models';

@Component({
  selector: 'mp-recommended-treatments',
  templateUrl: './recommended-treatments.component.html',
  styleUrls: ['./recommended-treatments.component.scss']
})
export class RecommendedTreatmentsComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() loading: boolean = false;
  @Input() recommendations: TreatmentRecommendation[] = [];

  @Output() onSelect = new EventEmitter<TreatmentRecommendation>();
  @Output() onFav = new EventEmitter<TreatmentRecommendation>();

  groups: RecommendationGroup[] = [];
  arbitrary: TreatmentRecommendation[] = [];
  filtered: TreatmentRecommendation[] = [];

  searchControl: FormControl = new FormControl();

  constructor() {
    this.searchControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string): void => {
        if (!value) {
          this.filtered = [];
          return;
        }

        const regex = new RegExp(value, 'i');

        this.filtered = this.recommendations.filter(x => regex.test(x.treatmentName)).sort((x, y) => x.treatmentName.localeCompare(y.treatmentName));

      });
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["recommendations"]) {
      this.groups = [];

      const currentValue: TreatmentRecommendation[] = changes["recommendations"].currentValue || [];
      const groups: { [categotry: string]: TreatmentRecommendation[] } = {};
      const arbitrary: TreatmentRecommendation[] = [];

      for (const item of currentValue) {
        if (!item.category) {
          arbitrary.push(item);
          continue;
        }


        if (!groups[item.category]) {
          groups[item.category] = [];
        }

        groups[item.category].push(item);
      }

      this.groups = Object.keys(groups)
        .map(x => ({ title: x, items: groups[x].sort((a, b) => a.treatmentName.localeCompare(b.treatmentName)) }))
        .sort((a, b) => a.title.localeCompare(b.title));

      this.arbitrary = arbitrary.sort((a, b) => a.treatmentName.localeCompare(b.treatmentName));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  select = (item: TreatmentRecommendation) => this.onSelect.emit(item);
  fav = (item: TreatmentRecommendation) => this.onFav.emit(item);

  get searching(): boolean { return !!this.searchControl.value; }

}

interface RecommendationGroup {
  title: string;
  items: TreatmentRecommendation[];
}
