import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Speciality } from "../../../generated/models";
import { Observable, forkJoin } from "rxjs";
import { SpecialitiesService } from "../../../generated/services";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class ManipulationsResolver implements Resolve<ManipulationsResolverPayload> {
  constructor(private specialitiesService: SpecialitiesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ManipulationsResolverPayload> {
    const specialitiesLoader = this.specialitiesService.Specialities({ Page: 1, Size: 0 }).pipe(map(x => x.items))

    return forkJoin({
      specialities: specialitiesLoader
    });
  }
}

export interface ManipulationsResolverPayload {
  specialities: Speciality[];
}
