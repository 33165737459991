import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { TemplateSection } from '../../../../generated/models';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'mp-appointment-date-section',
  templateUrl: './appointment-date-section.component.html',
  styleUrls: ['./appointment-date-section.component.scss']
})
export class AppointmentDateSectionComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() section: TemplateSection;
  @Input() required = false;
  @Input() disabled = false;

  @Output() onChange = new EventEmitter<number>();

  periodControl = new FormControl();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['required']) {
      const required: boolean = changes['required'].currentValue;

      if (required) {
        this.periodControl.setValidators([Validators.required])
      }
      else {
        this.periodControl.setValidators([]);
      }
      this.periodControl.updateValueAndValidity();
    }

    if (changes['disabled']) {
      const value: boolean = changes['disabled'].currentValue;

      if (value) {
        this.periodControl.enabled ? this.periodControl.disable({ emitEvent: false }) : undefined;
      } else {
        this.periodControl.disabled ? this.periodControl.enable({ emitEvent: false }) : undefined;
      }
    }

  }

  ngOnInit() {
    this.periodControl.setValue(this.section.appointmentPeriod);

    this.periodControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged((x: number, y: number): boolean => x === y)
      )
      .subscribe((value: number) => this.onChange.emit(value));
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  clear = () => this.periodControl.setValue(undefined);

  addWeek = () => this.periodControl.setValue(7);
  addMonth = () => this.periodControl.setValue(30);
  addHalfYear = () => this.periodControl.setValue(180);
  addYear = () => this.periodControl.setValue(365);
}
