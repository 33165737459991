import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'mp-import-modal',
  templateUrl: './import-modal.component.html',
  styleUrls: ['./import-modal.component.scss']
})
export class ImportModalComponent implements OnInit {

  @Input() processing = false;

  @Input() message = '';
  @Input() failed = false;

  @Input() errors: string[] = [];

  @Output() onClose = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  close = () => this.onClose.emit();
}
