import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PriceSetsService, PricesService } from '../../../../generated/services';
import { PriceSetResolverPayload } from '../../resolvers/price-set-resolver';

import * as moment from 'moment';

import { Company, PriceSetService, ServiceCategory } from '../../../../generated/models';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { PriceSetPriceModalComponent, PriceSetPricePayload } from '../price-set-price-modal/price-set-price-modal.component';
import { switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DeleteConfirmationModalComponent } from '../../../../components/delete-confirmation-modal/delete-confirmation-modal.component';
import { ImportModalComponent } from '../import-modal/import-modal.component';

@Component({
  selector: 'mp-price-set',
  templateUrl: './price-set.component.html',
  styleUrls: ['./price-set.component.scss'],
  host: { class: "page" }
})
export class PriceSetComponent implements OnInit {
  pricesChanged$ = new Subject<void>();

  @ViewChild("xlsUpload") xlsUploadElement: ElementRef<HTMLInputElement>;

  title = 'Прайс-лист';

  id: number;
  name = '';
  inUse = false;
  categories: ServiceCategory[] = [];
  companies: Company[] = [];
  dmsInsuranceCompanies: Company[] = [];

  importing = false;
  exporting = false;

  get importDisabled() { return this.inUse || this.importing; }

  messages: { [key: string]: string } = {
    "Default": "Внутренняя ошибка сервера",
    "ForbiddenForPriceSetWithVisits": "Операция запрещена для прайс-листов, использованных при регистрации посещений",
    "NameRequired": "Необходимо указать название прайс-листа",
    "InvalidPeriod": "Недопустимый период действия прайс-листа",
    "PermissionRequired": "Для выполнения действия отсутствует разрешение",
    "CompanyNotFound": "Указанная компания не найдена",
    "InvalidCompanyType": "Недопустимый тип компании",
    "InsuranceCompanyNotFound": "Страховая компания ДМС не найдена",
    "InvalidInsuranceCompanyType": "Необходимо выбрать страховую компанию ДМС",
    "EffectiveRequired": "Необходимо указать дату начала действия прайс-листа",
    "PriceSetNotFound": "Прайс-лист не найден",
    "ForbiddenForDefaultPriceSet": "Операция запрещенв для прайс-листа по умолчанию",
    "ServiceNotFound": "Не удалось найти услугу",
    "ServiceIdRequired": "Ключ сервиса не найден",
    "DuplicateService": "Некоторые услуги добавлены более одного раза",
    "SyncCollectionEmpty": "Передан пустой список цен для синхронизации",
    "InvalidAmount": "Недопустимая цена",
    "InvalidDoctorWages": "Недопустимая зарплата врача",
    "InvalidCost": "Недопустимая сумма затрат"
  };

  get disablePrices() { return !this.id; }

  priceSetsForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    effective: new FormControl('', [Validators.required]),
    expires: new FormControl(''),
    default: new FormControl(false),
    companyId: new FormControl(null),
    insuranceCompanyId: new FormControl(null)
  });

  xlsUploader = new FormControl(null);

  constructor(
    private priceSetsService: PriceSetsService,
    private pricesService: PricesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private toastrService: ToastrService
  ) {

    this.xlsUploader.valueChanges
      .subscribe(() => {
        this.import(this.xlsUploadElement.nativeElement.files)
      });

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: PriceSetResolverPayload }): void => {
      if (data.payload.priceSet.id > 0) {
        const priceSet = data.payload.priceSet;
        this.id = priceSet.id;

        this.priceSetsForm.patchValue({
          name: priceSet.name,
          insuranceCompanyId: priceSet.insuranceCompanyId,
          effective: priceSet.effective && moment(priceSet.effective, "DD.MM.YYYY").isValid() ?
            moment(priceSet.effective, "DD.MM.YYYY") : undefined,
          expires: priceSet.expires && moment(priceSet.expires, "DD.MM.YYYY").isValid() ?
            moment(priceSet.expires, "DD.MM.YYYY") : undefined,
          default: priceSet.default,
          companyId: priceSet.companyId
        });
      }

      this.title = data.payload.priceSet.id > 0 ? `Прайс-лист: ${data.payload.priceSet.name}` : "Новый прайс-лист";
      this.name = data.payload.priceSet.name;
      this.inUse = data.payload.priceSet.inUse;

      this.categories = data.payload.serviceCategories;
      this.companies = data.payload.companies;
      this.dmsInsuranceCompanies = data.payload.dmsInsuranceCompanies;
    });
  }

  importXls = () => this.xlsUploadElement.nativeElement.click();

  async create() {
    const value: FormValue = this.priceSetsForm.getRawValue();

    try {
      await this.priceSetsService.Create({
        name: value.name,
        effective: (value.effective && value.effective.isValid()) ? value.effective.format("DD.MM.YYYY") : "",
        expires: (value.expires && value.expires.isValid()) ? value.expires.format("DD.MM.YYYY") : "",
        default: value.default,
        companyId: value.companyId,
        dmsInsuranceCompanyId: value.insuranceCompanyId
      }).toPromise();

      this.toastrService.success('Прайс-лист создан', 'Успешно');
      this.router.navigate(['..'], { relativeTo: this.activatedRoute });

    } catch (e) {
      this.processHttpError(e as HttpErrorResponse);
    }
  }

  async update() {
    const value: FormValue = this.priceSetsForm.getRawValue();

    try {
      await this.priceSetsService.Update({
        id: this.id,
        request: {
          name: value.name,
          effective: (value.effective && value.effective.isValid()) ? value.effective.format("DD.MM.YYYY") : "",
          expires: (value.expires && value.expires.isValid()) ? value.expires.format("DD.MM.YYYY") : "",
          default: value.default,
          companyId: value.companyId,
          dmsInsuranceCompanyId: value.insuranceCompanyId
        }
      }).toPromise();

      this.toastrService.success('Прайс-лист сохранен', 'Успешно');
      this.title = `Прайс-лист: ${value.name}`;
      this.name = value.name;
    } catch (e) {
      this.processHttpError(e as HttpErrorResponse);
    }
  }

  async acceptChanges(value: FormValue) {
    if (this.id > 0) {
      await this.update();
    } else {
      await this.create();
    }
  }

  private processHttpError(response: HttpErrorResponse): void {
    if (response.status === 400) {
      for (const error of response.error.errors) {

        if (error.status) {
          switch (error.status) {
            case 1: this.toastrService.warning('Прайс-лист не найден', 'Ошибка'); break;
            case 2: this.toastrService.warning('Необходимо указать дату начала действия прайс-листа', 'Ошибка'); break;
            case 5: this.toastrService.warning('Необходимо указать название прайс-листа', 'Ошибка'); break;
            case 6: this.toastrService.warning('Компания не найдена', 'Ошибка'); break;
            case 7: this.toastrService.warning('Необходимо выбрать собственную компанию', 'Ошибка'); break;
            case 8: this.toastrService.warning('Для выполнения действия отсутствует разрешение', 'Ошибка'); break;
            case 10: this.toastrService.warning('Недопустимый период действия прайс-листа', 'Ошибка'); break;
            case 11: this.toastrService.warning('Страховая компания ДМС не найдена', 'Ошибка'); break;
            case 11: this.toastrService.warning('Необходимо выбрать страховую компанию ДМС', 'Ошибка'); break;

            default: this.toastrService.error('Ошибка при сохранении', 'Ошибка'); break;
          }
        }

        if (typeof error === 'string') {
          this.toastrService.warning(this.messages[error] || this.messages['Default'], 'Ошибка'); break;
        }

      }

      return;
    }

    this.toastrService.error('Ошибка при сохранении', 'Ошибка')

  }

  editPrice(price: PriceSetService): void {
    const modalRef: NgbModalRef = this.modal.open(PriceSetPriceModalComponent, { backdrop: 'static', centered: true, size: 'lg' });
    const componentRef: PriceSetPriceModalComponent = modalRef.componentInstance;

    componentRef.priceName = this.priceSetsForm.get('name').value;
    componentRef.price = price;

    componentRef.onCancel.subscribe(() => modalRef.dismiss());

    componentRef.onConfirm
      .pipe(
        switchMap((value: PriceSetPricePayload) => {
          if (price.id > 0) {
            return this.pricesService.Edit({
              id: price.id,
              model: {
                priceSetId: this.id,
                serviceId: price.serviceId,
                costs: value.costs,
                wages: value.wages,
                price: value.price,
                quantity: value.quantity
              }
            });
          }

          return this.pricesService.Add({
            serviceId: price.serviceId,
            priceSetId: this.id,
            costs: value.costs,
            wages: value.wages,
            price: value.price,
            quantity: value.quantity
          });
        })
      ).subscribe(
        () => {
          this.toastrService.success('Цена сохранена', 'Успешно');
          this.pricesChanged$.next();
          modalRef.close();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.toastrService.error(error.error.message, 'Ошибка');
          }
          else {
            this.toastrService.error('Ошибка сохранения', 'Ошибка');
          }
        });
  }

  deletePrice(service: PriceSetService): void {
    const modalRef: NgbModalRef = this.modal.open(DeleteConfirmationModalComponent, { backdrop: 'static', centered: true });
    const componentRef: DeleteConfirmationModalComponent = modalRef.componentInstance;

    componentRef.message = `Удалить цену для услуги ${service.name}?`;

    modalRef.result.then(
      () => {
        this.pricesService.Delete(service.id).subscribe(
          (): void => {
            this.pricesChanged$.next();

            this.toastrService.success('Цена удалёна', 'Успешно');
          },
          (error: HttpErrorResponse): void => {
            if (error.status === 400) {
              this.toastrService.error(error.error.message, 'Ошибка');
              return;
            }

            this.toastrService.error('Не удалось удалить цену', 'Отмена');
          }
        )
      },
      () => { }
    )
  }

  export(format: string) {
    if (this.exporting) return;

    this.exporting = true;

    this.toastrService.success('Документ формируется');

    this.priceSetsService.Export({ format: format, id: this.id })
      .subscribe(
        (response: Blob) => {
          this.exporting = false;

          const url = URL.createObjectURL(response);

          const a: HTMLAnchorElement = document.createElement("a");
          a.style.display = 'none';
          a.href = url;
          a.download = `${this.name} - выгрузка ${moment().format("YYYY_MM_DD HH_mm_ss")}.xlsx`;

          document.body.append(a);

          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        },
        () => {
          this.toastrService.error('Не удалось выгрузить прайс-лист', 'Ошибка');

          this.exporting = false;
        }
      );

  }

  import(files: FileList) {
    if (this.importing) return;
    if (files.length === 0) return;

    const modalRef = this.modal.open(ImportModalComponent, { centered: true, backdrop: 'static' });

    const componentRef: ImportModalComponent = modalRef.componentInstance;
    componentRef.message = `Импорт цен прайс-листа`;
    componentRef.onClose.subscribe(() => {
      modalRef.close();
    });

    this.importing = true;

    componentRef.processing = true;

    this.priceSetsService.Import({ id: this.id, format: "xlsx", file: files[0] })
      .subscribe(
        () => {
          this.importing = false;
          componentRef.processing = true;
          this.toastrService.success("Документ загружен", 'Успешно');

          modalRef.close();

          this.xlsUploader.reset();

          this.pricesChanged$.next();
        },
        (response: HttpErrorResponse) => {
          this.importing = false;

          componentRef.message = 'Не удалось импортировать цены';
          componentRef.failed = true;
          componentRef.processing = false;

          // workaround?
          let data = typeof response.error === 'string' ? JSON.parse(response.error) : response.error;

          if (data && data.errors && data.errors.length > 0) {

            for (const error of data.errors) {
              if (error.formattedMessagePlaceholderValues) {
                componentRef.errors.push(`Ошибка в строке ${error.formattedMessagePlaceholderValues.CollectionIndex + 1}: ${error.errorMessage}`);
              }
              if (error.status) {
                switch (error.status) {
                  case 15: componentRef.errors.push('Некоторые услуги добавлены более одного раза'); break;
                  case 16: componentRef.errors.push('Не удалось найти услугу'); break;
                }
              }

              if (typeof error === 'string') {
                componentRef.errors.push(this.messages[error] || this.messages['Default'])
              }
            }
          }
          else {
            componentRef.errors = [this.messages['Default']];
          }

          //modalRef.close();
          this.xlsUploader.reset();
        }
      );
  }

}

interface FormValue {
  name: string;
  effective: moment.Moment;
  expires: moment.Moment;
  default: boolean;
  companyId: number;
  insuranceCompanyId: number
}
