import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CodeSystem } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-code-system-modal',
  templateUrl: './employee-code-system-modal.component.html',
  styleUrls: ['./employee-code-system-modal.component.scss']
})
export class EmployeeCodeSystemModalComponent implements OnInit {

  public title: string;
  public form: FormGroup;

  @Input() codeSystems: CodeSystem[];

  @Output()
  onClose: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onSubmit: EventEmitter<{ codeSystem: number; value: string }> = new EventEmitter<{ codeSystem: number; value: string }>();

  constructor() {
    this.title = "Код сотрудника";

    this.form = new FormGroup({
      codeSystem: new FormControl(0, [Validators.required]),
      value: new FormControl("", [Validators.required])
    })
  }

  ngOnInit() {
  }

  public cancel(): void {
    this.onClose.emit();
  }

  public submit(): void {
    this.form.get('codeSystem').markAsTouched();
    this.form.get('value').markAsTouched();

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    this.onSubmit.emit({
      codeSystem: value.codeSystem,
      value: value.value
    });
  }

}

interface FormValue {
  codeSystem: number;
  value: string;
}
