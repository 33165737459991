import { Component, OnInit, Input } from '@angular/core';
import { TherapyResult } from '../../../../generated/models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mp-oms-result-modal',
  templateUrl: './oms-result-modal.component.html',
  styleUrls: ['./oms-result-modal.component.scss']
})
export class OmsResultModalComponent implements OnInit {

  @Input() title: string;
  @Input() results: TherapyResult[];
  @Input() selected: number;

  searchForm: FormGroup;

  private _results: TherapyResult[];
  private _filteredResults: TherapyResult[];

  constructor(
    private activeModal: NgbActiveModal
  ) {
    this.searchForm = new FormGroup({
      query: new FormControl('')
    })

    this._filteredResults = [];

    this.searchForm.get('query').valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value: string) => {
        console.log(value);
        if (value === "") {
          this._filteredResults = this._results;
        }
        else {
          this._filteredResults = this._results.filter(x => x.name.toLowerCase().includes(value.toLowerCase()))
        }
    })
  }

  ngOnInit() {
    this._results = [...this.results];
    this._results.unshift({ id: null, name: "Не указано" });
    this._filteredResults = this._results;
  }

  public select = (result: TherapyResult): void => {
    this.activeModal.close(result)
  }

  public dismiss = (): void => {
    this.activeModal.dismiss();
  }

  public get filteredResults(): TherapyResult[] { return this._filteredResults }

}
