/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PbxOperatorsResponse } from '../models/pbx-operators-response';
import { AddPbxOperatorResponse } from '../models/add-pbx-operator-response';
import { PbxOperatorViewModel } from '../models/pbx-operator-view-model';
import { Pbx } from '../models/pbx';
@Injectable({
  providedIn: 'root',
})
class PbxOperatorsService extends __BaseService {
  static readonly PbxOperatorsPath = '/api/PbxOperators';
  static readonly CreatePbxOperatorPath = '/api/PbxOperators';
  static readonly PbxOperatorPath = '/api/PbxOperators/{id}';
  static readonly UpdatePbxOperatorPath = '/api/PbxOperators/{id}';
  static readonly DeletePbxOperatorPath = '/api/PbxOperators/{id}';
  static readonly PbxListPath = '/api/PbxOperators/pbxes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PbxOperatorsService.PbxOperatorsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  PbxOperatorsResponse(params: PbxOperatorsService.PbxOperatorsParams): __Observable<__StrictHttpResponse<PbxOperatorsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PbxOperators`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PbxOperatorsResponse>;
      })
    );
  }
  /**
   * @param params The `PbxOperatorsService.PbxOperatorsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  PbxOperators(params: PbxOperatorsService.PbxOperatorsParams): __Observable<PbxOperatorsResponse> {
    return this.PbxOperatorsResponse(params).pipe(
      __map(_r => _r.body as PbxOperatorsResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreatePbxOperatorResponse(request?: PbxOperatorViewModel): __Observable<__StrictHttpResponse<AddPbxOperatorResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/PbxOperators`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddPbxOperatorResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreatePbxOperator(request?: PbxOperatorViewModel): __Observable<AddPbxOperatorResponse> {
    return this.CreatePbxOperatorResponse(request).pipe(
      __map(_r => _r.body as AddPbxOperatorResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  PbxOperatorResponse(id: number): __Observable<__StrictHttpResponse<PbxOperatorViewModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PbxOperators/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PbxOperatorViewModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  PbxOperator(id: number): __Observable<PbxOperatorViewModel> {
    return this.PbxOperatorResponse(id).pipe(
      __map(_r => _r.body as PbxOperatorViewModel)
    );
  }

  /**
   * @param params The `PbxOperatorsService.UpdatePbxOperatorParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdatePbxOperatorResponse(params: PbxOperatorsService.UpdatePbxOperatorParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/PbxOperators/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PbxOperatorsService.UpdatePbxOperatorParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdatePbxOperator(params: PbxOperatorsService.UpdatePbxOperatorParams): __Observable<null> {
    return this.UpdatePbxOperatorResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeletePbxOperatorResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/PbxOperators/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  DeletePbxOperator(id: number): __Observable<null> {
    return this.DeletePbxOperatorResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  PbxListResponse(): __Observable<__StrictHttpResponse<Array<Pbx>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/PbxOperators/pbxes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Pbx>>;
      })
    );
  }
  /**
   * @return Success
   */
  PbxList(): __Observable<Array<Pbx>> {
    return this.PbxListResponse().pipe(
      __map(_r => _r.body as Array<Pbx>)
    );
  }
}

module PbxOperatorsService {

  /**
   * Parameters for PbxOperators
   */
  export interface PbxOperatorsParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for UpdatePbxOperator
   */
  export interface UpdatePbxOperatorParams {
    id: number;
    request?: PbxOperatorViewModel;
  }
}

export { PbxOperatorsService }
