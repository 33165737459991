import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ColorMarksService } from '../../../../generated/services';
import { ColorMarkResolverPayload } from '../../resolvers/color-mark-resolver';

@Component({
  selector: 'mp-color-mark',
  templateUrl: './color-mark.component.html',
  styleUrls: ['./color-mark.component.scss']
})
export class ColorMarkComponent implements OnInit {
  id: number;

  title = "Новая цветовая метка";

  breadcrumbs = [
    { url: '/', title: 'Главная' },
    { url: '/settings/color-marks', title: 'Цветовые метки' }
  ];

  form: FormGroup;

  constructor(
    private service: ColorMarksService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService
  ) {
    this.form = new FormGroup({
      name: new FormControl("", [Validators.required]),
      color: new FormControl("", [Validators.required]),
      archived: new FormControl(false)
    });
  }

  async ngOnInit(): Promise<void> {

    this.activatedRoute.data
      .subscribe((data: { payload: ColorMarkResolverPayload }): void => {
        this.id = data.payload.colorMark.id;

        if (this.id) {
          this.title = `Цветовая метка ${data.payload.colorMark.name}`;
        }

        this.form.patchValue({ ...data.payload.colorMark }, { emitEvent: false });
      });

  }

  async acceptChanges(): Promise<void> {
    Object.entries(this.form.controls).forEach(x => x[1].markAsTouched());

    if (!this.form.valid) return;

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      try {
        await this.service.UpdateAsync({ id: this.id, request: { ...value } }).toPromise();
        this.title = `Цветовая метка ${value.name}`;
        this.toastrService.success('Цветовая метка обновлена', 'Успешно');
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          const message = response.error.errors.map(x => x.message).join('<br/>');

          this.toastrService.warning(message, 'Ошибка');
          return;
        }

        this.toastrService.error('Не удалось обновить цветовую метку', 'Ошибка');
      }
    }
    else {
      try {
        const result = await this.service.CreateAsync({ ...value }).toPromise();
        this.toastrService.success('Цветовая метка добавлена', 'Успешно');
        this.router.navigate(['..', result.colorMarkId], { relativeTo: this.activatedRoute });
      } catch (e) {
        const response = e as HttpErrorResponse;

        if (response.status === 400) {
          const message = response.error.errors.map(x => x.message).join('<br/>');

          this.toastrService.warning(message, 'Ошибка');
          return;
        }

        this.toastrService.error('Не удалось добавить цветовую метку', 'Ошибка');
      }
    }
  }

}

interface FormValue {
  name: string;
  color: string;
  archived: boolean;
}
