import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';
import { EmployeeService, EditEmployeeServiceModel } from 'projects/Clinic/src/app/generated/models';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'mp-employee-service-modal',
  templateUrl: './employee-service.modal.component.html',
  styleUrls: ['./employee-service.modal.component.scss']
})
export class EmployeeServiceModalComponent implements OnInit {
  @Input("service") service: EmployeeService;

  public title: string;

  public form: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private employeesService: EmployeesService
  ) {

    this.form = new FormGroup({
      minAge: new FormControl(""),
      maxAge: new FormControl(""),
    });

    this.title = "Услуга";
  }

  ngOnInit() {
    this.title = `Услуга \"${this.service.serviceName}\"`;

    this.form.get("minAge").setValue(this.service.minAge);
    this.form.get("maxAge").setValue(this.service.maxAge);
  }

  public submit() {
    this.employeesService.UpdateService({
      serviceId: this.service.serviceId,
      id: this.service.employeeId,
      model: {
        minAge: this.form.get("minAge").value,
        maxAge: this.form.get("maxAge").value
      }
    })
      .subscribe(
        (updated: EditEmployeeServiceModel): void => {
          this.activeModal.close(updated);
        }
      );

  }

  public cancel(): void {
    this.activeModal.dismiss();
  }
}
