import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonMarkComponent } from './components/person-mark/person-mark.component';
import { NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PersonMarkModalComponent } from './components/person-mark-modal/person-mark-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PersonMarkComponent, PersonMarkModalComponent],
  exports: [PersonMarkComponent],
  entryComponents: [PersonMarkModalComponent],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    NgbPopoverModule,
    NgbModalModule
  ]
})
export class PersonMarksModule { }
