import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AvailableResourceEmployee } from '../../../../generated/models/available-resource-employee';

@Component({
  selector: 'mp-schedule-employee-modal',
  templateUrl: './schedule-employee.modal.component.html',
  styleUrls: ['./schedule-employee.modal.component.scss']
})
export class ScheduleEmployeeModalComponent implements OnInit {
  title = 'Добавление сотрудника';

  @Input() doctors: AvailableResourceEmployee[] = [];

  @Output()
  onCancel = new EventEmitter<void>();

  @Output()
  onSubmit = new EventEmitter<number>();

  form = new FormGroup({
    employeeId: new FormControl(undefined, [Validators.required])
  });

  constructor() { }

  ngOnInit() { }

  cancel = () => this.onCancel.emit();

  submit(): void {
    this.form.markAsTouched();

    if (!this.form.valid) return;

    const value: FormValue = this.form.getRawValue();
    this.onSubmit.emit(value.employeeId);
  }
}

interface FormValue {
  employeeId: number;
}
