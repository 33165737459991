import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonLabOrder, PersonLabResult } from '../../../../generated/models';

@Component({
  selector: 'mp-lab-results-parameter',
  templateUrl: './lab-results-parameter.component.html',
  styleUrls: ['./lab-results-parameter.component.scss']
})
export class LabResultsParameterComponent implements OnInit {

  @Input() result: PersonLabOrder = {};
  @Input() copyAsTable = false;
  @Input() copyAsList = false;

  @Output() onCopy = new EventEmitter<PersonLabResult>();
  @Output() onCopyAll = new EventEmitter<void>();
  @Output() onCopyTable = new EventEmitter<void>();
  @Output() onCopyList = new EventEmitter<void>();

  @Output() onOpen = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  copy = (test: PersonLabResult) => this.onCopy.emit(test);
  copyAll = () => this.result && this.result.groups && this.result.groups.length > 0 ? this.onCopyAll.emit() : undefined;
  open = () => this.result && this.result.groups && this.result.groups.length > 0 ? this.onOpen.emit() : undefined;

  copyTable = () => this.onCopyTable.emit();
  copyList = () => this.onCopyList.emit();
}
