import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ScheduleBreakReason } from '../../../../generated/models';
import { ScheduleBreakReasonsService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-schedule-break-reasons',
  templateUrl: './schedule-break-reasons.component.html',
  styleUrls: ['./schedule-break-reasons.component.scss'],
  host: { class: 'page' }
})
export class ScheduleBreakReasonsComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = true;
  page = 1;
  size = 50;

  items: ScheduleBreakReason[] = [];

  form = new FormGroup({
    search: new FormControl()
  });

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: ScheduleBreakReasonsService,
    private confirmationsService: ConfirmationModalService,
    private toastrService: ToastrService) {

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (value: FiltersValue): Promise<void> => {
        this.page = 1;
        this.loading = true;
        this.items = [];
        this.items = await this.load();
        this.loading = false;
      });
  }

  async ngOnInit() {
    this.loading = true;
    this.items = await this.load();
    this.loading = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async load() {
    const filters: FiltersValue = this.form.getRawValue();

    return this.service.ReasonsAsync({ Page: this.page, Size: this.size, Search: filters.search }).toPromise();
  }

  async onScrollDown() {
    this.page++;

    this.loading = true;
    this.items.push(...await this.load());
    this.loading = false;
  }

  create = () => this.router.navigate(['new'], { relativeTo: this.activatedRoute });

  edit = (item: ScheduleBreakReason) => this.router.navigate([item.id], { relativeTo: this.activatedRoute });

  async remove(item: ScheduleBreakReason) {
    const confirmed = await this.confirmationsService.open({ message: `Причина перерыва «${item.description}» будет удалена. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.service.DeleteAsync(item.id)
      .subscribe(
        async () => {
          this.toastrService.success('Причина перерыва удалена', 'Успешно');

          this.page = 1;
          this.items = [];
          this.loading = true;
          this.items = await this.load();
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          if (response.status === 400) {
            for (const error of response.error) {
              this.toastrService.warning(error.message, 'Ошибка');
            }
          } else {
            this.toastrService.error(`Не удалось удалить причину перерыва "${item.description}"`, 'Ошибка');
          }
        }
      );
  }
}

interface FiltersValue {
  search: string;
}
