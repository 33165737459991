/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QuestionnairesResponse } from '../models/questionnaires-response';
import { EditQuestionnaireModel } from '../models/edit-questionnaire-model';
import { QuestionnaireResponse } from '../models/questionnaire-response';
import { AddQuestionnaireQuestionModel } from '../models/add-questionnaire-question-model';
import { DeleteQuestionnaireModel } from '../models/delete-questionnaire-model';
import { AddQuestionnaireOptionModel } from '../models/add-questionnaire-option-model';
import { ThresholdResponse } from '../models/threshold-response';
import { ManageQuestionnaireModel } from '../models/manage-questionnaire-model';
@Injectable({
  providedIn: 'root',
})
class QuestionnairesService extends __BaseService {
  static readonly QuestionnairesPath = '/api/Questionnaires';
  static readonly CreatePath = '/api/Questionnaires';
  static readonly GetPath = '/api/Questionnaires/{id}';
  static readonly EditPath = '/api/Questionnaires/{questionnaireId}';
  static readonly RemovePath = '/api/Questionnaires/{questionnaireId}';
  static readonly AddQuestionPath = '/api/Questionnaires/{questionnaireId}/questions';
  static readonly EditQuestionPath = '/api/Questionnaires/{questionnaireId}/questions';
  static readonly DeleteQuestionPath = '/api/Questionnaires/{questionnaireId}/questions';
  static readonly AddOptionPath = '/api/Questionnaires/{questionnaireId}/options';
  static readonly EditOptionPath = '/api/Questionnaires/{questionnaireId}/options';
  static readonly DeleteOptionPath = '/api/Questionnaires/{questionnaireId}/options';
  static readonly AddThresholdPath = '/api/Questionnaires/{questionnaireId}/thresholds';
  static readonly EditThresholdPath = '/api/Questionnaires/{questionnaireId}/thresholds';
  static readonly DeleteThresholdPath = '/api/Questionnaires/{questionnaireId}/thresholds';
  static readonly ManagePath = '/api/Questionnaires/{id}/manage';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `QuestionnairesService.QuestionnairesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  QuestionnairesResponse(params: QuestionnairesService.QuestionnairesParams): __Observable<__StrictHttpResponse<QuestionnairesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.ActiveOnly != null) __params = __params.set('ActiveOnly', params.ActiveOnly.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Questionnaires`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuestionnairesResponse>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.QuestionnairesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `ActiveOnly`:
   *
   * @return Success
   */
  Questionnaires(params: QuestionnairesService.QuestionnairesParams): __Observable<QuestionnairesResponse> {
    return this.QuestionnairesResponse(params).pipe(
      __map(_r => _r.body as QuestionnairesResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: EditQuestionnaireModel): __Observable<__StrictHttpResponse<EditQuestionnaireModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Questionnaires`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditQuestionnaireModel>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: EditQuestionnaireModel): __Observable<EditQuestionnaireModel> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as EditQuestionnaireModel)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GetResponse(id: number): __Observable<__StrictHttpResponse<QuestionnaireResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Questionnaires/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QuestionnaireResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Get(id: number): __Observable<QuestionnaireResponse> {
    return this.GetResponse(id).pipe(
      __map(_r => _r.body as QuestionnaireResponse)
    );
  }

  /**
   * @param params The `QuestionnairesService.EditParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditResponse(params: QuestionnairesService.EditParams): __Observable<__StrictHttpResponse<EditQuestionnaireModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditQuestionnaireModel>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.EditParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  Edit(params: QuestionnairesService.EditParams): __Observable<EditQuestionnaireModel> {
    return this.EditResponse(params).pipe(
      __map(_r => _r.body as EditQuestionnaireModel)
    );
  }

  /**
   * @param questionnaireId undefined
   */
  RemoveResponse(questionnaireId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Questionnaires/${questionnaireId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param questionnaireId undefined
   */
  Remove(questionnaireId: number): __Observable<null> {
    return this.RemoveResponse(questionnaireId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `QuestionnairesService.AddQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddQuestionResponse(params: QuestionnairesService.AddQuestionParams): __Observable<__StrictHttpResponse<AddQuestionnaireQuestionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/questions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddQuestionnaireQuestionModel>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.AddQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddQuestion(params: QuestionnairesService.AddQuestionParams): __Observable<AddQuestionnaireQuestionModel> {
    return this.AddQuestionResponse(params).pipe(
      __map(_r => _r.body as AddQuestionnaireQuestionModel)
    );
  }

  /**
   * @param params The `QuestionnairesService.EditQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditQuestionResponse(params: QuestionnairesService.EditQuestionParams): __Observable<__StrictHttpResponse<AddQuestionnaireQuestionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/questions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddQuestionnaireQuestionModel>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.EditQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditQuestion(params: QuestionnairesService.EditQuestionParams): __Observable<AddQuestionnaireQuestionModel> {
    return this.EditQuestionResponse(params).pipe(
      __map(_r => _r.body as AddQuestionnaireQuestionModel)
    );
  }

  /**
   * @param params The `QuestionnairesService.DeleteQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteQuestionResponse(params: QuestionnairesService.DeleteQuestionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/questions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.DeleteQuestionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteQuestion(params: QuestionnairesService.DeleteQuestionParams): __Observable<null> {
    return this.DeleteQuestionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `QuestionnairesService.AddOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddOptionResponse(params: QuestionnairesService.AddOptionParams): __Observable<__StrictHttpResponse<AddQuestionnaireOptionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddQuestionnaireOptionModel>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.AddOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddOption(params: QuestionnairesService.AddOptionParams): __Observable<AddQuestionnaireOptionModel> {
    return this.AddOptionResponse(params).pipe(
      __map(_r => _r.body as AddQuestionnaireOptionModel)
    );
  }

  /**
   * @param params The `QuestionnairesService.EditOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditOptionResponse(params: QuestionnairesService.EditOptionParams): __Observable<__StrictHttpResponse<AddQuestionnaireOptionModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddQuestionnaireOptionModel>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.EditOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditOption(params: QuestionnairesService.EditOptionParams): __Observable<AddQuestionnaireOptionModel> {
    return this.EditOptionResponse(params).pipe(
      __map(_r => _r.body as AddQuestionnaireOptionModel)
    );
  }

  /**
   * @param params The `QuestionnairesService.DeleteOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteOptionResponse(params: QuestionnairesService.DeleteOptionParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/options`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.DeleteOptionParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteOption(params: QuestionnairesService.DeleteOptionParams): __Observable<null> {
    return this.DeleteOptionResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `QuestionnairesService.AddThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddThresholdResponse(params: QuestionnairesService.AddThresholdParams): __Observable<__StrictHttpResponse<ThresholdResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/thresholds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ThresholdResponse>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.AddThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddThreshold(params: QuestionnairesService.AddThresholdParams): __Observable<ThresholdResponse> {
    return this.AddThresholdResponse(params).pipe(
      __map(_r => _r.body as ThresholdResponse)
    );
  }

  /**
   * @param params The `QuestionnairesService.EditThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditThresholdResponse(params: QuestionnairesService.EditThresholdParams): __Observable<__StrictHttpResponse<ThresholdResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/thresholds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ThresholdResponse>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.EditThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   *
   * @return Success
   */
  EditThreshold(params: QuestionnairesService.EditThresholdParams): __Observable<ThresholdResponse> {
    return this.EditThresholdResponse(params).pipe(
      __map(_r => _r.body as ThresholdResponse)
    );
  }

  /**
   * @param params The `QuestionnairesService.DeleteThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteThresholdResponse(params: QuestionnairesService.DeleteThresholdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Questionnaires/${params.questionnaireId}/thresholds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `QuestionnairesService.DeleteThresholdParams` containing the following parameters:
   *
   * - `questionnaireId`:
   *
   * - `model`:
   */
  DeleteThreshold(params: QuestionnairesService.DeleteThresholdParams): __Observable<null> {
    return this.DeleteThresholdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ManageResponse(id: number): __Observable<__StrictHttpResponse<ManageQuestionnaireModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Questionnaires/${id}/manage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ManageQuestionnaireModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Manage(id: number): __Observable<ManageQuestionnaireModel> {
    return this.ManageResponse(id).pipe(
      __map(_r => _r.body as ManageQuestionnaireModel)
    );
  }
}

module QuestionnairesService {

  /**
   * Parameters for Questionnaires
   */
  export interface QuestionnairesParams {
    Size?: number;
    Search?: string;
    Page?: number;
    ActiveOnly?: boolean;
  }

  /**
   * Parameters for Edit
   */
  export interface EditParams {
    questionnaireId: number;
    model?: EditQuestionnaireModel;
  }

  /**
   * Parameters for AddQuestion
   */
  export interface AddQuestionParams {
    questionnaireId: number;
    model?: AddQuestionnaireQuestionModel;
  }

  /**
   * Parameters for EditQuestion
   */
  export interface EditQuestionParams {
    questionnaireId: number;
    model?: AddQuestionnaireQuestionModel;
  }

  /**
   * Parameters for DeleteQuestion
   */
  export interface DeleteQuestionParams {
    questionnaireId: number;
    model?: DeleteQuestionnaireModel;
  }

  /**
   * Parameters for AddOption
   */
  export interface AddOptionParams {
    questionnaireId: number;
    model?: AddQuestionnaireOptionModel;
  }

  /**
   * Parameters for EditOption
   */
  export interface EditOptionParams {
    questionnaireId: number;
    model?: AddQuestionnaireOptionModel;
  }

  /**
   * Parameters for DeleteOption
   */
  export interface DeleteOptionParams {
    questionnaireId: number;
    model?: DeleteQuestionnaireModel;
  }

  /**
   * Parameters for AddThreshold
   */
  export interface AddThresholdParams {
    questionnaireId: number;
    model?: ThresholdResponse;
  }

  /**
   * Parameters for EditThreshold
   */
  export interface EditThresholdParams {
    questionnaireId: number;
    model?: ThresholdResponse;
  }

  /**
   * Parameters for DeleteThreshold
   */
  export interface DeleteThresholdParams {
    questionnaireId: number;
    model?: DeleteQuestionnaireModel;
  }
}

export { QuestionnairesService }
