import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PersonTaxDeduction } from '../../../../generated/models';

import * as moment from 'moment';

@Component({
  selector: 'mp-tax-deduction-history-item',
  templateUrl: './tax-deduction-history-item.component.html',
  styleUrls: ['./tax-deduction-history-item.component.scss']
})
export class TaxDeductionHistoryItemComponent implements OnInit {

  @Input() item: PersonTaxDeduction;

  @Output() onPrint = new EventEmitter<void>();
  @Output() onRemove = new EventEmitter<void>();

  currencyFormat: Intl.NumberFormatOptions = {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  };

  @Input() canRemove = false;

  constructor() { }

  ngOnInit() { }

  get total(): string { return ''; }

  get date(): string { return moment(this.item.created).format('DD.MM.YYYY HH:mm'); }

  print = () => this.onPrint.emit();
  remove = () => this.onRemove.emit();
}
