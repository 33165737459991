import { Injectable } from '@angular/core';
import { Speciality, Company, Resource, SpecialitiesResponse, Category, CategoriesResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { SpecialitiesService, CompaniesService, ScheduleSettingsService, CategoriesService } from '../../../generated/services';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ScheduleItemResolver implements Resolve<ScheduleItemPayload>{

  constructor(
    private specialitiesService: SpecialitiesService,
    private categoriesService: CategoriesService,
    private scheduleSettingsService: ScheduleSettingsService,
    private companiesServcie: CompaniesService

  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ScheduleItemPayload> {
    const specialitiesLoader: Observable<Speciality[]> = this.specialitiesService.Specialities({ Page: 1, Size: 0 })
      .pipe(
        map((response: SpecialitiesResponse): Speciality[] => response.items)
      );

    const categoriesLoader: Observable<Category[]> = this.categoriesService.Categories({ Page: 1, Size: 0 })
      .pipe(
        map((response: CategoriesResponse): Category[] => response.items)
      );

    const id: number = parseInt(route.paramMap.get("id"), 10);

    let resourceLoader: Observable<Resource> = undefined;
    if (id) {
      resourceLoader = this.scheduleSettingsService.Resource(id);
    } else {
      const newService: any = {};

      resourceLoader = from([newService]);
    }

    const companiesLoader: Observable<Company[]> = this.companiesServcie.Companies({});

    return forkJoin({
      specialities: specialitiesLoader,
      categories: categoriesLoader,
      resource: resourceLoader,
      companies: companiesLoader
    });
  }
}

export class ScheduleItemPayload {
  public specialities: Speciality[];
  public categories: Category[];
  public companies: Company[];
  public resource: Resource;
}
