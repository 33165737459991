import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import { v4 as uuidv4 } from 'uuid';

import { EmployeesModalComponent } from '../employees-modal/employees-modal.component';
import { SalesModalComponent } from '../sales-modal/sales-modal.component';
import { ReportTypes } from '../../models/reportTypes';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ReportsPayload } from '../../resolvers/reports-resolver';
import { Company, ReportEmployee, ReportPromotion, ReportDiscount, ReportService, ReportGroup } from 'projects/Clinic/src/app/generated/models';
import { LinksService, MyService } from 'projects/Clinic/src/app/generated/services';
import { UserStorage } from '../../../../services/user-storage';
import { PromotionsModalComponent } from '../promotions-modal/promotions-modal.component';
import { ServicesModalComponent } from '../services-modal/services-modal.component';
import { CustomersModalComponent } from '../customers-modal/customers-modal.component';
import { ReportsUtilityService } from '../../../shared/services/reports-url-service';
import { ToastrService } from 'ngx-toastr';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReportsComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  ReportTypes = ReportTypes;

  promotions: ReportPromotion[] = [];
  medicalCenters: Company[] = [];
  employees: ReportEmployee[] = [];
  discounts: ReportDiscount[] = [];
  services: ReportService[] = [];
  customers: Company[] = [];

  activeDiscounts: ReportDiscount[] = [];
  activeEmployees: ReportEmployee[] = [];
  activePromotions: ReportPromotion[] = [];

  showingArchivePricelists = true;
  showingArchivePartners = false;

  groups: ReportGroup[] = [];

  enabledReports: string[] = [];

  allowedReports: { [name: string]: number[] } = {};

  filters: ReportsFiltersValue = {
    dateRange: { from: moment(), to: moment() },
    selectedCompanies: [],

    allDiscountsSelected: false,
    selectedDiscounts: [],

    allPromotionsSelected: false,
    selectedPromotions: [],

    allServicesSelected: false,
    selectedServices: [],

    allEmployeesSelected: false,
    allPartnersSelected: false,
    allSpecialistsSelected: false,
    selectedEmployees: [],

    selectedCustomers: []
  };

  constructor(
    private router: Router,
    private modal: NgbModal,
    private sanitaizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private userStorage: UserStorage,
    private myService: MyService,
    private changeDetectorRef: ChangeDetectorRef,
    private reportsUtilityService: ReportsUtilityService,
    private toastrService: ToastrService,
    private linksService: LinksService
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (data: { payload: ReportsPayload }): void => {
          this.medicalCenters = data.payload.companies;
          this.employees = data.payload.employees;
          this.discounts = data.payload.discounts;

          this.promotions = data.payload.promotions;
          this.services = data.payload.services;
          this.customers = data.payload.customers;

          this.activeDiscounts = this.discounts.filter(x =>
            moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
            && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
          );

          this.activeEmployees = this.employees.filter(x =>
            moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
            && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
          );


          this.activePromotions = this.promotions.filter(x =>
            moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
            && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
          );

          this.groups = data.payload.groups;

          const queryMap = this.activatedRoute.snapshot.queryParamMap;

          const queryCompanies = queryMap.getAll('company').map(x => parseInt(x));
          const queryEmployees = queryMap.getAll('employee').map(x => parseInt(x));
          const queryServices = queryMap.getAll('service').map(x => parseInt(x));
          const queryDiscounts = queryMap.getAll('discount').map(x => parseInt(x));
          const queryPromotions = queryMap.getAll('promotion').map(x => parseInt(x));
          const queryCustomers = queryMap.getAll('customer').map(x => parseInt(x));

          const allSpecialists = queryMap.get('allSpecialists') === 'true';
          const allPartners = queryMap.get('allPartners') === 'true';
          const allEmployees = queryMap.get('allEmployees') === 'true';

          const allServices = queryMap.get('allServices') === 'true';
          const allDiscounts = queryMap.get('allDiscounts') === 'true';
          const allPromotions = queryMap.get('allPromotions') === 'true';

          this.filters = {
            dateRange: {
              from: queryMap.has('from') && moment(queryMap.get('from'), 'DD.MM.YYYY').isValid() ? moment(queryMap.get('from'), 'DD.MM.YYYY') : moment(),
              to: queryMap.has('to') && moment(queryMap.get('to'), 'DD.MM.YYYY').isValid() ? moment(queryMap.get('to'), 'DD.MM.YYYY') : moment(),
            },

            selectedCompanies: queryCompanies.length > 0 ?
              this.medicalCenters.filter((x: Company): boolean => queryCompanies.includes(x.id)) :
              this.medicalCenters.filter((x: Company): boolean => x.id === this.userStorage.profile.companyId),

            selectedCustomers: queryCustomers.length > 0 ? this.customers.filter(x => queryCustomers.includes(x.id)) : [],
            selectedDiscounts: !allDiscounts && queryDiscounts.length > 0 ? this.activeDiscounts.filter(x => queryDiscounts.includes(x.id)) : [],
            selectedEmployees: !allEmployees && !allPartners && !allSpecialists && queryEmployees.length > 0 ? this.activeEmployees.filter(x => queryEmployees.includes(x.id)) : [],
            selectedPromotions: !allPromotions && queryPromotions.length > 0 ? this.activePromotions.filter(x => queryPromotions.includes(x.id)) : [],
            selectedServices: !allServices && queryServices.length > 0 ? this.services.filter(x => queryServices.includes(x.id)) : [],

            allSpecialistsSelected: allSpecialists,
            allEmployeesSelected: allEmployees,
            allPartnersSelected: allPartners,

            allServicesSelected: allServices,
            allDiscountsSelected: allDiscounts,
            allPromotionsSelected: allPromotions

          };
        });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  updateFilters(filters: ReportsFiltersValue): void {
    this.filters = {
      ...filters,

      selectedEmployees: filters.selectedEmployees.filter(x =>
        moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
        && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))),

      selectedDiscounts: filters.selectedDiscounts.filter(x =>
        moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
        && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))),

      selectedPromotions: filters.selectedPromotions.filter(x =>
        moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
        && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))),
    };

    this.activeDiscounts = this.discounts.filter(x =>
      moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
      && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
    );

    this.activeEmployees = this.employees.filter(x =>
      moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
      && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
    );

    this.activePromotions = this.promotions.filter(x =>
      moment(x.effective, "DD.MM.YYYY").isBefore(this.filters.dateRange.to)
      && (x.expires === "" || moment(x.expires, "DD.MM.YYYY").isAfter(this.filters.dateRange.from))
    );

    const queryParams: Params = {};

    if (!this.filters.dateRange.from.isSame(moment(), "day")) {
      queryParams.from = this.filters.dateRange.from.format("DD.MM.YYYY");
    }

    if (!this.filters.dateRange.to.isSame(moment(), "day")) {
      queryParams.to = this.filters.dateRange.to.format("DD.MM.YYYY");
    }

    if (this.filters.selectedCompanies.length === 1 && this.filters.selectedCompanies[0].id !== this.userStorage.profile.companyId) {
      queryParams.company = this.filters.selectedCompanies.map(x => x.id);
    }
    else if (this.filters.selectedCompanies.length > 1) {
      queryParams.company = this.filters.selectedCompanies.map(x => x.id);
    }

    if (this.filters.allSpecialistsSelected) {
      queryParams.allSpecialists = true;
    }
    else if (this.filters.allPartnersSelected) {
      queryParams.allPartners = true;
    }
    else if (this.filters.allEmployeesSelected) {
      queryParams.allEmployees = true;
    }
    else if (this.filters.selectedEmployees.length > 0) {
      queryParams.employee = this.filters.selectedEmployees.map(x => x.id);
    }

    if (this.filters.allServicesSelected) {
      queryParams.allServices = true;
    } else if (this.filters.selectedServices.length > 0) {
      queryParams.service = this.filters.selectedServices.map(x => x.id);
    }

    if (this.filters.allDiscountsSelected) {
      queryParams.allDiscounts = true;
    } else if (this.filters.selectedDiscounts.length > 0) {
      queryParams.discount = this.filters.selectedDiscounts.map(x => x.id);
    }

    if (this.filters.allPromotionsSelected) {
      queryParams.allPromotions = true;
    } else if (this.filters.selectedPromotions.length > 0) {
      queryParams.promotion = this.filters.selectedPromotions.map(x => x.id);
    }

    if (this.filters.selectedCustomers.length > 0) {
      queryParams.customer = this.filters.selectedCustomers.map(x => x.id);
    }

    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })

  }

  openEmployeesModal(): void {
    const options: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(EmployeesModalComponent, options);
    const componentRef: EmployeesModalComponent = modalRef.componentInstance;

    componentRef.title = "Сотрудники";
    componentRef.selected = [...this.filters.selectedEmployees];
    componentRef.employees = [...this.activeEmployees];

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((result: ReportEmployee[]): void => {
      const filters: ReportsFiltersValue = {
        ...this.filters,
        selectedEmployees: result
      };

      if (result.length > 0) {
        filters.allEmployeesSelected = false;
        filters.allPartnersSelected = false;
        filters.allSpecialistsSelected = false;
      }

      this.filters = filters;
      this.changeDetectorRef.markForCheck();
      modalRef.close();
    });
  }

  openSalesModal(): void {
    const options: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(SalesModalComponent, options);
    const componentRef: SalesModalComponent = modalRef.componentInstance;

    componentRef.title = "Скидки";
    componentRef.discounts = this.activeDiscounts;
    componentRef.selected = [...this.filters.selectedDiscounts];

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((result: ReportDiscount[]): void => {
      const filters: ReportsFiltersValue = {
        ...this.filters,
        selectedDiscounts: result
      };

      if (result.length > 0) {
        filters.allDiscountsSelected = false;
      }

      this.filters = filters;
      this.changeDetectorRef.markForCheck();
      modalRef.close();
    });
  }

  managePromotions(): void {
    const options: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(PromotionsModalComponent, options);
    const componentRef: PromotionsModalComponent = modalRef.componentInstance;

    componentRef.title = "Каналы привлечения";
    componentRef.selected = [...this.filters.selectedPromotions];

    componentRef.items = [...this.activePromotions];

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((result: ReportPromotion[]): void => {
      const filters: ReportsFiltersValue = {
        ...this.filters,
        selectedPromotions: result
      };

      if (result.length > 0) {
        filters.allPromotionsSelected = false;
      }
      this.filters = filters;
      this.changeDetectorRef.markForCheck();
      modalRef.close();
    });
  }

  openServicesModal(): void {
    const options: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(ServicesModalComponent, options);
    const componentRef: ServicesModalComponent = modalRef.componentInstance;

    componentRef.title = "Услуги";
    componentRef.selected = [...this.filters.selectedServices];
    componentRef.services = [...this.services];

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((result: ReportService[]): void => {
      const filters: ReportsFiltersValue = {
        ...this.filters,
        selectedServices: result
      };

      if (result.length > 0) {
        filters.allServicesSelected = false;
      }

      this.filters = filters;
      this.changeDetectorRef.markForCheck();
      modalRef.close();
    });
  }

  openCustomersModal(): void {
    const options: NgbModalOptions = {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    };

    const modalRef: NgbModalRef = this.modal.open(CustomersModalComponent, options);
    const componentRef: CustomersModalComponent = modalRef.componentInstance;

    componentRef.title = "Компании";
    componentRef.selected = [...this.filters.selectedCustomers];
    componentRef.companies = [...this.customers];

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onConfirm.subscribe((result: Company[]): void => {
      const filters: ReportsFiltersValue = {
        ...this.filters,
        selectedCustomers: result
      };

      this.filters = filters;
      this.changeDetectorRef.markForCheck();
      modalRef.close();
    });
  }

  //========== Reports
  openReport(report: RequestedReport): void {
    const parameters = this.combineParameters(report.name, report.parameters);

    parameters['reportHash'] = uuidv4();

    this.reportsUtilityService.getUrl(parameters)
      .subscribe(url => {
        const options: NgbModalOptions = {
          centered: true,
          backdrop: 'static',
          size: 'lg'
        };
        const modalRef: NgbModalRef = this.modal.open(PreviewModalComponent, options);
        const componentRef: PreviewModalComponent = modalRef.componentInstance;

        componentRef.title = "Просмотр отчета";
        componentRef.pdfSrc = this.sanitaizer.bypassSecurityTrustResourceUrl(url);
        componentRef.orientation = parameters['orientation'] || 'portrait';

        componentRef.onDownload
          .subscribe((type: string): void => {
            this.reportsUtilityService.downloadReport(parameters, type);
          });

        componentRef.onShare.subscribe(async () => {
          const url = await this.reportsUtilityService.getUrl(parameters).toPromise();
          const response = await this.linksService.CutAsync({ url: window.location.protocol + '//' + window.location.host + '/' + url }).toPromise();

          const textarea: HTMLTextAreaElement = document.createElement("textarea");
          textarea.value = response.url;

          textarea.style.position = "fixed";
          textarea.style.top = "-1000px";

          document.body.appendChild(textarea);
          textarea.select();
          document.execCommand("copy");

          document.body.removeChild(textarea);

          this.toastrService.success('Срок действия ссылки ограничен', 'Ссылка скопирована');
        });

        componentRef.onCancel.subscribe(() => {
          modalRef.close();
        });

      });
  }

  private combineParameters(name: string, preset: { [key: string]: string } = {}): { [key: string]: string } {
    const parameters: { [key: string]: string } = {
      start: this.filters.dateRange.from.format('DD.MM.YYYY'),
      stop: this.filters.dateRange.to.format('DD.MM.YYYY'),
      companyId: this.filters.selectedCompanies.map(x => x.id).join(','),
      name: name
    };

    if (this.filters.allPromotionsSelected) {
      parameters["promotionId"] = "";
    } else if (this.filters.selectedPromotions.length > 0) {
      parameters["promotionId"] = this.filters.selectedPromotions.map((x: ReportPromotion): number => x.id).join(",");
    }

    if (this.filters.selectedDiscounts.length > 0) {
      parameters["discountId"] = this.filters.selectedDiscounts.map((x: ReportDiscount): number => x.id).join(",");
    }

    if (this.filters.allServicesSelected) {
      parameters["serviceId"] = "";
    } else if (this.filters.selectedServices.length > 0) {
      parameters["serviceId"] = this.filters.selectedServices.map(x => x.id).join(",");
    }

    if (this.filters.allPartnersSelected) {
      parameters["doctorId"] = "-2";
    } else if (this.filters.allEmployeesSelected) {
      parameters["doctorId"] = "-1";
    } else if (this.filters.selectedEmployees.length > 0) {
      parameters["doctorId"] = this.filters.selectedEmployees.map((x: ReportEmployee): number => x.id).join(",");
    }

    if (this.filters.selectedCustomers.length > 0) {
      parameters["paymentCompanyId"] = this.filters.selectedCustomers.map(x => x.id).join(",");
    }

    return {
      ...(preset || {}), ...parameters
    };
  }

}

export interface ReportsFiltersValue {
  dateRange?: { from: moment.Moment; to: moment.Moment };
  selectedCompanies?: Company[];

  allDiscountsSelected?: boolean;
  selectedDiscounts?: ReportDiscount[];

  allPromotionsSelected?: boolean;
  selectedPromotions?: ReportPromotion[];

  allServicesSelected?: boolean;
  selectedServices?: ReportService[];

  allSpecialistsSelected?: boolean;
  allPartnersSelected?: boolean;
  allEmployeesSelected?: boolean;
  selectedEmployees?: ReportEmployee[];

  selectedCustomers?: Company[];
}

export interface RequestedReport {
  name: string;
  parameters: { [key: string]: string };
}
