import { Component, OnInit, OnDestroy, forwardRef, ViewChild, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

import * as moment_ from "moment";
const moment = moment_;

import { Moment } from "moment"
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'mp-date-single',
  templateUrl: './datesingle.component.html',
  styleUrls: ['./datesingle.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatesingleComponent),
    multi: true
  }]
})
export class DatesingleComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();
  public disabled: boolean;

  public control: FormControl;

  @ViewChild("input") input: ElementRef<HTMLElement>;

  @Input() tabindex: number;

  constructor() {
    this.control = new FormControl("");

    this.control.valueChanges
      .pipe(
        distinctUntilChanged((x: string, y: string): boolean => x === y)
      )
      .subscribe((value: string): void => {
        if (!value) {
          if (this.propagateChange) {
            this.propagateChange(null);
          }
          return;
        }

        if (value.length === 10) {
          const date: Moment = moment(value, "DD.MM.YYYY");

          if (date.isValid() && this.propagateChange) {
            this.propagateChange(date);
          }
        }
      });

    this.control.statusChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((status: any): void => {
        if (this.propagateTouched) {
          this.propagateTouched(status);
        }
      })
  }

  ngOnInit() { }
  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  private propagateChange = (value: Moment) => { }
  private propagateTouched = (status: any) => { }

  public blur(): void {
    if (this.isInvalid(this.control.value)) {
      this.control.setValue(undefined);
    }

    if (this.propagateTouched) {
      this.propagateTouched(this.control.value);
    }
  }

  public isInvalid = (value: string): boolean => {
    if (!value) {
      return false;
    }
    return !moment(value, "DD.MM.YYYY", true).isValid();
  }

  //From ControlValueAccessor
  writeValue(obj: any): void {
    const old = this.control.value;

    if (obj && obj.isValid && (obj as Moment).isValid()) {
      const value: string = (obj as Moment).format("DD.MM.YYYY");

      this.control.setValue(value, { emitEvent: value === old });
    } else {
      this.control.setValue("", { emitEvent: '' === old });
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }
}
