import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColorMark } from '../../../../generated/models';

@Component({
  selector: 'mp-color-mark-item',
  templateUrl: './color-mark-item.component.html',
  styleUrls: ['./color-mark-item.component.scss']
})
export class ColorMarkItemComponent implements OnInit {
  @Input() item: ColorMark;
  @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  remove(): void {
    this.onRemove.emit();
  }

}
