import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ScheduleItemViewModel } from 'projects/Clinic/src/app/generated/models';

import * as moment from "moment";

@Component({
  selector: 'mp-schedule-shift-modal',
  templateUrl: './schedule-shift.modal.component.html',
  styleUrls: ['./schedule-shift.modal.component.scss']
})
export class ScheduleShiftModalComponent implements OnInit {

  daysOfWeek = [
    { value: 2, text: "Понедельник" },
    { value: 4, text: "Вторник" },
    { value: 8, text: "Среда" },
    { value: 16, text: "Четверг" },
    { value: 32, text: "Пятница" },
    { value: 64, text: "Суббота" },
    { value: 1, text: "Воскресенье" },
  ];

  @Input() shift: ScheduleItemViewModel = {};

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<ScheduleShiftModalPayload>();

  get minExpirationDate(): string {
    if (!this.shift || !this.shift.lastAppointmentDate) return '';

    const date = moment(this.shift.lastAppointmentDate, 'DD.MM.YYYY');

    if (date.isValid) {
      return date.add(1, 'day').format('DD.MM.YYYY');
    }

    return '';
  }


  get title(): string { return this.shift && this.shift.id > 0 ? 'Обновление смены' : 'Добавление смены'; }

  form = new FormGroup({
    weekParity: new FormControl(undefined, [Validators.required]),
    days: new FormControl([], [Validators.required]),
    start: new FormControl("", [Validators.required]),
    stop: new FormControl("", [Validators.required]),
    effective: new FormControl(undefined, [Validators.required]),
    expires: new FormControl(undefined, []),
    duration: new FormControl(undefined, [Validators.required]),
    cabinet: new FormControl('')
  });;

  constructor() { }

  ngOnInit() {
    this.form.patchValue({
      ...this.shift,
      effective: this.shift.effective ? moment(this.shift.effective, "DD.MM.YYYY") : undefined,
      expires: this.shift.expires ? moment(this.shift.expires, "DD.MM.YYYY") : undefined,
    });

    if (this.shift && this.shift.id > 0) {
      this.form.disable({ emitEvent: false });
      this.form.get('cabinet').enable({ emitEvent: false });
      this.form.get('expires').enable({ emitEvent: false });
    }
  }

  confirm() {
    Object.entries(this.form.controls).forEach((item: [string, AbstractControl]): void => item[1].markAsDirty());
    if (this.form.invalid) return;

    this.onConfirm.emit(this.form.getRawValue());
  }

  cancel = () => this.onCancel.emit();
}

export interface ScheduleShiftModalPayload {
  weekParity: 0 | 1 | 2 | 3;
  days: Array<0 | 1 | 2 | 4 | 8 | 16 | 32 | 62 | 64 | 65 | 127>
  start: string;
  stop: string;
  effective: moment.Moment;
  expires: moment.Moment;
  duration: number;
  cabinet: string;
}
