import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Medcard, PersonsVisit } from '../../../../generated/models';

@Component({
  selector: 'mp-patient-visits',
  templateUrl: './patient-visits.component.html',
  styleUrls: ['./patient-visits.component.scss']
})
export class PatientVisitsComponent implements OnInit {

  @Input() medcards: Medcard[];
  @Input() visits: PersonsVisit[];

  @Input() initials: string;
  @Input() lastName: string;

  @Output() onOpen: EventEmitter<PersonsVisit> = new EventEmitter<PersonsVisit>();
  @Output() onEdit: EventEmitter<PersonsVisit> = new EventEmitter<PersonsVisit>();

  @Output() onConnectRepresentative: EventEmitter<Medcard> = new EventEmitter<Medcard>();
  @Output() onRemoveRepresentative: EventEmitter<Medcard> = new EventEmitter<Medcard>();

  expanded: Medcard[] = [];

  constructor() { }

  ngOnInit() { }

  visitsForMedcard = (medcard: Medcard) => this.visits.filter(x => x.cardId == medcard.id);

  isExpanded = (medcard: Medcard) => this.expanded.some(x => x.id === medcard.id);

  expand(medcard: Medcard) {
    let index = this.expanded.findIndex(x => x.id == medcard.id);
    if (index === -1) {
      this.expanded.push(medcard);
    }
    else {
      this.expanded.splice(index, 1);
    }
  }

  lastNameDiffer = (visit: PersonsVisit) => this.lastName !== visit.lastname;

  open = (visit: PersonsVisit) => this.onOpen.emit(visit);
  edit = (visit: PersonsVisit) => this.onEdit.emit(visit);

  connectRepresentative = (medcard: Medcard) => this.onConnectRepresentative.emit(medcard);
  removeRepresentative = (medcard: Medcard) => this.onRemoveRepresentative.emit(medcard);
}
