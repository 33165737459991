import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClinicalRecommendation, SectionsServicePrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-prescriptions-recommendations',
  templateUrl: './prescriptions-recommendations.component.html',
  styleUrls: ['./prescriptions-recommendations.component.scss']
})
export class PrescriptionsRecommendationsComponent implements OnChanges {

  @Input() items: ClinicalRecommendation[] = [];

  @Input() recommendations: ClinicalRecommendation[] = [];
  @Input() selected: SectionsServicePrescription[] = [];
  @Input() prices: { [serviceId: number]: number } = {};

  @Output() onSelect = new EventEmitter<ClinicalRecommendation>();

  grouped: ClinicalRecommendation[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      const items: ClinicalRecommendation[] = changes['items'].currentValue;
      const groups: { [name: string]: ClinicalRecommendation[] } = {};

      for (const item of items) {
        if (!groups[item.manipulationName]) groups[item.manipulationName] = [];
        groups[item.manipulationName].push(item);
      }

      for (const key of Object.keys(groups)) {
        this.grouped.push({
          manipulationName: key,
          manipulationId: groups[key].some(x => x.manipulationId > 0) ? groups[key].find(x => x.manipulationId > 0).manipulationId : null,
        });
      }
    }
  }

  title(item: ClinicalRecommendation): string {
    return item.manipulationName || item.serviceName;
  }

  isSelected = (item: ClinicalRecommendation) => this.selected.some(x => x.name === item.manipulationName);

  select = (item: ClinicalRecommendation) => this.onSelect.emit(item);
  grossPriceFor = (id: number) => this.prices[id];

  recommendationsFor = (item: ClinicalRecommendation, type: 0 | 1) => this.recommendations
    .filter(x => x.type === type && x.manipulationName == item.manipulationName)
    .map(x => ({ title: x.clinicalRecommendationName, text: x.text }));
}

