import { Component, Input, OnInit } from '@angular/core';
import { PersonalalBalanceOperation } from '../../../../generated/models';
import * as moment from "moment";

@Component({
  selector: 'mp-balance-operation-item',
  templateUrl: './balance-operation-item.component.html',
  styleUrls: ['./balance-operation-item.component.scss']
})
export class BalanceOperationItemComponent implements OnInit {

  @Input() item: PersonalalBalanceOperation;

  @Input() currencyFormat: Intl.NumberFormatOptions = {};

  constructor() { }

  ngOnInit() { }

  get total(): string {
    if (!this.item) return '';
    return this.item.total.toLocaleString('ru-RU', this.currencyFormat);
  }

  get type(): string {
    if (!this.item) return '';
    switch (this.item.type) {
      case 1: return 'Пополнение наличными';
      case 2: return 'Пополнение картой';

      case 3: return 'Оплата наличными';
      case 4: return 'Оплата картой';
      case 5: return 'Оплата c баланса';
      case 6: return 'Оплата с резерва средств';


      case 7: return 'Возврат наличными';
      case 8: return 'Возврат картой';
      case 9: return 'Возврат на баланса';

      case 10: return 'Резерв средств';
      case 11: return 'Отмена резерва средств';

      case 12: return 'Вывод наличных';
      case 13: return 'Вывод на карту';

      case 14: return 'Зачисление на баланс с баланса двойника';
      case 15: return 'Зачисление в резерв из резерва двойника';

      case 16: return 'Списание с баланса на баланс двойника';
      case 17: return 'Списание из резерва в резерв двойника';

      default: return '';
    }
  }

  get date(): string {
    if (!this.item) return '';

    return moment.unix(this.item.date).format("DD.MM.YYYY HH:mm");
  }
}
