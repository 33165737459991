import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CodeSystemValue } from '../../../../generated/models';

@Component({
  selector: 'mp-service-code-list-item',
  templateUrl: './service-code-list-item.component.html',
  styleUrls: ['./service-code-list-item.component.scss']
})
export class ServiceCodeListItemComponent implements OnInit {

  @Input() codeSystem: CodeSystemValue;

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  public delete = (): void => {
    this.onDelete.emit();
  }

}
