import { Injectable } from '@angular/core';
import { ReplaySubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdbBaseService {
  dbName = "CLINIC_IDB";

  db: IDBDatabase;
  dbVersion = 3;

  dbLoaded = new ReplaySubject();

  constructor() { }

  init(): void {
    const req: IDBOpenDBRequest = window.indexedDB.open(this.dbName, this.dbVersion);

    req.onerror = (e: any) => console.log("Error initing DB", e.target.errorCode);
    req.onsuccess = (e: any) => {
      console.log("db created")
      this.db = e.target.result
      this.dbLoaded.next(true)
    }

    req.onupgradeneeded = (e: any) => {
      console.log("updating db", e)

      this.db = e.target.result;

      switch (e.oldVersion) {
        case 0:
          var objectStoreIcd10 = this.db.createObjectStore("icd10", { keyPath: "id" });
          objectStoreIcd10.createIndex("id", "id");

          var objectStoreStandards = this.db.createObjectStore("standardServices", { keyPath: "id" });
          objectStoreStandards.createIndex("id", "id");
        case 2:
          var objectStoreDrafts = this.db.createObjectStore("recordDrafts", { keyPath: "id" });
          objectStoreDrafts.createIndex("id", "id");
      }
    }
  }

  getObjectStore(objectStoreName: string): IDBObjectStore {
    try {
      const transaction = this.db.transaction([objectStoreName], "readwrite");
      return transaction.objectStore(objectStoreName);
    }
    catch (e) {
      console.log(`Error: Failed to get object store. ${e}, name: ${objectStoreName}`);
      return null;
    }
  }

  objectStoreEmpty(objectStoreName: string): Observable<boolean> {
    const subject = new Subject<boolean>();

    try {
      let objectStore: IDBObjectStore = this.getObjectStore(objectStoreName);

      objectStore.count().onsuccess = (evt: any) => subject.next(evt.target.result <= 0);
    }
    catch (e) {
      console.log(`Error: Failed to get update. ${e}, name: ${objectStoreName}`);
      subject.next(true);
    }

    return subject.asObservable();
  }
}
