export enum ReportTypes {
  M_MAIN = 1,
  M_LABS,
  M_PATIENTJOURNAL,
  M_CASHIER,
  PRICE,
  E_PAYMENT,
  E_CASH,
  PARTNER,
  S_SUM,
  S_DISCOUNTCARD,
  SHIFT
}
