import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreatmentRecommendation } from '../../../../generated/models';
import { TreatmentsHelper } from '../../models/treatments-helper';

@Component({
  selector: 'mp-recommended-treatment',
  templateUrl: './recommended-treatment.component.html',
  styleUrls: ['./recommended-treatment.component.scss']
})
export class RecommendedTreatmentComponent implements OnInit {

  @Input() item: TreatmentRecommendation
  @Output() onFav = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  private parts(item: TreatmentRecommendation): string[] {
    if (!item) return [];

    const parts = [];

    parts.push(item.treatmentName);
    parts.push(TreatmentsHelper.getDosage(item.dosage, item.dosageUnit));
    parts.push(TreatmentsHelper.getSchema(item.quantity, item.quantityUnit, item.repeats, item.repeatsUnitName, item.method, item.time, item.mealRelation, item.postpone));
    parts.push(TreatmentsHelper.getDuration(item.duration, item.durationUnitName));

    return parts.filter(x => x);
  }

  get display(): string { return this.parts(this.item).join(", "); }

  hasRestrictions = (item: TreatmentRecommendation) => item.gender !== 0 || !!item.diagnosisCodes || item.minAge > 0 || item.maxAge > 0;

  restrictions(item: TreatmentRecommendation): string {
    const parts: string[] = [];

    if (item.diagnosisCodes) {
      parts.push(`Диагнозы: ${item.diagnosisCodes}`);
    }

    parts.push(TreatmentsHelper.agePeriodRestriction(item.minAge, item.minAgeUnit, item.maxAge, item.maxAgeUnit));
    parts.push(TreatmentsHelper.genderRestriction(item.gender));

    return parts.filter(x => !!x).join('<br/>');
  }

  fav = () => this.onFav.emit();

}
