import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, from, forkJoin } from 'rxjs';
import { PbxOperatorsService } from '../../../generated/services/pbx-operators.service'
import { PbxOperatorViewModel } from '../../../generated/models/pbx-operator-view-model';
import { Pbx } from '../../../generated/models/pbx';

@Injectable({ providedIn: "root" })
export class PhoneNumberResolver implements Resolve<PhoneNumberResolverPayload> {

  public constructor(private service: PbxOperatorsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PhoneNumberResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    const empty: PbxOperatorViewModel = {
      pbx: 1,
      directPhone: "",
      ext: "",
      pbxUser:""
    };

    let pbxOperatorsLoader: Observable<PbxOperatorViewModel> = from([empty]);

    if (id) {
      pbxOperatorsLoader = this.service.PbxOperator(id);
    }

    const pbxList: Observable<Array<Pbx>> = this.service.PbxList()

    return forkJoin({
      pbxOperator: pbxOperatorsLoader,
      pbxList: pbxList
    })
  }

}

export interface PhoneNumberResolverPayload {
  pbxOperator: PbxOperatorViewModel;
  pbxList: Array<Pbx>;
}
