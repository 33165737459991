import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-create-cabinet-modal',
  templateUrl: './create-cabinet-modal.component.html',
  styleUrls: ['./create-cabinet-modal.component.scss'],
  host: { class: "modal-default" }
})
export class CreateCabinetModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  processing = false;

  @Output() onSubmit = new EventEmitter<CreateCabinetModel>();

  form = new FormGroup({
    phone: new FormControl("", []),
    email: new FormControl("", [Validators.email])
  });

  constructor(private activeModal: NgbActiveModal) {

    this.form.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: FormValue): void => {

          if (!value.email && !value.phone) {
            const emailErrors: ValidationErrors = this.form.get('email').errors || {};
            const phoneErrors: ValidationErrors = this.form.get('phone').errors || {};

            emailErrors["phoneOrEmail"] = true;
            phoneErrors["phoneOrEmail"] = true;

            this.form.get('email').setErrors(emailErrors);
            this.form.get('phone').setErrors(phoneErrors);

          } else {

            const emailErrors: ValidationErrors = this.form.get('email').errors || null;
            const phoneErrors: ValidationErrors = this.form.get('email').errors || null;

            if (emailErrors && Object.entries(emailErrors).some((x: [string, any]): boolean => x[0] !== "phoneOrEmail")) {
              delete emailErrors["phoneOrEmail"];
              this.form.get('email').setErrors(emailErrors);
            } else {
              this.form.get('email').setErrors(null);
            }

            if (phoneErrors && Object.entries(phoneErrors).some((x: [string, any]): boolean => x[0] !== "phoneOrEmail")) {
              delete phoneErrors["phoneOrEmail"];
              this.form.get('phone').setErrors(phoneErrors);
            } else {
              this.form.get('phone').setErrors(null);
            }
          }

          this.form.get('phone').markAsTouched();
          this.form.get('email').markAsTouched();
        }
      );

  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  pastePhone(event: ClipboardEvent): void {
    const pastedText = event.clipboardData.getData('text');

    if (pastedText && pastedText.startsWith('+7')) {
      this.form.get('phone').setValue(pastedText.replace('+7', '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('7') && pastedText.length === 11) {
      this.form.get('phone').setValue(pastedText.replace(/^7/, '').replace(/\D/g, ''));
    }
    else if (pastedText && pastedText.startsWith('8') && pastedText.length === 11) {
      this.form.get('phone').setValue(pastedText.replace(/^8/, '').replace(/\D/g, ''));
    }
  }

  dismiss = () => this.activeModal.dismiss();

  ok() {
    Object.entries(this.form.controls)
      .forEach((item: [string, AbstractControl]): void => (item[1].markAsTouched()));

    if (this.form.invalid) {
      return;
    }

    const value: FormValue = this.form.getRawValue();

    if (!value.email && !value.phone) {
      this.form.get('email').setErrors({ phoneOrEmail: true });
      this.form.get('phone').setErrors({ phoneOrEmail: true });

      return;
    }

    if (this.processing) {
      return;
    }

    this.processing = true;

    this.onSubmit.emit({ phone: value.phone, email: value.email });
  }

}

interface FormValue {
  phone: string;
  email: string;
}

export interface CreateCabinetModel {
  phone: string;
  email: string;
}
