import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter, OnDestroy } from '@angular/core';

import { PersonDocument } from 'projects/Clinic/src/app/generated/models';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';


@Component({
  selector: 'mp-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @ViewChild("fileInput") fileInput: ElementRef<HTMLInputElement>;

  @Input() items: PersonDocument[] = [];
  @Input() loading = false;

  @Output() onAdd = new EventEmitter<File[]>();
  @Output() onOpen = new EventEmitter<PersonDocument>();
  @Output() onDownload = new EventEmitter<PersonDocument>();
  @Output() onPrint = new EventEmitter<PersonDocument>();
  @Output() onRemove = new EventEmitter<PersonDocument>();

  file = new FormControl();

  constructor(private confirmationsService: ConfirmationModalService) {

    this.file.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (): void => {
          const files: File[] = Array.from(this.fileInput.nativeElement.files);

          if (files.length === 0) {
            return;
          }

          this.onAdd.emit(files);

        }
      );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  add(): void {
    this.fileInput.nativeElement.click();
  }

  open = (document: PersonDocument) => this.onOpen.emit(document);
  download = (document: PersonDocument) => this.onDownload.emit(document);
  print = (document: PersonDocument) => this.onPrint.emit(document);

  async delete(document: PersonDocument) {
    const confirmed = await this.confirmationsService.open({ message: 'Докумен будет удален. Продолжить?', confirmBtnText: 'Продолжить' });

    if (confirmed) this.onRemove.emit(document);
  }
}
