/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ServiceCompany } from '../models/service-company';
@Injectable({
  providedIn: 'root',
})
class ServiceCompaniesService extends __BaseService {
  static readonly ServiceCompaniesAsyncPath = '/api/v2/services/{serviceId}/companies';
  static readonly EnableServiceInCompanyAsyncPath = '/api/v2/services/{serviceId}/companies/{companyId}/enable';
  static readonly DisableServiceInCompanyAsyncPath = '/api/v2/services/{serviceId}/companies/{companyId}/disable';
  static readonly EnableServiceInAllCompaniesAsyncPath = '/api/v2/services/{serviceId}/companies/all/enable';
  static readonly DisableServiceInAllCompaniesAsyncPath = '/api/v2/services/{serviceId}/companies/all/disable';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceCompaniesAsyncResponse(serviceId: number): __Observable<__StrictHttpResponse<Array<ServiceCompany>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/${serviceId}/companies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceCompany>>;
      })
    );
  }
  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceCompaniesAsync(serviceId: number): __Observable<Array<ServiceCompany>> {
    return this.ServiceCompaniesAsyncResponse(serviceId).pipe(
      __map(_r => _r.body as Array<ServiceCompany>)
    );
  }

  /**
   * @param params The `ServiceCompaniesService.EnableServiceInCompanyAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `companyId`:
   */
  EnableServiceInCompanyAsyncResponse(params: ServiceCompaniesService.EnableServiceInCompanyAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/${params.serviceId}/companies/${params.companyId}/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServiceCompaniesService.EnableServiceInCompanyAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `companyId`:
   */
  EnableServiceInCompanyAsync(params: ServiceCompaniesService.EnableServiceInCompanyAsyncParams): __Observable<null> {
    return this.EnableServiceInCompanyAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ServiceCompaniesService.DisableServiceInCompanyAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `companyId`:
   */
  DisableServiceInCompanyAsyncResponse(params: ServiceCompaniesService.DisableServiceInCompanyAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/${params.serviceId}/companies/${params.companyId}/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServiceCompaniesService.DisableServiceInCompanyAsyncParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `companyId`:
   */
  DisableServiceInCompanyAsync(params: ServiceCompaniesService.DisableServiceInCompanyAsyncParams): __Observable<null> {
    return this.DisableServiceInCompanyAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param serviceId undefined
   */
  EnableServiceInAllCompaniesAsyncResponse(serviceId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/${serviceId}/companies/all/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param serviceId undefined
   */
  EnableServiceInAllCompaniesAsync(serviceId: number): __Observable<null> {
    return this.EnableServiceInAllCompaniesAsyncResponse(serviceId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param serviceId undefined
   */
  DisableServiceInAllCompaniesAsyncResponse(serviceId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/${serviceId}/companies/all/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param serviceId undefined
   */
  DisableServiceInAllCompaniesAsync(serviceId: number): __Observable<null> {
    return this.DisableServiceInAllCompaniesAsyncResponse(serviceId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ServiceCompaniesService {

  /**
   * Parameters for EnableServiceInCompanyAsync
   */
  export interface EnableServiceInCompanyAsyncParams {
    serviceId: number;
    companyId: number;
  }

  /**
   * Parameters for DisableServiceInCompanyAsync
   */
  export interface DisableServiceInCompanyAsyncParams {
    serviceId: number;
    companyId: number;
  }
}

export { ServiceCompaniesService }
