import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ResourceDayScheduleSummary } from '../../../../generated/models';

@Component({
  selector: 'mp-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlotComponent implements OnInit {

  @Input() item: ResourceDayScheduleSummary;

  @Output()
  public onSelect: EventEmitter<ResourceDayScheduleSummary> = new EventEmitter<ResourceDayScheduleSummary>()

  public get vacation(): boolean { return this.item && this.item.vacation; }
  public get sickLeave(): boolean { return this.item && this.item.sickLeave; }
  public get vacationOrSickLeave(): boolean { return this.item && this.item.vacationOrSickLeave; }
  public get absent(): boolean { return this.item && this.item.totalSlots === 0; }

  public get available(): number { return this.item ? this.item.availableSlots : undefined; }
  public get capacity(): number { return this.item ? this.item.totalSlots : undefined; }

  constructor() { }

  ngOnInit() { }

  public select(): void {
    if (this.item && this.item.totalSlots > 0) {
      this.onSelect.emit(this.item);
    }
  }

  get from(): string {
    if (!this.item || !this.item.shiftBegin) return '';

    return this.item.shiftBegin.substr(11, 5);
  }

  get to(): string {
    if (!this.item || !this.item.shiftEnd) return '';

    return this.item.shiftEnd.substr(11, 5);
  }

  @HostBinding('class.full')
  get full(): boolean { return this.item && this.item.totalSlots > 0 && this.item.availableSlots === 0; }

  @HostBinding("class.in-the-past")
  get inThePast(): boolean { return this.item && this.item.inThePast; }

  @HostBinding("class.absent")
  get notAvailable(): boolean { return this.item && (this.item.vacation || this.item.sickLeave || this.item.vacationOrSickLeave); }

  @HostBinding("class.available")
  get isAvailable(): boolean { return this.item && this.item.totalSlots > 0 && this.item.availableSlots > 0; }
}
