import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordSection, RecordSectionMetric } from '../../../../generated/models';

@Component({
  selector: 'mp-metrics-section-editor',
  templateUrl: './metrics-section-editor.component.html',
  styleUrls: ['./metrics-section-editor.component.scss']
})
export class MetricsSectionEditorComponent implements OnInit {

  @Input() disabled = false;
  @Input() dirty = false;

  @Input() section: RecordSection = {};

  @Output() onChange = new EventEmitter<RecordSection>();

  constructor() { }

  ngOnInit() { }

  changeMetric(metric: RecordSectionMetric, value: string) {

    metric.value = value;

    this.onChange.emit(this.section);
  }

}
