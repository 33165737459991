/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UsersTemplate } from '../models/users-template';
import { Icd10FavoritesResponse } from '../models/icd-10favorites-response';
import { Icd10FavoritesResponseItem } from '../models/icd-10favorites-response-item';
import { AddFavoriteDiagnosisRequest } from '../models/add-favorite-diagnosis-request';
import { Icd10FavoritesChangeRequest } from '../models/icd-10favorites-change-request';
import { Icd10Response } from '../models/icd-10response';
import { FavoritePrescriptionItem } from '../models/favorite-prescription-item';
import { AddFavouriteRequest } from '../models/add-favourite-request';
import { RemoveManyPrescriptionsResponse } from '../models/remove-many-prescriptions-response';
import { PrescriptionChangeRequest } from '../models/prescription-change-request';
import { RecentPrescription } from '../models/recent-prescription';
import { RecentTreatmentPrescription } from '../models/recent-treatment-prescription';
import { ChangeFiscalPrinterRequest } from '../models/change-fiscal-printer-request';
import { AccessTokenResponse } from '../models/access-token-response';
import { AccessTokenRequest } from '../models/access-token-request';
import { ReportGroup } from '../models/report-group';
import { MyReport } from '../models/my-report';
import { ReviewSettings } from '../models/review-settings';
@Injectable({
  providedIn: 'root',
})
class MyService extends __BaseService {
  static readonly TemplatesPath = '/api/My/templates';
  static readonly FavoriteDiagnosesPath = '/api/My/diagnoses';
  static readonly AddFavoriteDiagnosisPath = '/api/My/diagnoses';
  static readonly UpdateFavoriteDiagnosesPath = '/api/My/diagnoses';
  static readonly RemoveManyFavoriteDiagnosesPath = '/api/My/diagnoses';
  static readonly RecentDiagnosesPath = '/api/My/recent-diagnoses';
  static readonly PrescriptionsPath = '/api/My/prescriptions';
  static readonly AddPrescriptionPath = '/api/My/prescriptions';
  static readonly RemoveManyPrescriptionsPath = '/api/My/prescriptions';
  static readonly UpdateFavoritePrescriptionsPath = '/api/My/prescriptions';
  static readonly RecentPrescriptionsAsyncPath = '/api/My/prescriptions/recent';
  static readonly RecentTreatmentsAsyncPath = '/api/My/prescriptions/treatments/recent';
  static readonly RemoveFavoriteDiagnosisPath = '/api/My/diagnoses/{id}';
  static readonly RemovePrescriptionPath = '/api/My/prescriptions/{id}';
  static readonly ChangeFiscalPrinterPath = '/api/My/fiscal-printer';
  static readonly AccessTokenPath = '/api/My/accesstoken';
  static readonly DocumentsAccessTokenPath = '/api/My/documentsaccesstoken';
  static readonly AvailableReportGroupsPath = '/api/My/report-groups';
  static readonly AvailableReportsPath = '/api/My/reports';
  static readonly ReviewSettingsAsyncPath = '/api/My/settings/review';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  TemplatesResponse(): __Observable<__StrictHttpResponse<Array<UsersTemplate>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/templates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UsersTemplate>>;
      })
    );
  }
  /**
   * @return Success
   */
  Templates(): __Observable<Array<UsersTemplate>> {
    return this.TemplatesResponse().pipe(
      __map(_r => _r.body as Array<UsersTemplate>)
    );
  }

  /**
   * @return Success
   */
  FavoriteDiagnosesResponse(): __Observable<__StrictHttpResponse<Icd10FavoritesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Icd10FavoritesResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  FavoriteDiagnoses(): __Observable<Icd10FavoritesResponse> {
    return this.FavoriteDiagnosesResponse().pipe(
      __map(_r => _r.body as Icd10FavoritesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  AddFavoriteDiagnosisResponse(request?: AddFavoriteDiagnosisRequest): __Observable<__StrictHttpResponse<Icd10FavoritesResponseItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/My/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Icd10FavoritesResponseItem>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  AddFavoriteDiagnosis(request?: AddFavoriteDiagnosisRequest): __Observable<Icd10FavoritesResponseItem> {
    return this.AddFavoriteDiagnosisResponse(request).pipe(
      __map(_r => _r.body as Icd10FavoritesResponseItem)
    );
  }

  /**
   * @param request undefined
   */
  UpdateFavoriteDiagnosesResponse(request?: Icd10FavoritesChangeRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/My/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  UpdateFavoriteDiagnoses(request?: Icd10FavoritesChangeRequest): __Observable<null> {
    return this.UpdateFavoriteDiagnosesResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Id undefined
   */
  RemoveManyFavoriteDiagnosesResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/My/diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param Id undefined
   */
  RemoveManyFavoriteDiagnoses(Id?: Array<number>): __Observable<null> {
    return this.RemoveManyFavoriteDiagnosesResponse(Id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param Search undefined
   * @return Success
   */
  RecentDiagnosesResponse(Search?: string): __Observable<__StrictHttpResponse<Icd10Response>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Search != null) __params = __params.set('Search', Search.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/recent-diagnoses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Icd10Response>;
      })
    );
  }
  /**
   * @param Search undefined
   * @return Success
   */
  RecentDiagnoses(Search?: string): __Observable<Icd10Response> {
    return this.RecentDiagnosesResponse(Search).pipe(
      __map(_r => _r.body as Icd10Response)
    );
  }

  /**
   * @return Success
   */
  PrescriptionsResponse(): __Observable<__StrictHttpResponse<Array<FavoritePrescriptionItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<FavoritePrescriptionItem>>;
      })
    );
  }
  /**
   * @return Success
   */
  Prescriptions(): __Observable<Array<FavoritePrescriptionItem>> {
    return this.PrescriptionsResponse().pipe(
      __map(_r => _r.body as Array<FavoritePrescriptionItem>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  AddPrescriptionResponse(request?: AddFavouriteRequest): __Observable<__StrictHttpResponse<FavoritePrescriptionItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/My/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FavoritePrescriptionItem>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  AddPrescription(request?: AddFavouriteRequest): __Observable<FavoritePrescriptionItem> {
    return this.AddPrescriptionResponse(request).pipe(
      __map(_r => _r.body as FavoritePrescriptionItem)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  RemoveManyPrescriptionsResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<RemoveManyPrescriptionsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/My/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoveManyPrescriptionsResponse>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  RemoveManyPrescriptions(Id?: Array<number>): __Observable<RemoveManyPrescriptionsResponse> {
    return this.RemoveManyPrescriptionsResponse(Id).pipe(
      __map(_r => _r.body as RemoveManyPrescriptionsResponse)
    );
  }

  /**
   * @param request undefined
   */
  UpdateFavoritePrescriptionsResponse(request?: PrescriptionChangeRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/My/prescriptions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  UpdateFavoritePrescriptions(request?: PrescriptionChangeRequest): __Observable<null> {
    return this.UpdateFavoritePrescriptionsResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @return Success
   */
  RecentPrescriptionsAsyncResponse(): __Observable<__StrictHttpResponse<Array<RecentPrescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/prescriptions/recent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecentPrescription>>;
      })
    );
  }
  /**
   * @return Success
   */
  RecentPrescriptionsAsync(): __Observable<Array<RecentPrescription>> {
    return this.RecentPrescriptionsAsyncResponse().pipe(
      __map(_r => _r.body as Array<RecentPrescription>)
    );
  }

  /**
   * @param Search undefined
   * @return Success
   */
  RecentTreatmentsAsyncResponse(Search?: string): __Observable<__StrictHttpResponse<Array<RecentTreatmentPrescription>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Search != null) __params = __params.set('Search', Search.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/prescriptions/treatments/recent`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RecentTreatmentPrescription>>;
      })
    );
  }
  /**
   * @param Search undefined
   * @return Success
   */
  RecentTreatmentsAsync(Search?: string): __Observable<Array<RecentTreatmentPrescription>> {
    return this.RecentTreatmentsAsyncResponse(Search).pipe(
      __map(_r => _r.body as Array<RecentTreatmentPrescription>)
    );
  }

  /**
   * @param id undefined
   */
  RemoveFavoriteDiagnosisResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/My/diagnoses/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveFavoriteDiagnosis(id: number): __Observable<null> {
    return this.RemoveFavoriteDiagnosisResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemovePrescriptionResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/My/prescriptions/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemovePrescription(id: number): __Observable<null> {
    return this.RemovePrescriptionResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   */
  ChangeFiscalPrinterResponse(request?: ChangeFiscalPrinterRequest): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/My/fiscal-printer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param request undefined
   */
  ChangeFiscalPrinter(request?: ChangeFiscalPrinterRequest): __Observable<null> {
    return this.ChangeFiscalPrinterResponse(request).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  AccessTokenResponse(request?: AccessTokenRequest): __Observable<__StrictHttpResponse<AccessTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/My/accesstoken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessTokenResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  AccessToken(request?: AccessTokenRequest): __Observable<AccessTokenResponse> {
    return this.AccessTokenResponse(request).pipe(
      __map(_r => _r.body as AccessTokenResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  DocumentsAccessTokenResponse(request?: AccessTokenRequest): __Observable<__StrictHttpResponse<AccessTokenResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/My/documentsaccesstoken`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AccessTokenResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  DocumentsAccessToken(request?: AccessTokenRequest): __Observable<AccessTokenResponse> {
    return this.DocumentsAccessTokenResponse(request).pipe(
      __map(_r => _r.body as AccessTokenResponse)
    );
  }

  /**
   * @return Success
   */
  AvailableReportGroupsResponse(): __Observable<__StrictHttpResponse<Array<ReportGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/report-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  AvailableReportGroups(): __Observable<Array<ReportGroup>> {
    return this.AvailableReportGroupsResponse().pipe(
      __map(_r => _r.body as Array<ReportGroup>)
    );
  }

  /**
   * @param type undefined
   * @return Success
   */
  AvailableReportsResponse(type?: any): __Observable<__StrictHttpResponse<Array<MyReport>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (type != null) __params = __params.set('type', type.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/reports`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<MyReport>>;
      })
    );
  }
  /**
   * @param type undefined
   * @return Success
   */
  AvailableReports(type?: any): __Observable<Array<MyReport>> {
    return this.AvailableReportsResponse(type).pipe(
      __map(_r => _r.body as Array<MyReport>)
    );
  }

  /**
   * @return Success
   */
  ReviewSettingsAsyncResponse(): __Observable<__StrictHttpResponse<ReviewSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/My/settings/review`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReviewSettings>;
      })
    );
  }
  /**
   * @return Success
   */
  ReviewSettingsAsync(): __Observable<ReviewSettings> {
    return this.ReviewSettingsAsyncResponse().pipe(
      __map(_r => _r.body as ReviewSettings)
    );
  }
}

module MyService {
}

export { MyService }
