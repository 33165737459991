import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Observable, from } from 'rxjs';
import { Speciality, SpecialitiesResponse, RemoveSpecialitiesResponse } from '../../../../generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { UserStorage } from '../../../../services/user-storage';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SpecialitiesService } from '../../../../generated/services';
import { ToastrService } from 'ngx-toastr';
import { PermissionNames } from '../../../../models/permission-names';
import { takeUntil, tap, switchMap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-specialities',
  templateUrl: './specialities.component.html',
  styleUrls: ['./specialities.component.scss'],
  host: { class: "page" }
})
export class SpecialitiesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  items: Speciality[] = [];
  loading = false;

  canCreate = false;
  canEdit = false;
  canRemove = false;

  page = 1;
  size = 20;

  form = new FormGroup({
    search: new FormControl()
  });

  constructor(userStorage: UserStorage,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private specialitiesService: SpecialitiesService,
    private toastrService: ToastrService,
    private confirmationsService: ConfirmationModalService
  ) {
    this.canCreate = userStorage.hasPermission(PermissionNames.EditSpecialities);
    this.canEdit = userStorage.hasPermission(PermissionNames.EditSpecialities);
    this.canRemove = userStorage.hasPermission(PermissionNames.EditSpecialities);

    this.form.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value: FiltersValue) => {
          const params: Params = {};

          if (value.search) {
            params.search = value.search;
          }

          router.navigate([], { relativeTo: this.activatedRoute, queryParams: params });
        }),
        tap(() => {
          this.items = [];
          this.loading = true;
          this.page = 1;
        }),
        switchMap((value: FiltersValue): Observable<SpecialitiesResponse> => this.load(value))
      )
      .subscribe(
        (response: SpecialitiesResponse): void => {
          this.items = response.items;
          this.loading = false;
        },
        (response: HttpErrorResponse): void => {
          this.loading = false;
          this.handleErrorResponse(response);
        }
      );
  }

  ngOnInit() {
    this.form.patchValue({
      search: this.activatedRoute.snapshot.queryParamMap.get("search")
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  create = () => this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  edit = (item: Speciality) => this.router.navigate([item.id.toString()], { relativeTo: this.activatedRoute });

  async remove(item: Speciality) {
    const confirmed = await this.confirmationsService.open({
      message: `Специальность «${item.name}» будет удалена. Продолжить?`,
      confirmBtnText: 'Продолжить'
    });

    if (!confirmed) return;

    this.specialitiesService.RemoveAsync(item.id).subscribe(
      () => {
        this.loading = true;
        this.page = 1;
        const value = this.form.getRawValue();

        this.load(value).subscribe(
          (response: SpecialitiesResponse) => {
            this.items = response.items;
            this.loading = false;
          },
          (response: HttpErrorResponse) => {
            this.loading = false;
            this.handleErrorResponse(response);

          }
        )
      },
      (response: HttpErrorResponse) => {
        if (response.status === 400 && response.error && response.error.errors) {

          for (const error of response.error.errors) {
            switch (error.message) {
              case 'InUseByServices': this.toastrService.error('Найдены услуги, использующие данную специальность. Удаление запрещено', 'Ошибка'); break;
              case 'InUseByEmployee': this.toastrService.error('Найдены сотрудники, использующие данную специальность. Удаление запрещено', 'Ошибка'); break;
              case 'InUseByResource': this.toastrService.error('Найдены расписания, использующие данную специальность. Удаление запрещено', 'Ошибка'); break;
              case 'InUseByTemplate': this.toastrService.error('Найдены шаблоны протоколов, использующие данную специальность. Удаление запрещено', 'Ошибка'); break;
              case 'PermissionRequired': this.toastrService.error('Для выполнения операции требуется разрешение', 'Ошибка'); break;
              case 'SpecialityNotFound': this.toastrService.error('Специальность не найдена', 'Ошибка'); break;
              default: this.toastrService.error('Не удалось удалить специальность', 'Ошибка');
            }


          }
          return;
        }


        this.toastrService.error(JSON.stringify(response));
      }
    )


  }

  onScrollDown() {
    this.page++;
    const value: FiltersValue = this.form.getRawValue();

    this.load(value).subscribe(
      (response: SpecialitiesResponse) => {
        this.items.push(...response.items);
      },
      () => {
        this.toastrService.warning('Не удалось загрузить специальности', 'Ошибка');

      }
    )

  }

  private load(value: FiltersValue): Observable<SpecialitiesResponse> {
    return this.specialitiesService.Specialities({ Page: this.page, Size: this.size, Search: value.search })
      .pipe(catchError((): Observable<SpecialitiesResponse> => from([{ items: [] }])));
  }

  private handleErrorResponse(response: HttpErrorResponse): void {
    if (response.status === 400) {
      this.toastrService.error(response.error.message, "Ошибка");
      return;
    }
    if (response.status === 40) {
      this.toastrService.error("Для выполнения данного действия требуется разрешение", "Доступ запрещен");
      return;
    }

    this.toastrService.error("Данные ошибки сохранены", "Ошибка");
  }
}

interface FiltersValue {
  search: string;
}
