export { AbsenceReasonsService } from './services/absence-reasons.service';
export { AccountService } from './services/account.service';
export { AgreementsService } from './services/agreements.service';
export { AppointmentsService } from './services/appointments.service';
export { AppointmentsV2Service } from './services/appointments-v2.service';
export { AttachmentsService } from './services/attachments.service';
export { AttendanceService } from './services/attendance.service';
export { BarcodePrinterService } from './services/barcode-printer.service';
export { BarcodesService } from './services/barcodes.service';
export { CallCenterService } from './services/call-center.service';
export { CallReportsService } from './services/call-reports.service';
export { CasesService } from './services/cases.service';
export { CashboxService } from './services/cashbox.service';
export { CategoriesService } from './services/categories.service';
export { ClinicalRecommendationsService } from './services/clinical-recommendations.service';
export { CodeSystemsService } from './services/code-systems.service';
export { ColorMarksService } from './services/color-marks.service';
export { CompaniesService } from './services/companies.service';
export { CustomerChannelsService } from './services/customer-channels.service';
export { DiagnosesService } from './services/diagnoses.service';
export { DiagnosisAliasesService } from './services/diagnosis-aliases.service';
export { DiagnosisRecommendationsService } from './services/diagnosis-recommendations.service';
export { DiscountsService } from './services/discounts.service';
export { DiseaseOutcomeService } from './services/disease-outcome.service';
export { DocumentsService } from './services/documents.service';
export { EmployeeCompaniesService } from './services/employee-companies.service';
export { EmployeePhonesService } from './services/employee-phones.service';
export { EmployeesService } from './services/employees.service';
export { EvotorAPIService } from './services/evotor-api.service';
export { ExternalConnectionsService } from './services/external-connections.service';
export { ImportService } from './services/import.service';
export { InsuranceService } from './services/insurance.service';
export { InvoicesService } from './services/invoices.service';
export { LinesService } from './services/lines.service';
export { LinksService } from './services/links.service';
export { LocusesService } from './services/locuses.service';
export { ManipulationRecommendationsService } from './services/manipulation-recommendations.service';
export { ManipulationsService } from './services/manipulations.service';
export { ManipulationServicesService } from './services/manipulation-services.service';
export { MedicalFilesService } from './services/medical-files.service';
export { MetricsService } from './services/metrics.service';
export { MyService } from './services/my.service';
export { MyFavoritesService } from './services/my-favorites.service';
export { MyRecentService } from './services/my-recent.service';
export { MyTreatmentsService } from './services/my-treatments.service';
export { ObservationsService } from './services/observations.service';
export { OrganizationsService } from './services/organizations.service';
export { ParseService } from './services/parse.service';
export { PartnerPlansService } from './services/partner-plans.service';
export { PartnersService } from './services/partners.service';
export { PatientPrescriptionsService } from './services/patient-prescriptions.service';
export { PatientsService } from './services/patients.service';
export { PaymentsService } from './services/payments.service';
export { PbxOperatorsService } from './services/pbx-operators.service';
export { PeopleService } from './services/people.service';
export { PermissionsService } from './services/permissions.service';
export { PersonalBalanceService } from './services/personal-balance.service';
export { PersonDocumentsService } from './services/person-documents.service';
export { PersonMarksService } from './services/person-marks.service';
export { PersonMetricsService } from './services/person-metrics.service';
export { PersonsService } from './services/persons.service';
export { PhoneCallsService } from './services/phone-calls.service';
export { PlannedVisitsService } from './services/planned-visits.service';
export { PrescriptionsService } from './services/prescriptions.service';
export { PricesService } from './services/prices.service';
export { PriceSetsService } from './services/price-sets.service';
export { PrintingService } from './services/printing.service';
export { ProfileService } from './services/profile.service';
export { PromotionsService } from './services/promotions.service';
export { ProtocolsService } from './services/protocols.service';
export { QuestionnairesService } from './services/questionnaires.service';
export { ReceiptsService } from './services/receipts.service';
export { RecommendationsService } from './services/recommendations.service';
export { RecommendedManipulationsService } from './services/recommended-manipulations.service';
export { RecordsService } from './services/records.service';
export { RefundReasonsService } from './services/refund-reasons.service';
export { RefundsService } from './services/refunds.service';
export { ReportsService } from './services/reports.service';
export { ReviewsService } from './services/reviews.service';
export { RolesService } from './services/roles.service';
export { ScheduleBreakReasonsService } from './services/schedule-break-reasons.service';
export { ScheduleHistoryService } from './services/schedule-history.service';
export { SchedulesService } from './services/schedules.service';
export { ScheduleSettingsService } from './services/schedule-settings.service';
export { ScheduleShiftsService } from './services/schedule-shifts.service';
export { ServiceCategoriesService } from './services/service-categories.service';
export { ServiceCompaniesService } from './services/service-companies.service';
export { ServiceGroupsService } from './services/service-groups.service';
export { ServicesService } from './services/services.service';
export { SettingsService } from './services/settings.service';
export { SocialStatusesService } from './services/social-statuses.service';
export { SpecialitiesService } from './services/specialities.service';
export { StandardsService } from './services/standards.service';
export { StandartizationService } from './services/standartization.service';
export { SuggestionsService } from './services/suggestions.service';
export { SystemSettingsService } from './services/system-settings.service';
export { TaxationSystemsService } from './services/taxation-systems.service';
export { TaxDeductionsService } from './services/tax-deductions.service';
export { TaxTypesService } from './services/tax-types.service';
export { TemplatesService } from './services/templates.service';
export { TherapyResultsService } from './services/therapy-results.service';
export { TreatmentRecommendationsService } from './services/treatment-recommendations.service';
export { ValueSetsService } from './services/value-sets.service';
export { VersionService } from './services/version.service';
export { VisitReportsService } from './services/visit-reports.service';
export { VisitsService } from './services/visits.service';
export { WageSchemasService } from './services/wage-schemas.service';
export { WorkspaceService } from './services/workspace.service';
