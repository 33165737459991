import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ManipulationResolverPayload } from '../../resolvers/manipulation-resolver';
import { CreateManipulationResponse, Manipulation, ManipulationRecommendation, ManipulationService, Service, Speciality } from '../../../../generated/models';
import { ManipulationRecommendationsService, ManipulationServicesService, ManipulationsService } from '../../../../generated/services';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManipulationServiceModalComponent } from '../manipulation-service-modal/manipulation-service-modal.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationModalService } from '../../../shared/services/confirmation-modal-service';

@Component({
  selector: 'mp-manipulation',
  templateUrl: './manipulation.component.html',
  styleUrls: ['./manipulation.component.scss'],
  host: { class: "page" }
})
export class ManipulationComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  title = 'Новая манимпуляция';

  specialities: Speciality[] = [];
  services: Service[] = [];

  loadingServices = false;
  loadingRecommendations = false;

  manipulationServices: ManipulationService[] = [];
  manipulationRecommendations: ManipulationRecommendation[] = [];

  manipulationServices$ = new BehaviorSubject<ManipulationService[]>([]);
  manipulationRecommendations$ = new BehaviorSubject<ManipulationRecommendation[]>([]);

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    specialityId: new FormControl(null)
  });

  servicesFilters = new FormGroup({
    search: new FormControl('')
  });

  constructor(private activatedRoute: ActivatedRoute,
    private service: ManipulationsService,
    private manipulationServicesService: ManipulationServicesService,
    private manipulationRecoomendationsService: ManipulationRecommendationsService,
    private confirmationService: ConfirmationModalService,
    private toastrService: ToastrService,
    private router: Router,
    private ngbModal: NgbModal
  ) {
    this.servicesFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (value: { search: string }) => {
          if (!value.search) {
            this.manipulationServices$.next([...this.manipulationServices]);
          } else {
            const regex = new RegExp(value.search);

            this.manipulationServices$.next([...this.manipulationServices.filter(x => regex.test(x.serviceName) || regex.test(x.serviceCode))]);
          }
        }
      );

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: ManipulationResolverPayload }) => {

      this.id = data.payload.manipulation.id;
      this.title = this.id > 0 ? `Назначение: «${data.payload.manipulation.name}»` : 'Новое назначение';

      this.form.patchValue({ ...data.payload.manipulation });

      this.specialities = data.payload.specialities;
      this.services = data.payload.services;

      if (this.id > 0) {
        this.loadServices();
        this.loadRecommendations();
      }
    });
  }

  ngOnDestroy() { }

  loadServices() {
    this.loadingServices = true;

    this.manipulationServicesService.ManipulationManipulationServicesAsync(this.id)
      .subscribe(
        (response: ManipulationService[]) => {
          this.manipulationServices = response;

          const servicesFilters = this.servicesFilters.getRawValue();

          if (!servicesFilters.search) {
            this.manipulationServices$.next([...response]);
          } else {
            const regex = new RegExp(servicesFilters.search);

            this.manipulationServices$.next([...response.filter(x => regex.test(x.serviceName) || regex.test(x.serviceCode))]);
          }

          this.loadingServices = false;
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить услуги для назначения', 'Ошибка');
          this.loadingServices = false;
        }
      );
  }

  loadRecommendations() {
    if (!this.id) return;

    this.loadingRecommendations = true;

    this.manipulationRecoomendationsService
      .RecommendationsAsync(this.id)
      .subscribe(
        (response: ManipulationRecommendation[]) => {
          this.manipulationRecommendations = response;

          this.manipulationRecommendations$.next(response);

          this.loadingRecommendations = false;
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить рекомендации для назначения', 'Ошибка');

          this.loadingRecommendations = false;
        }
      );
  }

  async acceptChanges() {

    Object.entries(this.form.controls).forEach(x => {
      x[1].markAsTouched();
      x[1].markAsDirty();
    });

    if (this.form.invalid) return;

    const value: FormValue = this.form.getRawValue();

    if (this.id > 0) {
      this.service.UpdateManipulationAsync({ id: this.id, request: value })
        .subscribe(
          () => {
            this.toastrService.success('Назначение обновлено', 'Успешно');
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400 && response.error && response.error.errors) {
              for (const error of response.error.errors) {
                switch (error.status) {
                  case 1: this.toastrService.warning('Необходимо указать название назначения', 'Ошибка'); break;
                  case 2: this.toastrService.warning('Назначение не найдено', 'Ошибка'); break;
                  case 3: this.toastrService.warning('Специальность не найдена', 'Ошибка'); break;
                  case 4: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;

                  default: this.toastrService.warning('Не удалось добавить назначение', 'Ошибка'); break;
                }
              }

              return;
            }

            this.toastrService.error('Не удалось обновить назначение', 'Ошибка');
          }
        );

    } else {
      this.service.CreateManipulationAsync(value)
        .subscribe(
          (response: CreateManipulationResponse) => {
            this.toastrService.success('Назначение добавлено', 'Успешно');
            this.router.navigate(['..', `${response.id}`], { relativeTo: this.activatedRoute });
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400 && response.error && response.error.errors) {
              for (const error of response.error.errors) {
                switch (error.status) {
                  case 1: this.toastrService.warning('Необходимо указать название назначения', 'Ошибка'); break;
                  case 2: this.toastrService.warning('Назначение не найдено', 'Ошибка'); break;
                  case 3: this.toastrService.warning('Специальность не найдена', 'Ошибка'); break;
                  case 4: this.toastrService.warning('Отсутствует необходимое разрешение', 'Ошибка'); break;

                  default: this.toastrService.warning('Не удалось добавить назначение', 'Ошибка'); break;
                }
              }

              return;
            }

            this.toastrService.error('Не удалось добавить назначение', 'Ошибка');
          }
        );
    }
  }

  addService() {
    const modalRef = this.ngbModal.open(ManipulationServiceModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    const componentInstance: ManipulationServiceModalComponent = modalRef.componentInstance;

    componentInstance.services = this.services.filter(x => !this.manipulationServices.some(s => s.serviceId === x.id));

    componentInstance.onCancel.subscribe(() => modalRef.close());
    componentInstance.onConfirm.subscribe((service: Service) => {

      this.manipulationServicesService.CreateManipulationServiceAsync({ manipulationId: this.id, serviceId: service.id })
        .subscribe(
          () => {
            this.toastrService.success('Назначение прикреплено к услуге', 'Успешно');
            this.loadServices();
            modalRef.close();
          },
          (response: HttpErrorResponse) => {
            this.toastrService.error('Не удалось прикрепить назначение к услуге', 'Ошибка');
          }
        );

    });
  }

  async removeService(item: ManipulationService) {
    const confirmed = await this.confirmationService.open({ message: `Услуга «${item.serviceName}» будет откреплена от назначения. Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.manipulationServicesService.RemoveManipulationServiceAsync(item.id)
      .subscribe(
        () => {
          this.toastrService.success('Назначение откреплено от услуги', 'Успешно');
          this.loadServices();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось открепить назначение от услуги', 'Ошибка');
        }
      );
  }

  markAsDefault(service: ManipulationService) {
    this.manipulationServicesService
      .MarkManipulationServiceAsDefaultAsync({ manipulationId: service.manipulationId, serviceId: service.serviceId })
      .subscribe(
        () => {
          this.toastrService.success(`Услуга «${service.serviceName}» отмечена как основной выбор`);
          this.loadServices();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось отметить услугу «${service.serviceName}» как основной выбор`);
        }
      );
  }

  unmarkAsDefault(service: ManipulationService) {
    this.manipulationServicesService
      .UnmarkManipulationServiceAsDefaultAsync({ manipulationId: service.manipulationId, serviceId: service.serviceId })
      .subscribe(
        () => {
          this.toastrService.success(`Метка основного выбора снята с услуги «${service.serviceName}»`);
          this.loadServices();
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error(`Не удалось снять метку основного выбора с услуг «${service.serviceName}»`);
        }
      );
  }

  age(item: ManipulationRecommendation) {
    if (!item) return '';

    if (item.minAge && item.maxAge) return `с ${item.minAge} до ${item.maxAge}`;

    if (item.minAge === 18) return 'взрослые';
    if (item.minAge) return `с ${item.minAge}`;

    if (item.maxAge === 18) return 'дети';
    if (item.maxAge) return `до ${item.maxAge}`;

    return '';
  }
}

interface FormValue {
  name: string;
  specialityId: number;
}
;
