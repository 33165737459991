import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { AddServiceItem } from '../add-service-modal/add-service-modal.component';

@Component({
  selector: 'mp-add-service-modal-item',
  templateUrl: './add-service-modal-item.component.html',
  styleUrls: ['./add-service-modal-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddServiceModalItemComponent implements OnInit {

  @Input() item: AddServiceItem;
  @Input() selected = false;


  constructor() { }

  ngOnInit() { }

  get price(): string {
    if (!this.item) return '';

    const options: Intl.NumberFormatOptions = {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    };

    return this.item.price.toLocaleString('ru-RU', options);
  }
}
