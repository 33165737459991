import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'mp-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss', './loader.scss']
})
export class PreviewModalComponent implements OnInit {

  @Input() title: string;
  @Input() pdfSrc: SafeResourceUrl;
  @Input() downloadLinks: DownloadLink[] = [];
  @Input() orientation = 'portrait';

  @ViewChild('iframe')
  iframe: ElementRef<HTMLIFrameElement>;

  @Output() onDownload = new EventEmitter<string>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onShare = new EventEmitter<void>();

  loading: boolean;
  loaded: boolean;

  constructor() { }

  ngOnInit() {
    this.loading = true;
    this.loaded = false;

    this.iframe.nativeElement.onload = (): void => {
      this.iframe.nativeElement.classList.add('loaded');
      this.iframe.nativeElement.width = `${this.iframe.nativeElement.contentWindow.document.body.scrollWidth}px`;
      this.iframe.nativeElement.height = `${this.iframe.nativeElement.contentWindow.document.body.scrollHeight + 90}px`;
      this.loading = false;
    };
  }

  cancel = () => this.onCancel.emit();

  print = () => this.iframe.nativeElement.contentWindow.print();
  share = () => this.onShare.emit();
  downloadPdf = () => this.onDownload.emit("pdf");
  downloadXlsx = () => this.onDownload.emit("xls");
}

export interface DownloadLink {
  type: string;
  url: string;
}
