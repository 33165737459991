import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceSetsComponent } from './components/price-sets/price-sets.component';
import { PriceSetListItemComponent } from './components/price-set-list-item/price-set-list-item.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ToastrModule } from 'ngx-toastr';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PriceSetComponent } from './components/price-set/price-set.component';
import { PriceSetResolver } from './resolvers/price-set-resolver';
import { PriceSetPricesListComponent } from './components/price-set-prices-list/price-set-prices-list.component';
import { PriceSetPricesListItemComponent } from './components/price-set-prices-list-item/price-set-prices-list-item.component';
import { PriceSetPriceModalComponent } from './components/price-set-price-modal/price-set-price-modal.component';
import { SharedModule } from '../shared/shared.module';
import { ImportModalComponent } from './components/import-modal/import-modal.component';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: PriceSetResolver
    },
    component: PriceSetComponent,
    data: {
      title: "Новый прайс-лист"
    }
  },
  {
    path: ":id",
    resolve: {
      payload: PriceSetResolver
    },
    component: PriceSetComponent,
    data: {
      title: "Прайс-лист"
    }
  },
  {
    path: "",
    component: PriceSetsComponent,
    data: {
      title: "Список прайс-листов"
    }
  }
];

@NgModule({
  declarations: [
    PriceSetsComponent,
    PriceSetListItemComponent,
    PriceSetComponent,
    PriceSetPricesListComponent,
    PriceSetPricesListItemComponent,
    PriceSetPriceModalComponent,
    ImportModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,

    InfiniteScrollModule,
    ToastrModule,
    NgbModalModule,
    NgbDropdownModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    PriceSetPriceModalComponent,
    ImportModalComponent
  ]
})
export class PriceSetSettingsModule { }
