import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RecordEditorComponent } from './components/record.editor/record.editor.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PatientComponent } from "./components/patient/patient.component";
import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { NgxSelectModule } from 'ngx-select-ex';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TemplatesModalComponent } from './components/templates-modal/templates-modal.component';
import { NgbModalModule, NgbDropdownModule, NgbButtonsModule, NgbPopoverModule, NgbAccordionModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentEditorModalComponent } from './components/comment-editor/comment-editor.component';
import { DiagnosisEditorModalComponent } from './components/diagnosis-editor/diagnosis-editor.component';
import { DiagnosisModalComponent } from './components/diagnosis-modal/diagnosis-modal.component';
import { ReferralsModalComponent } from './components/referrals-modal/referrals-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DocumentPreviewModalComponent } from './components/document-preview-modal/document-preview-modal.component';
import { DashboardResolver } from './resolvers/dashboard-resolver';
import { PatientResolver } from './resolvers/patient-resolver';
import { RecordResolver } from './resolvers/record-resolver';
import { SectionEditorComponent } from './components/section-editor/section-editor.component';
import { DiagnosisSectionEditorComponent } from './components/diagnosis-section-editor/diagnosis-section-editor.component';
import { AppointmentSectionEditorComponent } from './components/appointment-section-editor/appointment-section-editor.component';
import { ReferralsSectionEditorComponent } from './components/referrals-section-editor/referrals-section-editor.component';
import { DiagnosisFilterPipePipe } from './pipes/diagnosis-filter-pipe.pipe';
import { DashbordListItemComponent } from './components/dashbord-list-item/dashbord-list-item.component';

import { QuillModule } from "ngx-quill";
import { TextSectionEditorComponent } from './components/text-section-editor/text-section-editor.component';
import { PermissionNames } from '../../models/permission-names';
import { DocumentListItemComponent } from './components/document-list-item/document-list-item.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { DiagnosisListComponent } from './components/diagnosis-list/diagnosis-list.component';
import { DiagnosisListItemComponent } from './components/diagnosis-list-item/diagnosis-list-item.component';
import { ResultListItemComponent } from './components/result-list-item/result-list-item.component';
import { ResultListComponent } from './components/result-list/result-list.component';
import { VisitListItemComponent } from './components/visit-list-item/visit-list-item.component';
import { VisitListComponent } from './components/visit-list/visit-list.component';
import { RecordListItemComponent } from './components/record-list-item/record-list-item.component';
import { OmsComponent } from './components/oms/oms.component';
import { OmsResultModalComponent } from './components/oms-result-modal/oms-result-modal.component';
import { OmsOutcomeModalComponent } from './components/oms-outcome-modal/oms-outcome-modal.component';
import { QuestionaryModalComponent } from './components/questionary-modal/questionary-modal.component';

import Quill from 'quill';

import { SharedModule } from '../shared/shared.module';
import { ClosingSectionEditorComponent } from './components/closing-section-editor/closing-section-editor.component';
import { ReviewsModalComponent } from './components/reviews-modal/reviews-modal.component';
import { DiagnosisWarningModalComponent } from './components/diagnosis-warning-modal/diagnosis-warning-modal.component';
import { SelectedPrescriptionsComponent } from './components/selected-prescriptions/selected-prescriptions.component';
import { PrescriptionsServicesComponent } from './components/prescriptions-services/prescriptions-services.component';
import { PrescriptionsRecommendationsComponent } from './components/prescriptions-recommendations/prescriptions-recommendations.component';
import { AddServiceModalComponent } from './components/add-service-modal/add-service-modal.component';
import { AddServiceModalItemComponent } from './components/add-service-modal-item/add-service-modal-item.component';
import { TemplatesListItemComponent } from './components/templates-list-item/templates-list-item.component';
import { SignDocumentModalComponent } from './components/sign-document-modal/sign-document-modal.component';
import { SignatureWarningModalComponent } from './components/signature-warning-modal/signature-warning-modal.component';
import { SignatureLoadingModalComponent } from './components/signature-loading-modal/signature-loading-modal.component';
import { SignatureInfoModalComponent } from './components/signature-info-modal/signature-info-modal.component';
import { RecentPrescriptionsRecommendationsComponent } from './components/recent-prescriptions-recommendations/recent-prescriptions-recommendations.component';
import { TreatmentsModule } from '../treatments/treatments.module';
import { TreatmentPrescriptionsModalComponent } from '../treatments/components/treatment-prescriptions-modal/treatment-prescriptions-modal.component';
import { ReferralSectionItemComponent } from './components/referral-section-item/referral-section-item.component';
import { TimerActivatorComponent } from './components/timer-activator/timer-activator.component';
import { PrescriptionsManipulationsComponent } from './components/prescriptions-manipulations/prescriptions-manipulations.component';
import { PrescriptionsFavoritesComponent } from './components/prescriptions-favorites/prescriptions-favorites.component';
import { PrescriptionsFavoritesItemComponent } from './components/prescriptions-favorites-item/prescriptions-favorites-item.component';
import { ProtocolEditorHeaderComponent } from './components/protocol-editor-header/protocol-editor-header.component';
import { OutcomeSectionEditorComponent } from './components/outcome-section-editor/outcome-section-editor.component';
import { MetricsSectionEditorComponent } from './components/metrics-section-editor/metrics-section-editor.component';
import { SectionMetricComponent } from './components/section-metric/section-metric.component';
import { PersonMarksModule } from '../person-marks/person-marks.module';
import { MedicalCardModule } from '../medical-card/medical-card.module';
import { RecordEditorModule } from '../record-editor/record-editor.module';
import { ChangeTemplateModalComponent } from '../record-editor/components/change-template-modal/change-template-modal.component';
import { ProtocolInfoModule } from '../protocol-info/protocol-info.module';
import { LabResultsModalComponent } from '../record-editor/components/lab-results-modal/lab-results-modal.component';

const Embed = Quill.import('blots/embed');

class LazyPlaceholder extends Embed {
  static create(content) {

    const node = super.create();
    node.innerHTML = content;
    node.setAttribute('class', 'ql-lazy-placeholder');
    return node;
  }

  static get ATTRIBUTES() {
    return ['data-placeholder', 'title']
  }

  static formats(domNode) {
    const params = {};

    for (const key of this.ATTRIBUTES) {
      const value = domNode.getAttribute(key);

      if (value) {
        params[key] = value;
      }
    }

    return params;
  }

  format(name, value) {
    // Handle unregistered embed formats
    if (name === 'data-placeholder' || name === 'title') {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name, value);
      }
    } else {
      super.format(name, value);
    }
  }
  domNode: any;

  static blotName: string;
  static tagName: string;

  static value(node) {
    return node.innerHTML;
  }
}
LazyPlaceholder.blotName = 'LazyPlaceholder';
LazyPlaceholder.tagName = 'lp';

Quill.register(LazyPlaceholder);

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);

const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);

const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);

const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);

const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);

const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);

const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);

const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);

const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);

const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);

const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);

const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);

const SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

const workspaceRoutes: Routes = [
  {
    path: "records",
    children: [
      {
        path: ":id",
        component: RecordEditorComponent,
        data: {
          title: "Редактор документа",
          requirements: [PermissionNames.ViewWorkspace]
        },
        resolve: {
          payload: RecordResolver
        }
      }
    ]
  },
  {
    path: "medicalfiles",
    children: [
      {
        path: ":id",
        component: PatientComponent,
        data: {
          title: "Медицинская карта пациента",
          requirements: [PermissionNames.ViewWorkspace]
        },
        resolve: {
          payload: PatientResolver
        }
      }
    ]
  },
  {
    path: "",
    component: DashboardComponent,
    data: {
      title: "Список посещений",
      requirements: [PermissionNames.ViewWorkspace]
    },
    resolve: {
      payload: DashboardResolver
    }
  }
]

@NgModule({
  declarations: [
    DashboardComponent,
    PatientComponent,
    RecordEditorComponent,

    TemplatesModalComponent,
    CommentEditorModalComponent,
    DiagnosisEditorModalComponent,
    DiagnosisModalComponent,
    ReferralsModalComponent,
    DocumentPreviewModalComponent,
    SectionEditorComponent,
    DiagnosisSectionEditorComponent,
    AppointmentSectionEditorComponent,
    ReferralsSectionEditorComponent,
    DiagnosisFilterPipePipe,
    DashbordListItemComponent,
    TextSectionEditorComponent,
    DocumentListItemComponent,
    DocumentListComponent,
    DiagnosisListComponent,
    DiagnosisListItemComponent,
    ResultListItemComponent,
    ResultListComponent,
    VisitListItemComponent,
    VisitListComponent,
    RecordListItemComponent,
    OmsComponent,
    OmsResultModalComponent,
    OmsOutcomeModalComponent,
    QuestionaryModalComponent,
    ClosingSectionEditorComponent,
    ReviewsModalComponent,
    DiagnosisWarningModalComponent,
    SelectedPrescriptionsComponent,
    PrescriptionsServicesComponent,
    PrescriptionsRecommendationsComponent,
    AddServiceModalComponent,
    AddServiceModalItemComponent,
    TemplatesListItemComponent,
    SignDocumentModalComponent,
    SignatureWarningModalComponent,
    SignatureLoadingModalComponent,
    SignatureInfoModalComponent,
    RecentPrescriptionsRecommendationsComponent,
    ReferralSectionItemComponent,
    TimerActivatorComponent,
    PrescriptionsManipulationsComponent,
    PrescriptionsFavoritesComponent,
    PrescriptionsFavoritesItemComponent,
    ProtocolEditorHeaderComponent,
    OutcomeSectionEditorComponent,
    MetricsSectionEditorComponent,
    SectionMetricComponent,

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(workspaceRoutes),
    FormsModule,
    ReactiveFormsModule,

    SharedModule,
    TreatmentsModule,
    PersonMarksModule,
    MedicalCardModule,
    RecordEditorModule,

    NgxSelectModule,
    EditorModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbButtonsModule,
    NgbPopoverModule,
    InfiniteScrollModule,
    DragDropModule,
    NgbAccordionModule,
    NgbTypeaheadModule,

    QuillModule.forRoot(),

    LayoutModule,
    ControlsModule,

    ProtocolInfoModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    TemplatesModalComponent,
    CommentEditorModalComponent,
    DiagnosisEditorModalComponent,
    DiagnosisModalComponent,
    ReferralsModalComponent,
    DocumentPreviewModalComponent,
    OmsResultModalComponent,
    OmsOutcomeModalComponent,
    QuestionaryModalComponent,
    ReviewsModalComponent,
    DiagnosisWarningModalComponent,
    AddServiceModalComponent,
    SignDocumentModalComponent,
    SignatureWarningModalComponent,
    SignatureLoadingModalComponent,
    SignatureInfoModalComponent,

    TreatmentPrescriptionsModalComponent,

    // from RecordEditorModule
    ChangeTemplateModalComponent,
    LabResultsModalComponent
  ]
})
export class WorkspaceModule { }
