import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceSet, PriceSetGroupServicePrice, ServiceGroup, ServiceGroupService } from '../../../../generated/models';
import { FormControl, FormGroup } from '@angular/forms';
import { PriceSetsService } from '../../../../generated/services';

@Component({
  selector: 'mp-prices-modal',
  templateUrl: './prices-modal.component.html',
  styleUrls: ['./prices-modal.component.scss']
})
export class PricesModalComponent implements OnInit {

  @Input() priceSets: PriceSet[] = [];
  @Input() serviceGroupServices: ServiceGroupService[] = [];
  @Input() group: ServiceGroup;

  prices: ServicePrice[] = [];

  grossPrice = 0;
  discountedPrice = 0;
  finalPrice = 0;

  @Output() onCancel = new EventEmitter<void>();

  form = new FormGroup({
    priceSetId: new FormControl(undefined)
  });

  constructor(private priceSetsService: PriceSetsService) {
    this.form.valueChanges.subscribe((value: { priceSetId: number }) => {
      this.priceSetsService.ServiceGroupPrices({ id: value.priceSetId, groupId: this.group.id })
        .subscribe((response: PriceSetGroupServicePrice[]) => {
          this.grossPrice = 0;
          this.discountedPrice = 0;
          this.finalPrice = 0;

          for (const service of this.prices) {
            const price = response.find(x => x.serviceId === service.serviceId);

            if (!!price) {
              service.grossPrice = price.grossPrice;
              service.discountedPrice = price.discountedPrice;
              service.finalPrice = price.finalPrice;

              this.grossPrice += service.grossPrice;
              this.discountedPrice += service.discountedPrice;
              this.finalPrice += service.finalPrice;
            } else {
              service.grossPrice = 0;
              service.discountedPrice = 0;
              service.finalPrice = 0;
            }
          }



        });
    });

  }

  ngOnInit() {
    if (this.priceSets.some(x => !x.companyId && x.default)) {
      this.form.patchValue({ priceSetId: this.priceSets.find(x => !x.companyId && x.default).id });
    } else if (this.priceSets.some(x => x.default)) {
      this.form.patchValue({ priceSetId: this.priceSets.find(x => x.default).id });
    } else if (this.priceSets.length > 0) {
      this.form.patchValue({ priceSetId: this.priceSets[0].id });
    }

    this.prices = this.serviceGroupServices.map(service => ({
      serviceId: service.serviceId,
      code: service.serviceCode,
      name: service.serviceName,

      grossPrice: 0,
      discountedPrice: 0,
      finalPrice: 0
    }))

  }

  cancel = () => this.onCancel.emit();

}

interface ServicePrice {
  serviceId: number;
  code: number;
  name: string;
  grossPrice: number;
  discountedPrice: number;
  finalPrice: number;
}
