import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ClinicalRecommendation, EmployeeFavoriteManipulation, RecordSection, SectionsServicePrescription } from '../../../../generated/models';
import { Recommendation } from '../referral-section-item/referral-section-item.component';

@Component({
  selector: 'mp-referrals-section-editor',
  templateUrl: './referrals-section-editor.component.html',
  styleUrls: ['./referrals-section-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferralsSectionEditorComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() disabled = false;
  @Input() patientAge: number;
  @Input() patientGender = '';
  @Input() section: RecordSection;

  @Input() favoritePrescriptions: EmployeeFavoriteManipulation[] = [];
  @Input() recommendations: ClinicalRecommendation[] = [];
  @Output() onRequest = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<RecordSection>();

  hints: { [name: string]: Recommendation[] } = {};

  placeholder = "Комментарий к назначениям";

  modules: QuillEditorOptions = { toolbar: false };

  commentControl = new FormControl();

  items: SectionsServicePrescription[] = [];

  constructor() {
    this.commentControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: string) => this.onChange.emit({ ...this.section, comment: value }));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.commentControl.value !== this.section.comment) {
      this.commentControl.setValue(this.section.comment, { emitEvent: false });
    }

    if (changes['disabled']) {
      if (changes['disabled'].currentValue) {
        this.commentControl.disable({ emitEvent: false });
      } else {
        this.commentControl.enable({ emitEvent: false });
      }
    }

    this.hints = {};

    this.items = [];
    const items = [];

    const groups = {};

    for (const item of this.section.referrals) {
      if (!groups[item.name]) groups[item.name] = [];
      if (!this.hints[item.name]) this.hints[item.name] = [];

      groups[item.name].push({
        code: `${item.code || ''}`,
        name: item.name,
        serviceId: item.serviceId,
        manipulationId: item.manipulationId,
        listOrder: item.listOrder
      });
    }

    for (const item of this.recommendations) {
      if (!this.showRecommendation(item)) continue;
      if (!groups[item.manipulationName]) groups[item.manipulationName] = [];
      if (!this.hints[item.manipulationName]) this.hints[item.manipulationName] = [];

      this.hints[item.manipulationName].push({ title: item.clinicalRecommendationName, text: item.text, type: item.type });
      groups[item.manipulationName].push({
        code: `${item.serviceCode || ''}`,
        name: item.manipulationName,
        serviceId: item.serviceId,
        manipulationId: item.manipulationId
      });
    }

    for (const item of this.favoritePrescriptions) {
      if (!groups[item.manipulationName]) groups[item.manipulationName] = [];
      if (!this.hints[item.manipulationName]) this.hints[item.manipulationName] = [];

      groups[item.manipulationName].push({
        code: ``,
        name: item.manipulationName,
        manipulationId: item.manipulationId
      });
    }

    for (const name of Object.keys(groups)) {
      items.push({
        name,
        code: groups[name].some(x => x.code) ? groups[name].filter(x => x.code)[0].code : '',
        serviceId: groups[name].some(x => x.serviceId > 0) ? groups[name].filter(x => x.serviceId > 0)[0].serviceId : null,
        manipulationId: groups[name].some(x => x.manipulationId > 0) ? groups[name].filter(x => x.manipulationId > 0)[0].manipulationId : null,
        listOrder: Math.max(...groups[name].map(x => x.listOrder || 0))
      });
    }

    this.items = items.sort((a, b) => ((this.selected(b) ? 1 : 0) - (this.selected(a) ? 1 : 0)) * 1000 + (a.listOrder - b.listOrder) * 10 + b.name.localeCompare(a.name))

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  requestReferrals() {
    if (!this.disabled) this.onRequest.emit();
  }

  selected = (item: SectionsServicePrescription) => this.section.referrals.some(x => x.name === item.name);

  toggle(item: SectionsServicePrescription) {
    if (this.disabled) return;

    let referrals = [...this.section.referrals];

    if (referrals.some(x => x.name === item.name)) {
      referrals = referrals.filter(x => x.name !== item.name);
    }
    else {
      referrals = [...referrals, item];
    }
    this.onChange.emit({ ...this.section, referrals });
  }

  showRecommendation(recommendation: ClinicalRecommendation): boolean {
    if (recommendation.maxAge && recommendation.maxAge < this.patientAge) return false;
    if (recommendation.minAge && recommendation.minAge > this.patientAge) return false;
    if (recommendation.gender === 'f' && this.patientGender !== 'f') return false;
    if (recommendation.gender === 'm' && this.patientGender !== 'm') return false;

    return true;
  }

}

interface QuillEditorOptions {
  toolbar: boolean;
}
