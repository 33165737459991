import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ResourcesDayScheduleViewModel } from '../../../../generated/models';

@Component({
  selector: 'mp-day-resource',
  templateUrl: './day-resource.component.html',
  styleUrls: ['./day-resource.component.scss']
})
export class DayResourceComponent implements OnInit {

  @Input() resource: ResourcesDayScheduleViewModel;

  @Input()
  @HostBinding("class.active")
  active = false;

  constructor() { }

  ngOnInit() { }

  get specialities(): string {
    if (!this.resource || !this.resource.specialities) return '';
    return this.resource.specialities.map(x => x.name).join(', ');
  }

}
