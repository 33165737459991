/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DiagnosisAlias } from '../models/diagnosis-alias';
import { CreateDiagnosisAliasResponse } from '../models/create-diagnosis-alias-response';
import { CreateDiagnosisAliasRequest } from '../models/create-diagnosis-alias-request';
@Injectable({
  providedIn: 'root',
})
class DiagnosisAliasesService extends __BaseService {
  static readonly FindAliasesAsyncPath = '/api/v2/diagnoses/aliases';
  static readonly DiagnosisAliasesAsyncPath = '/api/v2/diagnoses/{code}/aliases';
  static readonly CreateDiagnosisAliasAsyncPath = '/api/v2/diagnoses/{code}/aliases';
  static readonly RemoveDiagnosisAliasAsyncPath = '/api/v2/diagnoses/aliases/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param Search undefined
   * @return Success
   */
  FindAliasesAsyncResponse(Search?: string): __Observable<__StrictHttpResponse<Array<DiagnosisAlias>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (Search != null) __params = __params.set('Search', Search.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnoses/aliases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiagnosisAlias>>;
      })
    );
  }
  /**
   * @param Search undefined
   * @return Success
   */
  FindAliasesAsync(Search?: string): __Observable<Array<DiagnosisAlias>> {
    return this.FindAliasesAsyncResponse(Search).pipe(
      __map(_r => _r.body as Array<DiagnosisAlias>)
    );
  }

  /**
   * @param code undefined
   * @return Success
   */
  DiagnosisAliasesAsyncResponse(code: string): __Observable<__StrictHttpResponse<Array<DiagnosisAlias>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/diagnoses/${code}/aliases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DiagnosisAlias>>;
      })
    );
  }
  /**
   * @param code undefined
   * @return Success
   */
  DiagnosisAliasesAsync(code: string): __Observable<Array<DiagnosisAlias>> {
    return this.DiagnosisAliasesAsyncResponse(code).pipe(
      __map(_r => _r.body as Array<DiagnosisAlias>)
    );
  }

  /**
   * @param params The `DiagnosisAliasesService.CreateDiagnosisAliasAsyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateDiagnosisAliasAsyncResponse(params: DiagnosisAliasesService.CreateDiagnosisAliasAsyncParams): __Observable<__StrictHttpResponse<CreateDiagnosisAliasResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/diagnoses/${params.code}/aliases`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateDiagnosisAliasResponse>;
      })
    );
  }
  /**
   * @param params The `DiagnosisAliasesService.CreateDiagnosisAliasAsyncParams` containing the following parameters:
   *
   * - `code`:
   *
   * - `request`:
   *
   * @return Success
   */
  CreateDiagnosisAliasAsync(params: DiagnosisAliasesService.CreateDiagnosisAliasAsyncParams): __Observable<CreateDiagnosisAliasResponse> {
    return this.CreateDiagnosisAliasAsyncResponse(params).pipe(
      __map(_r => _r.body as CreateDiagnosisAliasResponse)
    );
  }

  /**
   * @param id undefined
   */
  RemoveDiagnosisAliasAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/diagnoses/aliases/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveDiagnosisAliasAsync(id: number): __Observable<null> {
    return this.RemoveDiagnosisAliasAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module DiagnosisAliasesService {

  /**
   * Parameters for CreateDiagnosisAliasAsync
   */
  export interface CreateDiagnosisAliasAsyncParams {
    code: string;
    request?: CreateDiagnosisAliasRequest;
  }
}

export { DiagnosisAliasesService }
