import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mp-colorpicker',
  templateUrl: './colorpicker.component.html',
  styleUrls: ['./colorpicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorpickerComponent),
    multi: true
  }]
})

export class ColorpickerComponent implements OnInit, ControlValueAccessor {

  colors: string[] = [];
  color = '';

  fn: (valye: string) => void;

  constructor() {
    const values = ['000000', 'FF0000', '800000', 'FFFF00', 'FFA500', '808000', '00FF00', '008000', '00FFFF', '008080', '0000FF', '000080', 'FF00FF', '800080'];
    const opacities = ['FF', '88', '22'];

    for (let o of opacities) {
      for (let b of values) {
        this.colors.push(`#${b}${o}`);
      }

    }
  }
  writeValue(obj: string): void {
    this.color = obj;
  }
  registerOnChange(fn: any): void {
    this.fn = fn;
  }
  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void { }

  ngOnInit() { }

  select(color: string) {
    this.color = color;

    this.fn(color);

  }
}
