import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class WidgetService {

  constructor(private appRef: ApplicationRef, private resolver: ComponentFactoryResolver, private injector: Injector) { }

  open<T>(type: Type<T>, options?: WidgetOptions): WidgetRef<T> {
    const widget = this.resolver.resolveComponentFactory(type).create(this.injector);

    this.appRef.attachView(widget.hostView);
    document.body.appendChild((widget.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement);

    if (options && options.bodyClass) {
      document.body.classList.add(options.bodyClass);
    }

    const widgetRef = new WidgetRef(widget);

    widgetRef.close = () => {
      this.appRef.detachView(widget.hostView);
      widget.destroy();

      if (options && options.bodyClass) {
        document.body.classList.remove(options.bodyClass);
      }
    };

    return widgetRef;
  }
}

export interface WidgetOptions {
  bodyClass?: string;
}

export class WidgetRef<T> {
  private _ref: ComponentRef<T>;

  constructor(ref: ComponentRef<T>) {
    this._ref = ref;
  }

  get componentInstance(): T { return this._ref.instance; }

  close: () => void;
}
