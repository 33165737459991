import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { DiseaseOutcome, TherapyResult } from '../../../../generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OmsResultModalComponent } from '../oms-result-modal/oms-result-modal.component';
import { OmsOutcomeModalComponent } from '../oms-outcome-modal/oms-outcome-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'mp-oms',
  templateUrl: './oms.component.html',
  styleUrls: ['./oms.component.scss']
})
export class OmsComponent implements OnInit, OnDestroy {
  private _destroy: Subject<void> = new Subject<void>();

  @Input() outcomes: DiseaseOutcome[];
  @Input() results: TherapyResult[];

  @Input()
  public set data(value: { outcome: number; result: number; resultDate: moment.Moment }) {
    console.info(value);
    this.form.patchValue(value, { emitEvent: false });
  }


  @Output()
  onChange: EventEmitter<FormValue> = new EventEmitter<FormValue>();

  public form: FormGroup;

  constructor(
    private modalService: NgbModal
  ) {
    this.form = new FormGroup({
      result: new FormControl(undefined),
      outcome: new FormControl(undefined),
      resultDate: new FormControl(undefined)
    });

    this.form.valueChanges
      .pipe(
        takeUntil(this._destroy)
      )
      .subscribe(
        (value: FormValue): void => {
          this.onChange.emit(value);
        }
      );
  }

  ngOnInit() { }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  public get formValue(): FormValue { return this.form.value }

  public changeResult = (): void => {
    const modal: NgbModalRef = this.modalService.open(OmsResultModalComponent, { backdrop: 'static', size: 'lg' });

    modal.componentInstance.title = "Результат обращения за медпомощью";
    modal.componentInstance.results = this.results;
    modal.componentInstance.selected = this.form.get('result').value;

    modal.result.then(
      (result: TherapyResult) => {
        this.form.patchValue({ result: result.id });
        if (!this.form.get("resultDate").value.isValid()) {
          this.form.patchValue({ resultDate: moment() })
        }
      },
      () => { }
    )
  }

  public changeOutcome = (): void => {
    const modal: NgbModalRef = this.modalService.open(OmsOutcomeModalComponent, { backdrop: 'static', size: 'lg' });

    modal.componentInstance.title = "Исход заболевания";
    modal.componentInstance.outcomes = this.outcomes;
    modal.componentInstance.selected = this.form.get('outcome').value;

    modal.result.then(
      (outcome: DiseaseOutcome) => {
        this.form.patchValue({ outcome: outcome.id });
        if (!this.form.get("resultDate").value.isValid()) {
          this.form.patchValue({ resultDate: moment() })
        }
      },
      () => { }
    )
  }

  public resultName = (id: number): string => {
    let index = this.results.findIndex(value => value.id == id);
    if (index != -1) {
      return this.results[index].name;
    }
    else {
      return "";
    }
  }

  public outcomeName = (id: number): string => {
    let index = this.outcomes.findIndex(value => value.id == id);
    if (index != -1) {
      return this.outcomes[index].name;
    }
    else {
      return "";
    }
  }

}

interface FormValue {
  result: number;
  outcome: number;
  resultDate: string;
}
