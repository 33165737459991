import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { PriceSet, PriceEditorModel } from 'projects/Clinic/src/app/generated/models';

@Component({
  selector: 'mp-service-price-modal',
  templateUrl: './service-price.modal.component.html',
  styleUrls: ['./service-price.modal.component.scss']
})
export class ServicePriceModalComponent implements OnInit {

  @Input() serviceId: number;
  @Input() priceSets: PriceSet[];
  @Input() price: PriceEditorModel;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
  @Output() onSubmit: EventEmitter<ServicePricePayload> = new EventEmitter<ServicePricePayload>();

  title = "Цена услуги";
  form: FormGroup;

  constructor() {

    this.form = new FormGroup({
      priceSetId: new FormControl(null, [Validators.required]),
      costs: new FormControl(null),
      wages: new FormControl(null),
      price: new FormControl(null, [Validators.required, Validators.min(0)]),
      quantity: new FormControl(null)
    });
  }

  ngOnInit() {

    this.form.patchValue(this.price);

    if (this.price.id) {
      this.form.get("priceSetId").disable();
    }
  }

  public cancel(): void {
    this.onClose.emit();
  }

  public submit(): void {

    Object.entries(this.form.controls)
      .forEach((x: [string, AbstractControl]) => x[1].markAsDirty());


    if (!this.form.valid) {
      return;
    }

    const payload: ServicePricePayload = this.form.getRawValue();

    this.onSubmit.emit(payload);
  }
}

interface ServicePricePayload {
  priceSetId: number;
  costs: number;
  wages: number;
  price: number;
  quantity: number;
}
