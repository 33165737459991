import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActiveCallService, CallAction } from '../../services/active-call-service';

@Component({
  selector: 'mp-active-call-report-modal',
  templateUrl: './active-call-report-modal.component.html',
  styleUrls: ['./active-call-report-modal.component.scss']
})
export class ActiveCallReportModalComponent implements OnInit {

  @Input() actions: CallAction[] = [];
  @Input() processing = false;

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<ActiveCallReportPayload>();

  available: CallAction[] = [{ ActionType: 1 }, { ActionType: 2 }, { ActionType: 3 }];

  form = new FormGroup({
    comment: new FormControl("", [])
  });

  constructor(private activeCallSrevice: ActiveCallService) {
    this.activeCallSrevice.actionsUpdates$.subscribe((actions) => this.actions = [...actions]);
  }

  ngOnInit() { }

  remove = (action: CallAction) => this.activeCallSrevice.removeAction(action);
  add = (action: CallAction) => this.activeCallSrevice.addAction(action.ActionType);

  cancel = () => this.onCancel.emit();

  selected = (action: CallAction) => this.actions.some(x => x.ActionType === action.ActionType);

  confirm() {
    if (this.processing) return;
    this.processing = true;

    Object.entries(this.form.controls).forEach(x => x[1].markAsDirty());

    if (this.form.invalid) return;

    this.onConfirm.emit(this.form.getRawValue());
  }

  display(action: CallAction) {
    if (!action) return '';

    switch (action.ActionType) {
      case 1: return 'Консультация';
      case 2: return 'Действия с расписанием';
      case 3: return 'Действие с результатами';
      case 4: return 'Другое';
    }
  }

}

export interface ActiveCallReportPayload {
  comment: string;
}
