/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ServiceGroup } from '../models/service-group';
import { CreateServiceGroupResponse } from '../models/create-service-group-response';
import { CreateServiceGroupRequest } from '../models/create-service-group-request';
import { UpdateServiceGroupRequest } from '../models/update-service-group-request';
import { ServiceGroupService } from '../models/service-group-service';
import { AddServiceIntoServiceGroupRequest } from '../models/add-service-into-service-group-request';
@Injectable({
  providedIn: 'root',
})
class ServiceGroupsService extends __BaseService {
  static readonly ServiceGroupsAsyncPath = '/api/v2/services/groups';
  static readonly CreateServiceGroupAsyncPath = '/api/v2/services/groups';
  static readonly ServiceGroupAsyncPath = '/api/v2/services/groups/{id}';
  static readonly UpdateServiceGroupAsyncPath = '/api/v2/services/groups/{id}';
  static readonly RemoveServiceGroupAsyncPath = '/api/v2/services/groups/{id}';
  static readonly ServiceGroupServicesAsyncPath = '/api/v2/services/groups/{id}/services';
  static readonly AddServiceIntoServiceGroupAsyncPath = '/api/v2/services/groups/{id}/services';
  static readonly ServiceGroupsForServiceAsyncPath = '/api/v2/services/{serviceId}/groups';
  static readonly RemoveServiceFromServiceGroupPath = '/api/v2/services/groups/{id}/services/{serviceId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ServiceGroupsService.ServiceGroupsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchival`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  ServiceGroupsAsyncResponse(params: ServiceGroupsService.ServiceGroupsAsyncParams): __Observable<__StrictHttpResponse<Array<ServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeArchival != null) __params = __params.set('IncludeArchival', params.IncludeArchival.toString());
    if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceGroup>>;
      })
    );
  }
  /**
   * @param params The `ServiceGroupsService.ServiceGroupsAsyncParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `IncludeArchival`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  ServiceGroupsAsync(params: ServiceGroupsService.ServiceGroupsAsyncParams): __Observable<Array<ServiceGroup>> {
    return this.ServiceGroupsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<ServiceGroup>)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateServiceGroupAsyncResponse(request?: CreateServiceGroupRequest): __Observable<__StrictHttpResponse<CreateServiceGroupResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateServiceGroupResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  CreateServiceGroupAsync(request?: CreateServiceGroupRequest): __Observable<CreateServiceGroupResponse> {
    return this.CreateServiceGroupAsyncResponse(request).pipe(
      __map(_r => _r.body as CreateServiceGroupResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupAsyncResponse(id: number): __Observable<__StrictHttpResponse<ServiceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/groups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceGroup>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupAsync(id: number): __Observable<ServiceGroup> {
    return this.ServiceGroupAsyncResponse(id).pipe(
      __map(_r => _r.body as ServiceGroup)
    );
  }

  /**
   * @param params The `ServiceGroupsService.UpdateServiceGroupAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateServiceGroupAsyncResponse(params: ServiceGroupsService.UpdateServiceGroupAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v2/services/groups/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServiceGroupsService.UpdateServiceGroupAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateServiceGroupAsync(params: ServiceGroupsService.UpdateServiceGroupAsyncParams): __Observable<null> {
    return this.UpdateServiceGroupAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveServiceGroupAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/services/groups/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveServiceGroupAsync(id: number): __Observable<null> {
    return this.RemoveServiceGroupAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupServicesAsyncResponse(id: number): __Observable<__StrictHttpResponse<Array<ServiceGroupService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/groups/${id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceGroupService>>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  ServiceGroupServicesAsync(id: number): __Observable<Array<ServiceGroupService>> {
    return this.ServiceGroupServicesAsyncResponse(id).pipe(
      __map(_r => _r.body as Array<ServiceGroupService>)
    );
  }

  /**
   * @param params The `ServiceGroupsService.AddServiceIntoServiceGroupAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddServiceIntoServiceGroupAsyncResponse(params: ServiceGroupsService.AddServiceIntoServiceGroupAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/services/groups/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServiceGroupsService.AddServiceIntoServiceGroupAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddServiceIntoServiceGroupAsync(params: ServiceGroupsService.AddServiceIntoServiceGroupAsyncParams): __Observable<null> {
    return this.AddServiceIntoServiceGroupAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceGroupsForServiceAsyncResponse(serviceId: number): __Observable<__StrictHttpResponse<Array<ServiceGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/services/${serviceId}/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceGroup>>;
      })
    );
  }
  /**
   * @param serviceId undefined
   * @return Success
   */
  ServiceGroupsForServiceAsync(serviceId: number): __Observable<Array<ServiceGroup>> {
    return this.ServiceGroupsForServiceAsyncResponse(serviceId).pipe(
      __map(_r => _r.body as Array<ServiceGroup>)
    );
  }

  /**
   * @param params The `ServiceGroupsService.RemoveServiceFromServiceGroupParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  RemoveServiceFromServiceGroupResponse(params: ServiceGroupsService.RemoveServiceFromServiceGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/services/groups/${params.id}/services/${params.serviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ServiceGroupsService.RemoveServiceFromServiceGroupParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  RemoveServiceFromServiceGroup(params: ServiceGroupsService.RemoveServiceFromServiceGroupParams): __Observable<null> {
    return this.RemoveServiceFromServiceGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ServiceGroupsService {

  /**
   * Parameters for ServiceGroupsAsync
   */
  export interface ServiceGroupsAsyncParams {
    Size?: number;
    Search?: string;
    Page?: number;
    IncludeArchival?: boolean;
    CategoryId?: number;
  }

  /**
   * Parameters for UpdateServiceGroupAsync
   */
  export interface UpdateServiceGroupAsyncParams {
    id: number;
    request?: UpdateServiceGroupRequest;
  }

  /**
   * Parameters for AddServiceIntoServiceGroupAsync
   */
  export interface AddServiceIntoServiceGroupAsyncParams {
    id: number;
    request?: AddServiceIntoServiceGroupRequest;
  }

  /**
   * Parameters for RemoveServiceFromServiceGroup
   */
  export interface RemoveServiceFromServiceGroupParams {
    serviceId: number;
    id: number;
  }
}

export { ServiceGroupsService }
