import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonMetricsListComponent } from './components/person-metrics-list/person-metrics-list.component';
import { PersonMetricsListItemComponent } from './components/person-metrics-list-item/person-metrics-list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PersonMetricsListComponent, PersonMetricsListItemComponent],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule

  ],
  exports: [
    PersonMetricsListComponent, PersonMetricsListItemComponent
  ]
})
export class MedicalCardModule { }
