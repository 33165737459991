import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Company, ImportPatientsResponse, ParsedPatient } from '../../../../generated/models';
import { ParseService } from '../../../../generated/services/parse.service';
import { CheckingPatient } from '../parsed-patient/parsed-patient.component';

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ImportService } from '../../../../generated/services/import.service';
import { ActivatedRoute } from '@angular/router';
import { ImportPatientsPayload } from '../../resolvers/import-patients-resolver';

@Component({
  selector: 'mp-import-patients',
  templateUrl: './import-patients.component.html',
  styleUrls: ['./import-patients.component.scss'],
  host: { class: "page" }
})
export class ImportPatientsComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  empty = true;
  loading = false;
  loaded = false;

  dirty = false;

  patients: CheckingPatient[] = [];
  dmsInsuranceCompanies: Company[] = [];

  @ViewChild("fileUpload") fileUpload: ElementRef<HTMLInputElement>;

  uploader: FormControl;

  get canClear(): boolean { return this.patients.length > 0; }
  get valid(): boolean {
    return !this.patients.some(x => {
      return !x.firstname || !x.lastname || !x.gender || !x.dob;
    });
  }

  constructor(private parseService: ParseService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private importService: ImportService) {

    this.uploader = new FormControl();

    this.uploader.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((): void => {
        const files = this.fileUpload.nativeElement.files;

        if (files.length > 0) {
          this.loading = true;
          this.empty = false;
          this.loaded = false;

          this.parseService.ParsePatientsAsync(files[0]).subscribe(
            (patients: ParsedPatient[]): void => {
              this.loading = false;
              this.empty = false;
              this.loaded = true;

              this.uploader.reset();

              this.patients = patients.map((x: ParsedPatient): CheckingPatient => {
                let insuranceCompanyId = undefined;

                if (x.insuranceCompanyName) {
                  const index = this.dmsInsuranceCompanies.findIndex(c => c.name.toLowerCase() === x.insuranceCompanyName.toLowerCase().trim());

                  if (index !== -1) {
                    insuranceCompanyId = this.dmsInsuranceCompanies[index].id;
                  }
                }

                return ({
                  id: Math.floor(Math.random() * 1000000) * -1,
                  lastname: x.lastname,
                  firstname: x.firstname,
                  middlename: x.middlename,
                  dob: x.dob,
                  gender: x.gender,
                  dmsCertificate: x.dmsCertificate,
                  dmsInsuranceCompanyId: insuranceCompanyId
                })
              });

            },
            (): void => {
              this.loading = false;
              this.empty = true;
              this.loaded = false;
            }
          );
        }
      });

  }

  ngOnInit() {
    this.activatedRoute.data.subscribe((data: { payload: ImportPatientsPayload }): void => {
      this.dmsInsuranceCompanies = data.payload.dmsInsuranceCompanies;
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  selectFile(): void {
    this.fileUpload.nativeElement.click();
  }

  clear(): void {
    this.patients = [];
    this.empty = true;
    this.loaded = false;

    this.uploader.reset();
  }

  send(): void {
    this.dirty = true;

    if (this.patients.length === 0) {
      this.toastrService.warning("Нет пациентов для импорта", "Ошибка");
      return;
    }
    const invalid = this.patients.filter(x => {
      if (x.gender !== 'f' && x.gender !== 'm') {
        return true;
      }

      return !x.lastname || !x.firstname || !x.dob || !x.dmsCertificate || !x.dmsInsuranceCompanyId;
    });

    if (invalid.length > 0) {
      this.toastrService.warning(`Пациентов с некорректными данными: ${invalid.length}`, "Ошибка");
      return;
    }

    this.loaded = false;
    this.empty = false;
    this.loading = true;

    this.importService.ImportPatientsAsync({ patients: this.patients })
      .subscribe(
        (response: ImportPatientsResponse) => {

          if (response.success) {
            this.loading = false;
            this.toastrService.success("Пациенты импортированы", "Успешно");
            this.clear();
          } else {
            this.loaded = true;
            this.empty = false;
            this.loading = false;

            const invalidCount = response.patients.filter(x => x.errors.length > 0).length;
            this.toastrService.warning(`Пациентов с некорректными данными: ${invalidCount}`, "Ошибка");

            this.patients = response.patients.map(x => ({
              id: Math.floor(Math.random() * 1000000) * -1,
              firstname: x.firstname,
              middlename: x.middlename,
              lastname: x.lastname,
              gender: x.gender,
              dob: x.dob,
              dmsCertificate: x.dmsCertificate,
              dmsInsuranceCompanyId: x.dmsInsuranceCompanyId
            }));
          }

        },
        (): void => {
          this.loaded = true;
          this.empty = false;
          this.loading = false;
        });
  }

  add(): void {
    const patient: CheckingPatient = {
      id: Math.floor(Math.random() * 1000000) * -1,
      lastname: '',
      firstname: '',
      middlename: '',
      gender: '',
      dob: '',
      dmsCertificate: '',
      dmsInsuranceCompanyId: undefined
    };

    this.patients.push(patient);
  }

  update(patient: CheckingPatient): void {
    const index = this.patients.findIndex(x => x.id === patient.id);

    if (index !== -1) {
      this.patients[index].firstname = patient.firstname;
      this.patients[index].middlename = patient.middlename;
      this.patients[index].lastname = patient.lastname;
      this.patients[index].dob = patient.dob;
      this.patients[index].gender = patient.gender;
      this.patients[index].dmsCertificate = patient.dmsCertificate;
      this.patients[index].dmsInsuranceCompanyId = patient.dmsInsuranceCompanyId;
    }
  }

  remove(patient: CheckingPatient): void {
    this.patients = this.patients.filter(x => x.id !== patient.id);
  }
}
