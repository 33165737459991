import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RecordSection, SectionsTreatmentPrescription } from '../../../../generated/models';

@Component({
  selector: 'mp-treatment-prescriptions-section-editor',
  templateUrl: './treatment-prescriptions-section-editor.component.html',
  styleUrls: ['./treatment-prescriptions-section-editor.component.scss']
})
export class TreatmentPrescriptionsSectionEditorComponent implements OnInit {

  @Input() section: RecordSection = {};
  @Input() disabled = true;

  @Output()
  onPrescriptionsRequested: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  format(prescription: SectionsTreatmentPrescription): string {
    return [prescription.treatmentName, prescription.dosage, prescription.schema, prescription.duration].filter(x => x).join(', ');
  }

  requestPrescriptions(): void {
    if (this.disabled) {
      return;
    }

    this.onPrescriptionsRequested.emit();
  }

}
