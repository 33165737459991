import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmployeePbxNumber } from '../../../../generated/models/employee-pbx-number';
import { PbxOperatorViewModel } from '../../../../generated/models';

@Component({
  selector: 'mp-employee-pbx-list-item',
  templateUrl: './employee-pbx-list-item.component.html',
  styleUrls: ['./employee-pbx-list-item.component.scss']
})
export class EmployeePbxListItemComponent implements OnInit {

  @Input() phone: EmployeePbxNumber;

  @Output()
  onDelete: EventEmitter<EmployeePbxNumber> = new EventEmitter<EmployeePbxNumber>();

  public get info(): string {
    let info = "";
    if (this.phone.pbxExt) {
      info += ` - Добавочный: ${this.phone.pbxExt}`
    }
    if (this.phone.pbxUser) {
      info += ` - Пользователь: ${this.phone.pbxUser}`
    }
    return info;
  }

  public get company(): string { return this.phone.companyId > 0 ? this.phone.companyName : "Все организации"; }


  constructor() { }

  ngOnInit() { }

  public delete = (): void => {
    this.onDelete.emit();
  }
}
