/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PrintReportResponse } from '../models/print-report-response';
import { PrintReportRequest } from '../models/print-report-request';
import { ReportEmployee } from '../models/report-employee';
import { ReportPromotion } from '../models/report-promotion';
import { ReportDiscount } from '../models/report-discount';
import { ReportService } from '../models/report-service';
import { ReportGroup } from '../models/report-group';
@Injectable({
  providedIn: 'root',
})
class ReportsService extends __BaseService {
  static readonly PreparedPath = '/api/v1/Reports/prepared';
  static readonly PrintPath = '/api/v1/Reports/prepared';
  static readonly ReportEmployeesPath = '/api/v1/Reports/employees';
  static readonly ReportPromotionsPath = '/api/v1/Reports/promotions';
  static readonly ReportDiscountsPath = '/api/v1/Reports/discounts';
  static readonly ReportServicesPath = '/api/v1/Reports/services';
  static readonly GroupsAsyncPath = '/api/v1/Reports/groups';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReportsService.PreparedParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   */
  PreparedResponse(params: ReportsService.PreparedParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Type != null) __params = __params.set('Type', params.Type.toString());
    if (params.Token != null) __params = __params.set('Token', params.Token.toString());
    if (params.Expires != null) __params = __params.set('Expires', params.Expires.toString());
    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/prepared`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ReportsService.PreparedParams` containing the following parameters:
   *
   * - `Type`:
   *
   * - `Token`:
   *
   * - `Expires`:
   *
   * - `Download`:
   */
  Prepared(params: ReportsService.PreparedParams): __Observable<null> {
    return this.PreparedResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  PrintResponse(request?: PrintReportRequest): __Observable<__StrictHttpResponse<PrintReportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/Reports/prepared`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PrintReportResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Print(request?: PrintReportRequest): __Observable<PrintReportResponse> {
    return this.PrintResponse(request).pipe(
      __map(_r => _r.body as PrintReportResponse)
    );
  }

  /**
   * @return Success
   */
  ReportEmployeesResponse(): __Observable<__StrictHttpResponse<Array<ReportEmployee>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportEmployee>>;
      })
    );
  }
  /**
   * @return Success
   */
  ReportEmployees(): __Observable<Array<ReportEmployee>> {
    return this.ReportEmployeesResponse().pipe(
      __map(_r => _r.body as Array<ReportEmployee>)
    );
  }

  /**
   * @return Success
   */
  ReportPromotionsResponse(): __Observable<__StrictHttpResponse<Array<ReportPromotion>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/promotions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportPromotion>>;
      })
    );
  }
  /**
   * @return Success
   */
  ReportPromotions(): __Observable<Array<ReportPromotion>> {
    return this.ReportPromotionsResponse().pipe(
      __map(_r => _r.body as Array<ReportPromotion>)
    );
  }

  /**
   * @return Success
   */
  ReportDiscountsResponse(): __Observable<__StrictHttpResponse<Array<ReportDiscount>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/discounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportDiscount>>;
      })
    );
  }
  /**
   * @return Success
   */
  ReportDiscounts(): __Observable<Array<ReportDiscount>> {
    return this.ReportDiscountsResponse().pipe(
      __map(_r => _r.body as Array<ReportDiscount>)
    );
  }

  /**
   * @return Success
   */
  ReportServicesResponse(): __Observable<__StrictHttpResponse<Array<ReportService>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportService>>;
      })
    );
  }
  /**
   * @return Success
   */
  ReportServices(): __Observable<Array<ReportService>> {
    return this.ReportServicesResponse().pipe(
      __map(_r => _r.body as Array<ReportService>)
    );
  }

  /**
   * @return Success
   */
  GroupsAsyncResponse(): __Observable<__StrictHttpResponse<Array<ReportGroup>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/Reports/groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ReportGroup>>;
      })
    );
  }
  /**
   * @return Success
   */
  GroupsAsync(): __Observable<Array<ReportGroup>> {
    return this.GroupsAsyncResponse().pipe(
      __map(_r => _r.body as Array<ReportGroup>)
    );
  }
}

module ReportsService {

  /**
   * Parameters for Prepared
   */
  export interface PreparedParams {
    Type?: string;
    Token?: string;
    Expires?: number;
    Download?: boolean;
  }
}

export { ReportsService }
