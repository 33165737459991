import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VisitOption } from '../option/option.component';

@Component({
  selector: 'mp-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent {
  @Input() options: VisitOption[] = [];

  @Output()
  onChange = new EventEmitter<OptionChange>();

  change = (value: string, option: VisitOption) => this.onChange.emit({ option: option, value: value });
}

export interface OptionChange {
  option: VisitOption;
  value: string;
}
