/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EmployeesResponse } from '../models/employees-response';
import { EmployeeEditorModel } from '../models/employee-editor-model';
import { Registrator } from '../models/registrator';
import { AttendingsResponse } from '../models/attendings-response';
import { SystemUser } from '../models/system-user';
import { EmployeeSpecialitiesResponse } from '../models/employee-specialities-response';
import { EmployeeSpeciality } from '../models/employee-speciality';
import { AddEmployeeSpecialityModel } from '../models/add-employee-speciality-model';
import { EmployeesAvailableSpecialitiesResponse } from '../models/employees-available-specialities-response';
import { EmployeePartnersReponse } from '../models/employee-partners-reponse';
import { EmployeePartner } from '../models/employee-partner';
import { AddPartnerModel } from '../models/add-partner-model';
import { EmployeeAvailablePartnersResponse } from '../models/employee-available-partners-response';
import { EmployeeAbsencesResponse } from '../models/employee-absences-response';
import { EmployeeAbsence } from '../models/employee-absence';
import { AddAbsenceModel } from '../models/add-absence-model';
import { EmployeeServicesResponse } from '../models/employee-services-response';
import { EmployeeAppointmentsResponse } from '../models/employee-appointments-response';
import { EmployeeRolesResponse } from '../models/employee-roles-response';
import { EmployeeRole } from '../models/employee-role';
import { AddRoleModel } from '../models/add-role-model';
import { EmployeePbxPhoneNumbersResponse } from '../models/employee-pbx-phone-numbers-response';
import { EmployeePbxNumber } from '../models/employee-pbx-number';
import { AddPbxNumberModel } from '../models/add-pbx-number-model';
import { EmployeeWageSchemasResponse } from '../models/employee-wage-schemas-response';
import { AddEmployeeWageSChemaRequest } from '../models/add-employee-wage-schema-request';
import { EmployeeCallReport } from '../models/employee-call-report';
import { SendNewPasswordRequest } from '../models/send-new-password-request';
import { EmployeeGenerateApiKeyResponse } from '../models/employee-generate-api-key-response';
import { EditEmployeeServiceModel } from '../models/edit-employee-service-model';
import { UpdateEmployeeWageSchemaRequest } from '../models/update-employee-wage-schema-request';
@Injectable({
  providedIn: 'root',
})
class EmployeesService extends __BaseService {
  static readonly EmployeesPath = '/api/Employees';
  static readonly CreatePath = '/api/Employees';
  static readonly RegistratorsPath = '/api/Employees/registrators';
  static readonly AttendingsPath = '/api/Employees/attendings';
  static readonly SystemUsersPath = '/api/Employees/system-users';
  static readonly EmployeePath = '/api/Employees/{id}';
  static readonly UpdatePath = '/api/Employees/{id}';
  static readonly DeletePath = '/api/Employees/{id}';
  static readonly SpecialitiesPath = '/api/Employees/{id}/specialities';
  static readonly AddSpecialityPath = '/api/Employees/{id}/specialities';
  static readonly AvailableSpecialitiesPath = '/api/Employees/{id}/available-specialities';
  static readonly PartnersPath = '/api/Employees/{id}/partners';
  static readonly AddPartnerPath = '/api/Employees/{id}/partners';
  static readonly AvailablePartnersPath = '/api/Employees/{id}/available-partners';
  static readonly AbsencesPath = '/api/Employees/{id}/absences';
  static readonly AddAbsencePath = '/api/Employees/{id}/absences';
  static readonly ServicesPath = '/api/Employees/{id}/services';
  static readonly AppointmentsPath = '/api/Employees/{id}/appointments';
  static readonly RolesPath = '/api/Employees/{id}/roles';
  static readonly AddRolePath = '/api/Employees/{id}/roles';
  static readonly PbxPhoneNumbersPath = '/api/Employees/{id}/phone-numbers';
  static readonly AddPhoneNumberPath = '/api/Employees/{id}/phone-numbers';
  static readonly WageSchemasPath = '/api/Employees/{id}/wage-schemas';
  static readonly AddWageSchemaPath = '/api/Employees/{id}/wage-schemas';
  static readonly CallReportsAsyncPath = '/api/Employees/{id}/calls';
  static readonly BlockPath = '/api/Employees/{id}/block';
  static readonly UnblockPath = '/api/Employees/{id}/unblock';
  static readonly SendNewPasswordPath = '/api/Employees/{id}/password';
  static readonly EnableServicePath = '/api/Employees/{id}/services/{serviceId}/enable';
  static readonly DisableServicePath = '/api/Employees/{id}/services/{serviceId}/disable';
  static readonly FirePath = '/api/Employees/{id}/fire';
  static readonly GenerateApiKeyPath = '/api/Employees/{id}/api-key';
  static readonly CheckAccountForEmployeePath = '/api/Employees/{id}/account/available';
  static readonly CheckAccountPath = '/api/Employees/account/available';
  static readonly UpdateServicePath = '/api/Employees/{id}/services/{serviceId}';
  static readonly UpdateWageSchemaPath = '/api/Employees/{id}/wage-schemas/{schemaId}';
  static readonly RemoveWageSchemaPath = '/api/Employees/{id}/wage-schemas/{schemaId}';
  static readonly DeleteSpecialityPath = '/api/Employees/{id}/specialities/{specialityId}';
  static readonly DeletePartnerPath = '/api/Employees/{id}/partners/{partnerId}';
  static readonly DeleteAbsencePath = '/api/Employees/{id}/absences/{absenceId}';
  static readonly DeleteRolePath = '/api/Employees/{id}/roles/{roleId}';
  static readonly DeletePhoneNumberPath = '/api/Employees/{id}/phone-numbers/{phoneId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `EmployeesService.EmployeesParams` containing the following parameters:
   *
   * - `SpecialityIds`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `RoleIds`:
   *
   * - `Page`:
   *
   * - `IncludeFired`:
   *
   * - `EmployeesFlag`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  EmployeesResponse(params: EmployeesService.EmployeesParams): __Observable<__StrictHttpResponse<EmployeesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.SpecialityIds || []).forEach(val => {if (val != null) __params = __params.append('SpecialityIds', val.toString())});
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    (params.RoleIds || []).forEach(val => {if (val != null) __params = __params.append('RoleIds', val.toString())});
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.IncludeFired != null) __params = __params.set('IncludeFired', params.IncludeFired.toString());
    if (params.EmployeesFlag != null) __params = __params.set('EmployeesFlag', params.EmployeesFlag.toString());
    (params.CompanyIds || []).forEach(val => {if (val != null) __params = __params.append('CompanyIds', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeesResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.EmployeesParams` containing the following parameters:
   *
   * - `SpecialityIds`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `RoleIds`:
   *
   * - `Page`:
   *
   * - `IncludeFired`:
   *
   * - `EmployeesFlag`:
   *
   * - `CompanyIds`:
   *
   * @return Success
   */
  Employees(params: EmployeesService.EmployeesParams): __Observable<EmployeesResponse> {
    return this.EmployeesResponse(params).pipe(
      __map(_r => _r.body as EmployeesResponse)
    );
  }

  /**
   * @param model undefined
   * @return Success
   */
  CreateResponse(model?: EmployeeEditorModel): __Observable<__StrictHttpResponse<EmployeeEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeEditorModel>;
      })
    );
  }
  /**
   * @param model undefined
   * @return Success
   */
  Create(model?: EmployeeEditorModel): __Observable<EmployeeEditorModel> {
    return this.CreateResponse(model).pipe(
      __map(_r => _r.body as EmployeeEditorModel)
    );
  }

  /**
   * @return Success
   */
  RegistratorsResponse(): __Observable<__StrictHttpResponse<Array<Registrator>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/registrators`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Registrator>>;
      })
    );
  }
  /**
   * @return Success
   */
  Registrators(): __Observable<Array<Registrator>> {
    return this.RegistratorsResponse().pipe(
      __map(_r => _r.body as Array<Registrator>)
    );
  }

  /**
   * @param params The `EmployeesService.AttendingsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  AttendingsResponse(params: EmployeesService.AttendingsParams): __Observable<__StrictHttpResponse<AttendingsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    (params.Companies || []).forEach(val => {if (val != null) __params = __params.append('Companies', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/attendings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AttendingsResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AttendingsParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Companies`:
   *
   * @return Success
   */
  Attendings(params: EmployeesService.AttendingsParams): __Observable<AttendingsResponse> {
    return this.AttendingsResponse(params).pipe(
      __map(_r => _r.body as AttendingsResponse)
    );
  }

  /**
   * @return Success
   */
  SystemUsersResponse(): __Observable<__StrictHttpResponse<Array<SystemUser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/system-users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SystemUser>>;
      })
    );
  }
  /**
   * @return Success
   */
  SystemUsers(): __Observable<Array<SystemUser>> {
    return this.SystemUsersResponse().pipe(
      __map(_r => _r.body as Array<SystemUser>)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  EmployeeResponse(id: number): __Observable<__StrictHttpResponse<EmployeeEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Employee(id: number): __Observable<EmployeeEditorModel> {
    return this.EmployeeResponse(id).pipe(
      __map(_r => _r.body as EmployeeEditorModel)
    );
  }

  /**
   * @param params The `EmployeesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  UpdateResponse(params: EmployeesService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employees/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   */
  Update(params: EmployeesService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  DeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Delete(id: number): __Observable<null> {
    return this.DeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.SpecialitiesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  SpecialitiesResponse(params: EmployeesService.SpecialitiesParams): __Observable<__StrictHttpResponse<EmployeeSpecialitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSpecialitiesResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.SpecialitiesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Specialities(params: EmployeesService.SpecialitiesParams): __Observable<EmployeeSpecialitiesResponse> {
    return this.SpecialitiesResponse(params).pipe(
      __map(_r => _r.body as EmployeeSpecialitiesResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddSpecialityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddSpecialityResponse(params: EmployeesService.AddSpecialityParams): __Observable<__StrictHttpResponse<EmployeeSpeciality>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeSpeciality>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddSpecialityParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddSpeciality(params: EmployeesService.AddSpecialityParams): __Observable<EmployeeSpeciality> {
    return this.AddSpecialityResponse(params).pipe(
      __map(_r => _r.body as EmployeeSpeciality)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  AvailableSpecialitiesResponse(id: number): __Observable<__StrictHttpResponse<EmployeesAvailableSpecialitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${id}/available-specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeesAvailableSpecialitiesResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  AvailableSpecialities(id: number): __Observable<EmployeesAvailableSpecialitiesResponse> {
    return this.AvailableSpecialitiesResponse(id).pipe(
      __map(_r => _r.body as EmployeesAvailableSpecialitiesResponse)
    );
  }

  /**
   * @param params The `EmployeesService.PartnersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `PlanId`:
   *
   * - `Page`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  PartnersResponse(params: EmployeesService.PartnersParams): __Observable<__StrictHttpResponse<EmployeePartnersReponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.PlanId != null) __params = __params.set('PlanId', params.PlanId.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePartnersReponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.PartnersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `PlanId`:
   *
   * - `Page`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Partners(params: EmployeesService.PartnersParams): __Observable<EmployeePartnersReponse> {
    return this.PartnersResponse(params).pipe(
      __map(_r => _r.body as EmployeePartnersReponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddPartnerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddPartnerResponse(params: EmployeesService.AddPartnerParams): __Observable<__StrictHttpResponse<EmployeePartner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePartner>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddPartnerParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddPartner(params: EmployeesService.AddPartnerParams): __Observable<EmployeePartner> {
    return this.AddPartnerResponse(params).pipe(
      __map(_r => _r.body as EmployeePartner)
    );
  }

  /**
   * @param params The `EmployeesService.AvailablePartnersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  AvailablePartnersResponse(params: EmployeesService.AvailablePartnersParams): __Observable<__StrictHttpResponse<EmployeeAvailablePartnersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/available-partners`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAvailablePartnersResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AvailablePartnersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  AvailablePartners(params: EmployeesService.AvailablePartnersParams): __Observable<EmployeeAvailablePartnersResponse> {
    return this.AvailablePartnersResponse(params).pipe(
      __map(_r => _r.body as EmployeeAvailablePartnersResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AbsencesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `From`:
   *
   * @return Success
   */
  AbsencesResponse(params: EmployeesService.AbsencesParams): __Observable<__StrictHttpResponse<EmployeeAbsencesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/absences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAbsencesResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AbsencesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `From`:
   *
   * @return Success
   */
  Absences(params: EmployeesService.AbsencesParams): __Observable<EmployeeAbsencesResponse> {
    return this.AbsencesResponse(params).pipe(
      __map(_r => _r.body as EmployeeAbsencesResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddAbsenceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddAbsenceResponse(params: EmployeesService.AddAbsenceParams): __Observable<__StrictHttpResponse<EmployeeAbsence>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/absences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAbsence>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddAbsenceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddAbsence(params: EmployeesService.AddAbsenceParams): __Observable<EmployeeAbsence> {
    return this.AddAbsenceResponse(params).pipe(
      __map(_r => _r.body as EmployeeAbsence)
    );
  }

  /**
   * @param params The `EmployeesService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  ServicesResponse(params: EmployeesService.ServicesParams): __Observable<__StrictHttpResponse<EmployeeServicesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.SpecialityId != null) __params = __params.set('SpecialityId', params.SpecialityId.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.CategoryId != null) __params = __params.set('CategoryId', params.CategoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeServicesResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.ServicesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `SpecialityId`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `CategoryId`:
   *
   * @return Success
   */
  Services(params: EmployeesService.ServicesParams): __Observable<EmployeeServicesResponse> {
    return this.ServicesResponse(params).pipe(
      __map(_r => _r.body as EmployeeServicesResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AppointmentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `HidePending`:
   *
   * - `HideConfirmed`:
   *
   * - `HideCanceled`:
   *
   * - `From`:
   *
   * @return Success
   */
  AppointmentsResponse(params: EmployeesService.AppointmentsParams): __Observable<__StrictHttpResponse<EmployeeAppointmentsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.To != null) __params = __params.set('To', params.To.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.HidePending != null) __params = __params.set('HidePending', params.HidePending.toString());
    if (params.HideConfirmed != null) __params = __params.set('HideConfirmed', params.HideConfirmed.toString());
    if (params.HideCanceled != null) __params = __params.set('HideCanceled', params.HideCanceled.toString());
    if (params.From != null) __params = __params.set('From', params.From.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/appointments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeAppointmentsResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AppointmentsParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `To`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * - `HidePending`:
   *
   * - `HideConfirmed`:
   *
   * - `HideCanceled`:
   *
   * - `From`:
   *
   * @return Success
   */
  Appointments(params: EmployeesService.AppointmentsParams): __Observable<EmployeeAppointmentsResponse> {
    return this.AppointmentsResponse(params).pipe(
      __map(_r => _r.body as EmployeeAppointmentsResponse)
    );
  }

  /**
   * @param params The `EmployeesService.RolesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * @return Success
   */
  RolesResponse(params: EmployeesService.RolesParams): __Observable<__StrictHttpResponse<EmployeeRolesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeRolesResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.RolesParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Roles(params: EmployeesService.RolesParams): __Observable<EmployeeRolesResponse> {
    return this.RolesResponse(params).pipe(
      __map(_r => _r.body as EmployeeRolesResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddRoleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddRoleResponse(params: EmployeesService.AddRoleParams): __Observable<__StrictHttpResponse<EmployeeRole>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.model;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/roles`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeRole>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddRoleParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  AddRole(params: EmployeesService.AddRoleParams): __Observable<EmployeeRole> {
    return this.AddRoleResponse(params).pipe(
      __map(_r => _r.body as EmployeeRole)
    );
  }

  /**
   * @param params The `EmployeesService.PbxPhoneNumbersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  PbxPhoneNumbersResponse(params: EmployeesService.PbxPhoneNumbersParams): __Observable<__StrictHttpResponse<EmployeePbxPhoneNumbersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/phone-numbers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePbxPhoneNumbersResponse>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.PbxPhoneNumbersParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  PbxPhoneNumbers(params: EmployeesService.PbxPhoneNumbersParams): __Observable<EmployeePbxPhoneNumbersResponse> {
    return this.PbxPhoneNumbersResponse(params).pipe(
      __map(_r => _r.body as EmployeePbxPhoneNumbersResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddPhoneNumberParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddPhoneNumberResponse(params: EmployeesService.AddPhoneNumberParams): __Observable<__StrictHttpResponse<EmployeePbxNumber>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/phone-numbers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeePbxNumber>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddPhoneNumberParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  AddPhoneNumber(params: EmployeesService.AddPhoneNumberParams): __Observable<EmployeePbxNumber> {
    return this.AddPhoneNumberResponse(params).pipe(
      __map(_r => _r.body as EmployeePbxNumber)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  WageSchemasResponse(id: number): __Observable<__StrictHttpResponse<EmployeeWageSchemasResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${id}/wage-schemas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeWageSchemasResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  WageSchemas(id: number): __Observable<EmployeeWageSchemasResponse> {
    return this.WageSchemasResponse(id).pipe(
      __map(_r => _r.body as EmployeeWageSchemasResponse)
    );
  }

  /**
   * @param params The `EmployeesService.AddWageSchemaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddWageSchemaResponse(params: EmployeesService.AddWageSchemaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/wage-schemas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.AddWageSchemaParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  AddWageSchema(params: EmployeesService.AddWageSchemaParams): __Observable<null> {
    return this.AddWageSchemaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.CallReportsAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CallReportsAsyncResponse(params: EmployeesService.CallReportsAsyncParams): __Observable<__StrictHttpResponse<Array<EmployeeCallReport>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.request != null) __params = __params.set('request', params.request.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Employees/${params.id}/calls`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<EmployeeCallReport>>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.CallReportsAsyncParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   *
   * @return Success
   */
  CallReportsAsync(params: EmployeesService.CallReportsAsyncParams): __Observable<Array<EmployeeCallReport>> {
    return this.CallReportsAsyncResponse(params).pipe(
      __map(_r => _r.body as Array<EmployeeCallReport>)
    );
  }

  /**
   * @param id undefined
   */
  BlockResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${id}/block`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Block(id: number): __Observable<null> {
    return this.BlockResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  UnblockResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${id}/unblock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Unblock(id: number): __Observable<null> {
    return this.UnblockResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.SendNewPasswordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SendNewPasswordResponse(params: EmployeesService.SendNewPasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.SendNewPasswordParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  SendNewPassword(params: EmployeesService.SendNewPasswordParams): __Observable<null> {
    return this.SendNewPasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.EnableServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  EnableServiceResponse(params: EmployeesService.EnableServiceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/services/${params.serviceId}/enable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.EnableServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  EnableService(params: EmployeesService.EnableServiceParams): __Observable<null> {
    return this.EnableServiceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DisableServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  DisableServiceResponse(params: EmployeesService.DisableServiceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/services/${params.serviceId}/disable`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DisableServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   */
  DisableService(params: EmployeesService.DisableServiceParams): __Observable<null> {
    return this.DisableServiceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  FireResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${id}/fire`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  Fire(id: number): __Observable<null> {
    return this.FireResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  GenerateApiKeyResponse(id: number): __Observable<__StrictHttpResponse<EmployeeGenerateApiKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${id}/api-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EmployeeGenerateApiKeyResponse>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  GenerateApiKey(id: number): __Observable<EmployeeGenerateApiKeyResponse> {
    return this.GenerateApiKeyResponse(id).pipe(
      __map(_r => _r.body as EmployeeGenerateApiKeyResponse)
    );
  }

  /**
   * @param params The `EmployeesService.CheckAccountForEmployeeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `login`:
   *
   * @return Success
   */
  CheckAccountForEmployeeResponse(params: EmployeesService.CheckAccountForEmployeeParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.login;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/${params.id}/account/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param params The `EmployeesService.CheckAccountForEmployeeParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `login`:
   *
   * @return Success
   */
  CheckAccountForEmployee(params: EmployeesService.CheckAccountForEmployeeParams): __Observable<boolean> {
    return this.CheckAccountForEmployeeResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param login undefined
   * @return Success
   */
  CheckAccountResponse(login?: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = login;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Employees/account/available`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param login undefined
   * @return Success
   */
  CheckAccount(login?: string): __Observable<boolean> {
    return this.CheckAccountResponse(login).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param params The `EmployeesService.UpdateServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  UpdateServiceResponse(params: EmployeesService.UpdateServiceParams): __Observable<__StrictHttpResponse<EditEmployeeServiceModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.model;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employees/${params.id}/services/${params.serviceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EditEmployeeServiceModel>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.UpdateServiceParams` containing the following parameters:
   *
   * - `serviceId`:
   *
   * - `id`:
   *
   * - `model`:
   *
   * @return Success
   */
  UpdateService(params: EmployeesService.UpdateServiceParams): __Observable<EditEmployeeServiceModel> {
    return this.UpdateServiceResponse(params).pipe(
      __map(_r => _r.body as EditEmployeeServiceModel)
    );
  }

  /**
   * @param params The `EmployeesService.UpdateWageSchemaParams` containing the following parameters:
   *
   * - `schemaId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateWageSchemaResponse(params: EmployeesService.UpdateWageSchemaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Employees/${params.id}/wage-schemas/${params.schemaId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.UpdateWageSchemaParams` containing the following parameters:
   *
   * - `schemaId`:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateWageSchema(params: EmployeesService.UpdateWageSchemaParams): __Observable<null> {
    return this.UpdateWageSchemaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.RemoveWageSchemaParams` containing the following parameters:
   *
   * - `schemaId`:
   *
   * - `id`:
   */
  RemoveWageSchemaResponse(params: EmployeesService.RemoveWageSchemaParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/wage-schemas/${params.schemaId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.RemoveWageSchemaParams` containing the following parameters:
   *
   * - `schemaId`:
   *
   * - `id`:
   */
  RemoveWageSchema(params: EmployeesService.RemoveWageSchemaParams): __Observable<null> {
    return this.RemoveWageSchemaResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DeleteSpecialityParams` containing the following parameters:
   *
   * - `specialityId`:
   *
   * - `id`:
   */
  DeleteSpecialityResponse(params: EmployeesService.DeleteSpecialityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/specialities/${params.specialityId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DeleteSpecialityParams` containing the following parameters:
   *
   * - `specialityId`:
   *
   * - `id`:
   */
  DeleteSpeciality(params: EmployeesService.DeleteSpecialityParams): __Observable<null> {
    return this.DeleteSpecialityResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DeletePartnerParams` containing the following parameters:
   *
   * - `partnerId`:
   *
   * - `id`:
   */
  DeletePartnerResponse(params: EmployeesService.DeletePartnerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/partners/${params.partnerId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DeletePartnerParams` containing the following parameters:
   *
   * - `partnerId`:
   *
   * - `id`:
   */
  DeletePartner(params: EmployeesService.DeletePartnerParams): __Observable<null> {
    return this.DeletePartnerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DeleteAbsenceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `absenceId`:
   */
  DeleteAbsenceResponse(params: EmployeesService.DeleteAbsenceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/absences/${params.absenceId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DeleteAbsenceParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `absenceId`:
   */
  DeleteAbsence(params: EmployeesService.DeleteAbsenceParams): __Observable<null> {
    return this.DeleteAbsenceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DeleteRoleParams` containing the following parameters:
   *
   * - `roleId`:
   *
   * - `id`:
   */
  DeleteRoleResponse(params: EmployeesService.DeleteRoleParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/roles/${params.roleId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DeleteRoleParams` containing the following parameters:
   *
   * - `roleId`:
   *
   * - `id`:
   */
  DeleteRole(params: EmployeesService.DeleteRoleParams): __Observable<null> {
    return this.DeleteRoleResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `EmployeesService.DeletePhoneNumberParams` containing the following parameters:
   *
   * - `phoneId`:
   *
   * - `id`:
   */
  DeletePhoneNumberResponse(params: EmployeesService.DeletePhoneNumberParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Employees/${params.id}/phone-numbers/${params.phoneId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `EmployeesService.DeletePhoneNumberParams` containing the following parameters:
   *
   * - `phoneId`:
   *
   * - `id`:
   */
  DeletePhoneNumber(params: EmployeesService.DeletePhoneNumberParams): __Observable<null> {
    return this.DeletePhoneNumberResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module EmployeesService {

  /**
   * Parameters for Employees
   */
  export interface EmployeesParams {
    SpecialityIds?: Array<number>;
    Size?: number;
    Search?: string;
    RoleIds?: Array<number>;
    Page?: number;
    IncludeFired?: boolean;
    EmployeesFlag?: any;
    CompanyIds?: Array<number>;
  }

  /**
   * Parameters for Attendings
   */
  export interface AttendingsParams {
    Size?: number;
    Page?: number;
    Companies?: Array<number>;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    model?: EmployeeEditorModel;
  }

  /**
   * Parameters for Specialities
   */
  export interface SpecialitiesParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for AddSpeciality
   */
  export interface AddSpecialityParams {
    id: number;
    model?: AddEmployeeSpecialityModel;
  }

  /**
   * Parameters for Partners
   */
  export interface PartnersParams {
    id: number;
    Size?: number;
    Search?: string;
    PlanId?: number;
    Page?: number;
    CompanyId?: number;
  }

  /**
   * Parameters for AddPartner
   */
  export interface AddPartnerParams {
    id: number;
    model?: AddPartnerModel;
  }

  /**
   * Parameters for AvailablePartners
   */
  export interface AvailablePartnersParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Absences
   */
  export interface AbsencesParams {
    id: number;
    To?: string;
    Size?: number;
    Page?: number;
    From?: string;
  }

  /**
   * Parameters for AddAbsence
   */
  export interface AddAbsenceParams {
    id: number;
    model?: AddAbsenceModel;
  }

  /**
   * Parameters for Services
   */
  export interface ServicesParams {
    id: number;
    SpecialityId?: number;
    Size?: number;
    Search?: string;
    Page?: number;
    CategoryId?: number;
  }

  /**
   * Parameters for Appointments
   */
  export interface AppointmentsParams {
    id: number;
    To?: string;
    Size?: number;
    Search?: string;
    Page?: number;
    HidePending?: boolean;
    HideConfirmed?: boolean;
    HideCanceled?: boolean;
    From?: string;
  }

  /**
   * Parameters for Roles
   */
  export interface RolesParams {
    id: number;
    Size?: number;
    Page?: number;
  }

  /**
   * Parameters for AddRole
   */
  export interface AddRoleParams {
    id: number;
    model?: AddRoleModel;
  }

  /**
   * Parameters for PbxPhoneNumbers
   */
  export interface PbxPhoneNumbersParams {
    id: number;
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for AddPhoneNumber
   */
  export interface AddPhoneNumberParams {
    id: number;
    request?: AddPbxNumberModel;
  }

  /**
   * Parameters for AddWageSchema
   */
  export interface AddWageSchemaParams {
    id: number;
    request?: AddEmployeeWageSChemaRequest;
  }

  /**
   * Parameters for CallReportsAsync
   */
  export interface CallReportsAsyncParams {
    id: number;
    request?: any;
  }

  /**
   * Parameters for SendNewPassword
   */
  export interface SendNewPasswordParams {
    id: number;
    request?: SendNewPasswordRequest;
  }

  /**
   * Parameters for EnableService
   */
  export interface EnableServiceParams {
    serviceId: number;
    id: number;
  }

  /**
   * Parameters for DisableService
   */
  export interface DisableServiceParams {
    serviceId: number;
    id: number;
  }

  /**
   * Parameters for CheckAccountForEmployee
   */
  export interface CheckAccountForEmployeeParams {
    id: number;
    login?: string;
  }

  /**
   * Parameters for UpdateService
   */
  export interface UpdateServiceParams {
    serviceId: number;
    id: number;
    model?: EditEmployeeServiceModel;
  }

  /**
   * Parameters for UpdateWageSchema
   */
  export interface UpdateWageSchemaParams {
    schemaId: number;
    id: number;
    request?: UpdateEmployeeWageSchemaRequest;
  }

  /**
   * Parameters for RemoveWageSchema
   */
  export interface RemoveWageSchemaParams {
    schemaId: number;
    id: number;
  }

  /**
   * Parameters for DeleteSpeciality
   */
  export interface DeleteSpecialityParams {
    specialityId: number;
    id: number;
  }

  /**
   * Parameters for DeletePartner
   */
  export interface DeletePartnerParams {
    partnerId: number;
    id: number;
  }

  /**
   * Parameters for DeleteAbsence
   */
  export interface DeleteAbsenceParams {
    id: number;
    absenceId: number;
  }

  /**
   * Parameters for DeleteRole
   */
  export interface DeleteRoleParams {
    roleId: number;
    id: number;
  }

  /**
   * Parameters for DeletePhoneNumber
   */
  export interface DeletePhoneNumberParams {
    phoneId: number;
    id: number;
  }
}

export { EmployeesService }
