import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompanyForbiddenDiagnosis, Icd10ResponseItem } from '../../../../generated/models';
import { DiagnosesService, OrganizationsService } from '../../../../generated/services';

@Component({
  selector: 'mp-forbidden-diagnoses',
  templateUrl: './forbidden-diagnoses.component.html',
  styleUrls: ['./forbidden-diagnoses.component.scss']
})
export class ForbiddenDiagnosesComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  editing = false;
  @Input() companyId: number;

  loading = false;
  result: Icd10ResponseItem[] = [];

  selected: CompanyForbiddenDiagnosis[] = [];
  saved: CompanyForbiddenDiagnosis[] = [];

  searchControl: FormControl;

  constructor(
    private diagnosesService: DiagnosesService,
    private organizationsService: OrganizationsService,
    private toastrService: ToastrService
  ) {
    this.searchControl = new FormControl(undefined);

    this.searchControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: string): void => {
        this.loading = true;

        if (!value || value.length < 3) {
          this.result = [];
          this.loading = false;
          return;
        }

        this.diagnosesService.SearchAsync(value)
          .subscribe((response: Icd10ResponseItem[]): void => {
            this.result = response;
            this.loading = false;
          },

            (): void => {
              this.loading = false;
            });
      });
  }

  ngOnInit() {
    this.organizationsService.ForbiddenDiagnosesAsync(this.companyId)
      .subscribe(
        (diagnoses: CompanyForbiddenDiagnosis[]): void => {
          this.selected = diagnoses;
          this.saved = [...diagnoses];
        }
      );

  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  edit(): void {
    this.editing = true;
  }

  cancel(): void {
    this.searchControl.reset();
    this.editing = false;
    this.selected = [...this.saved];
  }

  commit(): void {


    this.organizationsService.UpdateForbiddenDiagnosesAsync({ companyId: this.companyId, request: { codes: this.selected.map(x => x.diagnosisCode) } })
      .subscribe(
        (): void => {
          this.toastrService.success('Запрещенные диагнозы обновлены', 'Успешно');
          this.searchControl.reset();
          this.editing = false;
          this.saved = [...this.selected];
        },
        (): void => {
          this.toastrService.error('Не удалось обновить диагнозы', 'Ошибка');
        }
      );
  }

  add(diagnosis: Icd10ResponseItem): void {

    const index: number = this.selected.findIndex(x => x.diagnosisCode === diagnosis.code);

    if (index === -1) {
      this.selected = [{ companyId: this.companyId, diagnosisCode: diagnosis.code, diagnosisName: diagnosis.name, id: 0 }, ...this.selected];
    }
  }

  remove(diagnosis: CompanyForbiddenDiagnosis): void {
    this.selected = this.selected.filter(x => x.diagnosisCode !== diagnosis.diagnosisCode);
  }

}
