import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DiagnosesService } from '../../../../generated/services';
import { DiagnosisNode, Icd10ResponseItem } from '../../../../generated/models';
import { Icd10 } from '../../../workspace/models/ic10';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DiagnosesResolverPayload } from '../../resolvers/diagnoses-resolver';
import { Observable, Subject, from } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'mp-diagnoses',
  templateUrl: './diagnoses.component.html',
  styleUrls: ['./diagnoses.component.scss'],
  host: { class: "page" }
})
export class DiagnosesComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  loading = true;
  page = 1;
  size = 100;

  items: DiagnosisNode[] = [];
  groups: DiagnosisNode[] = [];

  source: { [key: number]: DiagnosisNode[] } = {};

  filters = new FormGroup({
    search: new FormControl(),
    parentId: new FormControl(null)
  });

  constructor(private toastrService: ToastrService,
    private diagnosesService: DiagnosesService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.filters.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.page = 1;
          this.loading = true;
          this.items = [];
        }),
        tap((value: FilterValue) => {

          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          if (value.parentId) {
            queryParams.parentId = value.parentId;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
        switchMap((value: FilterValue): Observable<DiagnosisNode[]> => this.diagnosesService
          .DiagnosisNodesAsync({ Page: this.page, Size: this.size, Search: value.search, ParentId: value.parentId })
          .pipe(catchError((error) => from([]))))
      ).subscribe(
        (response: DiagnosisNode[]) => {
          this.items = response;
          this.loading = false;
        }
      );


  }

  ngOnInit() {

    const params = this.activatedRoute.snapshot.queryParamMap;

    const value: FilterValue = { search: '', parentId: null };

    if (params.has('search')) {
      value.search = params.get('search');
    }

    if (params.has('parentId')) {
      value.parentId = parseInt(params.get('parentId'));
    }

    this.filters.patchValue(value);

    this.activatedRoute.data.subscribe(
      (data: { payload: DiagnosesResolverPayload }) => {
        this.groups = data.payload.groups;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScrollDown() {
    this.page++;
    this.loading = true;

    const value: FilterValue = this.filters.getRawValue();

    this.diagnosesService
      .DiagnosisNodesAsync({ Page: this.page, Size: this.size, Search: value.search, ParentId: value.parentId })
      .pipe(catchError((error) => from([])))
      .subscribe(
        (response: DiagnosisNode[]) => {
          this.items.push(...response);
          this.loading = false;
        }
      );

  }

  expanded = (item: DiagnosisNode) => !!this.source[item.id];
  children = (item: DiagnosisNode) => this.source[item.id] || [];

  add = () => this.toastrService.warning('Добавление диагнозов отключено', 'Ошибка');
  remove = (item: DiagnosisNode) => this.toastrService.warning('Удаление диагнозов отключено', 'Ошибка');
  edit = (item: DiagnosisNode) => this.router.navigate([`${item.id}`], { relativeTo: this.activatedRoute });
}

interface FilterValue {
  search: string;
  parentId: number;
}
