import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-manage-service-standard-modal',
  templateUrl: './manage-service-standard.modal.component.html',
  styleUrls: ['./manage-service-standard.modal.component.scss']
})
export class ManageServiceStandardModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
