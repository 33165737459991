import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ScrollHistoryService {
  private _name = "MP_POSITIONS";

  private loadPositions() {
    const value = localStorage.getItem(this._name);

    if (!value) {
      return {};
    }

    return JSON.parse(value) || {};
  }

  private savePositions(positions: { [key: string]: IScrollHistoryItem }) {
    localStorage.setItem(this._name, JSON.stringify(positions));
  }

  savePosition(key: string, page: number, id: number): void {
    const data = this.loadPositions();

    data[key] = { page, id };

    this.savePositions(data);
  }

  retrievePosition(key: string): IScrollHistoryItem {
    const data = this.loadPositions();

    return data[key];
  }

  resetPosition(key: string) {
    const data = this.loadPositions();

    data[key] = undefined;

    this.savePositions(data);
  }
}

export interface IScrollHistoryItem {
  page: number;
  id: number;
}
