import { Injectable } from '@angular/core';
import { IdbBaseService } from './idb-base.service';
import { Standard } from '../generated/models';
import { Observable, Subject, of } from 'rxjs';
import { StandardsWebworkerService } from './standards-webworker.service';
import { switchMap, take } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class StandardsStorageService {

  constructor(private idbBaseService: IdbBaseService, private standardsWWService: StandardsWebworkerService) {
  }

  public getAllStandards(query: { search: string, includeExpired: boolean }): Observable<Standard[]> {
    const subject = new Subject<Standard[]>();

    this.idbBaseService.dbLoaded.pipe(
      switchMap((value) => { return this.standardsWWService.dataLoaded }),
      switchMap((value) => { return this.needsUpdate() }),
      take(1),
      switchMap((result: boolean) => { return result ? this.standardsWWService.loadData() : of({}) }),
      switchMap(() => this.loadFromDb(query))
    ).subscribe(
      (response: Standard[]) => {
        subject.next(response)
      }
    )

    return subject.asObservable();
  }

  private needsUpdate = (): Observable<boolean> => {
    return this.idbBaseService.objectStoreEmpty("standardServices");
  }

  private loadFromDb = (query: { search: string, includeExpired: boolean }): Observable<Standard[]> => {
    let result = new Subject<Standard[]>();

    let data: Standard[] = [];

    let objectStore: IDBObjectStore = this.idbBaseService.getObjectStore("standardServices");
    var request = objectStore.openCursor();

    request.onsuccess = (evt: any) => {
      let cursor = evt.target.result;
      if (cursor != null) {

        if (cursor.value.code.toLowerCase().includes(query.search.toLowerCase())
          || cursor.value.name.toLowerCase().includes(query.search.toLowerCase())
          && (query.includeExpired || (cursor.value.expires == "" || moment(cursor.value.expires, "DD.MM.YYYY").isAfter(moment())))) {
          data.push(cursor.value);
        }
        
        cursor.continue();

      } else {
        result.next(data);
      }
    };

    return result.asObservable();
  }

}
