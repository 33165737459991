import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { PbxOperatorsService } from '../../../../generated/services/pbx-operators.service';
import { PhoneNumberResolverPayload } from '../../resolver/phone-number.resolver';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { PbxOperatorViewModel } from '../../../../generated/models/pbx-operator-view-model';
import { Pbx } from '../../../../generated/models/pbx';

@Component({
  selector: 'mp-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  host: { class: "page" }
})
export class PhoneNumberComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  id: number;
  title = '';
  pbxList: Pbx[] = [];

  form = new FormGroup({
    directPhone: new FormControl("", []),
    ext: new FormControl("", []),
    pbxUser: new FormControl("", []),
    pbx: new FormControl("", [])
  });

  constructor(
    private pbxService: PbxOperatorsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: { payload: PhoneNumberResolverPayload }): void => {
          this.id = data.payload.pbxOperator.id;

          this.title = this.id > 0 ? `Телефонный номер ${data.payload.pbxOperator.directPhone}` : "Новый телефонный номер";

          this.pbxList = data.payload.pbxList;

          this.form.patchValue({ ...data.payload.pbxOperator });
        }
      )
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async createAsync(): Promise<void> {
    const value: FormValue = this.form.getRawValue();

    try {
      const response = await this.pbxService.CreatePbxOperator({ ...value }).toPromise();
      this.toastrService.success('Телефонный номер добавлен', 'Успешно');
      this.router.navigate(["../", response.id], { relativeTo: this.activatedRoute });
    } catch (e) {
      const response = e as HttpErrorResponse;

      switch (response.status) {
        case 400:
          {
            this.toastrService.warning(response.error.message, "Номер не сохранен");
            return;
          }
        case 403:
          {
            this.toastrService.error("Для выполнения данной опреации отсутствуют необходимые разрешения", "Запрещено");
          }
          return
      }
      this.toastrService.error("Не удалось сохранить телефонный номер", "Ошибка")
    }
  }

  async updateAsync(): Promise<void> {
    const value: FormValue = this.form.getRawValue();

    try {
      await this.pbxService.UpdatePbxOperator({ id: this.id, request: value }).toPromise();
      this.toastrService.success('Телефонный номер сохранен', 'Успешно');
    } catch (e) {
      const response = e as HttpErrorResponse;

      switch (response.status) {
        case 400:
          {
            this.toastrService.warning(response.error.message, "Номер не сохранен");
            return;
          }
        case 403:
          {
            this.toastrService.error("Для выполнения данной опреации отсутствуют необходимые разрешения", "Запрещено");
          }
          return
      }
      this.toastrService.error("Не удалось сохранить телефонный номер", "Ошибка")
    }
  }

  async acceptChanges(): Promise<void> {

    if (this.id > 0) {
      await this.updateAsync();
    } else {
      await this.createAsync();
    }
  }
}

interface FormValue {
  directPhone: string;
  ext: string;
  pbxUser: string;
  pbx: 1;
}
