import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgbDatepickerModule, NgbModalModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LayoutModule } from '@skytecs/layout';
import { ControlsModule } from '@skytecs/controls';
import { QuestionnaireResolver } from './resolvers/questionnaire.resolver';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { QuestionnairesComponent } from './components/questionnaires/questionnaires.component';
import { ManageQuestionnaireModalComponent } from './components/manage-questionnaire.modal/manage-questionnaire.modal.component';
import { QuestionnaireQuestionModalComponent } from './components/questionnaire-question.modal/questionnaire-question.modal.component';
import { QuestionnaireThresholdModalComponent } from './components/questionnaire-threshold.modal/questionnaire-threshold.modal.component';
import { QuestionnaireOptionModalComponent } from './components/questionnaire-option.modal/questionnaire-option.modal.component';
import { ToastrModule } from 'ngx-toastr';

const routes: Routes = [
  {
    path: "new",
    resolve: {
      payload: QuestionnaireResolver
    },
    component: QuestionnaireComponent,
    data: {
      title: "Новый опросник",
      requirements: ["EditQuestionnaires"]
    }
  },
  {
    path: ":id",
    resolve: {
      payload: QuestionnaireResolver
    },
    component: QuestionnaireComponent,
    data: {
      title: "Опросник",
      requirements: ["EditQuestionnaires"]
    }
  },
  {
    path: "",
    component: QuestionnairesComponent,
    data: {
      title: "Опросники",
      requirements: ["EditQuestionnaires"]
    }
  }
];

@NgModule({
  declarations: [
    QuestionnaireComponent,
    QuestionnairesComponent,
    ManageQuestionnaireModalComponent,
    QuestionnaireQuestionModalComponent,
    QuestionnaireThresholdModalComponent,
    QuestionnaireOptionModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,

    InfiniteScrollModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbDropdownModule,
    ToastrModule,

    LayoutModule,
    ControlsModule
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    ManageQuestionnaireModalComponent,
    QuestionnaireQuestionModalComponent,
    QuestionnaireOptionModalComponent,
    QuestionnaireThresholdModalComponent
  ]
})
export class QuestionnaireSettingsModule { }
