import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TemplateSection } from '../../../../generated/models';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'mp-metric-section',
  templateUrl: './metric-section.component.html',
  styleUrls: ['./metric-section.component.scss']
})
export class MetricSectionComponent implements OnInit {

  @Input() section: TemplateSection = {};
  @Output() onManage = new EventEmitter<void>();
  constructor() { }

  ngOnInit() { }

  manage = () => this.onManage.emit();

  drop = (event: CdkDragDrop<any>) => {
    moveItemInArray(this.section.metrics, event.previousIndex, event.currentIndex);
  };

}
