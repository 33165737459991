import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Metric } from '../../../../generated/models';
import { Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-metrics-modal-item',
  templateUrl: './metrics-modal-item.component.html',
  styleUrls: ['./metrics-modal-item.component.scss'],
  host: { class: 'd-flex mb-2 p-1' }
})
export class MetricsModalItemComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() metric: Metric = {};
  @Input() selected = false;
  @Input() disabled = false;

  @Output() onChange = new EventEmitter<boolean>();

  checkbox = new FormControl(false);

  constructor() {
    this.checkbox.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: boolean) => this.onChange.emit(value));
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selected']) {
      const selected: boolean = changes['selected'].currentValue;
      this.checkbox.setValue(selected, { emitEvent: false });
    }

    if (changes['disabled']) {
      const disabled: boolean = changes['disabled'].currentValue;

      if (disabled) {
        this.checkbox.disable({ emitEvent: false });
      } else {
        this.checkbox.enable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }


}
