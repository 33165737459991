import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageQuestionnaireModel } from '../../../../generated/models/manage-questionnaire-model';
import { QuestionnairesService } from '../../../../generated/services/questionnaires.service';

@Component({
  selector: 'mp-manage-questionnaire-modal',
    templateUrl: './manage-questionnaire.modal.component.html',
    styleUrls: ['./manage-questionnaire.modal.component.scss']
})
export class ManageQuestionnaireModalComponent implements OnInit {
  public title: string;

  @Input("model") model: ManageQuestionnaireModel;

  constructor(
    private activeModal: NgbActiveModal,
    private questionnairesService: QuestionnairesService
  ) {
    this.title = "Опросник";
  }

  ngOnInit() {

    this.title = `Удаление опросника "${this.model.title}"`;
  }

  public remove(): void {
      this.questionnairesService.Remove(this.model.id)
      .subscribe(
        (): void => {
          this.activeModal.close();
        },
        (): void => { }
      );
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

}
