import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PromotionResponse } from '../../../../generated/models';

@Component({
  selector: 'mp-promotions-list-item',
  templateUrl: './promotions-list-item.component.html',
  styleUrls: ['./promotions-list-item.component.scss']
})
export class PromotionsListItemComponent implements OnInit {

  @Input() promotion: PromotionResponse;

  @Output()
  onEdit: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  public edit(): void {
    this.onEdit.emit();
  }

  public delete(): void {
    this.onDelete.emit();
  }

}
