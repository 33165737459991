import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceItemPayload } from '../../resolvers/service-item.resolver';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceCategory, Speciality, ServicePricesResponse, ServiceEditorModel, Company, ServiceStandard, ServiceStandardsResponse, ServiceIntegration, ServicePrice, ServiceIntegrationsResponse, PriceSet, AvailablePriceSetsResponse, PriceEditorModel, PriceSetsResponse, ServiceAgreement, CodeSystemValue, CodeSystem, ServiceCompany, ManipulationService, Manipulation, ServiceGroup } from 'projects/Clinic/src/app/generated/models';
import { ServicesService, PricesService, PriceSetsService, ServiceCompaniesService, ManipulationServicesService, ServiceGroupsService } from 'projects/Clinic/src/app/generated/services';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ServicePriceModalComponent } from '../service-price.modal/service-price.modal.component';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ServiceStandardModalComponent } from '../service-standard.modal/service-standard.modal.component';
import { ManageServiceStandardModalComponent } from '../manage-service-standard.modal/manage-service-standard.modal.component';
import { ToastrService } from 'ngx-toastr';
import { ValueSetsService } from '../../../../generated/services/value-sets.service';
import { ServiceCodeSystemModalComponent } from '../service-code-system-modal/service-code-system-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { UserStorage } from "../../../../services/user-storage";
import { PermissionNames } from "../../../../models/permission-names";
import { ConfirmationModalService } from "../../../shared/services/confirmation-modal-service";
import { ServiceManipulationModalComponent } from "../service-manipulation-modal/service-manipulation-modal.component";
import { GroupsModalComponent } from "../groups-modal/groups-modal.component";


@Component({
  selector: 'mp-service-item',
  templateUrl: './service-item.component.html',
  styleUrls: ['./service-item.component.scss'],
  host: { class: "page" }
})
export class ServiceItemComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  id: number;
  name: string;

  propertiesChangedText = 'Параметры услуги изменены';
  availabilityChangedText = 'Доступность услуги в филиалах изменена';

  showIntegrations = false;
  showPrices = false;
  showManipulations = false;
  showCompanies = true;
  showGroups = true;

  disablePrices = true;
  disableStandards = true;
  disableCodeSystems = true;
  disableCompanies = true;
  disableManipulations = true;
  disableGroups = true;

  pricesPage = 1;
  standardsPage = 1;
  integrationsPage = 1;

  size = 50;

  categories: ServiceCategory[] = [];
  specialities: Speciality[] = [];
  companies: Company[] = [];
  agreements: ServiceAgreement[] = [];
  manipulations: Manipulation[] = [];

  codeSystems: CodeSystem[] = [];

  prices: ServicePrice[] = [];
  standards: ServiceStandard[] = [];
  integrations: ServiceIntegration[] = [];
  serviceCompanies: ServiceCompany[] = [];
  serviceManipulations: ManipulationService[] = [];

  codeSystemValues: CodeSystemValue[] = [];
  groups: ServiceGroup[] = [];

  availableGroups: ServiceGroup[] = [];

  serviceManipulations$ = new BehaviorSubject<ManipulationService[]>([]);

  serviceForm = new FormGroup({
    name: new FormControl("", [Validators.required]),
    seoName: new FormControl("", []),
    nameInCheck: new FormControl(""),
    nameInDocument: new FormControl(""),

    code: new FormControl("", []),
    category: new FormControl("", [Validators.required]),

    archived: new FormControl(""),
    favorite: new FormControl(""),
    speciality: new FormControl(""),

    lab: new FormControl(""),
    labCode: new FormControl(""),
    timing: new FormControl(""),

    taxationType: new FormControl(undefined),
    taxationSystem: new FormControl(undefined),
    agreement: new FormControl(""),
    printAgreement: new FormControl(""),
    onlineBooking: new FormControl(""),

    type: new FormControl(undefined, [Validators.required]),
    canStack: new FormControl(false)
  });

  pricesFilters = new FormGroup({
    search: new FormControl(""),
    includeExpired: new FormControl("")
  });

  standardsFilters = new FormGroup({
    search: new FormControl(""),
    includeExpired: new FormControl("")
  });

  integrationsFilters = new FormGroup({
    search: new FormControl(""),
    includeExpired: new FormControl("")
  });

  availabilityForm = new FormGroup({
    everywhere: new FormControl(true)
  });

  manipulationsFilters = new FormGroup({
    search: new FormControl('')
  })

  get title(): string { return `Услуга: ${this.name}`; }
  get myCompanies(): Company[] { return this.companies.filter(x => x.type == 4); }

  constructor(
    private activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private pricesService: PricesService,
    private priceSetsService: PriceSetsService,
    private valueSetsService: ValueSetsService,
    private serviceCompaniesService: ServiceCompaniesService,
    private manipulationServicesService: ManipulationServicesService,
    private serviceGroupsService: ServiceGroupsService,
    private router: Router,
    private modal: NgbModal,
    private toastrService: ToastrService,
    private userStorage: UserStorage,
    private confirmationService: ConfirmationModalService
  ) {
    this.showPrices = userStorage.hasPermission(PermissionNames.ManagePrices);
    this.showManipulations = userStorage.hasPermission(PermissionNames.ManageManipulations);

    this.serviceForm.patchValue({
      taxationType: userStorage.profile.defaultVatTaxRate
    });

    this.pricesFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        this.prices = [];
        this.pricesPage = 1;

        this.loadPrices();
      });

    this.standardsFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        this.standards = [];
        this.standardsPage = 1;

        this.loadStandards();
      });

    this.integrationsFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((): void => {
        this.integrations = [];
        this.integrationsPage = 1;

        this.loadIntegrations();
      });

    this.manipulationsFilters.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: { search: string }) => {

        if (value.search) {
          const regexp = new RegExp(value.search);
          this.serviceManipulations$.next(this.serviceManipulations.filter(x => regexp.test(x.manipulationName)));
        } else {
          this.serviceManipulations$.next([...this.serviceManipulations]);
        }
      });
  }

  async ngOnInit() {

    this.activatedRoute.data.subscribe(async (data: { payload: ServiceItemPayload }) => {
      this.name = data.payload.service.name || "Новая";
      this.id = data.payload.service.id;
      this.showIntegrations = this.id > 0 && !!data.payload.service.labCode;

      this.disablePrices = !this.id;
      this.disableStandards = !this.id;
      this.disableCodeSystems = !this.id;
      this.disableCompanies = !this.id;
      this.disableManipulations = !this.id;
      this.disableGroups = !this.id;

      this.categories = data.payload.categories;
      this.specialities = data.payload.specialities;
      this.companies = data.payload.companies;
      this.agreements = data.payload.agreements;
      this.codeSystems = data.payload.codeSystems;
      this.availableGroups = data.payload.groups;

      this.serviceForm.patchValue({
        name: data.payload.service.name,
        seoName: data.payload.service.seoName,
        nameInCheck: data.payload.service.nameInCheck,
        nameInDocument: data.payload.service.nameInDocument,
        code: data.payload.service.code,
        category: data.payload.service.categoryId,
        speciality: data.payload.service.specialityId,
        archived: data.payload.service.archived,
        favorite: data.payload.service.favorite,
        lab: data.payload.service.labId,
        labCode: data.payload.service.labCode,
        timing: data.payload.service.timing,
        taxationType: this.id > 0 ? data.payload.service.taxationType : this.userStorage.profile.defaultVatTaxRate,
        taxationSystem: data.payload.service.taxationSystem,
        printAgreement: data.payload.service.agreementRequired,
        agreement: data.payload.service.agreementId,
        onlineBooking: data.payload.service.onlineBooking,
        type: data.payload.service.type,
        canStack: data.payload.service.canStack
      });

      for (const company of data.payload.companies.filter(x => x.type === 4)) {
        this.availabilityForm.addControl(`company_${company.id}`, new FormControl(false));
      }

      if (this.id) {
        this.loadPrices();
        this.loadStandards();
        if (this.showIntegrations) {
          this.loadIntegrations();
        }
        this.loadCodeSystemValues();
        this.loadServiceManipulations();

        this.loadGroups();

        await this.loadServiceCompanies();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  acceptChanges(event: any): void {
    const value: FormValue = this.serviceForm.getRawValue();

    if (this.id > 0) {
      this.servicesService.UpdateService({
        id: this.id,
        model: {
          name: value.name,
          nameInCheck: value.nameInCheck,
          nameInDocument: value.nameInDocument,
          seoName: value.seoName,

          code: value.code,

          categoryId: value.category,
          specialityId: value.speciality,

          archived: value.archived,
          favorite: value.favorite,
          timing: value.timing,
          labCode: value.labCode,
          labId: value.lab,

          agreementRequired: value.printAgreement,
          agreementId: value.agreement,
          onlineBooking: value.onlineBooking,
          taxationSystem: value.taxationSystem,
          taxationType: value.taxationType,
          type: value.type,

          canStack: value.canStack
        }
      }).subscribe((updated: ServiceEditorModel): void => {
        this.name = updated.name;
        this.showIntegrations = !!updated.labCode;

        this.integrationsPage = 1;
        this.integrations = [];

        if (updated.labCode) {
          this.showIntegrations = true;
          this.integrations = [];
          this.integrationsPage = 1;

          this.loadIntegrations();
        } else {
          this.showIntegrations = false;
          this.integrations = [];
          this.integrationsPage = 1;
        }

        this.toastrService.success("Услуга сохранена", "Успешно");
      });
    } else {

      this.servicesService.CreateService({
        name: value.name,
        nameInCheck: value.nameInCheck,
        nameInDocument: value.nameInDocument,
        seoName: value.seoName,

        code: value.code,

        categoryId: value.category,
        specialityId: value.speciality,

        archived: value.archived,
        favorite: value.favorite,
        timing: value.timing,
        labCode: value.labCode,
        labId: value.lab,

        agreementRequired: value.printAgreement,
        agreementId: value.agreement,
        onlineBooking: value.onlineBooking,
        taxationSystem: value.taxationSystem,
        taxationType: value.taxationType,
        type: value.type
      })
        .subscribe(
          (created: ServiceEditorModel): void => {
            this.toastrService.success("Услуга добавлена", "Успешно");
            this.router.navigate(["../", created.id], { relativeTo: this.activatedRoute });
          }
        );
    }
  }

  loadPrices(): void {
    this.servicesService.Prices({
      id: this.id,
      IncludeExpired: this.pricesFilters.get("includeExpired").value,
      Search: this.pricesFilters.get("search").value,
      Page: this.pricesPage,
      Size: 0
    })
      .subscribe(
        (response: ServicePricesResponse): void => {
          this.prices.push(...response.prices);
        });
  }

  loadStandards(): void {
    this.servicesService.Standards({
      id: this.id,
      IncludeExpired: this.standardsFilters.get("includeExpired").value,
      Search: this.standardsFilters.get("search").value,
      Page: this.standardsPage,
      Size: 0
    })
      .subscribe(
        (response: ServiceStandardsResponse): void => {
          this.standards.push(...response.standards);
        });
  }

  loadGroups() {
    this.serviceGroupsService.ServiceGroupsForServiceAsync(this.id)
      .subscribe(
        (response: ServiceGroup[]) => {
          this.groups = response;
        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить пакеты, в которые входит услуга', 'Ошибка');
        }
      );

  }

  loadIntegrations(): void {
    this.servicesService.Integrations({
      id: this.id,
      Page: this.integrationsPage,
      Size: 0,
      IncludeExpired: this.integrationsFilters.get("includeExpired").value,
      Search: this.integrationsFilters.get("search").value
    })
      .subscribe(
        (response: ServiceIntegrationsResponse): void => {
          this.integrations.push(...response.integrations)
        });

  }

  loadCodeSystemValues(): void {
    this.valueSetsService.Values({
      type: 'Service',
      id: this.id
    }).subscribe((response: CodeSystemValue[]): void => {
      this.codeSystemValues = response;
    })
  }

  loadServiceManipulations() {
    this.manipulationServicesService.ServiceManipulationServicesAsync(this.id)
      .subscribe(
        (response: ManipulationService[]) => {
          this.serviceManipulations = response;

          const value = this.manipulationsFilters.getRawValue();

          if (value.search) {
            const regexp = new RegExp(value.search);
            this.serviceManipulations$.next(response.filter(x => regexp.test(x.manipulationName)));
          } else {
            this.serviceManipulations$.next([...response]);
          }

        },
        (response: HttpErrorResponse) => {
          this.toastrService.error('Не удалось загрузить назначения услуги', 'Ошибка');
        }
      );

  }

  async loadServiceCompanies() {
    const response = await this.serviceCompaniesService.ServiceCompaniesAsync(this.id).toPromise();
    this.serviceCompanies = [];

    for (const company of this.companies.filter(x => x.type === 4)) {
      const existing = response.find(x => x.companyId == company.id);

      this.serviceCompanies.push({
        serviceId: this.id,
        companyId: company.id,
        companyName: company.name,
        enabled: !existing || existing.enabled
      });
    }
  }

  onScrollPrices(): void {
    this.pricesPage++;
    this.loadPrices();
  }

  onScrollStandards(): void {
    this.standardsPage++;
    this.loadStandards();
  }

  onScrollIntegrations(): void {
    this.integrationsPage++;
    this.loadIntegrations();
  }

  async addPrice(): Promise<void> {

    const priceSets = await this.servicesService.AvailablePriceSets(this.id)
      .pipe(map((response: AvailablePriceSetsResponse): PriceSet[] => response.priceSets))
      .toPromise();

    const price: PriceEditorModel = {};
    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(ServicePriceModalComponent, options);
    const componentRef: ServicePriceModalComponent = modalRef.componentInstance;

    componentRef.priceSets = priceSets;
    componentRef.price = price;
    componentRef.serviceId = this.id;

    componentRef.onClose.subscribe(() => {
      modalRef.close();
    });

    componentRef.onSubmit.subscribe((payload: { priceSetId: number, costs: number; wages: number; price: number; quantity: number; }) => {
      this.pricesService.Add({
        serviceId: this.id,
        priceSetId: payload.priceSetId,
        costs: payload.costs,
        wages: payload.wages,
        price: payload.price,
        quantity: payload.quantity
      }).subscribe(
        (): void => {
          this.pricesPage = 1;
          this.prices = [];
          this.loadPrices();

          modalRef.close();
        },
        (response: HttpErrorResponse) => {
          if (response.status === 400) {
            this.toastrService.warning(response.error, 'Ошибка');
          } else {
            this.toastrService.error('Не удалось обновить цену', 'Ошибка');
          }
        });

    });
  }

  addStandard(): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg" };

    const modalRef: NgbModalRef = this.modal.open(ServiceStandardModalComponent, options);
    const componentRef: ServiceStandardModalComponent = modalRef.componentInstance;

    componentRef.serviceId = this.id;

    modalRef.result.then(
      (): void => {
        this.standards = [];
        this.standardsPage = 1;
        this.loadStandards();
      },
      (): void => { }
    );
  }

  addCodeSystemValue(): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(ServiceCodeSystemModalComponent, options);
    const componetRef: ServiceCodeSystemModalComponent = modalRef.componentInstance;

    componetRef.codeSystems = this.codeSystems;

    componetRef.onClose.subscribe((): void => {
      modalRef.close();
    });

    componetRef.onSubmit.subscribe((value: { codeSystem: number, value: string }): void => {
      this.valueSetsService.Create({
        type: 'Service', request: {
          codeSystemId: value.codeSystem,
          value: value.value,
          targetId: this.id
        }
      }).subscribe((): void => {
        modalRef.close();
        this.loadCodeSystemValues();
      },
        (response: HttpErrorResponse): void => {
          switch (response.status) {
            case 400: {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }
            case 403: {
              this.toastrService.warning("Для выполнения операции отсутствуют необходимые разрешения", "Запрещено");
              return;
            }
            default: {
              this.toastrService.warning("Не удалось добавить код", "Ошибка");
            }
              break;
          }
        })
    });
  }

  async editPrice(item: ServicePrice): Promise<void> {
    const price = await this.pricesService.Price(item.id).toPromise();
    const priceSets = await this.priceSetsService.PriceSets({ IncludeExpired: true, Size: 0 })
      .pipe(map((response: PriceSetsResponse): PriceSet[] => response.priceSets))
      .toPromise();

    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(ServicePriceModalComponent, options);
    const componentRef: ServicePriceModalComponent = modalRef.componentInstance;

    componentRef.priceSets = priceSets;
    componentRef.price = price;
    componentRef.serviceId = this.id;

    componentRef.onClose.subscribe(() => {
      modalRef.close();
    });

    componentRef.onSubmit
      .subscribe((payload: { costs: number; wages: number; price: number; quantity: number }): void => {

        this.pricesService.Edit({
          id: item.id,
          model: {
            costs: payload.costs,
            wages: payload.wages,
            price: payload.price,
            quantity: payload.quantity
          }
        }).subscribe(
          (): void => {
            this.pricesPage = 1;
            this.prices = [];
            this.loadPrices();

            modalRef.close();
          },
          (response: HttpErrorResponse) => {
            if (response.status === 400) {
              this.toastrService.warning(response.error, 'Ошибка');
            } else {
              this.toastrService.error('Не удалось обновить цену', 'Ошибка');
            }
          });
      });

  }

  manageStandard(item: ServiceStandard): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(ManageServiceStandardModalComponent, options);

    modalRef.result.then(
      (): void => {
        this.standardsPage = 1;
        this.standards = [];
        this.loadStandards();
      },
      (): void => { }
    );

  }

  deleteStandard(item: ServiceStandard): void {
    this.servicesService.RemoveStandard({ standardId: item.id, id: this.id })
      .subscribe(
        (): void => {
          this.standardsPage = 1;
          this.standards = [];
          this.loadStandards();
        });
  }

  deletePrice(price: ServicePrice): void {
    this.pricesService.Delete(price.id)
      .subscribe((): void => {
        this.pricesPage = 1;
        this.prices = [];

        this.loadPrices();
      });
  }

  deleteCodeSystem(item: CodeSystemValue): void {
    this.valueSetsService.Delete(item.id).subscribe(() => {
      const index: number = this.codeSystemValues.findIndex((x: CodeSystemValue): boolean => x.id === item.id);

      if (index !== -1) {
        this.codeSystemValues.splice(index, 1);
      }

      this.toastrService.success("Код удален", "Успешно")
    },
      (response: HttpErrorResponse): void => {
        switch (response.status) {
          case 400: {
            this.toastrService.warning(response.error.message, "Ошибка");
            return;
          }
          default: {
            this.toastrService.warning("Не удалось добавить код", "Ошибка");
          }
            break;
        }
      })
  }

  async toggleCompany(item: ServiceCompany) {
    if (item.enabled) {
      await this.serviceCompaniesService.DisableServiceInCompanyAsync({ companyId: item.companyId, serviceId: this.id }).toPromise();
    } else {
      await this.serviceCompaniesService.EnableServiceInCompanyAsync({ companyId: item.companyId, serviceId: this.id }).toPromise();
    }

    await this.loadServiceCompanies();
  }

  async enableAllCompanies() {
    try {
      await this.serviceCompaniesService.EnableServiceInAllCompaniesAsync(this.id).toPromise();
      await this.loadServiceCompanies();

      this.toastrService.success('Услуга разрешена во всех филиалах', 'Успешно');
    } catch (e) {
      this.toastrService.error('Не удалось разрешить услугу во всех филиалах', 'Ошибка');
    }
  }

  async disableAllCompanies() {
    try {
      await this.serviceCompaniesService.DisableServiceInAllCompaniesAsync(this.id).toPromise();
      await this.loadServiceCompanies();

      this.toastrService.success('Услуга запрещена во всех филиалах', 'Успешно');
    } catch (e) {
      this.toastrService.error('Не удалось запреить услугу во всех филиалах', 'Ошибка');
    }
  }

  async removeManipulation(item: ManipulationService) {
    const confirmed = await this.confirmationService.open({ message: `Назначение '${item.manipulationName}' будет откреплено от услуги. продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.manipulationServicesService.RemoveManipulationServiceAsync(item.id)
      .subscribe(
        () => {
          this.toastrService.success('Назначение откреплено', 'Успешно');
          this.loadServiceManipulations();
        },
        (error: HttpErrorResponse) => {
          this.toastrService.error('Не удалось открепить назначение', 'Ошибка');
        }
      );
  }

  addManipulation() {
    const modalRef = this.modal.open(ServiceManipulationModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    const componentRef: ServiceManipulationModalComponent = modalRef.componentInstance;

    componentRef.serviceManipulations = this.serviceManipulations;

    componentRef.onCancel.subscribe(() => modalRef.close());
    componentRef.onConfirm.subscribe((manipulation: Manipulation) => {

      this.manipulationServicesService.CreateManipulationServiceAsync({ serviceId: this.id, manipulationId: manipulation.id })
        .subscribe(
          () => {
            this.toastrService.success('Назначение привязано', 'Успешно');
            this.loadServiceManipulations();
            modalRef.close();
          },
          (response: HttpErrorResponse) => {
            this.toastrService.error('Не удалось привязать назначение', 'Ошибка');
          }
        );

    });
  }

  addIntoGroup() {
    const modalRef = this.modal.open(GroupsModalComponent, { size: 'lg', centered: true, backdrop: 'static' });
    const component: GroupsModalComponent = modalRef.componentInstance;

    component.items = this.availableGroups.filter(x => !this.groups.some(s => s.id === x.id));

    component.onCancel.subscribe(() => modalRef.close());

    component.onConfirm.subscribe((group: ServiceGroup) => {

      this.serviceGroupsService.AddServiceIntoServiceGroupAsync({ id: group.id, request: { serviceId: this.id } })
        .subscribe(
          () => {
            this.toastrService.success(`Услуга добавлена в пакет «${group.name}»`, 'Успешно');

            modalRef.close();

            this.loadGroups();
          },
          (response: HttpErrorResponse) => this.handleError(response, 'Не удалось добавить услугу в пакет')
        );
    });
  }

  async removeFromGroup(group: ServiceGroup) {
    const confirmed = await this.confirmationService.open({ message: `Услуга будет исключена из пакета «${group.name}». Продолжить?`, confirmBtnText: 'Продолжить' });

    if (!confirmed) return;

    this.serviceGroupsService.RemoveServiceFromServiceGroupResponse({ id: group.id, serviceId: this.id }).subscribe(
      () => {
        this.toastrService.success(`Услуга исключена из пакета «${group.name}»`, 'Успешно');
        this.loadGroups();
      },
      (response: HttpErrorResponse) => this.handleError(response, `Не удалось убрать услугу из пакета «${group.name}»`)
    )
  }

  errorMessage(code: string, message: string) {

    switch (code) {

      case 'NameRequired': return 'Необходимо указать имя пакета';
      case 'RoundingTypeInvalid': return 'Неизвестный тип округления';
      case 'PermissionRequired': return 'Для выполнения операции отсутствует необходимое разрешение';
      case 'ServiceGroupNotFound': return 'Пакет не найдена';
      case 'ServiceAlreadyAdded': return 'Услуга уже добавлена в пакет';
      case 'ServiceNotFound': return 'Услуга не найдена';
      case 'ServiceGroupServiceNotFound': return 'Услуга не найдена в данном пакете';

      default: return message;
    }
  }

  handleError(response: HttpErrorResponse, message: string) {
    if (response.status === 400 && response.error && response.error.errors) {

      for (const error of response.error.errors) {
        this.toastrService.warning(this.errorMessage(error.errorCode, message), 'Ошибка');
      }

      return;
    }

    if (response.status === 400 && response.error && Array.isArray(response.error)) {
      for (const error in response.error) {
        this.toastrService.warning(this.errorMessage(error, message), 'Ошибка');
      }

      return;
    }

    this.toastrService.error(message, 'Ошибка');
  }
}



interface FormValue {
  name: string;
  seoName: string;
  nameInCheck: string;
  nameInDocument: string;

  code: number;

  category: number;
  speciality: number;

  archived: boolean;
  favorite: boolean;

  lab: number;
  labCode: string;
  timing: number;
  taxationType: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  taxationSystem: 1 | 2 | 3 | 4;
  printAgreement: boolean;
  agreement: number;
  onlineBooking: 0 | 1 | 2;
  type: 0 | 1 | 4;

  canStack: boolean;
}
