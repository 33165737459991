/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PersonDocument } from '../models/person-document';
import { AddPersonDocumentResponse } from '../models/add-person-document-response';
@Injectable({
  providedIn: 'root',
})
class PersonDocumentsService extends __BaseService {
  static readonly DocumentsAsyncPath = '/api/v2/persons/{personId}/documents';
  static readonly UploadAsyncPath = '/api/v2/persons/{personId}/documents';
  static readonly DocumentLinkAsyncPath = '/api/v2/persons/documents/{token}';
  static readonly DocumentAsyncPath = '/api/v2/persons/{personId}/documents/{hash}';
  static readonly RemoveAsyncPath = '/api/v2/persons/{personId}/documents/{hash}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param personId undefined
   * @return Success
   */
  DocumentsAsyncResponse(personId: number): __Observable<__StrictHttpResponse<Array<PersonDocument>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${personId}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PersonDocument>>;
      })
    );
  }
  /**
   * @param personId undefined
   * @return Success
   */
  DocumentsAsync(personId: number): __Observable<Array<PersonDocument>> {
    return this.DocumentsAsyncResponse(personId).pipe(
      __map(_r => _r.body as Array<PersonDocument>)
    );
  }

  /**
   * @param params The `PersonDocumentsService.UploadAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `Document`:
   *
   * @return Success
   */
  UploadAsyncResponse(params: PersonDocumentsService.UploadAsyncParams): __Observable<__StrictHttpResponse<AddPersonDocumentResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;

    if (params.Document != null) { __formData.append('Document', params.Document as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v2/persons/${params.personId}/documents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddPersonDocumentResponse>;
      })
    );
  }
  /**
   * @param params The `PersonDocumentsService.UploadAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `Document`:
   *
   * @return Success
   */
  UploadAsync(params: PersonDocumentsService.UploadAsyncParams): __Observable<AddPersonDocumentResponse> {
    return this.UploadAsyncResponse(params).pipe(
      __map(_r => _r.body as AddPersonDocumentResponse)
    );
  }

  /**
   * @param params The `PersonDocumentsService.DocumentLinkAsyncParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `Download`:
   *
   * @return Success
   */
  DocumentLinkAsyncResponse(params: PersonDocumentsService.DocumentLinkAsyncParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Download != null) __params = __params.set('Download', params.Download.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/documents/${params.token}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `PersonDocumentsService.DocumentLinkAsyncParams` containing the following parameters:
   *
   * - `token`:
   *
   * - `Download`:
   *
   * @return Success
   */
  DocumentLinkAsync(params: PersonDocumentsService.DocumentLinkAsyncParams): __Observable<Blob> {
    return this.DocumentLinkAsyncResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `PersonDocumentsService.DocumentAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   *
   * @return Success
   */
  DocumentAsyncResponse(params: PersonDocumentsService.DocumentAsyncParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v2/persons/${params.personId}/documents/${params.hash}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      })
    );
  }
  /**
   * @param params The `PersonDocumentsService.DocumentAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   *
   * @return Success
   */
  DocumentAsync(params: PersonDocumentsService.DocumentAsyncParams): __Observable<Blob> {
    return this.DocumentAsyncResponse(params).pipe(
      __map(_r => _r.body as Blob)
    );
  }

  /**
   * @param params The `PersonDocumentsService.RemoveAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   */
  RemoveAsyncResponse(params: PersonDocumentsService.RemoveAsyncParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v2/persons/${params.personId}/documents/${params.hash}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `PersonDocumentsService.RemoveAsyncParams` containing the following parameters:
   *
   * - `personId`:
   *
   * - `hash`:
   */
  RemoveAsync(params: PersonDocumentsService.RemoveAsyncParams): __Observable<null> {
    return this.RemoveAsyncResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PersonDocumentsService {

  /**
   * Parameters for UploadAsync
   */
  export interface UploadAsyncParams {
    personId: number;
    Document?: Blob;
  }

  /**
   * Parameters for DocumentLinkAsync
   */
  export interface DocumentLinkAsyncParams {
    token: string;
    Download?: boolean;
  }

  /**
   * Parameters for DocumentAsync
   */
  export interface DocumentAsyncParams {
    personId: number;
    hash: string;
  }

  /**
   * Parameters for RemoveAsync
   */
  export interface RemoveAsyncParams {
    personId: number;
    hash: string;
  }
}

export { PersonDocumentsService }
