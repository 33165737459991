import { Component, OnInit, OnDestroy } from "@angular/core";
import { EmployeesService } from 'projects/Clinic/src/app/generated/services';
import { Employee, EmployeesResponse, Role, Speciality } from 'projects/Clinic/src/app/generated/models';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ManageEmployeeComponent } from '../manage-employee/manage-employee.component';
import { Subject, Observable, from } from 'rxjs';
import { takeUntil, tap, switchMap, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import * as moment from "moment";
import { EmployeesPayload } from '../../resolvers/employees.resolver';
import { Company } from '../../../../../../../PatientPortal/src/app/generated/models';
import { ScrollHistoryService } from "../../../shared/services/scroll-history-service";

@Component({
  selector: 'mp-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
  host: { class: "page" }
})
export class EmployeesComponent implements OnInit, OnDestroy {
  private _destroy: Subject<any> = new Subject<any>();

  employees: Employee[] = [];
  selected: { [id: number]: boolean } = {};

  page = 1;
  size = 50;

  loading = false;

  scrollDistance = 1;

  specialities: Speciality[] = [];
  roles: Role[] = [];
  companies: Company[] = [];

  showAdvancedSearch = false;

  searchForm: FormGroup;

  constructor(
    private employeesService: EmployeesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private readonly scrollHistoryService: ScrollHistoryService
  ) {

    this.searchForm = new FormGroup({
      search: new FormControl(""),
      includeFired: new FormControl(""),
      specialities: new FormControl([]),
      roles: new FormControl([]),
      companies: new FormControl([]),
      employeesFlag: new FormControl(0)
    });
  }

  ngOnInit() {
    const queryMap = this.activatedRoute.snapshot.queryParamMap;

    const value: SearchValue = {
      includeFired: false,
      search: "",
      specialities: [],
      roles: [],
      companies: [],
      employeesFlag: 0
    };

    if (queryMap.has("search")) {
      value.search = queryMap.get("search");
    }

    if (queryMap.has("includeFired") && queryMap.get("includeFired") == "true") {
      value.includeFired = true;
    }

    if (queryMap.has("speciality")) {
      value.specialities = queryMap.getAll("speciality").map((x: string): number => parseInt(x, 10));
    }

    if (queryMap.has("role")) {
      value.roles = queryMap.getAll("role").map((x: string): number => parseInt(x, 10));
    }

    if (queryMap.has("company")) {
      value.companies = queryMap.getAll("company").map((x: string): number => parseInt(x, 10));
    }

    if (queryMap.has("employeesFlag")) {
      let flag = queryMap.get("employeesFlag");
      if (flag === "1") {
        value.employeesFlag = 1;
      } else if (flag === "2") {
        value.employeesFlag = 2;
      }
    }

    this.searchForm.patchValue(value);

    this.initScroll();

    this.searchForm.valueChanges
      .pipe(
        takeUntil(this._destroy),
        tap((value: SearchValue): void => {
          const queryParams: Params = {};

          if (value.search) {
            queryParams.search = value.search;
          }

          if (value.includeFired) {
            queryParams.includeFired = true;
          }

          if (value.specialities.length > 0) {
            queryParams.speciality = value.specialities;
          }

          if (value.roles.length > 0) {
            queryParams.role = value.roles;
          }

          if (value.companies.length > 0) {
            queryParams.company = value.companies;
          }

          if (value.employeesFlag !== 0) {
            queryParams.employeesFlag = value.employeesFlag;
          }

          this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams })
        }),
        tap((): void => {
          this.page = 1;
          this.loading = true;
          this.employees = [];
        }),
        switchMap((value: SearchValue): Observable<EmployeesResponse> =>
          this.employeesService.Employees({
            Page: 1,
            Size: this.size,
            Search: value.search,
            IncludeFired: value.includeFired,
            SpecialityIds: value.specialities,
            RoleIds: value.roles,
            CompanyIds: value.companies,
            EmployeesFlag: value.employeesFlag
          })
        ),
        map((response: EmployeesResponse): Employee[] => response.items)
      ).subscribe(
        (employees: Employee[]): void => {
          this.employees = employees;
          this.loading = false;
        },
        (): void => {
          this.loading = false;
        }
      );


    this.activatedRoute.data.subscribe(
      (data: { payload: EmployeesPayload }) => {
        this.specialities = data.payload.specialities;
        this.roles = data.payload.roles;
        this.companies = data.payload.companies;
      }
    )
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.unsubscribe();
  }

  async initScroll(): Promise<void> {
    const position = this.scrollHistoryService.retrievePosition('employees');

    if (!position) {
      this.load();
      return;
    }

    this.page = position.page;

    const value: SearchValue = this.searchForm.value;

    const payload = {
      Size: position.page * this.size,
      Page: 1,
      Search: value.search,
      IncludeFired: value.includeFired,
      SpecialityIds: value.specialities,
      RoleIds: value.roles,
      CompanyIds: value.companies,
      EmployeesFlag: value.employeesFlag
    }

    try {
      const response = await this.employeesService.Employees(payload).toPromise();

      this.employees.push(...response.items);

      setTimeout(() => {
        document.getElementById(`Employee${position.id}`).scrollIntoView({ behavior: 'auto' });
      }, 0);

      this.scrollHistoryService.resetPosition('employees');

    } catch (e) { }
    this.loading = false;
  }

  async load(): Promise<void> {

    const value: SearchValue = this.searchForm.value;

    const response = await this.employeesService.Employees({
      Size: this.size,
      Page: this.page,
      Search: value.search,
      IncludeFired: value.includeFired,
      SpecialityIds: value.specialities,
      RoleIds: value.roles,
      CompanyIds: value.companies,
      EmployeesFlag: value.employeesFlag
    }).toPromise();

    this.employees.push(...response.items);
    this.loading = false;

  }

  async onScrollDown() {
    this.page++;

    this.loading = true;
    await this.load();
  }

  edit(employee: Employee): void {
    this.scrollHistoryService.savePosition('employees', this.page, employee.id);

    this.router.navigate([employee.id], { relativeTo: this.activatedRoute });
  }

  create(): void {
    this.router.navigate(["new"], { relativeTo: this.activatedRoute });
  }

  manage(employee: Employee): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg" }

    const modalRef: NgbModalRef = this.modal.open(ManageEmployeeComponent, options);
    const instanceRef: ManageEmployeeComponent = modalRef.componentInstance;

    instanceRef.employee = employee;

    from(modalRef.result)
      .subscribe(
        (value: { status: 1 | 2 }): void => {
          const index: number = this.employees.findIndex((x: Employee): boolean => x.id === employee.id);
          const searchValue: SearchValue = this.searchForm.value;

          if (index !== -1) {
            if (value.status === 1) {
              this.employees.splice(index, 1);
            }

            if (value.status === 2) {
              if (searchValue.includeFired) {
                this.employees[index].expires = moment().format("DD.MM.YYYY");
              } else {
                this.employees.splice(index, 1);
              }

            }
          }
        },
        (): void => { }
      );
  }

  toggleAdvancedSearch(): void {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }
}

interface SearchValue {
  search: string;
  includeFired: boolean;
  specialities: number[];
  roles: number[];
  companies: number[];
  employeesFlag: 0 | 1 | 2;
}
