import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Medcard } from '../../../../generated/models';

@Component({
  selector: 'mp-medcard-list-item',
  templateUrl: './medcard-list-item.component.html',
  styleUrls: ['./medcard-list-item.component.scss']
})
export class MedcardListItemComponent implements OnInit {
  @Input() item: Medcard;
  @Input() expanded: boolean;

  @Output() onConnectRepresentative = new EventEmitter<void>();
  @Output() onRemoveRepresentative = new EventEmitter<void>();

  get phone(): string {
    if (!this.item.phone) return "";

    if (this.item.phone.length !== 10) return this.item.phone;

    return `+7 (${this.item.phone.substr(0, 3)}) ${this.item.phone.substr(3, 3)}-${this.item.phone.substr(6, 2)}-${this.item.phone.substr(8, 2)}`;
  }

  constructor() { }

  ngOnInit() { }

  connectRep = () => this.onConnectRepresentative.emit();
  onRemoveRep = () => this.onRemoveRepresentative.emit();
}
