import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { PatientSearchResponse, PatientSearchResult } from 'projects/Clinic/src/app/generated/models';
import { PatientsService } from 'projects/Clinic/src/app/generated/services';
import { FormControl } from '@angular/forms';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, tap, switchMap, map, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mp-select-client-modal',
  templateUrl: './select-client-modal.component.html',
  styleUrls: ['./select-client-modal.component.scss', '../../spinner.scss'],
  host: {
    class: "modal-default"
  }
})
export class SelectClientModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  private _selectedClientId: number;

  @Input()
  set searchQuery(value: string) { this.searchControl.setValue(value); };

  @Input() selectedPatientId: number;

  @Output()
  onClose = new EventEmitter<void>();
  @Output()
  onSelect = new EventEmitter<PatientSearchResult>();

  patients: PatientSearchResult[] = [];
  loading = false;
  page = 1;
  size = 50;

  searchControl = new FormControl("");

  get disabled(): boolean { return !this._selectedClientId; }

  constructor(private patientsService: PatientsService) {

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        tap(() => this.page = 1),
        tap(() => this.loading = true),
        tap(() => this.patients = []),
        switchMap((value: string): Observable<PatientSearchResponse> => {
          if (!value) return of({ items: [] });

          return this.patientsService.Search({
            Page: this.page,
            Size: this.size,
            Search: value
          })
        }),
        map((response: PatientSearchResponse): PatientSearchResult[] => response.items)
      )
      .subscribe(
        (items: PatientSearchResult[]): void => {
          this.patients = items;
          this.loading = false;
        },
        (): void => {
          this.patients = [];
          this.loading = false;
        }
      );
  }
  ngOnInit() {
    this._selectedClientId = this.selectedPatientId;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onScroll(): void {
    this.page++;

    const value: string = this.searchControl.value;
    if (!value) return;

    const params: PatientsService.SearchParams = {
      Page: this.page,
      Size: this.size,
      Search: value
    };

    this.patientsService.Search(params).subscribe((response: PatientSearchResponse) => this.patients.push(...response.items));
  }

  dismiss = () => this.onClose.emit();
  select = (patient: PatientSearchResult) => this.onSelect.emit(patient);
  selected = (patient: PatientSearchResult) => this._selectedClientId && this._selectedClientId === patient.id;
}
