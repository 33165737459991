import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class DiagnosisCodeValidator {
  public static validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const source: string = control.value;

    const codes: string[] = source.split(/[\-;]/);

    return codes.filter((x: string): boolean => !!x)
      .every(x => /^([A-Z][0-9][0-9]){1}(\.[0-9]{1,2}){0,1}$/.test(x.trim())) ?
      null : { diagnosisCode: true };
  }
}
