import { DiscountResponse } from '../../../generated/models';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DiscountsService } from '../../../generated/services/discounts.service';

@Injectable({ providedIn: "root" })
export class DiscountResolver implements Resolve<DiscountResolverPayload> {

  public constructor(
    private service: DiscountsService,
  ) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DiscountResolverPayload> {

    const id: number = parseInt(route.paramMap.get("id"));

    let discountLoader: Observable<DiscountResponse> = null;

    if (id) {
      discountLoader = this.service.Get(id);
    } else {
      const discount: any = {};

      discountLoader = from([discount]);
    }

    return forkJoin({
      discount: discountLoader,
    });
  }
}

export class DiscountResolverPayload {
  public discount: DiscountResponse;

  public constructor() {
  }
}
