import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DiseaseOutcome, RecordSection } from '../../../../generated/models';

import * as moment from "moment";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-closing-section-editor',
  templateUrl: './closing-section-editor.component.html',
  styleUrls: ['./closing-section-editor.component.scss']
})
export class ClosingSectionEditorComponent implements OnInit, OnChanges, OnDestroy {
  private _destroy$: EventEmitter<void> = new EventEmitter<void>();

  @Input() section: RecordSection;
  @Input() disabled: boolean;

  @Input() outcomes: DiseaseOutcome[] = [];

  @Output()
  onChange: EventEmitter<RecordSection> = new EventEmitter<RecordSection>();

  closingDateControl: FormControl;
  caseResultControl: FormControl;

  constructor() {
    this.closingDateControl = new FormControl(undefined, [Validators.required]);
    this.caseResultControl = new FormControl(undefined, [Validators.required]);

    this.closingDateControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: moment.Moment): void => {
        if (value) {
          this.onChange.emit({ ...this.section, closingDate: value.format('DD.MM.YYYY') });
        } else {
          this.onChange.emit({ ...this.section, closingDate: "" });
        }
      });

    this.caseResultControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: number): void => {
        this.onChange.emit({ ...this.section, caseResultId: value });
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.closingDateControl.disable({ emitEvent: false });
        this.caseResultControl.disable({ emitEvent: false });
      } else {
        this.closingDateControl.enable({ emitEvent: false });
        this.caseResultControl.enable({ emitEvent: false });
      }
    }

    if (changes.section) {
      const currentValue: RecordSection = changes.section.currentValue;

      this.caseResultControl.setValue(currentValue.caseResultId, { emitEvent: false });

      if (currentValue.closingDate && moment(currentValue.closingDate, "DD.MM.YYYY").isValid()) {
        console.info(moment(currentValue.closingDate, "DD.MM.YYYY"));
        this.closingDateControl.setValue(moment(currentValue.closingDate, "DD.MM.YYYY"), { emitEvent: false });
      } else {
        this.closingDateControl.setValue(undefined, { emitEvent: false });
      }
    }

  }

  ngOnInit() { }

}
