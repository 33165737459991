import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mp-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {

  @Input() path: string;
  @Input() canPrint = false;

  @Output() onDownload = new EventEmitter<void>();

  url: SafeResourceUrl;
  loading = false;

  @ViewChild("Content") content: ElementRef<HTMLIFrameElement>;

  constructor(private modal: NgbActiveModal, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.path);

    this.loading = true;

    this.content.nativeElement.onload = (e): void => {
      this.content.nativeElement.classList.add('loaded');
      this.loading = false;
    };
  }

  download = () => this.onDownload.emit();
  print = () => this.content.nativeElement.contentWindow.print();
  close = () => this.modal.close();
}
