import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Manipulation, ManipulationService } from '../../../../generated/models';
import { BehaviorSubject, Observable, Subject, from } from 'rxjs';
import { ManipulationsService } from '../../../../generated/services';
import { FormControl } from '@angular/forms';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'mp-service-manipulation-modal',
  templateUrl: './service-manipulation-modal.component.html',
  styleUrls: ['./service-manipulation-modal.component.scss']
})
export class ServiceManipulationModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() serviceManipulations: ManipulationService[] = [];

  @Output() onCancel = new EventEmitter<void>();
  @Output() onConfirm = new EventEmitter<Manipulation>();

  result$ = new BehaviorSubject<Manipulation[]>([]);

  item: Manipulation;

  page = 1;
  size = 100;

  search = new FormControl('');

  manipulations: Manipulation[] = [];
  loading = false;

  constructor(private manipulationsService: ManipulationsService,
    private toastrService: ToastrService) {
    this.search.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.page = 1;
          this.manipulations = [];
          this.loading = true;
          this.item = undefined;
        }),
        switchMap((value: string): Observable<Manipulation[]> => {
          return this.manipulationsService.ManipulationsAsync({ Page: this.page, Size: this.size, Search: value })
            .pipe(catchError((response: HttpErrorResponse) => { this.toastrService.error('Не удалось загрузить назначения', 'Ошикбка'); return from([]); }));

        })
      ).subscribe(
        (response: Manipulation[]) => {
          this.manipulations = response;
          this.result$.next(response);

          this.loading = false;
        }
      );

  }

  ngOnInit() {
    this.search.patchValue('');
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  show = (item: Manipulation) => this.serviceManipulations && !this.serviceManipulations.some(x => x.manipulationId === item.id);
  selected = (item: Manipulation) => this.item && this.item.id === item.id;
  select = (item: Manipulation) => this.item = item;

  get canConfirm(): boolean { return !!this.item; }

  confirm = () => this.onConfirm.emit(this.item);
  cancel = () => this.onCancel.emit();


  onScrollDown() {
    this.loading = true;

    this.page++;

    const value = this.search.value;

    this.manipulationsService.ManipulationsAsync({ Page: this.page, Size: this.size, Search: value })
      .subscribe(
        (response: Manipulation[]) => {
          this.manipulations.push(...response);
          this.loading = false;
        }
      );


  }
}
