import { Component, OnInit, Input } from '@angular/core';
import { PatientSearchResult } from '../../../../generated/models';
import * as moment from 'moment';

@Component({
  selector: 'mp-representative-select-item',
  templateUrl: './representative-select-item.component.html',
  styleUrls: ['./representative-select-item.component.scss']
})
export class RepresentativeSelectItemComponent implements OnInit {

  private _phone: string;
  private _age: number;

  @Input() selected: boolean;
  @Input() patient: PatientSearchResult;

  public get phone(): string { return this._phone; }
  public get age(): number { return this._age; }

  constructor() { }

  ngOnInit() {
    if (this.patient) {
      this._phone = this.patient.phone;

      if (this.patient.phone && this.patient.phone.startsWith("7") && this.patient.phone.length === 11) {
        this._phone = `7 (${this.patient.phone.substr(1, 3)}) ${this.patient.phone.substr(4, 3)} ${this.patient.phone.substr(7, 4)}`;
      }

      if (this.patient.dob) {
        this._age = moment().diff(moment(this.patient.dob, "DD.MM.YYYY"), 'years');
      }
    }
  }

}
