import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceCategoriesComponent } from './components/service-categories/service-categories.component';
import { ServiceCategoryItemComponent } from './components/service-category-item/service-category-item.component';
import { Routes, RouterModule } from '@angular/router';
import { ServiceCategoryResolver } from './resolvers/service-category-resolver';
import { ReactiveFormsModule } from '@angular/forms';

import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { ToastrModule } from 'ngx-toastr';
import { NgbDropdownModule, NgbAccordionModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionNames } from '../../models/permission-names';
import { ServiceCategoryModalComponent } from './components/service-category-modal/service-category-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ServiceCategoryComponent } from './components/service-category/service-category.component';

const routes: Routes = [
  {
    path: "new",
    component: ServiceCategoryComponent,
    data: {
      title: "Новая категория",
      requirements: [PermissionNames.EditCategories]
    },
    resolve: {
      payload: ServiceCategoryResolver
    }
  },
  {
    path: ":id",
    component: ServiceCategoryComponent,
    data: {
      title: "Категория",
      requirements: [PermissionNames.EditCategories]
    },
    resolve: {
      payload: ServiceCategoryResolver
    }
  },
  {
    path: "",
    component: ServiceCategoriesComponent,
    data: {
      title: "Список категорий",
      requirements: [PermissionNames.EditCategories]
    }
  }
];

@NgModule({
  declarations: [
    ServiceCategoriesComponent,
    ServiceCategoryItemComponent,
    ServiceCategoryModalComponent,
    ServiceCategoryComponent
  ],
  imports: [
    CommonModule,
    ToastrModule,

    RouterModule.forChild(routes),
    ReactiveFormsModule,

    NgbDropdownModule,
    NgbAccordionModule,
    NgbModalModule,
    DragDropModule,

    LayoutModule,
    ControlsModule
  ],
  entryComponents: [
    ServiceCategoryModalComponent
  ],
  exports: [
    RouterModule
  ]
})
export class ServiceCategorySettingsModule { }
