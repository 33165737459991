import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { PartnerPlansService } from 'projects/Clinic/src/app/generated/services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RateTypeOption } from 'projects/Clinic/src/app/generated/models/rate-type-option';
import { RateDiscountBehaviorOption } from 'projects/Clinic/src/app/generated/models/rate-discount-behavior-option';
import { PartnerPlanService } from 'projects/Clinic/src/app/generated/models/partner-plan-service';
import { EditPartnerPlanModel } from 'projects/Clinic/src/app/generated/models/edit-partner-plan-model';
import { PartnerPlanServicesResponse } from 'projects/Clinic/src/app/generated/models/partner-plan-services-response';

import * as moment from "moment";
import { PartnerPlanResolverPayload } from '../../resolvers/partner-plan.resolver';
import { ServiceCategory } from 'projects/Clinic/src/app/generated/models';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PlanServiceModalComponent } from '../plan-service-modal/plan-service-modal.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CreatePartnerPlanResponse } from '../../../../generated/models/create-partner-plan-response';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'mp-partner-plan-editor',
  templateUrl: './partner-plan.component.html',
  styleUrls: ['./partner-plan.component.scss'],
  host: { class: "page" }
})
export class PartnerPlanComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  private _title: string;

  public scrollDistance: number = 1;

  private _types: RateTypeOption[];
  private _behaviors: RateDiscountBehaviorOption[];
  private _categories: ServiceCategory[];

  private _id: number;

  private _page: number;
  private _size: number;
  private _total: number;

  private _disableServices: boolean;

  private _services: PartnerPlanService[];

  public get taxErrorMessage(): string {
    const control: AbstractControl = this.paymentsForm.get("tax");

    if (control.valid || control.pristine) {
      return "";
    }

    if (control.errors["min"]) {
      return `Значение не может быть меньше ${control.errors["min"].min}`;
    }

    if (control.errors["max"]) {
      return `Значение не может быть больше ${control.errors["max"].max}`;
    }

    return "";
  }

  public get types(): RateTypeOption[] { return this._types; }
  public get behaviours(): RateDiscountBehaviorOption[] { return this._behaviors; }
  public get services(): PartnerPlanService[] { return this._services; }
  public get categories(): ServiceCategory[] { return this._categories; }

  public get title(): string { return this._title; }
  public get disableServices(): boolean { return !this._id; }

  public paymentsForm: FormGroup;
  public servicesForm: FormGroup;

  constructor(
    private partnerPlansService: PartnerPlansService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modal: NgbModal,
    private readonly toastrService: ToastrService,
    private decimalPipe: DecimalPipe
  ) {
    this._types = [];
    this._behaviors = [];
    this._categories = [];
    this._title = "Программа";

    this._services = [];

    const typeControl: FormControl = new FormControl(undefined, [Validators.required]);
    const taxControl: FormControl = new FormControl(undefined, [Validators.min(0)]);

    this.paymentsForm = new FormGroup({
      name: new FormControl("", [Validators.required]),
      type: typeControl,
      tax: taxControl,
      behavior: new FormControl(undefined, [Validators.required]),
      effective: new FormControl("", [Validators.required]),
      expires: new FormControl("")
    });

    typeControl.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((value: 1 | 2): void => {
        if (value === 1) {
          taxControl.setValidators([Validators.min(0)]);
        }
        if (value === 2) {
          taxControl.setValidators([Validators.min(0), Validators.max(100)]);
        }
      });

    this.servicesForm = new FormGroup({
      search: new FormControl(""),
      includeArchived: new FormControl(false),
      category: new FormControl(0)
    });

    this._page = 1;
    this._size = 50;
    this._total = 0;
  }

  ngOnInit() {

    this.activatedRoute.data.subscribe((data: { payload: PartnerPlanResolverPayload }): void => {
      this._types = data.payload.types;
      this._behaviors = data.payload.behaviors;
      this._categories = data.payload.categories;

      const effective: moment.Moment = data.payload.plan.effective ? moment(data.payload.plan.effective, "DD.MM.YYYY") : undefined;
      const expires: moment.Moment = data.payload.plan.expires ? moment(data.payload.plan.expires, "DD.MM.YYYY") : undefined;

      this.paymentsForm.patchValue({
        name: data.payload.plan.name,
        type: data.payload.plan.type,
        behavior: data.payload.plan.behavior,
        tax: data.payload.plan.tax,
        effective: effective && effective.isValid() ? effective : undefined,
        expires: expires && expires.isValid() ? expires : undefined
      });

      this._title = this._title = data.payload.plan.id > 0 ? `Программа: ${data.payload.plan.name}` : "Новая программа";
    });

    this.activatedRoute.params.subscribe((params: Params): void => {
      this._id = parseInt(params["id"]);

      this._disableServices = !this._id;

      if (this._id) {
        this.loadServices();
      }
    });

    this.servicesForm.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe((): void => {
        this._page = 1;
        this._services = [];

        this.loadServices();
      });

  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  public acceptChanges(value: FormValue): void {
    if (this._id > 0) {

      this.partnerPlansService.Edit({
        id: this._id, model: {
          name: value.name,
          tax: value.tax,
          type: value.type,
          behavior: value.behavior,
          effective: (value.effective && value.effective.isValid()) ? value.effective.format("DD.MM.YYYY") : "",
          expires: (value.expires && value.expires.isValid()) ? value.expires.format("DD.MM.YYYY") : "",
        }
      })
        .subscribe(
          (): void => {
            this.toastrService.success("Партнерская программа сохранена", "Успешно");

            this._services = [];
            this._page = 1;

            this.loadServices();
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось обновить партнерскую программу", "Ошибка");
          }
        );

    } else {
      this.partnerPlansService.Create({
        name: value.name,
        tax: value.tax,
        type: value.type,
        behavior: value.behavior,
        effective: (value.effective && value.effective.isValid()) ? value.effective.format("DD.MM.YYYY") : "",
        expires: (value.expires && value.expires.isValid()) ? value.expires.format("DD.MM.YYYY") : "",
      })
        .subscribe(
          (created: CreatePartnerPlanResponse): void => {
            this.toastrService.success("Партнерская программа создана", "Успешно");
            this.router.navigate(["../", created.id], { relativeTo: this.activatedRoute });
          },
          (response: HttpErrorResponse): void => {
            if (response.status === 400) {
              this.toastrService.warning(response.error.message, "Ошибка");
              return;
            }

            this.toastrService.error("Не удалось добавить партнерскую программу", "Ошибка");
          }
        );
    }
  }

  public onScrollDown = (): void => {
    this._page++;

    this.loadServices();
  }

  public serviceClass(type: PartnerPlanService): string {
    switch (type.status) {
      case 0: return "negative";
      case 1: return "neutral";
      case 2: return "positiv";
      default: "";
    }
  }

  public editService(service: PartnerPlanService): void {
    const options: NgbModalOptions = { backdrop: "static", size: "lg" };
    const modalRef: NgbModalRef = this.modal.open(PlanServiceModalComponent, options);
    const componentRef: PlanServiceModalComponent = modalRef.componentInstance;

    const value: FormValue = this.paymentsForm.getRawValue();

    componentRef.plan = {
      id: this._id,
      name: value.name,
      type: value.type,
      effective: value.effective ? value.effective.format('dd.MM.yyyy') : '',
      expires: value.expires ? value.expires.format('dd.MM.yyyy') : '',
      behavior: value.behavior,
      tax: value.tax
    };

    componentRef.service = service;

    componentRef.onCancel.subscribe((): void => {
      modalRef.close();
    });

    componentRef.onSubmit.subscribe(
      (value: { partnersBonus: number; employeesBonus: number }): void => {

        this.partnerPlansService.EditService({
          id: this._id,
          serviceId: service.serviceId,
          model: {
            employeesBonus: value.employeesBonus,
            partnersBonus: value.partnersBonus
          }
        })
          .subscribe(
            (response: PartnerPlanService): void => {
              service.defaultPrice = response.defaultPrice;
              service.employeesTaxRub = response.employeesTaxRub;
              service.employeeTaxPercent = response.employeeTaxPercent;
              service.partnersTaxPercent = response.partnersTaxPercent;
              service.partnersTaxRub = response.partnersTaxRub;

              service.status = response.status;

              modalRef.close();
            },
            (response: HttpErrorResponse): void => {
              if (response.status === 400) {
                this.toastrService.warning(response.error.message, "Ошибка");
                return;
              }

              this.toastrService.error("Не удалось обновить услугу", "Ошибка");
            }
          );
      }
    );
  }

  private loadServices(): void {
    const filters: ServicesFilters = this.servicesForm.getRawValue();

    this.partnerPlansService.Services({
      id: this._id,
      Page: this._page,
      Size: this._size,
      Search: filters.search,
      CategoryId: filters.category > 0 ? filters.category : null,
      IncludeArchived: filters.includeArchived
    })
      .subscribe((result: PartnerPlanServicesResponse): void => {
        this._services = this._services.concat(result.items);
        this._total = result.total;
      });
  }

  public getTaxForService(service: PartnerPlanService): string {
    const formValue: FormValue = this.paymentsForm.getRawValue();

    if (formValue.tax && service.defaultPrice) {
      if (formValue.type === 1) {
        const employeeServicePercent = 100 * formValue.tax / service.defaultPrice;
        return `${this.decimalPipe.transform(formValue.tax, '1.0-2')}₽ / ${this.decimalPipe.transform(employeeServicePercent, '1.0-2')} %`;
      } else if (formValue.type === 2) {
        const employeeServicePrice = service.defaultPrice * formValue.tax / 100.0;
        return `${this.decimalPipe.transform(employeeServicePrice, '1.0-2')}₽ / ${this.decimalPipe.transform(formValue.tax, '1.0-2')} %`;
      }
    } else if (formValue.tax) {
      if (formValue.type === 1) {
        return `${this.decimalPipe.transform(formValue.tax, '1.0-2')}₽ / %`;
      } else if (formValue.type === 2) {
        return `₽ / ${this.decimalPipe.transform(formValue.tax, '1.0-2')} %`;
      }
    } else {
      return `₽ / %`
    }
  }

  public employeeTaxHasValue(service: PartnerPlanService): boolean {
    return service.employeeTaxPercent !== undefined && service.employeeTaxPercent !== null
      && service.employeesTaxRub !== undefined && service.employeesTaxRub !== null;
  }

  public partnerTaxHasValue(service: PartnerPlanService): boolean {
    return service.partnersTaxPercent !== undefined && service.partnersTaxPercent !== null
      && service.partnersTaxRub !== undefined && service.partnersTaxRub !== null;
  }
}

interface FormValue {
  name: string;
  type: 1 | 2;
  tax: number;
  behavior: 1 | 2 | 3 | 4;
  effective: moment.Moment;
  expires: moment.Moment;
}

interface ServicesFilters {
  search: string;
  category: number;
  includeArchived: boolean;
}
