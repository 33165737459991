import { OnInit, Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { MyService, PersonsService } from 'projects/Clinic/src/app/generated/services';
import { FormControl } from '@angular/forms';
import { UsersTemplate, Record } from 'projects/Clinic/src/app/generated/models';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: "mp-templates-modal",
  templateUrl: "./templates-modal.component.html",
  styleUrls: ["./templates-modal.component.scss"]
})

export class TemplatesModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  private _templates$ = new BehaviorSubject<UsersTemplate[]>([]);

  templates: UsersTemplate[] = [];
  created: { [templateId: number]: Record } = {};
  selected: UsersTemplate = null;

  loading = false;
  loaded = false;
  processing = false;
  opening = false;

  @Input() contractItemId: number;
  @Input() personId: number;

  @Input() title: string;

  @Output() onSubmit = new EventEmitter<UsersTemplate>();
  @Output() onRecordOpen = new EventEmitter<number>();

  searchControl: FormControl = new FormControl("");

  get templates$(): Observable<UsersTemplate[]> { return this._templates$; }
  get hasSelected(): boolean { return !!this.selected; }
  get exists(): boolean { return !!this.selected && !!this.created[this.selected.id]; }

  public constructor(
    private modal: NgbActiveModal,
    private myService: MyService,
    private personsService: PersonsService
  ) {

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        (value: string) => {

          if (!value) {
            this._templates$.next([...this.templates]);
            return;
          }

          const regex = new RegExp(value, "i");

          this._templates$.next(this.templates.filter(x => regex.test(x.name)));
        }
      );

    this.searchControl.disable();
  }

  ngOnInit(): void {
    this.loading = true;

    forkJoin({
      consultations: this.personsService.ConsultationsAsync(this.personId),
      templates: this.myService.Templates()
    }).pipe(
      map((payload) => {
        this.templates = payload.templates;
        const consultation = payload.consultations.find(x => x.id === this.contractItemId);

        if (consultation) {
          for (const record of consultation.records) {
            this.created[record.templateId] = record;
          }
        }

        this.loading = false;
        this.searchControl.enable({ emitEvent: false });

        this.searchControl.setValue("");
      })
    ).subscribe()
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  isSelected(item: UsersTemplate): boolean {
    return !!this.selected && this.selected.id === item.id;
  }

  select = (item: UsersTemplate)=> this.selected = item;
  cancel = ()=> this.modal.dismiss();

  createDocument() {
    if (!this.selected) return;
    if (this.processing) return;

    if (this.created[this.selected.id]) {
      this.opening = true;
      this.onRecordOpen.emit(this.created[this.selected.id].id);
      return;
    }

    this.processing = true;

    this.onSubmit.emit(this.selected);
  }
}
