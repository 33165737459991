/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SpecialitiesResponse } from '../models/specialities-response';
import { CreateSpecialityResponse } from '../models/create-speciality-response';
import { CreateSpecialityRequest } from '../models/create-speciality-request';
import { RemoveSpecialitiesResponse } from '../models/remove-specialities-response';
import { SpecialityEditorModel } from '../models/speciality-editor-model';
import { UpdateSpecialityRequest } from '../models/update-speciality-request';
import { PerformersResponse } from '../models/performers-response';
@Injectable({
  providedIn: 'root',
})
class SpecialitiesService extends __BaseService {
  static readonly SpecialitiesPath = '/api/Specialities';
  static readonly CreatePath = '/api/Specialities';
  static readonly RemoveManyPath = '/api/Specialities';
  static readonly SpecialityPath = '/api/Specialities/{id}';
  static readonly UpdatePath = '/api/Specialities/{id}';
  static readonly RemoveAsyncPath = '/api/Specialities/{id}';
  static readonly PerformersPath = '/api/Specialities/{specialityId}/performers';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SpecialitiesService.SpecialitiesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  SpecialitiesResponse(params: SpecialitiesService.SpecialitiesParams): __Observable<__StrictHttpResponse<SpecialitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Search != null) __params = __params.set('Search', params.Search.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialitiesResponse>;
      })
    );
  }
  /**
   * @param params The `SpecialitiesService.SpecialitiesParams` containing the following parameters:
   *
   * - `Size`:
   *
   * - `Search`:
   *
   * - `Page`:
   *
   * @return Success
   */
  Specialities(params: SpecialitiesService.SpecialitiesParams): __Observable<SpecialitiesResponse> {
    return this.SpecialitiesResponse(params).pipe(
      __map(_r => _r.body as SpecialitiesResponse)
    );
  }

  /**
   * @param request undefined
   * @return Success
   */
  CreateResponse(request?: CreateSpecialityRequest): __Observable<__StrictHttpResponse<CreateSpecialityResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = request;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CreateSpecialityResponse>;
      })
    );
  }
  /**
   * @param request undefined
   * @return Success
   */
  Create(request?: CreateSpecialityRequest): __Observable<CreateSpecialityResponse> {
    return this.CreateResponse(request).pipe(
      __map(_r => _r.body as CreateSpecialityResponse)
    );
  }

  /**
   * @param Id undefined
   * @return Success
   */
  RemoveManyResponse(Id?: Array<number>): __Observable<__StrictHttpResponse<RemoveSpecialitiesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (Id || []).forEach(val => {if (val != null) __params = __params.append('Id', val.toString())});
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Specialities`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoveSpecialitiesResponse>;
      })
    );
  }
  /**
   * @param Id undefined
   * @return Success
   */
  RemoveMany(Id?: Array<number>): __Observable<RemoveSpecialitiesResponse> {
    return this.RemoveManyResponse(Id).pipe(
      __map(_r => _r.body as RemoveSpecialitiesResponse)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  SpecialityResponse(id: number): __Observable<__StrictHttpResponse<SpecialityEditorModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Specialities/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SpecialityEditorModel>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  Speciality(id: number): __Observable<SpecialityEditorModel> {
    return this.SpecialityResponse(id).pipe(
      __map(_r => _r.body as SpecialityEditorModel)
    );
  }

  /**
   * @param params The `SpecialitiesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  UpdateResponse(params: SpecialitiesService.UpdateParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.request;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/Specialities/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `SpecialitiesService.UpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `request`:
   */
  Update(params: SpecialitiesService.UpdateParams): __Observable<null> {
    return this.UpdateResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  RemoveAsyncResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/Specialities/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  RemoveAsync(id: number): __Observable<null> {
    return this.RemoveAsyncResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `SpecialitiesService.PerformersParams` containing the following parameters:
   *
   * - `specialityId`:
   *
   * - `VisitDate`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  PerformersResponse(params: SpecialitiesService.PerformersParams): __Observable<__StrictHttpResponse<PerformersResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.VisitDate != null) __params = __params.set('VisitDate', params.VisitDate.toString());
    if (params.Size != null) __params = __params.set('Size', params.Size.toString());
    if (params.Page != null) __params = __params.set('Page', params.Page.toString());
    if (params.Gender != null) __params = __params.set('Gender', params.Gender.toString());
    if (params.Dob != null) __params = __params.set('Dob', params.Dob.toString());
    if (params.CompanyId != null) __params = __params.set('CompanyId', params.CompanyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Specialities/${params.specialityId}/performers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PerformersResponse>;
      })
    );
  }
  /**
   * @param params The `SpecialitiesService.PerformersParams` containing the following parameters:
   *
   * - `specialityId`:
   *
   * - `VisitDate`:
   *
   * - `Size`:
   *
   * - `Page`:
   *
   * - `Gender`:
   *
   * - `Dob`:
   *
   * - `CompanyId`:
   *
   * @return Success
   */
  Performers(params: SpecialitiesService.PerformersParams): __Observable<PerformersResponse> {
    return this.PerformersResponse(params).pipe(
      __map(_r => _r.body as PerformersResponse)
    );
  }
}

module SpecialitiesService {

  /**
   * Parameters for Specialities
   */
  export interface SpecialitiesParams {
    Size?: number;
    Search?: string;
    Page?: number;
  }

  /**
   * Parameters for Update
   */
  export interface UpdateParams {
    id: number;
    request?: UpdateSpecialityRequest;
  }

  /**
   * Parameters for Performers
   */
  export interface PerformersParams {
    specialityId: number;
    VisitDate?: string;
    Size?: number;
    Page?: number;
    Gender?: any;
    Dob?: string;
    CompanyId?: number;
  }
}

export { SpecialitiesService }
