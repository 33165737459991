import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { Attending, Company, Speciality } from '../../../../generated/models';

import { ReviewsFilters } from '../reviews/reviews.component';

@Component({
  selector: 'mp-reviews-filters-modal',
  templateUrl: './reviews-filters-modal.component.html',
  styleUrls: ['./reviews-filters-modal.component.scss']
})
export class ReviewsFiltersModalComponent implements OnInit, OnChanges, OnDestroy {
  destroy$ = new Subject<void>();

  @Input() companies: Company[] = [];
  @Input() specialities: Speciality[] = [];
  @Input() doctors: Attending[] = [];

  @Input()
  set filters(value: ReviewsFilters) {
    this.form.patchValue(value, { emitEvent: false });
  }

  @Output() onConfirm = new EventEmitter<ReviewsFilters>();

  @Output() onCancel = new EventEmitter<void>();

  form = new FormGroup({
    companies: new FormControl([]),
    specialities: new FormControl([]),
    doctors: new FormControl([]),
    patientName: new FormControl(""),
    patientDob: new FormControl(undefined),
    patientGender: new FormControl(0),
    visitDate: new FormControl(undefined),
    onlyClosed: new FormControl(true),
    onlyReviewed: new FormControl(false),
    onlyUnrevised: new FormControl(false),
    onlyDrafts: new FormControl(false),
    isPreventive: new FormControl(false),
    reviewDate: new FormControl(undefined),
    diagnosis: new FormControl('')
  });

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filters']) {
      const value: ReviewsFilters = changes['filters'].currentValue;
      this.form.patchValue({ ...value }, { emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  confirm(): void {
    const value: ReviewsFilters = this.form.getRawValue();

    this.onConfirm.emit(value);
  }

  cancel(): void {
    this.onCancel.emit();
  }

  clear() {
    this.form.reset();
    this.form.patchValue({
      onlyClosed: true,
      patientGender: 0
    });
  }

  selected(company: Company) {
    const value: ReviewsFilters = this.form.getRawValue();
    return value.companies && value.companies.includes(company.id);
  }

  toggle(company: Company, checked: boolean) {
    const value: ReviewsFilters = this.form.getRawValue();

    if (!checked && value.companies.includes(company.id)) {
      this.form.patchValue({ companies: value.companies.filter(x => x !== company.id) });
    }

    if (checked && !value.companies.includes(company.id)) {
      this.form.patchValue({ companies: [...value.companies, company.id] });
    }
  }

}
