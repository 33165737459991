import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap, Params } from '@angular/router';

import * as moment from 'moment';

import { Observable, Subject, from } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';

import { SchedulesService, PatientsService } from 'projects/Clinic/src/app/generated/services';
import { FiltersResponse, FilterItem, WeekScheduleViewModel, ResourcesDayScheduleViewModel, AppointmentViewModel, Patient, ResourceDayScheduleSummary, ResourceWeekScheduleViewModel, Speciality, PatientSearchResult } from 'projects/Clinic/src/app/generated/models';
import { filter, tap, takeUntil, switchMap, catchError } from 'rxjs/operators';
import { PatientPersonalInfo } from '../../models/patient.personal.info';
import { EventNames, MessageReceiverService } from '../../../../services/message.receiver.service';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { UserStorage } from '../../../../services/user-storage';
import { PermissionNames } from '../../../../models/permission-names';
import { ActiveCallService, Interlocutor } from '../../../active-call/services/active-call-service';

@Component({
  selector: 'mp-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent implements OnInit {
  destroy$ = new Subject<void>();

  private _currentWeekStart: moment.Moment;
  private _currentDay: moment.Moment;

  previousWeekRange: string;
  nextWeekRange: string;

  dayScheduleLoader: Observable<ResourcesDayScheduleViewModel[]>;

  resources: ResourceWeekScheduleViewModel[] = [];
  daySchedule: ResourcesDayScheduleViewModel[];

  isOnDayComponent = false;

  queryParamsWeek: Params = {};
  queryParamsDay: Params = {};

  allMedicalCenters: FilterItem[] = [];
  allServices: FilterItem[] = [];
  allDoctors: FilterItem[] = [];
  allSpecialities: FilterItem[] = [];

  patient: PatientPersonalInfo;

  dayLoading = false;
  weekLoading = false;

  get currentWeekStart(): string {
    if (!this._currentWeekStart) return '';
    return this._currentWeekStart.format("YYYY-MM-DD");
  }
  get currentDay(): string {
    if (!this._currentDay) return '';
    return this._currentDay.format("YYYY-MM-DD");
  }

  searchForm = new FormGroup({
    searchCentersWeek: new FormControl([]),
    searchServicesWeek: new FormControl([]),
    searchDoctorsWeek: new FormControl([]),
    searchSpecialitiesWeek: new FormControl([]),

    searchCentersDay: new FormControl([]),
    searchServicesDay: new FormControl([]),
    searchDoctorsDay: new FormControl([]),
    searchSpecialitiesDay: new FormControl([]),

    from: new FormControl("", []),
    to: new FormControl("", []),
    date: new FormControl("", []),
    mode: new FormControl(2, [])
  });

  canManageBreaks = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private schedulesService: SchedulesService,
    private messageRecieverService: MessageReceiverService,
    private patientsService: PatientsService,
    private toastrService: ToastrService,
    private userStorage: UserStorage,
    private activeCallService: ActiveCallService
  ) {

    this.canManageBreaks = userStorage.hasPermission(PermissionNames.ManageScheduleBreaks);

    this.searchForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((value: FormValue): boolean => value.mode === 1),
        tap((value: FormValue): void => {
          this.queryParamsWeek = {};

          if (value.searchCentersWeek.length > 0) {
            this.queryParamsWeek["medicalCenter"] = value.searchCentersWeek;
          }

          if (value.searchServicesWeek.length > 0) {
            this.queryParamsWeek["service"] = value.searchServicesWeek;
          }
          if (value.searchDoctorsWeek.length > 0) {
            this.queryParamsWeek["doctor"] = value.searchDoctorsWeek;
          }
          if (value.searchSpecialitiesWeek.length > 0) {
            this.queryParamsWeek["speciality"] = value.searchSpecialitiesWeek;
          }

          this.router.navigate(["/schedules/week", value.from], { relativeTo: this.activatedRoute, queryParams: this.queryParamsWeek });
        }),
        tap((): void => {
          this.weekLoading = true;
        }),
        switchMap((value: FormValue): Observable<WeekScheduleViewModel> =>
          this.schedulesService.WeeklySchedule({
            StartDate: value.from,
            EndDate: value.to,
            DoctorIds: value.searchDoctorsWeek,
            OrganizationIds: value.searchCentersWeek,
            ServicesIds: value.searchServicesWeek,
            SpecsIds: value.searchSpecialitiesWeek
          })
            .pipe(
              catchError((error: HttpErrorResponse): Observable<WeekScheduleViewModel> => {
                this.toastrService.error("Не удалось загрузить раписание", "Ошибка");

                return from([{}]);
              })
            )
        ),
        tap((): void => {
          this.isOnDayComponent = false;
        })
      )
      .subscribe(
        (schedule: WeekScheduleViewModel): void => {
          this.resources = schedule.items;
          this.weekLoading = false;
        },
        (): void => {
          this.weekLoading = false;
        }
      );

    this.searchForm.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter((value: FormValue): boolean => value.mode === 2),
        tap((value: FormValue): void => {
          this.queryParamsDay = {};

          if (value.searchCentersDay.length > 0) {
            this.queryParamsDay["medicalCenter"] = value.searchCentersDay;
          }

          if (value.searchServicesDay.length > 0) {
            this.queryParamsDay["service"] = value.searchServicesDay;
          }
          if (value.searchDoctorsDay.length > 0) {
            this.queryParamsDay["doctor"] = value.searchDoctorsDay;
          }
          if (value.searchSpecialitiesDay.length > 0) {
            this.queryParamsDay["speciality"] = value.searchSpecialitiesDay;
          }
          this.router.navigate(["/schedules/day", value.date], { relativeTo: this.activatedRoute, queryParams: this.queryParamsDay });
        }),
        tap((): void => {
          this.dayLoading = true;
          this.daySchedule = [];
          this.isOnDayComponent = true;
        }),
        switchMap((value: FormValue): Observable<ResourcesDayScheduleViewModel[]> =>
          this.schedulesService.ResourceSlots({
            Day: value.date,

            DoctorIds: value.searchDoctorsDay,
            OrganizationIds: value.searchCentersDay,
            ServicesIds: value.searchServicesDay,
            SpecsIds: value.searchSpecialitiesDay
          })
            .pipe(catchError((error: HttpErrorResponse): Observable<ResourcesDayScheduleViewModel[]> => {
              this.toastrService.error("Не удалось загрузить раписание", "Ошибка");

              return from([[]]);
            })))
      )
      .subscribe(
        (value: ResourcesDayScheduleViewModel[]): void => {

          this.daySchedule = value;
          this.dayLoading = false;
        },
        (): void => {
          this.dayLoading = false;
        }
      );

    this.messageRecieverService.forEvent(EventNames.Slot)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => {
        const start = moment(event.slotStart, 'DD.MM.YYYY HH:mm');

        const schedule = this.daySchedule.find(x => x.date === start.format('DD.MM.YYYY') && x.resourceId === event.resourceId);

        if (schedule) {
          const appointment = schedule.appointments.find(x => x.start === start.format('DD.MM.YYYY HH:mm'));

          if (appointment) {
            appointment.break = event.break;
            appointment.unscheduledBreak = event.unscheduledBreak;
            appointment.claimed = event.claimed;
            appointment.confirmed = event.confirmed;
            appointment.inThePast = event.inThePast;
            appointment.orphan = event.orphan;
            appointment.vacation = event.vacation;
            appointment.sickLeave = event.sickLeave;
            appointment.vacationOrSickLeave = appointment.vacation || appointment.sickLeave;

            appointment.outOfDoctors = event.outOfDoctors;

            appointment.recommended = event.recommended;

            appointment.patientDateOfBirth = event.patientDateOfBirth;
            appointment.patientPhone = event.patientPhone;
            appointment.patientLastName = event.patientLastName;
            appointment.patientFirstName = event.patientFirstName;
            appointment.patientLastName = event.patientMiddleName;

            appointment.serviceId = event.serviceId;
            appointment.doctorId = event.doctorId;
            appointment.comment = event.comment;
          }
        }

      });

    this.messageRecieverService.forEvent(EventNames.CallReportFinished)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.searchForm.patchValue(
          {
            from: this._currentWeekStart.format('YYYY-MM-DD'),
            to: this._currentWeekStart.clone().endOf("isoWeek").format('YYYY-MM-DD'),
            mode: 1,

            searchCentersWeek: [],
            searchServicesWeek: [],
            searchDoctorsWeek: [],
            searchSpecialitiesWeek: [],

            searchCentersDay: [],
            searchServicesDay: [],
            searchDoctorsDay: [],
            searchSpecialitiesDay: []
          });
      })

    this.activeCallService.interlocutorUpdated$
      .pipe(takeUntil(this.destroy$))
      .subscribe((interlocutor: Interlocutor) => {

        if (this.activeCallService.accepted) {
          this.patient = {
            id: interlocutor.id,
            phone: interlocutor.phone.length === 11 && interlocutor.phone.startsWith('7') ? interlocutor.phone.substring(1) : interlocutor.phone,
            lastName: interlocutor.lastname,
            firstName: interlocutor.firstname,
            middleName: interlocutor.middlename,
            dob: interlocutor.dob
          }
        }
      });

    this.activeCallService.accepted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.patient = {
          id: this.activeCallService.interlocutor.id,
          phone: this.activeCallService.interlocutor.phone.length === 11 && this.activeCallService.interlocutor.phone.startsWith('7') ?
            this.activeCallService.interlocutor.phone.substring(1) : this.activeCallService.interlocutor.phone,
          lastName: this.activeCallService.interlocutor.lastname,
          firstName: this.activeCallService.interlocutor.firstname,
          middleName: this.activeCallService.interlocutor.middlename,
          dob: this.activeCallService.interlocutor.dob
        }
      });

    this.activeCallService.completed$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.patient = undefined);
  }

  async ngOnInit() {

    this.schedulesService.Filters().subscribe(
      (result: FiltersResponse) => {
        this.allMedicalCenters = result.organizationIds;
        this.allDoctors = result.doctorsIds;
        this.allServices = result.servicesIds;
        this.allSpecialities = result.specsIds;
      });

    if (this.activatedRoute.snapshot.queryParamMap.has("patientId")) {
      const patientId: number = parseInt(this.activatedRoute.snapshot.queryParamMap.get("patientId"), 10);

      try {
        if (patientId > 0) {
          const response = await this.patientsService.Patient(patientId).toPromise()
          this.selectPatientFromSearch(response);
        }
      } catch (e) {
        console.error(e);
      }
    } else if (this.activeCallService.accepted) {
      this.patient = {
        id: this.activeCallService.interlocutor.id,
        phone: this.activeCallService.interlocutor.phone.length === 11 && this.activeCallService.interlocutor.phone.startsWith('7') ?
          this.activeCallService.interlocutor.phone.substring(1) : this.activeCallService.interlocutor.phone,
        lastName: this.activeCallService.interlocutor.lastname,
        firstName: this.activeCallService.interlocutor.firstname,
        middleName: this.activeCallService.interlocutor.middlename,
        dob: this.activeCallService.interlocutor.dob
      }
    }

    //processing url date
    const datePassed: string = this.activatedRoute.snapshot.paramMap.get("date");
    let day: moment.Moment;
    if (datePassed) {
      day = moment(datePassed, "YYYY-MM-DD");
    }
    else {
      day = moment();
    }

    this._currentWeekStart = day.clone().startOf("isoWeek");
    this._currentDay = day;

    const prevWeekStart: moment.Moment = this._currentWeekStart.clone().add(-1, "week");
    const nextWeekStart: moment.Moment = this._currentWeekStart.clone().add(1, "week");

    this.previousWeekRange = `неделя ${(prevWeekStart.week())} с ${prevWeekStart.format('DD.MM')} по ${prevWeekStart.clone().endOf('isoWeek').format('DD.MM')}`;
    this.nextWeekRange = `неделя ${(nextWeekStart.week())} с ${nextWeekStart.format('DD.MM')} по ${nextWeekStart.clone().endOf('isoWeek').format('DD.MM')}`;

    const mode: string = this.activatedRoute.snapshot.paramMap.get("mode");
    if (mode === "day") {
      this.isOnDayComponent = true;
    }
    else if (mode === "week") {
      this.isOnDayComponent = false;
    }

    //processing url params
    if (this.isOnDayComponent) {
      const param: ParamMap = this.activatedRoute.snapshot.queryParamMap;

      this.searchForm.patchValue({
        searchCentersDay: param.getAll('medicalCenter').map((x: string): number => parseInt(x, 10)),
        searchServicesDay: param.getAll('service').map((x: string): number => parseInt(x, 10)),
        searchDoctorsDay: param.getAll('doctor').map((x: string): number => parseInt(x, 10)),
        searchSpecialitiesDay: param.getAll('speciality').map((x: string): number => parseInt(x, 10)),
        date: day.format("YYYY-MM-DD"),
        mode: 2
      });
    }
    else {
      const param: ParamMap = this.activatedRoute.snapshot.queryParamMap;

      this.searchForm.patchValue({
        searchCentersWeek: param.getAll('medicalCenter').map((x: string): number => parseInt(x, 10)),
        searchServicesWeek: param.getAll('service').map((x: string): number => parseInt(x, 10)),
        searchDoctorsWeek: param.getAll('doctor').map((x: string): number => parseInt(x, 10)),
        searchSpecialitiesWeek: param.getAll('speciality').map((x: string): number => parseInt(x, 10)),
        from: day.clone().startOf('isoWeek').format("YYYY-MM-DD"),
        to: day.clone().endOf('isoWeek').format("YYYY-MM-DD"),
        mode: 1
      });
    }
  }

  dayOfWeekChosen(value: { day: moment.Moment; resource: number }): void {
    this._currentDay = value.day;

    const filters: FormValue = this.searchForm.getRawValue();

    const resource = this.resources.find(x => x.resourceId === value.resource);

    if (!resource) return;

    const specs: number[] = filters.searchSpecialitiesWeek.length > 0 ?
      resource.specialities.filter(s => filters.searchSpecialitiesWeek.includes(s.id)).map(x => x.id)
      : resource.specialities.map(x => x.id);

    const doctors: number[] = filters.searchDoctorsWeek.length > 0 ?
      resource.doctorIds.filter((s: number): boolean => filters.searchDoctorsWeek.includes(s))
      : resource.doctorIds;

    this.searchForm.patchValue({
      date: this._currentDay.format('YYYY-MM-DD'),
      searchCentersDay: [resource.organizationId],
      searchDoctorsDay: [...doctors],
      searchSpecialitiesDay: [...specs],
      mode: 2
    });
  }

  dayOfWeekChanged(value: string): void {
    const day: moment.Moment = moment(value, "DD.MM.YYYY");

    this._currentDay = day;

    this.searchForm.patchValue({
      date: this._currentDay.format('YYYY-MM-DD'),
    });
  }

  dayComponentClosed(closed: boolean): void {
    this.isOnDayComponent = !closed;

    this.searchForm.patchValue(
      {
        from: this._currentWeekStart.format('YYYY-MM-DD'),
        to: this._currentWeekStart.clone().endOf("isoWeek").format('YYYY-MM-DD'),
        mode: closed ? 1 : 2
      });

  }

  navigateWeekBack(): void {
    this.navigateWeek(-1);
  }

  navigateWeekForward(): void {
    this.navigateWeek(1);
  }

  selectPatient(patient: PatientPersonalInfo): void {
    this.patient = patient;
  }

  selectPatientFromSearch(patient: Patient) {
    this.patient = {
      id: patient.id,
      lastName: patient.lastname,
      firstName: patient.firstname,
      middleName: patient.middlename,
      phone: patient.hasRep && patient.repPhone ? patient.repPhone : patient.phone,
      dob: patient.dob
    };
  }

  clearPatient(): void {
    this.patient = undefined;
  }

  private navigateWeek(delta: number): void {
    this._currentWeekStart = this._currentWeekStart.add(delta, 'week');

    const prevWeekStart: moment.Moment = this._currentWeekStart.clone().add(-1, "week");
    const nextWeekStart: moment.Moment = this._currentWeekStart.clone().add(1, "week");

    this.previousWeekRange = `неделя ${(prevWeekStart.week())} с ${prevWeekStart.format('DD.MM')} по ${prevWeekStart.clone().endOf('isoWeek').format('DD.MM')}`;
    this.nextWeekRange = `неделя ${(nextWeekStart.week())} с ${nextWeekStart.format('DD.MM')} по ${nextWeekStart.clone().endOf('isoWeek').format('DD.MM')}`;

    const from: string = this._currentWeekStart.clone().startOf("isoWeek").format("YYYY-MM-DD");
    const to: string = this._currentWeekStart.clone().endOf("isoWeek").format("YYYY-MM-DD");

    this.searchForm.patchValue({ from: from, to: to });
  }
}


interface FormValue {
  searchCentersWeek: number[];
  searchServicesWeek: number[];
  searchDoctorsWeek: number[];
  searchSpecialitiesWeek: number[];

  searchCentersDay: number[];
  searchServicesDay: number[];
  searchDoctorsDay: number[];
  searchSpecialitiesDay: number[];

  from: string;
  to: string;

  date: string;

  mode: 1 | 2;
}

/** Событие обновления состояния слота */
interface SlotEvent {
  scheduleId: number;
  resourceId: number;
  slotStart: string;
  newStatus: number;
  user: string;
  patientFirstName: string;
  patientMiddleName: string;
  patinetLastName: string;
  phone: string;
  doB: string;
  serviceId: number;
  doctorsId: number;
}
