import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'mp-referrals-section',
  templateUrl: './referrals-section.component.html',
  styleUrls: ['./referrals-section.component.scss']
})
export class ReferralsSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
