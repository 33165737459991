import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { ScheduleBreakReason } from '../../../../generated/models';

@Component({
  selector: 'mp-break-reason',
  templateUrl: './break-reason.component.html',
  styleUrls: ['./break-reason.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreakReasonComponent implements OnInit {

  @Input() item: ScheduleBreakReason;
  @Input() selected = false;

  constructor() { }

  ngOnInit() { }

}
