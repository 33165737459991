import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

// @dynamic
export class SkytecsValidators {


  static snils(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => {
      if (!control.value) {
        return null;
      }

      const escaped: string = control.value.replace(/\D/g, '');

      if (escaped.length !== 11) {
        return null;
      }

      const number: number[] = escaped.substr(0, 9).split('').map((x: string): number => parseInt(x));
      const key: number = parseInt(escaped.substr(9));

      const sum: number = number.reduce((sum: number, digit: number, index: number): number => sum + digit * (9 - index), 0);

      let correct = false;

      if (sum < 100) {
        correct = sum === key;
      } else if (sum > 101) {
        correct = (sum % 101) === key;
      } else {
        correct = key === 0;
      }

      return correct ? null : { snils: true };
    }
  }


}
