import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreatmentPrescriptionsModalComponent } from './components/treatment-prescriptions-modal/treatment-prescriptions-modal.component';
import { SelectedTreatmentPrescriptionComponent } from './components/selected-treatment-prescription/selected-treatment-prescription.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TreatmentPrescriptionsSectionEditorComponent } from './components/treatment-prescriptions-section-editor/treatment-prescriptions-section-editor.component';
import { RecentTreatmentPrescriptionComponent } from './components/recent-treatment-prescription/recent-treatment-prescription.component';
import { RecommendedTreatmentsComponent } from './components/recommended-treatments/recommended-treatments.component';
import { LayoutModule } from "@skytecs/layout";
import { ControlsModule } from "@skytecs/controls";
import { NgbAccordionModule, NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { RecommendedTreatmentComponent } from './components/recommended-treatment/recommended-treatment.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TreatmentAnalogModalComponent } from './components/treatment-analog-modal/treatment-analog-modal.component';
import { TreatmentAnalogEditorComponent } from './components/treatment-analog-editor/treatment-analog-editor.component';
import { FavTreatmentsComponent } from './components/fav-treatments/fav-treatments.component';
import { FavTreatmentComponent } from './components/fav-treatment/fav-treatment.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TreatmentPrescriptionsModalComponent, SelectedTreatmentPrescriptionComponent, TreatmentPrescriptionsSectionEditorComponent, RecentTreatmentPrescriptionComponent, RecommendedTreatmentsComponent, RecommendedTreatmentComponent, TreatmentAnalogModalComponent, TreatmentAnalogEditorComponent, FavTreatmentsComponent, FavTreatmentComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LayoutModule,
    ControlsModule,
    SharedModule,
    NgbAccordionModule,
    NgbPopoverModule,
    NgbDropdownModule,
    NgbModalModule,
    DragDropModule,
  ],
  exports: [
    TreatmentPrescriptionsModalComponent,
    TreatmentPrescriptionsSectionEditorComponent
  ],
  entryComponents: [TreatmentAnalogModalComponent]
})
export class TreatmentsModule { }
