import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { ReportPromotion } from '../../../../generated/models';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mp-promotions-modal',
  templateUrl: './promotions-modal.component.html',
  styleUrls: ['./promotions-modal.component.scss']
})
export class PromotionsModalComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<void> = new Subject<void>();

  private _selected: ReportPromotion[] = [];
  private _resultSubject: BehaviorSubject<ReportPromotion[]> = new BehaviorSubject<ReportPromotion[]>([]);

  @Input() title: string;
  @Input() items: ReportPromotion[] = [];
  @Input() selected: ReportPromotion[] = [];

  @Output()
  onConfirm: EventEmitter<ReportPromotion[]> = new EventEmitter<ReportPromotion[]>();

  @Output()
  onCancel: EventEmitter<void> = new EventEmitter<void>();

  get result(): Observable<ReportPromotion[]> { return this._resultSubject; }
  search: FormControl;

  constructor() {
    this.search = new FormControl("");

    this.search.valueChanges
      .pipe(
        takeUntil(this._destroy$)
      )
      .subscribe(
        (value: string): void => {
          if (!value) {
            this._resultSubject.next([...this.items]);
          } else {

            const search = new RegExp(value, "i");
            const filtered: ReportPromotion[] = this.items.filter((x: ReportPromotion): boolean => search.test(x.name));

            this._resultSubject.next(filtered);
          }
        }
      );
  }

  ngOnInit() {
    this._selected = this.selected;
    this.search.setValue("");
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  isSelected(item: ReportPromotion): boolean {
    return this._selected.some((x: ReportPromotion): boolean => x.id === item.id);
  }

  toggle(item: ReportPromotion): void {
    const index: number = this._selected.findIndex((x: ReportPromotion): boolean => x.id === item.id);

    if (index === -1) {
      this._selected.push(item);
    } else {
      this._selected.splice(index, 1);
    }
  }

  confirm(): void {
    this.onConfirm.emit([...this._selected]);
  }

  cancel(): void {
    this.onCancel.emit();
  }
}
