import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectedServiceDetails } from '../service-details-modal/service-details-modal.component';
import { Locus, LocusContainer, Performer } from '../../../../generated/models';

@Component({
  selector: 'mp-service-details-modal-item',
  templateUrl: './service-details-modal-item.component.html',
  styleUrls: ['./service-details-modal-item.component.scss']
})
export class ServiceDetailsModalItemComponent implements OnInit {

  @Input() item: SelectedServiceDetails;

  @Output() onPerformerSelected = new EventEmitter<Performer>();
  @Output() onContainerToggle = new EventEmitter<{ locusId: number, containerId: number }>();

  get performerMissed() { return this.item.performerRequired && !this.item.performerId; }
  get containersMissed() { return this.item.containersRequired && this.item.selectedLocusContainers.length === 0; }

  constructor() { }

  ngOnInit() { }

  isSelected = (performer: Performer) => this.item.performerId === performer.id;
  select = (performer: Performer) => this.onPerformerSelected.emit(performer);

  age(age: number) {
    if (age === 11) return '11 лет';
    if (age % 10 === 1) return `${age} года`;

    return `${age} лет`;
  }

  ageRestrictions(performer: Performer) {
    if (!performer.minAge && !performer.maxAge) {
      return 'без возрастных ограничений';
    }

    if (performer.minAge > 0 && performer.maxAge > 0) {
      return `от ${this.age(performer.minAge)} до ${this.age(performer.maxAge)}`;
    }

    if (performer.minAge > 0) {
      return `от ${this.age(performer.minAge)}`;
    }

    if (performer.maxAge > 0) {
      return `до ${this.age(performer.maxAge)}`;
    }

    return '';
  }


  isContainerSelected(locus: Locus, container: LocusContainer) {
    return this.item.selectedLocusContainers.some(x => x.locusId === locus.id && x.containerId === container.id);
  }

  toggleContainer(locus: Locus, container: LocusContainer) {
    this.onContainerToggle.emit({ locusId: locus.id, containerId: container.id });
  }

  locusInvalid(locus: Locus) {
    const selected = this.item.selectedLocusContainers.filter(x => x.locusId === locus.id);

    if (locus.required && selected.length === 0) {
      return 'Выбор контейнера для данного биоматериала обязателен';
    }

    if (selected.length !== 0 && locus.containers.some(x => x.required && !selected.some(c => c.containerId === x.id))) {
      return 'Необходимо выбрать все обязательные контейнеры для данного биоматериала';
    }

    return;
  }
}
