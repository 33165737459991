import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mp-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true
  }]
})
export class PhoneInputComponent implements OnInit, ControlValueAccessor {
  private _value: string;

  public disabled: boolean;

  public get value(): string { return this._value; }
  public set value(value: string) { this._value = value; }

  public onChange: (value: string) => any;
  public onTouched: (value: string) => any;

  constructor() { }

  ngOnInit() { }

  change() {
    this.onChange(this._value);
  }

  focusout() {
    this._value = this._value.replace(/\D/g, "");

    this.onTouched(this._value);
  }
  focus() { }

  writeValue(obj: any): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
