import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyService } from '../../../generated/services';

@Injectable({ providedIn: 'root' })
export class ReportsUtilityService {

  constructor(private myService: MyService) { }

  getUrl(parameters: { [key: string]: string }, type = 'html', download = false): Observable<string> {

    return this.myService.AccessToken({
      parameters: parameters
    }).pipe(
      map(response => `api/v1/Reports/Prepared?token=${response.token}&type=${type}${(download ? '&download=true' : '')}`)
    );
  }

  downloadReport(parameters: { [key: string]: string }, type = 'html'): void {
    this.getUrl(parameters, type, true).subscribe(x => this.download(x));
  }

  printReport(parameters: { [key: string]: string }, type = 'html'): void {
    this.getUrl(parameters, type, false).subscribe(x => this.download(x, true));
  }

  download(url: string, printOnLoad = false): void {
    const iframe: HTMLIFrameElement = document.createElement("iframe");

    iframe.width = "0px";
    iframe.height = "0px";
    iframe.frameBorder = "none";
    iframe.hidden = true;
    iframe.src = url;

    document.body.appendChild(iframe);

    if (printOnLoad) {
      iframe.contentWindow.print();
    }
  }

}
