import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { PlannedVisit, PlannedVisitCallReport } from '../../../../generated/models';

@Component({
  selector: 'mp-planned-visit-item',
  templateUrl: './planned-visit-item.component.html',
  styleUrls: ['./planned-visit-item.component.scss']
})
export class PlannedVisitItemComponent implements OnInit {
  @HostBinding('class.success') get success() { return this.item && this.item.appointments.length > 0; }

  @HostBinding('class.warning')
  get warning() {
    if (!this.item) return false;
    if (this.item.appointments && this.item.appointments.length > 0) return false;
    if (!this.item.calls || this.item.calls.length === 0) return true;
    if (this.item.calls.some(c => c.reasons && c.reasons.every(r => r.reasonable))) return true;

    return false;
  }

  @HostBinding('class.danger') get danger() { return this.item && this.item.calls && this.item.calls.some(c => c.reasons && c.reasons.some(r => !r.reasonable)); }

  @Output() onCall = new EventEmitter<void>();
  @Output() onTypeClick = new EventEmitter<void>();
  @Output() onRemoveCall = new EventEmitter<PlannedVisitCallReport>();
  @Output() onUpdateCall = new EventEmitter<PlannedVisitCallReport>();

  @Input() item: PlannedVisit;
  @Input() canRemoveCalls = false;

  get date(): string { return this.item ? this.item.date.substring(0, 10) : ''; }
  get appointmentDate(): string { return this.item ? this.item.appointmentDate.substring(0, 10) : ''; }

  get phone() {
    if (!this.item) return '';
    if (this.item.patientPhone.length !== 11) return this.item.patientPhone;
    const p = this.item.patientPhone;
    return `+7 (${p.substring(1, 4)}) ${p.substring(4, 7)}-${p.substring(7, 9)}-${p.substring(9, 11)}`;
  }

  get doctorName(): string { return this.fullName(this.item.doctorLastName, this.item.doctorFirstName, this.item.doctorMiddleName); }
  get patientName(): string { return this.fullName(this.item.patientLastName, this.item.patientFirstName, this.item.patientMiddleName); }

  get showBtn(): boolean {
    if (!this.item) return false;
    //if (this.item.visits && this.item.visits.length > 0) return false;
    if (this.item.calls && this.item.calls.length > 0) return false;

    return true;
  }

  get canManage(): boolean {
    if (!this.item) return false;
    if (!this.item.calls || this.item.calls.length === 0) return false;

    return this.canRemoveCalls;
  }

  constructor() { }

  ngOnInit() { }

  fullName = (lastname: string, firstname: string, middlename: string) => [lastname, firstname, middlename].filter(x => x).join(' ').trim();

  call = () => this.onCall.emit();
  clickType = () => this.onTypeClick.emit();
  removeCall = () => this.onRemoveCall.emit(this.item.calls[0]);
  updateCall = () => this.onUpdateCall.emit(this.item.calls[0]);
}
